export enum IncomingPrintFields {
  Comment = 'comment',
  CompanyName = 'companyName',
  VATId = 'VATId',
  House = 'house',
  Street = 'street',
  City = 'city',
  ZipCode = 'zipCode',
  BillNumber = 'billNumber',
}

export interface BillInfoProps {
  companyName: string | null;
  billAddress: {
    city: string | null;
    street: string | null;
    index: string | null;
    house: string | null;
  } | null;
  comment: string | null;
  VATId: string | null;
}
