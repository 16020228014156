import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { PaginationFragmentDoc } from '../../fragments/__generated__/Pagination';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type GetStocksQueryVariables = Types.Exact<{
  pagination?: Types.InputMaybe<Types.PaginationArg>;
  filters?: Types.InputMaybe<Types.StockFiltersInput>;
  sort?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  filtersLocale?: Types.InputMaybe<Types.CarSpareLocaleFiltersInput>;
}>;


export type GetStocksQuery = { __typename?: 'Query', stocks?: { __typename?: 'StockEntityResponseCollection', meta: { __typename?: 'ResponseCollectionMeta', pagination: { __typename?: 'Pagination', page: number, pageCount: number, pageSize: number, total: number } }, data: Array<{ __typename?: 'StockEntity', id?: string | null, attributes?: { __typename?: 'Stock', isAcceptedFromOrder?: boolean | null, balanceQuantity: number, incomingQuantity?: number | null, reservedQuantity?: number | null, supplierPrice?: number | null, createdAt?: any | null, car_spare?: { __typename?: 'CarSpareEntityResponse', data?: { __typename?: 'CarSpareEntity', id?: string | null, attributes?: { __typename?: 'CarSpare', tecdoc_articleNumber?: string | null, car_spare_locales?: { __typename?: 'CarSpareLocaleRelationResponseCollection', data: Array<{ __typename?: 'CarSpareLocaleEntity', id?: string | null, attributes?: { __typename?: 'CarSpareLocale', title?: string | null, brandName?: string | null } | null }> } | null } | null } | null } | null, supplier?: { __typename?: 'SupplierEntityResponse', data?: { __typename?: 'SupplierEntity', id?: string | null, attributes?: { __typename?: 'Supplier', name?: string | null } | null } | null } | null, stock_cell_address?: { __typename?: 'StockCellAddressEntityResponse', data?: { __typename?: 'StockCellAddressEntity', id?: string | null, attributes?: { __typename?: 'StockCellAddress', cell?: string | null, shelf?: string | null } | null } | null } | null, incoming_invoice?: { __typename?: 'IncomingInvoiceEntityResponse', data?: { __typename?: 'IncomingInvoiceEntity', id?: string | null, attributes?: { __typename?: 'IncomingInvoice', date: any, total?: number | null } | null } | null } | null } | null }> } | null };


export const GetStocksDocument = gql`
    query getStocks($pagination: PaginationArg, $filters: StockFiltersInput, $sort: [String], $filtersLocale: CarSpareLocaleFiltersInput) {
  stocks(pagination: $pagination, filters: $filters, sort: $sort) {
    meta {
      ...Pagination
    }
    data {
      id
      attributes {
        isAcceptedFromOrder
        balanceQuantity
        incomingQuantity
        reservedQuantity
        supplierPrice
        createdAt
        car_spare {
          data {
            id
            attributes {
              car_spare_locales(filters: $filtersLocale) {
                data {
                  id
                  attributes {
                    title
                    brandName
                  }
                }
              }
              tecdoc_articleNumber
            }
          }
        }
        supplier {
          data {
            id
            attributes {
              name
            }
          }
        }
        stock_cell_address {
          data {
            id
            attributes {
              cell
              shelf
            }
          }
        }
        incoming_invoice {
          data {
            id
            attributes {
              date
              total
            }
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useGetStocksQuery__
 *
 * To run a query within a React component, call `useGetStocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStocksQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *      filtersLocale: // value for 'filtersLocale'
 *   },
 * });
 */
export function useGetStocksQuery(baseOptions?: Apollo.QueryHookOptions<GetStocksQuery, GetStocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStocksQuery, GetStocksQueryVariables>(GetStocksDocument, options);
      }
export function useGetStocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStocksQuery, GetStocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStocksQuery, GetStocksQueryVariables>(GetStocksDocument, options);
        }
export type GetStocksQueryHookResult = ReturnType<typeof useGetStocksQuery>;
export type GetStocksLazyQueryHookResult = ReturnType<typeof useGetStocksLazyQuery>;
export type GetStocksQueryResult = Apollo.QueryResult<GetStocksQuery, GetStocksQueryVariables>;