import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type GetIsMobileQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetIsMobileQuery = { __typename?: 'Query', isMobile: boolean };


export const GetIsMobileDocument = gql`
    query getIsMobile {
  isMobile @client
}
    `;

/**
 * __useGetIsMobileQuery__
 *
 * To run a query within a React component, call `useGetIsMobileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsMobileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsMobileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIsMobileQuery(baseOptions?: Apollo.QueryHookOptions<GetIsMobileQuery, GetIsMobileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIsMobileQuery, GetIsMobileQueryVariables>(GetIsMobileDocument, options);
      }
export function useGetIsMobileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIsMobileQuery, GetIsMobileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIsMobileQuery, GetIsMobileQueryVariables>(GetIsMobileDocument, options);
        }
export type GetIsMobileQueryHookResult = ReturnType<typeof useGetIsMobileQuery>;
export type GetIsMobileLazyQueryHookResult = ReturnType<typeof useGetIsMobileLazyQuery>;
export type GetIsMobileQueryResult = Apollo.QueryResult<GetIsMobileQuery, GetIsMobileQueryVariables>;