import React, { FC } from 'react';

import { InfoOutlined } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';

type OrderItemStatusesHeaderProps = {
  translateLang: (transVar: string) => string;
};

export const OrderItemStatusesHeader: FC<OrderItemStatusesHeaderProps> = ({ translateLang }) => {
  return (
    <Box display="flex">
      <Typography fontSize="14px" marginRight="3px">
        {translateLang('status')}
      </Typography>
      <Tooltip
        title={
          <>
            <Typography>{translateLang('first_checkbox')}</Typography>{' '}
            <Typography mt={1}>{translateLang('second_checkbox')}</Typography>
            <Typography mt={1}>{translateLang('information_mark')}</Typography>
          </>
        }
        arrow
      >
        <InfoOutlined fontSize="small" sx={{ color: '#a5a5a5' }} />
      </Tooltip>
    </Box>
  );
};
