import React, { FC } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { Icon } from '../../../legos';
import { theme } from '../../../helpers';

type ConfirmSendingOrderDialogProps = {
  isModalOpen: boolean;
  handleClose: () => void;
  dialogType: 'sendAll' | 'sendOne';
  yesAction: () => void;
  loading: boolean;
  supplierName?: string;
};

export const ConfirmSendingOrderDialog: FC<ConfirmSendingOrderDialogProps> = ({
  isModalOpen,
  handleClose,
  dialogType,
  yesAction,
  loading,
  supplierName,
}) => {
  return (
    <Dialog
      disableEscapeKeyDown
      open={isModalOpen}
      closeAfterTransition
      maxWidth="sm"
      fullWidth
      disableScrollLock
    >
      <DialogTitle>
        <Stack alignItems="center">
          <Stack width="100%" flexDirection="row" justifyContent="end" mb={2}>
            <IconButton disabled={loading} onClick={() => handleClose()} sx={{ p: 0 }}>
              <Icon icon="close" sx={{ color: theme.palette.primary.main }} />
            </IconButton>
          </Stack>
          <TranslatedField
            isTranslate
            variant="h4"
            color={theme.palette.common.darkBlue}
            marginBottom={2.5}
            fontSize={30}
            originText="automaticOrdering"
          />
        </Stack>
      </DialogTitle>
      <DialogContent>
        <TranslatedField
          isTranslate
          variant="h4"
          color={theme.palette.common.darkBlue}
          marginBottom={2.5}
          fontSize={18}
          originText={
            dialogType === 'sendAll'
              ? 'automaticOrderAllProductToTheSuppliers'
              : 'automaticOrderProductsToSupplier'
          }
          suffix={dialogType === 'sendOne' ? `: "${supplierName}"` : ''}
        />
        <TranslatedField
          isTranslate
          variant="h4"
          color={theme.palette.common.darkBlue}
          marginBottom={2.5}
          fontSize={18}
          originText="continue"
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-around', pb: 4 }}>
        <Button
          onClick={() => yesAction()}
          variant="contained"
          sx={{ height: 50, minWidth: 150, textTransform: 'none' }}
          color="warning"
          disabled={loading}
        >
          {loading ? <CircularProgress size="2.5rem" sx={{ position: 'absolute' }} /> : null}
          <TranslatedField originText="yes" fontSize={16} isTranslate noWrap />
        </Button>
        <Button
          autoFocus
          onClick={() => handleClose()}
          variant="contained"
          sx={{ height: 50, minWidth: 100, textTransform: 'none' }}
          disabled={loading}
        >
          <TranslatedField originText="no" fontSize={16} isTranslate noWrap />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
