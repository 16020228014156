import React, { ChangeEvent, FC, useState } from 'react';
import { Autocomplete, Box, Button, Stack, TextField, UseAutocompleteProps } from '@mui/material';

import { Input } from '../../../legos';
import { useLocalization } from '../../../localization';
import {
  CarCustomerType,
  useGetCustomerCar,
} from '../../../graphql/queries/hook/useGetCustomerCar';
import {
  UpdateVehicleMutationVariables,
  useUpdateVehicleMutation,
} from '../../../graphql/mutations/__generated__/updateVehicle';
import { CustomModal } from '../../../components';
import { VehiclesType } from '../../../helpers/types';
import { handlerError } from '../../../helpers/functions';
import {
  AddVehicleMutationVariables,
  useAddVehicleMutation,
} from '../../../graphql/mutations/__generated__/addVehicle';
import { useHandlerNotificationApp } from '../../../hooks/useHandlerNotificationApp';
import { GetClientByIdDocument } from '../../../graphql/queries/__generated__/getClintById';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';

interface CarCustomerForm extends CarCustomerType {
  error?: boolean;
  errorMessage?: string;
}

interface Props {
  open: boolean;
  customerId: string;
  handleClose: () => void;
  currentVehicle?: VehiclesType | null;
}

export const AddVehicleModal: FC<Props> = ({ open, handleClose, customerId, currentVehicle }) => {
  const { translateLang, updateSelectLanguage } = useLocalization();
  const [runAddVehicle] = useAddVehicleMutation();
  const [runUpdateVehicle] = useUpdateVehicleMutation();
  const { addNotification } = useHandlerNotificationApp();

  const [currentBrandCar, setCurrentBrandCar] = useState<CarCustomerForm | null>(
    currentVehicle?.carBrand
      ? { name: currentVehicle.carBrand, id: currentVehicle?.carBrandID || '' }
      : null
  );
  const [currentModelCar, setCurrentModelCar] = useState<CarCustomerForm | null>(
    currentVehicle?.carModel
      ? { name: currentVehicle.carModel, id: currentVehicle?.carModelID || '' }
      : null
  );
  const [currentYearOfManufactureCar, setCurrentYearOfManufactureCar] =
    useState<CarCustomerForm | null>(
      currentVehicle?.yearOfManufacture
        ? {
            name: `${currentVehicle?.yearOfManufacture} (${currentVehicle.carModel})`,
            id: `${currentVehicle?.yearOfManufacture} (${currentVehicle.carModel})`,
            tecdoc_modelId: currentVehicle.tecdoc_modelId,
          }
        : null
    );
  const [currentModificationCar, setCurrentModificationCar] = useState<CarCustomerForm | null>(
    currentVehicle?.carBodyType
      ? { name: currentVehicle?.modificationCar, id: currentVehicle?.carID || '' }
      : null
  );
  const [currentValueVinCode, setCurrentValueVinCode] = useState<CarCustomerForm>(
    currentVehicle?.vinCode ? { name: currentVehicle?.vinCode, id: '' } : { name: '', id: '' }
  );

  const {
    brands,
    models,
    loadingBrands,
    loadingDataCars,
    yearsManufacture,
    modificationsCar,
    loadingModelsGrouped,
  } = useGetCustomerCar({
    brandId: currentBrandCar?.id || '',
    modalId: currentModelCar?.id || '',
    tecdoc_modelId: currentYearOfManufactureCar?.tecdoc_modelId || '',
  });

  const validationFields = (): boolean => {
    const defaultCarObj = { error: true, name: '', id: '' };
    const fields = [
      { value: currentBrandCar, setter: setCurrentBrandCar },
      { value: currentModelCar, setter: setCurrentModelCar },
      { value: currentYearOfManufactureCar, setter: setCurrentYearOfManufactureCar },
      { value: currentModificationCar, setter: setCurrentModificationCar },
      { value: currentValueVinCode, setter: setCurrentValueVinCode, validate: validateVinCode },
    ];

    for (const field of fields) {
      if (!field.value || !field.value.name) {
        if (field.value === currentValueVinCode) {
          return true;
        }
        field.setter({ ...defaultCarObj });
        return false;
      } else if (field.validate) {
        const isValid = field.validate(field.value);
        if (!isValid) {
          field.setter({
            ...defaultCarObj,
            name: currentValueVinCode.name,
          });
          return false;
        }
      }
    }

    return true;

    function validateVinCode(value: CarCustomerForm): boolean {
      // example vin-code 1HGCM82633A123456
      if (value?.name === '') {
        return false;
      } else if (value?.name && value.name !== '') {
        const _val = value.name.trim();
        const re = new RegExp('^[A-HJ-NPR-Z\\d]{13}\\d{4}$', 'i');
        if (!re.test(_val)) {
          addNotification({ messageError: 'This vin-code is not valid', typeMessage: 'error' });
          return false;
        }
      }
      return true;
    }
  };

  const handlerChangeBrand: UseAutocompleteProps<
    CarCustomerForm,
    undefined,
    undefined,
    undefined
  >['onChange'] = (evt, option): void => {
    if (option) {
      setCurrentBrandCar(option as CarCustomerForm);
      setCurrentModelCar(null);
      setCurrentModificationCar(null);
      setCurrentValueVinCode({ id: '', name: '' });
      setCurrentYearOfManufactureCar(null);
    }
  };
  const handleChangeModel: UseAutocompleteProps<
    CarCustomerForm,
    undefined,
    undefined,
    undefined
  >['onChange'] = (evt, option): void => {
    if (option) {
      setCurrentModelCar(option as CarCustomerForm);
      setCurrentModificationCar(null);
      setCurrentValueVinCode({ id: '', name: '' });
      setCurrentYearOfManufactureCar(null);
    }
  };
  const handleChangeYear: UseAutocompleteProps<
    CarCustomerForm,
    undefined,
    undefined,
    undefined
  >['onChange'] = (evt, option): void => {
    if (option) {
      setCurrentYearOfManufactureCar(option as CarCustomerForm);
      setCurrentModificationCar(null);
      setCurrentValueVinCode({ id: '', name: '' });
    }
  };
  const handleChangeModificationCar: UseAutocompleteProps<
    CarCustomerForm,
    undefined,
    undefined,
    undefined
  >['onChange'] = (evt, option): void => {
    if (option) {
      setCurrentModificationCar(option as CarCustomerForm);
    }
  };
  const handleChangeVinCode = (evt: ChangeEvent<HTMLInputElement>): void => {
    const data: CarCustomerForm = {
      id: '',
      name: evt.target.value,
    };
    setCurrentValueVinCode(data);
  };
  const handleCloseModal = () => {
    handleClose();
  };
  const handlerSubmit = async () => {
    if (currentVehicle?.id) {
      const variables: UpdateVehicleMutationVariables = {
        id: currentVehicle?.id || '',
        data: {
          car: currentModificationCar?.id,
          yearOfManufacture: currentYearOfManufactureCar?.year,
          customer: customerId,
        },
        locale: updateSelectLanguage as string,
      };
      if (currentValueVinCode?.name) {
        variables.data.vinCode = currentValueVinCode.name.trim();
      }

      if (validationFields()) {
        try {
          await runUpdateVehicle({ variables });
        } catch (err: unknown) {
          handlerError(err);
        } finally {
          handleCloseModal();
        }
      }
    } else if (validationFields()) {
      try {
        // WBAGB330402182616
        // WBAVP31040VN10627
        const variables: AddVehicleMutationVariables = {
          data: {
            car: currentModificationCar?.id,
            yearOfManufacture: currentYearOfManufactureCar?.year,
            customer: customerId,
            // vinCode: currentValueVinCode?.name || '',
          },
          locale: updateSelectLanguage as string,
        };
        if (currentValueVinCode?.name) {
          variables.data.vinCode = currentValueVinCode.name;
        }

        await runAddVehicle({
          variables,
          refetchQueries: [
            {
              query: GetClientByIdDocument,
              variables: {
                id: customerId,
                pagination: { limit: -1 },
                locale: updateSelectLanguage as string,
              },
            },
          ],
        });
      } catch (err: unknown) {
        handlerError(err);
      } finally {
        handleCloseModal();
      }
    }
  };

  return (
    <CustomModal title={translateLang('add_auto')} handleClose={handleCloseModal} open={open}>
      <Stack component="form" flexGrow={1} spacing={2}>
        <Autocomplete
          loading={loadingBrands}
          disablePortal
          options={brands || []}
          sx={{ minWidth: 60 }}
          renderInput={params => (
            <TextField
              helperText={currentBrandCar?.error}
              error={currentBrandCar?.error}
              {...params}
              label={translateLang('car_brand')}
            />
          )}
          value={currentBrandCar}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          getOptionLabel={option => {
            return option?.name || '';
          }}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.id}>
              {option.name}
            </Box>
          )}
          onChange={handlerChangeBrand}
        />
        <Autocomplete
          loading={loadingModelsGrouped}
          disablePortal
          disabled={!currentBrandCar?.id}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          options={models || []}
          sx={{ minWidth: 60 }}
          renderInput={params => (
            <TextField
              error={currentModelCar?.error}
              {...params}
              label={translateLang('car_model')}
            />
          )}
          value={currentModelCar}
          getOptionLabel={option => {
            return option?.name || '';
          }}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.id}>
              {option.name}
            </Box>
          )}
          onChange={handleChangeModel}
        />
        <Autocomplete
          disablePortal
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          disabled={!currentBrandCar?.id || !currentModelCar?.id}
          options={yearsManufacture || []}
          sx={{ minWidth: 60 }}
          renderInput={params => (
            <TextField
              error={currentYearOfManufactureCar?.error}
              {...params}
              label={translateLang('yearOfManufacture')}
            />
          )}
          value={currentYearOfManufactureCar}
          getOptionLabel={option => {
            return option?.name || '';
          }}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.id}>
              {option.name}
            </Box>
          )}
          onChange={handleChangeYear}
        />
        <Autocomplete
          loading={loadingDataCars}
          disablePortal
          disabled={
            !currentBrandCar?.id || !currentModelCar?.id || !currentYearOfManufactureCar?.id
          }
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          options={modificationsCar}
          sx={{ minWidth: 60 }}
          renderInput={params => (
            <TextField
              error={currentModificationCar?.error}
              {...params}
              label={translateLang('modifications_car')}
            />
          )}
          value={currentModificationCar}
          getOptionLabel={option => {
            return option?.name || '';
          }}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.id}>
              {option.name}
            </Box>
          )}
          onChange={handleChangeModificationCar}
        />

        <Input
          type="Vin Code"
          placeholder={translateLang('VIN-code')}
          label={translateLang('VIN-code')}
          value={currentValueVinCode?.name}
          onChange={handleChangeVinCode}
          error={currentValueVinCode?.error}
          variant={currentValueVinCode?.error ? 'outlined' : 'standard'}
        />

        <Button color="secondary" variant="contained" size="large" onClick={handlerSubmit}>
          <TranslatedField
            originText={currentVehicle?.id ? 'edit  vehicle' : 'add_auto'}
            isTranslate
            color="white"
          />
        </Button>
      </Stack>
    </CustomModal>
  );
};
