import { GetClientsQuery } from '../__generated__/getClients';
import {
  Enum_Nosparepart_Status,
  NoSparePartFiltersInput,
  Pagination,
  PaginationArg,
} from '../../../__generated__/types';

import { useGetNewSparePartsQuery } from '../__generated__/getNoSpareParts';

type UserPermission = NonNullable<GetClientsQuery['usersPermissionsUsers']>['data'][0];

export interface NoSparePartsType {
  id?: UserPermission['id'];
  phoneNumbers?: string;
  vinCode?: string;
  message?: string;
  source?: string;
  status?: Enum_Nosparepart_Status;
  date?: string;
}

export type PaginationType = Omit<Pagination, '__typename'>;

interface ReturnHook {
  noSpareParts: Array<NoSparePartsType>;
  pagination?: PaginationType;
  isLoading?: boolean;
}

interface PropsHook {
  pagination?: PaginationArg;
  filters?: NoSparePartFiltersInput;
}

export const useNoSpareParts = ({ pagination, filters }: PropsHook): ReturnHook => {
  const { data } = useGetNewSparePartsQuery({
    variables: {
      pagination,
      filters,
      sort: ['id:desc'],
    },
  });

  if (data?.noSpareParts?.data) {
    const noSpareParts: Array<NoSparePartsType> = data?.noSpareParts?.data?.map(noParts => {
      return {
        id: noParts.id || '',
        vinCode: noParts.attributes?.vin_code || '',
        phoneNumbers: noParts.attributes?.phone,
        message: noParts.attributes?.message || '',
        status: noParts.attributes?.status || Enum_Nosparepart_Status.Processed,
        date: noParts.attributes?.updatedAt || '',
        source: noParts.attributes?.source || '',
      };
    });
    const pagination: PaginationType = {
      page: data.noSpareParts.meta.pagination.page,
      pageCount: data.noSpareParts.meta.pagination.pageCount,
      pageSize: data.noSpareParts.meta.pagination.pageSize,
      total: data.noSpareParts.meta.pagination.total,
    };
    return { noSpareParts, pagination };
  }
  return { noSpareParts: [] };
};
