import React, { FC } from 'react';
import { Box } from '@mui/material';
import { ReturnedType } from '../../../../graphql/queries/hook/useGetOrderItemsReturnedFromCustomers';
import { useGetReturnedItemsTable } from './hooks/useGetReturnedItemsTable';
import { TableProductActive } from '../../../../components/TableProductActive/TableProductActive';
interface Props {
  returnedItems: Array<ReturnedType>;
  loading?: boolean;
  totalPage?: number;
  currentPage?: number;
  changePage?: (page: number) => void;
}

export const ReturnTabTable: FC<Props> = ({
  returnedItems,
  loading,
  currentPage,
  totalPage,
  changePage,
}) => {
  const { getHeadersTable, getRowsTableActive } = useGetReturnedItemsTable({
    returnedItems,
  });
  return (
    <Box boxSizing="border-box" width="100%">
      <TableProductActive
        startPage={currentPage}
        totalPages={totalPage}
        itemsRows={getRowsTableActive()}
        itemsHeader={getHeadersTable()}
        changeCurrentPage={changePage}
        loading={loading}
      />
    </Box>
  );
};
