import React, { ReactNode } from 'react';

import { useLocalization } from '../../../../../../../localization';
import { TableIncomingInvoiceRow } from '../TableIncomingInvoiceRow';
import { Enum_Incominginvoice_Status, StockEntity } from '../../../../../../../__generated__/types';
import { HeaderCell } from '../../../../../../../components/TableProduct/TableProduct';

interface HookProps {
  items?: StockEntity[];
  invoiceId?: string | null;
  invoiceStatus?: Enum_Incominginvoice_Status | null;
}
interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTableActive(): Array<ReactNode>;
}

export const useGetIncomingInvoiceTable = ({
  items,
  invoiceId,
  invoiceStatus,
}: HookProps): ReturnHook => {
  const { translateLang } = useLocalization();

  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      {
        title: translateLang('article'),
        containerProps: { width: '240px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('productName'),
        containerProps: { width: '240px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('manufacturer'),
        containerProps: { width: '240px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('quantity'),
        containerProps: { width: '240px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('price'),
        containerProps: { width: '240px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: '',
        containerProps: { width: '40px', color: '#f1f3f8', align: 'center' },
      },
    ];
  };

  const getRowsTableActive = (): ReactNode[] => {
    if (items && items?.length) {
      const invoiceIds = items.map(item => item.id);
      return items?.map((item, index) => {
        return (
          <TableIncomingInvoiceRow
            key={index}
            invoiceData={item}
            invoiceIds={invoiceIds}
            invoiceId={invoiceId}
            invoiceStatus={invoiceStatus}
          />
        );
      });
    }
    return [];
  };

  return {
    getHeadersTable,
    getRowsTableActive,
  };
};
