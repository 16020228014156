import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { GetVehicleEntityFragmentDoc } from '../../fragments/__generated__/getVehicleEntity';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type AddVehicleMutationVariables = Types.Exact<{
  data: Types.VehicleInput;
  locale: Types.Scalars['String'];
}>;


export type AddVehicleMutation = { __typename?: 'Mutation', createVehicle?: { __typename?: 'VehicleEntityResponse', data?: { __typename?: 'VehicleEntity', id?: string | null, attributes?: { __typename?: 'Vehicle', yearOfManufacture?: number | null, vinCode?: string | null, car?: { __typename?: 'CarEntityResponse', data?: { __typename?: 'CarEntity', id?: string | null, attributes?: { __typename?: 'Car', engines?: any | null, tecdoc_modelId?: string | null, tecdoc_carId?: string | null, tecdoc_carType?: string | null, tecdoc_manuId?: string | null, cylinderCapacityLiter?: number | null, powerHpTo?: number | null, car_locales?: { __typename?: 'CarLocaleRelationResponseCollection', data: Array<{ __typename?: 'CarLocaleEntity', attributes?: { __typename?: 'CarLocale', name?: string | null, constructionType?: string | null, fuelType?: string | null } | null }> } | null, car_brand?: { __typename?: 'CarBrandEntityResponse', data?: { __typename?: 'CarBrandEntity', id?: string | null, attributes?: { __typename?: 'CarBrand', tecdoc_manuId?: string | null, car_brand_locales?: { __typename?: 'CarBrandLocaleRelationResponseCollection', data: Array<{ __typename?: 'CarBrandLocaleEntity', attributes?: { __typename?: 'CarBrandLocale', name?: string | null } | null }> } | null } | null } | null } | null, car_model?: { __typename?: 'CarModelEntityResponse', data?: { __typename?: 'CarModelEntity', id?: string | null, attributes?: { __typename?: 'CarModel', tecdoc_modelId?: string | null, car_model_locales?: { __typename?: 'CarModelLocaleRelationResponseCollection', data: Array<{ __typename?: 'CarModelLocaleEntity', attributes?: { __typename?: 'CarModelLocale', name?: string | null } | null }> } | null } | null } | null } | null } | null } | null } | null } | null } | null } | null };


export const AddVehicleDocument = gql`
    mutation addVehicle($data: VehicleInput!, $locale: String!) {
  createVehicle(data: $data) {
    data {
      ...getVehicleEntity
    }
  }
}
    ${GetVehicleEntityFragmentDoc}`;
export type AddVehicleMutationFn = Apollo.MutationFunction<AddVehicleMutation, AddVehicleMutationVariables>;

/**
 * __useAddVehicleMutation__
 *
 * To run a mutation, you first call `useAddVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVehicleMutation, { data, loading, error }] = useAddVehicleMutation({
 *   variables: {
 *      data: // value for 'data'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useAddVehicleMutation(baseOptions?: Apollo.MutationHookOptions<AddVehicleMutation, AddVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddVehicleMutation, AddVehicleMutationVariables>(AddVehicleDocument, options);
      }
export type AddVehicleMutationHookResult = ReturnType<typeof useAddVehicleMutation>;
export type AddVehicleMutationResult = Apollo.MutationResult<AddVehicleMutation>;
export type AddVehicleMutationOptions = Apollo.BaseMutationOptions<AddVehicleMutation, AddVehicleMutationVariables>;