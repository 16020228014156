import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';

import { UrlParamsObjectType } from '../../../types/types';
import { OrderFiltersInput } from '../../../__generated__/types';
import { useTableHistory } from './Modals/hooks/useTableHistory';
import { useGetOrders } from '../../../graphql/queries/hook/useGetOrders';
import { TableProductActive } from '../../../components/TableProductActive/TableProductActive';

const PAGE_COUNT = 4;
interface Props {
  statusFilter: OrderFiltersInput | undefined;
}

export const HistoryTable: FC<Props> = ({ statusFilter }) => {
  const { idUser = '' } = useParams<UrlParamsObjectType>();
  const [page, setPage] = useState(0);
  const { orders, pagination, loadingOrders } = useGetOrders({
    pagination: {
      page: page + 1,
      pageSize: PAGE_COUNT,
    },
    filters: getFiltersOrders(),
  });
  const { getHeadersTable, getRowsTableActive } = useTableHistory({ orders });

  const changePageNumber = (val: number) => {
    setPage(val);
  };

  function getFiltersOrders(): OrderFiltersInput | undefined {
    return {
      ...statusFilter,
      customer: {
        id: {
          eq: idUser,
        },
      },
    };
  }

  useEffect(() => {
    setPage(0);
  }, [statusFilter]);

  return (
    <Box>
      <Box pt={2} boxSizing="border-box">
        <TableProductActive
          itemsHeader={getHeadersTable()}
          itemsRows={getRowsTableActive()}
          totalPages={pagination?.total ? Math.ceil(pagination.total / PAGE_COUNT) : 0}
          startPage={pagination?.page || 0}
          changeCurrentPage={changePageNumber}
          loading={loadingOrders}
        />
      </Box>
    </Box>
  );
};
