import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { addDays, endOfToday, format, startOfDay, subDays } from 'date-fns';

import {
  IncomingInvoiceFiltersInput,
  SupplierReturnFiltersInput,
} from '../../../../__generated__/types';
import { ToolbarInvoicesTab } from './ToolbarInvoicesTab';
import { PAGE_COUNT } from '../../../../helpers/constants';
import { IncomingInvoicesTabTable } from './IncomingInvoicesTabTable';
import { IncomingInvoice } from './components/IncomingInvoice/IncomingInvoice';
import { useGetIncomingInvoices } from '../../../../graphql/queries/hook/useGetIncomingInvoices';
import { useFiltersSearchParamsForOrders } from '../../../../hooks/useFiltersSearchParamsForOrders';
import { SupplierReturnsTable } from '../../../SupplierInfo/components/SupplierReturns/SupplierReturnsTable';
import { useGetSupplierReturns } from '../../../SupplierInfo/components/SupplierReturns/hooks/useGetSupplierReturns';
import { ReturnInvoice } from '../../../SupplierInfo/components/SupplierReturns/component/ReturnInvoice/ReturnInvoice';

interface Props {
  idSupplier: string;
  refetchSupplierBalance: () => void;
}

export const IncomingInvoicesTab: FC<Props> = ({ idSupplier, refetchSupplierBalance }) => {
  const { selDateTo, selDateFrom } = useFiltersSearchParamsForOrders({
    dateFrom: format(addDays(new Date(), -30), 'yyyy-MM-dd'),
    dateTo: format(new Date(), 'yyyy-MM-dd'),
  });

  const {
    data: incomingInvoicesData,
    loading,
    changePage,
    page,
    totalPage,
    refetch: refetchIncomingInvoices,
  } = useGetIncomingInvoices({ filters: getFiltersOrders(idSupplier) });
  const [activeInvoicesTab, setActiveInvoicesTab] = useState<'incomingInvoices' | 'returnInvoices'>(
    'incomingInvoices'
  );

  const { supplierReturns, pagination, isLoading } = useGetSupplierReturns({
    pagination: {
      page,
      pageSize: PAGE_COUNT,
    },
    filters: getSupplierReturnFilters(),
  });

  const [isInvoiceTable, setIsInvoiceTable] = useState(false);
  const [isReturnInvoiceTable, setIsReturnInvoiceTable] = useState(false);

  const [invoiceId, setInvoiceId] = useState('');
  const [returnInvoiceId, setReturnInvoiceId] = useState('');

  const toggleInvoiceTable = (id: string) => {
    setIsReturnInvoiceTable(false);
    setIsInvoiceTable(!isInvoiceTable);
    setInvoiceId(id);
  };

  const toggleReturnInvoiceTable = (id: string) => {
    setIsInvoiceTable(false);
    setIsReturnInvoiceTable(!isReturnInvoiceTable);
    setReturnInvoiceId(id);
  };

  const handleActiveInvoicesTab = (type: 'incomingInvoices' | 'returnInvoices') => {
    setActiveInvoicesTab(type);
  };

  function getFiltersOrders(idSupplier: string): IncomingInvoiceFiltersInput {
    const filters: IncomingInvoiceFiltersInput = {
      date: {
        between: [
          format(startOfDay(subDays(new Date(), 30)), 'yyyy-MM-dd'),
          format(endOfToday(), 'yyyy-MM-dd'),
        ],
      },
      supplier: {
        id: {
          eq: idSupplier,
        },
      },
    };

    if (selDateTo && selDateFrom) {
      filters['date'] = {
        between: [selDateFrom, selDateTo],
      };
    }
    return filters;
  }

  function getSupplierReturnFilters(): SupplierReturnFiltersInput {
    const filters: SupplierReturnFiltersInput = {
      id: { eq: idSupplier },
      dateReturned: { between: [startOfDay(subDays(new Date(), 30)), endOfToday()] },
    };

    if (selDateTo && selDateFrom) {
      filters['dateReturned'] = {
        between: [selDateFrom, selDateTo],
      };
    }
    return filters;
  }

  const supplierName =
    incomingInvoicesData?.incomingInvoices?.data[0]?.attributes?.supplier?.data?.attributes?.name;

  return (
    <>
      {!isInvoiceTable && !isReturnInvoiceTable ? (
        <Stack width="100%">
          <ToolbarInvoicesTab
            supplier={supplierName}
            activeInvoicesTab={activeInvoicesTab}
            handleActiveInvoicesTab={handleActiveInvoicesTab}
            refetchIncomingInvoices={refetchIncomingInvoices}
          />
          {activeInvoicesTab === 'incomingInvoices' ? (
            <IncomingInvoicesTabTable
              loading={loading}
              currentPage={page}
              totalPage={totalPage}
              items={incomingInvoicesData?.incomingInvoices?.data}
              changePage={changePage}
              toggleInvoiceTable={toggleInvoiceTable}
              refetchSupplierBalance={refetchSupplierBalance}
            />
          ) : (
            <SupplierReturnsTable
              loading={isLoading}
              currentPage={page}
              supplierReturns={supplierReturns}
              totalPage={pagination?.pageCount}
              changePage={changePage}
              toggleReturnInvoiceTable={toggleReturnInvoiceTable}
            />
          )}
        </Stack>
      ) : isInvoiceTable ? (
        <IncomingInvoice
          invoiceId={invoiceId}
          toggleInvoiceTable={toggleInvoiceTable}
          idSupplier={idSupplier}
          refetchIncomingInvoices={refetchIncomingInvoices}
        />
      ) : (
        <ReturnInvoice
          returnInvoiceId={returnInvoiceId}
          toggleReturnInvoiceTable={toggleReturnInvoiceTable}
        />
      )}
    </>
  );
};
