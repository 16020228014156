import React, { BaseSyntheticEvent, FC, useState } from 'react';
import Image from 'mui-image';
import { Box, Button, CircularProgress, IconButton, Stack } from '@mui/material';
import { Add } from '@mui/icons-material';

import { TranslatedField } from '../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { theme } from '../../../../../helpers';
import { Icon } from '../../../../../legos';
import { useUploadImageFileMutation } from '../../../../../graphql/mutations/__generated__/uploadImageFile';
import { handlerError } from '../../../../../helpers/functions';
import { resizeFile } from '../../../helpers/reziser';
import { GeneralSpareInputType } from '../types';
import { useLocalization } from '../../../../../localization';
import { useRemoveImageFileMutation } from '../../../../../graphql/mutations/__generated__/removeImageFile';
import { useHandlerNotificationApp } from '../../../../../hooks/useHandlerNotificationApp';

interface ProductPhotoProps {
  imagesSrc: any | null;
  productId: string | null;
  productCode: string | null;
  titleEN?: string;
  titleDE?: string;
  isEditable?: boolean;
  brandCode: {
    brandName: string;
    brandId: string;
    id: string;
  };
  handleAddEditProduct: (values: GeneralSpareInputType, productId: string | null) => void;
}

export const ProductPhoto: FC<ProductPhotoProps> = ({
  imagesSrc,
  productId,
  productCode,
  brandCode,
  titleEN,
  titleDE,
  isEditable,
  handleAddEditProduct,
}) => {
  const { translateLang } = useLocalization();
  const [loadImage, setLoadImage] = useState(false);
  const { addNotification } = useHandlerNotificationApp();
  const [uploadImageFileMutation] = useUploadImageFileMutation();
  const [removeImageFileMutation] = useRemoveImageFileMutation();

  const handleUploadImage = async (evt: BaseSyntheticEvent) => {
    setLoadImage(true);
    const file = evt.target.files[0];
    if (file && productCode) {
      try {
        const image200 = await resizeFile(file, 200, 200, 50);
        const image400 = await resizeFile(file, 400, 400, 80);
        const image1600 = await resizeFile(file, 1600, 1600, 100);

        const uploadImagesPromises = [image200, image400, image1600].map(async imageItem => {
          try {
            const uploadResponse = await uploadImageFileMutation({
              variables: {
                file: imageItem,
              },
            });

            return uploadResponse?.data?.upload?.data || {};
          } catch (error: any) {
            addNotification({
              messageError: error?.message,
              typeMessage: 'error',
            });

            return false;
          }
        });

        const uploadedImages = await Promise.all(uploadImagesPromises);
        await handleAddEditProduct(
          {
            productCode,
            brandCode,
            titleEN,
            titleDE,
            images: [
              {
                ...(uploadedImages[0]
                  ? {
                      imageID200: uploadedImages[0].id,
                      imageURL200: uploadedImages[0].attributes?.url,
                    }
                  : null),
                ...(uploadedImages[1]
                  ? {
                      imageID400: uploadedImages[1]?.id,
                      imageURL400: uploadedImages[1]?.attributes?.url,
                    }
                  : null),
                ...(uploadedImages[2]
                  ? {
                      imageID1600: uploadedImages[2]?.id,
                      imageURL1600: uploadedImages[2]?.attributes?.url,
                    }
                  : null),
              },
            ],
          },
          productId
        );
      } catch (err: unknown) {
        handlerError(err);
      } finally {
        setLoadImage(false);
      }
    }
    setLoadImage(false);
  };

  const handleDeleteImage = async (image: any) => {
    setLoadImage(true);
    const imagesIds = [image[0].imageID200, image[0].imageID400, image[0].imageID1600];
    try {
      const deleteImagesPromises = imagesIds.map(async item => {
        try {
          const imageResponse = await removeImageFileMutation({
            variables: {
              id: item,
            },
          });

          return imageResponse?.data?.removeFile?.data?.id || false;
        } catch (error: any) {
          addNotification({
            messageError: error?.message,
            typeMessage: 'error',
          });

          return false;
        }
      });

      await Promise.all(deleteImagesPromises);

      if (productCode) {
        await handleAddEditProduct(
          {
            productCode,
            brandCode,
            images: [],
          },
          productId
        );
      }
    } catch (err: unknown) {
      handlerError(err);
    } finally {
      setLoadImage(false);
    }
    setLoadImage(false);
  };

  const disableButton = !productCode || !brandCode || !titleEN || !titleDE || !isEditable;
  return (
    <Stack
      width="100%"
      height={300}
      borderRadius="10px"
      bgcolor="#F1F3F8"
      alignItems="center"
      justifyContent="center"
      position="relative"
      overflow="hidden"
    >
      {imagesSrc?.length ? <Image src={imagesSrc?.[0].imageURL400} alt="Uploaded photo" /> : null}
      <Box
        position="absolute"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={2}
        top={0}
        left={0}
        width="100%"
        height="100%"
      >
        <Box>
          <Button
            variant="contained"
            color="secondary"
            disabled={!!disableButton}
            style={{
              textTransform: 'none',
              minWidth: '156px',
              height: '38px',
            }}
          >
            {!loadImage && (
              <>
                <Add style={{ color: theme.palette.common.white }} />
                <TranslatedField
                  variant="h6"
                  capitalLetter
                  originText={imagesSrc?.length ? 'replaceImg' : 'addPhoto'}
                  isTranslate
                  noWrap
                  color="white"
                />
              </>
            )}
            {loadImage && <CircularProgress size={20} sx={{ color: theme.palette.common.white }} />}
            <input
              style={{
                position: 'absolute',
                opacity: 0,
                cursor: 'pointer',
                width: '100%',
                height: '100%',
              }}
              type="file"
              onChange={handleUploadImage}
            />
          </Button>
          {imagesSrc?.length ? (
            <IconButton
              color="primary"
              onClick={() => handleDeleteImage(imagesSrc)}
              disabled={!!disableButton || loadImage}
            >
              <Icon icon="deleting" size="medium" />
            </IconButton>
          ) : null}
        </Box>
        <Box>
          <TranslatedField
            variant="h6"
            textAlign="center"
            color={theme.palette.common.black}
            originText={translateLang('minPhotoSize')}
          />
        </Box>
      </Box>
    </Stack>
  );
};
