import React, { FC, useEffect, useMemo } from 'react';
import { Box, Stack } from '@mui/material';

import {
  OrderFilters,
  useGetAllOrdersAccounting,
} from '../../../../../graphql/queries/hook/useGetAllOrdersAccounting';
import { AllOrdersTable } from './AllOrdersTable';
import { AllOrdersToolbar } from './AllOrdersToolbar';
import { useLocalization } from '../../../../../localization';
import { SearchField } from '../../../../../components/SearchField';
import { getClientsSearchFilter, isNumber } from '../../../../../helpers/functions';
import { useFiltersSearchParamsForOrders } from '../../../../../hooks/useFiltersSearchParamsForOrders';
import { addDays, format, isValid, parse } from 'date-fns';
import { HelpSearchOrder } from '../../../../../components/HelpSearchOrder';

interface Props {
  idUser?: string;
}

export const AllOrdersTab: FC<Props> = ({ idUser }) => {
  const { translateLang } = useLocalization();
  const { changeSearchText, searchText, selStatus, selStatusPayment, selDateTo, selDateFrom } =
    useFiltersSearchParamsForOrders({
      dateFrom: format(addDays(new Date(), -30), 'yyyy-MM-dd'),
      dateTo: format(new Date(), 'yyyy-MM-dd'),
    });

  const getFiltersOrders: OrderFilters | undefined = useMemo(() => {
    const filters: OrderFilters | undefined = { and: [] };

    if (idUser) {
      filters.and?.push({
        customer: {
          id: { eq: idUser },
        },
      });
    }

    if (selStatusPayment) {
      if (selStatusPayment === 'paid') {
        filters.and?.push({
          isFullPaid: {
            eq: true,
          },
        });
      } else {
        filters.and?.push({
          or: [
            {
              isFullPaid: {
                eq: false,
              },
            },
            {
              isFullPaid: {
                null: true,
              },
            },
          ],
        });
      }
    }

    if (selStatus) {
      filters.and?.push({
        status: {
          eq: selStatus,
        },
      });
    }

    if (
      selDateTo &&
      selDateFrom &&
      isValid(parse(selDateFrom, 'yyyy-MM-dd', new Date())) &&
      isValid(parse(selDateTo, 'yyyy-MM-dd', new Date()))
    ) {
      filters.and?.push({
        date: {
          between: [selDateFrom, selDateTo],
        },
      });
    }
    const text = getClientsSearchFilter(searchText);

    if (text?.searchNumbers) {
      filters['searchNumbers'] = text.searchNumbers;
    }

    if (text?.searchStrings) {
      filters['searchStrings'] = text.searchStrings;
    }

    return Object.keys(filters).length > 0 ? filters : undefined;
  }, [idUser, searchText, selDateFrom, selDateTo, selStatus, selStatusPayment]);

  const { allOrdersAccounting, page, totalPage, changePage, loading } = useGetAllOrdersAccounting({
    filters: getFiltersOrders,
    sort: isNumber(searchText)
      ? 'orderNumber:desc'
      : searchText
      ? 'customer.customer_contact_info.firstName'
      : '',
  });

  useEffect(() => {
    changePage(0);
  }, [getFiltersOrders, changePage]);

  return (
    <Box width="100%">
      <AllOrdersToolbar />
      <Box width="100%">
        <Stack flexGrow={1}>
          <SearchField
            initStateText={searchText}
            executeWithDelay={changeSearchText}
            placeholder={translateLang('searchOrdersAccountingLabel')}
            endAdornment={<HelpSearchOrder />}
          />
        </Stack>
        <AllOrdersTable
          allOrdersAccounting={allOrdersAccounting}
          filters={getFiltersOrders}
          totalPage={totalPage}
          changePage={changePage}
          currentPage={page}
          loading={loading}
        />
      </Box>
    </Box>
  );
};
