import React, { useState } from 'react';
import { Stack } from '@mui/material';

import { ReturnTabTable } from './ReturnTabTable';
import { ToolBarReturnTab } from './ToolBarReturnTab';
import { useLocalization } from '../../../../localization';
import { SearchField } from '../../../../components/SearchField';
import { StockReturnedFromCustomerFiltersInput } from '../../../../__generated__/types';
import { useGetOrderItemsReturnedFromCustomers } from '../../../../graphql/queries/hook/useGetOrderItemsReturnedFromCustomers';

export const ReturnTab = () => {
  const { translateLang } = useLocalization();
  const [filter, setFilter] = useState<StockReturnedFromCustomerFiltersInput>({});
  const { returnedItems, isLoading, changePage, page, totalPage, total } =
    useGetOrderItemsReturnedFromCustomers({
      filters: filter,
    });

  const changeSearchFilter = (text: string) => {
    changePage(0);
    if (text) {
      setFilter(oldFilter => {
        return {
          ...oldFilter,
          or: [
            {
              order_item: {
                car_spare: {
                  or: [
                    { tecdoc_articleNumber: { containsi: text } },
                    { car_spare_locales: { title: { containsi: text } } },
                  ],
                },
              },
            },
          ],
        };
      });
    } else {
      const _filter = { ...filter };
      delete _filter?.or;
      setFilter(_filter);
    }
  };

  return (
    <Stack width="100%" paddingBottom={10}>
      <SearchField
        executeWithDelay={changeSearchFilter}
        placeholder={translateLang('searchByNameArticle')}
      />
      <ToolBarReturnTab result={total} setFilterItems={setFilter} changePage={changePage} />
      <ReturnTabTable
        returnedItems={returnedItems}
        loading={isLoading}
        changePage={changePage}
        currentPage={page}
        totalPage={totalPage}
      />
    </Stack>
  );
};
