import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useGetMe } from '../../../graphql/queries/hook/useGetMe';
import { Stack } from '@mui/material';

interface Props {
  open: boolean;
  path: string;
  handleDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

export const MobileAppBar: FC<Props> = ({ open, handleDrawerOpen, path }) => {
  const [auth] = useState(true);
  const { firstName, lastName } = useGetMe();

  return (
    <AppBar position="static" sx={{ backgroundColor: '#FFFFFF', boxShadow: 'none' }}>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => handleDrawerOpen(true)}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
        {auth && (
          <Stack direction="row" alignItems="center">
            <IconButton size="large" aria-controls="menu-appbar" aria-haspopup="true">
              <AccountCircle />
            </IconButton>
            <Typography color="#1A1A1A" fontSize="16px">{`${firstName} ${lastName}`}</Typography>
          </Stack>
        )}
      </Toolbar>
    </AppBar>
  );
};
