import { useLocalization } from '../localization';
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek, subMonths, subWeeks } from 'date-fns';

interface DefaultRangeDatesItem {
  label: string;
  value: [Date, Date];
}

interface ReturnHook {
  reportRangeDates: DefaultRangeDatesItem[];
}

export const useDefaultRangeCalendar = (): ReturnHook => {
  const { translateLang } = useLocalization();
  const reportRangeDates: DefaultRangeDatesItem[] = [
    {
      label: translateLang('currentWeek'),
      value: [
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        endOfWeek(new Date(), { weekStartsOn: 1 }),
      ],
    },
    {
      label: translateLang('lastWeek'),
      value: [
        startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }),
        endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }),
      ],
    },
    {
      label: translateLang('currentMonth'),
      value: [startOfMonth(new Date()), endOfMonth(new Date())],
    },
    {
      label: translateLang('lastMonth'),
      value: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
    },
  ];
  return {
    reportRangeDates,
  };
};
