import { endOfToday, startOfMonth } from 'date-fns';

import {
  Enum_Supplierreturn_Status,
  Pagination,
  PaginationArg,
  SupplierReturnFiltersInput,
} from '../../../../../__generated__/types';
import { useGetSupplierReturnsQuery } from '../../../../../graphql/queries/__generated__/getSupplierReturns';

export interface ReturnType {
  id?: string | null;
  correctiveInvoiceDate: any;
  correctiveInvoiceNumber?: string | null;
  dateOfReceiving: any;
  dateReturned: any;
  status?: Enum_Supplierreturn_Status | null;
}

export type PaginationTransactionsType = Omit<Pagination, '__typename'>;

interface ReturnHook {
  supplierReturns: Array<ReturnType>;
  pagination?: PaginationTransactionsType;
  isLoading?: boolean;
  refetchSupplierReturns: () => void;
}

export type FiltersType = {
  searchStrings?: string;
  searchNumbers?: string;
};
interface PropsHook {
  pagination?: PaginationArg;
  filters: SupplierReturnFiltersInput;
}

export const useGetSupplierReturns = ({ pagination, filters }: PropsHook): ReturnHook => {
  const {
    data: supplierReturnsData,
    loading: supplierReturnsLoading,
    refetch: refetchSupplierReturns,
  } = useGetSupplierReturnsQuery({
    variables: {
      filters: {
        ...(filters.dateReturned
          ? {
              createdAt: filters.createdAt,
            }
          : {
              createdAt: {
                between: [startOfMonth(new Date()), endOfToday()],
              },
            }),
        supplier: { id: filters.id },
      },
      pagination,
      sort: ['createdAt:desc'],
    },
  });

  if (supplierReturnsData?.supplierReturns?.data) {
    const returnsData = supplierReturnsData?.supplierReturns.data;
    const supplierReturns = returnsData.map(item => {
      return {
        id: item.id,
        dateOfReceiving:
          item?.attributes?.supplier_return_items?.data[0]?.attributes?.stock?.data?.attributes
            ?.incoming_invoice?.data?.attributes?.supplierInvoiceDate,
        correctiveInvoiceDate: item?.attributes?.correctiveInvoiceDate,
        correctiveInvoiceNumber: item.attributes?.correctiveInvoiceNumber,
        dateReturned: item.attributes?.dateReturned,
        status: item.attributes?.status,
      };
    });
    const pagination: PaginationTransactionsType = {
      page: supplierReturnsData?.supplierReturns.meta.pagination.page,
      pageCount: supplierReturnsData?.supplierReturns.meta.pagination.pageCount,
      pageSize: supplierReturnsData?.supplierReturns.meta.pagination.pageSize,
      total: supplierReturnsData?.supplierReturns.meta.pagination.total,
    };
    return {
      supplierReturns,
      pagination,
      isLoading: supplierReturnsLoading,
      refetchSupplierReturns,
    };
  }

  return { supplierReturns: [], isLoading: supplierReturnsLoading, refetchSupplierReturns };
};
