import { Stack, Typography, TextField, Button } from '@mui/material';
import React, { FC, useRef, useState } from 'react';
import { TranslatedField } from '../../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useLocalization } from '../../../../../../localization';

interface Props {
  setResult: React.Dispatch<React.SetStateAction<string>>;
  changeMode: (eanNumber: string) => void;
}
export const ManualSearch: FC<Props> = ({ setResult, changeMode }) => {
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { translateLang } = useLocalization();

  const handleChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setInputValue(event.target.value);
  };
  const handleSubmit = () => {
    changeMode(inputValue);
  };
  return (
    <Stack>
      <Typography sx={{ fontWeight: 400, fontSize: '26px', lineHeight: '45px', mb: '60px' }}>
        {translateLang('codeManually')}
      </Typography>
      <TextField
        value={inputValue}
        onChange={handleChange}
        variant="standard"
        autoComplete="off"
        InputProps={{
          disableUnderline: true,
        }}
        InputLabelProps={{
          shrink: true,
          style: {
            color: '#2A3453',
            fontSize: '14px',
            fontWeight: '500',
            height: '17px',
            lineHeight: '17px',
          },
        }}
        sx={{
          pt: '100px',
          color: '#FFFFFF',
          paddingLeft: '16px',
          paddingRight: '16px',
          paddingTop: '14px',
          paddingBottom: '14px',
          backgroundColor: '#FFFFFF',
          borderRadius: '6px',
          mx: '10px',
        }}
      />
      {inputValue.length ? (
        <Button
          color="primary"
          variant="outlined"
          onClick={handleSubmit}
          sx={{
            width: '95%',
            textTransform: 'none',
            alignSelf: 'center',
            marginTop: '35px',
            borderRadius: '10px',
            border: '2px solid',
          }}
        >
          <TranslatedField
            noWrap
            isTranslate
            fontSize={16}
            color="black"
            originText="enterManual"
          />
        </Button>
      ) : null}
    </Stack>
  );
};
