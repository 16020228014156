import React, { ReactNode } from 'react';
import { useLocalization } from '../../../../../localization';
import { HeaderCell } from '../../../../../components/TableProductActive/TableProductActive';
import { AllOrdersAccountingRowItem } from '../components/tablesComponents/AllOrdersAccountingRowItem';
import { AllOrdersAccountingType } from '../../../../../graphql/queries/hook/useGetAllOrdersAccounting';
import { OrderFiltersInput } from '../../../../../__generated__/types';

interface HookProps {
  allOrdersAccounting?: AllOrdersAccountingType[];
  filters?: OrderFiltersInput;
}
interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTableActive(): Array<ReactNode>;
}

export const useGetAllOrdersAccountingTable = ({
  allOrdersAccounting,
  filters,
}: HookProps): ReturnHook => {
  const { translateLang } = useLocalization();
  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      {
        title: translateLang('numberOrder'),
        containerProps: { width: '40px', color: '#F1F3F8', align: 'center' },
      },
      {
        title: translateLang('orderDate'),
        containerProps: { width: '60px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('customer'),
        containerProps: { width: '200px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('status'),
        containerProps: { width: '60px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('orderAmount'),
        containerProps: { width: '80px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('debtAmount'),
        containerProps: { width: '80px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('statusPayment'),
        containerProps: { width: '80px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('invoiceBill'),
        containerProps: { width: '60px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang(''),
        containerProps: { width: '100px', color: '#f1f3f8' },
      },
    ];
  };

  const getRowsTableActive = (): ReactNode[] => {
    if (allOrdersAccounting && allOrdersAccounting?.length) {
      return allOrdersAccounting?.map(item => {
        return (
          <AllOrdersAccountingRowItem
            key={`order-${item.orderId}-${item.orderNumber}`}
            order={item}
            filters={filters}
          />
        );
      });
    }
    return [];
  };

  return {
    getHeadersTable,
    getRowsTableActive,
  };
};
