import React from 'react';
import { Stack, Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { TabsBar } from './components/TabsBar';
import { useLocalization } from '../../localization';
import { SuppliersTab } from './components/Suppliers/SuppliersTab';
import { WrapperPages } from '../../components/Wrapper/WrapperPages';
import { AllOrdersTab } from './components/AllOrders/components/AllOrdersTab';
import { AllClientsTab } from './components/AllClients/components/AllClientsTab';

export const AccountingPage = () => {
  const { translateLang } = useLocalization();
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTable = searchParams.get('tab') ?? 'orders';

  const handleChange = (val: string | undefined) => {
    if (val && searchParams.get('tab') !== val) {
      setSearchParams({ tab: val });
    }
  };

  const getActiveTable = () => {
    switch (searchParams.get('tab')) {
      case 'orders':
        return <AllOrdersTab />;
      case 'clients':
        return <AllClientsTab />;
      case 'providers':
        return <SuppliersTab />;
      default:
        return <AllOrdersTab />;
    }
  };

  return (
    <WrapperPages isButton={false} titleHeader={translateLang('accounting')}>
      <Stack spacing={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          height="48px"
          marginBottom="30px"
          flexWrap="wrap"
          spacing={3}
        >
          <TabsBar activeTable={activeTable} handleChange={handleChange} />
        </Stack>
        <Stack>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              placeContent: 'center',
            }}
          >
            {getActiveTable()}
          </Box>
        </Stack>
      </Stack>
    </WrapperPages>
  );
};
