import React, { ReactNode } from 'react';
import { useLocalization } from '../../../../../localization';
import { OrdersType } from '../../../../../graphql/queries/hook/useGetOrders';
import { TableRowHistoryItem } from '../../tableComponents/TableRowHistoryItem';
import { HeaderCell } from '../../../../../components/TableProduct/TableProduct';

interface HookProps {
  orders: OrdersType[] | undefined;
}

interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTableActive(): Array<ReactNode>;
}

export const useTableHistory = ({ orders }: HookProps): ReturnHook => {
  const { translateLang } = useLocalization();

  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      {
        title: translateLang('numberOrder'),
        containerProps: { width: '66px', align: 'center', color: '#F1F3F8' },
      },
      { title: translateLang('orderDate'), containerProps: { align: 'center', color: '#F1F3F8' } },
      { title: translateLang('manager'), containerProps: { align: 'center', color: '#F1F3F8' } },
      { title: translateLang('status'), containerProps: { align: 'center', color: '#F1F3F8' } },
      { title: translateLang('sum'), containerProps: { align: 'center', color: '#F1F3F8' } },
      {
        title: translateLang(''),
        containerProps: { width: '66px', align: 'center', color: '#F1F3F8' },
      },
    ];
  };

  const getRowsTableActive = (): ReactNode[] => {
    if (orders && orders?.length) {
      return orders?.map((item, index) => {
        let managers: string[] = [];
        if (item?.managerCredential?.length) {
          managers = item.managerCredential.map(i => `${i?.lastName ?? ''} ${i?.firstName ?? ''}`);
        }
        return (
          <TableRowHistoryItem
            key={index.toString()}
            numberOfLine={index + 1}
            date={new Date(item.date ?? '')}
            manager={managers ?? []}
            orderID={item.id ?? ''}
            orderNumber={item.orderNumber ?? ''}
            price={item.total ?? 0}
            status={translateLang(item.status ?? '')}
          />
        );
      });
    }
    return [];
  };

  return {
    getHeadersTable,
    getRowsTableActive,
  };
};
