import React, { ReactNode } from 'react';
import { useLocalization } from '../../../../../localization';

import { HeaderCell } from '../../../../../components/TableProductActive/TableProductActive';
import { TableWarehouseStocksRowItem } from '../components/tablesComponents/TableWarehouseStocksRowItem';

import { ItemWarehouseType } from '../../../../../graphql/queries/hook/useGetWarehouse';

interface HookProps {
  itemsWarehouse?: ItemWarehouseType[];
}
interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTableActive(): Array<ReactNode>;
}

export const useGetWarehouseTabTable = ({ itemsWarehouse }: HookProps): ReturnHook => {
  const { translateLang } = useLocalization();

  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      {
        title: translateLang('№'),
        containerProps: { width: '56px', color: '#F1F3F8', align: 'center' },
      },
      {
        title: translateLang('article'),
        containerProps: { width: '150px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('provider'),
        containerProps: { width: '240px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('productName'),
        containerProps: { width: '400px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('incomingQuantity'),
        containerProps: { width: '40px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('reserved'),
        containerProps: { width: '40px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('quantity'),
        containerProps: { width: '40px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('place'),
        containerProps: { width: '880px', color: '#f1f3f8', align: 'left' },
      },
    ];
  };

  const getRowsTableActive = (): ReactNode[] => {
    if (itemsWarehouse && itemsWarehouse?.length) {
      return itemsWarehouse?.map((item, index) => {
        return (
          <TableWarehouseStocksRowItem
            key={index}
            index={index}
            article={item.article}
            supplier={item.supplier}
            productName={item.productName}
            productStockId={item.productStockId}
            reservedQuantity={item.reservedQuantity}
            balanceQuantity={item.balanceQuantity}
            incomingQuantity={item.incomingQuantity}
            stockAddressOrder={item.stockAddressOrder}
          />
        );
      });
    }
    return [];
  };

  return {
    getHeadersTable,
    getRowsTableActive,
  };
};
