import React, { FC } from 'react';
import { Stack, Typography, useMediaQuery } from '@mui/material';

interface Props {
  characteristic: string | number;
  value: string | number;
  bgColor?: string;
}

export const CharacteristicsItem: FC<Props> = ({ characteristic, value, bgColor }) => {
  return (
    <>
      {characteristic && value ? (
        <Stack
          minHeight="66px"
          flexDirection={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          alignItems={{ xs: 'start', sm: 'center' }}
          px={{ xs: 1.5, sm: 2.5 }}
          bgcolor={bgColor}
          borderRadius="5px"
        >
          <Typography
            variant={'body1'}
            letterSpacing={{ xs: 'unset', sm: '0.01em' }}
            whiteSpace="nowrap"
          >
            {characteristic}:
          </Typography>

          <hr
            style={{
              minWidth: '30px',
              width: '100%',
              height: '1px !important',
              margin: '12px 5px 0 5px',
              borderTop: `dashed 1px `,
            }}
          />

          <Typography
            variant={'body1'}
            letterSpacing={{ xs: 'unset', sm: '0.01em' }}
            whiteSpace="nowrap"
          >
            {value}
          </Typography>
        </Stack>
      ) : null}
    </>
  );
};
