import React, { useState } from 'react';
import { Stack, Typography, Autocomplete, TextField, IconButton, Box, Button } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';

import { useLocalization } from '../../../../../localization';
import { FieldArray, useFormikContext, getIn } from 'formik';
import { Input } from '../../../../../legos';
import { theme } from '../../../../../helpers';
import { GeneralSpareInputType, ProductField } from '../types';
import { TranslatedField } from '../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useGetBrandsQuery } from '../../../../../graphql/queries/__generated__/getBrands';
import { AddNewBrandForm } from './AddNewBrandForm';

export const ProductDescriptionForm = ({ isEditable }: { isEditable?: boolean }) => {
  const { translateLang, updateSelectLanguage } = useLocalization();
  const { values, errors, touched, handleChange, setFieldValue } =
    useFormikContext<GeneralSpareInputType>();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const {
    data: brandsData,
    loading: loadingBrands,
    refetch: refetchBrands,
  } = useGetBrandsQuery({
    variables: { locale: updateSelectLanguage, pagination: { limit: -1 }, sort: ['brandName'] },
  });

  const brands = brandsData?.carSpareBrands?.data.map(item => ({
    brandName: item.attributes?.brandName,
    brandId: item.attributes?.brandId,
    id: item.id,
  }));

  const handleToggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return (
    <>
      <Stack
        ml={{ xs: 0, lg: 4 }}
        mt={{ xs: 4, lg: 0 }}
        pb={3.75}
        mb={3.75}
        gap={2.5}
        borderBottom="0.5px solid #B2C0E5"
      >
        <Stack pb={3.75} mb={3.75} borderBottom="0.5px solid #B2C0E5">
          <Stack flexDirection={'row'} gap={2.5}>
            <Stack width={{ xs: '100%', md: '50%' }}>
              <Input
                disabled={!isEditable}
                name={ProductField.ProductCode}
                labelColor={theme.palette.primary.light}
                label={translateLang('productCode')}
                value={values[ProductField.ProductCode]}
                onChange={handleChange}
                error={touched[ProductField.ProductCode] && !!errors[ProductField.ProductCode]}
                helperText={touched[ProductField.ProductCode] && errors[ProductField.ProductCode]}
                sx={{
                  marginTop: 0.5,
                }}
              />
            </Stack>
            <Stack width={{ xs: '100%', md: '50%' }}>
              <Typography mb="4px" fontSize="10px" color="#2A3453">
                {translateLang('brandCode')}
              </Typography>
              <Autocomplete
                disabled={!isEditable}
                loading={loadingBrands}
                disablePortal
                options={[{ brandName: '', brandId: '', id: '' }, ...(brands || [])]}
                sx={{
                  minWidth: 60,

                  '& .MuiOutlinedInput-root': {
                    py: 0.75,
                    pl: 1.5,
                    color: '#7A828A',
                  },
                  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
                renderInput={params => (
                  <TextField
                    error={touched[ProductField.BrandCode] && !!errors[ProductField.BrandCode]}
                    helperText={
                      touched[ProductField.BrandCode]?.brandId &&
                      errors[ProductField.BrandCode]?.brandId
                    }
                    {...params}
                    sx={{
                      '& .MuiInputBase-root': {
                        bgcolor: '#F1F3F8',
                      },
                    }}
                    FormHelperTextProps={{ sx: { ml: 0, mt: '3px' } }}
                  />
                )}
                value={values[ProductField.BrandCode]}
                isOptionEqualToValue={(option, value) => {
                  return option.brandId === value.brandId;
                }}
                getOptionLabel={option => {
                  return option.brandName ? `${option.brandName} (${option.brandId})` : '';
                }}
                renderOption={(props, option) =>
                  option.brandId ? (
                    <Box component="li" {...props} key={`brand-${option.id}`}>
                      {`${option.brandName} (${option.brandId})`}
                    </Box>
                  ) : null
                }
                onChange={(_, value) => {
                  setFieldValue(ProductField.BrandCode, value);
                  setFieldValue(ProductField.BrandNameDE, value?.brandName);
                  setFieldValue(ProductField.BrandNameENG, value?.brandName);
                }}
              />
              {isEditable && (
                <Button
                  variant="outlined"
                  startIcon={
                    <Add
                      style={{
                        color: '#848C22',
                      }}
                    />
                  }
                  sx={{
                    width: 'fit-content',
                    cursor: 'pointer',
                    p: 0,
                    mt: 2.5,
                    border: 'none',
                    textTransform: 'initial',
                    '&:hover': {
                      border: 'none',
                      bgcolor: 'transparent',
                    },
                  }}
                  onClick={handleToggleModal}
                >
                  <TranslatedField
                    isTranslate
                    color="#848C22"
                    originText={translateLang('addNewBrand')}
                  />
                </Button>
              )}
            </Stack>
          </Stack>
          <Stack width="50%" pt={2} gap={2.5}>
            <FieldArray name="barcodes">
              {({ remove, push }) => {
                return (
                  values?.barcodes && (
                    <>
                      {values.barcodes?.length > 0 &&
                        values.barcodes.map((_, index) => (
                          <Stack
                            key={`barcode-${index}`}
                            width="100%"
                            flexDirection="row"
                            alignItems="end"
                            justifyContent="space-between"
                          >
                            <Input
                              name={`barcodes.${index}`}
                              labelColor={theme.palette.primary.light}
                              label={`${translateLang('productBarcode')} ${index + 1}`}
                              value={values[ProductField.Barcodes]?.[index]}
                              onChange={handleChange}
                              error={
                                Array.isArray(touched.barcodes) &&
                                touched.barcodes[index] &&
                                !!getIn(errors, `${ProductField.Barcodes}.${index}`)
                              }
                              helperText={
                                Array.isArray(touched.barcodes) &&
                                touched.barcodes[index] &&
                                getIn(errors, `${ProductField.Barcodes}.${index}`)
                              }
                              width="100%"
                            />
                            <IconButton
                              sx={{ width: 30, height: 30, ml: 0.5, mb: 1.25, p: 0 }}
                              onClick={() => remove(index)}
                            >
                              <Delete />
                            </IconButton>
                          </Stack>
                        ))}

                      <Button
                        variant="outlined"
                        disabled={values?.barcodes?.[values?.barcodes?.length - 1] === ''}
                        startIcon={
                          <Add
                            style={{
                              color:
                                values?.barcodes?.[values?.barcodes?.length - 1] === ''
                                  ? '#7A828A'
                                  : '#848C22',
                            }}
                          />
                        }
                        sx={{
                          width: 'fit-content',
                          cursor: 'pointer',
                          p: 0,
                          border: 'none',
                          textTransform: 'initial',
                          '&:hover': {
                            border: 'none',
                            bgcolor: 'transparent',
                          },
                          '&:disabled': {
                            border: 'none',
                          },
                        }}
                        onClick={() => push('')}
                      >
                        <TranslatedField
                          isTranslate
                          color={
                            values?.barcodes?.[values?.barcodes?.length - 1] === ''
                              ? '#7A828A'
                              : '#848C22'
                          }
                          originText={translateLang('addBarcode')}
                        />
                      </Button>
                    </>
                  )
                );
              }}
            </FieldArray>
          </Stack>
        </Stack>
        <Stack gap={2.5} flexDirection={{ xs: 'column', md: 'row' }}>
          <Stack width={{ xs: '100%', md: '50%' }} gap={2.5}>
            <Input
              name={ProductField.TitleEN}
              labelColor={theme.palette.primary.light}
              label={translateLang('titleENG')}
              value={values[ProductField.TitleEN]}
              onChange={handleChange}
              error={touched[ProductField.TitleEN] && !!errors[ProductField.TitleEN]}
              helperText={touched[ProductField.TitleEN] && errors[ProductField.TitleEN]}
            />
            <Input
              multiline
              minRows={4}
              maxRows={4}
              name={ProductField.DescriptionENG}
              labelColor={theme.palette.primary.light}
              label={translateLang('descriptionENG')}
              value={values[ProductField.DescriptionENG]}
              onChange={handleChange}
              error={touched[ProductField.DescriptionENG] && !!errors[ProductField.DescriptionENG]}
              helperText={
                touched[ProductField.DescriptionENG] && errors[ProductField.DescriptionENG]
              }
            />
          </Stack>
          <Stack width={{ xs: '100%', md: '50%' }} gap={2.5}>
            <Input
              name={ProductField.TitleDE}
              labelColor={theme.palette.primary.light}
              label={translateLang('titleDE')}
              value={values[ProductField.TitleDE]}
              onChange={handleChange}
              error={touched[ProductField.TitleDE] && !!errors[ProductField.TitleDE]}
              helperText={touched[ProductField.TitleDE] && errors[ProductField.TitleDE]}
            />
            <Input
              multiline
              minRows={4}
              maxRows={4}
              name={ProductField.DescriptionDE}
              labelColor={theme.palette.primary.light}
              label={translateLang('descriptionDE')}
              value={values[ProductField.DescriptionDE]}
              onChange={handleChange}
              error={touched[ProductField.DescriptionDE] && !!errors[ProductField.DescriptionDE]}
              helperText={touched[ProductField.DescriptionDE] && errors[ProductField.DescriptionDE]}
            />
          </Stack>
        </Stack>
      </Stack>

      <AddNewBrandForm
        refetchBrands={refetchBrands}
        handleToggleModal={handleToggleModal}
        isOpenModal={isOpenModal}
      />
    </>
  );
};
