/* eslint-disable no-console */
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './useLocalStorage';
import { useUserAuthorizationMutation } from '../graphql/mutations/__generated__/userAutorisation';
import { useGetMeLazyQuery } from '../graphql/queries/__generated__/getMe';
import { PatchesName, RolesEnum } from '../types/types';
import { apolloClient } from '../api/apolloClient';
import { initStore } from '../api/store/initStore';
import { useHandlerNotificationApp } from './useHandlerNotificationApp';
import { useUserAuthorizationByPhoneMutation } from '../graphql/mutations/__generated__/userAuthorizationByPhone';

interface IPropsLogin {
  identifier: string;
  password: string;
  isPhone?: boolean;
}
interface ReturnAuth {
  isAuth: boolean;
  login(options: IPropsLogin): Promise<void>;
  logout(): void;
}

export const useAuthUser = (): ReturnAuth => {
  const { addNotification } = useHandlerNotificationApp();
  const navigate = useNavigate();
  const [runGetMe] = useGetMeLazyQuery();
  const [runUserAuthorization] = useUserAuthorizationMutation();
  const [runUserAuthorizationByPhone] = useUserAuthorizationByPhoneMutation();
  const { jwt, setStorageValue, clearJwtKey } = useLocalStorage();

  const login = async ({ identifier, password, isPhone }: IPropsLogin): Promise<void> => {
    try {
      let dataLogin;
      if (!isPhone) {
        const response = await runUserAuthorization({
          variables: {
            dataInput: {
              identifier,
              password,
              provider: 'local',
            },
          },
        });
        dataLogin = response.data?.login;
      } else {
        const response = await runUserAuthorizationByPhone({
          variables: {
            input: {
              phoneNumber: identifier,
              password,
            },
          },
        });
        dataLogin = response.data?.loginByPhoneNumber;
      }

      if (dataLogin?.jwt) {
        setStorageValue(dataLogin.jwt);
        initStore();
        const responseMe = await runGetMe({ fetchPolicy: 'network-only' });
        const role = (responseMe.data?.me?.role?.name as RolesEnum) || RolesEnum.Manager;
        const resolveRoles = [
          RolesEnum.SuperAdmin,
          RolesEnum.Admin,
          RolesEnum.Manager,
          RolesEnum.Courier,
          RolesEnum.WarehouseManager,
          RolesEnum.Accounter,
        ];
        if (resolveRoles.includes(role)) {
          navigate(`/`, { replace: true });
        } else {
          logout();
          handlerError('You do not have access rights');
        }
      } else {
        handlerError('Something went wrong...');
      }
    } catch (err: any) {
      console.error('[ERROR_GRAPHQL]:', err);
      if (Object.prototype.hasOwnProperty.call(err, 'graphQLErrors')) {
        if (err.graphQLErrors.length > 0) {
          addNotification({ messageError: err.graphQLErrors[0].message, typeMessage: 'error' });
          return Promise.reject(err.graphQLErrors[0].message);
        }
        return Promise.reject('Something went wrong...');
      } //
      else if (Object.prototype.hasOwnProperty.call(err, 'message')) {
        addNotification({ messageError: err.message, typeMessage: 'error' });
        return Promise.reject(err.message);
      }
      return Promise.reject('Something went wrong...');
    }
  };
  function handlerError(mess: string) {
    throw new Error(mess);
  }

  const logout = () => {
    clearJwtKey();
    apolloClient.clearStore().then(() => {
      initStore();
      navigate(`/${PatchesName.Login}`, { replace: true });
    });
  };

  return {
    isAuth: !!jwt,
    login,
    logout,
  };
};
