import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

export const LayoutTranslate = () => {
  return (
    <Box width="100%" height="100%">
      <Outlet />
    </Box>
  );
};
