import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type GeneralSpareFragmentFragment = { __typename?: 'GeneralSparesEntity', id: string, attributes?: { __typename?: 'GeneralSpare', descriptionsInfo?: any | null, articleCriteria?: any | null, isDraft?: boolean | null, source?: number | null, eanNumber?: any | null, oemNumbers?: any | null, images?: any | null, price?: number | null, brandId?: number | null, brandName?: string | null, title?: string | null, tecdoc_articleNumber?: string | null, discounts?: Array<{ __typename?: 'GeneralDiscounts', id?: string | null, discount?: number | null, supplier?: { __typename?: 'GeneralSupplier', id?: string | null } | null } | null> | null, suppliersSpare?: Array<{ __typename?: 'GeneralSuppliersSpare', id?: string | null, minOrderQuantity?: number | null, amount?: string | null, discount?: number | null, prevPrice?: number | null, price?: number | null, supplierPrice?: number | null, warehouse?: any | null, tecdoc_articleNumber?: string | null, external_id?: string | null, priceByRole?: { __typename?: 'PriceByRole', customer?: number | null, customer30?: number | null, customer40?: number | null, employee?: number | null, permanentWholesaleCustomer?: number | null, wholesaleCustomer?: number | null } | null, supplier?: { __typename?: 'GeneralSupplier', id?: string | null, name?: string | null, deliveryDay?: number | null } | null } | null> | null } | null };

export const GeneralSpareFragmentFragmentDoc = gql`
    fragment GeneralSpareFragment on GeneralSparesEntity {
  id
  attributes {
    descriptionsInfo
    articleCriteria
    isDraft
    source
    eanNumber
    oemNumbers
    images
    price
    brandId
    brandName
    discounts {
      id
      discount
      supplier {
        id
      }
    }
    title
    tecdoc_articleNumber
    suppliersSpare {
      id
      minOrderQuantity
      amount
      discount
      priceByRole {
        customer
        customer30
        customer40
        employee
        permanentWholesaleCustomer
        wholesaleCustomer
      }
      prevPrice
      price
      supplierPrice
      warehouse
      tecdoc_articleNumber
      external_id
      supplier {
        id
        name
        deliveryDay
      }
    }
  }
}
    `;