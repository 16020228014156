import { apolloClient } from '../../api/apolloClient';
import { GetMeDocument } from '../../graphql/queries/__generated__/getMe';
import { PatchesName, RolesEnum } from '../../types/types';

interface ReturnHook {
  pathRedirect: PatchesName;
}
export const useGetPathRedirect = (): ReturnHook => {
  const data = apolloClient.readQuery({ query: GetMeDocument });
  let pathTo = PatchesName.Orders;
  const role = data?.me?.role?.name ?? '';
  if (role === RolesEnum.WarehouseManager) {
    pathTo = PatchesName.Warehouse;
  } else if (role === RolesEnum.Courier) {
    pathTo = PatchesName.Delivery;
  }

  return { pathRedirect: pathTo };
};
