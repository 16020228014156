import React, { useState } from 'react';
import { Box } from '@mui/system';
import { endOfToday, startOfDay, subDays } from 'date-fns';

import { PAGE_COUNT } from '../../../helpers/constants';
import { NoPartsTable } from './NoSpareTab/NoPartsTable';
import { ToolBarNoSpare } from './NoSpareTab/ToolBarNoSpare';
import { NoSparePartFiltersInput } from '../../../__generated__/types';
import { useNoSpareParts } from '../../../graphql/queries/hook/useNoSpareParts';

export const NoParts = () => {
  const [page, setPage] = useState(0);
  const [filterSubscription, setFilterSubscription] = useState<NoSparePartFiltersInput>({
    createdAt: {
      between: [startOfDay(subDays(new Date(), 30)).toISOString(), endOfToday().toISOString()],
    },
  });
  const [resetFilter, setResetFilter] = useState(new Date().getTime().toString());

  const { noSpareParts, pagination } = useNoSpareParts({
    pagination: {
      page: page + 1,
      pageSize: PAGE_COUNT,
    },
    filters: filterSubscription,
  });

  const handlerReset = () => {
    setFilterSubscription({
      createdAt: {
        between: [startOfDay(subDays(new Date(), 30)).toISOString(), endOfToday().toISOString()],
      },
    });
    setResetFilter(new Date().getTime().toString());
  };

  return (
    <Box width="100%">
      <ToolBarNoSpare
        key={resetFilter}
        result={pagination?.total || ''}
        setFilterSubscription={setFilterSubscription}
        setResetFilter={handlerReset}
      />
      <NoPartsTable
        noParts={noSpareParts}
        pagination={pagination}
        setPage={setPage}
        pageCount={PAGE_COUNT}
      />
    </Box>
  );
};
