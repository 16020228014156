import React, { FC, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useLocalization } from '../../../localization';
import { Input } from '../../../legos';
import {
  UpdateOrderMutationVariables,
  useUpdateOrderMutation,
} from '../../../graphql/mutations/__generated__/updateOrder';
import { useParams } from 'react-router-dom';
import { UrlParamsObjectType } from '../../../types/types';
import { useHandlerNotificationApp } from '../../../hooks/useHandlerNotificationApp';
interface OrderComentProps {
  comment?: string | null | undefined;
}
export const CartOrderComment: FC<OrderComentProps> = ({ comment }) => {
  const { translateLang, updateSelectLanguage } = useLocalization();
  const [value, setValue] = useState(comment);
  const [runUpdateOrder] = useUpdateOrderMutation();
  const { idOrder = '' } = useParams<UrlParamsObjectType>();
  const { addNotification } = useHandlerNotificationApp();

  const getComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  const commentPush = async () => {
    const variables: UpdateOrderMutationVariables = {
      id: idOrder,
      data: {
        comment: value,
      },
      locale: updateSelectLanguage as string,
    };
    try {
      const response = await runUpdateOrder({ variables });
      if (response && response?.data) {
        addNotification({
          messageError: translateLang('commentSuccessfullyAdded'),
          typeMessage: 'success',
        });
      }
    } catch (error) {
      console.error('error', error);
    }
  };
  return (
    <Stack marginTop="24px" sx={{ flexGrow: 1 }} justifyContent="space-between">
      <Stack
        sx={{
          color: '#7A828A',
          paddingLeft: '16px',
          paddingTop: '14px',
          paddingBottom: '14px',
          backgroundColor: '#F1F3F8',
          borderRadius: '6px',
          width: { xs: '100%', sm: '50%' },
          padding: '10px',
        }}
      >
        <Typography
          sx={{
            color: '##2A3453',
            paddingLeft: '16px',
            paddingTop: '14px',
            paddingBottom: '14px',
            maxHeight: '46px',
            minWidth: '100px',
          }}
        >
          {translateLang('comment')}:
        </Typography>

        <Input
          value={value}
          onChange={getComment}
          placeholder={comment ? comment : translateLang('writeComment')}
          sx={{
            color: '#2A3453',
          }}
        />
        {value && value !== comment && <Button onClick={commentPush}>Save</Button>}
      </Stack>
    </Stack>
  );
};
