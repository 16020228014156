import React, { FC, forwardRef } from 'react';
import * as yup from 'yup';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import 'react-phone-input-2/lib/style.css';
import ReactPhoneInput from 'react-phone-input-material-ui';
import { useFormik } from 'formik';

import styles from './Input.module.css';
import { useUpdateCustomerContactInfoMutation } from '../../../../graphql/mutations/__generated__/updateCustomerContactInfo';
import { useGetClientById } from '../../../../graphql/queries/hook/useGetClientById';
import {
  ComponentClientPhoneNumbersInput,
  CustomerContactInfoInput,
} from '../../../../__generated__/types';
import { GetClientByIdDocument } from '../../../../graphql/queries/__generated__/getClintById';
import { useAddCustomerContactInfoMutation } from '../../../../graphql/mutations/__generated__/addCustomerContactInfo';

import { CustomModal } from '../../../../components';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useLocalization } from '../../../../localization';
import { handlerError } from '../../../../helpers/functions';
import { useValidation } from '../../../../hooks';

interface PhoneProps {
  clientId: string;
  closeModal(): void;
  openModal: boolean;
  id?: string;
  number?: string;
}

enum PhoneFields {
  Phone = 'phone',
}

export const PhoneModal: FC<PhoneProps> = ({ id, closeModal, openModal, clientId }) => {
  const { phoneNumbers, isContactInfoActive, contactInfoId } = useGetClientById(clientId);
  const { updateSelectLanguage } = useLocalization();
  const [runAddCustomerContactInfo] = useAddCustomerContactInfoMutation();
  const [runUpdateCustomer] = useUpdateCustomerContactInfoMutation();
  const { phoneValidation } = useValidation();

  const getCurrentPhone = () => {
    if (id) {
      const phone = phoneNumbers?.find(item => item?.id === id);

      if (phone && phone?.number) {
        return phone.number;
      }
    }
    return '';
  };
  const initialValues = {
    [PhoneFields.Phone]: getCurrentPhone(),
  };
  const { values, errors, touched, setErrors, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    validationSchema: yup.object({
      [PhoneFields.Phone]: phoneValidation,
    }),
    onSubmit: async values => {
      let phones: ComponentClientPhoneNumbersInput[] | undefined;
      if (id) {
        phones = phoneNumbers?.map(item => {
          let currentPhone = item?.number;
          if (item?.id === id && item?.number) {
            currentPhone = values[PhoneFields.Phone];
          }
          return {
            id: item?.id,
            number: currentPhone,
          };
        });
      } else {
        phones = phoneNumbers?.map(item => {
          return {
            id: item?.id,
            number: item?.number,
          };
        });

        if (phones) {
          phones?.push({ number: values[PhoneFields.Phone] });
        } else {
          phones = [{ number: values[PhoneFields.Phone] }];
        }
      }
      const variables: CustomerContactInfoInput = {
        phoneNumbers: phones,
      };
      if (!isContactInfoActive) {
        variables.user = clientId;
        try {
          await runAddCustomerContactInfo({
            variables: {
              data: {
                ...variables,
              },
            },
            refetchQueries: [
              {
                query: GetClientByIdDocument,
                variables: {
                  id: clientId,
                  locale: updateSelectLanguage as string,
                },
              },
            ],
          });
        } catch (error: unknown) {
          handlerError(error);
        }
      } else {
        try {
          await runUpdateCustomer({
            variables: {
              data: {
                ...variables,
              },
              id: contactInfoId || '',
            },
          });
        } catch (error) {
          setErrors({
            [PhoneFields.Phone]: error as string,
          });
        } finally {
          closeModal();
        }
      }
    },
  });

  return (
    <CustomModal title="phoneNumbers" handleClose={closeModal} open={openModal}>
      <Stack component="form" flexGrow={1} spacing={6} onSubmit={handleSubmit}>
        <ReactPhoneInput
          autoFormat
          jumpCursorToEnd
          disableSearchIcon
          value={values[PhoneFields.Phone]}
          component={CustomInput}
          onChange={value => {
            setFieldValue(PhoneFields.Phone, value);
          }}
          regions={['europe']}
          country="at"
          inputStyle={{
            padding: '10px 0 0 0',
            height: 50,
            marginLeft: '60px',
            width: 'calc(100% - 62px)',
            background: 'tra',
            border: 'none',
          }}
          buttonClass={styles.buttonClass_standard}
          dropdownClass={styles.dropdownClass_standard}
          containerStyle={{
            borderRadius: '5px',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor:
              touched[PhoneFields.Phone] && !!errors[PhoneFields.Phone] ? 'red' : '#c5c5c5',
          }}
          label=""
        />
        <Box position="absolute">
          {touched[PhoneFields.Phone] && errors[PhoneFields.Phone] && (
            <Typography m="5px 0 0 0" color="red" fontSize="13px">
              {errors[PhoneFields.Phone]}
            </Typography>
          )}
        </Box>

        <Button color="secondary" variant="contained" size="large" type="submit">
          <TranslatedField originText="saveChanges" isTranslate color="white" />
        </Button>
      </Stack>
    </CustomModal>
  );
};

const CustomInput = forwardRef<HTMLInputElement>((props, ref) => {
  return (
    <TextField
      hiddenLabel
      variant="standard"
      InputProps={{
        disableUnderline: true,
      }}
      inputRef={ref}
      {...props}
    />
  );
});
CustomInput.displayName = 'CustomInput';
