import React, { ReactNode } from 'react';

import { useLocalization } from '../../../../../localization';
import { IncomingInvoiceEntity } from '../../../../../__generated__/types';
import { TableIncomingInvoiceRowItem } from '../components/TableIncomingInvoiceRowItem';
import { HeaderCell } from '../../../../../components/TableProductActive/TableProductActive';

interface HookProps {
  items?: IncomingInvoiceEntity[];
  toggleInvoiceTable: (id: string) => void;
  refetchSupplierBalance: () => void;
}
interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTableActive(): Array<ReactNode>;
}

export const useGetIncomingInvoicesTable = ({
  items,
  toggleInvoiceTable,
  refetchSupplierBalance,
}: HookProps): ReturnHook => {
  const { translateLang } = useLocalization();

  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      {
        title: translateLang('numberSecond'),
        containerProps: { width: '240px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('date'),
        containerProps: { width: '240px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: `${translateLang('actualAmount')} €`,
        containerProps: { width: '240px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: `${translateLang('invoiceAmount')} €`,
        containerProps: { width: '240px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('invoiceStatus'),
        containerProps: { width: '240px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: '',
        containerProps: { width: '30px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: '',
        containerProps: { width: '30px', color: '#f1f3f8', align: 'center' },
      },
    ];
  };

  const getRowsTableActive = (): ReactNode[] => {
    if (items && items?.length) {
      return items?.map((item, index) => {
        return (
          <TableIncomingInvoiceRowItem
            key={index}
            invoiceData={item}
            toggleInvoiceTable={toggleInvoiceTable}
            refetchSupplierBalance={refetchSupplierBalance}
          />
        );
      });
    }
    return [];
  };

  return {
    getHeadersTable,
    getRowsTableActive,
  };
};
