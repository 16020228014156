import React, { FC, useState } from 'react';
import { Delete } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';

import { useLocalization } from '../../../../../../localization';
import {
  Enum_Incominginvoice_Status,
  Maybe,
  StockEntity,
} from '../../../../../../__generated__/types';
import { ModalConfirm } from '../../../../../../components/ModalConfirm';
import { useHandlerNotificationApp } from '../../../../../../hooks/useHandlerNotificationApp';
import { TableProductActive } from '../../../../../../components/TableProductActive/TableProductActive';
import { useUpdateIncomingInvoiceMutation } from '../../../../../../graphql/mutations/__generated__/updateIncomingInvoice';
import { theme } from '../../../../../../helpers';

interface Props {
  invoiceData: StockEntity;
  invoiceId?: string | null;
  invoiceIds: (Maybe<string> | undefined)[];
  invoiceStatus?: Enum_Incominginvoice_Status | null;
}

export const TableIncomingInvoiceRow: FC<Props> = ({
  invoiceId,
  invoiceIds,
  invoiceData,
  invoiceStatus,
}) => {
  const { translateLang } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();

  const [updateIncomingInvoice] = useUpdateIncomingInvoiceMutation();

  const [openModal, setOpenModal] = useState(false);

  const handleConfirm = () => {
    const filteredIds = invoiceIds.filter(id => id !== invoiceData.id);
    updateIncomingInvoice({
      variables: {
        data: {
          stocks: filteredIds as string[],
        },
        id: invoiceId as string,
      },
    })
      .then(() => {
        addNotification({
          messageError: translateLang('invoiceSuccessfullyUpdated'),
          typeMessage: 'success',
        });
      })
      .catch(err => {
        addNotification({ messageError: err.message, typeMessage: 'error' });
      });
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <TableProductActive.RowTable>
        <TableProductActive.CellTable ceilStyle={{ borderLeft: 'none' }}>
          {invoiceData.attributes?.car_spare?.data?.attributes?.tecdoc_articleNumber}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable>
          {
            invoiceData.attributes?.car_spare?.data?.attributes?.car_spare_locales?.data[0]
              .attributes?.title
          }
        </TableProductActive.CellTable>
        <TableProductActive.CellTable>
          {
            invoiceData.attributes?.car_spare?.data?.attributes?.car_spare_locales?.data[0]
              .attributes?.brandName
          }
        </TableProductActive.CellTable>
        <TableProductActive.CellTable>
          {invoiceData.attributes?.incomingQuantity}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable>
          {`${invoiceData.attributes?.supplierPrice} €`}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable ceilStyle={{ borderLeft: 'none' }}>
          <Box bgcolor="#e7eef4" borderRadius={5}>
            <IconButton
              disabled={invoiceStatus === Enum_Incominginvoice_Status.Completed}
              onClick={toggleModal}
              sx={{
                '&:disabled': {
                  bgcolor: theme.palette.common.lightGrey,
                },
              }}
            >
              <Delete fontSize="small" sx={{ color: '#5269A3' }} />
            </IconButton>
          </Box>
        </TableProductActive.CellTable>
      </TableProductActive.RowTable>
      <ModalConfirm
        openModal={openModal}
        closeModal={toggleModal}
        title={translateLang('doYouReallyConfirmThisAction')}
        handlerConfirm={handleConfirm}
        confirmTitleButton={translateLang('yes')}
      />
    </>
  );
};
