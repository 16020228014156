import React, { FC, useState } from 'react';
import {
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';
import { Box } from '@mui/system';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';

import { CustomModal } from '../../../../components';
import { useLocalization } from '../../../../localization';
import { handlerError } from '../../../../helpers/functions';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useGetStockCellAddressesQuery } from '../../../../graphql/queries/__generated__/getStockCellAddresses';
import { useUpdateStockOrderItemMutation } from '../../../../graphql/mutations/__generated__/updateStockOrderitem';

interface Props {
  isOpen: boolean;
  location: string;
  productId: string;
  closeModal(): void;
}

export const ChangeLocationModal: FC<Props> = ({ closeModal, isOpen, location, productId }) => {
  const { translateLang } = useLocalization();
  const { data } = useGetStockCellAddressesQuery();
  const [runUpdateStockOrderItem] = useUpdateStockOrderItemMutation();
  const [cellId, setCellId] = useState(location);

  const cellTitle = translateLang('cell');
  const shelfTitle = translateLang('shelf');

  const handleChange = (event: SelectChangeEvent) => {
    setCellId(event.target.value as string);
  };

  const changeAddressOrderItem = async () => {
    try {
      if (cellId !== location) {
        await runUpdateStockOrderItem({
          variables: {
            id: productId,
            data: {
              stock_cell_address: cellId,
            },
          },
        });
      }
    } catch (err: unknown) {
      handlerError(err);
    }
  };

  const handleSaveLocationCell = () => {
    changeAddressOrderItem();
    closeModal();
  };

  return (
    <CustomModal
      width="500px"
      open={isOpen}
      handleClose={closeModal}
      title={translateLang('choosePlace')}
    >
      <Box
        pt={3}
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <FormControl fullWidth>
          <InputLabel>{`${shelfTitle}, ${cellTitle}`}</InputLabel>
          <Select onChange={handleChange} value={cellId} label={`${shelfTitle}, ${cellTitle}`}>
            {data?.stockCellAddresses?.map((item, index) => (
              <MenuItem key={index.toString()} value={item?.id || index}>
                <Box sx={{ alignItems: 'center', display: 'flex' }}>
                  {item?.isEmptyCell ? (
                    <CheckBoxOutlineBlank
                      sx={{ color: '#97A408', marginRight: '10px' }}
                      fontSize="small"
                    />
                  ) : (
                    <CheckBox sx={{ color: '#97A408', marginRight: '10px' }} fontSize="small" />
                  )}
                  {`${shelfTitle}: ${item?.shelf}, ${cellTitle}: ${item?.cell} (${translateLang(
                    item?.isEmptyCell ? 'cellEmpty' : 'cellFull'
                  )})`}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          onClick={handleSaveLocationCell}
          style={{
            marginTop: '20px',
          }}
        >
          <TranslatedField
            noWrap
            isTranslate
            fontSize={16}
            color="white"
            originText={translateLang('save')}
          />
        </Button>
      </Box>
    </CustomModal>
  );
};
