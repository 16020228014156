import React, { FC, useState, DragEvent, useEffect } from 'react';
import { Box } from '@mui/system';
import { Button, CircularProgress, Divider } from '@mui/material';

import { ItemOdreCard } from './ItemOredrCard';
import { CustomModal } from '../../../../../../components';
import { OrderItemType } from '../../../../../../helpers/types';
import { useLocalization } from '../../../../../../localization';
import {
  SplitOrderMutationVariables,
  useSplitOrderMutation,
} from '../../../../../../graphql/mutations/__generated__/splitOrder';
import {
  getVarOrderItems,
  useGetOrderItems,
} from '../../../../../../graphql/queries/hook/useGetOrderItems';
import { TranslatedField } from '../../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useHandlerNotificationApp } from '../../../../../../hooks/useHandlerNotificationApp';
import { GetOrderItemsDocument } from '../../../../../../graphql/queries/__generated__/getOrderItems';

export type Board = {
  id: string;
  items: OrderItemType[];
};

interface Props {
  open: boolean;
  idOrder: string;
  closeModal(): void;
  orderNumber: string;
}

export const DeviderModal: FC<Props> = ({ idOrder, orderNumber, closeModal, open }) => {
  const { translateLang, updateSelectLanguage } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();
  const [currentId] = useState(idOrder);
  const { orderItems } = useGetOrderItems(currentId);
  const [runSplitOrderMutation] = useSplitOrderMutation();
  const [currentBoard, setCurrentBoard] = useState<Board>();
  const [desabledButton, setDesabledButton] = useState(false);
  const [currentItem, setCurrentItem] = useState<OrderItemType | undefined>(undefined);
  const [boards, setBoards] = useState<Board[]>([
    { id: '1', items: [] },
    { id: '2', items: [] },
  ]);

  const filterOrderItems = (items: OrderItemType[]): Board[] => {
    const filteredItems1: OrderItemType[] = items.filter(
      item => item.quantity !== item.outgoingQuantity
    );

    const filteredItems2: OrderItemType[] = items.filter(
      item => item.quantity === item.outgoingQuantity
    );

    return [
      { id: '1', items: filteredItems1 },
      { id: '2', items: filteredItems2 },
    ];
  };

  const checkEmptyBoard = () => {
    setDesabledButton(boards[0]?.items.length === 0 || boards[1]?.items.length === 0);
  };

  const handlerSubmit = async () => {
    const ids = [];
    if (boards[1].items.length) {
      ids.push(...boards[1].items.map(item => item?.id || ''));
    }
    if (ids.length > 0) {
      const variables: SplitOrderMutationVariables = {
        orderId: idOrder,
        separateOrderItemIds: ids,
        locale: updateSelectLanguage as string,
      };
      if (boards) {
        try {
          await runSplitOrderMutation({
            variables,
            refetchQueries: [
              {
                query: GetOrderItemsDocument,
                variables: getVarOrderItems(updateSelectLanguage),
              },
            ],
          });
          addNotification({
            messageError: translateLang('split order success'),
            typeMessage: 'success',
          });
          closeModal();
        } catch (error: unknown) {
          addNotification({
            messageError: `${error}`,
            typeMessage: 'error',
          });
        }
      }
    }
  };

  const dragOverHandler = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const dragStartHandler = (
    event: DragEvent<HTMLDivElement>,
    item: OrderItemType,
    board: Board
  ) => {
    setCurrentItem(item);
    setCurrentBoard(board);
  };

  const dragEndHandler = (event: DragEvent<HTMLDivElement>) => {
    event.currentTarget.style.boxShadow = 'none';
  };

  const dragEndHandlerBoard = (event: DragEvent<HTMLDivElement>) => {
    event.currentTarget.style.border = 'none';
  };

  const dragOverHandlerBoard = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.currentTarget.className === 'board') {
      event.currentTarget.style.border = '1px solid #97A408';
    }
  };

  const dropCardHandlerBoard = (event: DragEvent<HTMLDivElement>, board: Board) => {
    event.currentTarget.style.border = 'none';
    if (currentItem) {
      board.items.push(currentItem);
      const currentIndex = currentBoard?.items.indexOf(currentItem);
      if (currentIndex !== undefined && currentBoard) {
        currentBoard.items.splice(currentIndex, 1);
      }
      const updatedBoards: { id: string; items: OrderItemType[] }[] = boards.map(b => {
        if (b && b.id === board.id) {
          return board;
        }
        if (b && b.id === currentBoard?.id && currentBoard) {
          return currentBoard;
        }
        return b || { id: '', items: [] };
      });

      setBoards(updatedBoards);
    }
  };

  const dragLeaveHandler = (event: DragEvent<HTMLDivElement>) => {
    if (event.currentTarget.className === 'board') {
      event.currentTarget.style.border = 'none'; // Remove the background color
    }
  };
  const dragLeaveHandlerBoard = (event: DragEvent<HTMLDivElement>) => {
    if (event.currentTarget.className === 'board') {
      event.currentTarget.style.border = 'none'; // Remove the background color
    }
  };

  const dragDropHandler = (event: DragEvent<HTMLDivElement>, item: OrderItemType, board: Board) => {
    if (board.items.length !== 0) return;
    event.currentTarget.style.boxShadow = 'none';
    event.preventDefault();
    if (currentItem) {
      const currentIndex = currentBoard?.items.indexOf(currentItem);
      if (currentIndex !== undefined && currentBoard) {
        currentBoard.items.splice(currentIndex, 1);
      }
      const dropIndex = board?.items.indexOf(item);
      board?.items.splice(dropIndex + 1, 0, currentItem);
      const updatedBoards: { id: string; items: OrderItemType[] }[] = boards.map(b => {
        if (b && b.id === board.id) {
          return board;
        }
        if (b && b.id === currentBoard?.id) {
          return currentBoard;
        }
        return b || { id: '', items: [] };
      });
      setBoards(updatedBoards);
    }
  };

  useEffect(() => {
    if (orderItems.length) {
      const filteredBoards: Board[] = filterOrderItems(orderItems);
      setBoards(filteredBoards);
    }
  }, [orderItems]);

  useEffect(() => {
    checkEmptyBoard();
  }, [boards]);

  return (
    <>
      <CustomModal
        open={open}
        width="1100px"
        bgcolor="#F1F3F8"
        handleClose={closeModal}
        title={`${translateLang('')}`}
      >
        <Box width="100%" height="100%" display="flex" alignItems="center" flexDirection="column">
          <Box mb={3} width="100%" display="flex" flexDirection="row" justifyContent="center">
            <TranslatedField
              noWrap
              isTranslate
              fontSize={30}
              color="black"
              originText={`${translateLang('split')}  #${orderNumber} (1)`}
            />
          </Box>
          <Box mb={3} width="100%" display="flex" flexDirection="row" justifyContent="space-around">
            <Box display="flex">
              <TranslatedField
                noWrap
                isTranslate
                fontSize={16}
                color="black"
                originText={`${translateLang('order')}  #${orderNumber} (origin Order)`}
              />
            </Box>
            <Box display="flex">
              <TranslatedField
                noWrap
                isTranslate
                fontSize={16}
                color="black"
                originText={`${translateLang('order')}  #${orderNumber} (new Order)`}
              />
            </Box>
          </Box>
          <Box width="100%" display="flex" flexDirection="row" justifyContent="center">
            {boards?.map((board, index) => {
              const key = board?.id ? `${board.id}-${index}` : index.toString();
              return (
                <React.Fragment key={key}>
                  {index === 1 && <Divider orientation="vertical" variant="middle" flexItem />}
                  <div
                    className="board"
                    key={key}
                    onDragEnd={e => dragEndHandlerBoard(e)}
                    onDragOver={e => dragOverHandlerBoard(e)}
                    onDragLeave={e => dragLeaveHandlerBoard(e)}
                    onDrop={e => dropCardHandlerBoard(e, board)}
                    style={{
                      width: '47%',
                      display: 'flex',
                      height: '500px',
                      overflow: 'auto',
                      marginLeft: '20px',
                      marginBottom: '5px',
                      borderRadius: '5px',
                      marginRight: '20px',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    {orderItems.length ? (
                      board?.items.map((item, innerIndex) => {
                        const innerKey = item?.id
                          ? `${item.id}-${innerIndex}`
                          : innerIndex.toString();
                        return (
                          <ItemOdreCard
                            item={item}
                            board={board}
                            draggable={true}
                            article={item.article || ''}
                            dragEndHandler={dragEndHandler}
                            dragOverHandler={dragOverHandler}
                            dragDropHandler={dragDropHandler}
                            key={innerKey}
                            dragLeaveHandler={dragLeaveHandler}
                            dragStartHandler={dragStartHandler}
                            customerName={item?.provider || ''}
                            productName={item?.productName || ''}
                          />
                        );
                      })
                    ) : (
                      <CircularProgress size={30} color="secondary" />
                    )}
                  </div>
                </React.Fragment>
              );
            })}
          </Box>
          <Button
            color="secondary"
            variant="contained"
            onClick={handlerSubmit}
            disabled={desabledButton}
            style={{
              marginTop: '20px',
              width: '320px',
            }}
          >
            <TranslatedField
              noWrap
              isTranslate
              fontSize={16}
              color="white"
              originText={translateLang('save')}
            />
          </Button>
        </Box>
      </CustomModal>
    </>
  );
};
