import React, { useEffect, useMemo, useRef, useState } from 'react';
import jsPDF from 'jspdf';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { ArrowBack } from '@mui/icons-material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';

import { Input } from '../../../../legos';
import { theme } from '../../../../helpers';
import { InvoicePrint } from './InvoicePrint';
import { useValidation } from './hooks/useValidation';
import { useLocalization } from '../../../../localization';
import { CalendarInvoicePrint } from './CalendarInvoicePrint';
import { IncomingPrintFields } from './types';
import { useGetClientById } from '../../../../graphql/queries/hook/useGetClientById';
import { useHandlerNotificationApp } from '../../../../hooks/useHandlerNotificationApp';
import { useGetCustomerBillLazyQuery } from '../../../../graphql/queries/__generated__/getCustomerBill';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useUpdateCustomerBillMutation } from '../../../../graphql/mutations/__generated__/updateCustomerBill';
import { useUpdateCustomerContactInfoMutation } from '../../../../graphql/mutations/__generated__/updateCustomerContactInfo';
import { PatchesName } from '../../../../types/types';
import html2canvas from 'html2canvas';

import {
  setCurrentClientCred,
  setCurrentOrderData,
} from '../../../../graphql/store/functionsWitingCache';

export const InvoicePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { addNotification } = useHandlerNotificationApp();
  const { translateLang, updateSelectLanguage } = useLocalization();

  const { idOrder, idUser = '' } = useParams();
  const idsOrder = idOrder?.split(',');
  const { validationSchema } = useValidation();

  const { contactInfoId } = useGetClientById(idUser);

  const [loading, setLoading] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [date, setDate] = useState<Date | null>(new Date());
  const [invoicePaid, setInvoicePaid] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('cashPayment');
  const divInvoiceRef = useRef<HTMLDivElement>(null);

  const [runUpdateCustomer] = useUpdateCustomerContactInfoMutation();
  const [runGetCustomerBill, { data: customerBillData, refetch }] = useGetCustomerBillLazyQuery();

  const customerBillInfo = useMemo(
    () => ({
      ...customerBillData?.customerBill?.data?.attributes,
      ordersInvoice: customerBillData?.customerBill?.data?.attributes?.orders?.data.map(item => ({
        orderNumber: item.attributes?.orderNumber,
        deliveryAddress: item.attributes?.deliveryAddress,
        data: item.attributes?.order_items?.data.map(orderItem => ({
          title:
            orderItem.attributes?.car_spare?.data?.attributes?.car_spare_locales?.data[0].attributes
              ?.title,
          count:
            (orderItem.attributes?.quantity?.orderedQuantity || 0) -
            (orderItem.attributes?.quantity?.returnedQuantity || 0),
          price: orderItem.attributes?.customer_price,
        })),
      })),
      userContactInfo:
        customerBillData?.customerBill?.data?.attributes?.customer?.data?.attributes
          ?.customer_contact_info?.data?.attributes,
    }),
    [customerBillData?.customerBill?.data?.attributes]
  );

  const [updateCustomerBillMutation] = useUpdateCustomerBillMutation();

  const initialValues = {
    [IncomingPrintFields.BillNumber]: customerBillInfo.billNumber ?? '',
    [IncomingPrintFields.Comment]: customerBillInfo?.comment ?? '',
    [IncomingPrintFields.CompanyName]: customerBillInfo?.userContactInfo?.companyName ?? '',
    [IncomingPrintFields.House]:
      customerBillInfo?.address?.number ??
      customerBillInfo?.ordersInvoice?.[0]?.deliveryAddress?.number ??
      '',
    [IncomingPrintFields.Street]:
      customerBillInfo?.address?.street ??
      customerBillInfo?.ordersInvoice?.[0]?.deliveryAddress?.street ??
      '',
    [IncomingPrintFields.City]:
      customerBillInfo?.address?.city ??
      customerBillInfo?.ordersInvoice?.[0]?.deliveryAddress?.city ??
      '',
    [IncomingPrintFields.ZipCode]:
      customerBillInfo?.address?.zipCode ??
      customerBillInfo?.ordersInvoice?.[0]?.deliveryAddress?.zipCode ??
      '',
    [IncomingPrintFields.VATId]: customerBillInfo?.userContactInfo?.VATId ?? '',
  };

  const { values, errors, touched, handleChange, handleSubmit, dirty } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async ({ VATId, companyName, street, house, city, zipCode, comment, billNumber }) => {
      setLoading(true);
      try {
        await runUpdateCustomer({
          variables: {
            id: contactInfoId ?? '',
            data: {
              VATId,
              companyName,
            },
          },
        });
        await updateCustomerBillMutation({
          variables: {
            id: customerBillData?.customerBill?.data?.id as string,
            data: {
              comment,
              billNumber: +billNumber,
              address: {
                street,
                number: house,
                city,
                zipCode,
              },
            },
          },
        });
        addNotification({
          messageError: translateLang('updatedSuccessfully'),
          typeMessage: 'success',
        });
      } catch (err) {
        addNotification({
          messageError: (err as Error)?.message,
          typeMessage: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (location.state?.customerBillId) {
      runGetCustomerBill({
        variables: {
          id: location.state.customerBillId,
          locale: updateSelectLanguage as string,
        },
      }).finally(() => setPageLoading(false));
    }
  }, []);

  useEffect(() => {
    if (customerBillInfo?.billDate) {
      setDate(new Date(customerBillInfo?.billDate));
    }
  }, [customerBillInfo?.billDate]);

  const handleDateChange = async (newValue: Date | null) => {
    await updateCustomerBillMutation({
      variables: {
        id: customerBillData?.customerBill?.data?.id as string,
        data: {
          billDate: format(newValue ?? new Date(), 'yyyy-MM-dd'),
          orders: idsOrder,
        },
      },
    })
      .then(() => {
        setDate(newValue);
        refetch();
        addNotification({
          messageError: translateLang('invoiceDateUpdated'),
          typeMessage: 'success',
        });
      })
      .catch(err => {
        addNotification({ messageError: err.message, typeMessage: 'error' });
      });
  };

  const runInvoicePrint = () => {
    const ifram = document.createElement('iframe');
    ifram.style.display = 'none';
    document.body.appendChild(ifram);
    const pri = ifram.contentWindow;

    if (pri) {
      pri.document.open();
      if (divInvoiceRef.current) {
        const customInnerHTML = divInvoiceRef.current.innerHTML
          .replace(
            '<input',
            `<input style='border: none; font-size: 12px; font-weight: 700; margin-left: 16px'`
          )
          .replace('class="MuiInputAdornment', `style='display:none' class="MuiInputAdornment`);
        pri.document.write(customInnerHTML);
      }
      pri.document.close();
      pri.focus();
      pri.print();
    }
    setTimeout(() => {
      document.body.removeChild(ifram);
    }, 500);
  };

  const downloadInvoicePDF = async () => {
    const invoiceElement = divInvoiceRef.current;
    const redLineElement = invoiceElement?.querySelector('.red-line') as HTMLElement;

    if (!invoiceElement) return;
    setPdfLoading(true);

    if (redLineElement) redLineElement.style.zIndex = '-1';

    try {
      const canvas = await html2canvas(invoiceElement, { scale: 2 });
      const imgData = canvas.toDataURL('image/jpeg', 0.6);
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
      });

      const pageWidth = pdf.internal.pageSize.getWidth();
      const imgWidth = pageWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);
      pdf.save(`invoice_${billNumber}.pdf`);
    } finally {
      if (redLineElement) redLineElement.style.zIndex = '1';
      setPdfLoading(false);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const toggleInvoicePaid = () => {
    setInvoicePaid(!invoicePaid);
  };

  const billNumber = `${new Date(customerBillInfo?.billDate).getFullYear()}/${
    values[IncomingPrintFields.BillNumber]
  }`;

  const total = customerBillInfo?.total || 0;

  const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod((event.target as HTMLInputElement).value);
  };

  if (!location.state?.customerBillId) {
    navigate(`/${PatchesName.Accounting}`);
  }

  return (
    <Stack mb={4}>
      <Box mb={2.5}>
        <IconButton onClick={handleGoBack}>
          <ArrowBack sx={{ color: theme.palette.common.black }} />
        </IconButton>
      </Box>
      {!pageLoading ? (
        <Stack
          maxWidth={1300}
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
          gap={5}
        >
          <InvoicePrint
            ref={divInvoiceRef}
            total={total}
            invoicePaid={invoicePaid}
            invoicePaidComment={values[IncomingPrintFields.Comment]}
            billInfo={{
              companyName: values[IncomingPrintFields.CompanyName],
              VATId: values[IncomingPrintFields.VATId],
              street: values[IncomingPrintFields.Street],
              house: values[IncomingPrintFields.House],
              city: values[IncomingPrintFields.City],
              zipCode: values[IncomingPrintFields.ZipCode],
            }}
            billNumber={billNumber}
            ordersInvoice={customerBillInfo?.ordersInvoice}
            date={date}
          />

          <Stack component="form" justifyContent="space-between" gap={2} onSubmit={handleSubmit}>
            <Stack flexDirection="column" gap={2}>
              <TranslatedField
                originText={'documentSettings'}
                fontSize={28}
                fontWeight={600}
                isTranslate
                noWrap
                overflow="initial"
              />
              <Stack flexDirection="row" gap={1.25} alignItems="flex-start">
                <CalendarInvoicePrint
                  disableFuture
                  value={date}
                  isLabel={false}
                  setValue={handleDateChange}
                  inputStyle={{
                    bgcolor: theme.palette.common.white,
                    height: 40,
                    p: 0,
                    '& .MuiInputBase-root': {
                      width: '100%',
                      fontFamily: 'Mulish,sans-serif',
                      fontSize: '12px',
                      fontWeight: 700,
                      lineHeight: 1.5,
                      p: '9px 15px',
                    },
                  }}
                />
                <Input
                  name={IncomingPrintFields.BillNumber}
                  value={values[IncomingPrintFields.BillNumber]}
                  onChange={handleChange}
                  error={
                    touched[IncomingPrintFields.BillNumber] &&
                    !!errors[IncomingPrintFields.BillNumber]
                  }
                  helperText={
                    touched[IncomingPrintFields.BillNumber] &&
                    errors[IncomingPrintFields.BillNumber]
                  }
                  bgcolor={theme.palette.common.white}
                  inputProps={{
                    style: {
                      height: 12,
                      width: '60%',
                    },
                  }}
                />
              </Stack>
              <Stack flexDirection="row" gap={1.25} alignItems="center">
                <Checkbox checked={invoicePaid} onChange={toggleInvoicePaid} />

                <TranslatedField
                  originText={'invoicePaid'}
                  fontSize={20}
                  fontWeight={500}
                  isTranslate
                  noWrap
                  overflow="initial"
                />
              </Stack>
              {invoicePaid ? (
                <Stack flexDirection="column" gap={2}>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      {translateLang('paymentMethod')}
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      value={paymentMethod}
                      onChange={handlePaymentMethodChange}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <FormControlLabel
                        value="cashPayment"
                        control={<Radio />}
                        label={translateLang('cashPayment')}
                      />
                      <FormControlLabel
                        value="bankTransfer"
                        control={<Radio />}
                        label={translateLang('bankTransfer')}
                      />
                    </RadioGroup>
                  </FormControl>
                  <TextField
                    multiline
                    rows={4}
                    name={IncomingPrintFields.Comment}
                    value={values[IncomingPrintFields.Comment]}
                    onChange={handleChange}
                    label={translateLang('comment')}
                  />
                </Stack>
              ) : null}

              <Stack mt={3} flexGrow={1} spacing={2}>
                <Stack height={75} gap={2} direction="row">
                  <Input
                    label={translateLang('companyName')}
                    name={IncomingPrintFields.CompanyName}
                    value={values[IncomingPrintFields.CompanyName]}
                    onChange={handleChange}
                    error={
                      touched[IncomingPrintFields.CompanyName] &&
                      !!errors[IncomingPrintFields.CompanyName]
                    }
                    helperText={
                      touched[IncomingPrintFields.CompanyName] &&
                      errors[IncomingPrintFields.CompanyName]
                    }
                    bgcolor={theme.palette.common.white}
                    inputProps={{
                      style: {
                        height: 16,
                      },
                    }}
                  />
                  <Input
                    label={translateLang('VATId')}
                    name={IncomingPrintFields.VATId}
                    value={values[IncomingPrintFields.VATId]}
                    onChange={handleChange}
                    error={
                      touched[IncomingPrintFields.VATId] && !!errors[IncomingPrintFields.VATId]
                    }
                    helperText={
                      touched[IncomingPrintFields.VATId] && errors[IncomingPrintFields.VATId]
                    }
                    bgcolor={theme.palette.common.white}
                    inputProps={{
                      style: {
                        height: 16,
                      },
                    }}
                  />
                </Stack>
                <Stack height={75} gap={2} direction="row">
                  <Input
                    label={`${translateLang('street')}`}
                    name={IncomingPrintFields.Street}
                    value={values[IncomingPrintFields.Street]}
                    onChange={handleChange}
                    error={
                      touched[IncomingPrintFields.Street] && !!errors[IncomingPrintFields.Street]
                    }
                    helperText={
                      touched[IncomingPrintFields.Street] && errors[IncomingPrintFields.Street]
                    }
                    bgcolor={theme.palette.common.white}
                    inputProps={{
                      style: {
                        height: 16,
                      },
                    }}
                  />
                  <Input
                    label={`${translateLang('house')}`}
                    name={IncomingPrintFields.House}
                    value={values[IncomingPrintFields.House]}
                    onChange={handleChange}
                    error={
                      touched[IncomingPrintFields.House] && !!errors[IncomingPrintFields.House]
                    }
                    helperText={
                      touched[IncomingPrintFields.House] && errors[IncomingPrintFields.House]
                    }
                    bgcolor={theme.palette.common.white}
                    inputProps={{
                      style: {
                        height: 16,
                      },
                    }}
                  />
                </Stack>
                <Stack height={75} gap={2} direction="row">
                  <Input
                    label={`${translateLang('city')}`}
                    name={IncomingPrintFields.City}
                    value={values[IncomingPrintFields.City]}
                    onChange={handleChange}
                    error={
                      !!(touched[IncomingPrintFields.City] && !!errors[IncomingPrintFields.City])
                    }
                    helperText={
                      touched[IncomingPrintFields.City] && errors[IncomingPrintFields.City]
                    }
                    bgcolor={theme.palette.common.white}
                    inputProps={{
                      style: {
                        height: 16,
                      },
                    }}
                  />
                  <Input
                    label={`${translateLang('zip')}`}
                    name={IncomingPrintFields.ZipCode}
                    value={values[IncomingPrintFields.ZipCode]}
                    onChange={handleChange}
                    error={
                      !!(
                        touched[IncomingPrintFields.ZipCode] &&
                        !!errors[IncomingPrintFields.ZipCode]
                      )
                    }
                    helperText={
                      touched[IncomingPrintFields.ZipCode] && errors[IncomingPrintFields.ZipCode]
                    }
                    bgcolor={theme.palette.common.white}
                    inputProps={{
                      style: {
                        height: 16,
                      },
                    }}
                  />
                </Stack>

                <Button
                  disabled={!dirty}
                  color="secondary"
                  variant="contained"
                  size="large"
                  type="submit"
                  style={{
                    width: 200,
                    maxWidth: 200,
                    margin: '0 auto',
                  }}
                >
                  <TranslatedField originText="save" isTranslate color="white" />
                  {loading && <CircularProgress color="inherit" size={16} sx={{ marginLeft: 1 }} />}
                </Button>
              </Stack>
            </Stack>
            <Stack gap={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                disabled={pdfLoading}
                sx={{
                  height: 50,
                  textTransform: 'none',
                }}
                onClick={downloadInvoicePDF}
              >
                <TranslatedField
                  originText={'download'}
                  fontSize={16}
                  isTranslate
                  noWrap
                  overflow="initial"
                />
                {pdfLoading && (
                  <CircularProgress color="inherit" size={16} sx={{ marginLeft: 1 }} />
                )}
              </Button>
              <Stack gap={2} direction="row">
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  sx={{
                    height: 50,
                    textTransform: 'none',
                  }}
                  onClick={runInvoicePrint}
                >
                  <TranslatedField
                    originText={'print'}
                    fontSize={16}
                    isTranslate
                    noWrap
                    overflow="initial"
                  />
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  sx={{
                    height: 50,
                    textTransform: 'none',
                  }}
                >
                  <TranslatedField
                    originText={'send'}
                    fontSize={16}
                    isTranslate
                    noWrap
                    overflow="initial"
                  />
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack justifyContent="center" alignItems="center" mt={10}>
          <CircularProgress size={40} />
        </Stack>
      )}
    </Stack>
  );
};
