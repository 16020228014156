import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type GetSupplierSupportAutoOrderingQueryVariables = Types.Exact<{
  orderId: Types.Scalars['ID'];
  locale: Types.Scalars['String'];
}>;


export type GetSupplierSupportAutoOrderingQuery = { __typename?: 'Query', supplierSupportAutoOrdering?: Array<{ __typename?: 'SupplierSupportAutoOrdering', id?: string | null, name?: string | null, order_items?: Array<{ __typename?: 'OrderItemEntity', id?: string | null, attributes?: { __typename?: 'OrderItem', customer_price?: number | null, isOrderConfirmed?: boolean | null, autoOrderLastError?: string | null, isOrdered?: boolean | null, delivery_date?: any | null, discount?: number | null, comment?: string | null, quantity?: { __typename?: 'OrderItemQuantity', confirmedQuantity?: number | null, deliveryQuantity?: number | null, orderedQuantity?: number | null, outgoingQuantity?: number | null, pickedUpQuantity?: number | null, returnedQuantity?: number | null } | null, car_spare?: { __typename?: 'CarSpareEntityResponse', data?: { __typename?: 'CarSpareEntity', id?: string | null, attributes?: { __typename?: 'CarSpare', tecdoc_articleNumber?: string | null, car_spare_locales?: { __typename?: 'CarSpareLocaleRelationResponseCollection', data: Array<{ __typename?: 'CarSpareLocaleEntity', id?: string | null, attributes?: { __typename?: 'CarSpareLocale', title?: string | null, brandName?: string | null } | null }> } | null } | null } | null } | null } | null } | null> | null } | null> | null };


export const GetSupplierSupportAutoOrderingDocument = gql`
    query getSupplierSupportAutoOrdering($orderId: ID!, $locale: String!) {
  supplierSupportAutoOrdering(orderId: $orderId) {
    id
    name
    order_items {
      id
      attributes {
        customer_price
        quantity {
          confirmedQuantity
          deliveryQuantity
          orderedQuantity
          outgoingQuantity
          pickedUpQuantity
          returnedQuantity
        }
        isOrderConfirmed
        autoOrderLastError
        isOrdered
        delivery_date
        discount
        comment
        car_spare {
          data {
            id
            attributes {
              tecdoc_articleNumber
              car_spare_locales(filters: {locale: {eq: $locale}}) {
                data {
                  id
                  attributes {
                    title
                    brandName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetSupplierSupportAutoOrderingQuery__
 *
 * To run a query within a React component, call `useGetSupplierSupportAutoOrderingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierSupportAutoOrderingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierSupportAutoOrderingQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetSupplierSupportAutoOrderingQuery(baseOptions: Apollo.QueryHookOptions<GetSupplierSupportAutoOrderingQuery, GetSupplierSupportAutoOrderingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupplierSupportAutoOrderingQuery, GetSupplierSupportAutoOrderingQueryVariables>(GetSupplierSupportAutoOrderingDocument, options);
      }
export function useGetSupplierSupportAutoOrderingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupplierSupportAutoOrderingQuery, GetSupplierSupportAutoOrderingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupplierSupportAutoOrderingQuery, GetSupplierSupportAutoOrderingQueryVariables>(GetSupplierSupportAutoOrderingDocument, options);
        }
export type GetSupplierSupportAutoOrderingQueryHookResult = ReturnType<typeof useGetSupplierSupportAutoOrderingQuery>;
export type GetSupplierSupportAutoOrderingLazyQueryHookResult = ReturnType<typeof useGetSupplierSupportAutoOrderingLazyQuery>;
export type GetSupplierSupportAutoOrderingQueryResult = Apollo.QueryResult<GetSupplierSupportAutoOrderingQuery, GetSupplierSupportAutoOrderingQueryVariables>;