import React, { FC } from 'react';
import { Box, BoxProps, Tooltip, Typography } from '@mui/material';

interface Props {
  comment?: string;
  width?: BoxProps['width'];
}

export const ToolTypeComment: FC<Props> = ({ comment, width = '100%' }) => {
  if (comment) {
    return (
      <Box width={width}>
        <Tooltip title={<Typography>{comment}</Typography>} arrow>
          <Typography noWrap>{comment}</Typography>
        </Tooltip>
      </Box>
    );
  }
  return <Typography>&ndash;</Typography>;
};
