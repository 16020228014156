import React, { FC, useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';

import { useLocalization } from '../../../../../../localization';
import { ModalConfirm } from '../../../../../../components/ModalConfirm';
import { WrapperToolBar } from '../../../../../../components/WrapperToolBar';
import { Enum_Supplierreturn_Status } from '../../../../../../__generated__/types';
import { useHandlerNotificationApp } from '../../../../../../hooks/useHandlerNotificationApp';
import { GetSupplierReturnQuery } from '../../../../../../graphql/queries/__generated__/getSupplierReturn';
import { useUpdateSupplierReturnMutation } from '../../../../../../graphql/mutations/__generated__/updateSupplierReturn';

interface Props {
  status?: Enum_Supplierreturn_Status | null;
  returnInvoiceData?: GetSupplierReturnQuery;
  returnInvoiceId: string;
}

export const ToolbarInvoice: FC<Props> = ({ status, returnInvoiceData, returnInvoiceId }) => {
  const { translateLang } = useLocalization();

  const [openModal, setOpenModal] = useState(false);

  const [updateSupplierReturnMutation] = useUpdateSupplierReturnMutation();

  const { addNotification } = useHandlerNotificationApp();

  const [returnStatus, setReturnStatus] = useState<Enum_Supplierreturn_Status | null | undefined>(
    null
  );

  useEffect(() => {
    setReturnStatus(status);
  }, [status]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    if (value === Enum_Supplierreturn_Status.Completed) {
      toggleModal();
      return;
    }

    updateSupplierReturnMutation({
      variables: {
        data: { status: Enum_Supplierreturn_Status.Created },
        id: returnInvoiceId,
      },
    })
      .then(() => {
        addNotification({
          messageError: translateLang('invoiceSuccessfullyUpdated'),
          typeMessage: 'success',
        });

        setReturnStatus(Enum_Supplierreturn_Status.Created);
      })
      .catch(err => {
        addNotification({ messageError: err.message, typeMessage: 'error' });
      });
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const handleConfirm = () => {
    const status = Enum_Supplierreturn_Status.Completed;

    updateSupplierReturnMutation({
      variables: {
        data: { status },
        id: returnInvoiceId,
      },
    })
      .then(() => {
        addNotification({
          messageError: translateLang('invoiceSuccessfullyUpdated'),
          typeMessage: 'success',
        });

        setReturnStatus(status);
      })
      .catch(err => {
        addNotification({ messageError: err.message, typeMessage: 'error' });
      });
  };

  const invoiceNumber = `${new Date(
    returnInvoiceData?.supplierReturn?.data?.attributes?.dateReturned
  ).getFullYear()}/${returnInvoiceId}`;

  return (
    <WrapperToolBar pb={5}>
      <Stack width="100%" flexDirection="row" justifyContent="space-between" alignItems="start">
        <Stack flexDirection="row" alignItems="start" gap={4.5}>
          <Stack flexDirection="column" justifyContent="left">
            <Typography fontSize="22px" color="#19151699" mb={2}>
              {`${translateLang('incomingInvoice')} №`}{' '}
              <span style={{ fontSize: '26px', color: '#191516' }}>{invoiceNumber}</span>
            </Typography>
          </Stack>
          <Stack flexDirection="row" alignItems="center" gap={2}>
            <Typography fontSize="10px" color="#19151699">
              {`${translateLang('status')}:`}
            </Typography>
            <RadioGroup row value={returnStatus} onChange={handleChange}>
              <FormControlLabel
                value={Enum_Supplierreturn_Status.Created}
                control={<Radio />}
                label={
                  <Typography fontSize="14px" color="#191516">
                    {translateLang('forming')}
                  </Typography>
                }
              />

              <FormControlLabel
                value={Enum_Supplierreturn_Status.Completed}
                control={<Radio />}
                label={
                  <Typography fontSize="14px" color="#191516">
                    {translateLang('formed')}
                  </Typography>
                }
              />
            </RadioGroup>
          </Stack>
        </Stack>
      </Stack>
      <ModalConfirm
        openModal={openModal}
        closeModal={toggleModal}
        title={translateLang('doYouReallyConfirmThisAction')}
        handlerConfirm={handleConfirm}
        confirmTitleButton={translateLang('yes')}
      />
    </WrapperToolBar>
  );
};
