/* eslint-disable no-console */
import React, { FC, useMemo, useState } from 'react';
import { format, isSameDay, parseISO } from 'date-fns';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { LaunchRounded, RemoveRedEyeOutlined } from '@mui/icons-material';

import { DeliveryDateChangeModal } from '../DeliveryDateChangeModal';
import { formatPhoneNumber } from '../../../../../../helpers/functions';
import { handlerSetDataDelivery } from '../../../../../../graphql/store/helper/js';
import { Enum_Mode_Delivery_State, Enum_Order_Status } from '../../../../../../__generated__/types';
import { AllOrdersDeliveryType } from '../../../../../../graphql/queries/hook/useGetAllOrdersDelivery';
import { TableProductActive } from '../../../../../../components/TableProductActive/TableProductActive';
import { useLocalization } from '../../../../../../localization';
import { CourierSelect } from './CourierSelect';
import { DeliveryAddressSelect } from './DeliveryAddressSelect';
import { formatDate } from '../../../../../../helpers/functions';
export const AllOrdersDeliveryRowItem: FC<AllOrdersDeliveryType> = ({
  orderId,
  customerPhone,
  deliveryAddress,
  customerInfo,
  deliveryTime,
  status,
  orderNumber,
  customerId,
  courierId,
  orderDate,
}) => {
  const { translateLang } = useLocalization();

  const [modal, setModal] = useState(false);

  const isCurrentDay = useMemo(() => {
    const currentDate = new Date();
    if (deliveryTime) {
      const givenDate = parseISO(deliveryTime);
      return isSameDay(givenDate, currentDate);
    }
    return false;
  }, [deliveryTime]);

  return (
    <TableProductActive.RowTable>
      <TableProductActive.CellTable ceilStyle={{ width: '20px', borderLeft: 'none' }}>
        {`#${orderNumber}`}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ width: '20px' }}>
        {formatDate(orderDate)}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable>{customerInfo}</TableProductActive.CellTable>
      <TableProductActive.CellTable>
        {customerPhone && (
          <Stack direction="row" spacing={2}>
            <Typography>{formatPhoneNumber(customerPhone)}</Typography>
          </Stack>
        )}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ justifyContent: 'flex-start' }}>
        <CourierSelect value={courierId} orderId={orderId} />
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ alignItems: 'center' }}>
        <DeliveryAddressSelect value={deliveryAddress} orderId={orderId} customerId={customerId} />
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ alignItems: 'center' }}>
        <Typography sx={{ color: isCurrentDay ? '#97A408' : 'black' }}>
          {deliveryTime?.length ? formatDate(new Date(deliveryTime as string), true) : translateLang('enterDate')}
        </Typography>
        <Box display="flex" alignItems="center">
          <IconButton onClick={() => setModal(true)}>
            <LaunchRounded sx={{ fontSize: '0.8rem', color: '#5269A3' }} />
          </IconButton>
        </Box>
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ alignItems: 'center' }}>
        <Typography
          sx={{
            color: status === Enum_Order_Status.Delivery ? '#97A408' : '#8D8D8D',
          }}
        >
          {translateLang(status?.toString() ?? '')}
        </Typography>
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ alignItems: 'end' }}>
        <Box bgcolor="#e7eef4" borderRadius={2}>
          <IconButton
            onClick={() =>
              handlerSetDataDelivery({
                idOrder: orderId,
                isOpenModal: true,
                orderNumber: orderNumber || '',
                idUser: customerId,
                status: status || Enum_Order_Status.Created,
                callParent: Enum_Mode_Delivery_State.Delivery,
                isInfoAndPrint: false,
              })
            }
          >
            <RemoveRedEyeOutlined fontSize="small" sx={{ color: '#5269A3' }} />
          </IconButton>
        </Box>
      </TableProductActive.CellTable>
      {modal && (
        <DeliveryDateChangeModal
          orderId={orderId}
          isOpen={modal}
          date={format(deliveryTime ? new Date(deliveryTime as string) : new Date(), 'P HH:mm')}
          closeModal={() => setModal(false)}
        />
      )}
    </TableProductActive.RowTable>
  );
};
