import { Enum_Componentorderstatuseditinghistory_Status } from '../../__generated__/types';

export const orderStatuses = [
  Enum_Componentorderstatuseditinghistory_Status.Created,
  Enum_Componentorderstatuseditinghistory_Status.Coordination,
  Enum_Componentorderstatuseditinghistory_Status.Editing,
  Enum_Componentorderstatuseditinghistory_Status.Ordered,
  Enum_Componentorderstatuseditinghistory_Status.Awaiting,
  Enum_Componentorderstatuseditinghistory_Status.InStock,
  Enum_Componentorderstatuseditinghistory_Status.PickedUp,
  Enum_Componentorderstatuseditinghistory_Status.Delivery,
  Enum_Componentorderstatuseditinghistory_Status.Completed,
];
