import { setSelectLanguageUserQuery } from '../../graphql/store/functionsWitingCache';
import { useSetSelectLanguageUserQuery } from '../../graphql/store/__generated__/setSelectLanguageUser';
import { DataLanguages } from '../languages';
import { Enum_Language_Type } from '../../__generated__/types';

interface ReturnLocalization {
  updateSelectLanguage: Enum_Language_Type | undefined;
  selectLanguages: Enum_Language_Type | undefined;
  translateLang(transVar: string): string;
  setCurrentLanguage(lang: Enum_Language_Type): void;
}

export const useLocalization = (): ReturnLocalization => {
  const { data } = useSetSelectLanguageUserQuery();

  const setCurrentLanguage = (lang: Enum_Language_Type) => {
    setSelectLanguageUserQuery(
      {
        dataWrite: { selectLanguage: lang },
      },
      lang,
      true
    );
  };

  const translateLang = (transVar: string): string => {
    if (Object.prototype.hasOwnProperty.call(DataLanguages, transVar) && data?.selectLanguage) {
      return DataLanguages[transVar][data.selectLanguage];
    }
    return transVar;
  };

  return {
    updateSelectLanguage:
      data?.selectLanguage === Enum_Language_Type.Uk ? Enum_Language_Type.De : data?.selectLanguage,
    translateLang,
    selectLanguages: data?.selectLanguage,
    setCurrentLanguage,
  };
};
