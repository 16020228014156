import React, { FC, ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
interface Props {
  children: ReactNode | ReactNode[];
  title: string;
}
export const WrapperField: FC<Props> = ({ title, children }) => {
  return (
    <Box mb={3}>
      <Typography mb="6px">{title}</Typography>
      {children}
    </Box>
  );
};
