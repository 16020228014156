import React from 'react';

import { useGetCustomerBalanceQuery } from '../../../../../../../graphql/queries/__generated__/getCustomerBalance';
import { useGetCustomerAccountsQuery } from '../../../../../../../graphql/queries/__generated__/getCustomerAccounts';

export const useGetClientBalance = (clientId: string) => {
  const { data: customerAccountData, refetch: refetchCustomerAccount } =
    useGetCustomerAccountsQuery({
      variables: { filters: { customerId: { eq: clientId } } },
    });
  const { data: customerBalanceData, refetch: refetchCustomerBalance } = useGetCustomerBalanceQuery(
    {
      variables: { input: { customerId: clientId } },
    }
  );

  const customerBalance = customerBalanceData?.customerBalance;
  const customerAccount = customerAccountData?.customerAccounts?.data[0].attributes?.total;

  return {
    customerBalance,
    customerAccount,
    refetchCustomerAccount,
    refetchCustomerBalance,
  };
};
