import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { useLocalization } from '../../../../localization';
import { CurrentAddressType } from '../../types';

export const InformAddressStock: FC<CurrentAddressType> = ({ cell, shelf }) => {
  const { translateLang } = useLocalization();

  return (
    <Stack textAlign="center" direction={{ xs: 'column', sm: 'row' }} spacing={3} mt={2} mb={3}>
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: '36px',
          lineHeight: '45px',
          color: '#5269A3',
        }}
      >{`${shelf} ${translateLang('shelf')} ${cell} ${translateLang('cell')} `}</Typography>
    </Stack>
  );
};
