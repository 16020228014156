import { Typography, Stack, Divider } from '@mui/material';
import React, { FC } from 'react';
interface Props {
  value?: string | null | undefined;
  label: string;
  width?: string;
  divider?: boolean;
  labelColor?: string;
}
export const CustomerTypography: FC<Props> = ({
  value,
  label,
  width,
  divider = true,
  labelColor = '#7A8095',
}) => {
  return (
    <>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} sx={{ my: '15px' }}>
        <Typography
          sx={{
            lineHeight: '25.1px',
            fontSize: { lg: '20', md: '15px' },
            fontWeight: { lg: '600', md: '500', sm: '300' },
            color: labelColor,
            width: width,
          }}
        >
          {label}
        </Typography>
        <Typography
          sx={{
            lineHeight: '25.1px',
            fontSize: { lg: '20', md: '15px' },
            fontWeight: { lg: '400', md: '300', sm: '300' },
            color: '#2A3453',
          }}
        >
          {value}
        </Typography>
      </Stack>
      {divider && <Divider />}
    </>
  );
};
