import { Stack, Typography, Box, Button, CircularProgress } from '@mui/material';
import React, { FC } from 'react';
import BarcodeS from './BarcodeS';
import { useLocalization } from '../../../../../../localization';
import { MODE } from '../../types';

interface Props {
  scanResult: string;
  scaningError: null;
  setScanResult: React.Dispatch<React.SetStateAction<string>>;
  setScaningError: React.Dispatch<React.SetStateAction<null>>;
  changeMode: (eanNumber: string) => void;
  setCodeSearch: React.Dispatch<React.SetStateAction<MODE>>;
}
export const ScanerStep: FC<Props> = ({
  scanResult,
  scaningError,
  setScanResult,
  setScaningError,
  changeMode,
  setCodeSearch,
}) => {
  const { translateLang } = useLocalization();

  return (
    <Stack p="20px">
      <Typography sx={{ fontWeight: 400, fontSize: '26px', lineHeight: '45px' }}>
        {translateLang('runScanPoint')}
      </Typography>
      <Box sx={{ backgroundColor: '#ffff' }}>
        <BarcodeS
          scanResult={scanResult}
          setScanResult={setScanResult}
          scaningError={scaningError}
          setScaningError={setScaningError}
          onChange={changeMode}
        />
      </Box>
      <Button
        color="primary"
        variant="outlined"
        sx={{
          mt: '20px',
          width: '95%',
          textTransform: 'none',
          alignSelf: 'center',
          marginTop: '35px',
          borderRadius: '10px',
          border: '1px solid',
        }}
        onClick={() => setCodeSearch('manualSearch')}
      >
        {translateLang('codeManually')}
      </Button>
      {/* {completeOrder ? <CircularProgress size={30} color="secondary" /> : null} */}
    </Stack>
  );
};
