import React, { FC } from 'react';
import { Button, ButtonProps, Stack, Step, StepLabel, Stepper, styled } from '@mui/material';
import { useLocalization } from '../../localization';

import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { theme } from '../../helpers';

const StyledButton = styled(Button)<ButtonProps>(() => ({
  '&:disabled': {
    backgroundColor: '#D9D9D9',
  },
  '&:hover': {
    backgroundColor: '#5269a3c7',
  },
  minWidth: '34px',
  height: '34px',
  width: '34px',
}));
const CustomizedStep = styled(Step)`
  & .MuiSvgIcon-root {
    font-size: 50px;
    color: #dde0e8;
  }

  & .MuiStepConnector-root {
    top: 25px;
    left: calc(-50% + 50px);
    right: calc(50% + 50px);
  }

  & .MuiSvgIcon-root.Mui-active,
  & .MuiStepLabel-label.Mui-active {
    color: #5269a3;
  }

  & .MuiSvgIcon-root.Mui-completed,
  & .MuiStepLabel-label.Mui-completed {
    color: #97a408;
  }
`;

const steps = [
  {
    value: 'clientRegistration',
    step: 0,
  },
  {
    value: 'carRegistration',
    step: 1,
  },
  {
    value: 'orderPlacing',
    step: 2,
  },
];
interface Props {
  totalStep: number;
  activeStep: number;
  steps?: [value: string, step: number];
  onNext?: () => void;
  onPrev?: () => void;
  withoutStep?: boolean;
}

export const OrderStepper: FC<Props> = ({ activeStep, onNext, onPrev, totalStep, withoutStep }) => {
  const { translateLang } = useLocalization();

  return (
    <Stack justifyContent="space-between" alignItems="center" flexGrow="1" gap={4}>
      <Stack gap={8}>
        <Stack width="100%" justifyContent="space-between" flexGrow="1" gap={4} mt={4} mb={4}>
          <Stack direction="row" alignItems="baseline">
            {!withoutStep && (
              <StyledButton
                onClick={onPrev}
                disabled={activeStep === 0}
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  color: '#FFFFFF',
                }}
              >
                <KeyboardDoubleArrowLeftIcon fontSize="small" />
              </StyledButton>
            )}

            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map(({ value }) => (
                <CustomizedStep key={value}>
                  <StepLabel>{translateLang(value)}</StepLabel>
                </CustomizedStep>
              ))}
            </Stepper>
            {!withoutStep && (
              <StyledButton
                onClick={onNext}
                sx={{
                  backgroundColor:
                    activeStep >= totalStep
                      ? theme.palette.common.appleGreen
                      : theme.palette.secondary.main,
                  color: activeStep >= totalStep ? '#FFFFFF' : '#FFFFFF',
                }}
                disabled={activeStep == totalStep}
              >
                <KeyboardDoubleArrowRightIcon fontSize="small" />
              </StyledButton>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
