import { Stack, Box } from '@mui/material';
import React, { FC } from 'react';
import { BadgetButtons } from './BadgetButtons';

type Tabs = {
  value: string;
  id: number;
  notification?: number;
};

interface Props {
  activeTable: string | null;
  handleChange: (val: string | undefined) => void;
  tabs: Tabs[];
  notBadge?: boolean;
}
export const TableChangeButton: FC<Props> = ({ activeTable, handleChange, tabs, notBadge }) => {
  return (
    <Stack
      sx={{
        height: '58px',
        backgroundColor: 'white',
        width: { sm: '100%', md: '90%', lg: '60%' },
        borderRadius: '10px',
        paddingRight: '30px',
      }}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      divider={
        <Box
          sx={{
            width: '0.5px',
            backgroundColor: '#2A3453',
            height: '27px',
            marginLeft: '20px',
          }}
        />
      }
    >
      {tabs.map(tab => (
        <BadgetButtons
          key={tab.id}
          value={tab.value}
          label={tab.value}
          handleSet={handleChange}
          notification={tab.notification}
          activeTab={activeTable}
          notBadge={notBadge}
        />
      ))}
    </Stack>
  );
};
