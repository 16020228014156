import { createTheme, Theme } from '@mui/material';

declare module '@mui/material/styles' {
  interface SecondaryColors {
    main?: string;
  }
  interface CommonColors {
    black: string;
    white: string;
    appleGreen: string;
    darkBlue: string;
    grey: string;
    lightGrey: string;
    red: string;
  }
}
export const theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#2A3453',
    },
    secondary: {
      main: '#5269A3',
      light: '#7A828A',
    },
    common: {
      black: '#000000',
      darkBlue: '#0A163B',
      white: '#ffffff',
      appleGreen: '#97A408',
      red: '#F44336',
      lightGrey: '#F1F3F8',
      grey: '#CBCBCB',
    },
  },
  typography: {
    fontFamily: 'Mulish, sans-serif',
  },
});
