import React, { FC, useState } from 'react';
import { Button } from '@mui/material';
import { TranslatedField } from '../../../../../components/Layout/components/TranslatedField/TranslatedField';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { GetReviewsDocument } from '../../../../../graphql/queries/__generated__/getReviews';
import { useUpdateReviewMutation } from '../../../../../graphql/mutations/__generated__/updateReview';
import { getRefetchReviews } from '../../../../../graphql/queries/hook/useGetReviews';
import { useHandlerNotificationApp } from '../../../../../hooks/useHandlerNotificationApp';
interface Props {
  id: string;
  statusPublication: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
}

export const DeleteButton: FC<Props> = ({ id, statusPublication, setShow, show }) => {
  const [updateReviewMutation] = useUpdateReviewMutation();
  const { addNotification } = useHandlerNotificationApp();
  const handlerDelete = async () => {
    try {
      await updateReviewMutation({
        variables: {
          id,
          data: {
            publication: false,
          },
        },

        refetchQueries: [
          {
            query: GetReviewsDocument,
            variables: getRefetchReviews(),
          },
        ],
      });
      setShow(false);
      addNotification({
        messageError: 'Delete success!!!',
        typeMessage: 'success',
      });
    } catch (err: unknown) {
      if (err instanceof ErrorEvent) {
        addNotification({
          messageError: err?.message,
          typeMessage: 'error',
        });
      }
    }
  };
  return (
    <>
      {show && statusPublication && (
        <Button
          variant="outlined"
          startIcon={<DeleteForeverOutlinedIcon />}
          onClick={handlerDelete}
          sx={{
            textTransform: 'none',
            height: '50px',
            borderRadius: '10px',
            color: '#F44336',
            borderColor: '#F44336',
            borderWidth: '1.5px',
            marginY: '10px',
            width: '230px',
          }}
        >
          <TranslatedField noWrap isTranslate fontSize={16} originText={'DeleteReview'} />
        </Button>
      )}
    </>
  );
};
