/* eslint-disable no-console */
import React, { FC } from 'react';
import { Button, Stack } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { CustomModal } from '../../../../components';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useUpdateCustomerContactInfoMutation } from '../../../../graphql/mutations/__generated__/updateCustomerContactInfo';
import { useGetClientById } from '../../../../graphql/queries/hook/useGetClientById';
import { Input } from '../../../../legos';
import { CustomerContactInfoInput } from '../../../../__generated__/types';
import { theme } from '../../../../helpers';

import { useLocalization } from '../../../../localization';
import { useAddCustomerContactInfoMutation } from '../../../../graphql/mutations/__generated__/addCustomerContactInfo';
import { GetClientByIdDocument } from '../../../../graphql/queries/__generated__/getClintById';
import { useGetMe } from '../../../../graphql/queries/hook/useGetMe';

interface CredentianlsProps {
  clientId: string;
  closeModal(): void;
  openModal: boolean;
}
enum CredentialsField {
  CompanyName = 'companyName',
  VATId = 'VATId',
  FirstName = 'firstName',
  LastName = 'lastName',
  TypeClient = 'typeClint',
  Login = 'login',
}

export const CredentialsInformationsModal: FC<CredentianlsProps> = ({
  clientId,
  closeModal,
  openModal,
}) => {
  const { translateLang, updateSelectLanguage } = useLocalization();
  const { isSuperAdmin, isAccounter } = useGetMe();

  const {
    firstName,
    lastName,
    isContactInfoActive,
    contactInfoId,
    typeClient,
    userName,
    companyName,
    VATId,
  } = useGetClientById(clientId);
  const [runUpdateCustomer] = useUpdateCustomerContactInfoMutation();
  const [runAddCustomerContactInfo] = useAddCustomerContactInfoMutation();

  const initialValues = {
    [CredentialsField.CompanyName]: companyName ?? '',
    [CredentialsField.FirstName]: firstName,
    [CredentialsField.LastName]: lastName,
    [CredentialsField.TypeClient]: typeClient,
    [CredentialsField.Login]: userName,
    [CredentialsField.VATId]: VATId ?? '',
  };
  const validationSchema = yup.object({
    [CredentialsField.FirstName]: yup.string(),
    [CredentialsField.LastName]: yup.string(),
    [CredentialsField.TypeClient]: yup.string().required(translateLang('emptyInputError')),
    [CredentialsField.Login]: yup.string().required(translateLang('emptyInputError')),
    [CredentialsField.CompanyName]: yup.string(),
    [CredentialsField.VATId]: yup.string(),
  });
  const { values, errors, touched, handleChange, setErrors, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      const variables: CustomerContactInfoInput = {
        firstName: values[CredentialsField.FirstName],
        lastName: values[CredentialsField.LastName],
        ...(isSuperAdmin || isAccounter
          ? { companyName: values[CredentialsField.CompanyName] }
          : {}),
        ...(isSuperAdmin || isAccounter ? { VATId: values[CredentialsField.VATId] } : {}),
      };

      try {
        if (!isContactInfoActive) {
          variables.user = clientId;
          try {
            await runAddCustomerContactInfo({
              variables: {
                data: {
                  ...variables,
                },
              },
              refetchQueries: [
                {
                  query: GetClientByIdDocument,
                  variables: {
                    id: clientId,
                    locale: updateSelectLanguage as string,
                  },
                },
              ],
            });
          } catch (error) {
            console.log('%c Error! ', error);
          }
        } else {
          await runUpdateCustomer({
            variables: {
              data: {
                ...variables,
              },
              id: contactInfoId || '',
            },
            refetchQueries: !isContactInfoActive
              ? [
                  {
                    query: GetClientByIdDocument,
                    variables: {
                      id: clientId,
                      locale: updateSelectLanguage as string,
                    },
                  },
                ]
              : [],
          });
          closeModal();
        }
      } catch (error) {
        setErrors({
          [CredentialsField.FirstName]: error as string,
          [CredentialsField.TypeClient]: error as string,
          [CredentialsField.LastName]: error as string,
        });
      } finally {
        closeModal();
      }
    },
  });

  return (
    <CustomModal
      title={translateLang('contactInformation')}
      handleClose={closeModal}
      open={openModal}
    >
      <Stack component="form" flexGrow={1} spacing={2} onSubmit={handleSubmit}>
        {/*<FormControl fullWidth>
          <InputLabel>{translateLang('status')}</InputLabel>
          <Select
            value={values[CredentialsField.TypeClient]}
            label={translateLang('status')}
            onChange={event => setFieldValue(CredentialsField.TypeClient, event.target.value)}
            error={touched[CredentialsField.TypeClient] && !!errors[CredentialsField.TypeClient]}
          >
            <MenuItem value={RolesEnum.Customer}>
              {translateLang(RolesEnum.Customer)}
            </MenuItem>
            <MenuItem value={RolesEnum.Service}>
              {translateLang(RolesEnum.Service)}
            </MenuItem>
          </Select>
        </FormControl>*/}
        <Input
          disabled
          sx={{ maxWidth: '425px' }}
          labelColor={theme.palette.primary.light}
          label={translateLang('login')}
          placeholder={translateLang('placeHolderLogin')}
          name={CredentialsField.Login}
          value={values[CredentialsField.Login]}
          onChange={handleChange}
          error={touched[CredentialsField.Login] && !!errors[CredentialsField.Login]}
          helperText={touched[CredentialsField.Login] && errors[CredentialsField.Login]}
        />

        <Input
          sx={{ maxWidth: '425px' }}
          labelColor={theme.palette.primary.light}
          label={translateLang('clientName')}
          placeholder={translateLang('placeHolderName')}
          name={CredentialsField.FirstName}
          value={values[CredentialsField.FirstName]}
          onChange={handleChange}
          error={touched[CredentialsField.FirstName] && !!errors[CredentialsField.FirstName]}
          helperText={touched[CredentialsField.FirstName] && errors[CredentialsField.FirstName]}
        />
        <Input
          sx={{ maxWidth: '425px' }}
          label={translateLang('clientLastName')}
          placeholder={translateLang('placeHolderLastName')}
          name={CredentialsField.LastName}
          value={values[CredentialsField.LastName]}
          onChange={handleChange}
          error={touched[CredentialsField.LastName] && !!errors[CredentialsField.LastName]}
          helperText={touched[CredentialsField.LastName] && errors[CredentialsField.LastName]}
        />
        {isSuperAdmin || isAccounter ? (
          <>
            <Input
              sx={{ maxWidth: '425px' }}
              labelColor={theme.palette.primary.light}
              label={translateLang('companyName')}
              placeholder={translateLang('placeHolderName')}
              name={CredentialsField.CompanyName}
              value={values[CredentialsField.CompanyName]}
              onChange={handleChange}
              error={
                touched[CredentialsField.CompanyName] && !!errors[CredentialsField.CompanyName]
              }
              helperText={
                touched[CredentialsField.CompanyName] && errors[CredentialsField.CompanyName]
              }
            />
            <Input
              sx={{ maxWidth: '425px' }}
              label={translateLang('VATId')}
              placeholder={translateLang('placeHolderLastName')}
              name={CredentialsField.VATId}
              value={values[CredentialsField.VATId]}
              onChange={handleChange}
              error={touched[CredentialsField.VATId] && !!errors[CredentialsField.VATId]}
              helperText={touched[CredentialsField.VATId] && errors[CredentialsField.VATId]}
            />
          </>
        ) : null}

        <Button color="secondary" variant="contained" size="large" type="submit">
          <TranslatedField originText="saveChanges" isTranslate color="white" />
        </Button>
      </Stack>
    </CustomModal>
  );
};
