import React, { FC } from 'react';
import { Button, Fade, Modal, Stack } from '@mui/material';

import { TranslatedField } from '../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { theme } from '../../../../../helpers';

interface ExitModalFormProps {
  isExitModalOpen: boolean;
  toggleExitModal: () => void;
  handleCloseAddProduct: () => void;
}

export const ExitModalForm: FC<ExitModalFormProps> = ({
  isExitModalOpen,
  toggleExitModal,
  handleCloseAddProduct,
}) => {
  return (
    <Modal open={isExitModalOpen} onClose={toggleExitModal} closeAfterTransition>
      <Fade in={isExitModalOpen}>
        <Stack
          borderTop={`8px solid ${theme.palette.secondary.main}`}
          bgcolor="background.paper"
          sx={{
            p: 4,
            top: '50%',
            width: 400,
            left: '50%',
            boxShadow: 24,
            borderRadius: 2,
            position: 'absolute' as const,
            transform: 'translate(-50%, -50%)',
          }}
        >
          <TranslatedField
            isTranslate
            variant="h5"
            color={theme.palette.secondary.main}
            marginBottom="10px"
            originText={'youWantToLeave'}
          />
          <TranslatedField
            isTranslate
            variant="body1"
            color="#191516"
            marginBottom="40px"
            originText={'dataWillBeLost'}
          />
          <Stack flexDirection="row" gap={5}>
            <Button
              variant="outlined"
              fullWidth
              sx={{
                height: 50,
                p: 2,
                textTransform: 'none',
                borderRadius: '10px',
              }}
              onClick={toggleExitModal}
            >
              <TranslatedField originText={'cancel'} fontSize={16} isTranslate noWrap />
            </Button>
            <Button
              variant="contained"
              fullWidth
              sx={{
                height: 50,
                p: 2,
                textTransform: 'none',
                backgroundColor: '#5269A3',
                borderRadius: '10px',
              }}
              onClick={handleCloseAddProduct}
            >
              <TranslatedField originText={'yesClose'} fontSize={16} isTranslate noWrap />
            </Button>
          </Stack>
        </Stack>
      </Fade>
    </Modal>
  );
};
