import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { InputAdornment, StandardTextFieldProps, TextField, Tooltip } from '@mui/material';
import { Search } from '@mui/icons-material';

interface Props extends StandardTextFieldProps {
  executeWithDelay?: (text: string) => void;
  delay?: number;
  placeholder?: string;
  initStateText?: string;
  isManagerPanel?: boolean;
  endAdornment?: JSX.Element;
}

export const SearchField: FC<Props> = ({
  executeWithDelay,
  placeholder,
  delay = 1000,
  endAdornment,
  initStateText = '',
}) => {
  const [textSearch, setTextSearch] = useState('');
  const timer = useRef<NodeJS.Timer>();
  const firstChangeInput = useRef(false);

  const onChangeInput = (evt: ChangeEvent<HTMLInputElement>) => {
    firstChangeInput.current = true;
    setTextSearch(evt.target.value);
  };

  useEffect(() => {
    if (firstChangeInput.current) {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        if (executeWithDelay) {
          executeWithDelay(textSearch);
        }
      }, delay);
    }
  }, [textSearch]);

  useEffect(() => {
    setTextSearch(initStateText);
  }, [initStateText]);

  return (
    <TextField
      id="filled-basic"
      fullWidth
      variant="filled"
      value={textSearch}
      onChange={onChangeInput}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment,
      }}
      sx={{
        '& .MuiInputAdornment-root': {
          marginTop: '0 !important',
        },
        '& input': {
          padding: '18px 17px',
        },
        '& .MuiFilledInput-root': {
          backgroundColor: 'rgba(255,255,255,1)',
        },
      }}
    />
  );
};
