import { useState } from 'react';

import {
  GetOrdersQuery,
  useGetOrdersLazyQuery,
  useGetOrdersQuery,
} from '../__generated__/getOrders';
import { PaginationType } from './useGetOrders';
import { PAGE_COUNT } from '../../../helpers/constants';
import { useLocalization } from '../../../localization';
import { getPhone, handlerError } from '../../../helpers/functions';
import { Enum_Order_Status, OrderFiltersInput } from '../../../__generated__/types';
import { getUserFullName } from '../../../utils/getUserFullName';
import { OrderFragment } from '../../fragments/__generated__/order';
import { formatOrderNumber } from '../../../utils/formatOrderNumber';

type AddressType = NonNullable<OrderFragment>['deliveryAddress'];

export interface AllOrdersDeliveryType {
  orderId: string;
  orderDate: string;
  customerPhone: string;
  courierId: string;
  courier: string;
  deliveryAddress?: AddressType;
  customerId: string;
  customerInfo: string;
  deliveryTime?: string;
  status?: Enum_Order_Status;
  orderNumber?: string | null;
}
interface ReturnHook {
  allOrdersDelivery: Array<AllOrdersDeliveryType>;
  loading: boolean;
  page: number;
  totalPage: number;
  pageSize: number;
  changePage(page: number): void;
}

interface ReturnHookLazy
  extends Pick<ReturnHook, 'allOrdersDelivery' | 'loading' | 'totalPage' | 'pageSize'> {
  getOrdersData(): void;
}
interface PropsHook {
  filters?: OrderFiltersInput;
  pagination?: PaginationType;
}
export const useLazyGetAllOrdersDelivery = (page: number): ReturnHookLazy => {
  const { updateSelectLanguage } = useLocalization();
  const [allOrdersDelivery, setAllOrdersDelivery] = useState<Array<AllOrdersDeliveryType>>([]);
  const [totalPage, setTotalPage] = useState(0);
  const pageSize = 2;
  const [runGetOrders, { loading }] = useGetOrdersLazyQuery({
    variables: {
      pagination: {
        page,
        pageSize,
      },
      filters: {
        status: {
          containsi: Enum_Order_Status.Delivery,
        },
      },
      locale: updateSelectLanguage as string,
    },
  });
  const getOrdersData = async () => {
    try {
      const response = await runGetOrders();
      if (response.data) {
        const orders = getOrdersNormalize(response.data);
        setAllOrdersDelivery(orders);
        setTotalPage(response.data.orders?.meta.pagination.total || 0);
      }
    } catch (error: unknown) {
      handlerError(error);
    }
  };

  return {
    allOrdersDelivery,
    loading,
    totalPage,
    pageSize,
    getOrdersData,
  };
};

export const useGetAllOrdersDelivery = ({ filters }: PropsHook): ReturnHook => {
  const { updateSelectLanguage } = useLocalization();
  const [page, setPage] = useState(1);

  const { data, loading } = useGetOrdersQuery({
    variables: {
      pagination: {
        page: page,
        pageSize: PAGE_COUNT,
      },
      filters: filters
        ? filters
        : {
            or: [
              {
                status: {
                  containsi: Enum_Order_Status.PickedUp,
                },
              },
              {
                status: {
                  containsi: Enum_Order_Status.Delivery,
                },
              },
            ],
          },
      sort: ['date:desc', 'orderNumber', 'orderNumberAdd'],
      locale: updateSelectLanguage as string,
    },
  });

  const changePage = (_page: number): void => {
    setPage(_page + 1);
  };
  return {
    allOrdersDelivery: getOrdersNormalize(data),
    loading,
    page,
    pageSize: data?.orders?.meta.pagination.total || 0,
    totalPage: data?.orders?.meta.pagination.total
      ? Math.ceil(data?.orders?.meta.pagination.total / PAGE_COUNT)
      : 0,
    changePage,
  };
};

function getOrdersNormalize(data: GetOrdersQuery | undefined): Array<AllOrdersDeliveryType> {
  const allOrdersDelivery: Array<AllOrdersDeliveryType> = [];

  if (data?.orders?.data.length) {
    data.orders.data.forEach(item => {
      const orderNumber = formatOrderNumber(
        item.attributes?.orderNumber,
        item.attributes?.orderNumberAdd
      );

      allOrdersDelivery.push({
        courier: getUserFullName(item.attributes?.courier?.data),
        courierId: item.attributes?.courier?.data?.id || '',
        customerPhone:
          getPhone(
            item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes
              ?.phoneNumbers
          ) || '',
        orderId: item.id || '',
        orderNumber,
        orderDate: item.attributes?.date,
        deliveryAddress: item.attributes?.deliveryAddress,
        customerInfo: item.attributes?.customer?.data?.attributes?.customer_contact_info?.data
          ? `${
              item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes
                ?.firstName || ''
            } ${
              item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes
                ?.lastName || ''
            }\xa0  #${item.attributes?.customer?.data?.id}`
          : `#${item.attributes?.customer?.data?.id}`,
        deliveryTime: item.attributes?.deliveryDate,
        status: item.attributes?.status,
        customerId: item.attributes?.customer?.data?.id || '',
      });
    });
  }
  return allOrdersDelivery;
}
