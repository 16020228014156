type GetUserFullNameParams = {
  attributes?: {
    username?: string | null;
    customer_contact_info?: {
      data?: {
        attributes?: {
          lastName?: string | null;
          firstName?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export const getUserFullName = (data?: GetUserFullNameParams | null) => {
  const { lastName, firstName } = data?.attributes?.customer_contact_info?.data?.attributes ?? {};
  if (lastName || firstName) {
    return formatClientName(firstName, lastName);
  } else {
    return data?.attributes?.username ?? '';
  }
};

export const formatClientName = (
  firstName?: string | null,
  lastName?: string | null,
  companyName?: string | null
) =>
  `${firstName ?? ''}${firstName && lastName ? ` ${lastName}` : lastName ?? ''}` ||
  companyName ||
  '';
