import React, { useMemo } from 'react';
import { theme } from '../../../../../../helpers';
import { TransactionType } from './hooks/useGetTransactions';
import { useLocalization } from '../../../../../../localization';
import { Enum_Customeraccounttransaction_Documenttype } from '../../../../../../__generated__/types';
import { TableProductActive } from '../../../../../../components/TableProductActive/TableProductActive';
import {
  GetOrderByIdQuery,
  useGetOrderByIdLazyQuery,
} from '../../../../../../graphql/queries/__generated__/getOrderById';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { formatDate } from '../../../../../../helpers/functions';
export const ClientTransactionRowItem = ({
  transaction,
  action,
}: {
  transaction: TransactionType;
  action: (
    transaction: TransactionType,
    type: 'edit' | 'open' | 'delete',
    order?: GetOrderByIdQuery
  ) => void;
}) => {
  const { translateLang, selectLanguages } = useLocalization();
  const [runGetOrderById, { data: orderData, loading: orderTotalLoading }] =
    useGetOrderByIdLazyQuery();

  // TODO refactor this block
  if (!orderData && !orderTotalLoading && transaction?.documentId) {
    runGetOrderById({
      variables: {
        id: `${transaction?.documentId}`,
        locale: selectLanguages as string,
      },
    });
  }

  const { id, paymentDate, total, documentType, paymentNumber, comment } = transaction;

  const paymentIncoming =
    documentType === Enum_Customeraccounttransaction_Documenttype.PaymentIncomingNonCash ||
    documentType === Enum_Customeraccounttransaction_Documenttype.PaymentIncomingCash;

  const paymentOutgoing =
    documentType === Enum_Customeraccounttransaction_Documenttype.PaymentOutgoingCash ||
    documentType === Enum_Customeraccounttransaction_Documenttype.PaymentOutgoingNonCash;

  const sum =
    paymentIncoming || documentType === Enum_Customeraccounttransaction_Documenttype.Return
      ? `+ ${total} €`
      : paymentOutgoing || documentType === Enum_Customeraccounttransaction_Documenttype.Order
      ? `- ${total} €`
      : `${total} €`;

  const description = useMemo(() => {
    return documentType === Enum_Customeraccounttransaction_Documenttype.PaymentOutgoingCash
      ? translateLang('withdrawCash')
      : documentType === Enum_Customeraccounttransaction_Documenttype.PaymentOutgoingNonCash
      ? translateLang('withdrawBankTransfer')
      : documentType === Enum_Customeraccounttransaction_Documenttype.PaymentIncomingCash
      ? translateLang('fundPACash')
      : documentType === Enum_Customeraccounttransaction_Documenttype.PaymentIncomingNonCash
      ? translateLang('fundPABankTransfer')
      : documentType === Enum_Customeraccounttransaction_Documenttype.Return
      ? translateLang('refunds')
      : (orderData?.order?.data?.attributes?.total || 0) > (total || 0)
      ? `${translateLang('partialPaymentOrder')} #${paymentNumber}`
      : `${translateLang('orderPayment')} #${paymentNumber}`;
  }, [orderData?.order?.data?.attributes?.total, selectLanguages]);

  return (
    <TableProductActive.RowTable>
      <TableProductActive.CellTable ceilStyle={{ width: '40px', borderLeft: 'none' }}>
        {id}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ width: '200px', borderLeft: 'none' }}>
        {formatDate(new Date(paymentDate))}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable
        ceilStyle={{ width: '60px', alignItems: 'center', borderLeft: 'none' }}
      >
        {paymentIncoming
          ? translateLang('fund')
          : paymentOutgoing
          ? translateLang('withdrawal')
          : documentType === Enum_Customeraccounttransaction_Documenttype.Order
          ? translateLang('order')
          : translateLang('return')}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable
        ceilStyle={{
          width: '60px',
          alignItems: 'center',
          borderLeft: 'none',
          color:
            paymentIncoming || documentType === Enum_Customeraccounttransaction_Documenttype.Return
              ? theme.palette.common.appleGreen
              : paymentOutgoing ||
                documentType === Enum_Customeraccounttransaction_Documenttype.Order
              ? theme.palette.common.red
              : undefined,
        }}
      >
        {sum}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable
        ceilStyle={{ width: '60px', alignItems: 'end', borderLeft: 'none' }}
      >
        {!orderTotalLoading ? description : <CircularProgress size={16} />}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable
        ceilStyle={{ width: '60px', alignItems: 'end', borderLeft: 'none' }}
      >
        {comment}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ alignItems: 'end', padding: 1 }}>
        {transaction.documentType === Enum_Customeraccounttransaction_Documenttype.Order ||
        transaction.documentType ===
          Enum_Customeraccounttransaction_Documenttype.PaymentIncomingCash ||
        transaction.documentType ===
          Enum_Customeraccounttransaction_Documenttype.PaymentIncomingNonCash ||
        transaction.documentType ===
          Enum_Customeraccounttransaction_Documenttype.PaymentOutgoingCash ||
        transaction.documentType ===
          Enum_Customeraccounttransaction_Documenttype.PaymentOutgoingNonCash ? (
          <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
            <IconButton
              onClick={() => {
                action(transaction, 'edit', orderData);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={() => {
                action(transaction, 'delete');
              }}
            >
              <Delete />
            </IconButton>
          </Box>
        ) : (
          <Box sx={{ width: '96px', height: 40 }} />
        )}
      </TableProductActive.CellTable>
    </TableProductActive.RowTable>
  );
};
