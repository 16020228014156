import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { useLocalization } from '../../../localization';
interface OrderComentProps {
  comment?: string | null | undefined;
  orderAmount?: number | null | undefined;
}
export const OrderComment: FC<OrderComentProps> = ({ comment, orderAmount }) => {
  const { translateLang } = useLocalization();

  return (
    <Stack sx={{ flexGrow: 1 }} justifyContent="space-between">
      <Stack
        sx={{
          color: '#7A828A',
          paddingLeft: '16px',
          paddingTop: '14px',
          paddingBottom: '14px',
          backgroundColor: '#F1F3F8',
          borderRadius: '6px',
          width: { xs: '100%', sm: '50%' },
          padding: '10px',
          marginTop: '30px',
        }}
      >
        <Typography
          sx={{
            color: '##2A3453',
            paddingLeft: '16px',
            paddingTop: '14px',
            paddingBottom: '14px',
            maxHeight: '46px',
            minWidth: '100px',
          }}
        >
          {translateLang('comment')}:
        </Typography>
        <Typography
          sx={{ color: '##2A3453', paddingLeft: '16px', paddingTop: '14px', paddingBottom: '14px' }}
        >
          {comment}
        </Typography>
      </Stack>

      <Typography
        sx={{
          lineHeight: '37.65px',
          fontSize: '30px',
          fontWeight: '400',
          color: '#2A3453',
          textAlign: 'right',
        }}
      >
        {translateLang('orderAmount')}: {`${orderAmount?.toFixed(2)}`} €
      </Typography>
    </Stack>
  );
};
