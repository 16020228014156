import React, { FC, useEffect, useRef } from 'react';
import { VideoFrame } from './VideoFrame';

interface Props {
  scanResult: string;
  scaningError: null;
  setScanResult: React.Dispatch<React.SetStateAction<string>>;
  setScaningError: React.Dispatch<React.SetStateAction<null>>;
  onChange(code: string): void;
}
declare const BarcodeDetector: any;
declare const ImageCapture: any;

const BarcodeS: FC<Props> = ({ setScanResult, onChange }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const streamRef = useRef<MediaStream>();
  const animation = useRef(true);

  const getVideoStream = async () => {
    return await navigator.mediaDevices.getUserMedia({
      video: { facingMode: 'environment', frameRate: 10 },
      audio: false,
    });
  };
  const stopStreamedVideo = (stream: MediaStream) => {
    const video = videoRef.current;
    if (stream) {
      const tracks = stream.getTracks();
      if (tracks.length) {
        tracks[0].stop();
      }
      if (video) {
        video.srcObject = null;
      }
    }
  };

  const detectBarcode = async () => {
    const video = videoRef.current;

    const barcodeDetector = new BarcodeDetector({
      formats: ['code_39', 'code_128', 'codabar', 'ean_13'],
    });
    if (video) {
      try {
        const mediaStream = video.srcObject;
        if (!(mediaStream instanceof MediaStream)) {
          console.error('Invalid media stream.');
          return;
        }
        const videoTracks = mediaStream.getVideoTracks();
        if (videoTracks.length === 0) {
          console.error('No video tracks available.');
          return;
        }
        const imageCapture = new ImageCapture(videoTracks[0]);
        const frame = await imageCapture.grabFrame();
        const barcodes = await barcodeDetector.detect(frame);
        if (barcodes.length > 0) {
          animation.current = false;
          const barcodeValues = barcodes.map((barcode: { rawValue: any }) => barcode.rawValue);
          setScanResult(barcodeValues[0]);
          onChange(barcodeValues[0]);
        }
        // console.log('%c Check! ');
        if (animation.current) {
          requestAnimationFrame(detectBarcode);
        }
      } catch (error) {
        console.error('Error detecting barcode:', error);
      }
    }
  };
  const runBarCodeDetector = async () => {
    const stream = await getVideoStream();
    if (!stream) {
      return;
    }
    streamRef.current = stream;
    const video = videoRef.current;

    if (video) {
      video.srcObject = stream;
      video.onloadedmetadata = async () => {
        await video.play();
        await detectBarcode();
      };
    }
  };

  useEffect(() => {
    if (!('BarcodeDetector' in window)) {
      console.log('BarcodeDetector is not supported in this browser');
      return;
    }

    runBarCodeDetector().then();
    return () => {
      if (streamRef.current) {
        stopStreamedVideo(streamRef.current);
      }
    };
  }, []);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      {'BarcodeDetector' in window ? (
        <div style={{ position: 'relative' }}>
          <video ref={videoRef} autoPlay={true} />
          <VideoFrame />
        </div>
      ) : (
        <span>Barcode detection is not supported in this browser</span>
      )}
    </div>
  );
};

export default BarcodeS;
