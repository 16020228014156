import React, { FC, ReactNode } from 'react';
import { Box } from '@mui/material';

interface Props {
  helperText?: ReactNode;
  error?: boolean;
  infoText?: string;
}
export const CustomHelperText: FC<Props> = ({ helperText, error, infoText }) => {
  const isError = helperText && error;
  return (
    <Box color={isError ? '#F44336' : '#000'} fontSize="12px" ml="4px" mt="1px" position="absolute">
      {isError && helperText}
      {!isError && infoText}
    </Box>
  );
};
