import React, { FC } from 'react';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import { theme } from '../../helpers';
import { downloadImage } from '../../helpers/downloadImage';

interface Props {
  chosenPhoto: any;
}

export const CarouselPreview: FC<Props> = ({ chosenPhoto }) => {
  const xsScreen = useMediaQuery(`(max-width:${600}px)`);
  const lgScreen = useMediaQuery(`(max-width:${900}px)`);

  return (
    <Stack
      width="100%"
      position="relative"
      height={xsScreen ? 300 : 500}
      bgcolor="white.main"
      p={xsScreen ? 3 : 6}
      order={xsScreen ? 1 : 2}
      borderRadius="10px"
      boxShadow="0px 0px 1px rgba(25, 21, 22, 0.25)"
    >
      {/* <Stack
        bgcolor={theme.palette.common.appleGreen}
        height={32}
        position="absolute"
        px={1.25}
        top={10}
        left={10}
        zIndex={1}
        alignItems="center"
        justifyContent="center"
        borderRadius="5px"
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          downloadImage(chosenPhoto);
        }}
      >
        <Typography color={theme.palette.common.white}>Download</Typography>
      </Stack> */}
      <Stack
        position="relative"
        width="100%"
        justifyContent="center"
        alignItems="center"
        height={xsScreen ? '300px' : lgScreen ? '500px' : '100%'}
      >
        <img
          src={chosenPhoto}
          alt={'preview-photo'}
          style={{
            maxHeight: '100%',
          }}
        />
      </Stack>
    </Stack>
  );
};
