import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Stack } from '@mui/material';
import { RemoveRedEyeOutlined } from '@mui/icons-material';

import { PatchesName } from '../../../../../../types/types';
import { formatPhoneNumber } from '../../../../../../helpers/functions';
import { ClientType } from '../../../../../../graphql/queries/hook/useGetClients';
import { TableProductActive } from '../../../../../../components/TableProductActive/TableProductActive';

export const AllClientsAccountingRowItem = ({ client }: { client: ClientType }) => {
  const navigate = useNavigate();
  const { id, firstName, lastName, clientType, phoneNumbers, primaryPhoneNumber } = client;

  let userName = client.userName;
  if (lastName && firstName) {
    userName = `${firstName} ${lastName}`;
  }
  let clientNumbers = primaryPhoneNumber;

  if (phoneNumbers?.length) {
    clientNumbers = phoneNumbers.map(item => item?.number || '').join(', ') || '';
  }

  const handleGoToClientPage = () => {
    navigate(`/${PatchesName.Clients}/${id}/`);
  };

  return (
    <TableProductActive.RowTable>
      <TableProductActive.CellTable ceilStyle={{ width: '40px', borderLeft: 'none' }}>
        {`#${id}`}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ width: '200px' }}>
        {userName}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ width: '60px', alignItems: 'center' }}>
        {clientType}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ width: '60px', alignItems: 'center' }}>
        {formatPhoneNumber(clientNumbers)}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ width: '60px', alignItems: 'end' }}>
        <Stack flexDirection="row" gap={1.25}>
          <IconButton onClick={handleGoToClientPage}>
            <RemoveRedEyeOutlined />
          </IconButton>
        </Stack>
      </TableProductActive.CellTable>
    </TableProductActive.RowTable>
  );
};
