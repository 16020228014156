import React, { useEffect } from 'react';
import { SnackbarKey, useSnackbar, VariantType } from 'notistack';
import { IconButton } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { useSetMessageQuery } from '../graphql/cacheQuery/__generated__/setMessageQraphQL';
// import { GraphQLErrors } from '@apollo/client/errors';

type AddNotificationProps = {
  messageError?: string;
  typeMessage?: VariantType;
};

interface ReturnHook {
  addNotification(options: AddNotificationProps): void;
}

// type TransitionProps = Omit<SlideProps, 'direction'>;

export const useHandlerNotificationApp = (): ReturnHook => {
  const { data } = useSetMessageQuery();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // function transitionLeft(props: TransitionProps) {
  //   return <Slide {...props} direction="left" />;
  // }

  const action = (snackbarId: SnackbarKey | undefined) => (
    <IconButton
      aria-label="close"
      color="inherit"
      sx={{ p: 0.5 }}
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
    >
      <CloseOutlined />
    </IconButton>
  );
  const addNotification = (options: AddNotificationProps) => {
    const { messageError = '', typeMessage = 'info' } = options;
    enqueueSnackbar(messageError, {
      variant: typeMessage,
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      action,
      // TransitionComponent: transitionLeft,
    });
  };

  useEffect(() => {
    if (data && data?.messageError?.message) {
      addNotification({
        messageError: data.messageError.message || 'Something went wrong...',
        typeMessage: 'error',
      });
    }
  }, [data]);

  return {
    addNotification,
  };
};
