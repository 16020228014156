import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Grid, Pagination, Stack, Typography } from '@mui/material';

import { theme } from '../../../../helpers';
import { PatchesName } from '../../../../types/types';
import { useGetSupplierBalanceLazyQuery } from '../../../../graphql/queries/__generated__/getSupplierBalance';
import { Add } from '@mui/icons-material';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { CreateNewSupplier } from './CreateNewSupplierDialog';
import { PAGE_COUNT } from '../../../../helpers/constants';
import { useGetSuppliers } from '../../../../graphql/queries/hook/useGetSuppliers';

interface SuppliersType {
  supplierBalance?: number | null;
  remainingPayment?: number | null;
  supplierName: string | null | undefined;
  id?: string | null;
}

export const SuppliersTab = () => {
  const navigate = useNavigate();

  const {
    data: suppliersData,
    refetch,
    page,
    changePage,
  } = useGetSuppliers({
    or: [{ blocked: { eq: false } }, { blocked: { eq: null } }],
  });

  const [runGetSupplierBalance] = useGetSupplierBalanceLazyQuery();
  const [isOpenCreateSupplierDialog, setIsOpenCreateSupplierDialog] = useState(false);

  const [loading, setLoading] = useState(true);
  const [suppliers, setSuppliers] = useState<SuppliersType[]>([]);

  const totalPages = suppliersData?.suppliers?.meta?.pagination.total
    ? Math.ceil(suppliersData?.suppliers?.meta?.pagination.total / PAGE_COUNT)
    : 0;

  useEffect(() => {
    const fetchSuppliersData = async () => {
      if (suppliersData?.suppliers?.data) {
        const supplierPromises = suppliersData.suppliers.data.map(async item => {
          const balanceData = await runGetSupplierBalance({
            variables: {
              input: { supplierId: item.id as string },
            },
          });

          return {
            supplierBalance: balanceData.data?.supplierBalance?.balance,
            remainingPayment: balanceData.data?.supplierBalance?.remainingPayment,
            supplierName: item.attributes?.name,
            id: item.id,
          };
        });

        const result = await Promise.all(supplierPromises);
        setSuppliers(result);
        setLoading(false);
      }
    };

    fetchSuppliersData();
  }, [suppliersData, runGetSupplierBalance]);

  const handleGoToSupplierPage = (supplierId: string) => {
    navigate(`/${PatchesName.Accounting}/providers/${supplierId}`);
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" gap={2}>
      <Button
        variant="contained"
        startIcon={<Add />}
        sx={{
          height: 50,
          p: 2,
          textTransform: 'none',
          backgroundColor: '#5269A3',
          borderRadius: '10px',
          alignSelf: 'flex-end',
        }}
        onClick={() => {
          setIsOpenCreateSupplierDialog(true);
        }}
      >
        <TranslatedField originText={'createSupplier'} fontSize={16} isTranslate noWrap />
      </Button>
      {!loading ? (
        <Grid container width={!loading ? '100%' : 'auto'} gap={2.5}>
          {suppliers?.map(item => (
            <Grid
              item
              container
              xs={6}
              md={4}
              lg={3}
              key={item.id}
              flexDirection="row"
              flexWrap="nowrap"
              alignItems="center"
              justifyContent="center"
              gap={1.25}
              p={2.5}
              bgcolor={theme.palette.common.white}
              borderRadius="10px"
              sx={{ cursor: 'pointer' }}
              onClick={() => handleGoToSupplierPage(item.id ?? '')}
            >
              <Typography fontSize={32} color={theme.palette.primary.main}>
                {item.supplierName}
              </Typography>
              <Typography
                fontSize={20}
                marginTop="5px"
                fontWeight={600}
                whiteSpace="nowrap"
                color={
                  item.supplierBalance && item.supplierBalance <= 0
                    ? theme.palette.common.red
                    : theme.palette.common.appleGreen
                }
              >
                {`${item.supplierBalance || 0} €`}
              </Typography>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Stack justifyContent="center" alignItems="center" mt={10}>
          <CircularProgress size={40} />
        </Stack>
      )}
      <Pagination
        sx={{
          '& .MuiPaginationItem-root': {
            '&.Mui-selected': {
              background: '#5269A3',
              color: 'white',
              borderRadius: '8px',
            },
          },
        }}
        count={totalPages}
        shape="rounded"
        page={page ?? 1}
        onChange={(event, value) => changePage(value)}
        defaultPage={1}
        siblingCount={3}
        boundaryCount={3}
      />
      <CreateNewSupplier
        refetchSuppliers={refetch}
        isModalOpen={isOpenCreateSupplierDialog}
        handleClose={() => setIsOpenCreateSupplierDialog(false)}
      />
    </Box>
  );
};
