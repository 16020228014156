import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, TextField, Typography, Zoom } from '@mui/material';
import { useLocalization } from '../../../localization';
import { useHandlerNotificationApp } from '../../../hooks/useHandlerNotificationApp';

interface Props {
  isOpen: boolean;
  onChange(code: string): void;
  loading?: boolean;
}

export const ScanComponent: FC<Props> = ({ isOpen, onChange, loading }) => {
  const { translateLang } = useLocalization();
  const [currentCode, setCurrentCode] = useState('');
  const { addNotification } = useHandlerNotificationApp();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isClearInput = useRef(false);

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (isClearInput.current) {
      isClearInput.current = false;
      const value = evt.target.value.at(-1);
      setCurrentCode(value || '');
    } else {
      setCurrentCode(evt.target.value);
    }
  };
  function onKeyPress(evt: KeyboardEvent) {
    if (evt.key === 'Enter') {
      isClearInput.current = true;
      if (inputRef.current) {
        const val = inputRef.current?.value.replace(/\/./g, '-');
        addNotification({
          messageError: `BARCODE: ${val}`,
          typeMessage: 'success',
        });
        onChange(val);
      }
    }
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current?.addEventListener('keypress', onKeyPress);
    }
    return () => {
      if (inputRef.current) {
        inputRef.current?.removeEventListener('keypress', onKeyPress);
      }
    };
  }, []);

  return (
    <Zoom in={isOpen} mountOnEnter unmountOnExit>
      <Box paddingX="100px">
        <Typography sx={{ fontWeight: 400, fontSize: '36px', lineHeight: '45px', mb: '20px' }}>
          {translateLang('scanProduct')}
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '20px', lineHeight: '30px' }}>
          {translateLang('runScanPoint')}
        </Typography>
        <Box mb={4} pt="100px">
          <TextField
            autoFocus
            variant="standard"
            value={currentCode}
            InputProps={{
              disableUnderline: true,
            }}
            InputLabelProps={{
              shrink: true,
              style: {
                color: '#2A3453',
                fontSize: '14px',
                fontWeight: '500',
                height: '17px',
                lineHeight: '17px',
              },
            }}
            sx={{
              pt: '100px',
              color: '#FFFFFF',
              paddingLeft: '16px',
              paddingTop: '14px',
              paddingBottom: '14px',
              backgroundColor: '#FFFFFF',
              borderRadius: '6px',
              width: '100%',
            }}
            inputRef={inputRef}
            onChange={handleChange}
          />
        </Box>
        {loading ? <CircularProgress size={30} color="secondary" /> : null}
      </Box>
    </Zoom>
  );
};
