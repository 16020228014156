import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { addDays, endOfToday, startOfDay, subDays } from 'date-fns';
import { Box, Stack, TextField, Autocomplete, UseAutocompleteProps, Button } from '@mui/material';

import { useLocalization } from '../../../../localization';
import {
  Enum_Newslettersubscription_Source,
  Enum_Newslettersubscription_Status,
  NewsletterSubscriptionFiltersInput,
} from '../../../../__generated__/types';
import { useDefaultRangeCalendar } from '../../../../hooks';
import { WrapperToolBar } from '../../../../components/WrapperToolBar';
import { RangeCalendar } from '../../../../legos/rangeCalendar/RangeCalendar';
import { useFiltersSearchParamsForOrders } from '../../../../hooks/useFiltersSearchParamsForOrders';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';

interface Props {
  setResetFilterOrders(): void;
  result?: string | number;
  setFilterSubscription: Dispatch<SetStateAction<NewsletterSubscriptionFiltersInput>>;
}

export type NewsletterStatusType = {
  label: string;
  value: Enum_Newslettersubscription_Status;
};
export type NewsletterSourceType = {
  label: string;
  value: Enum_Newslettersubscription_Source;
};

const newsletterSourceTypes: NewsletterSourceType[] = [
  ...[Enum_Newslettersubscription_Source.Footer, Enum_Newslettersubscription_Source.HomePage].map(
    value => ({
      label: value,
      value,
    })
  ),
];
const NewsletterStatusTypes: NewsletterStatusType[] = [
  ...[
    Enum_Newslettersubscription_Status.Archived,
    Enum_Newslettersubscription_Status.Processed,
    Enum_Newslettersubscription_Status.Unprocessed,
  ].map(value => ({
    label: value,
    value,
  })),
];

export const ToolBarNewSletter: FC<Props> = ({
  result,
  setFilterSubscription,
  setResetFilterOrders,
}) => {
  const { translateLang } = useLocalization();
  const { reportRangeDates } = useDefaultRangeCalendar();
  const { changeDate, selDateTo, selDateFrom, resetFilters } = useFiltersSearchParamsForOrders();

  const [currentValueManager, setCurrentValueManager] = useState<NewsletterSourceType | null>();
  const [currentValueStatus, setCurrentValueStatus] = useState<NewsletterStatusType | null>();
  const [selectDates, setSelectedDates] = useState(getDatesFilters);
  const startRef = useRef(false);
  const handlerReset = () => {
    setResetFilterOrders();
    resetFilters();
    setSelectedDates([startOfDay(subDays(new Date(), 30)), new Date()]);
  };
  const handlerChangesDates = (val: Date[]) => {
    startRef.current = true;
    setSelectedDates(val);
    changeDate(
      val[0]?.toISOString(),
      val[1] ? addDays(val[1], 1)?.toISOString() : addDays(val[0], 1).toISOString()
    );
  };

  const handlerClearFilter = (deleteField: 'status') => {
    setCurrentValueStatus(undefined);

    setFilterSubscription(oldFilter => {
      const reset = { ...oldFilter };
      delete reset[deleteField];
      return reset;
    });
  };
  const handleChangeStatus: UseAutocompleteProps<
    NewsletterStatusType,
    any,
    any,
    any
  >['onChange'] = (evt, option): void => {
    if (option) {
      let filter: NewsletterSubscriptionFiltersInput | undefined;
      if (typeof option !== 'string') {
        const _option = option as NewsletterStatusType;
        filter = {
          status: {
            eq: _option.value,
          },
        };
      }
      setCurrentValueStatus(option as NewsletterStatusType);
      setFilterSubscription(oldFilter => ({
        ...oldFilter,
        ...filter,
      }));
    }
  };

  const handleChangeManagers: UseAutocompleteProps<
    NewsletterSourceType,
    any,
    any,
    any
  >['onChange'] = (evt, option): void => {
    if (option) {
      let filter: NewsletterSubscriptionFiltersInput | undefined;
      if (typeof option !== 'string') {
        const _option = option as NewsletterSourceType;
        filter = {
          source: {
            eq: _option.value,
          },
        };
      }
      setCurrentValueManager(option as NewsletterSourceType);
      setFilterSubscription(oldFilter => ({
        ...oldFilter,
        ...filter,
      }));
    }
  };

  useEffect(() => {
    setFilterSubscription(oldFilter => ({
      ...oldFilter,
      createdAt: {
        between: [
          selDateFrom !== '' ? selDateFrom : startOfDay(subDays(new Date(), 30)).toISOString(),
          selDateTo !== '' ? selDateTo : endOfToday().toISOString(),
        ],
      },
    }));
  }, [selectDates]);

  function getDatesFilters(): Date[] {
    if (selDateFrom && selDateTo) {
      return [new Date(selDateFrom), new Date(selDateTo)];
    }
    return [startOfDay(subDays(new Date(), 30)), endOfToday()];
  }

  return (
    <WrapperToolBar>
      <Stack flexDirection="row" justifyContent="left">
        <TranslatedField
          fontSize={{ sm: '20px', md: '30px' }}
          originText={`${translateLang('result')}: ${result}` || ''}
        />
      </Stack>
      <Stack direction="row" spacing={3} alignItems="center">
        <TranslatedField originText={translateLang('source')} />
        <Autocomplete
          size="small"
          disablePortal
          id="combo-box-demo"
          sx={{ minWidth: 200, mt: 0 }}
          value={currentValueManager}
          options={newsletterSourceTypes || []}
          renderInput={params => <TextField {...params} label={translateLang('source')} />}
          getOptionLabel={option => {
            return option?.value || '';
          }}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={translateLang(`${option.value}`)}>
              {translateLang(`${option.value}`)}
            </Box>
          )}
          onChange={handleChangeManagers}
          onInputChange={(e, value, reason) => {
            if (reason === 'clear') {
              handlerClearFilter('status');
            }
          }}
        />
        <TranslatedField originText={translateLang('status')} />
        <Autocomplete
          size="small"
          disablePortal
          id="combo-box-demo"
          sx={{ minWidth: 200 }}
          value={currentValueStatus}
          options={NewsletterStatusTypes || []}
          renderInput={params => <TextField {...params} label={translateLang('status')} />}
          getOptionLabel={option => {
            return option?.value || '';
          }}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.label}>
              {translateLang(option.value)}
            </Box>
          )}
          onChange={handleChangeStatus}
          onInputChange={(e, value, reason) => {
            if (reason === 'clear') {
              handlerClearFilter('status');
            }
          }}
        />
        <RangeCalendar
          selectedDates={selectDates}
          defaultRangeDates={reportRangeDates}
          setSelectedDates={handlerChangesDates}
        />
        <Button
          color="primary"
          variant="outlined"
          onClick={handlerReset}
          style={{
            width: '100%',
            textTransform: 'none',
          }}
        >
          <TranslatedField
            noWrap
            isTranslate
            fontSize={16}
            color="black"
            originText="resetFilters"
          />
        </Button>
      </Stack>
    </WrapperToolBar>
  );
};
