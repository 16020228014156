import React, { FC } from 'react';
import * as yup from 'yup';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import {
  Enum_Customeraccounttransaction_Documenttype,
  Enum_Customerpayment_Paymentdirection,
  Enum_Customerpayment_Paymentmethod,
} from '../../../../../../__generated__/types';
import { CustomerPaymentFields } from '../../../CustomerPaymentModal/types';
import { Icon, Input } from '../../../../../../legos';
import { theme } from '../../../../../../helpers';
import { TranslatedField } from '../../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useUpdateCustomerAccountTransactionMutation } from '../../../../../../graphql/mutations/__generated__/updateCustomerAccountTransaction';
import { useFormik } from 'formik';
import { useLocalization } from '../../../../../../localization';
import { useHandlerNotificationApp } from '../../../../../../hooks/useHandlerNotificationApp';
import { formatCurrencyAmount } from '../../../../../../helpers/functions';
import { TransactionType } from './hooks/useGetTransactions';
import { useGetClientBalance } from './hooks/useGetClientBalance';
import {
  GetOrderByIdDocument,
  GetOrderByIdQuery,
} from '../../../../../../graphql/queries/__generated__/getOrderById';
import { formatOrderNumber } from '../../../../../../utils/formatOrderNumber';
import { formatCurrency } from '../../../../../../utils/currencyUtils';
import { GetCustomerBalanceDocument } from '../../../../../../graphql/queries/__generated__/getCustomerBalance';
import { GetCustomerAccountsDocument } from '../../../../../../graphql/queries/__generated__/getCustomerAccounts';
import { formatDate } from '../../../../../../helpers/functions';

export type InitialValues = {
  id: string;
  [CustomerPaymentFields.Amount]: string;
  [CustomerPaymentFields.Description]: string;
  typePA: Enum_Customerpayment_Paymentdirection;
  paymentMethod: Enum_Customerpayment_Paymentmethod | null;
};

interface EditCustomerTransactionModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
  transaction: TransactionType | null;
  order?: GetOrderByIdQuery['order'] | null;
  userId: string;
}

export const EditCustomerTransactionModal: FC<EditCustomerTransactionModalProps> = ({
  isModalOpen,
  handleClose,
  transaction,
  order,
  userId,
  refetch,
}) => {
  const { translateLang, updateSelectLanguage } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();

  const refetchQueries = [];

  if (transaction?.documentType === Enum_Customeraccounttransaction_Documenttype.Order) {
    refetchQueries.push({
      query: GetOrderByIdDocument,
      variables: {
        id: transaction?.documentId as number,
        locale: updateSelectLanguage as string,
      },
    });
  }

  refetchQueries.push(
    {
      query: GetCustomerBalanceDocument,
      variables: {
        input: {
          customerId: userId,
        },
      },
    },
    {
      query: GetCustomerAccountsDocument,
      variables: {
        pagination: {},
        sort: [],
        filters: { customerId: { eq: userId } },
      },
    }
  );

  const [updateCustomerAccountTransaction, { loading: updateCustomerAccountTransactionLoading }] =
    useUpdateCustomerAccountTransactionMutation({
      refetchQueries,
    });
  const { customerAccount } = useGetClientBalance(userId);

  const paymentDirection =
    transaction?.documentType ===
      Enum_Customeraccounttransaction_Documenttype.PaymentIncomingCash ||
    transaction?.documentType ===
      Enum_Customeraccounttransaction_Documenttype.PaymentIncomingNonCash
      ? 'fundPA'
      : transaction?.documentType ===
          Enum_Customeraccounttransaction_Documenttype.PaymentOutgoingCash ||
        transaction?.documentType ===
          Enum_Customeraccounttransaction_Documenttype.PaymentOutgoingNonCash
      ? 'withdrawalOfOR'
      : 'orderPayment';

  const validationSchema = yup.object({
    total: yup
      .number()
      .typeError(translateLang('mustBeNumber'))
      .moreThan(0, translateLang('amountMoreThanZero'))
      .required(translateLang('emptyInputError')),
    comment: yup.string().required(translateLang('emptyInputError')),
  });

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, setFieldError } =
    useFormik({
      initialValues: {
        total: `${transaction?.total ?? '0'}`,
        comment: transaction?.comment ?? '',
      },
      enableReinitialize: true,
      validationSchema,
      onSubmit: ({ comment, total }) => {
        if (transaction?.id)
          updateCustomerAccountTransaction({
            variables: {
              id: transaction.id,
              data: {
                comment,
                total: parseFloat(total),
              },
            },
          })
            .then(() => {
              refetch();
              handleClose();
            })
            .catch(err => {
              addNotification({ messageError: err.message, typeMessage: 'error' });
            });
      },
    });

  return (
    <Dialog open={isModalOpen} onClose={handleClose} closeAfterTransition maxWidth="sm" fullWidth>
      <DialogTitle display="flex" flexDirection="row" justifyContent="space-between">
        <TranslatedField
          flex={1}
          textAlign="center"
          isTranslate
          variant="h5"
          color={theme.palette.common.darkBlue}
          marginBottom={5}
          originText="editTransaction"
        />
        <IconButton onClick={handleClose} sx={{ width: 40, height: 40 }}>
          <Icon icon="close" sx={{ color: theme.palette.primary.main }} />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Stack alignItems="center">
          <TranslatedField
            isTranslate
            variant="h4"
            color={theme.palette.common.darkBlue}
            marginBottom={2.5}
            fontSize={30}
            originText={paymentDirection}
          />
          <Typography fontSize={20} fontWeight={300} color={theme.palette.common.black} mb={2.5}>
            {translateLang('PAAmount')}
            {': '}
            <span style={{ fontWeight: 500, color: '#97A408' }}>{`${
              (customerAccount || 0) > 0 ? '+' : ''
            } ${customerAccount}€`}</span>
          </Typography>

          {transaction?.documentType === Enum_Customeraccounttransaction_Documenttype.Order ? (
            <Box alignSelf="flex-start">
              <TranslatedField
                isTranslate
                variant="h4"
                color={theme.palette.common.darkBlue}
                fontSize={16}
                originText="numberOrder"
                suffix={`: #${formatOrderNumber(
                  order?.data?.attributes?.orderNumber ?? '',
                  order?.data?.attributes?.orderNumberAdd
                )}`}
              />
              <TranslatedField
                isTranslate
                variant="h4"
                color={theme.palette.common.darkBlue}
                fontSize={16}
                originText="orderDate"
                suffix={`: ${formatDate(order?.data?.attributes?.date)}`}
              />
              <TranslatedField
                isTranslate
                variant="h4"
                color={theme.palette.common.darkBlue}
                fontSize={16}
                originText="orderAmount"
                suffix={`: ${formatCurrency(order?.data?.attributes?.total)} €`}
              />
              <TranslatedField
                isTranslate
                variant="h4"
                color={theme.palette.common.darkBlue}
                fontSize={16}
                marginBottom={2}
                originText="debtAmount"
                suffix={`: ${formatCurrency(
                  (order?.data?.attributes?.total ?? 0) -
                    (order?.data?.attributes?.paymentTotal ?? 0)
                )} €`}
              />
            </Box>
          ) : null}
        </Stack>

        <Stack component="form" mb={2} onSubmit={handleSubmit}>
          <Stack
            height={75}
            justifyContent="flex-start"
            flexDirection="row"
            alignItems="baseline"
            gap={3}
          >
            <TranslatedField
              flex={1}
              originText={'amount'}
              fontSize={16}
              isTranslate
              noWrap
              overflow="initial"
            />
            <Box flex={3}>
              <Input
                name="total"
                value={`${values.total}€`}
                onChange={event => {
                  const value = formatCurrencyAmount(event.target.value);
                  setFieldValue('total', value);
                }}
                error={touched.total && !!errors.total}
                helperText={touched.total && errors.total}
                inputProps={{
                  style: {
                    textAlign: 'center',
                    paddingLeft: 0,
                    color: theme.palette.common.black,
                    boxShadow: ' 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset',
                    height: 12,
                    width: '50%',
                  },
                }}
                bgcolor={theme.palette.common.white}
                sx={{
                  ml: 'auto',
                }}
              />
            </Box>
          </Stack>
          <Stack height={75} flexDirection="row" alignItems="baseline" gap={3}>
            <TranslatedField
              flex={1}
              originText={'comment'}
              fontSize={16}
              isTranslate
              noWrap
              overflow="initial"
            />
            <Box flex={3}>
              <Input
                name="comment"
                value={values.comment}
                onChange={handleChange}
                error={touched.comment && !!errors.comment}
                helperText={touched.comment && errors.comment}
                inputProps={{
                  style: {
                    color: theme.palette.common.black,
                    boxShadow: ' 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset',
                    height: 12,
                  },
                }}
                bgcolor={theme.palette.common.white}
                sx={{
                  ml: 'auto',
                }}
              />
            </Box>
          </Stack>

          <Button
            type="submit"
            variant="contained"
            fullWidth
            startIcon={
              updateCustomerAccountTransactionLoading ? <CircularProgress size={20} /> : undefined
            }
            sx={{
              height: 50,
              p: 2,
              textTransform: 'none',
              backgroundColor: '#5269A3',
              borderRadius: '10px',
              '&:disabled': {
                color: theme.palette.common.white,
              },
            }}
          >
            <TranslatedField originText="save" fontSize={16} isTranslate noWrap />
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
