import React, { FC } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { HighlightOff } from '@mui/icons-material';

interface Props {
  currentActiveMenu: {
    id: string;
    name: string | null | undefined;
  };
  subCategory: string;
  category: string;
  clearFilter(): void;
}
export const BottomNavigate: FC<Props> = ({
  currentActiveMenu,
  subCategory,
  category,
  clearFilter,
}) => {
  return (
    <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
      <Stack
        mt="31px"
        mb="10px"
        sx={{ color: '#2A3453', fontSize: '12px', fontWeight: 300, lineHeight: '15px' }}
        direction="row"
        alignItems="center"
        divider={<KeyboardArrowRightIcon sx={{ color: 'black' }} />}
        spacing={2}
      >
        {subCategory || category
          ? currentActiveMenu && <Typography> {currentActiveMenu?.name}</Typography>
          : ''}
        {subCategory !== '' && <Typography> {subCategory}</Typography>}
        {category && <Typography> {category}</Typography>}
      </Stack>
      {(category || subCategory) && (
        <IconButton
          onClick={clearFilter}
          sx={{
            marginLeft: '100px',
          }}
          size="large"
        >
          <HighlightOff fontSize="large" />
        </IconButton>
      )}
    </Box>
  );
};
