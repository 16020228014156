import { Box, Button, Collapse, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import React, { FC, useMemo, useState } from 'react';
import { Icon } from '../../../legos';
import { GetSupplierSupportAutoOrderingQuery } from '../../../graphql/queries/__generated__/getSupplierSupportAutoOrdering';
import {
  BoxCell,
  HeaderCell,
  TableCellsValue,
  TableProduct,
} from '../../../components/TableProduct/TableProduct';
import { OrderItemStatusesCell } from '../../../components/OrderItemStatuses/OrderItemStatusesCell';
import { formatDateTime } from '../../../utils/dateUtils';
import { ToolTypeComment } from '../../../components/ToolTypeComment';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { OrderItemStatusesHeader } from '../../../components/OrderItemStatuses';
import { ContainerCell } from '../../../components/TableProduct/TableCellProduct';
import { OrderItemQuantity } from '../../../components';
import { roundAmountToString } from '../../../utils/currencyUtils';
import { formatDate } from '../../../helpers/functions';

type SupplierSupportAutoOrderingRowProps = {
  supplier: NonNullable<
    NonNullable<GetSupplierSupportAutoOrderingQuery['supplierSupportAutoOrdering']>[number]
  >;
  translateLang: (transVar: string) => string;
  autoOrdering: (supplierId?: string | null) => void;
  handleOpenConfirmSendingOrderDialog: (supplierId?: string, supplierName?: string) => void;
};

export const SupplierSupportAutoOrderingRow: FC<SupplierSupportAutoOrderingRowProps> = ({
  supplier,
  translateLang,
  autoOrdering,
  handleOpenConfirmSendingOrderDialog,
}) => {
  const [open, setOpen] = useState(true);

  const orderItemTableHeader = useMemo((): Array<HeaderCell> => {
    return [
      {
        title: '№',
        containerProps: { width: '15px', color: '#F1F3F8', align: 'center' },
      },
      {
        title: <OrderItemStatusesHeader translateLang={translateLang} />,
        containerProps: { color: '#F1F3F8', align: 'center' },
      },
      {
        title: translateLang('article'),
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('productName'),
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('manufacturer'),
        containerProps: { width: '140px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('_delivery'),
        containerProps: { width: '140px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('quantity'),
        containerProps: { width: '120px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('discount'),
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('productComment'),
        containerProps: { width: '260px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('amount'),
        containerProps: { width: '120px', color: '#f1f3f8', align: 'center' },
      },
    ];
  }, [translateLang]);

  const orderItemRows = useMemo((): Array<TableCellsValue> => {
    const orderItems = supplier.order_items;
    if (orderItems && orderItems.length) {
      return orderItems.map((item, index) => {
        const orderItem = item!.attributes!;
        const values: Array<BoxCell> = [];
        values.push({
          val: index + 1,
          containerProps: { width: '20px', height: '20px', borderLeft: 'none' },
        });
        values.push({
          val: <OrderItemStatusesCell orderItem={orderItem} />,
        });
        values.push({
          val: orderItem.car_spare?.data?.attributes?.tecdoc_articleNumber,
        });
        values.push({
          val: orderItem.car_spare?.data?.attributes?.car_spare_locales?.data?.[0].attributes
            ?.title,
        });
        values.push({
          val: orderItem.car_spare?.data?.attributes?.car_spare_locales?.data?.[0].attributes
            ?.brandName,
        });
        values.push({
          val: formatDate(orderItem.delivery_date),
        });
        values.push({
          val: (
            <OrderItemQuantity
              confirmedQuantity={orderItem.quantity?.confirmedQuantity ?? 0}
              quantity={orderItem.quantity?.orderedQuantity ?? 0}
            />
          ),
        });
        values.push({ val: orderItem.discount });
        values.push({
          val: <ToolTypeComment comment={orderItem.comment ?? ''} width="200px" />,
        });
        values.push({
          val: `${roundAmountToString(
            (orderItem.customer_price ?? 0) * (orderItem.quantity?.orderedQuantity || 0)
          )} €`,
        });

        return { cellsValues: values };
      });
    }
    return [];
  }, [supplier.order_items]);

  return (
    <React.Fragment>
      <TableRow
        sx={{
          '& > *': { borderBottom: 'unset' },
        }}
      >
        <TableCell
          sx={{
            width: '1px',
            whiteSpace: 'nowrap',
          }}
        >
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <Icon icon="arrowDropDown" /> : <Icon icon="arrowDropUp" />}
          </IconButton>
        </TableCell>
        <TableCell scope="row">
          <ContainerCell justifyContent="flex-start">
            <Typography fontWeight="700">{supplier.name}</Typography>
          </ContainerCell>
        </TableCell>
        <TableCell scope="row">
          <ContainerCell justifyContent="flex-start">
            <Typography fontWeight="700">
              {supplier.order_items?.reduce(
                (sum, orderItem) =>
                  sum +
                  (orderItem?.attributes?.quantity?.orderedQuantity ?? 0) *
                    (orderItem?.attributes?.customer_price ?? 0),
                0
              )}{' '}
              €
            </Typography>
          </ContainerCell>
        </TableCell>
        <TableCell
          scope="row"
          align="right"
          sx={{
            width: '1px',
            whiteSpace: 'nowrap',
          }}
        >
          <ContainerCell justifyContent="flex-start">
            <Button
              onClick={() => {
                handleOpenConfirmSendingOrderDialog(supplier.id ?? '', supplier.name ?? '');
              }}
              variant="contained"
              color="warning"
              sx={{
                minWidth: 40,
                textTransform: 'none',
              }}
            >
              <TranslatedField originText="send" fontSize={16} isTranslate noWrap color="#fff" />
            </Button>
          </ContainerCell>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ m: 1, mb: 4 }}>
              <TableProduct itemsRows={orderItemRows} itemsHeader={orderItemTableHeader} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
