import React from 'react';
import { TableCellProps } from '@mui/material/TableCell';
import { Box, BoxProps, IconButton } from '@mui/material';
import { Block, LaunchRounded, RemoveRedEyeOutlined } from '@mui/icons-material';

import {
  BoxCell,
  HeaderCell,
  TableCellsValue,
} from '../../../components/TableProduct/TableProduct';
import {
  FiltersType,
  PaginationClientsType,
  useGetClients,
} from '../../../graphql/queries/hook/useGetClients';
import { RolesEnum } from '../../../types/types';
import { useLocalization } from '../../../localization';
import { PAGE_COUNT } from '../../../helpers/constants';
import { useGetMe } from '../../../graphql/queries/hook/useGetMe';
import { useFiltersSearchParamsForClients } from '../../../hooks/useFiltersSearchParamsForClients';

interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTable(): Array<TableCellsValue>;
  changePageNumber(value: number): void;
  pagination?: PaginationClientsType;
  loading?: boolean;
}
interface Props {
  filters?: FiltersType;
  handlerCellClick(idUser: string | number): void;
  handlerBlockedUser(idUser: string, name: string, type: string): void;
  isCreateOrder?: boolean;
}
export const useGetTableClientsListData = ({
  filters,
  handlerCellClick,
  handlerBlockedUser,
  isCreateOrder,
}: Props): ReturnHook => {
  const { role } = useGetMe();
  const { page, changePage } = useFiltersSearchParamsForClients();
  const { translateLang } = useLocalization();
  const { clients, pagination, isLoading } = useGetClients({
    pagination: {
      page,
      pageSize: PAGE_COUNT,
    },
    filters,
  });

  const changePageNumber = (value: number) => {
    changePage(value + 1);
  };
  const getHeadersTable = (): Array<HeaderCell> => {
    const style: TableCellProps = { color: '#F1F3F8', align: 'left' };
    return [
      {
        title: 'ID',
        containerProps: { ...style, align: 'center', width: '20px' },
      },
      {
        title: translateLang('client'),
        containerProps: { ...style, width: '100px' },
      },
      {
        title: translateLang('customerType'),
        containerProps: { width: '60px', ...style },
      },
      {
        title: translateLang('phoneNumbers'),
        containerProps: { ...style, width: '80px' },
      },
      {
        title: translateLang('deliveryAddress'),
        containerProps: { ...style, width: '200px' },
      },
      {
        title: translateLang('auto'),
        containerProps: { ...style, width: '120px' },
      },
      {
        title: '',
        containerProps: { ...style, align: 'center', width: '20px' },
      },
    ];
  };
  const getRowsTable = (): Array<TableCellsValue> => {
    if (clients && clients.length) {
      const style: BoxProps<'div'> = {
        borderLeft: 'none',
        justifyContent: 'flex-start',
        fontSize: '18px',
      };
      return clients.map(item => {
        let userName = item.userName;
        if (item.lastName && item.firstName) {
          userName = `${item.firstName} ${item.lastName}`;
        }
        let phoneNumbers = '';
        if (item.primaryPhoneNumber) {
          phoneNumbers = item.primaryPhoneNumber;
        } else {
          if (item?.phoneNumbers?.length) {
            phoneNumbers = item.phoneNumbers[0]?.number || '';
          }
        }
        let deliveryAddress = '';
        if (item.deliveryAddress?.length) {
          deliveryAddress = `${item.deliveryAddress[0]?.zipCode || ''}
          ${item.deliveryAddress[0]?.city || ''}
          ${item.deliveryAddress[0]?.street || ''}
          ${item.deliveryAddress[0]?.number || ''}`;
        }

        const values: Array<BoxCell> = [];
        values.push({ val: `#${item.id}`, containerProps: { ...style, justifyContent: 'center' } });
        values.push({ val: userName, containerProps: { fontSize: '18px' } });
        values.push({
          val: item.clientType,
          containerProps: { fontSize: '18px' },
        });
        values.push({ val: phoneNumbers, containerProps: { fontSize: '18px' } });
        values.push({ val: deliveryAddress, containerProps: { fontSize: '18px' } });
        values.push({ val: item.carModel, containerProps: { fontSize: '18px' } });
        values.push({
          val: (
            <>
              <Box bgcolor="#e7eef4" borderRadius={2}>
                <IconButton onClick={() => handlerCellClick(item?.id || '')}>
                  {isCreateOrder ? <LaunchRounded /> : <RemoveRedEyeOutlined />}
                </IconButton>
              </Box>
              {role === RolesEnum.SuperAdmin && !isCreateOrder && (
                <Box bgcolor="#e7eef4" borderRadius={2} ml={2}>
                  <IconButton
                    onClick={() =>
                      handlerBlockedUser(item?.id || '', userName ?? '', item?.clientType ?? '')
                    }
                  >
                    <Block />
                  </IconButton>
                </Box>
              )}
            </>
          ),
          containerProps: { ...style, justifyContent: 'center' },
        });

        return { cellsValues: values };
      });
    }
    return [];
  };

  return {
    getHeadersTable,
    getRowsTable,
    pagination,
    loading: isLoading,
    changePageNumber,
  };
};
