import { styled } from '@mui/material/styles';
import { Box, TableCell, tableCellClasses } from '@mui/material';

export const ContainerCell = styled(Box)(
  ({ justifyContent, borderLeft, height, padding, alignItems }) => ({
    '&': {
      width: '100%',
      minHeight: height || '40px',
      height: 'auto',
      display: 'flex',
      alignItems: alignItems || 'center',
      justifyContent: justifyContent || 'center',
      padding: padding || '10px',
      boxSizing: 'border-box',
    },
  })
);
export const StyledTableCell = styled(TableCell)(({ theme, color, valign }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: color || '#4d699c',
    color: color ? 'black' : theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    padding: '4px 0',
    verticalAlign: valign || 'middle',
    backgroundColor: 'white',
  },
}));
