import { ComponentOrderStatusEditingHistory, InputMaybe } from '../../__generated__/types';

export type TimePointStatus = {
  isActive: boolean;
  isCompleted: boolean;
  isSkipped: boolean;
};

export enum TimePointStatusName {
  Active = 'active',
  Completed = 'completed',
  Skipped = 'skipped',
}

export enum TimePointStatusColor {
  Active = '#97a408',
  Completed = '#7a8095',
  Skipped = '#979797',
  Light = '#f1f3f8',
}

export type TimePointProps = {
  statuses: TimePointStatus;
  topLabel: string;
  bottomLabel: string | JSX.Element;
  disabled: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export type RadioButtonIconProps = {
  statuses: TimePointStatus;
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export type EditingHistoryTableProps = {
  data?: ComponentOrderStatusEditingHistory[];
};

export type EditingHistoryPayload = {
  status_note?: InputMaybe<string>;
};

export type EditingHistoryFormProps = {
  updateOrderEditingHistory: (payload: EditingHistoryPayload) => void;
};
