import React, { useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { WrapperShadow } from './components/WrapperShadow';
import { ButtonOrange } from './components/ButtonOrange';
import { useSearchParams } from 'react-router-dom';
import { StarsSVG } from './components/StarsSVG';
import { useLocalization } from '../../localization';
import { useConfirmEmailMutation } from '../../graphql/mutations/__generated__/cofirmEmail';
import { useHandlerNotificationApp } from '../../hooks/useHandlerNotificationApp';
import { handlerError } from '../../helpers/functions';
import { useHelpersNavigate } from './hooks/helpersNavigate';

export const ConfirmEmailPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { goToLoginPage } = useHelpersNavigate();
  const { translateLang } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();
  const [runConfirmEmail, { data, loading }] = useConfirmEmailMutation();

  const handleConfirmEmail = async () => {
    const confirmation = searchParams.get('confirmation');
    if (confirmation) {
      searchParams.delete('confirmation');
      setSearchParams({});
      try {
        const resp = await runConfirmEmail({
          variables: {
            confirmation,
          },
        });
        if (resp.data?.emailConfirmation?.user.confirmed) {
          addNotification({
            messageError: '',
            typeMessage: 'success',
          });
        }
      } catch (err: unknown) {
        handlerError(err);
      }
    }
  };
  const getMessageConfirm = () => {
    if (data?.emailConfirmation) {
      return 'registerEmailConfirm';
    }
    return 'somethingWentWrong';
  };

  useEffect(() => {
      handleConfirmEmail().then();
  }, []);
  return (
    <WrapperShadow title={translateLang('emailConfirmation')}>
      <Box display="flex" justifyContent="center" width="100%" mb={3}>
        <StarsSVG />
      </Box>
      <Typography fontSize="18px" textAlign="center">
        {`${translateLang(getMessageConfirm())} 💌`}
      </Typography>
      <ButtonOrange
        type="button"
        titleButton={translateLang('signIn')}
        handlerClickButton={() => goToLoginPage({ replace: true })}
      />
      {loading && (
        <Box
          zIndex={3}
          bgcolor="rgba(0,0,0,0.83)"
          position="absolute"
          left={0}
          top={0}
          display="flex"
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={40} sx={{ color: '#00ff00' }} />
        </Box>
      )}
    </WrapperShadow>
  );
};
