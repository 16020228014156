import { useNavigate } from 'react-router-dom';
import { PatchesName } from '../../../types/types';
import { ProsLoginType } from '../helpers/types';

export const useHelpersNavigate = () => {
  const navigate = useNavigate();
  const goToLoginPage = (option?: ProsLoginType): void => {
    navigate(`/${PatchesName.Login}`, { ...option });
  };
  const goToForgotPasswordPage = (option?: ProsLoginType): void => {
    navigate(`/${PatchesName.ForgotPassword}`, { ...option });
  };
  const goToResetPasswordPage = (option?: ProsLoginType): void => {
    navigate(`/${PatchesName.ResetPassword}`, { ...option });
  };
  const goToRegisterPage = (option?: ProsLoginType): void => {
    navigate(`/${PatchesName.Register}`, { ...option });
  };
  const goToSuccessPage = (option?: ProsLoginType): void => {
    navigate(`/${PatchesName.Register}/success`, { ...option });
  };
  const goToEnterCodePage = (option?: ProsLoginType): void => {
    navigate(`/${PatchesName.EnterCode}`, { ...option });
  };
  const goBack = () => {
    navigate(-1);
  };

  return {
    goToLoginPage,
    goToEnterCodePage,
    goToRegisterPage,
    goToSuccessPage,
    goToForgotPasswordPage,
    goToResetPasswordPage,
    goBack,
  };
};
