import React, { Dispatch, FC } from 'react';
import { Box, SxProps, TextField, Typography } from '@mui/material';
import { useLocalization } from '../localization';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getCalendarLocalization } from '../utils/getCalendarLocalization';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TranslatedField } from '../components/Layout/components/TranslatedField/TranslatedField';
import { useSetSelectLanguageUserQuery } from '../graphql/store/__generated__/setSelectLanguageUser';

type Props = {
  value: Date | null;
  setValue: Dispatch<React.SetStateAction<Date | null>> | ((newValue: Date | null) => void);
  isLabel?: boolean;
  inputStyle?: SxProps;
  error?: string;
  disableFuture?: boolean;
  disabled?: boolean;
};

export const CalendarMUI: FC<Props> = ({
  value,
  setValue,
  isLabel = true,
  inputStyle,
  error,
  disableFuture,
  disabled,
}) => {
  const { translateLang } = useLocalization();
  const { data: lang } = useSetSelectLanguageUserQuery();

  return (
    <Box>
      {isLabel ? (
        <TranslatedField
          fontSize={11}
          noWrap
          isTranslate
          variant="h5"
          color="#2A3453"
          marginBottom="2px"
          originText={translateLang('dateCreateNewOrder')}
        />
      ) : null}

      <LocalizationProvider
        adapterLocale={getCalendarLocalization(lang)}
        dateAdapter={AdapterDateFns}
      >
        <DatePicker
          inputFormat="dd.MM.yyyy"
          disabled={disabled}
          disableFuture={disableFuture}
          InputProps={{
            disableUnderline: true,
          }}
          value={value}
          onChange={newValue => {
            setValue(newValue);
          }}
          renderInput={params => (
            <TextField
              variant="standard"
              sx={{
                border: 'none',
                height: '35px',
                paddingTop: '10px',
                paddingLeft: '16px',
                borderRadius: '6px',
                paddingRight: '16px',
                paddingBottom: '5px',
                background: '#F1F3F8',
                ...inputStyle,
              }}
              {...params}
            />
          )}
        />
        {!!error && (
          <Typography
            component="h4"
            fontSize={12}
            sx={{
              paddingTop: '6px',
              color: '#d32f2f',
            }}
          >
            {error}
          </Typography>
        )}
      </LocalizationProvider>
    </Box>
  );
};
