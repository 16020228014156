import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';

import { DeviderModal } from './components/deviderOrder/DeviderModal';
import { SearchField } from '../../../../components/SearchField';
import { CompletedOrderTabTable } from './CompletedOrderTabTable';
import { OrderFiltersInput } from '../../../../__generated__/types';
import { ToolBarCompletedOrderTab } from './ToolBarCompletedOrderTab';
import { setDataDeviderState } from '../../../../graphql/store/functionsWitingCache';
import { useGetOrdersWarehouse } from '../../../../graphql/queries/hook/useGetOrdersWarehouse';
import { useDataDeviderStateQuery } from '../../../../graphql/cacheQuery/__generated__/getDevidedData';
import { HelpSearchOrder } from '../../../../components/HelpSearchOrder';
import { getOrderNumberFilter } from '../../../../utils/getOrderNumberFilter';

export const OrderTab = () => {
  const [filter, setFilter] = useState<OrderFiltersInput | undefined>();
  const { data: dataDevider } = useDataDeviderStateQuery();
  const [openModal, setOpenModal] = useState(dataDevider?.dataDeviderState?.isOpenModal ?? false);

  const { orderItemsWarehouse, changePage, loading, page, pageSize, totalPage } =
    useGetOrdersWarehouse({ filters: filter });

  const handlerReset = () => {
    setFilter(undefined);
    changePage(0);
  };

  const changeSearchFilter = (text: string) => {
    changePage(0);
    if (text) {
      const orderNumberFilter = getOrderNumberFilter(text);

      setFilter(oldFilter => {
        return {
          ...oldFilter,
          or: [
            ...orderNumberFilter,
            {
              customer: {
                customer_contact_info: {
                  or: [{ firstName: { containsi: text } }, { lastName: { containsi: text } }],
                },
              },
            },
          ],
        };
      });
    } else {
      const _filter = { ...filter };
      delete _filter?.or;
      if (Object.keys(_filter).length === 0) {
        setFilter(undefined);
      } else {
        setFilter(_filter);
      }
    }
  };

  const clearDataDevider = () => {
    setDataDeviderState({
      dataWrite: {
        dataDeviderState: {
          idOrder: '',
          orderNumber: '',
          isOpenModal: false,
        },
      },
    });
  };

  useEffect(() => {
    setOpenModal(dataDevider?.dataDeviderState?.isOpenModal ?? false);
  }, [dataDevider?.dataDeviderState?.isOpenModal]);

  return (
    <Stack width="100%">
      <SearchField
        initStateText=""
        executeWithDelay={changeSearchFilter}
        endAdornment={<HelpSearchOrder />}
      />
      <ToolBarCompletedOrderTab
        result={pageSize}
        setFilter={setFilter}
        changePage={changePage}
        setResetFilter={handlerReset}
      />
      <CompletedOrderTabTable
        loading={loading}
        currentPage={page}
        totalPage={totalPage}
        changePage={changePage}
        orderItems={orderItemsWarehouse}
      />
      {openModal && (
        <DeviderModal
          open={openModal}
          closeModal={() => clearDataDevider()}
          idOrder={dataDevider?.dataDeviderState?.idOrder || ''}
          orderNumber={dataDevider?.dataDeviderState?.orderNumber || ''}
        />
      )}
    </Stack>
  );
};
