import * as yup from 'yup';
import { useLocalization } from '../../../../../localization';
import { CustomerPaymentFields } from '../types';

export const useTransactionValidation = (isFundPA: boolean) => {
  const { translateLang } = useLocalization();

  const validationSchema = yup.object({
    [CustomerPaymentFields.Amount]: yup
      .number()
      .typeError(translateLang('mustBeNumber'))
      .required(translateLang('emptyInputError')),
    [CustomerPaymentFields.Date]: yup.date().required(translateLang('emptyInputError')),
    ...(!isFundPA
      ? {
          [CustomerPaymentFields.Description]: yup
            .string()
            .required(translateLang('emptyInputError')),
        }
      : {}),
  });

  return {
    validationSchema,
  };
};
