export interface CriteriaProps {
  id: number | string;
  titleEN: string;
  titleDE: string;
  valueEN: string;
  valueDE: string;
  dimensionEN: string;
  dimensionDE: string;
}
export interface GeneralSpareInputType {
  productCode: string;
  brandCode: {
    brandName: string;
    brandId: string;
    id: string;
  };
  barcodes?: string[];
  titleEN?: string;
  brandNameENG?: string;
  descriptionENG?: string;
  titleDE?: string;
  brandNameDE?: string;
  descriptionDE?: string;
  // category: string;
  // subCategory: string;
  // secondCategory: string;
  criteria?: CriteriaProps[];
  isDraft?: boolean;
  images?: {
    imageURL200?: string | null;
    imageID200?: string | null;
    imageURL400?: string | null;
    imageID400?: string | null;
    imageURL1600?: string | null;
    imageID1600?: string | null;
  }[];
}

export enum ProductField {
  ProductCode = 'productCode',
  BrandCode = 'brandCode',
  Barcodes = 'barcodes',
  TitleEN = 'titleEN',
  BrandNameENG = 'brandNameENG',
  DescriptionENG = 'descriptionENG',
  TitleDE = 'titleDE',
  BrandNameDE = 'brandNameDE',
  DescriptionDE = 'descriptionDE',
  Category = 'category',
  SubCategory = 'subCategory',
  SecondCategory = 'secondCategory',
  Criteria = 'criteria',
  IsDraft = 'isDraft',
}

export enum NewBrandField {
  BrandNameEN = 'brandNameEN',
  BrandNameDE = 'brandNameDE',
}

export interface articleCriteriaProps {
  criteriaDescription: string;
  criteriaUnitDescription: string;
  formattedValue: string;
}
