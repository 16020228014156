import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import { useLocalization } from '../../../../../localization';
import { HeaderCell } from '../../../../../components/TableProduct/TableProduct';
import { useGetMarkupQuery } from '../../../../../graphql/queries/__generated__/getMarkup';
import { TableMarkupRowItem } from '../TableMarkupRowItem';
import { DataModalType } from '../../../../../helpers/types';
import { MarkupDataModal } from '../ProductMarkup';
import { MarkupInput } from '../../../../../__generated__/types';

interface Props {
  openMarkupModal: Dispatch<SetStateAction<DataModalType<MarkupDataModal>>>;
}
interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTableActive(): ReactNode[];
  loading: boolean;
}

export const useGetTableProductMarkup = ({ openMarkupModal }: Props): ReturnHook => {
  const { translateLang } = useLocalization();
  const { data, loading } = useGetMarkupQuery();

  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      { title: translateLang('nameMarkup'), containerProps: { width: '33%', align: 'left' } },
      { title: translateLang('markupValue'), containerProps: { align: 'center' } },
      { title: '', containerProps: { width: '33%' } },
    ];
  };
  const getRowsTableActive = (): ReactNode[] => {
    if (data && data.markup?.data?.attributes) {
      const items = Object.keys(data.markup.data.attributes).filter(
        item => !item.includes('_')
      ) as Array<keyof typeof data.markup.data.attributes>;
      if (items) {
        const markups: Array<{ name: keyof typeof data.markup.data.attributes; markup: number }> =
          items.map(item => {
            let markup = 0;
            if (data?.markup?.data?.attributes) {
              markup = (data.markup.data.attributes[item] as number) ?? 0;
            }
            return {
              name: item,
              markup,
            };
          });
        markups.sort((a, b) => a.markup - b.markup);

        return markups.map(item => {
          return (
            <TableMarkupRowItem
              key={item.name}
              nameCustomer={item.name}
              markup={item.markup}
              handlerButton={() => {
                openMarkupModal({
                  isOpen: true,
                  selProduct: {
                    customerMarkup: item.name as keyof MarkupInput,
                    markup: item.markup,
                  },
                });
              }}
            />
          );
        });
      }
    }
    return [];
  };

  return {
    loading,
    getHeadersTable,
    getRowsTableActive,
  };
};
