import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { Box, Button, TextField, Typography, Zoom } from '@mui/material';
import { useLocalization } from '../../../localization';

interface Props {
  isOpen: boolean;
  handleClose(): void;
  handleClickButton(eanNumber: string): void;
}
export const NonSearched: FC<Props> = ({ isOpen, handleClickButton }) => {
  const { translateLang } = useLocalization();
  const [code, setCode] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);

  const addGoods = (e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  return (
    <Zoom
      in={isOpen}
      mountOnEnter
      unmountOnExit
      style={{ transitionDelay: isOpen ? '500ms' : '0ms' }}
    >
      <Box paddingX="100px">
        <Typography sx={{ fontWeight: 400, fontSize: '36px', lineHeight: '45px', mb: '20px' }}>
          {translateLang('productNotFoundturn')}
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '20px', lineHeight: '30px' }}>
          {translateLang('codeManually')}
        </Typography>
        <Box pt="100px">
          <TextField
            onChange={addGoods}
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            InputLabelProps={{
              shrink: true,
              style: {
                color: '#2A3453',
                fontSize: '14px',
                fontWeight: '500',
                height: '17px',
                lineHeight: '17px',
              },
            }}
            sx={{
              pt: '100px',
              color: '#FFFFFF',
              paddingLeft: '16px',
              paddingTop: '14px',
              paddingBottom: '14px',
              backgroundColor: '#FFFFFF',
              borderRadius: '6px',
              width: '100%',
            }}
            inputRef={inputRef}
          />
          {code.length ? (
            <Button
              onClick={() => handleClickButton(code)}
              variant="contained"
              sx={{
                backgroundColor: '#5269A3',
                width: '320px',
                borderRadius: '10px',
                marginTop: '45px',
              }}
            >
              Done
            </Button>
          ) : null}
        </Box>
      </Box>
    </Zoom>
  );
};
