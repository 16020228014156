import React, { useState } from 'react';
import { useLocalization } from '../../localization';
import { Stack } from '@mui/material';
import { WrapperPages } from '../../components/Wrapper/WrapperPages';
import { Box } from '@mui/system';

import { NewSletterSubscriptions } from './components/NewSletterSubscriptions';
import { NoParts } from './components/NoParts';
import { TableChangeButton } from './components/TableChangeButton';
import { useGetNewsletterSubscriptions } from '../../graphql/queries/hook/useGetNewsletterSubscriptions';
import { useNoSpareParts } from '../../graphql/queries/hook/useNoSpareParts';
import { ContactNotifications } from './components/ContactNotifications';
import { ReviewTab } from './components/ReviewTab/ReviewTab';
import { useGetReviews } from '../../graphql/queries/hook/useGetReviews';

export const FeedbackPage = () => {
  const { translateLang } = useLocalization();
  const { newsletterSubscriptions } = useGetNewsletterSubscriptions({
    filters: { status: { eqi: 'unprocessed' } },
    pagination: { limit: -1 },
  });

  const { noSpareParts } = useNoSpareParts({
    filters: { status: { eqi: 'unprocessed' } },
    pagination: { limit: -1 },
  });

  const { total } = useGetReviews({
    filters: { publication: { eqi: false } },
  });
  const tabs = [
    { id: 1, value: 'newsletter_Subscription', notification: newsletterSubscriptions.length },
    { id: 2, value: 'no_Detail', notification: noSpareParts.length },
    { id: 3, value: 'call_me_back', notification: 0 },
    { id: 4, value: 'feedback', notification: total },
  ];
  const [activeTable, setActiveTable] = useState<string | null>('newsletter_Subscription');
  const handleChange = (val: string | undefined) => {
    if (val) {
      setActiveTable(val);
    }
  };
  return (
    <WrapperPages isButton={false} titleHeader={translateLang('feedback')}>
      <Stack spacing={2}>
        <TableChangeButton activeTable={activeTable} tabs={tabs} handleChange={handleChange} />
        <Stack>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              placeContent: 'center',
            }}
          >
            {activeTable === 'newsletter_Subscription' && <NewSletterSubscriptions />}

            {activeTable === 'no_Detail' && <NoParts />}
            {activeTable === 'call_me_back' && <ContactNotifications />}
            {activeTable === 'feedback' && <ReviewTab />}
          </Box>
        </Stack>
      </Stack>
    </WrapperPages>
  );
};
