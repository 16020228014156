import { useSearchParams } from 'react-router-dom';
import { AllFiltersType } from '../types/types';

interface ReturnHook {
  page: number;
  searchText: string;
  changeSearchText(text: string): void;
  changePage(newPage: number): void;
  resetFilters(): void;
}
export const useFiltersSearchParamsForClients = (): ReturnHook => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  const getPage = (): number => {
    const pageUrl = searchParams.get('page');
    if (pageUrl && +pageUrl) {
      return +pageUrl;
    }
    return 1;
  };
  const getSearchText = (): string => {
    const searchText = searchParams.get('searchText');
    if (searchText) {
      return searchText;
    }
    return '';
  };
  const changeSearchText = (text: string) => {
    if (text) {
      setSearchParams({ ...(tab ? { tab: tab } : ''), page: '1', searchText: text });
    } else {
      setSearchParams({ ...(tab ? { tab: tab } : ''), page: '1' });
    }
  };
  const changePage = (newPage: number) => {
    const filters = getAllFilters();
    setSearchParams({ ...filters, page: newPage.toString() });
  };

  const resetFilters = () => {
    setSearchParams({ ...(tab ? { tab: tab } : '') });
  };

  function getAllFilters(): AllFiltersType {
    const filters: AllFiltersType = {};
    searchParams.forEach((val, key) => {
      filters[key] = val;
    });
    return filters;
  }

  return {
    searchText: getSearchText(),
    page: getPage(),
    changePage,
    changeSearchText,
    resetFilters,
  };
};
