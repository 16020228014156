import React, { FC } from 'react';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { Button, ButtonProps, CircularProgress } from '@mui/material';

interface Props {
  titleButton: string;
  disableButton?: boolean;
  loading?: boolean;
  handlerClickButton?: () => void;
  type: ButtonProps['type']
;}
export const ButtonOrange: FC<Props> = ({
  titleButton,
  disableButton,
  loading,
  type,
  handlerClickButton,
}) => {

  return (
    <Button
      type={type}
      variant="contained"
      disabled={disableButton}
      fullWidth
      onClick={handlerClickButton}
      startIcon={loading ? <CircularProgress size={20} sx={{ color: '#00ff00' }} /> : undefined}
      style={{
        minWidth: '220px',
        textTransform: 'none',
        backgroundColor: disableButton ? '#9C9C9C' : '#F44336',
        borderRadius: '10px',
        height: '54px',
        marginTop: '20px',
      }}
    >
      <TranslatedField originText={titleButton} fontSize={16} isTranslate noWrap color="white" />
    </Button>
  );
};
