import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { UsersLoginPayloadFragmentDoc } from '../../fragments/__generated__/usersLoginPayload';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type UserAuthorizationMutationVariables = Types.Exact<{
  dataInput: Types.UsersPermissionsLoginInput;
}>;


export type UserAuthorizationMutation = { __typename?: 'Mutation', login: { __typename?: 'UsersPermissionsLoginPayload', jwt?: string | null, user: { __typename?: 'UsersPermissionsMe', id: string, email?: string | null, username: string, role?: { __typename?: 'UsersPermissionsMeRole', id: string, name: string } | null } } };


export const UserAuthorizationDocument = gql`
    mutation userAuthorization($dataInput: UsersPermissionsLoginInput!) {
  login(input: $dataInput) {
    ...usersLoginPayload
  }
}
    ${UsersLoginPayloadFragmentDoc}`;
export type UserAuthorizationMutationFn = Apollo.MutationFunction<UserAuthorizationMutation, UserAuthorizationMutationVariables>;

/**
 * __useUserAuthorizationMutation__
 *
 * To run a mutation, you first call `useUserAuthorizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserAuthorizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userAuthorizationMutation, { data, loading, error }] = useUserAuthorizationMutation({
 *   variables: {
 *      dataInput: // value for 'dataInput'
 *   },
 * });
 */
export function useUserAuthorizationMutation(baseOptions?: Apollo.MutationHookOptions<UserAuthorizationMutation, UserAuthorizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserAuthorizationMutation, UserAuthorizationMutationVariables>(UserAuthorizationDocument, options);
      }
export type UserAuthorizationMutationHookResult = ReturnType<typeof useUserAuthorizationMutation>;
export type UserAuthorizationMutationResult = Apollo.MutationResult<UserAuthorizationMutation>;
export type UserAuthorizationMutationOptions = Apollo.BaseMutationOptions<UserAuthorizationMutation, UserAuthorizationMutationVariables>;