import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type GetCustomerReturnsQueryVariables = Types.Exact<{
  filters: Types.CustomerReturnFiltersInput;
  pagination?: Types.InputMaybe<Types.PaginationArg>;
  sort?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
}>;


export type GetCustomerReturnsQuery = { __typename?: 'Query', customerReturns?: { __typename?: 'CustomerReturnEntityResponseCollection', data: Array<{ __typename?: 'CustomerReturnEntity', id?: string | null, attributes?: { __typename?: 'CustomerReturn', dateReturned?: any | null, correctiveInvoiceDate?: any | null, correctiveInvoiceNumber?: string | null, status: Types.Enum_Customerreturn_Status, customer_return_items?: { __typename?: 'CustomerReturnItemRelationResponseCollection', data: Array<{ __typename?: 'CustomerReturnItemEntity', id?: string | null, attributes?: { __typename?: 'CustomerReturnItem', returnComment?: string | null, returnReason?: string | null, stock?: { __typename?: 'StockEntityResponse', data?: { __typename?: 'StockEntity', id?: string | null, attributes?: { __typename?: 'Stock', incoming_invoice?: { __typename?: 'IncomingInvoiceEntityResponse', data?: { __typename?: 'IncomingInvoiceEntity', id?: string | null, attributes?: { __typename?: 'IncomingInvoice', date: any, supplierInvoiceDate?: any | null } | null } | null } | null } | null } | null } | null } | null }> } | null } | null }>, meta: { __typename?: 'ResponseCollectionMeta', pagination: { __typename?: 'Pagination', total: number, page: number, pageSize: number, pageCount: number } } } | null };


export const GetCustomerReturnsDocument = gql`
    query getCustomerReturns($filters: CustomerReturnFiltersInput!, $pagination: PaginationArg, $sort: [String]) {
  customerReturns(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        dateReturned
        correctiveInvoiceDate
        correctiveInvoiceNumber
        status
        customer_return_items {
          data {
            id
            attributes {
              returnComment
              returnReason
              stock {
                data {
                  id
                  attributes {
                    incoming_invoice {
                      data {
                        id
                        attributes {
                          date
                          supplierInvoiceDate
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}
    `;

/**
 * __useGetCustomerReturnsQuery__
 *
 * To run a query within a React component, call `useGetCustomerReturnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerReturnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerReturnsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetCustomerReturnsQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerReturnsQuery, GetCustomerReturnsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerReturnsQuery, GetCustomerReturnsQueryVariables>(GetCustomerReturnsDocument, options);
      }
export function useGetCustomerReturnsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerReturnsQuery, GetCustomerReturnsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerReturnsQuery, GetCustomerReturnsQueryVariables>(GetCustomerReturnsDocument, options);
        }
export type GetCustomerReturnsQueryHookResult = ReturnType<typeof useGetCustomerReturnsQuery>;
export type GetCustomerReturnsLazyQueryHookResult = ReturnType<typeof useGetCustomerReturnsLazyQuery>;
export type GetCustomerReturnsQueryResult = Apollo.QueryResult<GetCustomerReturnsQuery, GetCustomerReturnsQueryVariables>;