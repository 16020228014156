import { GetBannersQueryVariables, useGetBannersQuery } from '../__generated__/getBanners';
import { Enum_Banner_Size, Enum_Language_Type } from '../../../__generated__/types';
import { useLocalization } from '../../../localization';

type BannerType = {
  id: string;
  imgID: string;
  imgUrl: string;
  width: number;
  height: number;
};
interface ReturnHook {
  banners: Array<BannerType>;
}

export const VariablesGetBanners = (
  locale?: Enum_Language_Type,
  size?: Enum_Banner_Size
): GetBannersQueryVariables => {
  return {
    sort: ['id'],
    locale: locale === Enum_Language_Type.Uk ? Enum_Language_Type.De : locale,
    filters: { size: { eqi: size } },
  };
};

interface PropsHook {
  size: Enum_Banner_Size;
}
export const useGetBanners = ({ size }: PropsHook): ReturnHook => {
  const { selectLanguages } = useLocalization();
  const { data } = useGetBannersQuery({ variables: VariablesGetBanners(selectLanguages, size) });

  if (data && data.banners?.data.length) {
    const banners: Array<BannerType> = data.banners.data.map(banner => {
      return {
        id: banner?.id || '',
        imgID: banner.attributes?.image?.data?.id || '',
        imgUrl: banner.attributes?.image.data?.attributes?.url || '',
        width: banner.attributes?.image.data?.attributes?.width || 0,
        height: banner.attributes?.image.data?.attributes?.height || 0,
      };
    });

    return { banners };
  }

  return {
    banners: [],
  };
};
