import React, { FC, useMemo } from 'react';
import { SelectCustom } from '../../../../../../legos';
import { useGetClientById } from '../../../../../../graphql/queries/hook/useGetClientById';
import { Address, formatAddress } from '../../../../../../helpers/functions';
import { useUpdateOrderMutation } from '../../../../../../graphql/mutations/__generated__/updateOrder';
import { useLocalization } from '../../../../../../localization';

type DeliveryAddressSelectProps = {
  value?: Address | null;
  orderId: string;
  customerId: string;
};
export const DeliveryAddressSelect: FC<DeliveryAddressSelectProps> = ({
  customerId,
  orderId,
  value,
}) => {
  const { deliveryAddress } = useGetClientById(customerId);
  const [runUpdateOrder] = useUpdateOrderMutation();
  const { updateSelectLanguage } = useLocalization();

  const deliveryAddressList = useMemo(
    () =>
      deliveryAddress?.filter(address => address).map(address => formatAddress(address)) ?? [
        formatAddress(value) ?? '',
      ],
    [deliveryAddress, value]
  );

  const handleChangeDeliveryAddress = async (value: string) => {
    const deliveryAddressToUpdate = deliveryAddress?.find(
      address => formatAddress(address) === value
    );
    await runUpdateOrder({
      variables: {
        id: orderId,
        locale: updateSelectLanguage as string,
        data: {
          deliveryAddress: {
            city: deliveryAddressToUpdate?.city,
            country: deliveryAddressToUpdate?.country,
            district: deliveryAddressToUpdate?.district,
            number: deliveryAddressToUpdate?.number,
            street: deliveryAddressToUpdate?.street,
            zipCode: deliveryAddressToUpdate?.zipCode,
          },
        },
      },
    });
  };

  return (
    <SelectCustom
      sxContainer={{ width: '100%' }}
      sx={{
        fontSize: 12,
        textAlign: 'left',
        '.MuiSelect-select': {
          whiteSpace: 'unset',
          wordBreak: 'break-all',
        },
      }}
      value={formatAddress(value)}
      items={deliveryAddressList}
      handleChange={e => handleChangeDeliveryAddress(e.target.value as string)}
    />
  );
};
