import React, { FC, useState } from 'react';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { ArrowBack } from '@mui/icons-material';
import { Button, CircularProgress, IconButton, Stack, Typography } from '@mui/material';

import { theme } from '../../../../helpers';
import { CalendarMUI, Icon, Input } from '../../../../legos';
import { CustomerPaymentFields } from './types';
import { useLocalization } from '../../../../localization';
import { useTransactionValidation } from './hooks/useTransactionValidation';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useCreateCustomerPaymentMutation } from '../../../../graphql/mutations/__generated__/createCustomerPayment';
import {
  Enum_Customerpayment_Paymentdirection,
  Enum_Customerpayment_Paymentmethod,
} from '../../../../__generated__/types';
import { useHandlerNotificationApp } from '../../../../hooks/useHandlerNotificationApp';
import { useGetClientBalance } from '../AllClients/components/ClientTransaction/hooks/useGetClientBalance';
import { formatCurrencyAmount } from '../../../../helpers/functions';

interface CustomerPaymentProps {
  handleCloseCustomerPaymentForm: () => void;
  handleClose: () => void;
  typePA: '' | Enum_Customerpayment_Paymentdirection;
  userId?: string;
  customerPaymentsRefetch: () => void;
}

const initialValues = {
  [CustomerPaymentFields.Amount]: '',
  [CustomerPaymentFields.Date]: new Date(),
  [CustomerPaymentFields.Description]: '',
};

export const CustomerPaymentForm: FC<CustomerPaymentProps> = ({
  handleCloseCustomerPaymentForm,
  handleClose,
  typePA,
  userId,
  customerPaymentsRefetch,
}) => {
  const { translateLang } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();
  const { customerAccount, refetchCustomerAccount, refetchCustomerBalance } = useGetClientBalance(
    userId as string
  );

  const [createCustomerPaymentMutation, { loading }] = useCreateCustomerPaymentMutation();
  const { validationSchema } = useTransactionValidation(
    typePA === Enum_Customerpayment_Paymentdirection.Incoming
  );

  const [payMethod, setPayMethod] = useState<null | Enum_Customerpayment_Paymentmethod>(null);

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, setFieldError } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: ({ amount, date, description }) => {
        const formattedDate = format(date, 'yyyy-MM-dd');
        createCustomerPaymentMutation({
          variables: {
            data: {
              comment: description,
              paymentDate: formattedDate,
              total: +amount,
              paymentMethod: payMethod as Enum_Customerpayment_Paymentmethod,
              paymentDirection: typePA as Enum_Customerpayment_Paymentdirection,
              customer: userId as string,
            },
          },
        })
          .then(() => {
            addNotification({
              messageError:
                typePA === Enum_Customerpayment_Paymentdirection.Incoming
                  ? translateLang('paymentWasSuccessful')
                  : translateLang('withdrawalWasSuccessful'),
              typeMessage: 'success',
            });
            customerPaymentsRefetch();
            refetchCustomerAccount();
            refetchCustomerBalance();
            handleClose();
          })
          .catch(err => {
            addNotification({ messageError: err.message, typeMessage: 'error' });
            setFieldError(
              CustomerPaymentFields.Amount,
              (customerAccount || 0) <= +amount
                ? translateLang('insufficientFundsOnPA')
                : err.message
            );
          });
      },
    });

  const handleCashPay = () => {
    setPayMethod(Enum_Customerpayment_Paymentmethod.Cash);
  };

  const handleCashlessPay = () => {
    setPayMethod(Enum_Customerpayment_Paymentmethod.NonCash);
  };

  const handleDateChange = (newValue: Date | null) => {
    setFieldValue(CustomerPaymentFields.Date, newValue);
  };

  return (
    <Stack alignItems="center">
      <Stack width="100%" flexDirection="row" justifyContent="space-between" mb={2}>
        <IconButton onClick={handleCloseCustomerPaymentForm} sx={{ p: 0 }}>
          <ArrowBack sx={{ color: theme.palette.primary.main }} />
        </IconButton>
        <IconButton onClick={handleClose} sx={{ p: 0 }}>
          <Icon icon="close" sx={{ color: theme.palette.primary.main }} />
        </IconButton>
      </Stack>

      <TranslatedField
        isTranslate
        variant="h4"
        color={theme.palette.common.darkBlue}
        marginBottom={2.5}
        fontSize={30}
        originText={
          typePA === Enum_Customerpayment_Paymentdirection.Incoming ? 'fundPA' : 'withdrawalOfOR'
        }
      />

      <Typography fontSize={20} fontWeight={300} color={theme.palette.common.black} mb={2.5}>
        {translateLang('PAAmount')}
        {': '}
        <span style={{ fontWeight: 500, color: '#97A408' }}>{`${
          (customerAccount || 0) > 0 ? '+' : ''
        } ${customerAccount}€`}</span>
      </Typography>

      <Stack flexDirection="row" width="100%" gap={2} mb={2}>
        <Button
          variant="contained"
          fullWidth
          startIcon={
            payMethod === 'cash' && <Icon icon="done" sx={{ fontSize: '14px !important' }} />
          }
          sx={{
            height: 40,
            width: 210,
            p: 2,
            textTransform: 'none',
            backgroundColor:
              payMethod === 'cash' ? theme.palette.secondary.main : theme.palette.common.white,
            borderRadius: '5px',
            color: payMethod === 'cash' ? theme.palette.common.white : theme.palette.common.black,
            boxShadow: 'none',
            '&:hover': { color: theme.palette.common.white },
          }}
          onClick={handleCashPay}
        >
          <TranslatedField
            originText={'cash'}
            fontSize={16}
            isTranslate
            noWrap
            overflow="initial"
          />
        </Button>
        <Button
          variant="contained"
          fullWidth
          startIcon={
            payMethod === Enum_Customerpayment_Paymentmethod.NonCash && (
              <Icon icon="done" sx={{ fontSize: '14px !important' }} />
            )
          }
          sx={{
            height: 40,
            width: 210,

            p: 2,
            textTransform: 'none',
            backgroundColor:
              payMethod === Enum_Customerpayment_Paymentmethod.NonCash
                ? theme.palette.secondary.main
                : theme.palette.common.white,

            borderRadius: '5px',
            color:
              payMethod === Enum_Customerpayment_Paymentmethod.NonCash
                ? theme.palette.common.white
                : theme.palette.common.black,
            boxShadow: 'none',
            '&:hover': { color: theme.palette.common.white },
          }}
          onClick={handleCashlessPay}
        >
          <TranslatedField
            originText={'bankTransfer'}
            fontSize={16}
            isTranslate
            noWrap
            overflow="initial"
          />
        </Button>
      </Stack>

      <Stack component="form" width="100%" mb={2} onSubmit={handleSubmit}>
        <Stack
          width="77.5%"
          height={75}
          justifyContent="space-between"
          flexDirection="row"
          alignItems="baseline"
          gap={3}
        >
          <TranslatedField
            originText={'amount'}
            fontSize={16}
            isTranslate
            noWrap
            overflow="initial"
          />
          <Input
            name={CustomerPaymentFields.Amount}
            value={`${values[CustomerPaymentFields.Amount]}€`}
            onChange={event => {
              const value = formatCurrencyAmount(event.target.value);
              setFieldValue(CustomerPaymentFields.Amount, value);
            }}
            error={touched[CustomerPaymentFields.Amount] && !!errors[CustomerPaymentFields.Amount]}
            helperText={
              touched[CustomerPaymentFields.Amount] && errors[CustomerPaymentFields.Amount]
            }
            inputProps={{
              style: {
                textAlign: 'center',
                paddingLeft: 0,
                color: theme.palette.common.black,
                boxShadow: ' 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset',
                height: 12,
              },
            }}
            bgcolor={theme.palette.common.white}
            sx={{
              ml: 'auto',
              maxWidth: '180px',
            }}
          />
        </Stack>
        <Stack
          width="77.5%"
          height={75}
          justifyContent="space-between"
          flexDirection="row"
          alignItems="baseline"
          gap={3}
        >
          <TranslatedField
            originText={'date'}
            fontSize={16}
            isTranslate
            noWrap
            overflow="initial"
          />

          <CalendarMUI
            disableFuture
            value={values[CustomerPaymentFields.Date]}
            isLabel={false}
            setValue={handleDateChange}
            inputStyle={{
              bgcolor: theme.palette.common.white,
              height: 40,
              width: 180,
              boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset',
              p: 0,
              '& .MuiInputBase-root': {
                p: '5px 15px',
              },
            }}
          />
        </Stack>

        <Stack
          width="100%"
          height={75}
          justifyContent="space-between"
          flexDirection="row"
          alignItems="baseline"
          gap={3}
        >
          <TranslatedField
            originText={'comment'}
            fontSize={16}
            isTranslate
            noWrap
            overflow="initial"
          />
          <Input
            name={CustomerPaymentFields.Description}
            value={values[CustomerPaymentFields.Description]}
            onChange={handleChange}
            error={
              touched[CustomerPaymentFields.Description] &&
              !!errors[CustomerPaymentFields.Description]
            }
            helperText={
              touched[CustomerPaymentFields.Description] &&
              errors[CustomerPaymentFields.Description]
            }
            inputProps={{
              style: {
                color: theme.palette.common.black,
                boxShadow: ' 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset',
                height: 12,
              },
            }}
            bgcolor={theme.palette.common.white}
            sx={{
              ml: 'auto',
              maxWidth: '271px',
            }}
          />
        </Stack>

        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={
            !payMethod ||
            loading ||
            (typePA === Enum_Customerpayment_Paymentdirection.Outgoing &&
              (customerAccount || 0) <= 0)
          }
          startIcon={loading ? <CircularProgress size={20} /> : undefined}
          sx={{
            height: 50,
            p: 2,
            textTransform: 'none',
            backgroundColor: '#5269A3',
            borderRadius: '10px',
            '&:disabled': {
              color: theme.palette.common.white,
            },
          }}
        >
          <TranslatedField
            originText={
              typePA === Enum_Customerpayment_Paymentdirection.Incoming ? 'pay' : 'withdraw'
            }
            fontSize={16}
            isTranslate
            noWrap
          />
        </Button>
      </Stack>
    </Stack>
  );
};
