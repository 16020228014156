import React from 'react';
import WebIcon from '@mui/icons-material/Web';
import HomeIcon from '@mui/icons-material/Home';
import Person2Icon from '@mui/icons-material/Person2';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

interface InvoiceFooterItem {
  id: number;
  icon: React.ElementType;
  text: string[];
}

export const invoiceFooterData: InvoiceFooterItem[] = [
  {
    id: 1,
    icon: HomeIcon,
    text: ['AutoBro GmbH', 'Franzensbrückenstraße 28/4', '1020 Wien'],
  },
  {
    id: 2,
    icon: WebIcon,
    text: ['+43 660 5555 777', 'www.autobro.at', 'invoice@autobro.at'],
  },
  {
    id: 3,
    icon: CurrencyExchangeIcon,
    text: ['Bank', 'AT66 2011 18472675 3100', 'BIC: GIBAATWWXXX'],
  },
  {
    id: 4,
    icon: Person2Icon,
    text: ['UID: ATU78832836'],
  },
];
