import React, { useEffect, FC } from 'react';
import { ReviewsType, useGetReviews } from '../../../../../graphql/queries/hook/useGetReviews';
import { Box, Stack, Typography } from '@mui/material';
import { useLocalization } from '../../../../../localization';
interface Props {
  reviewText: string;
  rating: number;
}
export const ReviewMessage: FC<Props> = ({ reviewText, rating }) => {
  const { translateLang } = useLocalization();

  return (
    <Box mt="30px">
      <Stack justifyContent="space-around" alignItems="baseline" spacing={2}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography sx={{ fontSize: '14px', lineHeight: '17.57px', marginRight: '10px' }}>
            {`${translateLang('Rating')}:`}
          </Typography>
          <Typography sx={{ fontSize: '12px', lineHeight: '15px' }}>
            {`${rating} ${translateLang('stars')}`}
          </Typography>
        </Box>
        <Stack alignItems="flex-start" textAlign="left">
          <Typography sx={{ fontSize: '14px', lineHeight: '17.57px', marginRight: '10px' }}>
            {`${translateLang('message')}:`}
          </Typography>
          <Typography sx={{ fontSize: '14px', lineHeight: '18px' }}>{reviewText}</Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
