import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './api/apolloClient';
import { SnackbarProvider } from 'notistack';
import { theme } from './helpers';
import './App.css';
import { AppRouter } from './routes';
import { EntranceGate } from './pages/EntranceGate';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

function App() {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY as string}
      // useRecaptchaNet="[optional_boolean_value]"
      // useEnterprise="[optional_boolean_value]"
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        nonce: undefined, // optional, default undefined
      }}
      // container={{
      //   // optional to render inside custom element
      //   element: '[required_id_or_htmlelement]',
      //   parameters: {
      //      badge: '[inline|bottomright|bottomleft]', // optional, default undefined
      //      theme: 'dark', // optional, default undefined
      //   },
      // }}
    >
      <ThemeProvider theme={theme}>
        <ApolloProvider client={apolloClient}>
          <BrowserRouter>
            <SnackbarProvider maxSnack={6} preventDuplicate>
              <EntranceGate>
                <AppRouter />
              </EntranceGate>
            </SnackbarProvider>
          </BrowserRouter>
        </ApolloProvider>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
