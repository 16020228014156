import { endOfToday, startOfDay, subDays } from 'date-fns';

import {
  Pagination,
  PaginationArg,
  CustomerAccountTransactionFiltersInput,
  Enum_Customeraccounttransaction_Documenttype,
} from '../../../../../../../__generated__/types';
import { useGetCustomerAccountTransactionQuery } from '../../../../../../../graphql/queries/__generated__/getCustomerAccountTransaction';

export interface TransactionType {
  id: string;
  total?: number | null;
  paymentDate: any;
  paymentNumber?: string | null;
  documentType?: Enum_Customeraccounttransaction_Documenttype | null;
  documentId?: number | null;
  comment?: string | null;
}

export type PaginationTransactionsType = Omit<Pagination, '__typename'>;

interface ReturnHook {
  transactions: Array<TransactionType>;
  pagination?: PaginationTransactionsType;
  isLoading?: boolean;
  customerPaymentsRefetch: () => void;
}

export type FiltersType = {
  searchStrings?: string;
  searchNumbers?: string;
};
interface PropsHook {
  pagination?: PaginationArg;
  filters?: CustomerAccountTransactionFiltersInput;
  customerId?: string;
}

const FILTER_ALL_STATUSES = [
  {
    documentType: {
      eq: Enum_Customeraccounttransaction_Documenttype.Order,
    },
  },
  {
    documentType: {
      eq: Enum_Customeraccounttransaction_Documenttype.PaymentIncomingCash,
    },
  },
  {
    documentType: {
      eq: Enum_Customeraccounttransaction_Documenttype.PaymentIncomingNonCash,
    },
  },
  {
    documentType: {
      eq: Enum_Customeraccounttransaction_Documenttype.PaymentOutgoingCash,
    },
  },
  {
    documentType: {
      eq: Enum_Customeraccounttransaction_Documenttype.PaymentOutgoingNonCash,
    },
  },
  {
    documentType: {
      eq: Enum_Customeraccounttransaction_Documenttype.Return,
    },
  },
];

export const useGetTransactions = ({ pagination, filters, customerId }: PropsHook): ReturnHook => {
  const {
    data: customerPaymentsData,
    loading: customerPaymentsLoading,
    refetch: customerPaymentsRefetch,
  } = useGetCustomerAccountTransactionQuery({
    variables: {
      filters: filters
        ? {
            ...(filters.createdAt
              ? {
                  createdAt: filters.createdAt,
                }
              : {}),
            ...(filters.documentType
              ? {
                  documentType: filters.documentType,
                }
              : {
                  or: FILTER_ALL_STATUSES,
                }),
            customerId: { eq: customerId },
          }
        : {
            createdAt: {
              between: [startOfDay(subDays(new Date(), 30)), endOfToday()],
            },
            or: FILTER_ALL_STATUSES,
            customerId: { eq: customerId },
          },
      pagination,
      sort: ['createdAt:desc'],
    },
  });

  if (customerPaymentsData?.customerAccountTransactions?.data) {
    const paymentsData = customerPaymentsData.customerAccountTransactions.data;
    const transactions = paymentsData.map(payment => {
      return {
        id: payment?.id || '',
        total: payment.attributes?.total,
        paymentDate: payment.attributes?.createdAt,
        documentType: payment.attributes?.documentType,
        paymentNumber: payment.attributes?.documentNumber,
        documentId: payment.attributes?.documentId,
        comment: payment.attributes?.comment,
      };
    });
    const pagination: PaginationTransactionsType = {
      page: customerPaymentsData.customerAccountTransactions.meta.pagination.page,
      pageCount: customerPaymentsData.customerAccountTransactions.meta.pagination.pageCount,
      pageSize: customerPaymentsData.customerAccountTransactions.meta.pagination.pageSize,
      total: customerPaymentsData.customerAccountTransactions.meta.pagination.total,
    };
    return {
      transactions,
      pagination,
      isLoading: customerPaymentsLoading,
      customerPaymentsRefetch,
    };
  }

  return { transactions: [], isLoading: customerPaymentsLoading, customerPaymentsRefetch };
};
