import React, { FC } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { BadgetButtons } from '../../FeedbackPage/components/BadgetButtons';
import { managerPanelTabs } from '../constants';
import { Add } from '@mui/icons-material';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';

interface TabsContainerProps {
  activeTab: string | null;
  handleTabChange: (val?: string) => void;
  handleOpenAddProduct: () => void;
}

export const TabsContainer: FC<TabsContainerProps> = ({
  activeTab,
  handleTabChange,
  handleOpenAddProduct,
}) => {
  return (
    <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={2.5}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        width="fit-content"
        sx={{
          height: '58px',
          backgroundColor: 'white',
          borderRadius: '10px',
          paddingRight: '22px',
        }}
        divider={
          <Box
            sx={{
              width: '0.5px',
              backgroundColor: '#2A3453',
              height: '27px',
              marginLeft: '20px',
            }}
          />
        }
      >
        {managerPanelTabs.map(tab => (
          <BadgetButtons
            key={tab.id}
            value={tab.value}
            label={tab.value}
            handleSet={handleTabChange}
            notBadge
            activeTab={activeTab}
          />
        ))}
      </Stack>
      <Button
        variant="contained"
        startIcon={<Add />}
        sx={{
          height: 50,
          p: 2,
          textTransform: 'none',
          backgroundColor: '#5269A3',
          borderRadius: '10px',
        }}
        onClick={handleOpenAddProduct}
      >
        <TranslatedField originText={'addProduct'} fontSize={16} isTranslate noWrap />
      </Button>
    </Stack>
  );
};
