import React, { FC, useMemo } from 'react';
import { Stack, Button } from '@mui/material';
import { addDays, format, isValid, parse } from 'date-fns';

import { useLocalization } from '../../../../../localization';
import { useDefaultRangeCalendar } from '../../../../../hooks';
import { Enum_Order_Status } from '../../../../../__generated__/types';
import { RangeCalendar } from '../../../../../legos/rangeCalendar/RangeCalendar';
import { ItemSelectFilterType, SelectFilter } from '../../../../OrdersList/components/SelectFilter';
import { useFiltersSearchParamsForOrders } from '../../../../../hooks/useFiltersSearchParamsForOrders';
import { Enum_Order_Payment_Status } from '../../../../../graphql/queries/hook/useGetAllOrdersAccounting';
import { TranslatedField } from '../../../../../components/Layout/components/TranslatedField/TranslatedField';

interface Props {
  result?: string | number | undefined;
}

const STATUSES_FILTERS_PAYMENT: ItemSelectFilterType[] = [
  ...[Enum_Order_Payment_Status.Paid, Enum_Order_Payment_Status.NotPaid].map(value => ({
    label: value,
    value,
  })),
];

const STATUSES_FILTERS_ORDER: ItemSelectFilterType[] = [
  ...[
    Enum_Order_Status.Ordered,
    Enum_Order_Status.Awaiting,
    Enum_Order_Status.InStock,
    Enum_Order_Status.PickedUp,
    Enum_Order_Status.Delivery,
    Enum_Order_Status.Completed,
  ].map(value => ({
    label: value,
    value,
  })),
];

export const AllOrdersToolbar: FC<Props> = () => {
  const { translateLang } = useLocalization();
  const { reportRangeDates } = useDefaultRangeCalendar();
  const {
    changeStatus,
    changeStatusPayment,
    changeDate,
    resetFilters,
    selStatus,
    selStatusPayment,
    selDateTo,
    selDateFrom,
  } = useFiltersSearchParamsForOrders({
    dateFrom: format(addDays(new Date(), -30), 'yyyy-MM-dd'),
    dateTo: format(new Date(), 'yyyy-MM-dd'),
  });

  const handlerChangesDates = (val: Date[]) => {
    changeDate(
      val[0] ? format(val[0], 'yyyy-MM-dd') : '',
      val[1] ? format(val[1], 'yyyy-MM-dd') : ''
    );
  };

  const resetFiltersAll = () => {
    resetFilters();
  };

  const selectedDates = useMemo(() => {
    const dates = [];
    if (selDateFrom) {
      const dateFrom = parse(selDateFrom, 'yyyy-MM-dd', new Date());
      if (isValid(dateFrom)) {
        dates.push(parse(selDateFrom, 'yyyy-MM-dd', new Date()));

        if (selDateTo) {
          const dateTo = parse(selDateTo, 'yyyy-MM-dd', new Date());
          if (isValid(dateTo)) {
            dates.push(parse(selDateTo, 'yyyy-MM-dd', new Date()));
          }
        }
      }
    }

    return dates;
  }, [selDateFrom, selDateTo]);

  return (
    <>
      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems="center"
        justifyContent="end"
        mb={2.5}
        spacing={2}
      >
        <Stack spacing={2} flexDirection="row" justifyContent="right">
          <Stack direction="row" gap={2} alignItems="center">
            <SelectFilter
              labelFilter={translateLang('statusPayment')}
              items={STATUSES_FILTERS_PAYMENT}
              selectItem={selStatusPayment}
              changeUrlParams={changeStatusPayment}
            />
            <SelectFilter
              labelFilter={translateLang('orderStatus')}
              items={STATUSES_FILTERS_ORDER}
              selectItem={selStatus}
              changeUrlParams={changeStatus}
            />
            <RangeCalendar
              selectedDates={selectedDates}
              defaultRangeDates={reportRangeDates}
              setSelectedDates={handlerChangesDates}
            />
            <Button
              color="primary"
              variant="outlined"
              onClick={resetFiltersAll}
              style={{
                width: '100%',
                height: 40,
                textTransform: 'none',
              }}
            >
              <TranslatedField
                noWrap
                isTranslate
                fontSize={16}
                color="black"
                originText="resetFilters"
              />
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
