import { useState } from 'react';
import { PAGE_COUNT } from '../../../helpers/constants';
import { PaginationType } from './useGetOrders';
import { GetStocksQueryVariables, useGetStocksQuery } from '../__generated__/getStocks';
import { useLocalization } from '../../../localization';

export type CellAddressType = {
  id: string;
  address: string;
};

export interface ItemWarehouseType {
  article: string;
  supplier: string;
  productName: string;
  stockAddressOrder: Array<CellAddressType>;
  productStockId: string;
  balanceQuantity: number;
  incomingQuantity: number;
  reservedQuantity: number;
}
interface ReturnHook {
  itemsWarehouse: Array<ItemWarehouseType>;
  loading: boolean;
  page: number;
  totalPage: number;
  pageSize: number;
  changePage(page: number): void;
}

interface PropsHook {
  filters?: GetStocksQueryVariables['filters'];
  pagination?: PaginationType;
}
export const useGetWarehouse = ({ filters }: PropsHook): ReturnHook => {
  const { updateSelectLanguage } = useLocalization();
  const [page, setPage] = useState(1);

  const { data, loading } = useGetStocksQuery({
    variables: {
      sort: ['id:desc'],
      pagination: {
        page: page,
        pageSize: PAGE_COUNT,
      },
      filtersLocale: { locale: { eqi: updateSelectLanguage } },
      filters,
    },
  });

  const changePage = (_page: number): void => {
    setPage(_page + 1);
  };
  const itemsWarehouse: Array<ItemWarehouseType> = [];
  if (data?.stocks?.data.length) {
    data.stocks.data.forEach(item => {
      const stockAddressOrder: CellAddressType[] = [];
      if (item.attributes?.stock_cell_address?.data?.attributes) {
        const a = item.attributes?.stock_cell_address?.data?.attributes?.shelf || '';
        const b = item.attributes?.stock_cell_address?.data?.attributes?.cell || '';
        stockAddressOrder.push({
          id: item.attributes?.stock_cell_address?.data?.id || '',
          address: `${a}/${b}`,
        });
      }

      itemsWarehouse.push({
        productStockId: item.id || '',
        balanceQuantity: item?.attributes?.balanceQuantity || 0,
        reservedQuantity: item?.attributes?.reservedQuantity || 0,
        incomingQuantity: item?.attributes?.incomingQuantity || 0,
        supplier: item.attributes?.supplier?.data?.attributes?.name || '',
        article: item.attributes?.car_spare?.data?.attributes?.tecdoc_articleNumber || '',
        productName:
          item.attributes?.car_spare?.data?.attributes?.car_spare_locales?.data[0].attributes
            ?.title || '',
        stockAddressOrder,
      });
    });
  }

  return {
    itemsWarehouse,
    loading,
    page,
    pageSize: data?.stocks?.meta.pagination.total || 0,
    totalPage: data?.stocks?.meta.pagination.total
      ? Math.ceil(data?.stocks?.meta.pagination.total / PAGE_COUNT)
      : 0,
    changePage,
  };
};
