import React, { FC, forwardRef, ReactNode } from 'react';
import ReactPhoneInput from 'react-phone-input-material-ui';
import styles from '../../ClientInfo/components/Modals/Input.module.css';
import { TextField } from '@mui/material';
import { useLocalization } from '../../../localization';
import { WrapperField } from './WrapperField';
import { CustomHelperText } from './CustomHelperText';

interface Props {
  phoneValue: string;
  changePhoneValue(phone: string): void;
  helperText?: ReactNode;
  error?: boolean;
}
export const PhoneInput: FC<Props> = ({ phoneValue, changePhoneValue, helperText, error }) => {
  const { translateLang } = useLocalization();
  return (
    <WrapperField title={translateLang('phone')}>
      <ReactPhoneInput
        autoFormat
        jumpCursorToEnd
        disableSearchIcon
        value={phoneValue}
        component={CustomInput}
        onChange={value => {
          changePhoneValue(value);
        }}
        regions={['europe']}
        country="at"
        inputStyle={{
          padding: '10px 0 0 0',
          height: 54,
          marginLeft: '60px',
          width: 'calc(100% - 62px)',
          background: 'tra',
          border: 'none',
        }}
        buttonClass={styles.buttonClass_standard}
        dropdownClass={styles.dropdownClass_standard}
        containerStyle={{
          borderRadius: '10px',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: helperText && !!error ? 'red' : '#c5c5c5',
        }}
        label=""
      />
      <CustomHelperText helperText={helperText} error={error} />
    </WrapperField>
  );
};

const CustomInput = forwardRef<HTMLInputElement>((props, ref) => {
  return (
    <TextField
      hiddenLabel
      variant="standard"
      InputProps={{
        disableUnderline: true,
      }}
      inputRef={ref}
      {...props}
    />
  );
});
CustomInput.displayName = 'CustomInput';
