/* eslint-disable no-console */
import React, { FC } from 'react';
import { Box } from '@mui/material';
import { ItemWarehouseType } from '../../../../../graphql/queries/hook/useGetWarehouse';
import { useGetWarehouseTabTable } from '../hooks/useGetWarehouseTabTable';
import { TableProductActive } from '../../../../../components/TableProductActive/TableProductActive';

interface Props {
  totalPage: number;
  itemsWarehouse?: ItemWarehouseType[];
  currentPage?: number;
  changePage(page: number): void;
  loading?: boolean;
}

export const WarhouseOrderTabTable: FC<Props> = ({
  itemsWarehouse,
  totalPage,
  currentPage,
  changePage,
  loading,
}) => {
  const { getHeadersTable, getRowsTableActive } = useGetWarehouseTabTable({
    itemsWarehouse,
  });
  return (
    <>
      <Box boxSizing="border-box" width="100%">
        <TableProductActive
          startPage={currentPage}
          totalPages={totalPage}
          itemsRows={getRowsTableActive()}
          itemsHeader={getHeadersTable()}
          changeCurrentPage={changePage}
          loading={loading}
        />
      </Box>
    </>
  );
};
