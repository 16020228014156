/* eslint-disable no-console,@typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { Autocomplete, Box, Button, UseAutocompleteProps } from '@mui/material';
import { WarningAmberRounded } from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import { useLocalization } from '../../../../../localization';
import { TranslatedField } from '../../../../../components/Layout/components/TranslatedField/TranslatedField';

export type AutocompleteItemType = {
  id: string;
  title?: string;
  targetType?: string;
};

interface Props {
  options: AutocompleteItemType[];
  selectedValue: AutocompleteItemType[];
  label: string;
  handlerChange: UseAutocompleteProps<AutocompleteItemType, any, any, any>['onChange'];
  handlerUpdateAutocomplete?: () => void;
  isUpdateCatalog: boolean;
  titleButton?: string;
  placeholder?: string;
  onClose?: () => void;
}

export const AutocompleteCustom: FC<Props> = ({
  options,
  handlerChange,
  label,
  selectedValue,
  isUpdateCatalog,
  handlerUpdateAutocomplete,
  titleButton,
  placeholder,
  onClose,
}) => {
  const { translateLang } = useLocalization();
  return (
    <>
      <Autocomplete
        multiple
        id="tags-outlined"
        options={options}
        value={selectedValue}
        filterSelectedOptions
        disableCloseOnSelect
        getOptionLabel={option => {
          return option?.title && option?.targetType ? option.title : '';
        }}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        renderOption={(props, option) =>
          option.title ? (
            <Box component="li" {...props} key={option.title}>
              {option.title}
            </Box>
          ) : null
        }
        renderInput={params => (
          <TextField {...params} label={label} placeholder={placeholder ?? 'Add category...'} />
        )}
        onChange={handlerChange}
        onClose={onClose}
        sx={{
          '& .MuiAutocomplete-tag': {
            backgroundColor: '#545d75',
            color: 'white',
          },
          '& .MuiChip-root .MuiChip-deleteIcon': {
            color: 'rgb(255, 255, 255, 0.77)',
          },
        }}
      />
      {handlerUpdateAutocomplete && (
        <Button
          endIcon={isUpdateCatalog && <WarningAmberRounded color="warning" />}
          disabled={!isUpdateCatalog}
          onClick={handlerUpdateAutocomplete}
          variant="contained"
          color="secondary"
          style={{
            width: '300px',
            height: '50px',
            marginTop: '30px',
          }}
        >
          <TranslatedField
            originText={titleButton ?? translateLang('save')}
            fontSize={16}
            isTranslate
            noWrap
            color="white"
          />
        </Button>
      )}
    </>
  );
};
