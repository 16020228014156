import React, { useState } from 'react';
import { Stack } from '@mui/system';
import { endOfToday, startOfDay, subDays } from 'date-fns';

import { ExpectedTabTable } from './ExpectedTabTable';
import { ToolBarExpectedTab } from './ToolBarExpectedTab';
import { useLocalization } from '../../../../localization';
import { SearchField } from '../../../../components/SearchField';
import { OrderItemFiltersInput } from '../../../../__generated__/types';
import { useGetOrderItemsAwaiting } from '../../../../graphql/queries/hook/useGetOrderItems';

export const ExpectedTab = () => {
  const [filterSubscription, setFilterSubscription] = useState<OrderItemFiltersInput>({
    createdAt: {
      between: [startOfDay(subDays(new Date(), 30)).toISOString(), endOfToday().toISOString()],
    },
  });

  const { orderItems, loading, pageSize } = useGetOrderItemsAwaiting({
    filter: filterSubscription,
  });
  const [keySearchField, setKeySearchField] = useState((Math.random() * 1000).toFixed());

  const [resetFilter, setResetFilter] = useState(new Date().getTime().toString());
  const { translateLang } = useLocalization();

  const handlerReset = () => {
    setKeySearchField((Math.random() * 1000).toFixed());
    setFilterSubscription({
      createdAt: {
        between: [startOfDay(subDays(new Date(), 30)).toISOString(), endOfToday().toISOString()],
      },
    });
    setResetFilter(new Date().getTime().toString());
  };
  const changeSearchFilter = (text: string) => {
    if (text) {
      setFilterSubscription(oldFilter => {
        return {
          ...oldFilter,
          or: [
            { order: { orderNumber: { containsi: text } } },
            {
              car_spare: {
                or: [
                  { tecdoc_articleNumber: { containsi: text } },
                  { car_spare_locales: { title: { containsi: text } } },
                ],
              },
            },
          ],
        };
      });
    } else {
      const _filter = { ...filterSubscription };
      delete _filter?.or;
      setFilterSubscription(_filter);
    }
  };

  return (
    <Stack width="100%" paddingBottom={10}>
      <SearchField
        key={keySearchField}
        initStateText=""
        executeWithDelay={changeSearchFilter}
        placeholder={translateLang('search_name')}
      />
      <ToolBarExpectedTab
        key={resetFilter}
        result={pageSize}
        setFilterSubscription={setFilterSubscription}
        setResetFilter={handlerReset}
      />
      <ExpectedTabTable orderItems={orderItems} loading={loading} />
    </Stack>
  );
};
