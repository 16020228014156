import React, { FC } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  ComponentClientDeliveryAddressInput,
  CustomerContactInfoInput,
} from '../../../../__generated__/types';
import { useLocalization } from '../../../../localization';
import { useUpdateCustomerContactInfoMutation } from '../../../../graphql/mutations/__generated__/updateCustomerContactInfo';
import { useGetClientById } from '../../../../graphql/queries/hook/useGetClientById';
import { Stack, Button, Checkbox } from '@mui/material';
import { CustomModal } from '../../../../components';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { Input } from '../../../../legos';
import { GetClientByIdDocument } from '../../../../graphql/queries/__generated__/getClintById';
import { useAddCustomerContactInfoMutation } from '../../../../graphql/mutations/__generated__/addCustomerContactInfo';
import { useHandlerNotificationApp } from '../../../../hooks/useHandlerNotificationApp';
import { formatAddress, handlerError } from '../../../../helpers/functions';

interface AddressProps {
  clientId: string;
  closeModal(): void;
  openModal: boolean;
  id?: string;
  city?: string;
  street?: string;
  zipCode?: string;
  number?: string;
  district?: string;
  main?: boolean;
}
type InitialValueType = Pick<
  AddressProps,
  'id' | 'city' | 'street' | 'zipCode' | 'number' | 'district' | 'main'
>;
type InitialValueValidation = Omit<InitialValueType, 'id'>;

enum DeliveryField {
  City = 'city',
  Street = 'street',
  ZipCode = 'zipCode',
  Number = 'number',
  District = 'district',
  Main = 'main',
}

const hasDuplicateDeliveryAddress = (deliveryAddress?: ComponentClientDeliveryAddressInput[]) =>
  !deliveryAddress?.length
    ? false
    : new Set(deliveryAddress.map(address => formatAddress(address))).size !==
      deliveryAddress.length;

export const AddressModal: FC<AddressProps> = ({ id, closeModal, openModal, clientId }) => {
  const { translateLang, updateSelectLanguage } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();
  const { deliveryAddress, isContactInfoActive, contactInfoId } = useGetClientById(clientId);
  const [runUpdateCustomer] = useUpdateCustomerContactInfoMutation();
  const [runAddCustomerContactInfo] = useAddCustomerContactInfoMutation();

  const initialValue = () => {
    const init: InitialValueType = {
      city: '',
      street: '',
      number: '',
      zipCode: '',
      id: '',
      district: '',
      main: false,
    };

    if (id) {
      const address = deliveryAddress?.find(item => item?.id === id);
      if (address && address?.id) {
        init.city = address?.city || '';
        init.street = address?.street || '';
        init.zipCode = address?.zipCode || '';
        init.number = address?.number || '';
        init.district = address?.district || '';
        init.main = !!address.main;
      }
    }
    return init;
  };

  const validationSchema: yup.SchemaOf<InitialValueValidation> = yup.object({
    city: yup.string().required(translateLang('emptyInputError')),
    street: yup.string().required(translateLang('emptyInputError')),
    zipCode: yup.string().matches(/^\d+$/, translateLang('onlyDigitsValidation')),
    number: yup.string().required(translateLang('emptyInputError')),
    district: yup.string().matches(/^[0-9]+$/, 'Must be only digits'),
    main: yup.boolean().required(),
  });

  const { values, errors, touched, handleChange, setErrors, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: initialValue(),
      validationSchema,
      onSubmit: async values => {
        let address: ComponentClientDeliveryAddressInput[] | undefined;
        if (id) {
          address = deliveryAddress?.map(item => {
            if (item?.id === id) {
              return {
                city: values[DeliveryField.City],
                street: values[DeliveryField.Street],
                zipCode: values[DeliveryField.ZipCode],
                number: values[DeliveryField.Number],
                district: values[DeliveryField.District],
                main: values[DeliveryField.Main],
              };
            }
            return {
              id: item?.id,
              city: item?.city,
              street: item?.street,
              zipCode: item?.zipCode,
              number: item?.number,
              district: item?.district,
              main: values[DeliveryField.Main] ? false : item?.main,
            };
          });
        } else {
          address = deliveryAddress?.map(item => {
            return {
              id: item?.id,
              city: item?.city,
              street: item?.street,
              zipCode: item?.zipCode,
              number: item?.number,
              district: item?.district,
              main: values[DeliveryField.Main] ? false : item?.main,
            };
          });
          address?.push({
            city: values[DeliveryField.City],
            street: values[DeliveryField.Street],
            zipCode: values[DeliveryField.ZipCode],
            number: values[DeliveryField.Number],
            district: values[DeliveryField.District],
            main: values[DeliveryField.Main],
          });
        }

        if (hasDuplicateDeliveryAddress(address)) {
          addNotification({ messageError: 'The address is duplicated', typeMessage: 'error' });

          throw Error('The address is duplicated');
        }

        const variables: CustomerContactInfoInput = {
          deliveryAddress: address,
        };
        if (!isContactInfoActive) {
          variables.user = clientId;
          try {
            await runAddCustomerContactInfo({
              variables: {
                data: {
                  ...variables,
                },
              },
              refetchQueries: [
                {
                  query: GetClientByIdDocument,
                  variables: {
                    id: clientId,
                    locale: updateSelectLanguage as string,
                  },
                },
              ],
            });
          } catch (error: unknown) {
            handlerError(error);
          }
        } else
          try {
            await runUpdateCustomer({
              variables: {
                data: {
                  deliveryAddress: address,
                },
                id: contactInfoId || '',
              },
              refetchQueries: !isContactInfoActive
                ? [
                    {
                      query: GetClientByIdDocument,
                      variables: {
                        id: clientId,
                        locale: updateSelectLanguage as string,
                      },
                    },
                  ]
                : [],
            });
            addNotification({ messageError: 'Address  updated success', typeMessage: 'success' });
          } catch (error) {
            setErrors({
              [DeliveryField.City]: error as string,
              [DeliveryField.Street]: error as string,
              [DeliveryField.ZipCode]: error as string,
              [DeliveryField.Number]: error as string,
              [DeliveryField.District]: error as string,
            });
          } finally {
            closeModal();
          }
      },
    });

  return (
    <CustomModal
      title={translateLang('deliveryInformation')}
      handleClose={closeModal}
      open={openModal}
    >
      <Stack component="form" flexGrow={1} spacing={2} onSubmit={handleSubmit}>
        <Input
          label={translateLang('city')}
          name={DeliveryField.City}
          value={values[DeliveryField.City]}
          onChange={handleChange}
          error={touched[DeliveryField.City] && !!errors[DeliveryField.City]}
          helperText={touched[DeliveryField.City] && errors[DeliveryField.City]}
        />
        <Input
          label={translateLang('zip')}
          name={DeliveryField.ZipCode}
          value={values[DeliveryField.ZipCode]}
          onChange={handleChange}
          error={touched[DeliveryField.ZipCode] && !!errors[DeliveryField.ZipCode]}
          helperText={touched[DeliveryField.ZipCode] && errors[DeliveryField.ZipCode]}
        />
        <Input
          label={translateLang('street')}
          name={DeliveryField.Street}
          value={values[DeliveryField.Street]}
          onChange={handleChange}
          error={!!(touched[DeliveryField.Street] && !!errors[DeliveryField.Street])}
          helperText={touched[DeliveryField.Street] && errors[DeliveryField.Street]}
        />
        <Input
          label={translateLang('house')}
          name={DeliveryField.Number}
          value={values[DeliveryField.Number]}
          onChange={handleChange}
          error={touched[DeliveryField.Number] && !!errors[DeliveryField.Number]}
          helperText={touched[DeliveryField.Number] && errors[DeliveryField.Number]}
        />
        <Input
          label={translateLang('deliveryArrea')}
          name={DeliveryField.District}
          value={values[DeliveryField.District]}
          onChange={handleChange}
          error={touched[DeliveryField.District] && !!errors[DeliveryField.District]}
          helperText={touched[DeliveryField.District] && errors[DeliveryField.District]}
        />
        <Stack flexDirection="row" gap={1.25} alignItems="center">
          <Checkbox
            checked={values[DeliveryField.Main]}
            onChange={(_, checked) => setFieldValue(DeliveryField.Main, checked)}
          />

          <TranslatedField
            originText={'main'}
            fontSize={20}
            fontWeight={500}
            isTranslate
            noWrap
            overflow="initial"
          />
        </Stack>
        <Button color="secondary" variant="contained" size="large" type="submit">
          <TranslatedField originText="saveChanges" isTranslate color="white" />
        </Button>
      </Stack>
    </CustomModal>
  );
};
