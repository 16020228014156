/* eslint-disable no-console */
import React, { FC } from 'react';
import { Box } from '@mui/material';
import { Title } from './Title';

interface Props {
  colorButton?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | string;
  isButton?: boolean;
  disableButton?: boolean;
  titleHeader?: string;
  titleButton?: string;
  customChildrenButton?: JSX.Element[] | JSX.Element;
  children?: (JSX.Element | boolean)[] | JSX.Element | boolean;
  handlerButton?: () => void;
}

export const WrapperPages: FC<Props> = ({
  isButton,
  colorButton,
  titleButton,
  titleHeader = 'Enter Title',
  handlerButton = () => console.log('Enter function for this element'),
  customChildrenButton,
  children,
  disableButton,
}) => {
  return (
    <Box width="100%" height="100%">
      <Title
        customChildrenButton={customChildrenButton}
        isButton={isButton}
        colorButton={colorButton}
        titleButton={titleButton}
        titleHeader={titleHeader}
        handlerButton={handlerButton}
        disableButton={disableButton}
      />
      {children}
    </Box>
  );
};
