import { Typography, Stack, Divider } from '@mui/material';
import React, { FC } from 'react';
interface Props {
  value?: string | null | undefined;
  label: string;
  width?: string;
  divider?: boolean;
  labelColor?: string;
}
export const OrderInfoTypography: FC<Props> = ({ value, label, width, divider = true }) => {
  return (
    <>
      <Stack direction={{ xs: 'row', md: 'row' }} spacing={1} sx={{ my: '5px' }}>
        <Typography
          sx={{
            lineHeight: '20px',
            fontSize: '16px',
            fontWeight: 400,
            color: ' #535252',
            width: '80px',
          }}
        >
          {label}
        </Typography>
        <Typography
          sx={{
            lineHeight: '20px',
            fontSize: '16px',
            fontWeight: 400,
            color: '#2A3453',
            width: width,
          }}
        >
          {value}
        </Typography>
      </Stack>
      {divider && <Divider />}
    </>
  );
};
