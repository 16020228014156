import React from 'react';

import { useLocalization } from '../../../../../localization';

export const useClientReturnStatuses = () => {
  const { translateLang } = useLocalization();
  const clientReturnStatuses = [translateLang('formed'), translateLang('forming')];

  return {
    clientReturnStatuses,
  };
};
