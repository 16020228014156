import { useLocalization } from '../../../../../localization';
import { GeneralSpareInputType, CriteriaProps } from '../types';
import { useCreateGeneralSpareMutation } from '../../../../../graphql/mutations/__generated__/createGeneralSpare';

export const useCreateSpareMutation = () => {
  const { updateSelectLanguage } = useLocalization();
  const [createGeneralSpareMutation] = useCreateGeneralSpareMutation();

  const createGeneralSpare = (values: GeneralSpareInputType) => {
    const articleCriteriaEN = values?.criteria?.map((item: CriteriaProps) => ({
      formattedValue: item.titleEN,
      criteriaDescription: item.valueEN,
      criteriaUnitDescription: item.dimensionEN,
    }));
    const articleCriteriaDE = values?.criteria?.map((item: CriteriaProps) => ({
      formattedValue: item.titleDE,
      criteriaDescription: item.valueDE,
      criteriaUnitDescription: item.dimensionDE,
    }));

    return createGeneralSpareMutation({
      variables: {
        input: {
          brandId: +values.brandCode.brandId,
          titleDE: values?.titleDE || '',
          titleEN: values?.titleEN || '',
          // ...(imageSrc ? { images: [imageSrc] } : []),
          ...(values.descriptionENG ? { descriptionsInfoEN: [values.descriptionENG] } : null),
          ...(values.descriptionDE ? { descriptionsInfoDE: [values.descriptionDE] } : null),
          ...(articleCriteriaDE ? { articleCriteriaDE: articleCriteriaDE } : null),
          ...(articleCriteriaEN ? { articleCriteriaEN: articleCriteriaEN } : null),
          tecdoc_articleNumber: values.productCode,
          eanNumber: values.barcodes,
          isDraft: values.isDraft,
        },
        locale: updateSelectLanguage,
      },
    });
  };

  return { createGeneralSpare };
};
