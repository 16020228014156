import React from 'react';

interface ReturnHook {
  tabs: Array<{
    id: number;
    value: 'contact_info' | 'vehicle' | 'history' | 'transactions' | 'balance' | 'return';
  }>;
}
export const useGetTabs = (): ReturnHook => {
  return {
    tabs: [
      { id: 1, value: 'contact_info' },
      { id: 2, value: 'vehicle' },
      { id: 3, value: 'history' },
      // { id: 4, value: 'transactions' },
      { id: 5, value: 'balance' },
      { id: 6, value: 'return' },
    ],
  };
};
