import React, { FC } from 'react';
interface Props {
  heightFrame?: string;
}
const OPACITY = 0.6;
export const VideoFrame: FC<Props> = ({ heightFrame = '250px' }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        left: '0',
        top: '0',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          width: '100%',
          height: 'calc(100% / 2 - 80px)',
          backgroundColor: 'black',
          opacity: OPACITY,
        }}
      />
      <div style={{ flex: 1, width: '100%', display: 'flex' }}>
        <div
          style={{ height: '100%', width: '30px', backgroundColor: 'black', opacity: OPACITY }}
        />
        <div style={{ flex: 1, position: 'relative' }}>
          <div
            style={{
              height: '50px',
              width: '50px',
              position: 'absolute',
              top: '-3px',
              left: '-3px',
              borderLeft: '3px solid white',
              borderTop: '3px solid white',
              zIndex: 3,
            }}
          />
          <div
            style={{
              height: '50px',
              width: '50px',
              position: 'absolute',
              top: '-3px',
              right: '-3px',
              borderTop: '3px solid white',
              borderRight: '3px solid white',
              zIndex: 3,
            }}
          />
          <div
            style={{
              height: '50px',
              width: '50px',
              position: 'absolute',
              bottom: '-3px',
              right: '-3px',
              borderBottom: '3px solid white',
              borderRight: '3px solid white',
              zIndex: 3,
            }}
          />
          <div
            style={{
              height: '50px',
              width: '50px',
              position: 'absolute',
              bottom: '-3px',
              left: '-3px',
              borderLeft: '3px solid white',
              borderBottom: '3px solid white',
              zIndex: 3,
            }}
          />
        </div>
        <div
          style={{ height: '100%', width: '30px', backgroundColor: 'black', opacity: OPACITY }}
        />
      </div>
      <div
        style={{
          width: '100%',
          height: 'calc(100% / 2 - 80px)',
          backgroundColor: 'black',
          opacity: OPACITY,
        }}
      />
    </div>
  );
};
