export enum LoginFields {
  Identifier = 'identifier',
  Password = 'password',
  ConfirmPassword = 'confirmPassword',
  Code = 'code',
}
export enum EnumTabsAuthState {
  Phone,
  Email,
}
export enum SuccessType {
  Register,
  ForgotPassword,
}
export type OptionLoginType = {
  asModal?: boolean;
  phone?: string;
  password?: string;
  email?: string;
  isPhone?: boolean;
  successType?: SuccessType;
};
export type ProsLoginType = {
  state?: OptionLoginType;
  replace?: boolean;
};
