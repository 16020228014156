import React, { FC } from 'react';
import { Avatar, Box, Stack, SxProps, Typography } from '@mui/material';
import { useLocalization } from '../../../../localization';
import { GeneralSparesEntity } from '../../../../__generated__/types';

interface Props {
  spareItem?: GeneralSparesEntity | null;
}

const style: SxProps = {
  lineHeight: '25.1px',
  fontSize: '20px',
  fontWeight: 400,
  color: '#000000',
  marginBottom: '10px',
};
export const InformSpare: FC<Props> = ({ spareItem }) => {
  const { translateLang } = useLocalization();
  const getUrlImage = (): string => {
    if (spareItem) {
      const img = spareItem.attributes?.images;
      if (img[0]?.imageURL400) {
        return img[0]?.imageURL400;
      } else if (img[0]?.imageURL200) {
        return img[0]?.imageURL200;
      }
    }
    return '';
  };
  return (
    <Stack display="flex" width="100%" flexDirection="row">
      <Box width="300px" height="160px">
        <Avatar
          alt="Remy Sharp"
          src={getUrlImage()}
          variant="rounded"
          sx={{
            width: '100%',
            height: '100%',
            '& img': {
              objectFit: 'contain',
            },
          }}
        />
      </Box>
      <Box pl={3} pt={2} ml={3} flex={1} borderLeft="1px solid black">
        <Typography sx={style}>
          {`${translateLang('goods')}: ${spareItem?.attributes?.title}`}
        </Typography>
        <Typography sx={style}>
          {`${translateLang('article')}: ${spareItem?.attributes?.tecdoc_articleNumber}`}
        </Typography>
        <Typography sx={style}>
          {`${translateLang('manufacturer')}: ${spareItem?.attributes?.brandName}`}
        </Typography>
      </Box>
    </Stack>
  );
};
