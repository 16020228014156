import React, { ReactNode } from 'react';
import { ApolloQueryResult } from '@apollo/client';

import { HeaderCell } from '../../../components/TableProduct/TableProduct';
import { useLocalization } from '../../../localization';
import { TableSpareRowItem } from '../components/tablesComponents/TableSpareRowItem';
import { OrderStaffItemInput } from '../../../__generated__/types';
import { GetGeneralSparesQuery } from '../../../graphql/queries/__generated__/getGeneralSpares';

interface HookProps {
  dataCarSpares?: GetGeneralSparesQuery;
  handlerButtonAddOrderItem(params: Omit<OrderStaffItemInput, 'orderId'>): Promise<void>;
  carSparesRefetch(): Promise<ApolloQueryResult<GetGeneralSparesQuery>>;
}
interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTableActive(): Array<ReactNode>;
}

export const useGetTable = ({
  dataCarSpares,
  handlerButtonAddOrderItem,
  carSparesRefetch,
}: HookProps): ReturnHook => {
  const { translateLang } = useLocalization();

  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      { title: translateLang('photo'), containerProps: { width: '180px', align: 'center' } },
      { title: translateLang('article'), containerProps: { width: '100px', align: 'center' } },
      { title: translateLang('productName'), containerProps: { align: 'center' } },
      { title: translateLang('productInformation'), containerProps: { align: 'center' } },
      { title: translateLang('manufacturer'), containerProps: { align: 'center' } },
      { title: translateLang('provider'), containerProps: { width: '70px', align: 'center' } },
      { title: translateLang('inStock'), containerProps: { width: '50px', align: 'center' } },
      { title: translateLang('deliveryTime'), containerProps: { align: 'center' } },
      { title: translateLang('quantity'), containerProps: { width: '100px', align: 'center' } },
      { title: translateLang('price'), containerProps: { width: '100px', align: 'center' } },
      {
        title: translateLang('inCart'),
        containerProps: { width: '50px', align: 'center' },
      },
      {
        title: translateLang('addQuantity'),
        containerProps: { width: '50px', align: 'center' },
      },
    ];
  };
  const getRowsTableActive = (): ReactNode[] => {
    if (dataCarSpares && dataCarSpares.generalSpares?.data.length) {
      return dataCarSpares?.generalSpares?.data?.map(item => {
        if (item.id) {
          return (
            <TableSpareRowItem
              key={item.id}
              product={item}
              handlerButtonAddOrderItem={handlerButtonAddOrderItem}
              handleRefetchCarSpares={carSparesRefetch}
            />
          );
        } else {
          return null;
        }
      });
    }
    return [];
  };

  return {
    getHeadersTable,
    getRowsTableActive,
  };
};
