/* eslint-disable no-console,@typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useRef, useState } from 'react';
import { UseAutocompleteProps } from '@mui/material';
import {
  GetCarCategoriesDocument,
  GetCarCategoriesQueryVariables,
  useGetCarCategoriesQuery,
} from '../../../../../graphql/queries/__generated__/getCarCategories';
import { useLocalization } from '../../../../../localization';
import { useUpdateCategoryMutation } from '../../../../../graphql/mutations/__generated__/updateCarCategory';

import { AutocompleteCustom, AutocompleteItemType } from './AutocompleteCustom';
import { CategoryEntity } from '../../../../../__generated__/types';

interface Props {
  parentSectionID: string;
  label: string;
}

export const SectionItem: FC<Props> = ({ label, parentSectionID }) => {
  const { updateSelectLanguage } = useLocalization();
  const VARIABLES_GET_SECTIONS: GetCarCategoriesQueryVariables = {
    filters: { hasRoot: { eq: true }, tecdoc_linkingTargetType: { eq: 'P' } },
    pagination: { limit: -1 },
    locale: updateSelectLanguage,
    sort: ['name'],
  };
  const [runUpdateCategory] = useUpdateCategoryMutation();
  const { data: CategoriesCatalog } = useGetCarCategoriesQuery({
    variables: VARIABLES_GET_SECTIONS,
  });
  const [selectedValue, setSelectedValue] = useState<AutocompleteItemType[]>([]);
  const [valuesCarCategories, setValuesCarCategories] = useState<AutocompleteItemType[]>([]);
  const [isUpdateCatalog, setIsUpdateCatalog] = useState(false);
  const updateCategoriesRef = useRef<{ id: string; data: CategoryEntity | null }[]>([]);

  const getItems = (): AutocompleteItemType[] => {
    if (CategoriesCatalog && CategoriesCatalog?.carCategories?.data) {
      // const yuri = CategoriesCatalog?.carCategories?.data.map(item => item.attributes?.name);
      // console.log(JSON.stringify(yuri));
      return (
        CategoriesCatalog?.carCategories?.data
          // ?.filter(
          //   item =>
          //     item.attributes?.category?.data === null ||
          //     item.attributes?.category?.data?.id === parentSectionID,
          // )
          ?.map((item, index) => {
            return {
              id: item?.id || index.toString(),
              title: item?.attributes?.name || '',
              targetType: item?.attributes?.tecdoc_linkingTargetType || index.toString(),
            };
          })
      );
    }
    return [];
  };

  const handlerUpdateAutocomplete = async () => {
    const data = selectedValue.map(item => item.id);
    try {
      await runUpdateCategory({
        variables: {
          data: {
            car_root_categories: data,
          },
          id: parentSectionID,
          locale: updateSelectLanguage,
        },
        refetchQueries: [
          {
            query: GetCarCategoriesDocument,
            variables: VARIABLES_GET_SECTIONS,
          },
        ],
        /*update(cache) {
          updateCategoriesRef.current.map(item => {
            cache.updateFragment(
              {
                id: `CarCategoryEntity:${item.id}`,
                fragment: GetCategoryEntityFragmentDoc,
              },
              oldData => {
                const currCategory: Pick<CarCategory, 'category' | 'popular_category'> = {
                  category: {
                    data: item.data,
                  },
                };
                // if (item.data === null) {
                //   currCategory.popular_category = {
                //     data: null,
                //   }
                // }
                const newData = { ...oldData };
                newData.attributes = { ...oldData.attributes, ...currCategory };
                return { ...newData};
              },
            );
          });
        },*/
      });

      // console.log('response', response);
    } catch (err) {
      console.log(err);
    } finally {
      setIsUpdateCatalog(false);
      updateCategoriesRef.current.length = 0;
    }
  };

  const handlerChangeAutocomplete: UseAutocompleteProps<
    AutocompleteItemType,
    any,
    any,
    any
  >['onChange'] = (event, option, reason, details) => {
    if (reason === 'removeOption') {
      updateCategoriesRef.current.push({
        id: details?.option.id || '',
        data: null,
      });
    } else if (reason === 'selectOption') {
      updateCategoriesRef.current.push({
        id: details?.option.id || '',
        data: {
          id: parentSectionID,
          __typename: 'CategoryEntity',
        },
      });
    } else {
      selectedValue.forEach(item => {
        updateCategoriesRef.current.push({
          id: item.id || '',
          data: null,
        });
      });
    }
    setSelectedValue(option as AutocompleteItemType[]);
    setIsUpdateCatalog(true);
  };

  function getSelectedCategories(): AutocompleteItemType[] {
    if (CategoriesCatalog && CategoriesCatalog?.carCategories?.data) {
      return CategoriesCatalog?.carCategories?.data
        ?.filter(item => item?.attributes?.category?.data?.id === parentSectionID)
        .map((item, index) => {
          if (item.attributes?.category?.data?.id) {
            // console.log(item.attributes?.name, item.attributes?.category.data.id);
          }
          return {
            id: item?.id || index.toString(),
            title: item?.attributes?.name || '',
            targetType: item?.attributes?.tecdoc_linkingTargetType || index.toString(),
          };
        });
    }
    return [];
  }

  useEffect(() => {
    if (parentSectionID && CategoriesCatalog) {
      const dataSelectCategories = getSelectedCategories();
      const dataCategories = getItems();
      setValuesCarCategories(dataCategories);
      setSelectedValue(dataSelectCategories);
      setIsUpdateCatalog(false);
    }
  }, [parentSectionID, CategoriesCatalog]);

  return (
    <AutocompleteCustom
      options={valuesCarCategories}
      selectedValue={selectedValue}
      label={label}
      handlerChange={handlerChangeAutocomplete}
      handlerUpdateAutocomplete={handlerUpdateAutocomplete}
      isUpdateCatalog={isUpdateCatalog}
    />
  );
};
