import React, { FC } from 'react';
import { Stack, Button, Box, ButtonProps } from '@mui/material';
import { TranslatedField } from '../Layout/components/TranslatedField/TranslatedField';

interface Props {
  textColor?: string;
  isButton?: boolean;
  variantButton?: ButtonProps['variant'];
  customChildrenButton?: JSX.Element[] | JSX.Element;
  colorButton?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | string;
  titleHeader: string;
  titleButton?: string;
  handlerButton?: () => void;
  disableButton?: boolean;
}

export const Title: FC<Props> = ({
  isButton,
  textColor,
  variantButton = 'contained',
  colorButton = '#5269A3',
  handlerButton,
  titleButton,
  titleHeader,
  customChildrenButton,
  disableButton = false,
  ...props
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      paddingBottom={4}
      paddingTop={2}
      height="50px"
    >
      <TranslatedField originText={titleHeader} isTranslate variant="h5" noWrap color="black" />
      <Box>
        {isButton && (
          <Button
            onClick={handlerButton}
            variant={variantButton}
            disabled={disableButton}
            // color={colorButton}
            style={{
              minWidth: '220px',
              textTransform: 'none',
              backgroundColor: colorButton,
              borderRadius: '10px',
              height: '46px',
            }}
            {...props}
          >
            <TranslatedField
              originText={titleButton || ''}
              fontSize={16}
              isTranslate
              noWrap
              color={textColor}
            />
          </Button>
        )}
        {!isButton && customChildrenButton}
      </Box>
    </Stack>
  );
};
