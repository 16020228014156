import React, { FC, DragEvent } from 'react';
import { DragIndicator } from '@mui/icons-material';
import { Divider, Typography, Box } from '@mui/material';

import { IncomingInvoiceForSplit, StockForSplit } from '../types';
import { roundAmountToString } from '../../../utils/currencyUtils';

type StockRowProps = {
  stock: StockForSplit;
  draggable: boolean;
  acceptDrag?: boolean;
  invoice: IncomingInvoiceForSplit;
  dragEndHandler: (event: DragEvent<HTMLDivElement>) => void;
  dragDropHandler: (
    event: DragEvent<HTMLDivElement>,
    stock: StockForSplit,
    invoice: IncomingInvoiceForSplit
  ) => void;
  dragStartHandler: (
    event: DragEvent<HTMLDivElement>,
    stock: StockForSplit,
    invoice: IncomingInvoiceForSplit
  ) => void;
};

export const StockRow: FC<StockRowProps> = ({
  stock,
  invoice,
  dragEndHandler,
  dragDropHandler,
  draggable = true,
  dragStartHandler,
}) => {
  return (
    <Box
      m={0.5}
      display="flex"
      className="item"
      paddingBottom={1}
      flexDirection="row"
      alignItems="center"
      draggable={draggable}
      justifyContent="center"
      onDragEnd={e => {
        dragEndHandler(e);
        e.currentTarget.style.outline = '2px solid transparent';
      }}
      onDrop={e => {
        dragDropHandler(e, stock, invoice);
        if (e.currentTarget.parentElement) {
          e.currentTarget.parentElement.style.outline = '2px solid transparent';
        }
      }}
      onDragStart={e => dragStartHandler(e, stock, invoice)}
      style={{
        cursor: 'grab',
      }}
    >
      <DragIndicator sx={{ color: '#5F5F5F' }} />
      <Box
        flex={1}
        width="500px"
        display="flex"
        bgcolor="white"
        minHeight="52px "
        borderRadius="5px"
        alignItems="center"
        flexDirection="row"
        justifyContent="stretch"
      >
        <Box
          px={1}
          flex={8}
          display="flex"
          overflow="clip"
          alignItems="center"
          justifyContent="start"
        >
          <Typography>{stock.title}</Typography>
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Box
          flex={5}
          height="32px"
          display="flex"
          paddingX="10px"
          overflow="clip"
          alignItems="center"
          justifyContent="start"
        >
          <Typography noWrap>{stock.brandName}</Typography>
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Box
          flex={2}
          paddingX={1}
          height="32px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {stock.incomingQuantity}
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />

        <Box
          flex={3}
          paddingX={1}
          height="32px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {stock.supplierPrice.toFixed(2)}
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />

        <Box
          flex={3}
          paddingX={1}
          height="32px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {roundAmountToString(stock.supplierPrice * stock.incomingQuantity)}
        </Box>
      </Box>
    </Box>
  );
};
