type FormatCurrencyOptions = {
  digit?: number;
  error?: string;
  prefix?: string;
  suffix?: string;
};
export const formatCurrency = (
  amount?: number | string | null,
  options?: FormatCurrencyOptions
): string => {
  options = options ?? {};
  options.digit = options.digit ?? 2;
  options.error = options.error ?? '';
  options.prefix = options.prefix ?? '';
  options.suffix = options.suffix ?? '';
  if (typeof amount === 'number') {
    return options.prefix + amount.toFixed(options.digit) + options.suffix;
  }

  if (typeof amount === 'string') {
    const value = parseFloat(amount);
    if (Number.isNaN(value) || !Number.isFinite(value)) {
      return options.error;
    }
    return options.prefix + value.toFixed(options.digit) + options.suffix;
  }

  return options.error;
};

export const roundAmountToString = (value?: string | number | null): string => {
  if (value === undefined || value === null) {
    return '';
  }

  if (typeof value === 'string') {
    const numberValue = parseFloat(value);
    if (Number.isNaN(numberValue)) {
      return '';
    }
    return numberValue.toFixed(2);
  }

  return value.toFixed(2);
};

export const roundAmountToNumber = (value: number): number => {
  return Math.round(value * 100) / 100;
};
