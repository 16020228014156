import React, { FC, useEffect, useState } from 'react';
import { Edit, RemoveRedEyeOutlined } from '@mui/icons-material';
import { Box, IconButton, SelectChangeEvent, Stack } from '@mui/material';

import {
  IncomingInvoiceEntity,
  Enum_Incominginvoice_Status,
} from '../../../../../__generated__/types';
import { Icon } from '../../../../../legos';
import { useLocalization } from '../../../../../localization';
import { IncomingInvoiceModal } from './IncomingInvoiceModal';
import { SelectCustom } from '../../../../../legos/SelectCustom';
import { useIncomingInvoiceStatuses } from '../hooks/useIncomingInvoiceStatuses';
import { useHandlerNotificationApp } from '../../../../../hooks/useHandlerNotificationApp';
import { TableProductActive } from '../../../../../components/TableProductActive/TableProductActive';
import { useUpdateIncomingInvoiceMutation } from '../../../../../graphql/mutations/__generated__/updateIncomingInvoice';
import { formatDate } from '../../../../../helpers/functions';

interface Props {
  invoiceData: IncomingInvoiceEntity;
  toggleInvoiceTable: (id: string) => void;
  refetchSupplierBalance: () => void;
}

export const TableIncomingInvoiceRowItem: FC<Props> = ({
  invoiceData,
  toggleInvoiceTable,
  refetchSupplierBalance,
}) => {
  const { translateLang, selectLanguages } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();
  const { incomingInvoiceStatuses } = useIncomingInvoiceStatuses();

  const [updateIncomingInvoiceMutation] = useUpdateIncomingInvoiceMutation();

  const [invoiceModal, setInvoiceModal] = useState(false);

  const status =
    invoiceData.attributes?.status === Enum_Incominginvoice_Status.Completed
      ? translateLang('formed')
      : translateLang('forming');
  const [invoiceStatus, setInvoiceStatus] = useState(status);

  useEffect(() => {
    setInvoiceStatus(status);
  }, [selectLanguages, status]);

  const handleChangeInvoiceStatus = (event: SelectChangeEvent) => {
    const status =
      event.target.value === translateLang('formed')
        ? Enum_Incominginvoice_Status.Completed
        : Enum_Incominginvoice_Status.Created;

    updateIncomingInvoiceMutation({
      variables: {
        data: { status },
        id: invoiceData?.id as string,
      },
    })
      .then(() => {
        addNotification({
          messageError: translateLang('invoiceSuccessfullyUpdated'),
          typeMessage: 'success',
        });
        refetchSupplierBalance();
        setInvoiceStatus(event.target.value);
      })
      .catch(err => {
        addNotification({ messageError: err.message, typeMessage: 'error' });
      });
  };

  const toggleInvoiceModal = () => {
    setInvoiceModal(!invoiceModal);
  };

  return (
    <>
      <TableProductActive.RowTable>
        <TableProductActive.CellTable ceilStyle={{ borderLeft: 'none' }}>
          {invoiceData.attributes?.supplierInvoiceNumber}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable>
          {formatDate(invoiceData.attributes?.supplierInvoiceDate)}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable>
          <Stack flexDirection="row" alignItems="center" gap={1.25}>
            {`${invoiceData.attributes?.total} €`}
            {invoiceData.attributes?.total !== invoiceData.attributes?.supplierInvoiceTotal ? (
              <Icon icon="exclamation" sx={{ color: '#FE720C' }} />
            ) : null}
          </Stack>
        </TableProductActive.CellTable>
        <TableProductActive.CellTable>{`${invoiceData.attributes?.supplierInvoiceTotal} €`}</TableProductActive.CellTable>
        <TableProductActive.CellTable ceilStyle={{ borderLeft: 'none' }}>
          <SelectCustom
            items={incomingInvoiceStatuses}
            value={invoiceStatus}
            currentValue={invoiceStatus}
            handleChange={handleChangeInvoiceStatus}
            height="40px"
            minWidth={160}
            boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset"
          />
        </TableProductActive.CellTable>
        <TableProductActive.CellTable ceilStyle={{ borderLeft: 'none', alignItems: 'end' }}>
          <Box bgcolor="#e7eef4" borderRadius={5}>
            <IconButton onClick={() => toggleInvoiceTable(invoiceData?.id as string)}>
              <RemoveRedEyeOutlined fontSize="small" sx={{ color: '#5269A3' }} />
            </IconButton>
          </Box>
        </TableProductActive.CellTable>
        <TableProductActive.CellTable ceilStyle={{ borderLeft: 'none', alignItems: 'end' }}>
          <Box bgcolor="#e7eef4" borderRadius={5}>
            <IconButton onClick={toggleInvoiceModal}>
              <Edit fontSize="small" sx={{ color: '#5269A3' }} />
            </IconButton>
          </Box>
        </TableProductActive.CellTable>
      </TableProductActive.RowTable>
      {invoiceModal ? (
        <IncomingInvoiceModal
          openModal={invoiceModal}
          closeModal={toggleInvoiceModal}
          editInvoice
          invoiceData={invoiceData}
        />
      ) : null}
    </>
  );
};
