import { useState } from 'react';
import { add, parseISO } from 'date-fns';

import {
  Pagination,
  PaginationArg,
  StockReturnedFromCustomerEntity,
  StockReturnedFromCustomerFiltersInput,
} from '../../../__generated__/types';
import { PAGE_COUNT } from '../../../helpers/constants';
import { useGetStockReturnedFromCustomersQuery } from '../__generated__/getStockReturnedFromCustomers';
import { formatOrderNumber } from '../../../utils/formatOrderNumber';

export interface ReturnedType {
  returnComment?: string;
  returnReason?: string;
  dateReturned?: string;
  dateIncoming?: string;
  productName?: string;
  provider?: string;
  providerId: string;
  article?: string;
  id?: string;
  stockParentId?: string;
  orderNumber?: string;
  cell: string;
  shelf: string;
  stockId: string;
  returnTo?: string;
  customerId: string;
  customerName: string;
}

export type PaginationType = Omit<Pagination, '__typename'>;

interface ReturnHook {
  returnedItems: Array<ReturnedType>;
  pagination?: PaginationType;
  isLoading?: boolean;
  page: number;
  totalPage: number;
  total: number;
  pageSize: number;
  changePage(page: number): void;
  getReturnedItem(idItem: string): void;
  selectItemReturn?: ReturnedType;
}

interface PropsHook {
  pagination?: PaginationArg;
  filters?: StockReturnedFromCustomerFiltersInput;
}

const formatDate = (item: string | number | Date) => {
  const date = new Date(item);
  return date.toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export const normalizeReturnedItem = (item: StockReturnedFromCustomerEntity) => ({
  article:
    item.attributes?.parent_stock?.data?.attributes?.car_spare?.data?.attributes
      ?.tecdoc_articleNumber || '',
  dateIncoming: formatDate(item.attributes?.dateIncoming as string) || '',
  dateReturned: formatDate(item.attributes?.dateReturned as string) || '',
  productName: (
    item.attributes?.parent_stock?.data?.attributes?.car_spare?.data?.attributes?.car_spare_locales?.data.map(
      i => i.attributes?.title
    ) || []
  ).join(', '),
  providerId: item.attributes?.parent_stock?.data?.attributes?.supplier?.data?.id || '',
  provider: item.attributes?.parent_stock?.data?.attributes?.supplier?.data?.attributes?.name || '',
  returnComment: item.attributes?.returnComment || '',
  returnReason: item.attributes?.returnReason || '',
  id: item.id || '',
  stockParentId: item.attributes?.parent_stock?.data?.id || '',
  orderNumber: formatOrderNumber(
    item.attributes?.order_item?.data?.attributes?.order?.data?.attributes?.orderNumber,
    item.attributes?.order_item?.data?.attributes?.order?.data?.attributes?.orderNumberAdd
  ),
  cell:
    item.attributes?.order_item?.data?.attributes?.stock?.data?.attributes?.stock_cell_address?.data
      ?.attributes?.cell || '',
  shelf:
    item.attributes?.order_item?.data?.attributes?.stock?.data?.attributes?.stock_cell_address?.data
      ?.attributes?.shelf || '',
  stockId:
    item.attributes?.order_item?.data?.attributes?.stock?.data?.attributes?.stock_cell_address?.data
      ?.id || '',
  returnTo: formatDate(add(parseISO(item.attributes?.dateIncoming || ''), { days: 14 })),
  customerId:
    item.attributes?.order_item?.data?.attributes?.order?.data?.attributes?.customer?.data?.id ||
    '',
  customerName: `${
    item.attributes?.order_item?.data?.attributes?.order?.data?.attributes?.customer?.data
      ?.attributes?.customer_contact_info?.data?.attributes?.firstName || ''
  }
    ${
      item.attributes?.order_item?.data?.attributes?.order?.data?.attributes?.customer?.data
        ?.attributes?.customer_contact_info?.data?.attributes?.lastName || ''
    }
    (#${
      item.attributes?.order_item?.data?.attributes?.order?.data?.attributes?.customer?.data?.id
    })`,
});

export const useGetOrderItemsReturnedFromCustomers = ({ filters }: PropsHook): ReturnHook => {
  const [page, setPage] = useState(1);
  const [selectItemReturn, setSelectItemReturn] = useState<ReturnedType | undefined>();
  const { data, loading: isLoading } = useGetStockReturnedFromCustomersQuery({
    variables: {
      pagination: {
        page: page,
        pageSize: PAGE_COUNT,
      },
      filters,
      sort: ['id:desc'],
    },
  });

  const changePage = (_page: number): void => {
    setPage(_page + 1);
  };
  const returnedItems: Array<ReturnedType> = [];
  if (data?.stockReturnedFromCustomers?.data) {
    data?.stockReturnedFromCustomers.data.forEach(item => {
      returnedItems.push(normalizeReturnedItem(item as StockReturnedFromCustomerEntity));
    });
  }
  const getReturnedItem = (idItem: string) => {
    if (returnedItems.length) {
      const data = returnedItems.find(item => item.id === idItem);
      setSelectItemReturn(data);
    }
  };

  return {
    returnedItems,
    getReturnedItem,
    selectItemReturn,
    isLoading,
    changePage,
    page,
    total: data?.stockReturnedFromCustomers?.meta.pagination.total || 0,
    pageSize: data?.stockReturnedFromCustomers?.meta.pagination.total || 0,
    totalPage: data?.stockReturnedFromCustomers?.meta.pagination.total
      ? Math.ceil(data?.stockReturnedFromCustomers?.meta.pagination.total / PAGE_COUNT)
      : 0,
  };
};
