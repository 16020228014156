import { useMemo } from 'react';

import {
  GetSupplierPaymentInfoQueryVariables,
  useGetSupplierPaymentInfoQuery,
} from '../query/__generated__/getSupplierPaymentInfo';
import {
  SupplierPaymentDetailEntity,
  SupplierPaymentEntity,
  SupplierReturnEntity,
} from '../../../../../../../__generated__/types';

export const useSupplierPaymentInfo = (variables: GetSupplierPaymentInfoQueryVariables) => {
  const { data, ...rest } = useGetSupplierPaymentInfoQuery({
    variables,
    fetchPolicy: 'cache-and-network',
  });

  const supplierPaymentInfo = useMemo(() => {
    const supplierPaymentInfoResult: {
      supplierPaymentDetails: SupplierPaymentDetailEntity[];
      supplierPayment?: SupplierPaymentEntity;
      supplierReturn?: SupplierReturnEntity;
    } = {
      supplierPaymentDetails: [],
      supplierPayment: undefined,
      supplierReturn: undefined,
    };

    if (data?.supplierPaymentDetails?.data?.length) {
      supplierPaymentInfoResult.supplierPaymentDetails = data?.supplierPaymentDetails?.data;
    }

    if (data?.supplierPayment?.data) {
      supplierPaymentInfoResult.supplierPayment = data?.supplierPayment.data;
    }

    if (data?.supplierReturn?.data) {
      supplierPaymentInfoResult.supplierReturn = data?.supplierReturn.data;
    }

    return supplierPaymentInfoResult;
  }, [data]);

  return {
    supplierPaymentInfo,
    ...rest,
  };
};
