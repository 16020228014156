import { format, isValid, parse, parseISO } from 'date-fns';

type FormatDateTimeOptions = {
  format?: string;
  parse?: string;
  isISO?: boolean;
  error?: string;
};

export const formatDateTime = (
  date?: string | Date | null | number,
  options?: FormatDateTimeOptions
): string => {
  options = options ?? {};
  options.isISO = options.isISO ?? false;
  options.format = options.format ?? 'Pp';
  options.error = options.error ?? '';
  options.parse = options.parse ?? 'yyyy-MM-dd';

  if (!date) {
    return options.error;
  }
  let value = date;

  if (typeof value === 'string') {
    if (options.isISO) {
      value = parseISO(value);
    } else {
      value = parse(value, options.parse, new Date(2024, 1, 1, 0, 0, 0));
    }
  }
  if (isValid(value)) {
    return format(value, options.format);
  }

  return options.error;
};
