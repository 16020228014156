import React, { FC } from 'react';
import { Box } from '@mui/material';
import { TableProductActive } from '../../../../../components/TableProductActive/TableProductActive';
import { ReviewsType } from '../../../../../graphql/queries/hook/useGetReviews';
import { useReviewsTabTable } from '../hooks/useReviewsTabTable';
interface Props {
  totalPage: number;
  reviews: ReviewsType[];
  currentPage?: number;
  changePage(page: number): void;
  loading?: boolean;
}

export const ReviewsTable: FC<Props> = ({
  changePage,
  reviews,
  totalPage,
  currentPage,
  loading,
}) => {
  const { getHeadersTable, getRowsTableActive } = useReviewsTabTable({ reviews });
  return (
    <Box boxSizing="border-box" width="100%">
      <TableProductActive
        startPage={currentPage}
        totalPages={totalPage}
        itemsRows={getRowsTableActive()}
        itemsHeader={getHeadersTable()}
        changeCurrentPage={changePage}
        loading={loading}
      />
    </Box>
  );
};
