import React, { FC, useRef, useState } from 'react';
import { BreadCrumps } from './BreadCrumps';
import { SelectCustom } from '../../../legos/SelectCustom';
import { Avatar, Box, Toolbar, Typography } from '@mui/material';
import { useLocalization } from '../../../localization';
import { LANGS } from '../../../localization/languages';
import { SelectChangeEvent } from '@mui/material/Select';
import { AppBar as AppBarCustom } from '../helpers/styledComponents';
import { Enum_Language_Type } from '../../../__generated__/types';
import { useGetMe } from '../../../graphql/queries/hook/useGetMe';

interface Props {
  open: boolean;
}

export const AppBar: FC<Props> = ({ open }) => {
  const { lastName, firstName, username, role } = useGetMe();
  const { setCurrentLanguage, selectLanguages, translateLang } = useLocalization();
  const [currentValue, setCurrentValue] = useState(LANGS[0]);
  const timer = useRef<NodeJS.Timer>();
  const counter = useRef(0);

  const handleChange = (event: SelectChangeEvent) => {
    setCurrentLanguage(event.target.value as Enum_Language_Type);
    setCurrentValue(event.target.value as string);
  };
  const getUserData = () => {
    if (lastName && firstName) {
      return `${firstName} ${lastName}  (${translateLang(role)})`;
    }
    return `${username} (${translateLang(role)})`;
  };
  const handlerClickAvatar = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      counter.current = 0;
    }, 500);
    if (counter.current === 4) {
      const dataStore = localStorage.getItem('console');
      if (dataStore) {
        const isConsole = !!JSON.parse(dataStore);
        localStorage.setItem('console', `${!isConsole}`);
      } else {
        localStorage.setItem('console', 'true');
      }
    }
    counter.current++;
  };

  return (
    <AppBarCustom position="fixed" open={open}>
      <Toolbar>
        <BreadCrumps />
        {/*<Button
          onClick={() => navigate('/translate', { replace: true })}
          variant="contained"
          color="secondary"
          style={{
            textTransform: 'none',
            marginRight: '24px',
          }}
        >
          <TranslatedField
            capitalLetter
            originText={'translations'}
            fontSize={16}
            isTranslate
            noWrap
            color="white"
          />
        </Button>*/}
        <Typography marginRight={3} fontSize="20px" color="black">
          {getUserData()}
        </Typography>
        <Avatar onClick={handlerClickAvatar} alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
        <Box marginLeft={2} mr={2}>
          <SelectCustom
            minWidth={60}
            height="30px"
            items={LANGS}
            label="lang"
            value={selectLanguages}
            currentValue={currentValue}
            handleChange={handleChange}
          />
        </Box>
        {/*<Typography fontSize="14px" color="black">{`version: ${VERSION_APP}`}</Typography>*/}
      </Toolbar>
    </AppBarCustom>
  );
};
