import React, { Dispatch, FC, SetStateAction } from 'react';
import { Button, Stack } from '@mui/material';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useLocalization } from '../../../../localization';

interface Props {
  editing: boolean;
  runStickerPrint(): void;
  changeMode: Dispatch<SetStateAction<boolean>>;
  saveChangeAddressOrderItems(): void;
}
export const BlockButtons: FC<Props> = ({
  editing,
  runStickerPrint,
  changeMode,
  saveChangeAddressOrderItems,
}) => {
  const { translateLang } = useLocalization();

  if (!editing) {
    return (
      <Stack direction="row" spacing={4} width="100%" justifyContent="center">
        <Button
          onClick={runStickerPrint}
          variant="contained"
          color="secondary"
          style={{
            width: '40%',
            marginRight: '2px',
            borderRadius: '10px',
          }}
        >
          <TranslatedField
            originText={translateLang('stickerPrint')}
            fontSize={16}
            isTranslate
            noWrap
            color="white"
            textTransform="none"
          />
        </Button>
        <Button
          onClick={() => changeMode(true)}
          variant="outlined"
          style={{
            width: '40%',
            marginLeft: '2px',
            borderRadius: '10px',
          }}
        >
          <TranslatedField
            originText={translateLang('edit')}
            fontSize={16}
            isTranslate
            noWrap
            color="black"
            textTransform="none"
          />
        </Button>
      </Stack>
    );
  }

  return (
    <Stack direction="row" spacing={4} width="100%" justifyContent="center">
      <Button
        onClick={() => changeMode(false)}
        variant="outlined"
        style={{
          width: '40%',
          marginLeft: '2px',
          borderRadius: '10px',
        }}
      >
        <TranslatedField
          originText={translateLang('back').toUpperCase()}
          fontSize={16}
          isTranslate
          noWrap
          color="black"
          textTransform="none"
        />
      </Button>
      <Button
        onClick={saveChangeAddressOrderItems}
        variant="outlined"
        style={{
          width: '40%',
          marginLeft: '2px',
          borderRadius: '10px',
        }}
      >
        <TranslatedField
          originText={translateLang('save')}
          fontSize={16}
          isTranslate
          noWrap
          color="black"
          textTransform="none"
        />
      </Button>
    </Stack>
  );
};
