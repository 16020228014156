import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useGetTableProductMarkup } from './hooks/useGetTableProductMarkup';
import { TableProductActive } from '../../../../components/TableProductActive/TableProductActive';
import { DataModalType } from '../../../../helpers/types';
import { MarkupInput } from '../../../../__generated__/types';
import { MarkupModal } from '../Modal/MarkupModal';

export type MarkupDataModal = {
  markup: number;
  customerMarkup: keyof MarkupInput | null;
};
export const ProductMarkup = () => {
  const [markupModal, setMarkupModal] = useState<DataModalType<MarkupDataModal>>({
    isOpen: false,
    selProduct: {
      markup: 0,
      customerMarkup: null,
    },
  });
  const { loading, getRowsTableActive, getHeadersTable } = useGetTableProductMarkup({
    openMarkupModal: setMarkupModal,
  });

  return (
    <Box width="100%" pt={2} boxSizing="border-box">
      <TableProductActive
        itemsHeader={getHeadersTable()}
        itemsRows={getRowsTableActive()}
        loading={loading}
      />
      {markupModal.isOpen && (
        <MarkupModal
          open={true}
          customerMarkup={markupModal.selProduct?.customerMarkup ?? 'customer'}
          currentMarkup={markupModal.selProduct?.markup ?? 0}
          handleClose={() => setMarkupModal({
            isOpen: false,
            selProduct: {
              markup: 0,
              customerMarkup: null,
            },
          })}
        />
      )}
    </Box>
  );
};
