import {
  Add,
  Done,
  Edit,
  Group,
  Check,
  Close,
  Print,
  Logout,
  Difference,
  HighlightOff,
  ShoppingCart,
  ArrowDropDown,
  ArrowDropUp,
  CalendarMonth,
  DragIndicator,
  DeleteOutlined,
  CropFreeOutlined,
  RadioButtonChecked,
  RemoveCircleOutline,
  RadioButtonUnchecked,
  LocalShippingOutlined,
  CalendarToday,
  Report,
  Save,
} from '@mui/icons-material';
import { IconsNamesMapType } from './types';

export const IconsMap: IconsNamesMapType = {
  add: Add,
  done: Done,
  edit: Edit,
  group: Group,
  check: Check,
  close: Close,
  print: Print,
  logout: Logout,
  difference: Difference,
  deleting: DeleteOutlined,
  highlightOff: HighlightOff,
  shoppingCart: ShoppingCart,
  cropFree: CropFreeOutlined,
  remove: RemoveCircleOutline,
  arrowDropDown: ArrowDropDown,
  arrowDropUp: ArrowDropUp,
  calendarMonth: CalendarMonth,
  dragIndicator: DragIndicator,
  shippingCar: LocalShippingOutlined,
  radioButtonChecked: RadioButtonChecked,
  radioButtonUnchecked: RadioButtonUnchecked,
  calendar: CalendarToday,
  exclamation: Report,
  save: Save,
};
