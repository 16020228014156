import React, { FC } from 'react';

import { Typography, Stack } from '@mui/material';

interface Typography {
  onClick?: () => void;
  value?: string | null | undefined;
  label?: string;
  maxWidth?: string;
  width?: string;
  placeholder?: string;
  disabled?: boolean;
  stackPosition?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
}
export const StyledTypography: FC<Typography> = ({
  label,
  value,
  maxWidth,
  width,
  onClick,
  stackPosition,
}) => {
  return (
    <Stack
      sx={{ flexGrow: 1 }}
      direction={stackPosition}
      justifyContent="space-between"
      onClick={onClick}
      spacing={1}
    >
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: '500',
          height: '17px',
          lineHeight: '17px',
          color: '#2A3453',
        }}
      >
        {label}
      </Typography>

      <Typography
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: '#7A828A',
          paddingLeft: '16px',
          paddingTop: '12px',
          paddingBottom: '16px',
          backgroundColor: '#F1F3F8',
          borderRadius: '6px',
          maxWidth: maxWidth,
          width: width,
          minHeight: '18px',
          maxHeight: '18px',
          minWidth: '100px',
        }}
      >
        {value}
      </Typography>
    </Stack>
  );
};
