import React from 'react';
import { Box, Typography } from '@mui/material';
import { WrapperShadow } from './components/WrapperShadow';
import { ButtonOrange } from './components/ButtonOrange';
import { StarsSVG } from './components/StarsSVG';
import { useLocalization } from '../../localization';
import { useHelpersNavigate } from './hooks/helpersNavigate';
import { useLocation } from 'react-router-dom';
import { OptionLoginType, SuccessType } from './helpers/types';

export const SuccessRegisterPage = () => {
  const { translateLang } = useLocalization();
  const { goToLoginPage } = useHelpersNavigate();
  const stLoc = useLocation().state as OptionLoginType;

  const getTextSuccess = () => {
    if (stLoc?.successType === SuccessType.Register) {
      return (
        <>
          <Typography fontSize="24px" textAlign="center" color="#F44336" fontWeight={700} mb={2}>
            {translateLang('registerSuccess')}
          </Typography>
          <Typography fontSize="18px" textAlign="center">
            {`${translateLang(stLoc.isPhone ? 'phoneThankYou' : 'emailThankYou')} 💌`}
          </Typography>
        </>
      );
    } else if (stLoc?.successType === SuccessType.ForgotPassword) {
      return (
        <>
          <Typography fontSize="24px" textAlign="center" color="#F44336" fontWeight={700} mb={2}>
            {translateLang('requestResetPassword')}
          </Typography>
          <Typography fontSize="18px" textAlign="center">
            {`${translateLang('subtitleResetPassword')} 💌`}
          </Typography>
        </>
      );
    }
    return null;
  };

  return (
    <WrapperShadow title="">
      <Box display="flex" justifyContent="center" width="100%" mb={3}>
        <StarsSVG />
      </Box>
      {getTextSuccess()}
      <ButtonOrange
        type="button"
        titleButton={translateLang('signIn')}
        handlerClickButton={() => goToLoginPage({ replace: true })}
      />
    </WrapperShadow>
  );
};
