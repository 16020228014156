import React, { FC } from 'react';
import { IconButton, Stack } from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { PatchesName } from '../../../types/types';

interface MobileTopNavBarProps {
  totalStep: number;
  activeStep: number;
  steps?: [value: string, step: number];
  onPrev?: () => void;
  withoutStep?: boolean;
}

export const MobileTopNavBar: FC<MobileTopNavBarProps> = ({ onPrev, withoutStep }) => {
  const navigate = useNavigate();
  const onHome = () => {
    navigate(`/${PatchesName.Delivery}`, {
      replace: true,
    });
  };

  return (
    <Stack direction="row" sx={{ backgroundColor: '#ffff' }} justifyContent="space-between">
      {!withoutStep && (
        <IconButton onClick={onPrev}>
          <ArrowBackIcon />
        </IconButton>
      )}
      <IconButton onClick={onHome}>
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};
