import React, { FC, useState } from 'react';
import { Typography, Stack, Divider, Popover } from '@mui/material';
interface Props {
  value?: string | null | undefined;
  label: string;
  width?: string;
  divider?: boolean;
  labelColor?: string;
}
export const CustomTypographyDesktop: FC<Props> = ({
  value,
  label,
  divider = true,
  labelColor = '#7A8095',
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: {
    currentTarget: React.SetStateAction<HTMLElement | null>;
  }) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const trimReviewText = (reviewText: string) => {
    return reviewText.substring(0, 35).trim() + '...';

    return reviewText;
  };
  return (
    <Stack direction="row" spacing={1} my="2px">
      <Typography
        sx={{
          lineHeight: { lg: '17px', xs: '15px' },
          fontSize: { lg: '15px', xs: '12px' },
          fontWeight: { lg: '600', md: '500', sm: '400' },
          color: labelColor,
        }}
      >
        {label}
      </Typography>
      {value && value.length > 35 ? (
        <>
          <Typography
            sx={{
              lineHeight: '17px',
              fontSize: '14px',
              fontWeight: '400',
              color: '#000000',
            }}
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            {trimReviewText(value || '')}
          </Typography>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'white',
                padding: '8px',
                maxWidth: '200px',
              }}
            >
              <Typography
                sx={{
                  lineHeight: '17px',
                  fontSize: '14px',
                  fontWeight: '400',
                  color: '#000000',
                }}
              >
                {value}
              </Typography>
            </div>
          </Popover>
        </>
      ) : (
        <Typography
          sx={{
            lineHeight: '17px',
            fontSize: '14px',
            fontWeight: '400',
            color: '#000000',
          }}
        >
          {value}
        </Typography>
      )}
    </Stack>
  );
};
