import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';

import { RolesEnum } from '../../../types/types';
import { StyledTypography } from './StyledTypography';
import { useLocalization } from '../../../localization';
import { formatPhoneNumber } from '../../../helpers/functions';
import { useGetMe } from '../../../graphql/queries/hook/useGetMe';
import { ChangeCustomerTypeModal } from './Modals/ChangeCastomerTypeModal';
import { CustomerType } from '../../../graphql/queries/hook/useGetClientById';

type Fields = CustomerType;

export const UserInformaton: FC<Fields> = ({ typeClient, id, email, primaryPhoneNumber }) => {
  const { translateLang } = useLocalization();
  const { isAdmin, isManager, isSuperAdmin } = useGetMe();
  const [modal, setModal] = useState(false);

  const openModal = () => {
    if (isAdmin || isManager || isSuperAdmin) {
      setModal(true);
    }
    if (isAdmin && typeClient === RolesEnum.SuperAdmin) {
      setModal(false);
    }
  };

  const closeModal = () => setModal(false);

  return (
    <Stack spacing={4}>
      <StyledTypography
        onClick={openModal}
        label={translateLang('customerType')}
        value={translateLang(typeClient || '')}
        maxWidth="425px"
      />
      <StyledTypography label="ID" value={id} maxWidth="425px" />
      <StyledTypography
        label={translateLang(primaryPhoneNumber ? 'phone' : 'email')}
        value={primaryPhoneNumber ? formatPhoneNumber(primaryPhoneNumber) : email}
        maxWidth="425px"
      />

      {modal && <ChangeCustomerTypeModal clientId={id} closeModal={closeModal} />}
    </Stack>
  );
};
