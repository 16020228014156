import React, { FC } from 'react';

import { CustomModal } from '../../../../../components';
import { useLocalization } from '../../../../../localization';
import { theme } from '../../../../../helpers';
import { IncomingInvoiceEntity } from '../../../../../__generated__/types';
import { IncomingInvoiceForm } from '../../../../../components/IncomingInvoiceForm/IncomingInvoiceForm';

interface IncomingInvoiceModalProps {
  closeModal: () => void;
  openModal: boolean;
  editInvoice?: boolean;
  invoiceData?: IncomingInvoiceEntity;
  supplier?: string | null;
  refetchIncomingInvoices?: () => void;
}

export const IncomingInvoiceModal: FC<IncomingInvoiceModalProps> = ({
  closeModal,
  openModal,
  editInvoice,
  invoiceData,
  supplier,
  refetchIncomingInvoices,
}) => {
  const { translateLang } = useLocalization();

  return (
    <CustomModal
      title={
        editInvoice ? translateLang('editIncomingInvoice') : translateLang('createIncomingInvoice')
      }
      handleClose={closeModal}
      open={openModal}
      bgcolor={theme.palette.common.lightGrey}
    >
      <IncomingInvoiceForm
        isModal
        invoiceData={invoiceData}
        editInvoice={editInvoice}
        closeModal={closeModal}
        supplier={supplier}
        refetchIncomingInvoices={refetchIncomingInvoices}
      />
    </CustomModal>
  );
};
