import React, { ElementType, FC, ReactNode } from 'react';

import { Typography, TypographyProps } from '@mui/material';
import { useLocalization } from '../../../../localization';

interface TranslatedFieldProps extends TypographyProps {
  color?: string;
  originText: string;
  children?: ReactNode;
  isTranslate?: boolean;
  capitalLetter?: boolean;
  suffix?: string;
  prefix?: string;
  component?: ElementType<any>;
}

export const TranslatedField: FC<TranslatedFieldProps> = ({
  color,
  children,
  originText,
  isTranslate,
  capitalLetter,
  suffix,
  prefix,
  component = 'p',
  ...props
}) => {
  const { translateLang } = useLocalization();
  const getText = () => {
    if (isTranslate) {
      let currentTrans = translateLang(originText);
      if (capitalLetter) {
        currentTrans = currentTrans[0].toUpperCase() + currentTrans.slice(1);
      }
      return currentTrans;
    }

    if (capitalLetter) {
      return originText[0].toUpperCase() + originText.slice(1);
    }
    return originText;
  };
  return (
    <>
      <Typography
        sx={{
          textAlign: props.textAlign || 'left',
          fontWeight: props.fontWeight ?? '500',
          color: { color },
        }}
        component={component}
        {...props}
      >
        {`${prefix ? prefix : ''}${getText()}${suffix ? suffix : ''}`}
      </Typography>
      {children}
    </>
  );
};
