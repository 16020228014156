import React, { FC, useRef, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { Icon } from '../../../legos';
import { theme } from '../../../helpers';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { useGetSupplierSupportAutoOrderingQuery } from '../../../graphql/queries/__generated__/getSupplierSupportAutoOrdering';
import { useLocalization } from '../../../localization';
import { SupplierSupportAutoOrderingRow } from './SupplierSupportAutoOrderingRow';
import { useAutoOrderingMutation } from '../../../graphql/mutations/__generated__/autoOrdering';
import { ContainerCell, StyledTableCell } from '../../../components/TableProduct/TableCellProduct';
import { NoRowsImage } from '../../../components/NoRowsImage/NoRowsImage';
import { ConfirmSendingOrderDialog } from './ConfirmSendingOrderDialog';

interface SupplierSupportAutoOrderingModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  orderId: string;
}

export const SupplierSupportAutoOrderingModal: FC<SupplierSupportAutoOrderingModalProps> = ({
  isModalOpen,
  handleClose,
  orderId,
}) => {
  const supplierIdRef = useRef<string | undefined>();
  const supplierNameRef = useRef<string | undefined>();
  const { updateSelectLanguage, translateLang } = useLocalization();

  const [showConfirmSendingOrderDialog, setShowConfirmSendingOrderDialog] = useState(false);
  const { data: suppliersData, loading } = useGetSupplierSupportAutoOrderingQuery({
    variables: {
      orderId,
      locale: updateSelectLanguage as string,
    },
  });

  const handleCloseConfirmSendingOrderDialog = () => {
    supplierIdRef.current = undefined;
    supplierNameRef.current = undefined;
    setShowConfirmSendingOrderDialog(false);
  };

  const handleOpenConfirmSendingOrderDialog = (supplierId?: string, supplierName?: string) => {
    supplierIdRef.current = supplierId;
    supplierNameRef.current = supplierName;
    setShowConfirmSendingOrderDialog(true);
  };

  const [runAutoOrdering, { loading: autoOrderingLoading }] = useAutoOrderingMutation();

  const handleAutoOrdering = () => {
    runAutoOrdering({
      variables: {
        orderId,
        supplierId: supplierIdRef.current,
      },
    }).finally(() => {
      handleCloseConfirmSendingOrderDialog();
    });
  };

  return (
    <>
      <Dialog
        open={isModalOpen}
        onClose={() => handleClose()}
        closeAfterTransition
        maxWidth="lg"
        fullWidth
        disableScrollLock
      >
        <DialogTitle>
          <Stack alignItems="center">
            <Stack width="100%" flexDirection="row" justifyContent="end" mb={2}>
              <IconButton onClick={() => handleClose()} sx={{ p: 0 }}>
                <Icon icon="close" sx={{ color: theme.palette.primary.main }} />
              </IconButton>
            </Stack>
            <TranslatedField
              isTranslate
              variant="h4"
              color={theme.palette.common.darkBlue}
              marginBottom={2.5}
              fontSize={30}
              originText="automaticOrder"
            />
          </Stack>
          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={() => {
                handleOpenConfirmSendingOrderDialog();
              }}
              disabled={!suppliersData?.supplierSupportAutoOrdering?.length}
              variant="contained"
              color="warning"
              sx={{
                mb: 2,
                mr: 3,
                width: 150,
                textTransform: 'none',
                borderRadius: '5px',
              }}
            >
              <TranslatedField
                originText="sendAll"
                fontSize={16}
                isTranslate
                noWrap
                color={'#fff'}
              />
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ height: '70vh' }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell />
                  <StyledTableCell>
                    <ContainerCell justifyContent="flex-start">
                      {translateLang('provider')}
                    </ContainerCell>
                  </StyledTableCell>
                  <StyledTableCell>
                    <ContainerCell justifyContent="flex-start">
                      {translateLang('total')}{' '}
                    </ContainerCell>
                  </StyledTableCell>
                  <StyledTableCell>
                    <ContainerCell justifyContent="flex-start">
                      {translateLang('action')}{' '}
                    </ContainerCell>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {suppliersData?.supplierSupportAutoOrdering?.map(
                  supplierSupportAutoOrdering =>
                    !!supplierSupportAutoOrdering && (
                      <SupplierSupportAutoOrderingRow
                        handleOpenConfirmSendingOrderDialog={handleOpenConfirmSendingOrderDialog}
                        autoOrdering={handleAutoOrdering}
                        key={supplierSupportAutoOrdering?.id}
                        supplier={supplierSupportAutoOrdering}
                        translateLang={translateLang}
                      />
                    )
                )}
              </TableBody>
            </Table>
            {!suppliersData?.supplierSupportAutoOrdering?.length && (
              <Box p="20px 30px" display="flex" justifyContent="center">
                {loading ? <CircularProgress size={30} color="secondary" /> : <NoRowsImage />}
              </Box>
            )}
          </TableContainer>
        </DialogContent>
      </Dialog>
      <ConfirmSendingOrderDialog
        dialogType={supplierIdRef.current ? 'sendOne' : 'sendAll'}
        isModalOpen={showConfirmSendingOrderDialog}
        supplierName={supplierNameRef.current}
        handleClose={handleCloseConfirmSendingOrderDialog}
        yesAction={handleAutoOrdering}
        loading={autoOrderingLoading}
      />
    </>
  );
};
