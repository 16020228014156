import React, { FC } from 'react';
import { useLocalization } from '../../../../../../localization';
import { Stack, Button } from '@mui/material';
import { NoRowsImage } from '../../../../../../components/NoRowsImage/NoRowsImage';
import { MODE } from '../../types';

interface Props {
  setCodeSearch: React.Dispatch<React.SetStateAction<MODE>>;
}
export const NonSearched: FC<Props> = ({ setCodeSearch }) => {
  const { translateLang } = useLocalization();

  return (
    <Stack justifyContent="center" alignItems="center" bgcolor="#FFFFFF">
      <NoRowsImage text="productNotFoundturn" />
      <Stack direction="row" spacing={1} mt="20px" mb="10px">
        <Button
          sx={{ textTransform: 'none', bgcolor: '#5269A3', color: '#FFFFFF' }}
          onClick={() => setCodeSearch('manualSearch')}
        >
          {translateLang('codeManually')}
        </Button>
        <Button
          sx={{ textTransform: 'none', bgcolor: '#97A408', color: '#FFFFFF' }}
          onClick={() => setCodeSearch('inProcess')}
        >
          {translateLang('scanOrder')}
        </Button>
      </Stack>
    </Stack>
  );
};
