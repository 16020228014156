import React, { ReactNode } from 'react';
import { useLocalization } from '../../../../../localization';
import { HeaderCell } from '../../../../../components/TableProductActive/TableProductActive';
import { TableWarehouseOrdersRowItem } from '../components/tablesComponents/TableWarehouseOrdersRowItem';
import { OrderItemWarehouseType } from '../../../../../graphql/queries/hook/useGetOrdersWarehouse';
import { useGetMe } from '../../../../../graphql/queries/hook/useGetMe';
import { RolesEnum } from '../../../../../types/types';

interface HookProps {
  orderItems?: OrderItemWarehouseType[];
  printDeliveryNote: (orderId: string) => void;
}
interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTableActive(): Array<ReactNode>;
}

export const useGetWarehouseOrdersTable = ({
  orderItems,
  printDeliveryNote,
}: HookProps): ReturnHook => {
  const { role } = useGetMe();
  const { translateLang } = useLocalization();

  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      {
        title: translateLang('№'),
        containerProps: { width: '56px', color: '#F1F3F8', align: 'center' },
      },

      {
        title: translateLang('client'),
        containerProps: { width: '240px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('place'),
        containerProps: { width: '260px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('status'),
        containerProps: { width: '800px', color: '#f1f3f8', align: 'left' },
      },
      {
        title: '',
        containerProps: { width: '30px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: '',
        containerProps: { width: '30px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: '',
        containerProps: { width: '30px', color: '#f1f3f8', align: 'center' },
      },
    ];
  };

  const getRowsTableActive = (): ReactNode[] => {
    if (orderItems && orderItems?.length) {
      return orderItems?.map((item, index) => {
        return (
          <TableWarehouseOrdersRowItem
            printDeliveryNote={printDeliveryNote}
            isWarehouse={role === RolesEnum.WarehouseManager}
            key={index}
            index={index}
            orderId={item.orderId}
            orderNumber={item.orderNumber}
            customerFirstName={item.customerFirstName}
            customerLastName={item.customerLastName}
            customerId={item.customerId}
            orderQuantity={item.orderQuantity}
            stockAddressOrder={item.stockAddressOrder}
            balanceQuantity={item.balanceQuantity}
            statusOrder={item.status}
          />
        );
      });
    }
    return [];
  };

  return {
    getHeadersTable,
    getRowsTableActive,
  };
};
