import React, { FC } from 'react';
import { Stack, Button, CircularProgress } from '@mui/material';

import { useLocalization } from '../../../../../localization';
import { useFormik } from 'formik';
import { Input } from '../../../../../legos';
import { theme } from '../../../../../helpers';
import { NewBrandField } from '../types';
import { TranslatedField } from '../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useValidation } from '../hooks/useValidation';
import { useAddGeneralSpareBrandMutation } from '../../../../../graphql/mutations/__generated__/addGeneralSpareBrand';
import { useHandlerNotificationApp } from '../../../../../hooks/useHandlerNotificationApp';
import { CustomModal } from '../../../../../components';

const initialValues = {
  [NewBrandField.BrandNameEN]: '',
  [NewBrandField.BrandNameDE]: '',
};

interface AddNewBrandFormProps {
  refetchBrands: any;
  handleToggleModal: () => void;
  isOpenModal: boolean;
}

export const AddNewBrandForm: FC<AddNewBrandFormProps> = ({
  refetchBrands,
  handleToggleModal,
  isOpenModal,
}) => {
  const { translateLang, updateSelectLanguage } = useLocalization();

  const { validationSchemaNewBrand } = useValidation();
  const { addNotification } = useHandlerNotificationApp();
  const [addGeneralSpareBrandMutation, { loading: loadingNewBrand }] =
    useAddGeneralSpareBrandMutation();

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchemaNewBrand,
    enableReinitialize: true,
    onSubmit: ({ brandNameEN, brandNameDE }) => {
      addGeneralSpareBrandMutation({
        variables: {
          input: {
            brandNameEN,
            brandNameDE,
          },
          locale: updateSelectLanguage,
        },
      }).then(() => {
        handleCloseModal();
        refetchBrands();
        addNotification({
          messageError: translateLang('brandSuccessfullyCreated'),
          typeMessage: 'success',
        });
      });
    },
  });

  const handleCloseModal = () => {
    formik.resetForm();
    handleToggleModal();
  };

  return (
    <CustomModal
      title={translateLang('addNewBrand')}
      handleClose={handleCloseModal}
      open={isOpenModal}
    >
      <form onSubmit={formik.handleSubmit}>
        <Stack gap={5} mt={5}>
          <Input
            name={NewBrandField.BrandNameEN}
            labelColor={theme.palette.primary.light}
            label={translateLang('brandTitleENG')}
            value={formik.values[NewBrandField.BrandNameEN]}
            onChange={formik.handleChange}
            error={
              formik.touched[NewBrandField.BrandNameEN] &&
              !!formik.errors[NewBrandField.BrandNameEN]
            }
            helperText={
              formik.touched[NewBrandField.BrandNameEN] && formik.errors[NewBrandField.BrandNameEN]
            }
          />
          <Input
            name={NewBrandField.BrandNameDE}
            labelColor={theme.palette.primary.light}
            label={translateLang('brandTitleDE')}
            value={formik.values[NewBrandField.BrandNameDE]}
            onChange={formik.handleChange}
            error={
              formik.touched[NewBrandField.BrandNameDE] &&
              !!formik.errors[NewBrandField.BrandNameDE]
            }
            helperText={
              formik.touched[NewBrandField.BrandNameDE] && formik.errors[NewBrandField.BrandNameDE]
            }
          />
          <Button
            variant="contained"
            type="submit"
            sx={{
              width: '100%',
              height: '50px',
              borderRadius: '10px',
              bgcolor: theme.palette.secondary.main,
            }}
          >
            <TranslatedField originText={'save'} fontSize={18} isTranslate noWrap />
            {loadingNewBrand ? (
              <CircularProgress size={20} sx={{ color: '#97A408', ml: 2 }} />
            ) : null}
          </Button>
        </Stack>
      </form>
    </CustomModal>
  );
};
