import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { BannerEntityFragmentDoc } from '../../fragments/__generated__/bannerEntity';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type CreateImageBannerMutationVariables = Types.Exact<{
  data: Types.BannerInput;
  locale?: Types.InputMaybe<Types.Scalars['I18NLocaleCode']>;
}>;


export type CreateImageBannerMutation = { __typename?: 'Mutation', createBanner?: { __typename?: 'BannerEntityResponse', data?: { __typename?: 'BannerEntity', id?: string | null, attributes?: { __typename?: 'Banner', image: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', id?: string | null, attributes?: { __typename?: 'UploadFile', height?: number | null, width?: number | null, url: string } | null } | null } } | null } | null } | null };


export const CreateImageBannerDocument = gql`
    mutation createImageBanner($data: BannerInput!, $locale: I18NLocaleCode) {
  createBanner(data: $data, locale: $locale) {
    data {
      ...bannerEntity
    }
  }
}
    ${BannerEntityFragmentDoc}`;
export type CreateImageBannerMutationFn = Apollo.MutationFunction<CreateImageBannerMutation, CreateImageBannerMutationVariables>;

/**
 * __useCreateImageBannerMutation__
 *
 * To run a mutation, you first call `useCreateImageBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImageBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImageBannerMutation, { data, loading, error }] = useCreateImageBannerMutation({
 *   variables: {
 *      data: // value for 'data'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCreateImageBannerMutation(baseOptions?: Apollo.MutationHookOptions<CreateImageBannerMutation, CreateImageBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateImageBannerMutation, CreateImageBannerMutationVariables>(CreateImageBannerDocument, options);
      }
export type CreateImageBannerMutationHookResult = ReturnType<typeof useCreateImageBannerMutation>;
export type CreateImageBannerMutationResult = Apollo.MutationResult<CreateImageBannerMutation>;
export type CreateImageBannerMutationOptions = Apollo.BaseMutationOptions<CreateImageBannerMutation, CreateImageBannerMutationVariables>;