import React, { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useLocalization } from '../../../../localization';
import { CustomModal } from '../../../../components';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';

type Items = {
  value: string | number | null | undefined;
  label: string | null | undefined;
};

interface Props {
  title: string;
  data?: Items[];
  closeModal(): void;
  openModal: boolean;
  handlerDelete?: () => Promise<void>;
}

export const DeleteModal: FC<Props> = ({ openModal, closeModal, title, data, handlerDelete }) => {
  const { translateLang } = useLocalization();
  return (
    <CustomModal title={translateLang(title)} handleClose={closeModal} open={openModal}>
      <>
        {data?.map((item, index) => (
          <Stack flexDirection="row" key={index.toString()}>
            <TranslatedField
              originText={translateLang(item.label as string)}
              fontSize={16}
              noWrap
              color="black"
            />
            <Typography>:</Typography>
            <Typography>&nbsp;</Typography>
            <TranslatedField
              originText={item.value as string}
              fontSize={16}
              noWrap
              color="#7A828A"
            />
          </Stack>
        ))}
      </>
      <Stack flexDirection="row" mt={5}>
        <Button
          onClick={closeModal}
          variant="contained"
          color="secondary"
          style={{
            width: '80%',
            height: '95%',
            marginRight: '2px',
          }}
        >
          <TranslatedField
            originText={translateLang('cancel')}
            fontSize={16}
            isTranslate
            noWrap
            color="white"
          />
        </Button>
        <Button
          onClick={handlerDelete}
          variant="contained"
          color="error"
          style={{
            width: '80%',
            height: '95%',
            marginLeft: '2px',
          }}
        >
          <TranslatedField
            originText={translateLang('del')}
            fontSize={16}
            isTranslate
            noWrap
            color="white"
          />
        </Button>
      </Stack>
    </CustomModal>
  );
};
