/* eslint-disable no-console */
import React, { FC, useCallback, useRef } from 'react';
import { Box } from '@mui/material';

import { OrderItemWarehouseType } from '../../../../graphql/queries/hook/useGetOrdersWarehouse';
import { useGetWarehouseOrdersTable } from './hooks/useGetWarehouseOrdersTable';
import { TableProductActive } from '../../../../components/TableProductActive/TableProductActive';
import { DeliveryNote, DeliveryNoteProps } from './components/PreDelivery/DeliveryNote';
interface Props {
  totalPage: number;
  orderItems?: OrderItemWarehouseType[];
  currentPage?: number;
  changePage(page: number): void;
  loading?: boolean;
}

export const CompletedOrderTabTable: FC<Props> = ({
  orderItems,
  totalPage,
  currentPage,
  changePage,
  loading,
}) => {
  const deliveryNoteRef = useRef<DeliveryNoteProps | null>(null);

  const printDeliveryNote = useCallback((orderId: string) => {
    if (deliveryNoteRef.current) {
      deliveryNoteRef.current.print(orderId);
    }
  }, []);

  const { getHeadersTable, getRowsTableActive } = useGetWarehouseOrdersTable({
    orderItems,
    printDeliveryNote,
  });

  return (
    <>
      <Box boxSizing="border-box" width="100%">
        <TableProductActive
          startPage={currentPage}
          totalPages={totalPage}
          itemsRows={getRowsTableActive()}
          itemsHeader={getHeadersTable()}
          changeCurrentPage={changePage}
          loading={loading}
        />
        <DeliveryNote ref={deliveryNoteRef} />
      </Box>
    </>
  );
};
