import React, { SyntheticEvent, useState } from 'react';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import { SectionItem } from './components/SectionItem';
import { SectionItemTop } from './components/SectionItemTop';
import { useLocalization } from '../../../../localization';
import { useGetSectionsCatalogQuery } from '../../../../graphql/queries/__generated__/getSectionsCatalog';

export const ChangeCatalog = () => {
  const { updateSelectLanguage } = useLocalization();
  const { data: SectionsCatalog } = useGetSectionsCatalogQuery({
    variables: {
      locale: updateSelectLanguage,
      sort: ['id'],
    },
  });
  const [valueCategory, setValueCategory] = useState(0);
  const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
    setValueCategory(newValue);
  };

  return (
    <Box
      bgcolor="white"
      width="100%"
      flex={1}
      borderRadius="10px"
      pl={2}
      pr={2}
      pt={4}
      pb={4}
      boxSizing="border-box"
    >
      <Box width="100%">
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          value={valueCategory}
          onChange={handleChangeTab}
          sx={{
            '& .Mui-selected': {
              color: '#545d75',
              fontWeight: 600,
            },
            '& button': {
              maxWidth: 180,
              minWidth: 30,
            },
          }}
        >
          {SectionsCatalog?.categories?.data?.map(item => (
            <Tab key={item.id} label={item.attributes?.name} />
          ))}
        </Tabs>
      </Box>
      <Stack mt={4} width="100%" flex={1} flexDirection="row">
        {SectionsCatalog?.categories?.data?.length && (
          <>
            <Box flex={1} pr={2}>
              <SectionItem
                parentSectionID={SectionsCatalog?.categories?.data[valueCategory]?.id || ''}
                label={SectionsCatalog?.categories?.data[valueCategory].attributes?.name || ''}
              />
            </Box>
            <Box flex={1} pl={2}>
              <SectionItemTop
                parentSectionID={SectionsCatalog?.categories?.data[valueCategory].id || ''}
              />
            </Box>
          </>
        )}
      </Stack>
    </Box>
  );
};
