/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box, Stack } from '@mui/material';
import { useAuthUser, useValidation } from '../../hooks';
import { WrapperShadow } from './components/WrapperShadow';
import { TabsAuth } from './components/TabsAuth';
import { PasswordInput } from './components/PasswordInput';
import { InputAuth } from './components/InputAuth';
import { PhoneInput } from './components/PhoneInput';
import { useLocalization } from '../../localization';
import { ButtonOrange } from './components/ButtonOrange';
import { StrokeForNavigate } from './components/StrokeForNavigate';
import { EnumTabsAuthState, LoginFields } from './helpers/types';
import { useHelpersNavigate } from './hooks/helpersNavigate';

const initialValues = {
  [LoginFields.Identifier]: '',
  [LoginFields.Password]: '',
};

const LoginPage = () => {
  const { emailValidation, passwordValidation, phoneValidation } = useValidation();
  const { goToRegisterPage, goToForgotPasswordPage } = useHelpersNavigate();
  const { login } = useAuthUser();
  const { translateLang } = useLocalization();
  const [currentAuthState, setCurrentAuthState] = useState<EnumTabsAuthState>(
    EnumTabsAuthState.Email
  );

  const validationSchema = yup.object({
    [LoginFields.Identifier]:
      currentAuthState === EnumTabsAuthState.Email ? emailValidation : phoneValidation,
    [LoginFields.Password]: passwordValidation,
  });

  const { values, errors, touched, handleChange, setErrors, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: values => {
        let identifier = values[LoginFields.Identifier];
        let isPhone = false;
        if (currentAuthState === EnumTabsAuthState.Phone) {
          identifier = `+${values[LoginFields.Identifier]}`;
          isPhone = true;
        }
        login({
          identifier,
          password: values[LoginFields.Password],
          isPhone,
        })
          .then(() => {
            console.log('You authentication success!!!');
          })
          .catch(error => {
            setErrors({
              [LoginFields.Identifier]: error?.message,
              [LoginFields.Password]: error?.message,
            });
          });
      },
    });
  const handlerChangeState = (state: EnumTabsAuthState) => {
    setCurrentAuthState(state);
  };

  useEffect(() => {
    if (currentAuthState === EnumTabsAuthState.Email) {
      setFieldValue(LoginFields.Identifier, '');
    }
  }, [currentAuthState]);

  return (
    <WrapperShadow title={translateLang('signInWith')}>
      <TabsAuth currentState={currentAuthState} changeState={handlerChangeState} />
      <Stack component="form" width="100%" onSubmit={handleSubmit}>
        {currentAuthState === EnumTabsAuthState.Phone && (
          <PhoneInput
            phoneValue={values[LoginFields.Identifier]}
            changePhoneValue={val => {
              setFieldValue(LoginFields.Identifier, val);
            }}
            error={touched[LoginFields.Identifier] && !!errors[LoginFields.Identifier]}
            helperText={touched[LoginFields.Identifier] && errors[LoginFields.Identifier]}
          />
        )}
        {currentAuthState === EnumTabsAuthState.Email && (
          <InputAuth
            title={translateLang('email')}
            type="email"
            name={LoginFields.Identifier}
            value={values[LoginFields.Identifier]}
            onChange={handleChange}
            error={touched[LoginFields.Identifier] && !!errors[LoginFields.Identifier]}
            helperText={touched[LoginFields.Identifier] && errors[LoginFields.Identifier]}
          />
        )}
        <PasswordInput
          name={LoginFields.Password}
          value={values[LoginFields.Password]}
          onChange={handleChange}
          isForgotPassword
          goToForgotPassword={() =>
            goToForgotPasswordPage({
              state: { isPhone: currentAuthState === EnumTabsAuthState.Phone },
            })
          }
          error={touched[LoginFields.Password] && !!errors[LoginFields.Password]}
          helperText={touched[LoginFields.Password] && errors[LoginFields.Password]}
        />
        <ButtonOrange type="submit" titleButton={translateLang('signIn')} />
        <Box width="100%" mt={3}>
          <StrokeForNavigate
            text={translateLang('donAccount')}
            textNavigate={translateLang('signUp')}
            actionNavigate={goToRegisterPage}
          />
        </Box>
      </Stack>
    </WrapperShadow>
  );
};

export default LoginPage;
