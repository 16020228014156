import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

interface Props {
  bgcolor?: string;
}
export const StyledInput = styled(TextField)(({ bgcolor }: Props) => ({
  '.MuiInputBase-input': {
    color: '#7A828A',
    paddingLeft: '16px',
    paddingTop: '14px',
    paddingBottom: '14px',
    backgroundColor: bgcolor,
    borderRadius: '6px',
    width: '100%',
    '&::placeholder': {
      color: '#7A828A',
    },
  },
}));
export const StyledInputWithShadow = styled(TextField)(({ bgcolor }: Props) => ({
  '.MuiInputBase-input': {
    color: '#7A828A',
    paddingLeft: '16px',
    paddingTop: '14px',
    paddingBottom: '14px',
    backgroundColor: bgcolor,
    borderRadius: '6px',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.25) inset',
    '&::placeholder': {
      color: '#7A828A',
    },
  },
}));
