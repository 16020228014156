import React, { FC, useEffect, useRef, useState } from 'react';
import { ButtonOrange } from './ButtonOrange';
import { useLocalization } from '../../../localization';
interface Props {
  handlerButton: () => void;
  loading: boolean;
}
const INTERVAL = 60;
export const ButtonTimer: FC<Props> = ({ handlerButton, loading }) => {
  const { translateLang } = useLocalization();
  const [seconds, setSeconds] = useState(INTERVAL);
  const timerRef = useRef<NodeJS.Timer>();

  const startCountdown = () => {
    if (!timerRef.current) {
      timerRef.current = setInterval(() => {
        setSeconds(prevState => {
          return prevState - 1;
        });
      }, 1000);
    }
  };
  const handlerClickButton = () => {
    setSeconds(INTERVAL);
    handlerButton();
  };

  useEffect(() => {
    if (seconds <= 0) {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = undefined;
      }
    } else if (seconds === INTERVAL) {
      startCountdown();
    }
  }, [seconds]);
  useEffect(() => {
    startCountdown();
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = undefined;
      }
    };
  }, []);
  const getTitleButton = () => {
    if (seconds <= 0) {
      return translateLang('getNewCode');
    }
    return `${translateLang('getNewCode')}: ${seconds} s`;
  };

  return (
    <ButtonOrange
      type="button"
      titleButton={getTitleButton()}
      handlerClickButton={handlerClickButton}
      disableButton={seconds > 0}
      loading={loading}
    />
  );
};
