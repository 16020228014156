import React, { DragEvent, FC } from 'react';
import { Box, Divider } from '@mui/material';
import { InvoiceRow } from './InvoiceRow';
import {
  Enum_Incominginvoice_Status,
  IncomingInvoiceEntity,
} from '../../../../../../__generated__/types';
import { DraggedInvoice, StartDragInvoice } from './LinkingPaymentModal';
import { TranslatedField } from '../../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { theme } from '../../../../../../helpers';

type AvailableInvoicesProps = {
  invoices?: IncomingInvoiceEntity[] | null;
  dragStartHandler: StartDragInvoice;
  draggedInvoice: DraggedInvoice | null;
  dropHandler: (e: DragEvent<HTMLDivElement>) => void;
  acceptDrag: boolean;
  draggable: boolean;
};

export const AvailableInvoices: FC<AvailableInvoicesProps> = ({
  invoices,
  dragStartHandler,
  acceptDrag,
  dropHandler,
  draggedInvoice,
  draggable,
}) => {
  return (
    <Box
      flex={1}
      height="calc(100vh - 380px)"
      overflow="scroll"
      sx={{
        outline: '2px solid transparent',
        transition: 'all 200ms ',
      }}
      onDragEnd={e => {
        e.currentTarget.style.outline = '2px solid transparent';
      }}
      onDrop={e => {
        e.currentTarget.style.outline = '2px solid transparent';
        dropHandler(e);
      }}
      onDragLeave={e => {
        e.currentTarget.style.outline = '2px solid transparent';
      }}
      onDragOver={e => {
        if (draggedInvoice?.direction === 'unlink') {
          e.preventDefault();
          e.currentTarget.style.outline = '2px solid rgb(244, 67, 54)';
        }
      }}
    >
      <Box
        paddingLeft={3}
        py={0.5}
        position="sticky"
        top={0}
        zIndex={2}
        border="1px solid rgba(224, 224, 224, 1)"
        bgcolor={theme.palette.common.lightGrey}
      >
        <Box display="flex" alignItems="center" pl={1} pr={1.5} minHeight={52}>
          <TranslatedField
            isTranslate
            variant="h4"
            fontSize={14}
            originText="documentNumberAndDate"
            pl={1}
            flex={3}
          />
          <Divider orientation="vertical" variant="middle" flexItem />

          <TranslatedField
            isTranslate
            variant="h4"
            fontSize={14}
            originText="supplierDocumentNumberDate"
            pl={1}
            flex={3}
          />
          <Divider orientation="vertical" variant="middle" flexItem />

          <TranslatedField
            isTranslate
            variant="h4"
            fontSize={14}
            originText="invoiceAmount"
            pl={1}
            flex={2}
          />
          <Divider orientation="vertical" variant="middle" flexItem />

          <TranslatedField
            isTranslate
            variant="h4"
            fontSize={14}
            originText="debtAmount"
            pl={1}
            flex={2}
          />
        </Box>
      </Box>
      {invoices?.map((invoice, index) => {
        if (invoice.attributes) {
          return (
            <InvoiceRow
              lastChild={index === invoices?.length - 1}
              dropHandler={dropHandler}
              dragStartHandler={dragStartHandler}
              draggable={draggable}
              acceptDrag={acceptDrag}
              key={invoice.id}
              invoice={invoice}
              disabled={invoice.attributes.status !== Enum_Incominginvoice_Status.Completed}
            />
          );
        }
      })}
    </Box>
  );
};
