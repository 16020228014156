import { Box, CircularProgress, Typography, makeStyles } from '@mui/material';
import React, { FC, UIEvent, useRef, ReactNode, useEffect } from 'react';
import { useLocalization } from '../../../../localization';

interface Props {
  children?: ReactNode[];
  all?: boolean;
  loading: boolean;
  handlerScrollToBottom(): void;
}

export const MobileScrollComponent: FC<Props> = ({
  children,
  loading,
  all,
  handlerScrollToBottom,
}) => {
  const { translateLang } = useLocalization();
  const isBottomScroll = useRef(true);
  const scrollRef = useRef<HTMLDivElement>(null);
  let velocity = 0;

  function handleScroll(e: UIEvent<HTMLElement>) {
    const { scrollHeight, scrollTop, offsetHeight } = e.target as HTMLElement;

    const bottom = offsetHeight + scrollTop > scrollHeight - 20;
    const top =
      offsetHeight + scrollTop > scrollHeight - 100 && offsetHeight + scrollTop < scrollHeight - 55;

    if (velocity >= scrollTop && top && isBottomScroll.current) {
      isBottomScroll.current = false;
    }

    if (bottom && !isBottomScroll.current && velocity < scrollTop) {
      isBottomScroll.current = true;
      handlerScrollToBottom();
    }

    velocity = scrollTop;
  }
  useEffect(() => {
    isBottomScroll.current = false;
  }, [children?.length]);

  return (
    <Box height="100%" width="100%" position="relative" pb="50px">
      <Box ref={scrollRef} onScroll={handleScroll} overflow="scroll" height="100%">
        {children}
        {all && <Typography textAlign="center">{translateLang('thatsAll')}</Typography>}
      </Box>

      {loading && (
        <Box position="absolute" bottom="0" width="100%" display="flex" justifyContent="center">
          <CircularProgress size={40} color="secondary" />
        </Box>
      )}
    </Box>
  );
};
