import React, { FC, ReactNode } from 'react';
import { Box, BoxProps, Button, Stack, Typography } from '@mui/material';
import { useLocalization } from '../../../localization';
import { Enum_Language_Type } from '../../../__generated__/types';
import { useGetIsMobileQuery } from '../../../graphql/cacheQuery/__generated__/getIsMobile';

interface Props extends BoxProps {
  title: string;
  subTitle?: string;
  children?: ReactNode | ReactNode[] | string;
}
export const WrapperShadow: FC<Props> = ({ children, title, subTitle, ...props }) => {
  const { data } = useGetIsMobileQuery();
  const { setCurrentLanguage, selectLanguages } = useLocalization();
  const getColor = (lang: Enum_Language_Type): string => {
    if (lang === selectLanguages) {
      return '#F44336';
    }
    return '#000';
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height={data?.isMobile ? '100vh' : 'auto'}
      bgcolor="#ffffff"
      borderRadius="15px"
      boxSizing="border-box"
      boxShadow="0px 7px 23px 0px rgba(0,0,0,0.06)"
      {...props}
    >
      <Box px="40px" pt={3} flex={1} boxSizing="border-box">
        <Typography fontSize="24px" fontWeight="700" lineHeight="33px" textAlign="center">
          {title}
        </Typography>
        {subTitle && (
          <Typography mt={2} textAlign="center">
            {subTitle}
          </Typography>
        )}
        {children}
      </Box>
      <Stack
        display="flex"
        flexDirection="row"
        justifyContent="center"
        width="100%"
        height="100px"
        py="30px"
        boxSizing="border-box"
      >
        <Button
          variant="text"
          sx={{ textTransform: 'none', color: getColor(Enum_Language_Type.En) }}
          onClick={() => setCurrentLanguage(Enum_Language_Type.En)}
        >
          English
        </Button>
        <Button
          variant="text"
          sx={{ textTransform: 'none', color: getColor(Enum_Language_Type.De) }}
          onClick={() => setCurrentLanguage(Enum_Language_Type.De)}
        >
          Deutsch
        </Button>
        <Button
          variant="text"
          sx={{ textTransform: 'none', color: getColor(Enum_Language_Type.Uk) }}
          onClick={() => setCurrentLanguage(Enum_Language_Type.Uk)}
        >
          УкраЇнська
        </Button>
      </Stack>
      {data?.isMobile && <Box width="100%" height="40px" />}
    </Box>
  );
};
