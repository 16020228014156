import React, { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { WrapperShadow } from './components/WrapperShadow';
import { useFormik } from 'formik';
import { LoginFields, OptionLoginType } from './helpers/types';
import { useLocalization } from '../../localization';
import * as yup from 'yup';
import { useAuthUser, useValidation } from '../../hooks';
import { PasswordInput } from './components/PasswordInput';
import { ButtonOrange } from './components/ButtonOrange';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useResetPasswordByEmailMutation } from '../../graphql/mutations/__generated__/resetPasswordByEmail';
import { handlerError } from '../../helpers/functions';
import { useHelpersNavigate } from './hooks/helpersNavigate';
import { useResetPasswordByPhoneMutation } from '../../graphql/mutations/__generated__/resetPasswordByPhone';
import { EnterCodeInput } from './components/EnterCodeInput';

export const ResetPasswordPage = () => {
  const { translateLang } = useLocalization();
  const { login } = useAuthUser();
  const { goToLoginPage } = useHelpersNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const stLoc = useLocation().state as OptionLoginType;
  const { confirmPasswordValidation, passwordValidation } = useValidation();
  const [runResetPasswordByEmail, { loading: loadEmail }] = useResetPasswordByEmailMutation();
  const [runResetPasswordByPhone, { loading: loadPhone }] = useResetPasswordByPhoneMutation();
  const [currentCode, setCurrentCode] = useState('');
  const [errorCode, setErrorCode] = useState(false);

  const validationSchema = yup.object({
    [LoginFields.Password]: passwordValidation,
    [LoginFields.ConfirmPassword]: confirmPasswordValidation,
  });

  const { values, errors, touched, handleChange, setErrors, handleSubmit } = useFormik({
    initialValues: {
      [LoginFields.Password]: '',
      [LoginFields.ConfirmPassword]: '',
    },
    validationSchema,
    onSubmit: async values => {
      let dataResp;
      const code = searchParams.get('code');
      try {
        if (stLoc?.isPhone && stLoc?.phone) {
          const resp = await runResetPasswordByPhone({
            variables: {
              input: {
                code: currentCode.replace(/[^0-9]/g, ''),
                password: values[LoginFields.Password],
                phoneNumber: stLoc.phone,
              },
            },
          });
          dataResp = resp.data?.resetPasswordByPhoneNumber;
        } //
        else if (code) {
          searchParams.delete('code');
          setSearchParams({});
          const resp = await runResetPasswordByEmail({
            variables: {
              code,
              password: values[LoginFields.Password],
              passwordConfirmation: values[LoginFields.ConfirmPassword],
            },
          });
          dataResp = resp.data?.resetPassword;
        }

        if (dataResp?.user) {
          let identifier = '';
          if (stLoc?.isPhone && stLoc?.phone) {
            identifier = stLoc.phone;
          } else if (dataResp.user?.email) {
            identifier = dataResp.user.email;
          }
          if (identifier) {
            await login({
              identifier: identifier,
              password: values[LoginFields.Password],
              isPhone: stLoc?.isPhone ? stLoc.isPhone : false,
            });
          } else {
            goToLoginPage({ replace: true });
          }
        }
      } catch (err: any) {
        setErrors({
          [LoginFields.Password]: err?.message,
          [LoginFields.ConfirmPassword]: err?.message,
        });
        setErrorCode(true);
        handlerError(err);
      }
    },
  });

  return (
    <WrapperShadow
      title={translateLang('resetPassword')}
      subTitle={translateLang('subTitleResetPassword')}
    >
      <Stack mt={4} component="form" width="100%" onSubmit={handleSubmit}>
        {stLoc?.isPhone && (
          <EnterCodeInput
            code={currentCode}
            error={errorCode}
            setCurrentCode={setCurrentCode}
            setErrorCode={setErrorCode}
          />
        )}
        <PasswordInput
          name={LoginFields.Password}
          value={values[LoginFields.Password]}
          onChange={handleChange}
          error={touched[LoginFields.Password] && !!errors[LoginFields.Password]}
          helperText={touched[LoginFields.Password] && errors[LoginFields.Password]}
        />
        <Box mt={2}>
          <PasswordInput
            name={LoginFields.ConfirmPassword}
            value={values[LoginFields.ConfirmPassword]}
            onChange={handleChange}
            confirm
            error={touched[LoginFields.ConfirmPassword] && !!errors[LoginFields.ConfirmPassword]}
            helperText={touched[LoginFields.ConfirmPassword] && errors[LoginFields.ConfirmPassword]}
          />
        </Box>
        <ButtonOrange
          type="submit"
          titleButton={translateLang('updatePassword')}
          loading={loadEmail || loadPhone}
        />
      </Stack>
    </WrapperShadow>
  );
};
