import { useEffect, useState } from 'react';
import { Pagination, PaginationArg, ReviewFiltersInput } from '../../../__generated__/types';
import { GetReviewsQueryVariables, useGetReviewsQuery } from '../__generated__/getReviews';
import { PAGE_COUNT } from '../../../helpers/constants';

export interface ReviewsType {
  id?: string;
  date?: string;
  spareName?: string;
  reviewsUserName?: string;
  rating?: number;
  repliesUserName?: string;
  reviewText?: string;
  replyText?: string;
  statusPublication?: boolean;
}

export type PaginationType = Omit<Pagination, '__typename'>;

interface ReturnHook {
  getReviewedItem(idItem: string): void;
  selectItem?: ReviewsType;
  reviews: Array<ReviewsType>;
  pagination?: PaginationType;
  isLoading?: boolean;
  page: number;
  total: number;
  totalPage: number;
  pageSize: number;
  changePage(page: number): void;
}

interface PropsHook {
  pagination?: PaginationArg;
  filters?: ReviewFiltersInput;
}
export function getRefetchReviews(): GetReviewsQueryVariables {
  return {
    pagination: { limit: -1 },
    sort: ['id:desc'],
  };
}
export const useGetReviews = ({ filters }: PropsHook): ReturnHook => {
  const [page, setPage] = useState(1);
  const [selectItem, setSelectItem] = useState<ReviewsType | undefined>();
  const [reviews, setReviews] = useState<Array<ReviewsType>>([]);

  const formatDate = (item: string | number | Date) => {
    const date = new Date(item);
    return date
      .toLocaleString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })
      .split('/')
      .join('.');
  };

  const { data, loading: isLoading } = useGetReviewsQuery({
    variables: {
      pagination: {
        page: page,
        pageSize: PAGE_COUNT,
      },
      filters,

      sort: ['id:desc'],
    },
  });
  const getNormalizeData = (): Array<ReviewsType> => {
    if (data?.reviews?.data.length) {
      return data.reviews?.data.map(rev => {
        const spareData = rev.attributes?.car_spare?.data?.attributes?.car_spare_locales?.data
          .map(item => {
            return `${item.attributes?.title}`;
          })
          .join(', ');

        return {
          id: rev.id || '',
          date: formatDate(rev.attributes?.review?.date) || '',
          rating: rev.attributes?.car_spare?.data?.attributes?.rating || undefined,
          reviewText: rev.attributes?.review?.text || '',
          replyText: rev.attributes?.reply?.text || '',

          reviewsUserName:
            `${rev.attributes?.review?.user?.data?.attributes?.customer_contact_info?.data?.attributes?.firstName} ${rev.attributes?.review?.user?.data?.attributes?.customer_contact_info?.data?.attributes?.lastName}` ||
            '',
          repliesUserName:
            `${rev.attributes?.reply?.user?.data?.attributes?.customer_contact_info?.data?.attributes?.firstName} ${rev.attributes?.reply?.user?.data?.attributes?.customer_contact_info?.data?.attributes?.lastName}` ||
            '',
          spareName: spareData,
          statusPublication: rev.attributes?.publication || undefined,
        };
      });
    }
    return [];
  };
  const changePage = (_page: number): void => {
    setPage(_page + 1);
  };

  const getReviewedItem = (idItem: string) => {
    const _reviews = getNormalizeData();
    const data = _reviews.find(item => item.id === idItem);
    setSelectItem(data);
  };
  useEffect(() => {
    const _reviews = getNormalizeData();
    setReviews(_reviews);
  }, [data]);

  return {
    getReviewedItem,
    selectItem,
    reviews,
    page,
    total: data?.reviews?.meta.pagination.total || 0,
    pageSize: data?.reviews?.meta.pagination.total || 0,
    totalPage: data?.reviews?.meta.pagination.total
      ? Math.ceil(data?.reviews?.meta.pagination.total / PAGE_COUNT)
      : 0,
    changePage,
    isLoading,
  };
};
