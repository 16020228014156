import React, { useState } from 'react';
import { Button, Stack } from '@mui/material';
import { addDays, endOfToday, startOfDay, subDays } from 'date-fns';

import {
  ItemSelectFilterType,
  SelectFilter,
} from '../../../../../OrdersList/components/SelectFilter';
import { useLocalization } from '../../../../../../localization';
import { useDefaultRangeCalendar } from '../../../../../../hooks';
import { TranslatedField } from '../../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { RangeCalendar } from '../../../../../../legos/rangeCalendar/RangeCalendar';
import { useFiltersSearchParamsForOrders } from '../../../../../../hooks/useFiltersSearchParamsForOrders';
import { Enum_Customeraccounttransaction_Documenttype } from '../../../../../../__generated__/types';

export const ClientTransactionToolbar = () => {
  const { translateLang } = useLocalization();

  const STATUSES_FILTERS: ItemSelectFilterType[] = [
    {
      label: Enum_Customeraccounttransaction_Documenttype.PaymentIncomingCash,
      value: translateLang('fundPACash'),
    },
    {
      label: Enum_Customeraccounttransaction_Documenttype.PaymentIncomingNonCash,
      value: translateLang('fundPABankTransfer'),
    },
    {
      label: Enum_Customeraccounttransaction_Documenttype.PaymentOutgoingCash,
      value: translateLang('withdrawCash'),
    },
    {
      label: Enum_Customeraccounttransaction_Documenttype.PaymentOutgoingNonCash,
      value: translateLang('withdrawBankTransfer'),
    },
    { label: Enum_Customeraccounttransaction_Documenttype.Order, value: translateLang('order') },
    { label: Enum_Customeraccounttransaction_Documenttype.Return, value: translateLang('return') },
  ];
  const { reportRangeDates } = useDefaultRangeCalendar();

  const { changeStatus, changeDate, selStatus, selDateTo, selDateFrom, resetFilters } =
    useFiltersSearchParamsForOrders();
  const [selectDates, setSelectedDates] = useState(getDatesFilters);

  const resetFiltersAll = () => {
    resetFilters();
    setSelectedDates([startOfDay(subDays(new Date(), 30)), new Date()]);
  };

  function getDatesFilters(): Date[] {
    if (selDateFrom && selDateTo) {
      return [new Date(selDateFrom), new Date(selDateTo)];
    }
    return [startOfDay(subDays(new Date(), 30)), endOfToday()];
  }

  const handlerChangesDates = (val: Date[]) => {
    setSelectedDates(val);
    changeDate(
      val[0]?.toISOString(),
      val[1] ? addDays(val[1], 1)?.toISOString() : addDays(val[0], 1).toISOString()
    );
  };

  return (
    <Stack width="100%" justifyContent="end" alignItems="end" mb={2.5}>
      <Stack direction="row" gap={2} alignItems="center">
        <SelectFilter
          labelFilter={translateLang('status')}
          items={STATUSES_FILTERS}
          selectItem={selStatus}
          changeUrlParams={changeStatus}
        />
        <RangeCalendar
          selectedDates={selectDates}
          defaultRangeDates={reportRangeDates}
          setSelectedDates={handlerChangesDates}
        />
        <Button
          color="primary"
          variant="outlined"
          onClick={resetFiltersAll}
          style={{
            width: '100%',
            minWidth: 'auto',
            height: 40,
            textTransform: 'none',
          }}
        >
          <TranslatedField
            noWrap
            isTranslate
            fontSize={16}
            color="black"
            originText="resetFilters"
            overflow="initial"
          />
        </Button>
      </Stack>
    </Stack>
  );
};
