import React, { FC } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { theme } from '../helpers';
import { InfoOutlined } from '@mui/icons-material';
import { useLocalization } from '../localization';

type OrderItemQuantityProps = {
  confirmedQuantity: number;
  quantity: number;
  removedQuantity?: number;
};

export const OrderItemQuantity: FC<OrderItemQuantityProps> = ({
  confirmedQuantity,
  quantity,
  removedQuantity,
}) => {
  const { translateLang } = useLocalization();

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography
        fontSize={16}
        component="span"
        color={theme.palette.common.appleGreen}
        fontWeight={600}
      >
        {quantity - (removedQuantity ?? 0)}
      </Typography>
      <Tooltip
        title={
          <>
            <Typography fontSize={14}>
              · {translateLang('orderedQuantity')}: {quantity}
            </Typography>
            <Typography fontSize={14}>
              · {translateLang('confirmedQuantity')}: {confirmedQuantity}
            </Typography>
            <Typography fontSize={14}>
              · {translateLang('quantityRemoved')}: {removedQuantity}
            </Typography>
          </>
        }
        arrow
      >
        <InfoOutlined fontSize="small" sx={{ color: '#a5a5a5' }} />
      </Tooltip>
    </Box>
  );
};
