import React, { FC } from 'react';

import { StandardTextFieldProps, Stack } from '@mui/material';
import { StyledInput } from './styled';
interface CustomTextField extends Omit<StandardTextFieldProps, 'variant'> {
  placeholder?: string | undefined;
  label?: string;
  labelColor?: string;
  bgcolor?: string;
  width?: string;
  variant?: 'outlined' | 'standard';
}

export const Input: FC<CustomTextField> = ({
  placeholder,
  label,
  bgcolor = '#F1F3F8',
  width = '100%',
  variant = 'standard',
  ...props
}) => {
  return (
    <Stack spacing={1} sx={{ marginTop: 0 }} flexDirection="row" width="100%">
      <StyledInput
        bgcolor={bgcolor}
        variant={variant}
        InputProps={{
          disableUnderline: true,
        }}
        InputLabelProps={{
          shrink: true,
          style: {
            color: '#2A3453',
            fontSize: '14px',
            fontWeight: '500',
            height: '17px',
            lineHeight: '17px',
          },
        }}
        sx={{
          width: width,
          '& .MuiInputBase-input': {
            paddingRight: props.type === 'number' ? 2 : 0,
          },
        }}
        fullWidth={!!width}
        label={label}
        placeholder={placeholder}
        {...props}
      />
    </Stack>
  );
};
