import * as yup from 'yup';
import { useLocalization } from '../../../../../localization';
import { NewBrandField, ProductField } from '../types';

export const useValidation = () => {
  const { translateLang } = useLocalization();

  const stringRequiredValidation = yup.string().required(translateLang('emptyInputError'));

  const brandNameValidation = yup
    .string()
    .min(2, translateLang('min2characters'))
    .required(translateLang('emptyInputError'));

  const productCodeValidation = yup
    .string()
    .min(3, translateLang('min3characters'))
    .required(translateLang('emptyInputError'));

  const brandCodeValidation = yup.object().shape({
    brandId: yup.number().required(translateLang('emptyInputError')),
  });
  const barcodesValidation = yup
    .array()
    .of(yup.string().required(translateLang('emptyInputError')));

  const categoryValidation = yup
    .mixed()
    .test('category-validation', translateLang('emptyInputError'), value => {
      return typeof value === 'object' && value !== null ? true : false;
    });

  const subCategoryValidation = yup
    .mixed()
    .test('category-validation', translateLang('emptyInputError'), value => {
      return typeof value === 'object' && value !== null ? true : false;
    });

  const criteriaValidation = yup.array().of(
    yup.object().shape({
      titleEN: yup.string().required(translateLang('emptyInputError')),
      titleDE: yup.string().required(translateLang('emptyInputError')),
      valueEN: yup.string().required(translateLang('emptyInputError')),
      valueDE: yup.string().required(translateLang('emptyInputError')),
    })
  );

  const validationSchema = yup.object({
    [ProductField.ProductCode]: productCodeValidation,
    [ProductField.BrandCode]: brandCodeValidation,
    [ProductField.Barcodes]: barcodesValidation,
    [ProductField.TitleEN]: stringRequiredValidation,
    [ProductField.TitleDE]: stringRequiredValidation,
    // [ProductField.Category]: categoryValidation,
    // [ProductField.SubCategory]: subCategoryValidation,
    [ProductField.Criteria]: criteriaValidation,
  });

  const validationSchemaNewBrand = yup.object({
    [NewBrandField.BrandNameEN]: brandNameValidation,
    [NewBrandField.BrandNameDE]: brandNameValidation,
  });

  return {
    stringRequiredValidation,
    productCodeValidation,
    brandCodeValidation,
    barcodesValidation,
    categoryValidation,
    subCategoryValidation,
    criteriaValidation,
    validationSchema,
    validationSchemaNewBrand,
  };
};
