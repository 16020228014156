import React, { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { TextField } from '@mui/material';
import { WrapperField } from './WrapperField';
import { useLocalization } from '../../../localization';

interface Props {
  code: string;
  error: boolean;
  setCurrentCode: Dispatch<SetStateAction<string>>;
  setErrorCode: Dispatch<SetStateAction<boolean>>;
}

export const EnterCodeInput: FC<Props> = ({ code, error, setCurrentCode, setErrorCode }) => {
  const { translateLang } = useLocalization();
  const handlerChangeCode = (evt: ChangeEvent<HTMLInputElement>) => {
    let text = evt.target.value.replace(/[^0-9]/g, '');
    if (!text) {
      setCurrentCode('');
      return '';
    }
    if (text.length > 6) {
      text = text.slice(0, 6);
    }
    const regex = /^(\d{0,2})(\d{0,2})(\d{0,2})$/g;
    const code = text.replace(regex, (_str, $1, $2, $3) => {
      return [$1, $2, $3].filter(group => !!group).join('-');
    });

    if (error) {
      setErrorCode(false);
    }
    setCurrentCode(code);
  };
  return (
    <WrapperField title={translateLang('enterCode')}>
      <TextField
        color="success"
        autoComplete="off"
        fullWidth
        variant="outlined"
        InputProps={{ sx: { borderRadius: '10px' } }}
        value={code}
        onChange={handlerChangeCode}
        placeholder="XX-XX-XX"
        error={error}
        helperText={error ? translateLang('wrongCode') : undefined}
      />
    </WrapperField>
  );
};
