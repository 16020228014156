import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { AllOrdersToolbar } from './AllOrdersToolbar';
import { AllOrdersTable } from './AllOrdersTable';
import { Enum_Order_Status, OrderFiltersInput } from '../../../../../__generated__/types';
import { useGetAllOrdersDelivery } from '../../../../../graphql/queries/hook/useGetAllOrdersDelivery';
import { useFiltersSearchParamsForOrders } from '../../../../../hooks/useFiltersSearchParamsForOrders';
import { addDays, format } from 'date-fns';
import { getOrdersSearchFilter } from '../../../../../helpers/functions';

export const AllOrdersTab = () => {
  const { selStatus, selDateTo, selDateFrom, searchText } = useFiltersSearchParamsForOrders({
    dateFrom: format(addDays(new Date(), -30), 'yyyy-MM-dd'),
    dateTo: format(new Date(), 'yyyy-MM-dd'),
  });
  const { allOrdersDelivery, page, totalPage, changePage, loading } = useGetAllOrdersDelivery({
    filters: getFiltersOrders(),
  });

  const [resetFilterOrders, setResetFilterOrders] = useState(new Date().getTime().toString());

  const handlerReset = () => {
    setResetFilterOrders(new Date().getTime().toString());
  };

  function getFiltersOrders(): OrderFiltersInput | undefined {
    const filters: OrderFiltersInput | undefined = { and: [] };

    if (selStatus) {
      filters.and?.push({
        status: {
          eq: selStatus,
        },
      });
    } else {
      filters.and?.push({
        or: [
          {
            status: {
              eq: Enum_Order_Status.Delivery,
            },
          },
          {
            status: {
              eq: Enum_Order_Status.PickedUp,
            },
          },
        ],
      });
    }

    if (selDateTo && selDateFrom) {
      filters.and?.push({
        date: {
          between: [selDateFrom, selDateTo],
        },
      });
    }

    if (searchText) {
      const searchBlock = getOrdersSearchFilter(searchText);

      if (searchBlock) {
        filters.and?.push(searchBlock);
      }
    }

    return Object.keys(filters).length > 0 ? filters : undefined;
  }

  return (
    <Box width="100%">
      <AllOrdersToolbar key={resetFilterOrders} setResetFilterOrders={handlerReset} />
      <AllOrdersTable
        allOrdersDelivery={allOrdersDelivery}
        totalPage={totalPage}
        changePage={changePage}
        currentPage={page}
        loading={loading}
      />
    </Box>
  );
};
