import React, { useState } from 'react';
import { Box } from '@mui/system';
import { endOfToday, startOfDay, subDays } from 'date-fns';

import { PAGE_COUNT } from '../../../helpers/constants';
import { NewSletterTable } from './NewSletterSubscriptionsTab/NewSletterTable';
import { NewsletterSubscriptionFiltersInput } from '../../../__generated__/types';
import { ToolBarNewSletter } from './NewSletterSubscriptionsTab/ToolBarNewSletter';
import { useGetNewsletterSubscriptions } from '../../../graphql/queries/hook/useGetNewsletterSubscriptions';

export const NewSletterSubscriptions = () => {
  const [page, setPage] = useState(0);
  const [filterSubscription, setFilterSubscription] = useState<NewsletterSubscriptionFiltersInput>({
    createdAt: {
      between: [startOfDay(subDays(new Date(), 30)).toISOString(), endOfToday().toISOString()],
    },
  });
  const [resetFilterOrders, setResetFilterOrders] = useState(new Date().getTime().toString());

  const { newsletterSubscriptions, pagination } = useGetNewsletterSubscriptions({
    pagination: {
      page: page + 1,
      pageSize: PAGE_COUNT,
    },
    filters: filterSubscription,
  });

  const handlerReset = () => {
    setFilterSubscription({
      createdAt: {
        between: [startOfDay(subDays(new Date(), 30)).toISOString(), endOfToday().toISOString()],
      },
    });
    setResetFilterOrders(new Date().getTime().toString());
  };

  return (
    <Box width="100%">
      <ToolBarNewSletter
        key={resetFilterOrders}
        result={pagination?.total || ''}
        setFilterSubscription={setFilterSubscription}
        setResetFilterOrders={handlerReset}
      />
      <NewSletterTable
        subscription={newsletterSubscriptions}
        pagination={pagination}
        setPage={setPage}
        pageCount={PAGE_COUNT}
      />
    </Box>
  );
};
