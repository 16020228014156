import React from 'react';

import { Box } from '@mui/material';
import { useGetAllOrdersDelivery } from '../../../../graphql/queries/hook/useGetAllOrdersDelivery';
import { AllOrdersTable } from '../AllOrders/components/AllOrdersTable';
import { endOfDay, startOfDay } from 'date-fns';
import { Enum_Order_Status } from '../../../../__generated__/types';

export const OrdersDeliveryTodayTab = () => {
  const { allOrdersDelivery, page, totalPage, changePage, loading } = useGetAllOrdersDelivery({
    filters: {
      deliveryDate: {
        between: [startOfDay(new Date()).toISOString(), endOfDay(new Date()).toISOString()],
      },
      or: [
        {
          status: {
            containsi: Enum_Order_Status.PickedUp,
          },
        },
        {
          status: {
            containsi: Enum_Order_Status.Delivery,
          },
        },
      ],
    },
  });

  return (
    <Box width="100%">
      <AllOrdersTable
        allOrdersDelivery={allOrdersDelivery}
        totalPage={totalPage}
        changePage={changePage}
        currentPage={page}
        loading={loading}
      />
    </Box>
  );
};
