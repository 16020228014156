import React, { FC, useState } from 'react';
import { Box, IconButton, InputAdornment, OutlinedInput, OutlinedInputProps } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { WrapperField } from './WrapperField';
import { useLocalization } from '../../../localization';
import { CustomHelperText } from './CustomHelperText';
import { StrokeForNavigate } from './StrokeForNavigate';

interface Props extends OutlinedInputProps {
  helperText?: React.ReactNode;
  isForgotPassword?: boolean;
  confirm?: boolean;
  goToForgotPassword?: () => void;
}
export const PasswordInput: FC<Props> = ({
  helperText,
  isForgotPassword,
  confirm,
  goToForgotPassword,
  ...props
}) => {
  const { translateLang } = useLocalization();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const getInfoText = () => {
    const val = props.value as string;
    if (val.length < 8) {
      return translateLang('passwordRecommend');
    }
    return '';
  };

  return (
    <WrapperField title={translateLang(confirm ? 'confirmPassword' : 'password')}>
      <OutlinedInput
        fullWidth
        type={showPassword ? 'text' : 'password'}
        sx={{ borderRadius: '10px' }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        {...props}
      />
      <CustomHelperText helperText={helperText} error={props.error} infoText={getInfoText()} />
      {isForgotPassword && (
        <Box width="100%" pt={3} pr={1} boxSizing="border-box">
          <StrokeForNavigate
            text=""
            textNavigate={translateLang('forgotPassword')}
            textAlign="end"
            actionNavigate={goToForgotPassword}
          />
        </Box>
      )}
    </WrapperField>
  );
};
