import React, { FC, ReactNode } from 'react';
import { StandardTextFieldProps, TextField } from '@mui/material';
import { WrapperField } from './WrapperField';
import { CustomHelperText } from './CustomHelperText';

interface Props extends Omit<StandardTextFieldProps, 'helperText'> {
  title: string;
  helperText?: ReactNode;
}
export const InputAuth: FC<Props> = ({ title, helperText, ...props }) => {
  return (
    <WrapperField title={title}>
      <TextField
        color="success"
        fullWidth
        variant="outlined"
        InputProps={{ sx: { borderRadius: '10px' } }}
        {...props}
      />
      <CustomHelperText helperText={helperText} error={props.error} />
    </WrapperField>
  );
};
