import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  Zoom,
} from '@mui/material';
import { useBarcode } from 'next-barcode';
import { useLocalization } from '../../../../../localization';

import { ReturnInformComponent } from './ReturnInformComponent';

import { Enum_Method_To_Return, Enum_Receive_For } from '../../../../../__generated__/types';
import { ReturnedType } from '../../../../../graphql/queries/hook/useGetOrderItemsReturnedFromCustomers';
import { TranslatedField } from '../../../../../components/Layout/components/TranslatedField/TranslatedField';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleClickButton(
    methodToReturn: Enum_Method_To_Return,
    receiveFor: Enum_Receive_For,
    customerId?: string,
    providerId?: string
  ): void;
  barCode: string;
  returnedItem?: ReturnedType | null;
  loading: boolean;
}
export const SelectReturnType: FC<Props> = ({
  isOpen,
  barCode,
  returnedItem,
  handleClickButton,
  loading,
}) => {
  const { translateLang } = useLocalization();
  const [methodToReturn, setMethodToReturn] = useState(Enum_Method_To_Return.RemoveFromOrder);
  const [receiveFor, setReceiveFor] = useState(Enum_Receive_For.Stock);

  const { inputRef } = useBarcode({
    value: barCode || 'no number',
    options: {
      background: 'transparent',
      textPosition: 'top',
      textMargin: 14,
      height: 70,
      fontSize: 24,
    },
  });

  return (
    <Zoom
      in={isOpen}
      mountOnEnter
      unmountOnExit
      style={{ transitionDelay: isOpen ? '500ms' : '0ms' }}
    >
      <Stack height="100%" alignItems="center" justifyContent="space-between">
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '30px',
            lineHeight: '38px',
          }}
        >
          {translateLang('returnProduct')}
        </Typography>
        <Box display="flex" width="100%" p={1}>
          <Box
            width="360px"
            borderRight="1px solid #8D93A6"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            pr={2}
          >
            <Box width="100%" height="100%" display="flex">
              <svg ref={inputRef} />
            </Box>
          </Box>
          <Box flex={1} display="flex" flexDirection="column" alignItems="flex-start" pl={6}>
            {returnedItem && <ReturnInformComponent returnedItem={returnedItem} />}
            <Typography
              sx={{
                paddingTop: 2,
                fontWeight: 400,
                fontSize: '24px',
                lineHeight: '38px',
                color: '#5269A3',
              }}
            >
              {translateLang('returnMethod')}
            </Typography>
            <RadioGroup
              value={methodToReturn}
              onChange={event =>
                setMethodToReturn(
                  event.target.value === Enum_Method_To_Return.AddToCustomerReturn
                    ? Enum_Method_To_Return.AddToCustomerReturn
                    : Enum_Method_To_Return.RemoveFromOrder
                )
              }
            >
              <FormControlLabel
                value={Enum_Method_To_Return.RemoveFromOrder}
                control={<Radio />}
                label={<Typography fontSize="18px">{translateLang('removeMethod')}</Typography>}
              />

              <FormControlLabel
                value={Enum_Method_To_Return.AddToCustomerReturn}
                control={<Radio />}
                label={
                  <Typography fontSize="18px">{translateLang('customerReturnMethod')}</Typography>
                }
              />
            </RadioGroup>

            <Typography
              sx={{
                paddingTop: 2,
                fontWeight: 400,
                fontSize: '24px',
                lineHeight: '38px',
                color: '#5269A3',
              }}
            >
              {translateLang('forwardMethod')}
            </Typography>
            <RadioGroup
              value={receiveFor}
              onChange={event =>
                setReceiveFor(
                  event.target.value === Enum_Receive_For.Stock
                    ? Enum_Receive_For.Stock
                    : Enum_Receive_For.Supplier
                )
              }
            >
              <FormControlLabel
                value={Enum_Receive_For.Stock}
                control={<Radio />}
                label={<Typography fontSize="18px">{translateLang('forwardStock')}</Typography>}
              />

              <FormControlLabel
                value={Enum_Receive_For.Supplier}
                control={<Radio />}
                label={<Typography fontSize="18px">{translateLang('forwardSupplier')}</Typography>}
              />
            </RadioGroup>
          </Box>
        </Box>
        <Button
          onClick={() =>
            handleClickButton(
              methodToReturn,
              receiveFor,
              returnedItem?.customerId,
              returnedItem?.providerId
            )
          }
          disabled={loading}
          variant="contained"
          color="secondary"
          style={{
            width: '40%',
            marginRight: '2px',
            borderRadius: '10px',
          }}
        >
          <TranslatedField
            originText={translateLang('nextBtn')}
            fontSize={16}
            isTranslate
            noWrap
            color="white"
            textTransform="none"
          />
        </Button>
      </Stack>
    </Zoom>
  );
};
