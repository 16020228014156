import { useState } from 'react';
import {
  GetStocksQueryVariables,
  useGetStocksQuery,
} from '../../../../../../../../../graphql/queries/__generated__/getStocks';
import { useLocalization } from '../../../../../../../../../localization';
import { PAGE_COUNT } from '../../../../../../../../../helpers/constants';
import { IncomingInvoiceEntity } from '../../../../../../../../../__generated__/types';
import { PaginationType } from '../../../../../../../../../graphql/queries/hook/useGetOrders';

export type CellAddressType = {
  id: string;
  address: string;
};

export interface ItemStockType {
  supplierPrice: number;
  productName: string;
  brandName: string;
  productCode: string;
  productStockId: string;
  balanceQuantity: number;
  incomingQuantity: number;
  reservedQuantity: number;
  checked: boolean;
  createdAt: string;
  incomingInvoice?: IncomingInvoiceEntity | null;
}
interface ReturnHook {
  itemsStock: Array<ItemStockType>;
  loading: boolean;
  page: number;
  totalPage: number;
  pageSize: number;
  changePage(page: number): void;
}

interface PropsHook {
  filters?: GetStocksQueryVariables['filters'];
  pagination?: PaginationType;
}
export const useGetStocks = ({ filters }: PropsHook): ReturnHook => {
  const { updateSelectLanguage } = useLocalization();
  const [page, setPage] = useState(1);

  const { data, loading } = useGetStocksQuery({
    variables: {
      sort: ['supplierPrice:desc'],
      pagination: {
        page: page,
        pageSize: PAGE_COUNT,
      },
      filtersLocale: { locale: { eqi: updateSelectLanguage } },
      filters,
    },
  });

  const changePage = (_page: number): void => {
    setPage(_page + 1);
  };
  const itemsStock: Array<ItemStockType> = [];
  if (data?.stocks?.data.length) {
    data.stocks.data.map(item => {
      itemsStock.push({
        productStockId: item.id || '',
        productCode: item.attributes?.car_spare?.data?.attributes?.tecdoc_articleNumber || '',
        balanceQuantity: item?.attributes?.balanceQuantity || 0,
        reservedQuantity: item?.attributes?.reservedQuantity || 0,
        incomingQuantity: item?.attributes?.incomingQuantity || 0,
        supplierPrice: item.attributes?.supplierPrice || 0,
        productName:
          item.attributes?.car_spare?.data?.attributes?.car_spare_locales?.data[0].attributes
            ?.title || '',
        brandName:
          item.attributes?.car_spare?.data?.attributes?.car_spare_locales?.data[0].attributes
            ?.brandName || '',
        checked: false,
        createdAt: item.attributes?.createdAt,
        incomingInvoice: item?.attributes?.incoming_invoice?.data,
      });
    });
  }

  return {
    itemsStock,
    loading,
    page,
    pageSize: data?.stocks?.meta.pagination.total || 0,
    totalPage: data?.stocks?.meta.pagination.total
      ? Math.ceil(data?.stocks?.meta.pagination.total / PAGE_COUNT)
      : 0,
    changePage,
  };
};
