import React, { FC, ReactNode } from 'react';
import { Stack, StackProps } from '@mui/system';

interface WrapperToolBarProps extends StackProps {
  children?: ReactNode[] | ReactNode;
}

export const WrapperToolBar: FC<WrapperToolBarProps> = ({ children, ...props }) => {
  return (
    <Stack
      bgcolor="white"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      p={3}
      {...props}
    >
      {children}
    </Stack>
  );
};
