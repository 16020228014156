import React, { FC, useRef, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { addDays, endOfToday, format, startOfDay, subDays } from 'date-fns';

import { useLocalization } from '../../../localization';
import { useDefaultRangeCalendar } from '../../../hooks';
import { Enum_Order_Status } from '../../../__generated__/types';
import { WrapperToolBar } from '../../../components/WrapperToolBar';
import { ItemSelectFilterType, SelectFilter } from './SelectFilter';
import { RangeCalendar } from '../../../legos/rangeCalendar/RangeCalendar';
import { useGetManagers } from '../../../graphql/queries/hook/useGetManagers';
import { useFiltersSearchParamsForOrders } from '../../../hooks/useFiltersSearchParamsForOrders';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';

interface Props {
  setResetFilterOrders(): void;
  result?: string | number;
}

const STATUSES_FILTERS: ItemSelectFilterType[] = [
  ...[
    Enum_Order_Status.Created,
    Enum_Order_Status.Editing,
    Enum_Order_Status.InStock,
    Enum_Order_Status.Awaiting,
    Enum_Order_Status.Delivery,
    Enum_Order_Status.Completed,
    Enum_Order_Status.Coordination,
  ].map(value => ({
    label: value,
    value,
  })),
];

export const ToolBarWithSearchAndSelect: FC<Props> = ({ result }) => {
  const { translateLang } = useLocalization();
  const { reportRangeDates } = useDefaultRangeCalendar();
  const { clients } = useGetManagers();
  const {
    changeManager,
    changeStatus,
    changeDate,
    resetFilters,
    selStatus,
    selManager,
    selDateTo,
    selDateFrom,
  } = useFiltersSearchParamsForOrders({
    dateFrom: format(addDays(new Date(), -30), 'yyyy-MM-dd'),
    dateTo: format(new Date(), 'yyyy-MM-dd'),
  });

  const [selectDates, setSelectedDates] = useState(getDatesFilters);
  const startRef = useRef(false);

  const handlerChangesDates = (val: Date[]) => {
    startRef.current = true;
    setSelectedDates(val);
    changeDate(format(val[0], 'yyyy-MM-dd'), format(val[1] ?? val[0], 'yyyy-MM-dd'));
  };

  const getItems = () => {
    if (clients) {
      return clients.map((i, index) => {
        return {
          label: i?.id || index.toString(),
          value: `${i?.firstName || ''} ${i.lastName || ''} `,
        };
      });
    }
    return [];
  };
  const resetFiltersAll = () => {
    resetFilters();
    startRef.current = false;
    setSelectedDates([startOfDay(subDays(new Date(), 30)), new Date()]);
  };

  function getDatesFilters(): Date[] {
    if (selDateFrom && selDateTo) {
      return [new Date(selDateFrom), new Date(selDateTo)];
    }
    return [startOfDay(subDays(new Date(), 30)), endOfToday()];
  }

  return (
    <WrapperToolBar>
      <Stack>
        <TranslatedField
          fontSize="30px"
          originText={`${translateLang('result')}: ${result}` || '0'}
        />
      </Stack>
      <Stack direction="row" spacing={3}>
        {clients.length > 0 && (
          <SelectFilter
            labelFilter={translateLang('manager')}
            items={getItems()}
            selectItem={selManager}
            changeUrlParams={changeManager}
          />
        )}
        <SelectFilter
          labelFilter={translateLang('status')}
          items={STATUSES_FILTERS}
          selectItem={selStatus}
          changeUrlParams={changeStatus}
        />
        <RangeCalendar
          selectedDates={selectDates}
          defaultRangeDates={reportRangeDates}
          setSelectedDates={handlerChangesDates}
        />
        <Button
          color="primary"
          variant="outlined"
          onClick={resetFiltersAll}
          style={{
            width: '100%',
            textTransform: 'none',
          }}
        >
          <TranslatedField
            noWrap
            isTranslate
            fontSize={16}
            color="black"
            originText="resetFilters"
          />
        </Button>
      </Stack>
    </WrapperToolBar>
  );
};
