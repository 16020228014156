import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { addDays, endOfToday, startOfDay, subDays } from 'date-fns';
import { UseAutocompleteProps, Stack, Autocomplete, TextField, Box, Button } from '@mui/material';

import { REVIEWSPUBSTATUS } from './StatusSelect';
import { useLocalization } from '../../../../../localization';
import { useDefaultRangeCalendar } from '../../../../../hooks';
import { ReviewFiltersInput } from '../../../../../__generated__/types';
import { WrapperToolBar } from '../../../../../components/WrapperToolBar';
import { RangeCalendar } from '../../../../../legos/rangeCalendar/RangeCalendar';
import { useFiltersSearchParamsForOrders } from '../../../../../hooks/useFiltersSearchParamsForOrders';
import { TranslatedField } from '../../../../../components/Layout/components/TranslatedField/TranslatedField';

interface Props {
  setResetFilter(): void;
  result?: string | number;
  setFilter: Dispatch<SetStateAction<ReviewFiltersInput>>;
}

type PublishedStatus = {
  label: string;
  value: REVIEWSPUBSTATUS;
};

const NoSpareStatusTypes: PublishedStatus[] = [
  ...[REVIEWSPUBSTATUS.Published, REVIEWSPUBSTATUS.Unpublished].map(value => ({
    label: value,
    value,
  })),
];

export const ToolBarReviews: FC<Props> = ({ result, setFilter, setResetFilter }) => {
  const { translateLang } = useLocalization();
  const { reportRangeDates } = useDefaultRangeCalendar();
  const { changeDate, selDateTo, selDateFrom, resetFilters } = useFiltersSearchParamsForOrders();

  const [currentValueStatus, setCurrentValueStatus] = useState<PublishedStatus | null>();
  const [selectDates, setSelectedDates] = useState(getDatesFilters);
  const startRef = useRef(false);
  const handlerReset = () => {
    setResetFilter();
    resetFilters();
    setSelectedDates([startOfDay(subDays(new Date(), 30)), new Date()]);
  };
  const handlerChangesDates = (val: Date[]) => {
    startRef.current = true;
    setSelectedDates(val);
    changeDate(
      val[0]?.toISOString(),
      val[1] ? addDays(val[1], 1)?.toISOString() : addDays(val[0], 1).toISOString()
    );
  };

  const handlerClearFilter = (deleteField: 'publication') => {
    setCurrentValueStatus(undefined);

    setFilter(oldFilter => {
      const reset = { ...oldFilter };
      delete reset[deleteField];
      return reset;
    });
  };
  const getStatusValue = (value: REVIEWSPUBSTATUS): boolean | undefined => {
    if (value === REVIEWSPUBSTATUS.Published) {
      return true;
    } else if (value === REVIEWSPUBSTATUS.Unpublished) {
      return false;
    }
    return undefined;
  };
  const getStatusLabel = (value: string) => {
    if (value === REVIEWSPUBSTATUS.Published) {
      return translateLang('Processed');
    } else if (value === REVIEWSPUBSTATUS.Unpublished) {
      return `${translateLang('Unprocessed')}`;
    }
    return '';
  };
  const handleChangeStatus: UseAutocompleteProps<
    PublishedStatus,
    undefined,
    undefined,
    undefined
  >['onChange'] = (evt, option): void => {
    if (option) {
      let filter: ReviewFiltersInput | undefined;
      if (typeof option !== 'string') {
        const _option = option as PublishedStatus;
        filter = {
          publication: {
            containsi: getStatusValue(_option.value),
          },
        };
      }
      setCurrentValueStatus(option as PublishedStatus);
      setFilter(oldFilter => ({
        ...oldFilter,
        ...filter,
      }));
    }
  };

  useEffect(() => {
    if (startRef.current) {
      setFilter(oldFilter => ({
        ...oldFilter,
        createdAt: {
          between: [
            selDateFrom !== '' ? selDateFrom : startOfDay(subDays(new Date(), 30)).toISOString(),
            selDateTo !== '' ? selDateTo : endOfToday().toISOString(),
          ],
        },
      }));
    }
  }, [selectDates]);

  function getDatesFilters(): Date[] {
    if (selDateFrom && selDateTo) {
      return [new Date(selDateFrom), new Date(selDateTo)];
    }
    return [startOfDay(subDays(new Date(), 30)), endOfToday()];
  }

  return (
    <WrapperToolBar>
      <Stack width="100%" flexDirection="row" justifyContent="left">
        <TranslatedField
          fontSize="30px"
          originText={`${translateLang('result')}: ${result}` || ''}
        />
      </Stack>
      <Stack spacing={2} bgcolor="white" flexDirection="row" justifyContent="right">
        <Stack direction="row" gap={2} alignItems="center">
          <TranslatedField originText={translateLang('status')} />
          <Autocomplete
            size="small"
            disablePortal
            id="combo-box-demo"
            sx={{ minWidth: 200 }}
            value={currentValueStatus}
            options={NoSpareStatusTypes || []}
            renderInput={params => <TextField {...params} label={translateLang('status')} />}
            getOptionLabel={option => {
              return getStatusLabel(option.value) || '';
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={getStatusLabel(option.value)}>
                {getStatusLabel(option.value)}
              </Box>
            )}
            onChange={handleChangeStatus}
            onInputChange={(e, value, reason) => {
              if (reason === 'clear') {
                handlerClearFilter('publication');
              }
            }}
          />
          <RangeCalendar
            selectedDates={selectDates}
            defaultRangeDates={reportRangeDates}
            setSelectedDates={handlerChangesDates}
          />
          <Button
            color="primary"
            variant="outlined"
            onClick={handlerReset}
            style={{
              width: '100%',
              textTransform: 'none',
            }}
          >
            <TranslatedField
              noWrap
              isTranslate
              fontSize={16}
              color="black"
              originText="resetFilters"
            />
          </Button>
        </Stack>
      </Stack>
    </WrapperToolBar>
  );
};
