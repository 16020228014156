import React from 'react';

import { Box, Stack } from '@mui/material';
import { AllClientsTable } from './AllClientsTable';
import { PAGE_COUNT } from '../../../../../helpers/constants';
import { useLocalization } from '../../../../../localization';
import { SearchField } from '../../../../../components/SearchField';
import { useGetClients } from '../../../../../graphql/queries/hook/useGetClients';
import { getClientsSearchFilter, isNumber } from '../../../../../helpers/functions';
import { useFiltersSearchParamsForClients } from '../../../../../hooks/useFiltersSearchParamsForClients';

export const AllClientsTab = () => {
  const { translateLang } = useLocalization();

  const { page, changePage, searchText, changeSearchText } = useFiltersSearchParamsForClients();
  const { clients, pagination, isLoading } = useGetClients({
    pagination: {
      page,
      pageSize: PAGE_COUNT,
    },
    filters: getClientsSearchFilter(searchText),
    sort: isNumber(searchText) ? 'id:desc' : searchText ? 'customer_contact_info.firstName' : '',
  });

  const changePageNumber = (value: number) => {
    changePage(value + 1);
  };

  return (
    <Box width="100%">
      <Stack flexGrow={1}>
        <SearchField
          initStateText={searchText}
          executeWithDelay={changeSearchText}
          placeholder={translateLang('searchOrdersAccountingLabel')}
        />
      </Stack>

      <AllClientsTable
        allClientsAccounting={clients}
        totalPage={pagination?.pageCount}
        changePage={changePageNumber}
        currentPage={page}
        loading={isLoading}
      />
    </Box>
  );
};
