import { useGetClientByIdQuery } from '../__generated__/getClintById';
import { CustomerContactInfoFragment } from '../../fragments/__generated__/customerContactInfo';
import { VehiclesType } from '../../../helpers/types';
import { getVehicle } from '../../../helpers/functions';
import { RolesEnum } from '../../../types/types';
import { useLocalization } from '../../../localization';

type InfoType = NonNullable<NonNullable<CustomerContactInfoFragment['data']>['attributes']>;
type PhoneNumbersType = NonNullable<InfoType>['phoneNumbers'];
type AddressType = NonNullable<InfoType>['deliveryAddress'];

export interface CustomerType {
  id: string;
  email: string;
  contactInfoId?: string;
  firstName?: string | null;
  lastName?: string | null;
  userName?: string;
  typeClient?: string | RolesEnum;
  typeClientID?: string | null;
  typePriceClient?: string | RolesEnum;
  phoneNumbers?: PhoneNumbersType;
  primaryPhoneNumber?: string | null;
  deliveryAddress?: AddressType;
  vehicles?: Array<VehiclesType>;
  isContactInfoActive?: boolean;
  VATId?: string | null;
  companyName?: string | null;
}

export const useGetClientById = (clientId: string): CustomerType & { loading: boolean } => {
  const { updateSelectLanguage } = useLocalization();
  const { data, loading } = useGetClientByIdQuery({
    variables: { id: clientId, locale: updateSelectLanguage as string },
  });
  return {
    id: data?.usersPermissionsUser?.data?.id || '',
    email: data?.usersPermissionsUser?.data?.attributes?.email || '',
    contactInfoId:
      data?.usersPermissionsUser?.data?.attributes?.customer_contact_info?.data?.id || '',
    typeClient: data?.usersPermissionsUser?.data?.attributes?.role?.data?.attributes?.name || '',
    typeClientID: data?.usersPermissionsUser?.data?.attributes?.role?.data?.id || '',
    typePriceClient:
      data?.usersPermissionsUser?.data?.attributes?.role?.data?.attributes?.name || '',
    companyName:
      data?.usersPermissionsUser?.data?.attributes?.customer_contact_info?.data?.attributes
        ?.companyName || '',
    firstName:
      data?.usersPermissionsUser?.data?.attributes?.customer_contact_info?.data?.attributes
        ?.firstName || '',
    lastName:
      data?.usersPermissionsUser?.data?.attributes?.customer_contact_info?.data?.attributes
        ?.lastName || '',
    VATId:
      data?.usersPermissionsUser?.data?.attributes?.customer_contact_info?.data?.attributes
        ?.VATId || '',
    userName: data?.usersPermissionsUser?.data?.attributes?.username || '',
    primaryPhoneNumber: data?.usersPermissionsUser?.data?.attributes?.phoneNumber,
    phoneNumbers:
      data?.usersPermissionsUser?.data?.attributes?.customer_contact_info?.data?.attributes
        ?.phoneNumbers,
    deliveryAddress:
      data?.usersPermissionsUser?.data?.attributes?.customer_contact_info?.data?.attributes
        ?.deliveryAddress,
    isContactInfoActive:
      data?.usersPermissionsUser?.data?.attributes?.customer_contact_info?.data != null,
    vehicles: getVehicle(data),
    loading: loading,
  };
};
