import { IconButton, Badge } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC } from 'react';
import { SearchField } from '../../../components/SearchField';
import { ListAltOutlined, FilterAltOutlined } from '@mui/icons-material';
import { GeneralSparesFiltersInput } from '../../../__generated__/types';

interface Props {
  isCollapseCatalog: boolean;
  isCollapseFilter: boolean;
  searchText?: string | null;
  onCollapseCatalogClick: () => void;
  onCollapseFilterClick: () => void;
  onSearchParts: (text: string) => void;
  filters: GeneralSparesFiltersInput | undefined;
  searchBy: string[];
}
export const WrapperSearchFieldCollapse: FC<Props> = ({
  isCollapseCatalog,
  isCollapseFilter,
  onCollapseCatalogClick,
  onCollapseFilterClick,
  onSearchParts,
  searchText,
  filters,
  searchBy,
}) => {
  return (
    <Box width="100%" position="relative" display="flex" alignItems="center">
      <SearchField executeWithDelay={onSearchParts} initStateText={searchText ?? ''} />

      <Badge
        color="warning"
        variant="dot"
        overlap="circular"
        invisible={(filters?.brandIds?.length ?? 0) !== 0 || searchBy?.length !== 0 ? false : true}
        sx={{
          '& .MuiBadge-dot': {
            translate: '-5px 7px',
            width: 8,
            height: 8,
          },
        }}
      >
        <IconButton
          onClick={onCollapseFilterClick}
          sx={{
            marginLeft: '30px',
            '&': {
              borderRadius: '10px',
              backgroundColor: isCollapseFilter ? '#97A408' : '#5269A3',
              color: 'white',
            },
            '&:hover': {
              borderRadius: '10px',
              backgroundColor: isCollapseFilter ? 'rgba(151,164,8,0.56)' : '#2A3453',
              color: 'white',
            },
          }}
          size="large"
        >
          <FilterAltOutlined />
        </IconButton>
      </Badge>

      <Badge
        color="warning"
        variant="dot"
        overlap="circular"
        invisible={filters?.tecdoc_categoryId ? false : true}
        sx={{
          '& .MuiBadge-dot': {
            translate: '-5px 7px',
            width: 8,
            height: 8,
          },
        }}
      >
        <IconButton
          onClick={onCollapseCatalogClick}
          sx={{
            marginLeft: '10px',
            '&': {
              borderRadius: '10px',
              backgroundColor: isCollapseCatalog ? '#97A408' : '#5269A3',
              color: 'white',
            },
            '&:hover': {
              borderRadius: '10px',
              backgroundColor: isCollapseCatalog ? 'rgba(151,164,8,0.56)' : '#2A3453',
              color: 'white',
            },
          }}
          size="large"
        >
          <ListAltOutlined />
        </IconButton>
      </Badge>
    </Box>
  );
};
