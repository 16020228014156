import React, { FC } from 'react';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import { FormControl, Box, InputLabel, MenuItem } from '@mui/material';

interface Props extends SelectProps {
  error?: boolean;
  disabled?: boolean;
  label?: string;
  value?: string;
  height?: string;
  items: string[];
  minWidth?: number;
  currentValue?: string;
  handleChange?: (event: SelectChangeEvent) => void;
}

export const MobileSelect: FC<Props> = ({
  items,
  value,
  label,
  height,
  minWidth,
  handleChange,
  disabled,
  error,
}) => {
  return (
    <Box sx={{ minWidth: { minWidth } }}>
      <FormControl fullWidth disabled={disabled}>
        <InputLabel>{label}</InputLabel>
        <Select
          value={value}
          label={label}
          onChange={handleChange}
          MenuProps={{
            PaperProps: {
              sx: { backgroundColor: '#2c3a66', color: 'white', border: '1px solid white' },
            },
          }}
          sx={{
            backgroundColor: 'inherit',
            color: 'white',
            height: { height },
            width: '150px',
            textAlign: 'center',
            border: 'none',
            boxShadow: 'none',
            textTransform: 'uppercase',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },

            '&:before': {
              color: 'white',
              border: 'none',
              textTransform: 'uppercase',
            },
            '&:after': {
              border: 'none',
              textTransform: 'uppercase',
            },

            '& .MuiSvgIcon-root': {
              color: 'white',
            },
          }}
          error={error}
        >
          {items.map(item => (
            <MenuItem key={item} value={item} sx={{ textTransform: 'uppercase' }}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
