import { Stack } from '@mui/system';
import React, { FC, useState } from 'react';
import { IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { Icon } from '../../../legos';
import { CustomModal } from '../../../components';
import { StyledTypography } from './StyledTypography';
import { useLocalization } from '../../../localization';
import { formatPhoneNumber, handlerError } from '../../../helpers/functions';
import { ComponentClientPhoneNumbersInput } from '../../../__generated__/types';
import { GetClientByIdDocument } from '../../../graphql/queries/__generated__/getClintById';
import { CustomerType, useGetClientById } from '../../../graphql/queries/hook/useGetClientById';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { useUpdateCustomerContactInfoMutation } from '../../../graphql/mutations/__generated__/updateCustomerContactInfo';

type PhoneNumbers = Pick<CustomerType, 'phoneNumbers'>;
interface PhoneProps extends PhoneNumbers {
  onClickInput(idPhone: string): void;
  id: string;
}
export const PhonesField: FC<PhoneProps> = ({ id, phoneNumbers, onClickInput }) => {
  const { translateLang, updateSelectLanguage } = useLocalization();
  const [runUpdateCustomer] = useUpdateCustomerContactInfoMutation();
  const { contactInfoId } = useGetClientById(id);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [deletedId, setDeletedId] = useState<string>('');

  const closeSuccessModal = () => {
    setOpenSuccessModal(false);
  };
  const openModals = (value?: string) => {
    if (value) {
      setDeletedId(value);
    }
    setOpenSuccessModal(true);
  };
  const handlerDelete = async () => {
    const phone: ComponentClientPhoneNumbersInput[] | undefined = phoneNumbers
      ?.map(item => {
        return {
          id: item?.id,
          number: item?.number,
        };
      })
      .filter(item => item.id !== deletedId);

    const variables = {
      phoneNumbers: phone,
    };
    try {
      const response = await runUpdateCustomer({
        variables: {
          data: { ...variables },
          id: contactInfoId || '',
        },
        refetchQueries: [
          {
            query: GetClientByIdDocument,
            variables: {
              id: id || '',
              locale: updateSelectLanguage as string,
            },
          },
        ],
      });
      if (response) {
        setOpenSuccessModal(false);
      }
    } catch (err: unknown) {
      handlerError(err);
    }
  };
  return (
    <>
      {phoneNumbers &&
        phoneNumbers.map((number, index) => (
          <Stack key={number?.id} direction="row">
            <StyledTypography
              onClick={() => onClickInput(number?.id || '')}
              value={formatPhoneNumber(number?.number)}
              label={
                phoneNumbers.length > 1
                  ? `${translateLang('phoneNumbers')} ${index + 1}`
                  : translateLang('phoneNumbers')
              }
              maxWidth="425px"
            />

            {phoneNumbers.length > 1 && (
              <Button
                variant="text"
                startIcon={<DeleteIcon />}
                onClick={() => openModals(number?.id)}
              />
            )}
          </Stack>
        ))}
      <IconButton
        sx={{
          width: '200px',
          fontSize: 'small',
          height: '40px',
          borderRadius: '0px',
          justifyContent: 'left',
          paddingLeft: '0px',
          color: '#848C22',
        }}
        onClick={() => onClickInput('')}
      >
        <Icon icon="add" color="inherit" />
        {translateLang('addPhone')}
      </IconButton>
      <CustomModal
        title={translateLang('deleteConfirm')}
        handleClose={closeSuccessModal}
        open={openSuccessModal}
      >
        <Stack flexDirection="row" mt={5}>
          <Button
            onClick={() => setOpenSuccessModal(false)}
            variant="contained"
            color="secondary"
            style={{
              width: '80%',
              height: '95%',
              marginRight: '2px',
            }}
          >
            <TranslatedField
              originText={translateLang('cancel')}
              fontSize={16}
              isTranslate
              noWrap
              color="white"
            />
          </Button>
          <Button
            onClick={handlerDelete}
            variant="contained"
            color="error"
            style={{
              width: '80%',
              height: '95%',
              marginLeft: '2px',
            }}
          >
            <TranslatedField
              originText={translateLang('del')}
              fontSize={16}
              isTranslate
              noWrap
              color="white"
            />
          </Button>
        </Stack>
      </CustomModal>
    </>
  );
};
