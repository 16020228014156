import React, { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { CheckBox, CropFreeOutlined } from '@mui/icons-material';
import { useLocalization } from '../../../../../../localization';
import { usePreDeliveryStateQuery } from '../../../../../../graphql/cacheQuery/__generated__/preDeliveryState';

interface Props {
  onChange(): void;
  isComplectedToDelivery: boolean;
  hideButton?: boolean;
}
export const TitlePreDeliveryTable: FC<Props> = ({
  onChange,
  isComplectedToDelivery,
  hideButton,
}) => {
  const { data: dataPreDeliveryState } = usePreDeliveryStateQuery();
  const { translateLang } = useLocalization();

  const handleClickButton = () => {
    if (!isComplectedToDelivery) {
      onChange();
    }
  };

  return (
    <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
      <Typography sx={{ fontWeight: 400, fontSize: '36px', lineHeight: '45px' }}>
        {`${translateLang('order')}: #${dataPreDeliveryState?.preDeliveryState.orderNumber}`}
      </Typography>
      {!hideButton && (
        <Button
          sx={{
            height: '50px',
            minWidth: '248px',
            color: '#97A408',
            borderColor: '#97A408',
            borderRadius: '10px',
            padding: '0 30px',
          }}
          onClick={handleClickButton}
          variant="outlined"
          startIcon={
            isComplectedToDelivery ? (
              <CheckBox color="inherit" />
            ) : (
              <CropFreeOutlined color="inherit" />
            )
          }
        >
          {translateLang(isComplectedToDelivery ? 'orderCollected' : 'scanOrder')}
        </Button>
      )}
    </Box>
  );
};
