import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { endOfToday, startOfDay, subDays } from 'date-fns';

import { PAGE_COUNT } from '../../../../helpers/constants';
import { ClientReturnsTable } from './ClientReturnsTable';
import { ClientReturnsToolbar } from './ClientReturnsToolbar';
import { useGetClientReturns } from './hooks/useGetClientReturns';
import { ClientReturnItem } from './component/ClientReturnItem/ClientReturnItem';
import { CustomerReturnItemFiltersInput } from '../../../../__generated__/types';
import { useFiltersSearchParamsForOrders } from '../../../../hooks/useFiltersSearchParamsForOrders';
import { useFiltersSearchParamsForClients } from '../../../../hooks/useFiltersSearchParamsForClients';

interface Props {
  userId: string;
}

export const ClientReturns: FC<Props> = ({ userId }) => {
  const { selDateTo, selDateFrom } = useFiltersSearchParamsForOrders();
  const { page, changePage } = useFiltersSearchParamsForClients();

  const [isReturnInvoiceTable, setIsReturnInvoiceTable] = useState(false);
  const [returnInvoiceId, setReturnInvoiceId] = useState('');

  const { customerReturns, pagination, isLoading } = useGetClientReturns({
    pagination: {
      page,
      pageSize: PAGE_COUNT,
    },
    filters: getFiltersOrders(),
  });

  const toggleReturnInvoiceTable = (id: string) => {
    setIsReturnInvoiceTable(!isReturnInvoiceTable);
    setReturnInvoiceId(id);
  };

  const changePageNumber = (value: number) => {
    changePage(value + 1);
  };

  function getFiltersOrders(): CustomerReturnItemFiltersInput {
    const filters: CustomerReturnItemFiltersInput = {
      id: { eq: userId },
      createdAt: { between: [startOfDay(subDays(new Date(), 30)), endOfToday()] },
    };

    if (selDateTo && selDateFrom) {
      filters['createdAt'] = {
        between: [selDateFrom, selDateTo],
      };
    }
    return filters;
  }

  return (
    <>
      {!isReturnInvoiceTable ? (
        <Stack width="100%">
          <ClientReturnsToolbar />

          <ClientReturnsTable
            loading={isLoading}
            currentPage={page}
            clientReturns={customerReturns}
            totalPage={pagination?.pageCount}
            changePage={changePageNumber}
            toggleReturnInvoiceTable={toggleReturnInvoiceTable}
          />
        </Stack>
      ) : (
        <ClientReturnItem
          returnInvoiceId={returnInvoiceId}
          toggleReturnInvoiceTable={toggleReturnInvoiceTable}
        />
      )}
    </>
  );
};
