import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { GeneralSpareFragmentFragmentDoc } from '../../fragments/__generated__/GeneralSpareFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type GetGeneralSparesQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.GeneralSparesFiltersInput>;
  locale: Types.Scalars['I18NLocaleCode'];
  pagination?: Types.InputMaybe<Types.PaginationArg>;
  sort?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
}>;


export type GetGeneralSparesQuery = { __typename?: 'Query', generalSpares?: { __typename?: 'GeneralSparesEntityResponseCollection', data: Array<{ __typename?: 'GeneralSparesEntity', id: string, attributes?: { __typename?: 'GeneralSpare', descriptionsInfo?: any | null, articleCriteria?: any | null, isDraft?: boolean | null, source?: number | null, eanNumber?: any | null, oemNumbers?: any | null, images?: any | null, price?: number | null, brandId?: number | null, brandName?: string | null, title?: string | null, tecdoc_articleNumber?: string | null, discounts?: Array<{ __typename?: 'GeneralDiscounts', id?: string | null, discount?: number | null, supplier?: { __typename?: 'GeneralSupplier', id?: string | null } | null } | null> | null, suppliersSpare?: Array<{ __typename?: 'GeneralSuppliersSpare', id?: string | null, minOrderQuantity?: number | null, amount?: string | null, discount?: number | null, prevPrice?: number | null, price?: number | null, supplierPrice?: number | null, warehouse?: any | null, tecdoc_articleNumber?: string | null, external_id?: string | null, priceByRole?: { __typename?: 'PriceByRole', customer?: number | null, customer30?: number | null, customer40?: number | null, employee?: number | null, permanentWholesaleCustomer?: number | null, wholesaleCustomer?: number | null } | null, supplier?: { __typename?: 'GeneralSupplier', id?: string | null, name?: string | null, deliveryDay?: number | null } | null } | null> | null } | null }>, meta?: { __typename?: 'CollectionMeta', pagination: { __typename?: 'Pagination', page: number, pageCount: number, pageSize: number, total: number } } | null } | null };


export const GetGeneralSparesDocument = gql`
    query getGeneralSpares($filters: GeneralSparesFiltersInput, $locale: I18NLocaleCode!, $pagination: PaginationArg, $sort: [String!]) {
  generalSpares(
    filters: $filters
    locale: $locale
    pagination: $pagination
    sort: $sort
  ) {
    data {
      ...GeneralSpareFragment
    }
    meta {
      pagination {
        page
        pageCount
        pageSize
        total
      }
    }
  }
}
    ${GeneralSpareFragmentFragmentDoc}`;

/**
 * __useGetGeneralSparesQuery__
 *
 * To run a query within a React component, call `useGetGeneralSparesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralSparesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralSparesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      locale: // value for 'locale'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetGeneralSparesQuery(baseOptions: Apollo.QueryHookOptions<GetGeneralSparesQuery, GetGeneralSparesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGeneralSparesQuery, GetGeneralSparesQueryVariables>(GetGeneralSparesDocument, options);
      }
export function useGetGeneralSparesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGeneralSparesQuery, GetGeneralSparesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGeneralSparesQuery, GetGeneralSparesQueryVariables>(GetGeneralSparesDocument, options);
        }
export type GetGeneralSparesQueryHookResult = ReturnType<typeof useGetGeneralSparesQuery>;
export type GetGeneralSparesLazyQueryHookResult = ReturnType<typeof useGetGeneralSparesLazyQuery>;
export type GetGeneralSparesQueryResult = Apollo.QueryResult<GetGeneralSparesQuery, GetGeneralSparesQueryVariables>;