import * as yup from 'yup';
import { useLocalization } from '../localization';
import { LoginFields } from '../pages/Authorization/helpers/types';

export const useValidation = () => {
  const { translateLang } = useLocalization();
  // const phoneRegExp =
  //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const phoneValidation = yup
    .string()
    .required(translateLang('emptyInputError'))
    .min(11, 'Minimum 11 characters')
    .max(17, 'Maximum 17 characters');
  // .matches(phoneRegExp, 'Phone number is not valid');

  const emailValidation = yup
    .string()
    .email('Please enter a valid e-mail address')
    .required(translateLang('emptyInputError'));

  const passwordValidation = yup
    .string()
    .min(8, translateLang('Password must be at least 8 characters'))
    .required(translateLang('emptyInputError'));

  const confirmPasswordValidation = yup
    .string()
    .required(translateLang('emptyInputError'))
    .oneOf([yup.ref(LoginFields.Password), null], 'Passwords must matсh');

  return {
    phoneValidation,
    emailValidation,
    passwordValidation,
    confirmPasswordValidation,
  };
};
