import { ApolloClient } from '@apollo/client';
import { apolloCache } from './apolloCacheConfig';
import { apolloLink } from './apolloLinks';
import { initStore } from './store/initStore';
import { LOCAL_SCHEME } from './store/localSchema';

export const apolloClient = new ApolloClient({
  cache: apolloCache,
  link: apolloLink,
  typeDefs: LOCAL_SCHEME,
});
initStore();
