/* eslint-disable no-console */
import React, { Fragment, SyntheticEvent, useRef, useState } from 'react';
import { WrapperPages } from '../../components/Wrapper/WrapperPages';
import { useLocalization } from '../../localization';
import { PatchesName } from '../../types/types';
import { Box, Button, IconButton, Stack, Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  ItemSpecialProd,
  useGetSpecialProposals,
} from '../../graphql/queries/hook/useGetSpecialProposals';
import {
  BoxCell,
  HeaderCell,
  TableCellsValue,
  TableProduct,
} from '../../components/TableProduct/TableProduct';
import { Icon } from '../../legos';
import { setLocalDataSpecialProduct } from '../../graphql/store/functionsWitingCache';
import { NoRowsImage } from '../../components/NoRowsImage/NoRowsImage';
import { Enum_Specialproposal_System_Name } from '../../__generated__/types';
import { CustomAvatar, CustomModal } from '../../components';
import { TranslatedField } from '../../components/Layout/components/TranslatedField/TranslatedField';
import { useUpdateSpecialProposalMutation } from '../../graphql/mutations/__generated__/updateSpecialProposal';
import logo from '../../assets/logotype.svg';
import { useFiltersSearchParamsForClients } from '../../hooks/useFiltersSearchParamsForClients';

const LIMIT_COUNT = 10;
export const TopProducts = () => {
  const { translateLang, updateSelectLanguage } = useLocalization();
  const { page, changePage } = useFiltersSearchParamsForClients();

  const navigate = useNavigate();
  const [valueCategory, setValueCategory] = useState(0);

  const [dataDeleteModal, setDataDeleteModal] = useState<{
    isOpen: boolean;
    selProduct: ItemSpecialProd | null;
  }>({
    isOpen: false,
    selProduct: null,
  });
  const { specialProducts, categories } = useGetSpecialProposals(valueCategory);
  const [addSpecialProposalMutation] = useUpdateSpecialProposalMutation();

  const productsIDsRef = useRef<Array<string>>([]);

  const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
    setValueCategory(newValue);
    changePage(0);
  };
  const handlerDelete = async () => {
    try {
      const updateProducts = productsIDsRef.current.filter(
        item => item !== dataDeleteModal?.selProduct?.idProduct
      );
      await addSpecialProposalMutation({
        variables: {
          id: categories[valueCategory].id,
          data: {
            products: updateProducts,
          },
          locale: updateSelectLanguage,
        },
      });
      if (page * LIMIT_COUNT >= specialProducts.length - 1) {
        let curPage = page - 1;
        if (curPage < 0) {
          curPage = 0;
        }
        changePage(curPage);
      }
    } catch (err: unknown) {
      if (err instanceof ErrorEvent) {
        console.log(err?.message);
      }
    } finally {
      setDataDeleteModal({ selProduct: null, isOpen: false });
    }
  };
  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      { title: translateLang('photo'), containerProps: { width: '66px' } },
      { title: translateLang('article') },
      { title: translateLang('productName') },
      { title: translateLang('manufacturer') },
      { title: translateLang('action'), containerProps: { width: '40px' } },
    ];
  };
  const getRowsTable = (): Array<TableCellsValue> => {
    productsIDsRef.current = [];
    const start = page * LIMIT_COUNT;
    const length =
      start + LIMIT_COUNT < specialProducts.length
        ? start + LIMIT_COUNT
        : specialProducts.length + start;
    const rows: Array<TableCellsValue> = [];

    for (let index = start; index < length; index++) {
      const _values: Array<BoxCell> = [];

      const indexSpecialProduct = index % 10;
      const product = specialProducts[indexSpecialProduct];
      _values.push({
        val: (
          <CustomAvatar
            alt="Remy Sharp"
            src={product.imgUrl}
            logo={logo}
            variant="rounded"
            sx={{
              width: '100%',
              height: '100%',
              '& img': {
                objectFit: 'contain',
              },
            }}
          />
        ),
        containerProps: {
          borderLeft: 'none',
        },
      });
      _values.push({ val: product.vendorCode });
      _values.push({ val: product.title });
      _values.push({ val: product.manufacturer });
      _values.push({
        val: (
          <IconButton
            onClick={() =>
              setDataDeleteModal({
                selProduct: { ...product },
                isOpen: true,
              })
            }
          >
            <Icon fontSize="medium" icon="deleting" color="inherit" />
          </IconButton>
        ),
        containerProps: { justifyContent: 'center' },
      });

      rows.push({ cellsValues: _values });
    }
    for (let index = 0; index < specialProducts.length; index++) {
      productsIDsRef.current.push(specialProducts[index].idProduct);
    }

    return rows;
  };
  const changePageNumber = (val: number) => {
    changePage(val);
  };
  const handlerWrapperButton = () => {
    setLocalDataSpecialProduct({
      dataWrite: {
        selectedSpecialProduct: {
          idCategory: categories[valueCategory].id,
          productsIDs: productsIDsRef.current,
          systemName: categories[valueCategory].systemName as Enum_Specialproposal_System_Name,
          titleTab: categories[valueCategory].title,
        },
      },
    });
    navigate(`/${PatchesName.TopProducts}/${valueCategory}`);
  };

  return (
    <WrapperPages
      titleHeader={translateLang(PatchesName.TopProducts)}
      isButton
      disableButton={!specialProducts || !categories.length}
      titleButton={translateLang('addToProduct')}
      handlerButton={handlerWrapperButton}
    >
      <Box
        bgcolor="white"
        width="100%"
        flex={1}
        borderRadius="10px"
        pl={2}
        pr={2}
        pt={4}
        pb={4}
        boxSizing="border-box"
      >
        <Box mb={3} width="100%">
          {categories.length ? (
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              value={valueCategory}
              onChange={handleChangeTab}
              sx={{
                '& .Mui-selected': {
                  color: '#545d75',
                  fontWeight: 600,
                },
                '& button': {
                  // maxWidth: 180,
                  minWidth: 30,
                },
              }}
            >
              {categories.map((item, index) => (
                <Tab key={index.toString()} label={item.title} />
              ))}
            </Tabs>
          ) : (
            <NoRowsImage text="No data server" />
          )}
        </Box>
        <Box p={1} boxSizing="border-box">
          {specialProducts && categories.length && (
            <TableProduct
              totalPages={Math.ceil(specialProducts.length / LIMIT_COUNT)}
              startPage={page + 1}
              itemsRows={getRowsTable()}
              itemsHeader={getHeadersTable()}
              changeCurrentPage={changePageNumber}
            />
          )}
        </Box>
      </Box>
      {dataDeleteModal.isOpen ? (
        <CustomModal
          title={`${translateLang('del')}?`}
          handleClose={() => setDataDeleteModal({ selProduct: null, isOpen: false })}
          open={true}
        >
          <Box width="200px" height="200px">
            <CustomAvatar
              alt="Remy Sharp"
              src={dataDeleteModal?.selProduct?.imgUrl}
              logo={logo}
              variant="rounded"
              sx={{
                width: '100%',
                height: '100%',
                '& img': {
                  objectFit: 'contain',
                },
              }}
            />
          </Box>
          <TranslatedField
            originText={dataDeleteModal?.selProduct?.vendorCode || ''}
            fontSize={20}
            color="black"
          />
          <TranslatedField
            originText={dataDeleteModal?.selProduct?.title || ''}
            fontSize={20}
            color="black"
          />
          <TranslatedField
            originText={dataDeleteModal?.selProduct?.manufacturer || ''}
            fontSize={20}
            color="black"
          />
          <TranslatedField
            marginTop={3}
            originText="deleteTopProduct"
            isTranslate
            fontSize={16}
            color="black"
          />
          <Stack flexDirection="row" mt={5}>
            <Button
              onClick={() => setDataDeleteModal({ selProduct: null, isOpen: false })}
              variant="contained"
              color="secondary"
              style={{
                width: '80%',
                height: '95%',
                marginRight: '2px',
              }}
            >
              <TranslatedField
                originText={translateLang('cancel')}
                fontSize={16}
                isTranslate
                noWrap
                color="white"
              />
            </Button>
            <Button
              onClick={handlerDelete}
              variant="contained"
              color="error"
              style={{
                width: '80%',
                height: '95%',
                marginLeft: '2px',
              }}
            >
              <TranslatedField
                originText={translateLang('del')}
                fontSize={16}
                isTranslate
                noWrap
                color="white"
              />
            </Button>
          </Stack>
        </CustomModal>
      ) : (
        <Fragment />
      )}
    </WrapperPages>
  );
};
