/* eslint-disable no-console,@typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useRef, useState } from 'react';
import { UseAutocompleteProps } from '@mui/material';
import {
  GetCarCategoriesQueryVariables,
  useGetCarCategoriesQuery,
} from '../../../../../graphql/queries/__generated__/getCarCategories';
import { useLocalization } from '../../../../../localization';
import { AutocompleteCustom, AutocompleteItemType } from './AutocompleteCustom';
import { useUpdateCategoryMutation } from '../../../../../graphql/mutations/__generated__/updateCarCategory';
import { GetCategoryEntityFragmentDoc } from '../../../../../graphql/fragments/__generated__/categoryEntity';
import { CarCategory, CategoryEntity } from '../../../../../__generated__/types';

interface Props {
  parentSectionID: string;
}

export const SectionItemTop: FC<Props> = ({ parentSectionID }) => {
  const { updateSelectLanguage, translateLang } = useLocalization();
  const [runUpdatePopularCategory] = useUpdateCategoryMutation();
  const VARIABLES_GET_SECTIONS: GetCarCategoriesQueryVariables = {
    filters: { hasRoot: { eq: true }, tecdoc_linkingTargetType: { eq: 'P' } },
    pagination: { limit: -1 },
    locale: updateSelectLanguage,
    sort: ['name'],
  };
  const { data: CategoriesCatalog } = useGetCarCategoriesQuery({
    variables: VARIABLES_GET_SECTIONS,
  });
  const [selectedValue, setSelectedValue] = useState<AutocompleteItemType[]>([]);
  const [valuesCarCategories, setValuesCarCategories] = useState<AutocompleteItemType[]>([]);
  const [isUpdateCatalog, setIsUpdateCatalog] = useState(false);
  const updateCategoriesRef = useRef<{ id: string; data: CategoryEntity | null }[]>([]);

  const getItems = (): AutocompleteItemType[] => {
    if (CategoriesCatalog && CategoriesCatalog?.carCategories?.data) {
      return CategoriesCatalog?.carCategories?.data
        ?.filter(item => item.attributes?.category?.data?.id === parentSectionID)
        .map((item, index) => {
          return {
            id: item?.id || index.toString(),
            title: item?.attributes?.name || '',
            targetType: item?.attributes?.tecdoc_linkingTargetType || index.toString(),
          };
        });
    }
    return [];
  };

  const handlerUpdateAutocomplete = async () => {
    const data = selectedValue.map(item => item.id);
    try {
      await runUpdatePopularCategory({
        variables: {
          data: {
            car_popular_categories: data,
          },
          id: parentSectionID,
          locale: updateSelectLanguage,
        },
        // refetchQueries: [
        //   {
        //     query: GetCarCategoriesDocument,
        //     variables: VARIABLES_GET_SECTIONS,
        //   },
        // ],
        update(cache) {
          updateCategoriesRef.current.map(item => {
            cache.updateFragment(
              {
                id: `CarCategoryEntity:${item.id}`,
                fragment: GetCategoryEntityFragmentDoc,
              },
              oldData => {
                const currCategory: Pick<CarCategory, 'popular_category'> = {
                  popular_category: {
                    data: item.data,
                  },
                };
                const newData = { ...oldData };
                newData.attributes = { ...oldData.attributes, ...currCategory };
                return { ...newData };
              }
            );
          });
        },
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsUpdateCatalog(false);
    }
  };

  const handlerChangeAutocomplete: UseAutocompleteProps<
    AutocompleteItemType,
    any,
    any,
    any
  >['onChange'] = (event, option, reason, details) => {
    if (reason === 'removeOption') {
      updateCategoriesRef.current.push({
        id: details?.option.id || '',
        data: null,
      });
    } else if (reason === 'selectOption') {
      updateCategoriesRef.current.push({
        id: details?.option.id || '',
        data: {
          id: parentSectionID,
          __typename: 'CategoryEntity',
        },
      });
    } else {
      selectedValue.forEach(item => {
        updateCategoriesRef.current.push({
          id: item.id || '',
          data: null,
        });
      });
    }
    setSelectedValue(option as AutocompleteItemType[]);
    setIsUpdateCatalog(true);
  };

  function getSelectedCategories(): AutocompleteItemType[] {
    if (CategoriesCatalog && CategoriesCatalog?.carCategories?.data) {
      return CategoriesCatalog?.carCategories?.data
        ?.filter(item => item?.attributes?.popular_category?.data?.id === parentSectionID)
        .map((item, index) => {
          return {
            id: item?.id || index.toString(),
            title: item?.attributes?.name || '',
            targetType: item?.attributes?.tecdoc_linkingTargetType || index.toString(),
          };
        });
    }
    return [];
  }

  useEffect(() => {
    if (parentSectionID && CategoriesCatalog) {
      const dataSelectCategories = getSelectedCategories();
      const dataCategories = getItems();
      setValuesCarCategories(dataCategories);
      setSelectedValue(dataSelectCategories);
      setIsUpdateCatalog(false);
    }
  }, [parentSectionID, CategoriesCatalog]);

  return (
    <AutocompleteCustom
      options={valuesCarCategories}
      selectedValue={selectedValue}
      label={translateLang('popularCategories')}
      handlerChange={handlerChangeAutocomplete}
      handlerUpdateAutocomplete={handlerUpdateAutocomplete}
      isUpdateCatalog={isUpdateCatalog}
    />
  );
};
