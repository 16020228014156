import React, { Dispatch, FC, SetStateAction } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useLocalization } from '../../../../localization';
import { useGetStockCellAddressesQuery } from '../../../../graphql/queries/__generated__/getStockCellAddresses';
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';

interface Props {
  emptyCellID?: string;
  setEmptyCellID: Dispatch<SetStateAction<string | undefined>>;
}
export const EditAddressStock: FC<Props> = ({ setEmptyCellID, emptyCellID }) => {
  const { translateLang } = useLocalization();
  const { data } = useGetStockCellAddressesQuery();

  const handleChange = (event: SelectChangeEvent) => {
    setEmptyCellID(event.target.value as string);
  };

  const cellTitle = translateLang('cell');
  const shelfTitle = translateLang('shelf');

  return (
    <Box
      width="100%"
      mt={2}
      mb={3}
      display="flex"
      alignItems="baseline"
      justifyContent="space-between"
      pr={3}
      boxSizing="border-box"
    >
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{`${shelfTitle}, ${cellTitle}`}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={emptyCellID}
          label={`${shelfTitle}, ${cellTitle}`}
          onChange={handleChange}
        >
          {data?.stockCellAddresses
            // ?.filter(item => item?.isEmptyCell)
            ?.map((item, index) => (
              <MenuItem key={index.toString()} value={item?.id || index}>
                {item?.isEmptyCell ? (
                  <CheckBoxOutlineBlank
                    sx={{ color: '#97A408', marginRight: '10px' }}
                    fontSize="small"
                  />
                ) : (
                  <CheckBox sx={{ color: '#97A408', marginRight: '10px' }} fontSize="small" />
                )}
                {`${shelfTitle}: ${item?.shelf}, ${cellTitle}: ${item?.cell} (${translateLang(
                  item?.isEmptyCell ? 'cellEmpty' : 'cellFull'
                )})`}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};
