import React, { ReactNode } from 'react';
import { TableCellProps } from '@mui/material';

import { TransactionType } from './useGetTransactions';
import { useLocalization } from '../../../../../../../localization';
import { ClientTransactionRowItem } from '../ClientTransactionRowItem';
import { HeaderCell } from '../../../../../../../components/TableProduct/TableProduct';

interface HookProps {
  transactions?: TransactionType[];
  action: (transaction: TransactionType, type: 'edit' | 'open' | 'delete') => void;
}
interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTableActive(): Array<ReactNode>;
}

export const useGetClientTransactionTable = ({ transactions, action }: HookProps): ReturnHook => {
  const { translateLang } = useLocalization();

  const getHeadersTable = (): Array<HeaderCell> => {
    const style: TableCellProps = { color: '#F1F3F8', align: 'left' };
    return [
      {
        title: translateLang('documentNumber'),
        containerProps: { ...style, align: 'center', width: '20px' },
      },
      {
        title: translateLang('date'),
        containerProps: { ...style, align: 'center', width: '100px' },
      },
      {
        title: translateLang('type'),
        containerProps: { ...style, align: 'center', width: '60px' },
      },
      {
        title: `${translateLang('sum')} €`,
        containerProps: { ...style, align: 'center', width: '80px' },
      },
      {
        title: translateLang('description'),
        containerProps: { ...style, align: 'center', width: '20px' },
      },
      {
        title: translateLang('comment'),

        containerProps: { ...style, align: 'center', width: '20px' },
      },
      {
        title: translateLang('actions'),
        containerProps: { ...style, align: 'center', width: '20px' },
      },
    ];
  };

  const getRowsTableActive = (): ReactNode[] => {
    if (transactions && transactions?.length) {
      return transactions?.map(item => {
        return (
          <ClientTransactionRowItem
            key={`transaction-${item?.id}`}
            transaction={item}
            action={action}
          />
        );
      });
    }
    return [];
  };

  return {
    getHeadersTable,
    getRowsTableActive,
  };
};
