import * as yup from 'yup';

import { SupplierCorrectiveInvoiceFields } from '../types';
import { useLocalization } from '../../../../../../../localization';

export const useTransactionValidation = () => {
  const { translateLang } = useLocalization();

  const validationSchema = yup.object({
    [SupplierCorrectiveInvoiceFields.CorrectiveInvoiceDate]: yup
      .date()
      .required(translateLang('emptyInputError')),

    [SupplierCorrectiveInvoiceFields.CorrectiveInvoiceNumber]: yup
      .string()
      .required(translateLang('emptyInputError')),
  });

  return {
    validationSchema,
  };
};
