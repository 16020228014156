import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { GetCategoryEntityFragmentDoc } from '../../fragments/__generated__/categoryEntity';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type GetCarCategoriesQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.CarCategoryFiltersInput>;
  pagination?: Types.InputMaybe<Types.PaginationArg>;
  locale?: Types.InputMaybe<Types.Scalars['I18NLocaleCode']>;
  sort?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
}>;


export type GetCarCategoriesQuery = { __typename?: 'Query', carCategories?: { __typename?: 'CarCategoryEntityResponseCollection', data: Array<{ __typename?: 'CarCategoryEntity', id?: string | null, attributes?: { __typename?: 'CarCategory', name?: string | null, hasChildren: boolean, hasRoot?: boolean | null, tecdoc_linkingTargetType?: string | null, category?: { __typename?: 'CategoryEntityResponse', data?: { __typename?: 'CategoryEntity', id?: string | null } | null } | null, car_category_parent?: { __typename?: 'CarCategoryEntityResponse', data?: { __typename?: 'CarCategoryEntity', id?: string | null } | null } | null, popular_category?: { __typename?: 'CategoryEntityResponse', data?: { __typename?: 'CategoryEntity', id?: string | null } | null } | null } | null }> } | null };


export const GetCarCategoriesDocument = gql`
    query getCarCategories($filters: CarCategoryFiltersInput, $pagination: PaginationArg, $locale: I18NLocaleCode, $sort: [String]) {
  carCategories(
    filters: $filters
    pagination: $pagination
    locale: $locale
    sort: $sort
  ) {
    data {
      ...getCategoryEntity
    }
  }
}
    ${GetCategoryEntityFragmentDoc}`;

/**
 * __useGetCarCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCarCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarCategoriesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      locale: // value for 'locale'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetCarCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCarCategoriesQuery, GetCarCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCarCategoriesQuery, GetCarCategoriesQueryVariables>(GetCarCategoriesDocument, options);
      }
export function useGetCarCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCarCategoriesQuery, GetCarCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCarCategoriesQuery, GetCarCategoriesQueryVariables>(GetCarCategoriesDocument, options);
        }
export type GetCarCategoriesQueryHookResult = ReturnType<typeof useGetCarCategoriesQuery>;
export type GetCarCategoriesLazyQueryHookResult = ReturnType<typeof useGetCarCategoriesLazyQuery>;
export type GetCarCategoriesQueryResult = Apollo.QueryResult<GetCarCategoriesQuery, GetCarCategoriesQueryVariables>;