import React, { FC, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, GlobalStyles } from '@mui/material';

import { AppBar } from './components/AppBar';
import { Drawer } from './components/Drawer';
import { DrawerHeader } from './helpers/styledComponents';
import { useGetIsMobileQuery } from '../../graphql/cacheQuery/__generated__/getIsMobile';
import { MobileDrawer } from './components/MobileDrawer';
import { MobileAppBar } from './components/MobileAppBar';

interface Props {
  path: string;
}
export const Layout: FC<Props> = ({ path }) => {
  const { data } = useGetIsMobileQuery();
  const isMobile = data?.isMobile ?? false;

  const [open, setOpen] = useState(false);
  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <GlobalStyles
        styles={{
          body: { backgroundColor: '#F1F3F8' },
        }}
      />
      {!isMobile ? (
        <>
          <AppBar open={open} />
          <Drawer path={path} handleDrawerOpen={setOpen} open={open} />
        </>
      ) : (
        <MobileDrawer path={path} handleDrawerOpen={setOpen} open={open} />
      )}

      <Box
        component="main"
        sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, p: isMobile ? 0 : 3 }}
      >
        {!isMobile && <DrawerHeader />}
        {isMobile && <MobileAppBar path={path} handleDrawerOpen={setOpen} open={open} />}

        <Box flex={1} p={isMobile ? 3 : 0}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
