import { SelectChangeEvent } from '@mui/material';
import React, { FC, useState } from 'react';
import { useHandlerNotificationApp } from '../../../../hooks/useHandlerNotificationApp';
import { Enum_Nosparepart_Status } from '../../../../__generated__/types';
import { useUpdateNoSparePartMutation } from '../../../../graphql/mutations/__generated__/updateNoSparePart';
import { Selectus } from '../StyledSelect';
import { GetNewSparePartsDocument } from '../../../../graphql/queries/__generated__/getNoSpareParts';
const NO_SPARE_PART_STATUSES = [
  Enum_Nosparepart_Status.Archived,
  Enum_Nosparepart_Status.Processed,
  Enum_Nosparepart_Status.Unprocessed,
];

interface Props {
  currentStatus: Enum_Nosparepart_Status;
  noPartsId: string;
}
export const StatusSelectNoParts: FC<Props> = ({ currentStatus, noPartsId }) => {
  const { addNotification } = useHandlerNotificationApp();
  const [runUpdateNewsletterSubscriptionMutation] = useUpdateNoSparePartMutation();

  const handleChange = async (event: SelectChangeEvent) => {
    const _status = event.target.value as Enum_Nosparepart_Status;
    try {
      await runUpdateNewsletterSubscriptionMutation({
        variables: {
          id: noPartsId,
          data: {
            status: _status,
          },
        },
        refetchQueries: [
          {
            query: GetNewSparePartsDocument,
            variables: {
              sort: ['id:desc'],
              filters: { status: { eqi: 'unprocessed' } },
              pagination: { limit: -1 },
            },
          },
        ],
      });
      addNotification({ messageError: 'Status  updated success', typeMessage: 'success' });
    } catch (err: any) {
      addNotification({ messageError: err?.message, typeMessage: 'error' });
    }
  };
  return (
    <Selectus data={NO_SPARE_PART_STATUSES} status={currentStatus} handleChange={handleChange} />
  );
};
