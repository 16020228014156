import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useBarcode } from 'next-barcode';

import { getDataOderItems } from '../../../../../../graphql/queries/hook/useGetOrderItems';
import { getDataOrder } from '../../../../../../graphql/queries/hook/useGetOrderById';
import { roundAmountToString } from '../../../../../../utils/currencyUtils';
import { useGetOrderByIdLazyQuery } from '../../../../../../graphql/queries/__generated__/getOrderById';
import { useLocalization } from '../../../../../../localization';
import { useGetOrderItemsLazyQuery } from '../../../../../../graphql/queries/__generated__/getOrderItems';

export type DeliveryNoteProps = { print: (orderId: string) => void };

export const DeliveryNote = forwardRef<DeliveryNoteProps>((_, ref) => {
  const printableContentRef = useRef<HTMLDivElement | null>(null);
  const [shouldPrint, setShouldPrint] = useState(false);
  const { updateSelectLanguage } = useLocalization();

  const [runGetOrderById, { data: orderData }] = useGetOrderByIdLazyQuery();
  const [runGetOrderItems, { data: orderItemsData }] = useGetOrderItemsLazyQuery();

  const order = useMemo(() => getDataOrder(orderData), [orderData]);
  const orderItems = useMemo(() => getDataOderItems(orderItemsData), [orderItemsData]);

  const handlePrint = async (orderId: string) => {
    Promise.allSettled([
      runGetOrderById({
        variables: { id: orderId, locale: updateSelectLanguage as string },
      }),
      runGetOrderItems({
        variables: {
          filters: {
            order: {
              id: {
                eq: orderId,
              },
            },
          },
          pagination: {
            limit: -1,
          },
        },
      }),
    ]).then(() => setShouldPrint(true));
  };

  useEffect(() => {
    if (shouldPrint && order.id && order.orderNumber) {
      const ifram = document.createElement('iframe');
      ifram.style.display = 'none';
      ifram.style.fontFamily = 'Arial';

      document.body.appendChild(ifram);
      const pri = ifram.contentWindow;
      if (pri && printableContentRef?.current?.innerHTML) {
        pri.document.open();
        pri.document.write(printableContentRef.current.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
      }
      setTimeout(() => {
        document.body.removeChild(ifram);
      }, 500);

      setShouldPrint(false);
    }
  }, [shouldPrint, order.id, order.orderNumber]);

  useImperativeHandle(ref, () => ({
    print: handlePrint,
  }));

  const getBarcode = () => {
    if (order.orderNumber) {
      return `02-${order.id?.padStart(10, '0')}`;
    }
    return 'no number';
  };

  const { inputRef } = useBarcode({
    value: getBarcode(),
    options: {
      background: 'transparent',
      textPosition: 'bottom',
      textAlign: 'center',
      textMargin: 2,
      height: 60,
      fontSize: 16,
      margin: 0,
    },
  });

  const getOrderItems = () => {
    if (orderItems && orderItems.length) {
      return orderItems
        .filter(item => (item.quantityAfterRemovingAsReturn ?? 0) > 0)
        .map((item, index) => (
          <div key={index.toString()}>
            <span style={{ fontWeight: '600' }}>{`${index + 1}. `}</span>
            <span
              style={{ textAlign: 'left', fontSize: '14px' }}
            >{` ${item.article} ${item.productName} ${item.brandName}`}</span>
            <div style={{ display: 'flex' }}>
              <span style={{ flex: 1 }}></span>
              <span style={{ flex: 1 }}></span>
              <span
                style={{ flex: 1, textAlign: 'left' }}
              >{`${item.quantityAfterRemovingAsReturn} x`}</span>
              <span style={{ flex: 1, textAlign: 'left' }}>{item.customerPrice}</span>
              <span style={{ flex: 1, textAlign: 'right' }}>
                {roundAmountToString(
                  (item?.customerPrice || 0) * (item?.quantityAfterRemovingAsReturn || 0)
                )}
              </span>
            </div>
            <Line />
          </div>
        ));
    }
    return null;
  };
  const getTotalQuantity = () => {
    if (orderItems && orderItems.length) {
      return orderItems.reduce((akk, item) => {
        if (item?.balanceQuantity) {
          return akk + item.balanceQuantity;
        }
        return akk;
      }, 0);
    }
    return 0;
  };
  const getUserName = () => {
    const _firstName = order.firstName || '';
    const _lastName = order.lastName || '';
    const _userName = order.userName || '';
    if (_firstName || _lastName) {
      return `${_firstName} ${_lastName}`;
    } else if (_userName) {
      return _userName;
    }
    return 'No Name';
  };
  const getBrandAutoUser = () => {
    if (order.selectCar && order.selectCar.data?.attributes?.car_brand) {
      return (
        <span style={{ display: 'block', textAlign: 'center' }}>
          {
            order.selectCar.data.attributes.car_brand.data?.attributes?.car_brand_locales?.data[0]
              .attributes?.name
          }
        </span>
      );
    }
    return null;
  };
  const getModelAutoUser = () => {
    if (
      order.selectCar &&
      order.selectCar.data?.attributes?.car_locales?.data[0].attributes?.name
    ) {
      return (
        <span style={{ display: 'block', textAlign: 'center' }}>
          {order.selectCar.data.attributes.car_locales.data[0].attributes.name}
        </span>
      );
    }
    return null;
  };
  const getDeliveryDate = () => {
    if (orderItems.length && orderItems[0].deliveryDate) {
      const dateStr = new Date(orderItems[0].deliveryDate);
      const date = dateStr.getDate().toString().padStart(2, '0');
      const month = (dateStr.getMonth() + 1).toString().padStart(2, '0');
      const year = dateStr.getFullYear();
      return `${date}.${month}.${year}`;
    }
    return 'no date';
  };

  return (
    <div
      ref={printableContentRef}
      style={{
        display: 'none',
        width: '300px',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        border: 'solid 1px',
        backgroundColor: '#fff',
        fontFamily: 'Arial',
      }}
    >
      <p
        style={{ textAlign: 'center', fontSize: '24px' }}
      >{`Lieferschein #${order.orderNumber}`}</p>
      <span style={{ display: 'block', textAlign: 'center' }}>{getUserName()}</span>
      <span style={{ display: 'block', textAlign: 'center' }}>{getDeliveryDate()}</span>
      {getBrandAutoUser()}
      {getModelAutoUser()}
      <span style={{ display: 'block', textAlign: 'left', margin: '10px 0 10px 10px' }}>
        Lieferzone:
        <span style={{ fontSize: '16px', fontWeight: 600 }}>{` ${
          order.deliveryAddress?.city ? `${order.deliveryAddress?.city},` : ''
        } ${order.deliveryAddress?.district ? `Bezirk: ${order.deliveryAddress?.district},` : ''} ${
          order.deliveryAddress?.street ? `Straße: ${order.deliveryAddress?.street}` : ''
        }  ${order.deliveryAddress?.number ? `${order.deliveryAddress?.number},` : ''} ${
          order.deliveryAddress?.zipCode ? `PLZ: ${order.deliveryAddress?.zipCode},` : ''
        }`}</span>
      </span>
      <div style={{ width: '100%', height: '10px', borderBottom: 'solid 1px' }} />
      {getOrderItems()}

      <span style={{ display: 'block', textAlign: 'left', margin: '10px 0 10px 10px' }}>
        Stk. gesamt:
        <span style={{ fontSize: '18px', fontWeight: 600 }}>{`  ${getTotalQuantity()}`}</span>
      </span>
      <Line />
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 0 0 10px' }}>
        <span>Gesamt:</span>
        <span style={{ fontWeight: 600 }}>
          {typeof order.total === 'number' ? order.total.toFixed(2) : '0.00'}
        </span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 0 0 10px' }}>
        <span>inklusive Umst 20%:</span>
        <span style={{ fontWeight: 600 }}>
          {typeof order.total === 'number' ? (order.total / 6).toFixed(2) : '0.00'}
        </span>
      </div>
      <div style={{ padding: '20px 0' }}>
        <svg ref={inputRef} />
      </div>
      <span style={{ display: 'block', textAlign: 'left' }}>© autobro.at</span>
    </div>
  );
});

DeliveryNote.displayName = 'DeliveryNote';

const Line = () => {
  return (
    <svg viewBox="0 0 100 1" xmlns="http://www.w3.org/2000/svg">
      <line x1="0" y1="0" x2="100" y2="0" strokeWidth="0.5" stroke="black" />
    </svg>
  );
};
