import * as yup from 'yup';

import { SupplierPaymentFields } from '../types';
import { useLocalization } from '../../../../../../../localization';

export const useTransactionValidation = () => {
  const { translateLang } = useLocalization();

  const validationSchema = yup.object({
    [SupplierPaymentFields.Amount]: yup
      .number()
      .typeError(translateLang('mustBeNumber'))
      .moreThan(0, translateLang('amountMoreThanZero'))
      .required(translateLang('emptyInputError')),
    [SupplierPaymentFields.Date]: yup.date().required(translateLang('emptyInputError')),

    [SupplierPaymentFields.Description]: yup.string().required(translateLang('emptyInputError')),
  });

  return {
    validationSchema,
  };
};
