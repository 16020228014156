import React, { DragEvent, FC, useRef } from 'react';
import { Box, Divider } from '@mui/material';
import { InvoiceRow } from './InvoiceRow';
import { SupplierPaymentDetailEntity } from '../../../../../../__generated__/types';
import { DraggedInvoice, StartDragInvoice } from './LinkingPaymentModal';
import { TranslatedField } from '../../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { theme } from '../../../../../../helpers';

type LinkedInvoicesProps = {
  supplierPaymentDetails: SupplierPaymentDetailEntity[];
  dropHandler: (e: DragEvent<HTMLDivElement>) => void;
  dragStartHandler: StartDragInvoice;
  draggedInvoice: DraggedInvoice | null;
  linkedAmountChange?: (newAmount: string, invoiceId?: string | null) => Promise<void>;
};

export const LinkedInvoices: FC<LinkedInvoicesProps> = ({
  supplierPaymentDetails,
  dropHandler,
  dragStartHandler,
  draggedInvoice,
  linkedAmountChange,
}) => {
  const invoiceRefs = useRef<Map<string, { focusInput: () => void } | null>>(new Map());

  const innerDropHandler = (e: DragEvent<HTMLDivElement>) => {
    if (draggedInvoice?.direction === 'link') {
      const invoiceExist = supplierPaymentDetails.find(
        supplierPaymentDetail =>
          supplierPaymentDetail.attributes?.incoming_invoice?.data?.id === draggedInvoice.invoice.id
      );
      if (invoiceExist) {
        const element = invoiceRefs.current.get(invoiceExist.id!);
        if (element) {
          element.focusInput();
        }
      } else {
        dropHandler(e);
      }
    }
  };

  return (
    <Box
      flex={1}
      height="calc(100vh - 380px)"
      overflow="scroll"
      sx={{
        outline: '2px solid transparent',
        transition: 'all 200ms ',
      }}
      onDrop={e => {
        e.currentTarget.style.outline = '2px solid transparent';
        innerDropHandler(e);
      }}
      onDragLeave={e => {
        e.currentTarget.style.outline = '2px solid transparent';
      }}
      onDragOver={e => {
        if (draggedInvoice?.direction === 'link') {
          e.preventDefault();
          e.currentTarget.style.outline = '2px solid #2A3453';
        }
      }}
    >
      <Box
        paddingLeft={3}
        py={0.5}
        position="sticky"
        top={0}
        zIndex={2}
        border="1px solid rgba(224, 224, 224, 1)"
        bgcolor={theme.palette.common.lightGrey}
      >
        <Box display="flex" alignItems="center" pl={1} pr={1.5} minHeight={52}>
          <TranslatedField
            isTranslate
            variant="h4"
            fontSize={14}
            originText="documentNumberAndDate"
            pl={1}
            flex={3}
          />
          <Divider orientation="vertical" variant="middle" flexItem />

          <TranslatedField
            isTranslate
            variant="h4"
            fontSize={14}
            originText="supplierDocumentNumberDate"
            pl={1}
            flex={3}
          />
          <Divider orientation="vertical" variant="middle" flexItem />

          <TranslatedField
            isTranslate
            variant="h4"
            fontSize={14}
            originText="invoiceAmount"
            pl={1}
            flex={2}
          />
          <Divider orientation="vertical" variant="middle" flexItem />

          <TranslatedField
            isTranslate
            variant="h4"
            fontSize={14}
            originText="debtAmount"
            pl={1}
            flex={2}
          />
          <Divider orientation="vertical" variant="middle" flexItem />

          <TranslatedField
            isTranslate
            variant="h4"
            fontSize={14}
            originText="payment"
            pl={1}
            flex={3}
          />
        </Box>
      </Box>

      {supplierPaymentDetails?.map((supplierPaymentDetail, index) => {
        if (supplierPaymentDetail.attributes?.incoming_invoice?.data) {
          return (
            <InvoiceRow
              key={supplierPaymentDetail.id}
              lastChild={index === supplierPaymentDetails?.length - 1}
              ref={el => invoiceRefs.current.set(supplierPaymentDetail.id!, el)}
              editable
              linkedAmountChange={linkedAmountChange}
              linkedAmount={supplierPaymentDetail.attributes?.amount ?? 0}
              dropHandler={innerDropHandler}
              dragStartHandler={dragStartHandler}
              draggable
              acceptDrag
              invoice={supplierPaymentDetail.attributes.incoming_invoice.data}
              disabled={false}
            />
          );
        }
      })}
    </Box>
  );
};
