import React, { FC } from 'react';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { Button, CircularProgress, Fade, IconButton, Modal, Stack } from '@mui/material';
import { SupplierCorrectiveInvoiceFields } from './types';
import { theme } from '../../../../../../helpers';
import { useLocalization } from '../../../../../../localization';
import { CalendarMUI, Icon, Input } from '../../../../../../legos';
import { useTransactionValidation } from './hooks/useTransactionValidation';
import { useHandlerNotificationApp } from '../../../../../../hooks/useHandlerNotificationApp';
import { TranslatedField } from '../../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useUpdateSupplierReturnMutation } from '../../../../../../graphql/mutations/__generated__/updateSupplierReturn';

interface Props {
  id?: string;
  isModalOpen: boolean;
  invoiceDate: any;
  invoiceNumber?: string | null;
  handleClose: () => void;
}

export const CorrectiveInvoiceForm: FC<Props> = ({
  handleClose,
  id,
  isModalOpen,
  invoiceDate,
  invoiceNumber,
}) => {
  const { translateLang } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();

  const [updateSupplierReturnMutation, { loading }] = useUpdateSupplierReturnMutation();

  const initialValues = {
    [SupplierCorrectiveInvoiceFields.CorrectiveInvoiceDate]: invoiceDate ?? new Date(),
    [SupplierCorrectiveInvoiceFields.CorrectiveInvoiceNumber]: invoiceNumber ?? '',
  };

  const { validationSchema } = useTransactionValidation();

  const { values, errors, touched, dirty, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ correctiveInvoiceDate, correctiveInvoiceNumber }) => {
      const formattedDate = format(new Date(correctiveInvoiceDate), 'yyyy-MM-dd');

      updateSupplierReturnMutation({
        variables: {
          data: {
            correctiveInvoiceDate: formattedDate,
            correctiveInvoiceNumber,
          },
          id: id as string,
        },
      })
        .then(() => {
          addNotification({
            messageError: translateLang('invoiceSuccessfullyUpdated'),
            typeMessage: 'success',
          });
          handleClose();
        })
        .catch(err => {
          addNotification({ messageError: err.message, typeMessage: 'error' });
        });
    },
  });

  const handleDateChange = (newValue: Date | null) => {
    setFieldValue(SupplierCorrectiveInvoiceFields.CorrectiveInvoiceDate, newValue);
  };

  return (
    <Modal open={true} onClose={handleClose} closeAfterTransition>
      <Fade in={isModalOpen}>
        <Stack
          bgcolor={theme.palette.common.lightGrey}
          sx={{
            p: 4,
            top: '50%',
            width: 400,
            left: '50%',
            boxShadow: 24,
            borderRadius: 2,
            position: 'absolute' as const,
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Stack alignItems="center">
            <Stack width="100%" flexDirection="row" justifyContent="end" mb={2}>
              <IconButton onClick={handleClose} sx={{ p: 0 }}>
                <Icon icon="close" sx={{ color: theme.palette.primary.main }} />
              </IconButton>
            </Stack>

            <TranslatedField
              isTranslate
              variant="h4"
              color={theme.palette.common.darkBlue}
              marginBottom={2.5}
              fontSize={30}
              originText={'correctiveInvoice'}
            />

            <Stack component="form" width="100%" mb={2} onSubmit={handleSubmit}>
              <Stack
                width="100%"
                height={75}
                justifyContent="space-between"
                flexDirection="row"
                alignItems="baseline"
                gap={3}
              >
                <TranslatedField
                  originText={'incomingInvoice'}
                  fontSize={16}
                  isTranslate
                  noWrap
                  overflow="initial"
                />
                <Input
                  name={SupplierCorrectiveInvoiceFields.CorrectiveInvoiceNumber}
                  value={values[SupplierCorrectiveInvoiceFields.CorrectiveInvoiceNumber]}
                  onChange={handleChange}
                  error={
                    touched[SupplierCorrectiveInvoiceFields.CorrectiveInvoiceNumber] &&
                    !!errors[SupplierCorrectiveInvoiceFields.CorrectiveInvoiceNumber]
                  }
                  helperText={
                    touched[SupplierCorrectiveInvoiceFields.CorrectiveInvoiceNumber] &&
                    errors[SupplierCorrectiveInvoiceFields.CorrectiveInvoiceNumber]
                  }
                  inputProps={{
                    style: {
                      color: theme.palette.common.black,
                      boxShadow: ' 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset',
                      height: 12,
                    },
                  }}
                  bgcolor={theme.palette.common.white}
                  sx={{
                    ml: 'auto',
                    maxWidth: '271px',
                  }}
                />
              </Stack>

              <Stack
                width="77.5%"
                height={75}
                justifyContent="space-between"
                flexDirection="row"
                alignItems="baseline"
                gap={3}
              >
                <TranslatedField
                  originText={'date'}
                  fontSize={16}
                  isTranslate
                  noWrap
                  overflow="initial"
                />

                <CalendarMUI
                  disableFuture
                  value={values[SupplierCorrectiveInvoiceFields.CorrectiveInvoiceDate]}
                  isLabel={false}
                  setValue={handleDateChange}
                  inputStyle={{
                    bgcolor: theme.palette.common.white,
                    height: 40,
                    width: 180,
                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset',
                    p: 0,
                    '& .MuiInputBase-root': {
                      p: '5px 15px',
                    },
                  }}
                />
              </Stack>

              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={loading || !dirty}
                startIcon={loading ? <CircularProgress size={20} /> : undefined}
                sx={{
                  height: 50,
                  p: 2,
                  textTransform: 'none',
                  backgroundColor: '#5269A3',
                  borderRadius: '10px',
                  '&:disabled': {
                    color: theme.palette.common.white,
                  },
                }}
              >
                <TranslatedField originText={'update'} fontSize={16} isTranslate noWrap />
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Fade>
    </Modal>
  );
};
