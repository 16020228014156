import React, { FC } from 'react';
import { Box, Stack, useMediaQuery } from '@mui/material';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { Buttons } from './Buttons';
import { theme } from '../../helpers';

interface Props {
  handleChoosePhoto: (photo: any) => void;
  chosenPhoto: any;
  photos: {
    imageURL400: any;
    imageURL1600: any;
  }[];
}

export const CarouselSlider: FC<Props> = ({ handleChoosePhoto, chosenPhoto, photos }) => {
  const carouselXsScreen = useMediaQuery('(max-width:450px)');
  const carouselSmScreen = useMediaQuery('(max-width:550px)');
  const xsScreen = useMediaQuery(`(max-width:${600}px)`);
  return (
    <Stack order={xsScreen ? 2 : 1}>
      <CarouselProvider
        naturalSlideWidth={xsScreen ? 86 : 86}
        naturalSlideHeight={xsScreen ? 86 : 101}
        totalSlides={photos?.length}
        visibleSlides={xsScreen ? (carouselSmScreen ? (carouselXsScreen ? 5 : 6) : 7) : 5}
        orientation={xsScreen ? 'horizontal' : 'vertical'}
      >
        {photos?.length > 5 && <Buttons />}
        <Slider
          style={{
            width: xsScreen ? '100%' : '100px',
            height: xsScreen ? '72px' : '500px',
            margin: '0 auto',
          }}
        >
          {photos?.map(({ imageURL400, imageURL1600 }, index) => (
            <Slide key={`${imageURL400}-${index}`} index={index}>
              <Box
                height={xsScreen ? '60px' : '85px'}
                width={xsScreen ? '60px' : '85px'}
                display="flex"
                m="1px"
                mx={xsScreen ? 1 : '1px'}
                p={0.5}
                borderRadius="5px"
                alignItems="center"
                justifyContent="center"
                bgcolor="common.white"
                border={
                  chosenPhoto === imageURL1600
                    ? `1px solid ${theme.palette.common.appleGreen}`
                    : 'none'
                }
                boxShadow="0px 0px 1px rgba(25, 21, 22, 0.25)"
                onClick={() => handleChoosePhoto(imageURL1600)}
              >
                <Box
                  position="relative"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                  height="100%"
                >
                  <img
                    src={imageURL400}
                    alt={'slider-photo'}
                    style={{
                      maxHeight: '100%',
                      maxWidth: '100%',
                    }}
                  />
                </Box>
              </Box>
            </Slide>
          ))}
        </Slider>
      </CarouselProvider>
    </Stack>
  );
};
