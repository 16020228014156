import {
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useLocalization } from '../../../../../localization';
import { OrderInfoTypography } from './OrderInfoTypography';
import * as yup from 'yup';
import { useFormik } from 'formik';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useReturnOrderItemFromCustomerMutation } from '../../../../../graphql/mutations/__generated__/returnOrderItemFromCustomer';
import { useHandlerNotificationApp } from '../../../../../hooks/useHandlerNotificationApp';
import { useNavigate } from 'react-router-dom';
import { PatchesName } from '../../../../../types/types';

interface Props {
  client: string;
  phone: string;
  address: string;
  id: string | null | undefined;
}
enum ReturnFields {
  CommentField = 'comment_field',
}

export const ReturnApprovePage: FC<Props> = ({ address, client, phone, id }) => {
  const { translateLang } = useLocalization();
  const navigate = useNavigate();
  const [currentValue, setCurrentValue] = useState('0');
  const [required, setRequired] = useState(false);
  const color = '#9C9C9C';
  const [runReturnItem] = useReturnOrderItemFromCustomerMutation();
  const { addNotification } = useHandlerNotificationApp();
  const initialValues: {
    [key in ReturnFields]: string;
  } = {
    [ReturnFields.CommentField]: '',
  };
  const validationSchema = yup.object().shape({
    [ReturnFields.CommentField]: yup.string().when('required', {
      is: true,
      then: yup.string().required('Text field is required'),
    }),
  });

  const returnCheckList = translateLang('returnCheckList');
  const delimiter = ', ';
  const returnCheckListArray = returnCheckList.split(delimiter);
  const [disable, setDisable] = useState(false);
  const getRolesList = () => {
    const stringArray = returnCheckListArray.map(item => item.trim());
    if (stringArray) {
      return stringArray.map((item, index) => (
        <MenuItem key={index.toString()} value={item} sx={{ textTransform: 'capitalize' }}>
          {item}
        </MenuItem>
      ));
    }
    return null;
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      try {
        await runReturnItem({
          variables: {
            input: {
              orderItemId: id as string,
              returnedQuantity: 1,
              returnReason: currentValue,
              returnComment: values.comment_field,
            },
          },
        });
        addNotification({ messageError: 'Returned success', typeMessage: 'success' });
        setDisable(true);
        navigate(`/${PatchesName.Delivery}`, {
          replace: true,
        });
      } catch (err: any) {
        addNotification({ messageError: err?.message, typeMessage: 'error' });
      }
    },
  });

  const handleStatusChange = (event: SelectChangeEvent) => {
    setCurrentValue(event.target.value as string);
  };
  const comment = (req: boolean) => {
    if (req) {
      return (
        <Stack direction="row">
          <Typography
            sx={{
              lineHeight: '20.1px',
              fontSize: '16px',
              fontWeight: '400',
              color: '#000000',
              alignSelf: 'start',
            }}
          >
            {translateLang('comment')}
          </Typography>
          <span style={{ color: 'red' }}> * </span>
          <Typography
            sx={{
              lineHeight: '20.1px',
              fontSize: '16px',
              fontWeight: '400',
              color: '#7A828A',
              alignSelf: 'start',
            }}
          >
            ({translateLang('reasonReturn')})
          </Typography>
        </Stack>
      );
    } else {
      return (
        <Typography
          sx={{
            lineHeight: '20.1px',
            fontSize: '16px',
            fontWeight: '400',
            color: '#7A828A',
            alignSelf: 'start',
          }}
        >
          {`${translateLang('comment')}   (${translateLang('reasonReturn')})`}
        </Typography>
      );
    }
  };

  useEffect(() => {
    if (currentValue === 'Other' || currentValue === 'Sonstiges' || currentValue === 'Інше') {
      setRequired(true);
    } else {
      setRequired(false);
    }
  }, [currentValue]);

  return (
    <Stack padding="20px" height="100%" bgcolor="#F1F3F8">
      <Typography
        sx={{
          lineHeight: '25.1px',
          fontSize: '20px',
          fontWeight: '600',
          color: '#2A3453',
          textAlign: 'center',
        }}
      >
        {translateLang('order')}
      </Typography>
      {client && (
        <OrderInfoTypography label={`${translateLang('client')}:`} value={client} divider={false} />
      )}
      {phone && (
        <OrderInfoTypography label={`${translateLang('phone')}:`} value={phone} divider={false} />
      )}
      {address && (
        <OrderInfoTypography
          label={`${translateLang('address')}:`}
          value={address}
          divider={false}
        />
      )}

      <Stack component="form" flexGrow={1} spacing={2} onSubmit={handleSubmit} mt="38px">
        <Typography
          sx={{
            lineHeight: '20.1px',
            fontSize: '16px',
            fontWeight: '400',
            color: '#2A3453',
            alignSelf: 'start',
          }}
        >
          {translateLang('reasonReturn')}
        </Typography>
        <Select
          sx={{
            color: 'black',
            width: '100%',
            '&:before': {
              borderColor: color,
              color: 'black',
            },
            '&:after': {
              borderColor: color,
            },
            '&:not(.Mui-disabled):hover::before': {
              borderColor: color,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: color,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: color,
              backgroundColor: 'transparent',
            },
            '& .MuiSvgIcon-root': {
              color: color,
            },
            ' & > *::first-letter': {
              textTransform: 'uppercase',
            },
          }}
          IconComponent={ExpandMoreIcon}
          value={currentValue}
          onChange={handleStatusChange}
        >
          {getRolesList()}
        </Select>
        <> {comment(required)}</>
        <TextField
          name={ReturnFields.CommentField}
          value={values[ReturnFields.CommentField]}
          onChange={handleChange}
          error={touched[ReturnFields.CommentField] && !!errors[ReturnFields.CommentField]}
          helperText={touched[ReturnFields.CommentField] && errors[ReturnFields.CommentField]}
          required={required}
          sx={{ width: '100%' }}
        />

        <Button
          color="secondary"
          variant="contained"
          size="large"
          type="submit"
          disabled={disable}
          sx={{ textTransform: 'none', borderRadius: '10px' }}
        >
          {translateLang('confirmReturn')}
        </Button>
      </Stack>
    </Stack>
  );
};
