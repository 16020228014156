import { Button, ButtonProps } from '@mui/material';
import React, { FC } from 'react';
import { Icon } from '../../../legos';
import { useLocalization } from '../../../localization';

interface Props extends ButtonProps {
  handleClick: () => void;
  width?: number | string;
  isLabel?: boolean;
  disabled?: boolean;
}
export const PrinterButton: FC<Props> = ({ handleClick, width, isLabel, disabled }) => {
  const { translateLang } = useLocalization();

  return (
    <Button
      disabled={disabled}
      sx={{ width: width, color: '#97A408', borderColor: '#97A408', backgroundColor: 'inherit' }}
      onClick={handleClick}
      variant="outlined"
    >
      <Icon icon="print" color="inherit" />
      {!!isLabel && translateLang('print')}
    </Button>
  );
};
