import { SelectChangeEvent } from '@mui/material';
import React, { FC, useState } from 'react';
import { useHandlerNotificationApp } from '../../../../hooks/useHandlerNotificationApp';
import { Enum_Contactnotification_Status } from '../../../../__generated__/types';
import { Selectus } from '../StyledSelect';
import { useUpdateContactNotificationMutation } from '../../../../graphql/mutations/__generated__/updateContactNotification';
import { useLocalization } from '../../../../localization';

const Notifications_STATUSES = [
  Enum_Contactnotification_Status.Completed,
  Enum_Contactnotification_Status.InProcess,
  Enum_Contactnotification_Status.New,
];

interface Props {
  currentStatus: Enum_Contactnotification_Status;
  subscriptionId: string;
}
export const StatusSelect: FC<Props> = ({ currentStatus, subscriptionId }) => {
  const { addNotification } = useHandlerNotificationApp();
  const [status, setStatus] = useState<Enum_Contactnotification_Status>(currentStatus);
  const [runUpdateMutation] = useUpdateContactNotificationMutation();
  const { translateLang } = useLocalization();

  const handleChange = async (event: SelectChangeEvent) => {
    const _status = event.target.value as Enum_Contactnotification_Status;
    setStatus(_status);
    try {
      await runUpdateMutation({
        variables: {
          id: subscriptionId,
          data: {
            status: _status,
          },
        },
      });
      addNotification({
        messageError: translateLang('updatedSuccessfully'),
        typeMessage: 'success',
      });
    } catch (err: any) {
      addNotification({ messageError: err?.message, typeMessage: 'error' });
    }
  };
  return <Selectus data={Notifications_STATUSES} status={status} handleChange={handleChange} />;
};
