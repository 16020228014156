import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type GetCustomerAccountTransactionQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.CustomerAccountTransactionFiltersInput>;
  pagination?: Types.InputMaybe<Types.PaginationArg>;
  sort?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
}>;


export type GetCustomerAccountTransactionQuery = { __typename?: 'Query', customerAccountTransactions?: { __typename?: 'CustomerAccountTransactionEntityResponseCollection', data: Array<{ __typename?: 'CustomerAccountTransactionEntity', id?: string | null, attributes?: { __typename?: 'CustomerAccountTransaction', total?: number | null, documentType?: Types.Enum_Customeraccounttransaction_Documenttype | null, documentNumber?: string | null, documentId?: number | null, createdAt?: any | null, comment?: string | null } | null }>, meta: { __typename?: 'ResponseCollectionMeta', pagination: { __typename?: 'Pagination', total: number, page: number, pageSize: number, pageCount: number } } } | null };


export const GetCustomerAccountTransactionDocument = gql`
    query getCustomerAccountTransaction($filters: CustomerAccountTransactionFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  customerAccountTransactions(
    filters: $filters
    pagination: $pagination
    sort: $sort
  ) {
    data {
      id
      attributes {
        total
        documentType
        documentNumber
        documentId
        createdAt
        comment
      }
    }
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}
    `;

/**
 * __useGetCustomerAccountTransactionQuery__
 *
 * To run a query within a React component, call `useGetCustomerAccountTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerAccountTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerAccountTransactionQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetCustomerAccountTransactionQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerAccountTransactionQuery, GetCustomerAccountTransactionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerAccountTransactionQuery, GetCustomerAccountTransactionQueryVariables>(GetCustomerAccountTransactionDocument, options);
      }
export function useGetCustomerAccountTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerAccountTransactionQuery, GetCustomerAccountTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerAccountTransactionQuery, GetCustomerAccountTransactionQueryVariables>(GetCustomerAccountTransactionDocument, options);
        }
export type GetCustomerAccountTransactionQueryHookResult = ReturnType<typeof useGetCustomerAccountTransactionQuery>;
export type GetCustomerAccountTransactionLazyQueryHookResult = ReturnType<typeof useGetCustomerAccountTransactionLazyQuery>;
export type GetCustomerAccountTransactionQueryResult = Apollo.QueryResult<GetCustomerAccountTransactionQuery, GetCustomerAccountTransactionQueryVariables>;