import React, { Dispatch, FC, SetStateAction } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ItemProductType } from '../../types';

interface Props {
  dataSelect: Array<ItemProductType>;
  selectValue: string;
  changeSelect: Dispatch<SetStateAction<string>>;
  label: string;
}
export const SelectComponent: FC<Props> = ({ dataSelect, selectValue, changeSelect, label }) => {
  return (
    <Box width="100%" mt={2} mb={3} display="flex">
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectValue}
          label={label}
          onChange={evt => changeSelect(evt.target.value)}
        >
          {dataSelect?.map(item => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
