import React, { FC } from 'react';
import { CustomModal } from '../../../../../components';
import { ReviewDetail } from './ReviewDetail';
import { Box, Divider, Stack } from '@mui/material';
import { useLocalization } from '../../../../../localization';
import { ReviewsType } from '../../../../../graphql/queries/hook/useGetReviews';
import { ReviewMessage } from './ReviewMessage';
import { ButtonBlock } from './ButtonBlock';
import { ReplyMessage } from './ReplyMessage';

interface Props {
  open: boolean;
  handleClose: () => void;
  reviewData: ReviewsType;
}

export const ReviewModal: FC<Props> = ({ open, reviewData, handleClose }) => {
  const { translateLang } = useLocalization();

  return (
    <CustomModal
      title={translateLang('Review')}
      handleClose={handleClose}
      open={open}
      bgcolor="#F1F3F8"
      width="820px"
      height="560px"
      display="flex"
      flexDirection="column"
    >
      <Stack textAlign="center" width="100%" height="100%" overflow="hidden" sx={{ mb: '30px' }}>
        <ReviewDetail
          date={reviewData.date || ''}
          reviewsUserName={reviewData.reviewsUserName || ''}
          spareName={reviewData.spareName || ''}
        />
        <Divider sx={{ mt: '30px' }} />
        <ReviewMessage reviewText={reviewData?.reviewText || ''} rating={reviewData?.rating || 0} />
        {reviewData.replyText && <ReplyMessage replyText={reviewData.replyText} />}
        <Box sx={{ mt: 'auto', mb: '30px' }}>
          <ButtonBlock
            reply={!!reviewData.replyText?.length}
            statusPublication={reviewData.statusPublication || false}
            id={reviewData.id || ''}
          />
        </Box>
      </Stack>
    </CustomModal>
  );
};
