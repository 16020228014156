import React, { useCallback, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { WrapperShadow } from './components/WrapperShadow';
import { useLocalization } from '../../localization';
import { useLocation } from 'react-router-dom';
import { EnumTabsAuthState, LoginFields, OptionLoginType, SuccessType } from './helpers/types';
import { PhoneInput } from './components/PhoneInput';
import { InputAuth } from './components/InputAuth';
import { ButtonOrange } from './components/ButtonOrange';
import { StrokeForNavigate } from './components/StrokeForNavigate';
import { useFormik } from 'formik';
import { useValidation } from '../../hooks';
import * as yup from 'yup';
import { useHelpersNavigate } from './hooks/helpersNavigate';
import { useForgotPasswordByEmailMutation } from '../../graphql/mutations/__generated__/forgotPasswordByEmail';
import { useForgotPasswordByPhoneMutation } from '../../graphql/mutations/__generated__/forgotPasswordByPhone';
import { handlerError } from '../../helpers/functions';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export const ForgotPasswordPage = () => {
  const { translateLang } = useLocalization();
  const { goToLoginPage, goToSuccessPage, goToResetPasswordPage } = useHelpersNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { emailValidation, phoneValidation } = useValidation();
  const stLoc = useLocation().state as OptionLoginType;
  const [currentAuthState, setCurrentAuthState] = useState<EnumTabsAuthState>(() => {
    return stLoc?.isPhone ? EnumTabsAuthState.Phone : EnumTabsAuthState.Email;
  });
  const [runForgotPasswordByEmail, { loading: loadEmail }] = useForgotPasswordByEmailMutation();
  const [runForgotPasswordByPhone, { loading: loadPhone }] = useForgotPasswordByPhoneMutation();

  const validationSchema = yup.object({
    [LoginFields.Identifier]:
      currentAuthState === EnumTabsAuthState.Email ? emailValidation : phoneValidation,
  });

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('forgotByPhone');
    return token;
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const { values, errors, touched, handleChange, setErrors, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        [LoginFields.Identifier]: '',
      },
      validationSchema,
      onSubmit: async () => {
        if (currentAuthState === EnumTabsAuthState.Email) {
          await handlerForgotPasswordByEmail();
        } else {
          await handlerForgotPasswordByPhone();
        }
      },
    });

  const handlerForgotPasswordByEmail = async () => {
    try {
      const resp = await runForgotPasswordByEmail({
        variables: {
          email: values[LoginFields.Identifier],
          staff: true,
        },
      });
      if (resp.data?.forgotPassword?.ok) {
        goToSuccessPage({ state: { successType: SuccessType.ForgotPassword } });
      }
    } catch (err: any) {
      setErrors({
        [LoginFields.Identifier]: err?.message,
      });
      handlerError(err);
    }
  };
  const handlerForgotPasswordByPhone = async () => {
    try {
      const token = await handleReCaptchaVerify();
      const resp = await runForgotPasswordByPhone({
        variables: {
          input: {
            phoneNumber: `+${values[LoginFields.Identifier]}`,
            token: token || '',
          },
        },
      });
      if (resp.data?.forgotPasswordByPhoneNumber?.ok) {
        goToResetPasswordPage({
          state: { isPhone: true, phone: `+${values[LoginFields.Identifier]}` },
        });
      }
    } catch (err: any) {
      setErrors({
        [LoginFields.Identifier]: err?.message,
      });
      handlerError(err);
    }
  };

  useEffect(() => {
    setFieldValue(LoginFields.Identifier, '');
  }, [currentAuthState]);

  return (
    <WrapperShadow
      title={translateLang('resetPassword')}
      subTitle={translateLang('subTitleResetPassword')}
    >
      <Stack mt={4} component="form" width="100%" onSubmit={handleSubmit}>
        {currentAuthState === EnumTabsAuthState.Phone && (
          <PhoneInput
            phoneValue={values[LoginFields.Identifier]}
            changePhoneValue={val => {
              setFieldValue(LoginFields.Identifier, val);
            }}
            error={touched[LoginFields.Identifier] && !!errors[LoginFields.Identifier]}
            helperText={touched[LoginFields.Identifier] && errors[LoginFields.Identifier]}
          />
        )}
        {currentAuthState === EnumTabsAuthState.Email && (
          <InputAuth
            title={translateLang('email')}
            type="email"
            name={LoginFields.Identifier}
            value={values[LoginFields.Identifier]}
            onChange={handleChange}
            error={touched[LoginFields.Identifier] && !!errors[LoginFields.Identifier]}
            helperText={touched[LoginFields.Identifier] && errors[LoginFields.Identifier]}
          />
        )}
        <Box width="100%" my={1}>
          <StrokeForNavigate
            text={translateLang('resetPasswordVia')}
            textNavigate={translateLang(
              currentAuthState === EnumTabsAuthState.Email ? 'phoneNumber' : 'e-mail'
            ).toLowerCase()}
            actionNavigate={() => {
              if (currentAuthState === EnumTabsAuthState.Email) {
                setCurrentAuthState(EnumTabsAuthState.Phone);
              } else {
                setCurrentAuthState(EnumTabsAuthState.Email);
              }
            }}
          />
        </Box>
        <ButtonOrange
          type="submit"
          titleButton={translateLang('send')}
          loading={loadEmail || loadPhone}
        />
        <Box width="100%" mt={4}>
          <StrokeForNavigate
            text={translateLang('alreadyHaveAccount')}
            textNavigate={translateLang('log_in')}
            actionNavigate={goToLoginPage}
          />
        </Box>
      </Stack>
    </WrapperShadow>
  );
};
