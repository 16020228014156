import React, { useEffect, FC } from 'react';
import { ReviewsType, useGetReviews } from '../../../../../graphql/queries/hook/useGetReviews';
import { Box, Stack, Typography } from '@mui/material';
import { useLocalization } from '../../../../../localization';
interface Props {
  replyText: string;
}
export const ReplyMessage: FC<Props> = ({ replyText }) => {
  const { translateLang } = useLocalization();

  return (
    <Box mt="30px">
      <Stack justifyContent="space-around" alignItems="baseline" spacing={2}>
        <Stack alignItems="flex-start" textAlign="left">
          <Typography sx={{ fontSize: '14px', lineHeight: '17.57px', marginRight: '10px' }}>
            {`${translateLang('managerAnswer')}:`}
          </Typography>
          <Typography sx={{ fontSize: '14px', lineHeight: '18px' }}>{replyText}</Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
