import React, { ReactNode } from 'react';
import { Checkbox } from '@mui/material';

import { ItemStockType } from './useGetStocks';
import { TableStocksRow } from '../TableStocksRow';
import { useLocalization } from '../../../../../../../../../localization';
import { HeaderCell } from '../../../../../../../../../components/TableProductActive/TableProductActive';

interface HookProps {
  items?: ItemStockType[];
  allChecked: boolean;
  toggleChecked: () => void;
  handleCheckedItem: (id: string) => void;
}
interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTableActive(): Array<ReactNode>;
}

export const useGetStocksTable = ({
  items,
  allChecked,
  handleCheckedItem,
  toggleChecked,
}: HookProps): ReturnHook => {
  const { translateLang } = useLocalization();

  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      {
        title: '',
        containerProps: { width: '40px', color: '#f1f3f8', align: 'center' },
        checkBox: <Checkbox checked={allChecked} onClick={toggleChecked} />,
      },
      {
        title: translateLang('article'),
        containerProps: { width: '140px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('productName'),
        containerProps: { width: '340px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('manufacturer'),
        containerProps: { width: '140px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('dateOfReceiving'),
        containerProps: { width: '100px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('incomingQuantity'),
        containerProps: { width: '140px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('soldQuantity'),
        containerProps: { width: '140px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('price'),
        containerProps: { width: '140px', color: '#f1f3f8', align: 'center' },
      },
    ];
  };

  const getRowsTableActive = (): ReactNode[] => {
    if (items && items?.length) {
      return items?.map((item, index) => {
        return <TableStocksRow key={index} item={item} handleCheckedItem={handleCheckedItem} />;
      });
    }
    return [];
  };

  return {
    getHeadersTable,
    getRowsTableActive,
  };
};
