import React, { useState } from 'react';
import { useLocalization } from '../../../localization';
import { useParams } from 'react-router-dom';
import { UrlParamsObjectType } from '../../../types/types';
import { Box, Button } from '@mui/material';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { Stack } from '@mui/system';
import { CustomModal } from '../../../components';
import { useDeleteOrderItemMutation } from '../../../graphql/mutations/__generated__/deleteOrderItem';

import { useGetCartTable } from '../hooks/useGetCartTable';
import { useUpdateOrderMutation } from '../../../graphql/mutations/__generated__/updateOrder';
import { useHandlerNotificationApp } from '../../../hooks/useHandlerNotificationApp';
import { useGetManagers } from '../hooks/useGetManagers';
import { TableProductActive } from '../../../components/TableProductActive/TableProductActive';
import { DataModalType, OrderItemType } from '../../../helpers/types';
import { ChangeQuantityModal } from './ChangeQuantityModal';
import { ModalComment } from './ModalComment';
import { ChangeCustomerPriceModal } from './ChangeCustomerPriceModal';
import { GetOrderByIdDocument } from '../../../graphql/queries/__generated__/getOrderById';

export const CartDetailList = ({
  orderItems,
  orderItemsLoading,
  refetchOrderItems,
}: {
  orderItems: Array<OrderItemType>;
  orderItemsLoading: boolean;
  refetchOrderItems?: () => void;
}) => {
  const { idOrder = '' } = useParams<UrlParamsObjectType>();
  const { managers, isNewManager } = useGetManagers(idOrder);
  const { translateLang, updateSelectLanguage, selectLanguages } = useLocalization();
  const [runUpdateOrder] = useUpdateOrderMutation();
  const { addNotification } = useHandlerNotificationApp();
  const [runDeleteItem] = useDeleteOrderItemMutation({
    refetchQueries: [
      {
        query: GetOrderByIdDocument,
        variables: {
          id: idOrder,
          locale: selectLanguages,
        },
      },
    ],
  });
  const [commentModal, setCommentModal] = useState<DataModalType<{ id: string; comment: string }>>({
    isOpen: false,
    selProduct: {
      id: '',
      comment: '',
    },
  });
  const [dataDeleteModal, setDataDeleteModal] = useState<DataModalType<string>>({
    isOpen: false,
    selProduct: '',
  });
  const [changeQuantityModal, setChangeQuantityModal] = useState<DataModalType<OrderItemType>>({
    isOpen: false,
    selProduct: null,
  });
  const [changeCustomerPriceModal, setChangeCustomerPriceModal] = useState<
    DataModalType<OrderItemType>
  >({
    isOpen: false,
    selProduct: null,
  });

  const { getHeadersTable, getRowsTableActive } = useGetCartTable({
    orderItems,
    setCommentModal,
    setDataDeleteModal,
    setChangeQuantityModal,
    setChangeCustomerPriceModal,
    idOrder,
  });

  const handlerDelete = async () => {
    try {
      await runDeleteItem({
        variables: {
          id: dataDeleteModal?.selProduct ?? '',
        },
      }).then(() => {
        if (isNewManager) {
          runUpdateOrder({
            variables: {
              id: idOrder,
              data: {
                managers,
              },
              locale: updateSelectLanguage as string,
            },
          });
        }
        refetchOrderItems && refetchOrderItems();
      });
    } catch (error) {
      addNotification({
        messageError: translateLang('errorUpdateAmount'),
        typeMessage: 'error',
      });
    }

    setDataDeleteModal({ selProduct: '', isOpen: false });
  };
  const closeChangeQuantityModal = () => {
    setChangeQuantityModal({
      isOpen: false,
      selProduct: null,
    });
  };

  const closeChangeCustomerPriceModal = () => {
    setChangeCustomerPriceModal({
      isOpen: false,
      selProduct: null,
    });
  };

  return (
    <>
      <Box boxSizing="border-box" width="100%" marginTop="25px">
        <TableProductActive
          itemsRows={getRowsTableActive()}
          itemsHeader={getHeadersTable()}
          loading={orderItemsLoading}
        />
      </Box>
      {dataDeleteModal.isOpen && (
        <CustomModal
          width={350}
          title={`${translateLang('del')}?`}
          handleClose={() => setDataDeleteModal({ selProduct: '', isOpen: false })}
          open={true}
        >
          <Stack flexDirection="row" mt={5}>
            <Button
              onClick={() => setDataDeleteModal({ selProduct: '', isOpen: false })}
              variant="outlined"
              color="secondary"
              style={{
                width: '80%',
                height: '95%',
                marginRight: '2px',
              }}
            >
              <TranslatedField
                originText={translateLang('cancel')}
                fontSize={16}
                isTranslate
                noWrap
              />
            </Button>
            <Button
              onClick={handlerDelete}
              variant="outlined"
              color="error"
              style={{
                width: '80%',
                height: '95%',
                marginLeft: '2px',
              }}
            >
              <TranslatedField originText={translateLang('del')} fontSize={16} isTranslate noWrap />
            </Button>
          </Stack>
        </CustomModal>
      )}
      {changeQuantityModal.isOpen && (
        <ChangeQuantityModal
          dataProduct={changeQuantityModal.selProduct}
          handleClose={closeChangeQuantityModal}
          open={changeQuantityModal.isOpen}
        />
      )}
      {changeCustomerPriceModal.isOpen && (
        <ChangeCustomerPriceModal
          dataProduct={changeCustomerPriceModal.selProduct}
          handleClose={closeChangeCustomerPriceModal}
          open={changeCustomerPriceModal.isOpen}
        />
      )}
      {commentModal.isOpen && (
        <ModalComment
          productID={commentModal?.selProduct?.id ?? ''}
          orderID={idOrder}
          comment={commentModal.selProduct?.comment ?? ''}
          handleClose={() =>
            setCommentModal({
              isOpen: false,
              selProduct: {
                id: '',
                comment: '',
              },
            })
          }
        />
      )}
    </>
  );
};
