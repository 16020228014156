import React, { FC, useRef, useState } from 'react';
import { Add, AddCircleOutline, CheckBox, Edit } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, Tooltip } from '@mui/material';

import {
  BoxCell,
  HeaderCell,
  TableCellsValue,
  TableProduct,
} from '../../../components/TableProduct/TableProduct';
import logo from '../../../assets/logotype.svg';
import { CustomAvatar, CustomModal } from '../../../components';
import { PAGE_COUNT } from '../../../helpers/constants';
import { useLocalization } from '../../../localization';
import { AddStockInput, GeneralSparesEntity } from '../../../__generated__/types';
import { getFittingPosition, handlerError } from '../../../helpers/functions';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import {
  ExtendedSearchProductsResult,
  SearchedContentNotOrder,
} from '../../Warehouse/components/PrintModal/SearchedContentNotOrder';
import { useHandlerNotificationApp } from '../../../hooks/useHandlerNotificationApp';
import { useAddStockItemMutation } from '../../../graphql/mutations/__generated__/addStockItem';
import { AddCapacityModal } from '../AddCapacityModal';

interface Props {
  page: number;
  loading: boolean;
  totalPages: number;
  currentPage: number;
  generalSparesData?: GeneralSparesEntity[];
  changePage?: (value: number) => void;
  handleProductId: (id: string) => void;
}

export const ProductCatalogTable: FC<Props> = ({
  page,
  loading,
  totalPages,
  currentPage,
  generalSparesData,
  changePage,
  handleProductId,
}) => {
  const { translateLang } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();

  const [addStockItemMutation] = useAddStockItemMutation();

  const productsIDsRef = useRef<Array<string>>([]);

  const generalSparesDataLength = generalSparesData?.length || 0;

  const [product, setProduct] = useState<GeneralSparesEntity | null>(null);

  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      { title: translateLang('status'), containerProps: { align: 'center', width: '50px' } },
      { title: translateLang('photo'), containerProps: { width: '100px' } },
      { title: translateLang('article') },
      { title: translateLang('productName') },
      {
        title: translateLang('fittingPosition'),
        containerProps: { align: 'center', width: '150px' },
      },
      { title: translateLang('manufacturer') },
      { title: translateLang('action'), containerProps: { width: '40px' } },
    ];
  };

  const getRowsTable = (): Array<TableCellsValue> => {
    productsIDsRef.current = [];
    const start = page * PAGE_COUNT;
    const length =
      start + PAGE_COUNT < generalSparesDataLength
        ? PAGE_COUNT + start
        : generalSparesDataLength + start;

    const rows: Array<TableCellsValue> = [];

    for (let index = start; index < length; index++) {
      const _values: Array<BoxCell> = [];
      const indexGeneralSpare = index % 10;

      const product = generalSparesData?.[indexGeneralSpare];
      const fittingPosition = getFittingPosition(product?.attributes?.articleCriteria);

      _values.push({
        val: <CheckBox style={{ color: product?.attributes?.isDraft ? '#8D8D8D' : '#97A408' }} />,
        containerProps: { borderLeft: 'none' },
      });
      _values.push({
        val: (
          <Stack flexDirection="row" gap={1} position="relative">
            <Box
              width="0.2px"
              height={40}
              bgcolor="#8D8D8D"
              position="absolute"
              left={-10}
              top="50%"
              sx={{ transform: 'translateY(-50%)' }}
            />
            <CustomAvatar
              alt="Remy Sharp"
              src={product?.attributes?.images[0]?.imageURL200}
              logo={logo}
              variant="rounded"
              sx={{
                width: '100%',
                height: '100%',
                '& img': {
                  objectFit: 'contain',
                },
              }}
            />
          </Stack>
        ),
        containerProps: { borderLeft: 'none' },
      });
      _values.push({ val: product?.attributes?.tecdoc_articleNumber });
      _values.push({ val: product?.attributes?.title });
      _values.push({ val: fittingPosition });
      _values.push({ val: product?.attributes?.brandName });
      _values.push({
        val: (
          <Stack flexDirection="row" gap={1} position="relative">
            <Box
              width="0.2px"
              height={40}
              bgcolor="#8D8D8D"
              position="absolute"
              left={-16}
              top="50%"
              sx={{ transform: 'translateY(-50%)' }}
            />
            <Tooltip
              title={<TranslatedField originText={'edit'} fontSize={16} isTranslate noWrap />}
              arrow
            >
              <IconButton
                onClick={() => {
                  handleProductId(product?.id as string);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                <TranslatedField originText={'addСapacity'} fontSize={16} isTranslate noWrap />
              }
              arrow
            >
              <IconButton
                onClick={() => setProduct(product ?? null)}
                sx={{
                  color: '#97A408',
                }}
              >
                <AddCircleOutline />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
        containerProps: { justifyContent: 'center', borderLeft: 'none' },
      });

      rows.push({ cellsValues: _values });
    }
    for (let index = 0; index < generalSparesDataLength; index++) {
      const id = generalSparesData?.[index]?.id;
      if (id) {
        productsIDsRef.current.push(id);
      }
    }

    return rows;
  };

  return (
    <>
      <TableProduct
        totalPages={totalPages}
        startPage={currentPage}
        itemsRows={getRowsTable()}
        itemsHeader={getHeadersTable()}
        changeCurrentPage={changePage}
        loading={loading}
      />
      {product && (
        <AddCapacityModal
          isOpen={!!product}
          closeModal={() => setProduct(null)}
          product={product}
        />
      )}
    </>
  );
};
