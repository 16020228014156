import React from 'react';

import { useLocalization } from '../../../../../localization';

export const useIncomingInvoiceStatuses = () => {
  const { translateLang } = useLocalization();
  const incomingInvoiceStatuses = [translateLang('formed'), translateLang('forming')];

  return {
    incomingInvoiceStatuses,
  };
};
