import React, { FC, useState } from 'react';
import { Box, IconButton, Popover, Typography } from '@mui/material';
import { TableProductActive } from '../../../../../../components/TableProductActive/TableProductActive';
import { RemoveRedEyeOutlined } from '@mui/icons-material';

import { ReviewsType } from '../../../../../../graphql/queries/hook/useGetReviews';
import { REVIEWSPUBSTATUS, StatusSelect } from '../StatusSelect';
import { useLocalization } from '../../../../../../localization';
import { ReviewModal } from '../ReviewModal';
interface Props {
  item: ReviewsType;
}
export const ReviewsRowItem: FC<Props> = ({ item }) => {
  const [reviewModal, setReviewModal] = useState<ReviewsType | null>(null);
  const closeModal = () => {
    setReviewModal(null);
  };
  const { translateLang } = useLocalization();
  const openModal = (props: ReviewsType) => {
    setReviewModal({
      ...props,
    });
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const trimReviewText = (reviewText: string) => {
    return reviewText.substring(0, 50).trim() + '...';

    return reviewText;
  };
  const open = Boolean(anchorEl);
  return (
    <TableProductActive.RowTable>
      <TableProductActive.CellTable ceilStyle={{ width: '20px', borderLeft: 'none' }}>
        {item.id}
      </TableProductActive.CellTable>

      <TableProductActive.CellTable>{item.date}</TableProductActive.CellTable>
      <TableProductActive.CellTable>{item.spareName}</TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ alignItems: 'center' }}>
        {item.reviewsUserName}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ alignItems: 'center' }}>
        {`${item.rating} ${translateLang('stars')}`}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ alignItems: 'center' }}>
        {item.reviewText && item.reviewText.length > 100 ? (
          <div>
            <p
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              {trimReviewText(item.reviewText || '')}
            </p>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: 'none',
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <div
                style={{
                  width: '400px',
                  minHeight: '200px',
                  overflow: 'auto',
                  padding: '10px',
                  backgroundColor: 'white',
                }}
              >
                {item.reviewText}
              </div>
            </Popover>
          </div>
        ) : (
          <div>{item.reviewText}</div>
        )}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ alignItems: 'center' }}>
        <StatusSelect
          currentStatus={
            item.statusPublication ? REVIEWSPUBSTATUS.Published : REVIEWSPUBSTATUS.Unpublished
          }
          itemId={item.id || ''}
        />
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ borderLeft: 'none', alignItems: 'end' }}>
        <Box bgcolor="#e7eef4" borderRadius={2}>
          <IconButton
            onClick={() =>
              openModal({
                date: item.date,
                id: item.id,
                rating: item.rating,
                repliesUserName: item.repliesUserName,
                reviewsUserName: item.reviewsUserName,
                reviewText: item.reviewText,
                spareName: item.spareName,
                statusPublication: item.statusPublication,
              })
            }
          >
            <RemoveRedEyeOutlined fontSize="small" sx={{ color: '#5269A3' }} />
          </IconButton>
        </Box>
      </TableProductActive.CellTable>
      {reviewModal && <ReviewModal reviewData={item} open={true} handleClose={closeModal} />}
    </TableProductActive.RowTable>
  );
};
