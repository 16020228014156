import React from 'react';
import { InputAdornment, Tooltip } from '@mui/material';
import { Info } from '@mui/icons-material';

import { TranslatedField } from './Layout/components/TranslatedField/TranslatedField';

export const HelpSearchOrder = () => (
  <InputAdornment position="start">
    <Tooltip
      title={
        <TranslatedField
          originText={'toSearchByAdditionalOrderNumberUse'}
          fontSize={16}
          isTranslate
          noWrap={false}
        />
      }
      arrow
    >
      <Info />
    </Tooltip>
  </InputAdornment>
);
