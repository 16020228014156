import React from 'react';
import { Stack } from '@mui/material';
import { useLocalization } from '../../../../../localization';
import { ModalTypography } from '../../PrintModal/ModalTypography';
import { ReturnedType } from '../../../../../graphql/queries/hook/useGetOrderItemsReturnedFromCustomers';

export const ReturnInformComponent = ({ returnedItem }: { returnedItem: ReturnedType }) => {
  const { translateLang } = useLocalization();

  return (
    <Stack textAlign="left" spacing={2}>
      <ModalTypography
        label={`${translateLang('productName')}`}
        value={returnedItem?.productName as string}
      />
      <ModalTypography label={`${translateLang('article')}`} value={returnedItem?.article} />
      <ModalTypography label={`${translateLang('orders')}`} value={returnedItem?.orderNumber} />
      <ModalTypography
        label={`${translateLang('client')}`}
        value={returnedItem?.customerName || ''}
      />
      <ModalTypography label={`${translateLang('provider')}`} value={returnedItem?.provider} />
      <ModalTypography label={`${translateLang('returnTo')}`} value={returnedItem?.returnTo} />
    </Stack>
  );
};
