import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { FC } from 'react';
import { useLocalization } from '../../../localization';
import {
  Enum_Contactnotification_Status,
  Enum_Newslettersubscription_Status,
  Enum_Nosparepart_Status,
} from '../../../__generated__/types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type SelectProps = {
  status:
    | Enum_Newslettersubscription_Status
    | Enum_Nosparepart_Status
    | Enum_Contactnotification_Status;
  data:
    | Enum_Newslettersubscription_Status[]
    | Enum_Nosparepart_Status[]
    | Enum_Contactnotification_Status[];
  handleChange: (event: SelectChangeEvent) => void;
};

export const Selectus: FC<SelectProps> = ({ status, data, handleChange }) => {
  const { translateLang } = useLocalization();

  let color = '';
  switch (status) {
    case 'archived':
      color = '#9C9C9C';
      break;
    case 'processed':
      color = '#97A408';
      break;
    case 'inProcess':
      color = '#97A408';
      break;
    case 'new':
      color = '#5269A3';
      break;
    case 'unprocessed':
      color = '#F44336';
      break;
  }
  return (
    <Select
      IconComponent={ExpandMoreIcon}
      value={status}
      onChange={handleChange}
      fullWidth
      sx={{
        backgroundColor: 'white',
        color: color,
        height: '30px',
        width: '150px',
        textAlign: 'center',
        '&:before': {
          borderColor: color,
          color: color,
        },
        '&:after': {
          borderColor: color,
        },
        '&:not(.Mui-disabled):hover::before': {
          borderColor: color,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: color,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: color,
          backgroundColor: 'transparent',
        },
        '& .MuiSvgIcon-root': {
          color: color,
        },
      }}
    >
      {data.map(item => (
        <MenuItem key={item} value={item}>
          {translateLang(`${item}`)}
        </MenuItem>
      ))}
    </Select>
  );
};
