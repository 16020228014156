import React, { FC } from 'react';
import { Box } from '@mui/material';

import { useGetSupplierBalanceTable } from './hooks/useGetSupplierBalanceTable';
import { TableProductActive } from '../../../../components/TableProductActive/TableProductActive';
import { SupplierTransactionEntity } from '../../../../__generated__/types';

interface Props {
  totalPage?: number;
  transactions?: SupplierTransactionEntity[];
  currentPage?: number;
  changePage(page: number): void;
  loading?: boolean;
  action: (transaction: SupplierTransactionEntity, type: 'edit' | 'open' | 'delete') => void;
}

export const SupplierBalanceTable: FC<Props> = ({
  transactions,
  totalPage,
  currentPage,
  changePage,
  loading,
  action,
}) => {
  const { getHeadersTable, getRowsTableActive } = useGetSupplierBalanceTable({
    transactions,
    action,
  });

  return (
    <Box boxSizing="border-box" width="100%">
      <TableProductActive
        startPage={currentPage}
        totalPages={totalPage}
        itemsRows={getRowsTableActive()}
        itemsHeader={getHeadersTable()}
        changeCurrentPage={changePage}
        loading={loading}
      />
    </Box>
  );
};
