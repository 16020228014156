import * as yup from 'yup';

import { IncomingInvoicesFields } from '../type';
import { useLocalization } from '../../../../../localization';

export const useIncomingInvoicesValidation = () => {
  const { translateLang } = useLocalization();

  const numberValidation = yup.string().required(translateLang('emptyInputError'));
  const dateValidation = yup.date().required(translateLang('emptyInputError'));
  const supplierValidation = yup.string().required(translateLang('emptyInputError'));
  const actualAmountValidation = yup
    .number()
    .typeError(translateLang('mustBeNumber'))
    .required(translateLang('emptyInputError'));
  const statusValidation = yup.string().required(translateLang('emptyInputError'));

  const validationSchema = yup.object({
    [IncomingInvoicesFields.Number]: numberValidation,
    [IncomingInvoicesFields.Date]: dateValidation,
    [IncomingInvoicesFields.Supplier]: supplierValidation,
    [IncomingInvoicesFields.ActualAmount]: actualAmountValidation,
    [IncomingInvoicesFields.Status]: statusValidation,
  });

  return {
    validationSchema,
  };
};
