import React, { FC, Fragment, useEffect, useMemo } from 'react';
import { Box, List, ListItem, ListItemButton, ListItemText } from '@mui/material';

import { SecondaryCategories } from './SecondaryCategories';
import { useGetCategoryTreeQuery } from '../../../graphql/queries/__generated__/getCategoryTree';
import { useLocalization } from '../../../localization';

interface Props {
  secondCategory: (
    tecdoc_categoryId: string,
    nameCategory: string,
    nameSubCategory: string
  ) => void;
  category?: string;
  idCategory?: string | null;
}

export const SubCategory: FC<Props> = ({ idCategory, secondCategory }) => {
  const { updateSelectLanguage } = useLocalization();

  const { data: dataSubCategories, refetch: refetchCategoryTree } = useGetCategoryTreeQuery({
    variables: {
      input: {
        locale: updateSelectLanguage,
        levelFrom: 1,
        levelTo: 2,
      },
    },
  });

  useEffect(() => {
    refetchCategoryTree({
      input: {
        locale: updateSelectLanguage,
        levelFrom: 1,
        levelTo: 2,
      },
    });
  }, []);

  const dataSubCategory = useMemo(
    () =>
      dataSubCategories?.getCategoryTree?.categoryTree
        ?.filter(item => item?.parentId === idCategory)
        .map(item => {
          return {
            ...item,
          };
        }),
    [dataSubCategories?.getCategoryTree?.categoryTree, idCategory]
  );

  const length = dataSubCategory?.length || 0;
  return (
    <>
      {dataSubCategory?.map((item, indexItems) => (
        <Fragment key={item?.id || indexItems.toString()}>
          <Box
            display="flex"
            flexDirection="column"
            minWidth="200px"
            width={`calc(100% / ${length} - ${26 * (length - 1)}px)`}
          >
            <List disablePadding>
              <ListItem>
                <ListItemButton
                  onClick={() => secondCategory(item.id || '', item?.name ?? '', '')}
                  component="button"
                >
                  <ListItemText sx={{ color: 'black' }} primary={item?.name} />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <SecondaryCategories
                  idCategory={item?.id}
                  secondCategory={secondCategory}
                  subCategoryName={item?.name || ''}
                />
              </ListItem>
            </List>
          </Box>
          {length > 1 && length - 1 !== indexItems && <hr />}
        </Fragment>
      ))}
    </>
  );
};
