import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type UpdateSupplierPaymentMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  data: Types.SupplierPaymentInput;
}>;


export type UpdateSupplierPaymentMutation = { __typename?: 'Mutation', updateSupplierPayment?: { __typename?: 'SupplierPaymentEntityResponse', data?: { __typename?: 'SupplierPaymentEntity', id?: string | null } | null } | null };


export const UpdateSupplierPaymentDocument = gql`
    mutation updateSupplierPayment($id: ID!, $data: SupplierPaymentInput!) {
  updateSupplierPayment(id: $id, data: $data) {
    data {
      id
    }
  }
}
    `;
export type UpdateSupplierPaymentMutationFn = Apollo.MutationFunction<UpdateSupplierPaymentMutation, UpdateSupplierPaymentMutationVariables>;

/**
 * __useUpdateSupplierPaymentMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierPaymentMutation, { data, loading, error }] = useUpdateSupplierPaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSupplierPaymentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSupplierPaymentMutation, UpdateSupplierPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSupplierPaymentMutation, UpdateSupplierPaymentMutationVariables>(UpdateSupplierPaymentDocument, options);
      }
export type UpdateSupplierPaymentMutationHookResult = ReturnType<typeof useUpdateSupplierPaymentMutation>;
export type UpdateSupplierPaymentMutationResult = Apollo.MutationResult<UpdateSupplierPaymentMutation>;
export type UpdateSupplierPaymentMutationOptions = Apollo.BaseMutationOptions<UpdateSupplierPaymentMutation, UpdateSupplierPaymentMutationVariables>;