import React, { useCallback, useRef } from 'react';
import { Stack } from '@mui/material';
import { useGetIsMobileQuery } from '../../../graphql/cacheQuery/__generated__/getIsMobile';
import { InProgressOrderDetailBlockInfoDesktop } from './OrderHistory/components/InProgressOrderDetailBlockInfoDesktop';
import { CompletedOrderDetailBlockInfoDesktop } from './OrderHistory/components/CompletedOrderDetailBlockInfoDesktop';
import { InProgressOrderDetailBlockInfoPhone } from './OrderHistory/components/InProgressOrderDetailBlockInfoPhone';
import {
  DeliveryNote,
  DeliveryNoteProps,
} from '../../Warehouse/components/OrderTab/components/PreDelivery/DeliveryNote';

export const OrdersHistory = () => {
  const deliveryNoteRef = useRef<DeliveryNoteProps | null>(null);

  const { data } = useGetIsMobileQuery();
  const isMobile = data?.isMobile ?? false;

  const printDeliveryNote = useCallback((orderId: string) => {
    if (deliveryNoteRef.current) {
      deliveryNoteRef.current.print(orderId);
    }
  }, []);

  return (
    <>
      <Stack width="100%" marginBottom="50px">
        {isMobile ? (
          <InProgressOrderDetailBlockInfoPhone isMobile={isMobile} />
        ) : (
          <>
            <InProgressOrderDetailBlockInfoDesktop
              isMobile={isMobile}
              printDeliveryNote={printDeliveryNote}
            />
            <CompletedOrderDetailBlockInfoDesktop printDeliveryNote={printDeliveryNote} />
            <DeliveryNote ref={deliveryNoteRef} />
          </>
        )}
      </Stack>
    </>
  );
};
