import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { CustomModal } from '../../../../components';
import { useLocalization } from '../../../../localization';

interface Props {
  closeModal(): void;
  openModal: boolean;
  title: string;
}

export const SuccessModal: FC<Props> = ({ openModal, closeModal, title }) => {
  const { translateLang } = useLocalization();
  return (
    <Stack>
      <CustomModal
        title={translateLang(title)}
        handleClose={closeModal}
        open={openModal}
      ></CustomModal>
    </Stack>
  );
};
