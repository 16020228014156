import {
  Box,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { VERSION_APP } from '../../../helpers/constants';
import { useAuthUser } from '../../../hooks';
import { useLocalization } from '../../../localization';
import { Icon } from '../../../legos';

import { LANGS } from '../../../localization/languages';
import { Enum_Language_Type } from '../../../__generated__/types';
import { MobileSelect } from '../../../legos/MobileSelect';
interface Props {
  open: boolean;
  path: string;
  handleDrawerOpen: Dispatch<SetStateAction<boolean>>;
}
export const MobileDrawer: FC<Props> = ({ handleDrawerOpen, open, path }) => {
  const { logout } = useAuthUser();
  const { setCurrentLanguage, selectLanguages, translateLang } = useLocalization();

  const [currentValue, setCurrentValue] = useState(LANGS[0]);

  const handleChange = (event: SelectChangeEvent) => {
    setCurrentLanguage(event.target.value as Enum_Language_Type);
    setCurrentValue(event.target.value as string);
  };
  return (
    <Drawer
      open={open}
      onClose={() => handleDrawerOpen(false)}
      PaperProps={{
        sx: {
          backgroundColor: '#2A3453',
          color: 'red',
        },
      }}
      sx={{
        flexShrink: 0,
        whiteSpace: 'nowrap',
      }}
    >
      <Box
        flex={1}
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        paddingBottom="60px"
      >
        <Stack direction="row" alignItems="center" px="10px">
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              onClick={() => logout()}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
              }}
            >
              <ListItemText
                primary={translateLang('logout')}
                sx={{ color: 'white', opacity: open ? 1 : 0 }}
              />
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  justifyContent: 'center',
                  color: 'white',
                  padding: 1,
                }}
              >
                <Icon icon="logout" />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <MobileSelect
              minWidth={60}
              height="30px"
              items={LANGS}
              value={selectLanguages}
              currentValue={currentValue}
              handleChange={handleChange}
            />
          </ListItem>
        </Stack>

        <Typography fontSize="12px" color="#e0e0e0">{`v: ${VERSION_APP}`}</Typography>
      </Box>
    </Drawer>
  );
};
