import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { ModalTypography } from './ModalTypography';
import { useLocalization } from '../../../../localization';
import { AddStockFromOrderItemResult, AddStockResult } from '../../../../__generated__/types';

type ProductType = AddStockFromOrderItemResult | AddStockResult;
interface Props {
  dataProduct?: ProductType | null;
}
export const InformComponent: FC<Props> = ({ dataProduct }) => {
  const { translateLang } = useLocalization();
  return (
    <Stack textAlign="left" spacing={2}>
      <ModalTypography label={`${translateLang('goods')}`} value={dataProduct?.stockData?.title} />
      <ModalTypography
        label={`${translateLang('article')}`}
        value={dataProduct?.stockData?.articleNumber}
      />
      <ModalTypography
        label={`${translateLang('provider')}`}
        value={dataProduct?.stockData?.supplierName}
      />
      {dataProduct?.__typename === 'AddStockFromOrderItemResult' ? (
        <>
          <ModalTypography
            label={`${translateLang('order')}`}
            value={'#' + dataProduct?.orderData?.orderId}
          />
          <ModalTypography label={`${translateLang('completing')}`} value="test" />
          <ModalTypography
            label={`${translateLang('client')}`}
            value={'#' + dataProduct?.orderData?.customerId}
          />
        </>
      ) : null}
    </Stack>
  );
};
