import React, { FC, useState } from 'react';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { Button, CircularProgress, Fade, IconButton, Modal, Stack } from '@mui/material';

import {
  Enum_Supplierpayment_Paymentdirection,
  Enum_Supplierpayment_Paymentmethod,
} from '../../../../../../__generated__/types';
import { SupplierPaymentFields } from './types';
import { theme } from '../../../../../../helpers';
import { useLocalization } from '../../../../../../localization';
import { CalendarMUI, Icon, Input } from '../../../../../../legos';
import { useTransactionValidation } from './hooks/useTransactionValidation';
import { useHandlerNotificationApp } from '../../../../../../hooks/useHandlerNotificationApp';
import { TranslatedField } from '../../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useCreateSupplierPaymentMutation } from '../../../../../../graphql/mutations/__generated__/createSupplierPayment';
import { ArrowBack } from '@mui/icons-material';
import { formatCurrencyAmount } from '../../../../../../helpers/functions';
import { useUpdateSupplierPaymentMutation } from '../../../../../../graphql/mutations/__generated__/updateSupplierPayment';

interface Props {
  supplierId?: string;
  isModalOpen: boolean;
  handleClose: () => void;
  initPaymentDirection?: Enum_Supplierpayment_Paymentdirection | null;
  refresh: () => void;
  editMode?: boolean;
  initialValues?: {
    id: string | null;
    [SupplierPaymentFields.Amount]: string;
    [SupplierPaymentFields.Date]: Date;
    [SupplierPaymentFields.Description]: string;
    paymentMethod: Enum_Supplierpayment_Paymentmethod | null;
    paymentDirection: Enum_Supplierpayment_Paymentdirection | null;
  };
}

const newInitialValues = {
  id: null,
  [SupplierPaymentFields.Amount]: '',
  [SupplierPaymentFields.Date]: new Date(),
  [SupplierPaymentFields.Description]: '',
  paymentMethod: null,
  paymentDirection: null,
};

export const SupplierPaymentForm: FC<Props> = ({
  handleClose,
  supplierId,
  refresh,
  isModalOpen,
  editMode,
  initialValues = newInitialValues,
}) => {
  const { translateLang } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();
  const [paymentDirection, setPaymentDirection] =
    useState<Enum_Supplierpayment_Paymentdirection | null>(initialValues.paymentDirection);

  const [createSupplierPaymentMutation, { loading: createPaymentLoading }] =
    useCreateSupplierPaymentMutation();
  const [updateSupplierPaymentMutation, { loading: updatePaymentLoading }] =
    useUpdateSupplierPaymentMutation();
  const { validationSchema } = useTransactionValidation();

  const [paymentMethod, setPaymentMethod] = useState<null | Enum_Supplierpayment_Paymentmethod>(
    initialValues.paymentMethod
  );

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, setFieldError } =
    useFormik({
      initialValues: {
        id: initialValues.id,
        [SupplierPaymentFields.Amount]: initialValues[SupplierPaymentFields.Amount],
        [SupplierPaymentFields.Date]: initialValues[SupplierPaymentFields.Date],
        [SupplierPaymentFields.Description]: initialValues[SupplierPaymentFields.Description],
      },
      validationSchema,
      onSubmit: ({ id, amount, date, description }) => {
        const newTotal = parseFloat(amount);
        const oldTotal = parseFloat(initialValues[SupplierPaymentFields.Amount]);

        const formattedDate = format(date, 'yyyy-MM-dd');
        if (editMode && id) {
          updateSupplierPaymentMutation({
            variables: {
              id,
              data: {
                incomingInvoicePayments: newTotal < oldTotal ? [] : undefined,
                comment: description,
                total: newTotal,
                paymentDate: formattedDate,
                paymentMethod: paymentMethod,
                paymentDirection: paymentDirection,
              },
            },
          })
            .then(() => {
              addNotification({
                messageError: translateLang('updatedSuccessfully'),
                typeMessage: 'success',
              });
              refresh();
              handleClose();
            })
            .catch(err => {
              addNotification({ messageError: err.message, typeMessage: 'error' });
              setFieldError(SupplierPaymentFields.Amount, err.message);
            });
        } else {
          createSupplierPaymentMutation({
            variables: {
              data: {
                comment: description,
                paymentDate: formattedDate,
                total: +amount,
                paymentDirection,
                paymentMethod: paymentMethod as Enum_Supplierpayment_Paymentmethod,
                supplier: supplierId as string,
              },
            },
          })
            .then(() => {
              addNotification({
                messageError: translateLang('paymentWasSuccessful'),
                typeMessage: 'success',
              });
              refresh();
              handleClose();
            })
            .catch(err => {
              addNotification({ messageError: err.message, typeMessage: 'error' });
              setFieldError(SupplierPaymentFields.Amount, err.message);
            });
        }
      },
    });

  const handleCashPay = () => {
    setPaymentMethod(Enum_Supplierpayment_Paymentmethod.Cash);
  };

  const handleCashlessPay = () => {
    setPaymentMethod(Enum_Supplierpayment_Paymentmethod.NonCash);
  };

  const handleDateChange = (newValue: Date | null) => {
    setFieldValue(SupplierPaymentFields.Date, newValue);
  };

  const handlePaymentDirection = (direction: Enum_Supplierpayment_Paymentdirection | null) =>
    setPaymentDirection(direction);

  return (
    <Modal open={true} onClose={handleClose} closeAfterTransition keepMounted={false}>
      <Fade in={isModalOpen}>
        <Stack
          bgcolor={theme.palette.common.lightGrey}
          sx={{
            p: 4,
            top: '50%',
            width: 400,
            left: '50%',
            boxShadow: 24,
            borderRadius: 2,
            position: 'absolute' as const,
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Stack alignItems="center">
            <Stack width="100%" flexDirection="row" justifyContent="space-between" mb={2}>
              {paymentDirection && !editMode ? (
                <IconButton onClick={() => handlePaymentDirection(null)} sx={{ p: 0 }}>
                  <ArrowBack />
                </IconButton>
              ) : (
                <div />
              )}
              <IconButton onClick={handleClose} sx={{ p: 0 }}>
                <Icon icon="close" sx={{ color: theme.palette.primary.main }} />
              </IconButton>
            </Stack>
            <Stack marginBottom={2.5} alignItems="center">
              <TranslatedField
                isTranslate
                variant="h4"
                color={theme.palette.common.darkBlue}
                fontSize={30}
                originText={editMode ? 'editTransaction' : 'newTransaction'}
              />
              {paymentDirection ? (
                <TranslatedField
                  originText={
                    paymentDirection === 'incoming' ? 'incomingPayment' : 'outgoingPayment'
                  }
                  fontSize={16}
                  isTranslate
                  noWrap
                />
              ) : null}
            </Stack>
            {!paymentDirection ? (
              <Stack flexDirection="row" width="100%" gap={1.25}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    height: 40,
                    p: 2,
                    textTransform: 'none',
                    backgroundColor: theme.palette.common.white,
                    borderRadius: '5px',
                    color: theme.palette.common.black,
                    boxShadow: 'none',
                    '&:hover': { color: theme.palette.common.white },
                  }}
                  onClick={() => {
                    handlePaymentDirection(Enum_Supplierpayment_Paymentdirection.Incoming);
                  }}
                >
                  <TranslatedField
                    originText={'incomingPayment'}
                    fontSize={16}
                    isTranslate
                    noWrap
                  />
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    height: 40,
                    p: 2,
                    textTransform: 'none',
                    backgroundColor: theme.palette.common.white,
                    borderRadius: '5px',
                    color: theme.palette.common.black,
                    boxShadow: 'none',
                    '&:hover': { color: theme.palette.common.white },
                  }}
                  onClick={() => {
                    handlePaymentDirection(Enum_Supplierpayment_Paymentdirection.Outgoing);
                  }}
                >
                  <TranslatedField
                    originText={'outgoingPayment'}
                    fontSize={16}
                    isTranslate
                    noWrap
                  />
                </Button>
              </Stack>
            ) : (
              <>
                <Stack flexDirection="row" width="100%" gap={2} mb={2}>
                  <Button
                    variant="contained"
                    fullWidth
                    startIcon={
                      paymentMethod === 'cash' && (
                        <Icon icon="done" sx={{ fontSize: '14px !important' }} />
                      )
                    }
                    sx={{
                      height: 40,
                      width: 210,
                      p: 2,
                      textTransform: 'none',
                      backgroundColor:
                        paymentMethod === 'cash'
                          ? theme.palette.secondary.main
                          : theme.palette.common.white,
                      borderRadius: '5px',
                      color:
                        paymentMethod === 'cash'
                          ? theme.palette.common.white
                          : theme.palette.common.black,
                      boxShadow: 'none',
                      '&:hover': { color: theme.palette.common.white },
                    }}
                    onClick={handleCashPay}
                  >
                    <TranslatedField
                      originText={'cash'}
                      fontSize={16}
                      isTranslate
                      noWrap
                      overflow="initial"
                    />
                  </Button>
                  <Button
                    variant="contained"
                    fullWidth
                    startIcon={
                      paymentMethod === Enum_Supplierpayment_Paymentmethod.NonCash && (
                        <Icon icon="done" sx={{ fontSize: '14px !important' }} />
                      )
                    }
                    sx={{
                      height: 40,
                      width: 210,

                      p: 2,
                      textTransform: 'none',
                      backgroundColor:
                        paymentMethod === Enum_Supplierpayment_Paymentmethod.NonCash
                          ? theme.palette.secondary.main
                          : theme.palette.common.white,

                      borderRadius: '5px',
                      color:
                        paymentMethod === Enum_Supplierpayment_Paymentmethod.NonCash
                          ? theme.palette.common.white
                          : theme.palette.common.black,
                      boxShadow: 'none',
                      '&:hover': { color: theme.palette.common.white },
                    }}
                    onClick={handleCashlessPay}
                  >
                    <TranslatedField
                      originText={'bankTransfer'}
                      fontSize={16}
                      isTranslate
                      noWrap
                      overflow="initial"
                    />
                  </Button>
                </Stack>

                <Stack component="form" width="100%" mb={2} onSubmit={handleSubmit}>
                  <Stack
                    height={75}
                    justifyContent="space-between"
                    flexDirection="row"
                    alignItems="baseline"
                    gap={3}
                    mb={2}
                  >
                    <TranslatedField
                      originText={'amount'}
                      fontSize={16}
                      isTranslate
                      noWrap
                      overflow="initial"
                    />
                    <Input
                      name={SupplierPaymentFields.Amount}
                      value={`${values[SupplierPaymentFields.Amount]}€`}
                      onChange={event => {
                        const value = formatCurrencyAmount(event.target.value);

                        setFieldValue(SupplierPaymentFields.Amount, value);
                      }}
                      error={
                        touched[SupplierPaymentFields.Amount] &&
                        !!errors[SupplierPaymentFields.Amount]
                      }
                      helperText={
                        touched[SupplierPaymentFields.Amount] &&
                        errors[SupplierPaymentFields.Amount]
                          ? errors[SupplierPaymentFields.Amount]
                          : editMode &&
                            parseFloat(values[SupplierPaymentFields.Amount]) <
                              parseFloat(initialValues[SupplierPaymentFields.Amount])
                          ? translateLang('allInvoicesLinkedToThisPaymentWillBeUnlinked')
                          : ''
                      }
                      inputProps={{
                        style: {
                          textAlign: 'center',
                          paddingLeft: 0,
                          color: theme.palette.common.black,
                          boxShadow: ' 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset',
                          height: 12,
                        },
                      }}
                      bgcolor={theme.palette.common.white}
                      sx={{
                        ml: 'auto',
                        maxWidth: '280px',
                      }}
                    />
                  </Stack>
                  <Stack
                    height={75}
                    justifyContent="space-between"
                    flexDirection="row"
                    alignItems="baseline"
                    gap={3}
                  >
                    <TranslatedField
                      originText={'date'}
                      fontSize={16}
                      isTranslate
                      noWrap
                      overflow="initial"
                    />

                    <CalendarMUI
                      disableFuture
                      value={values[SupplierPaymentFields.Date]}
                      isLabel={false}
                      setValue={handleDateChange}
                      inputStyle={{
                        bgcolor: theme.palette.common.white,
                        height: 40,
                        width: 180,
                        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset',
                        p: 0,
                        '& .MuiInputBase-root': {
                          p: '5px 15px',
                        },
                      }}
                    />
                  </Stack>

                  <Stack
                    width="100%"
                    height={75}
                    justifyContent="space-between"
                    flexDirection="row"
                    alignItems="baseline"
                    gap={3}
                  >
                    <TranslatedField
                      originText={'comment'}
                      fontSize={16}
                      isTranslate
                      noWrap
                      overflow="initial"
                    />
                    <Input
                      name={SupplierPaymentFields.Description}
                      value={values[SupplierPaymentFields.Description]}
                      onChange={handleChange}
                      error={
                        touched[SupplierPaymentFields.Description] &&
                        !!errors[SupplierPaymentFields.Description]
                      }
                      helperText={
                        touched[SupplierPaymentFields.Description] &&
                        errors[SupplierPaymentFields.Description]
                      }
                      inputProps={{
                        style: {
                          color: theme.palette.common.black,
                          boxShadow: ' 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset',
                          height: 12,
                        },
                      }}
                      bgcolor={theme.palette.common.white}
                      sx={{
                        ml: 'auto',
                        maxWidth: '271px',
                      }}
                    />
                  </Stack>

                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={!paymentMethod || createPaymentLoading || updatePaymentLoading}
                    startIcon={
                      createPaymentLoading && updatePaymentLoading ? (
                        <CircularProgress size={20} />
                      ) : undefined
                    }
                    sx={{
                      height: 50,
                      p: 2,
                      textTransform: 'none',
                      backgroundColor: '#5269A3',
                      borderRadius: '10px',
                      '&:disabled': {
                        color: theme.palette.common.white,
                      },
                    }}
                  >
                    <TranslatedField
                      originText={editMode ? 'update' : 'save'}
                      fontSize={16}
                      isTranslate
                      noWrap
                    />
                  </Button>
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
      </Fade>
    </Modal>
  );
};
