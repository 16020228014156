import React, { useState } from 'react';

import { useLocalization } from '../../localization';
import ProductCatalog from './components/ProductCatalog';
import { TabsContainer } from './components/TabsContainer';
import { WrapperPages } from '../../components/Wrapper/WrapperPages';
import { AddEditProductForm } from './components/AddEditProductForm';
import { useNavigate } from 'react-router-dom';
import { PatchesName } from '../../types/types';
export const ManagerPanelPage = () => {
  const { translateLang } = useLocalization();
  const navigate = useNavigate();
  const [isAddProductOpen, setIsAddProductOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<string | null>('productCatalog');

  const [productId, setProductId] = useState<string | null>(null);

  const getActiveTab = () => {
    switch (activeTab) {
      case 'productCatalog':
        return <ProductCatalog handleProductId={handleProductId} />;

      default:
        return <ProductCatalog handleProductId={handleProductId} />;
    }
  };

  const handleTabChange = (val?: string) => {
    if (val) {
      setActiveTab(val);
    }
  };

  const handleOpenAddProduct = () => {
    navigate(`/${PatchesName.ManagerPanel}/new`);
  };
  const handleCloseAddProduct = () => {
    setIsAddProductOpen(false);
    setProductId(null);
  };

  const handleProductId = (id: string) => {
    setProductId(id);
    navigate(`/${PatchesName.ManagerPanel}/${id}`);
  };

  return (
    <>
      <WrapperPages isButton={false} titleHeader={translateLang('managerPanel')}>
        <TabsContainer
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          handleOpenAddProduct={handleOpenAddProduct}
        />
        {getActiveTab()}
      </WrapperPages>
    </>
  );
};
