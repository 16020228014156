import React, { FC } from 'react';
import { ArrowBack } from '@mui/icons-material';
import { CircularProgress, IconButton, Stack } from '@mui/material';

import { ToolbarInvoice } from './ToolbarInvoice';
import { theme } from '../../../../../../helpers';
import { IncomingInvoiceTable } from './IncomingInvoiceTable';
import { useLocalization } from '../../../../../../localization';
import { useGetIncomingInvoiceQuery } from '../../../../../../graphql/queries/__generated__/getIncomingInvoice';
import { TranslatedField } from '../../../../../../components/Layout/components/TranslatedField/TranslatedField';

interface Props {
  invoiceId: string;
  idSupplier: string;
  refetchIncomingInvoices: () => void;
  toggleInvoiceTable: (id: string) => void;
}

export const IncomingInvoice: FC<Props> = ({
  invoiceId,
  idSupplier,
  toggleInvoiceTable,
  refetchIncomingInvoices,
}) => {
  const { translateLang } = useLocalization();

  const {
    data: incomingInvoiceData,
    loading,
    refetch: incomingInvoiceRefetch,
  } = useGetIncomingInvoiceQuery({
    variables: {
      id: invoiceId,
    },
  });

  const invoiceStatus = incomingInvoiceData?.incomingInvoice?.data?.attributes?.status;
  const incomingInvoice = incomingInvoiceData?.incomingInvoice?.data?.attributes?.stocks?.data;

  const incomingInvoiceIds = incomingInvoice?.map(item => item.id ?? '') || [];

  return (
    <Stack width="100%" mb={5}>
      <>
        <Stack flexDirection="row" alignItems="center" gap={1} mb={4.25}>
          <IconButton onClick={() => toggleInvoiceTable('')}>
            <ArrowBack sx={{ color: theme.palette.common.black }} />
          </IconButton>
          <TranslatedField
            originText={translateLang('returnToListOfIncomingInvoices')}
            fontSize={20}
            isTranslate
            noWrap
          />
        </Stack>
        {!loading ? (
          <>
            <ToolbarInvoice
              invoiceId={invoiceId}
              idSupplier={idSupplier}
              status={invoiceStatus}
              incomingInvoiceIds={incomingInvoiceIds}
              incomingInvoiceData={incomingInvoiceData}
              incomingInvoiceRefetch={incomingInvoiceRefetch}
              refetchIncomingInvoices={refetchIncomingInvoices}
            />
            <IncomingInvoiceTable
              loading={loading}
              items={incomingInvoice}
              incomingInvoiceData={incomingInvoiceData}
            />
          </>
        ) : (
          <Stack width="100%" alignItems="center" mt={10}>
            <CircularProgress size={40} />
          </Stack>
        )}
      </>
    </Stack>
  );
};
