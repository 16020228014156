import React, { FC, Fragment, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useLocalization } from '../../../localization';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { SearchField } from '../../../components/SearchField';
import { useFiltersSearchParamsForClients } from '../../../hooks/useFiltersSearchParamsForClients';
import { PAGE_COUNT } from '../../../helpers/constants';
import { TableProduct } from '../../../components/TableProduct/TableProduct';
import { useGetTableClientsListData } from '../hooks/useGetTableClientsListData';
import { useUpdateCustomerMutation } from '../../../graphql/mutations/__generated__/updateCustomer';
import { getClientsSearchFilter, handlerError } from '../../../helpers/functions';
import { ModalConfirm } from '../../../components/ModalConfirm';
import { useHandlerNotificationApp } from '../../../hooks/useHandlerNotificationApp';
import { DataModalType } from '../../../helpers/types';

interface TableClientsProps {
  handlerCellClick(idUser: string | number): void;
  isCreateOrder?: boolean;
}
type UserType = {
  userID: string;
  name: string;
  type: string;
};

export const TableClients: FC<TableClientsProps> = ({ handlerCellClick, isCreateOrder }) => {
  const { addNotification } = useHandlerNotificationApp();
  const { translateLang } = useLocalization();
  const { searchText, changeSearchText } = useFiltersSearchParamsForClients();
  const [runUpdateCustomer, { loading: loadRunUpdate }] = useUpdateCustomerMutation();
  const [isModalConfirm, setIsModalConfirm] = useState<DataModalType<UserType>>({
    isOpen: false,
    selProduct: {
      name: '',
      type: '',
      userID: '',
    },
  });
  const { getHeadersTable, getRowsTable, loading, pagination, changePageNumber } =
    useGetTableClientsListData({
      filters: searchText ? getClientsSearchFilter(searchText) : undefined,
      isCreateOrder,
      handlerCellClick,
      handlerBlockedUser: (userID, name, type) =>
        setIsModalConfirm({
          isOpen: true,
          selProduct: {
            name,
            type,
            userID,
          },
        }),
    });

  async function handlerBlockedUser(userID: string) {
    try {
      const resp = await runUpdateCustomer({
        variables: {
          id: userID,
          data: {
            blocked: true,
          },
        },
      });
      if (resp.data?.updateUsersPermissionsUser) {
        addNotification({
          messageError: `[SUCCESS]: ${translateLang('successBlockUser')}`,
          typeMessage: 'success',
        });
      }
    } catch (err: unknown) {
      handlerError(err);
    }
  }
  const getInformation = () => {
    return (
      <>
        <Typography>{`ID: #${translateLang(isModalConfirm.selProduct?.userID ?? '')}`}</Typography>
        <Typography>
          {`${translateLang('customerType')}: ${translateLang(
            isModalConfirm.selProduct?.type ?? ''
          )}`}
        </Typography>
        <Typography>
          {`${translateLang('name')}: ${translateLang(isModalConfirm.selProduct?.name ?? '')}`}
        </Typography>
      </>
    );
  };

  return (
    <Fragment>
      <Box sx={{ width: '100%' }}>
        <SearchField
          initStateText={searchText}
          executeWithDelay={changeSearchText}
          placeholder={translateLang('search_name')}
        />
        <Box width="100%" bgcolor="white" padding="17px 27px" boxSizing="border-box">
          <TranslatedField
            originText="result"
            isTranslate
            suffix={`: ${pagination?.total || 0}`}
            fontSize="30px"
          />
        </Box>
        <Box p={1} boxSizing="border-box">
          <TableProduct
            startPage={pagination?.page || 0}
            totalPages={pagination?.total ? Math.ceil(pagination.total / PAGE_COUNT) : 0}
            itemsRows={getRowsTable()}
            itemsHeader={getHeadersTable()}
            changeCurrentPage={changePageNumber}
            loading={loading || loadRunUpdate}
          />
        </Box>
      </Box>
      {isModalConfirm.isOpen && (
        <ModalConfirm
          title={`${translateLang('wantBlockUser')}?`}
          information={getInformation()}
          closeModal={() =>
            setIsModalConfirm({
              isOpen: false,
              selProduct: {
                name: '',
                type: '',
                userID: '',
              },
            })
          }
          openModal={isModalConfirm.isOpen}
          handlerConfirm={() => handlerBlockedUser(isModalConfirm?.selProduct?.userID ?? '')}
        />
      )}
    </Fragment>
  );
};
