import React, { useState } from 'react';
import { Stack } from '@mui/material';
import {
  CompleteOrderDocument,
  useCompleteOrderMutation,
} from '../../../../../../graphql/mutations/__generated__/completeOrder';
import { handlerError } from '../../../../../../helpers/functions';
import { useNavigate } from 'react-router-dom';
import { PatchesName } from '../../../../../../types/types';

import { MobileTopNavBar } from '../../../MobileTopNavBar';
import { ManualSearch } from './ManualSearch';
import { ScanerStep } from './ScanerStep';
import { NonSearched } from './NonSearched';
import { MODE } from '../../types';

export const CompleteOrderScanerPage = () => {
  const navigate = useNavigate();

  const [runGetSearchProduct] = useCompleteOrderMutation();

  const [scanResult, setScanResult] = useState('');
  const [codeSearch, setCodeSearch] = useState<MODE>('inProcess');
  const [scaningError, setScaningError] = useState(null);

  const changeMode = async (eanNumber: string) => {
    try {
      const response = await runGetSearchProduct({
        variables: { barcode: eanNumber },
      });
      if (response.data?.completeOrder?.data) {
        const item = response.data.completeOrder;
        if (item?.data?.id) {
          navigate(`/${PatchesName.MobileDeliveryOrderInfo}/${item.data.id}`, {
            replace: true,
          });
        } else {
          setCodeSearch('searchedNotOrder');
        }
      } else {
        setCodeSearch('nonSearched');
      }
    } catch (err: unknown) {
      handlerError(err);
      setCodeSearch('nonSearched');
    }
  };
  const handleBack = () => {
    if (codeSearch === 'inProcess') {
      navigate(`/${PatchesName.Delivery}`, {
        replace: true,
      });
    } else {
      setCodeSearch('inProcess');
    }
  };

  return (
    <Stack width="100%" height="100%" bgcolor="#F1F3F8" overflow="hidden">
      <MobileTopNavBar activeStep={1} onPrev={handleBack} totalStep={2} />
      <Stack textAlign="center" justifyContent="center" height="80%">
        {codeSearch === 'inProcess' && (
          <ScanerStep
            setCodeSearch={setCodeSearch}
            scanResult={scanResult}
            setScanResult={setScanResult}
            scaningError={scaningError}
            setScaningError={setScaningError}
            changeMode={changeMode}
          />
        )}

        {codeSearch === 'manualSearch' && (
          <ManualSearch changeMode={changeMode} setResult={setScanResult} />
        )}
        {codeSearch === 'nonSearched' && <NonSearched setCodeSearch={setCodeSearch} />}
      </Stack>
    </Stack>
  );
};
