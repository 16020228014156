import { Button, ButtonProps } from '@mui/material';
import React, { FC, useRef } from 'react';
import { Icon } from '../../../legos';
import { useLocalization } from '../../../localization';

interface Props extends ButtonProps {
  width?: number | string;
  handleClick: () => void;
}
export const ScanerButton: FC<Props> = ({ handleClick, width }) => {
  const { translateLang } = useLocalization();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleButtonClick = () => {
    if (buttonRef.current) {
      buttonRef.current.blur();
    }
    handleClick();
  };

  return (
    <Button
      ref={buttonRef}
      sx={{ width: width, color: '#97A408', borderColor: '#97A408', borderRadius: '10px' }}
      onClick={handleButtonClick}
      variant="outlined"
      startIcon={<Icon icon="cropFree" color="inherit" />}
    >
      {translateLang('scanProduct')}
    </Button>
  );
};
