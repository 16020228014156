import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PatchesName, UrlParamsObjectType } from '../../../types/types';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useLocalization } from '../../../localization';
import { OrderInfoTypography } from './OrderHistory/components/OrderInfoTypography';
import { useGetOrderItem } from '../../../graphql/queries/hook/useGetOrderItems';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { MobileTopNavBar } from './MobileTopNavBar';
import { ReturnCheckList } from './OrderHistory/components/ReturnCheckList';
import { ReturnApprovePage } from './OrderHistory/components/ReturnApprowePage';
import { NoRowsImage } from '../../../components/NoRowsImage/NoRowsImage';

enum StepReturnComponent {
  Step1,
  Step2,
  Step3,
}

export const MobileDeliveryReturnPartInfo = () => {
  const { translateLang } = useLocalization();
  const navigate = useNavigate();
  const { barcode = '' } = useParams<UrlParamsObjectType>();

  const [currentStep, setCurrentStep] = useState(StepReturnComponent.Step1);
  const { orderItem, loading } = useGetOrderItem(barcode);
  const handleBack = () => {
    if (currentStep === StepReturnComponent.Step1) {
      navigate(`/${PatchesName.Delivery}`, {
        replace: true,
      });
    } else {
      setCurrentStep(currentStep => currentStep - 1);
    }
  };
  const short = orderItem?.deliveryAddress;
  const _address = `${short?.country ? short?.country : ''}${short?.city ? short.city : ''}${
    short?.street ? short.street : ''
  }${short?.number ? short.number : ''}`;
  return (
    <Stack height="100%" bgcolor="#F1F3F8">
      <MobileTopNavBar activeStep={currentStep} onPrev={handleBack} totalStep={2} />
      <Stack p="10px">
        {loading ? (
          <CircularProgress sx={{ alignSelf: 'center' }} size={30} color="secondary" />
        ) : null}

        {currentStep === StepReturnComponent.Step1 &&
          (orderItem ? (
            <>
              <Typography
                sx={{
                  lineHeight: '25.1px',
                  fontSize: '20px',
                  fontWeight: '600',
                  color: '#2A3453',
                  textAlign: 'center',
                  mt: '25px',
                }}
              >{`${translateLang('order')} #${orderItem.orderId}`}</Typography>
              <Stack sx={{ borderRadius: '10px' }} padding="10px" marginBottom="10px">
                {_address && (
                  <OrderInfoTypography
                    label={`${translateLang('address')}:`}
                    value={_address || 'N/A'}
                    divider={false}
                    width="100%"
                  />
                )}
                {(orderItem.firstName || orderItem.lastName || orderItem.customerPhone) && (
                  <OrderInfoTypography
                    label={`${translateLang('client')}:`}
                    value={
                      orderItem.firstName || orderItem.lastName
                        ? `${orderItem.firstName} ${orderItem.lastName} #${orderItem.customerId}`
                        : orderItem.customerPhone
                    }
                    divider={false}
                  />
                )}
                {orderItem.phoneNumber && (
                  <OrderInfoTypography
                    label={`${translateLang('phone')}:`}
                    value={orderItem.phoneNumber}
                    divider={false}
                  />
                )}
              </Stack>

              <Typography
                sx={{
                  lineHeight: '20px',
                  fontSize: '16px',
                  fontWeight: 400,
                  color: ' #535252',
                  marginY: '12px',
                  paddingLeft: '10px',
                }}
              >{`${translateLang('product')}: `}</Typography>
              <Stack
                sx={{ backgroundColor: '#FFFFFF', borderRadius: '10px' }}
                padding="10px"
                marginBottom="10px"
              >
                <OrderInfoTypography
                  label={`${translateLang('goods')}:`}
                  value={orderItem.productName}
                  divider={false}
                />
                <OrderInfoTypography
                  label={`${translateLang('article')}:`}
                  value={orderItem.article}
                  divider={false}
                />
                <Button
                  disabled={orderItem.lockForReturn}
                  variant="outlined"
                  onClick={() => {
                    setCurrentStep(StepReturnComponent.Step2);
                  }}
                  sx={{
                    textTransform: 'none',
                    height: '50px',
                    borderRadius: '10px',
                    color: '#97A408',
                    borderColor: '#97A408',
                    borderWidth: '1.5px',
                    marginY: '10px',
                  }}
                >
                  <TranslatedField noWrap isTranslate fontSize={16} originText="makeAReturn" />
                </Button>
              </Stack>
            </>
          ) : (
            <NoRowsImage text="nothingFound" />
          ))}
        {currentStep === StepReturnComponent.Step2 && (
          <ReturnCheckList setCurrentStep={setCurrentStep} />
        )}
        {currentStep === StepReturnComponent.Step3 && (
          <ReturnApprovePage
            address={_address}
            client={
              orderItem.firstName || orderItem.lastName
                ? `${orderItem.firstName} ${orderItem.lastName}`
                : orderItem.customerPhone || ''
            }
            phone={orderItem.phoneNumber || 'N/A'}
            id={orderItem.id}
          />
        )}
      </Stack>
    </Stack>
  );
};
