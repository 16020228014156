import React, { FC, useEffect } from 'react';
import { useLocalStorage } from '../hooks';
import { useGetMeLazyQuery } from '../graphql/queries/__generated__/getMe';
import { RolesEnum } from '../types/types';
import { NoMatch } from '../pages/NoMatch';

interface Props {
  roles: Array<string>;
  children: JSX.Element;
}
export const ResolveRoleRoute: FC<Props> = ({ children, roles }) => {
  const { jwt } = useLocalStorage();
  const [runGetMe, { data: dataMe }] = useGetMeLazyQuery();

  useEffect(() => {
    if (jwt) {
      runGetMe().then();
    }
  }, [jwt]);

  const role = dataMe?.me?.role?.name ?? RolesEnum.Public;
  if (roles.includes(role)) {
    return children;
  }

  return <NoMatch />;
};
