import React, { FC, ReactNode } from 'react';
import {
  Box,
  BoxProps,
  CircularProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
} from '@mui/material';
import { TableCellProps } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { ContainerCell, StyledTableCell } from './TableCellProduct';
import { StyledTableRow } from './TableRowProduct';
import { NoRowsImage } from '../NoRowsImage/NoRowsImage';

export interface HeaderCell {
  title?: string | ReactNode;
  containerProps?: TableCellProps;
}
export interface BoxCell {
  val: string | ReactNode;
  containerProps?: BoxProps;
}
export interface TableCellsValue {
  cellsValues: Array<BoxCell>;
}
interface PropsTable {
  itemsHeader: Array<HeaderCell>;
  itemsRows: Array<TableCellsValue>;
  totalPages?: number;
  startPage?: number;
  changeCurrentPage?: (value: number) => void;
  loading?: boolean;
}

export const TableProduct: FC<PropsTable> = ({
  itemsHeader,
  changeCurrentPage,
  itemsRows,
  totalPages,
  startPage,
  loading,
}) => {
  const getEmptyContent = () => {
    if (itemsRows.length === 0) {
      return (
        <Box p="20px 30px" display="flex" justifyContent="center">
          {loading ? <CircularProgress size={30} color="secondary" /> : <NoRowsImage />}
        </Box>
      );
    }
    return <></>;
  };
  const getTableBody = () => {
    if (itemsRows.length === 0) {
      return null;
    }
    return (
      <TableBody>
        {itemsRows.map((row, indexRow) => (
          <StyledTableRow key={indexRow.toString()}>
            {row.cellsValues.map((cell, indexRow) => (
              <StyledTableCell key={indexRow.toString()}>
                <ContainerCell {...cell.containerProps}>{cell.val}</ContainerCell>
              </StyledTableCell>
            ))}
          </StyledTableRow>
                  ))}
      </TableBody>
    );
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {itemsHeader.map((item, index) => (
              <StyledTableCell
                key={`${item.title}${index}`}
                sx={{
                  minWidth: '50px',
                  textAlign: 'center',
                }}
                {...item.containerProps}
              >
                {item.title}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        {getTableBody()}
      </Table>
      {getEmptyContent()}
      {totalPages ? (
        <Box p="20px 30px" display="flex" justifyContent="flex-end">
          <Pagination
            sx={{
              '& .MuiPaginationItem-root': {
                '&.Mui-selected': {
                  background: '#5269A3',
                  color: 'white',
                  borderRadius: '8px',
                },
              },
            }}
            count={totalPages}
            shape="rounded"
            page={startPage}
            onChange={
              changeCurrentPage ? (event, value) => changeCurrentPage(value - 1) : undefined
            }
            defaultPage={5}
            siblingCount={3}
            boundaryCount={3}
          />
        </Box>
      ) : null}
    </TableContainer>
  );
};
