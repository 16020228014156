import React from 'react';

import { useLocalization } from '../../../../../localization';

export const useSupplierReturnStatuses = () => {
  const { translateLang } = useLocalization();
  const supplierReturnStatuses = [translateLang('formed'), translateLang('forming')];

  return {
    supplierReturnStatuses,
  };
};
