/* eslint-disable no-underscore-dangle */
import { InMemoryCache } from '@apollo/client';

export const apolloCache = new InMemoryCache({
  typePolicies: {
    UsersPermissionsUserEntity: {
      fields: {
        attributes: {
          merge: true,
        },
      },
    },
    CustomerBillEntity: {
      fields: {
        attributes: {
          merge: true,
        },
      },
    },
    CarModelEntity: {
      fields: {
        attributes: {
          merge: true,
        },
      },
    },
    CarEntity: {
      fields: {
        attributes: {
          merge: true,
        },
      },
    },
    CarBrandEntity: {
      fields: {
        attributes: {
          merge: true,
        },
      },
    },
    OrderEntity: {
      fields: {
        attributes: {
          merge: true,
        },
      },
    },
    OrderItemEntity: {
      fields: {
        attributes: {
          merge: true,
        },
      },
    },
    SupplierEntity: {
      fields: {
        attributes: {
          merge: true,
        },
      },
    },
    CustomerContactInfoEntity: {
      fields: {
        attributes: {
          merge: true,
        },
      },
    },
  },
});
