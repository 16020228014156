import React, { FC } from 'react';
import { Box, ButtonProps } from '@mui/material';
import { useBarcode } from 'next-barcode';

interface Props extends ButtonProps {
  barcode: string;
}
export const BarCodeImage: FC<Props> = ({ barcode }) => {
  const { inputRef } = useBarcode({
    value: barcode || 'no number',
    options: {
      background: 'transparent',
      textPosition: 'top',
      textMargin: 14,
      height: 70,
      fontSize: 24,
    },
  });

  return (
    <Box
      width="360px"
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      mr={2}
    >
      <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
        <svg ref={inputRef} />
      </Box>
    </Box>
  );
};
