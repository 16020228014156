import React, { FC } from 'react';
import { Stack, Tab, Tabs } from '@mui/material';
import { useGetTabs } from './hooks/useGetTabs';
import { useLocalization } from '../../../localization';
import { theme } from '../../../helpers';
import { useGetMe } from '../../../graphql/queries/hook/useGetMe';
import { RolesEnum } from '../../../types/types';

interface Props {
  activeTable: string | null;
  handleChange: (val: string | undefined) => void;
}
export const TabsBar: FC<Props> = ({ activeTable, handleChange }) => {
  const { role } = useGetMe();
  const { tabs } = useGetTabs();
  const { translateLang } = useLocalization();

  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <Tabs
        value={activeTable}
        sx={{
          height: 44,
          '& .MuiTabs-scroller': {
            overflow: 'auto !important',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
          },
          '& .MuiTabs-scroller::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {tabs.map(tab => {
          const hasRights =
            RolesEnum.SuperAdmin === role ||
            RolesEnum.Accounter === role ||
            RolesEnum.Admin === role;
          const isTransactionOrBalanceTab = tab.value === 'transactions' || tab.value === 'balance';

          if (!hasRights && isTransactionOrBalanceTab) return;

          return (
            <Tab
              key={tab.id}
              value={tab.value}
              label={translateLang(tab.value)}
              color={
                activeTable === tab.value
                  ? theme.palette.primary.main
                  : theme.palette.common.appleGreen
              }
              sx={{
                fontSize: 16,
                fontWeight: 600,
                py: 1.25,
                px: 4,
                minHeight: 56,
                color: 'rgba(42,52,83,0.5)',
                '&.Mui-selected': {
                  color: theme.palette.primary.main,
                },
              }}
              onClick={() => handleChange(tab.value)}
            />
          );
        })}
      </Tabs>
    </Stack>
  );
};
