import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';

import { theme } from '../../../helpers';
import { useLocalization } from '../../../localization';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { useGetClientBalance } from '../../Accounting/components/AllClients/components/ClientTransaction/hooks/useGetClientBalance';

interface Props {
  idUser: string;
}

export const ClientInfoBalance: FC<Props> = ({ idUser }) => {
  const { translateLang } = useLocalization();

  const { customerAccount, customerBalance } = useGetClientBalance(idUser);

  return (
    <Stack flexDirection="row" alignItems="center" gap={2.5}>
      <Stack
        flexDirection="row"
        alignItems="center"
        bgcolor={theme.palette.common.white}
        py={1.5}
        px={2}
        gap={1.25}
        borderRadius="10px"
      >
        <TranslatedField
          isTranslate
          color="black"
          fontSize={20}
          fontWeight={300}
          whiteSpace="nowrap"
          originText={`${translateLang('personalAccount')}:`}
        />
        <Typography fontSize={26} sx={{ color: theme.palette.common.appleGreen }}>
          {`${(customerAccount || 0) > 0 ? '+' : ''}${customerAccount ?? 0}€`}
        </Typography>
      </Stack>
      <Stack
        flexDirection="row"
        alignItems="center"
        bgcolor={theme.palette.common.white}
        py={1.5}
        px={2}
        gap={1.25}
        borderRadius="10px"
      >
        <TranslatedField
          isTranslate
          color="black"
          fontSize={20}
          fontWeight={300}
          originText={`${translateLang('balance')}:`}
        />
        <Typography
          fontSize={26}
          sx={{
            color:
              (customerBalance || 0) >= 0
                ? theme.palette.common.appleGreen
                : theme.palette.common.red,
          }}
        >
          {`${(customerBalance || 0) > 0 ? '+' : ''}${customerBalance ?? 0}€`}
        </Typography>
      </Stack>
    </Stack>
  );
};
