import { GetClientsQuery } from '../__generated__/getClients';
import {
  Enum_Newslettersubscription_Status,
  NewsletterSubscriptionFiltersInput,
  Pagination,
  PaginationArg,
} from '../../../__generated__/types';
import { useGetNewsletterSubscriptionsQuery } from '../__generated__/getNewsletterSubscriptions';

type UserPermission = NonNullable<GetClientsQuery['usersPermissionsUsers']>['data'][0];
type AttributesType = NonNullable<UserPermission['attributes']>;

export interface SubscriptionType {
  id?: UserPermission['id'];
  userName?: NonNullable<AttributesType['username']>;
  phoneNumbers?: string;
  source?: string;
  status?: Enum_Newslettersubscription_Status;
  date?: string;
}

export type PaginationType = Omit<Pagination, '__typename'>;

interface ReturnHook {
  newsletterSubscriptions: Array<SubscriptionType>;
  pagination?: PaginationType;
  isLoading?: boolean;
}

interface PropsHook {
  pagination?: PaginationArg;
  filters?: NewsletterSubscriptionFiltersInput;
}

export const useGetNewsletterSubscriptions = ({ pagination, filters }: PropsHook): ReturnHook => {
  const { data } = useGetNewsletterSubscriptionsQuery({
    variables: {
      pagination,
      filters,
      sort: ['id:desc'],
    },
  });

  if (data?.newsletterSubscriptions?.data) {
    const newsletterSubscriptions: Array<SubscriptionType> =
      data?.newsletterSubscriptions?.data?.map(subscription => {
        return {
          id: subscription.id || '',
          userName: subscription.attributes?.name,
          phoneNumbers: subscription.attributes?.phone,
          status: subscription.attributes?.status,
          date: subscription.attributes?.updatedAt,
          source: subscription.attributes?.source,
        };
      });
    const pagination: PaginationType = {
      page: data.newsletterSubscriptions.meta.pagination.page,
      pageCount: data.newsletterSubscriptions.meta.pagination.pageCount,
      pageSize: data.newsletterSubscriptions.meta.pagination.pageSize,
      total: data.newsletterSubscriptions.meta.pagination.total,
    };
    return { newsletterSubscriptions, pagination };
  }
  return { newsletterSubscriptions: [] };
};
