import React from 'react';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';

import { useLocalization } from '../../../../../localization';
import { FieldArray, getIn, useFormikContext } from 'formik';
import { Input } from '../../../../../legos';
import { theme } from '../../../../../helpers';
import { Add, Delete } from '@mui/icons-material';
import { TranslatedField } from '../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { GeneralSpareInputType, CriteriaProps, ProductField } from '../types';

export const ProductCriteriaForm = () => {
  const { translateLang } = useLocalization();
  const { values, errors, touched, handleChange } = useFormikContext<GeneralSpareInputType>();

  const isCriteriaFilled = (criterion: CriteriaProps) => {
    if (!criterion) {
      return true;
    }
    return (
      criterion.titleEN &&
      criterion.titleDE &&
      criterion.valueEN &&
      criterion.valueDE &&
      criterion.dimensionEN &&
      criterion.dimensionDE
    );
  };

  return (
    <Stack ml={{ xs: 0, lg: 4 }} mt={{ xs: 4, lg: 0 }} gap={3} pb={3.75} mb={3.75}>
      <Typography variant="body1" fontSize="20px">
        {translateLang('criteria')}:
      </Typography>
      <FieldArray
        name="criteria"
        render={({ remove, push }) =>
          values?.criteria && (
            <>
              {values.criteria.length > 0 &&
                values.criteria?.map((criterion, index) => (
                  <Stack
                    key={`${criterion}-${index}`}
                    gap={1.25}
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Box width="100%">
                      <Box mb={1.25}>
                        <Typography variant="body2" color={theme.palette.primary.main} mb={1.25}>
                          {`${translateLang('criterion')} ${index + 1} (${translateLang(
                            'english'
                          )})`}
                        </Typography>
                        <Stack
                          width="100%"
                          flexDirection={{ xs: 'column', md: 'row' }}
                          alignItems="end"
                          justifyContent="space-between"
                          gap={1.25}
                        >
                          <Input
                            name={`${ProductField.Criteria}.${index}.titleEN`}
                            labelColor={theme.palette.primary.light}
                            label={translateLang('title')}
                            value={values[ProductField.Criteria]?.[index].titleEN}
                            onChange={handleChange}
                            error={
                              Array.isArray(touched.criteria) &&
                              touched.criteria[index] &&
                              !!getIn(errors, `${ProductField.Criteria}.${index}.titleEN`)
                            }
                            helperText={
                              Array.isArray(touched.criteria) &&
                              touched.criteria[index] &&
                              getIn(errors, `${ProductField.Criteria}.${index}.titleEN`)
                            }
                            width="100%"
                          />
                          <Input
                            name={`${ProductField.Criteria}.${index}.valueEN`}
                            labelColor={theme.palette.primary.light}
                            label={translateLang('value')}
                            value={values[ProductField.Criteria]?.[index].valueEN}
                            onChange={handleChange}
                            error={
                              Array.isArray(touched.criteria) &&
                              touched.criteria[index] &&
                              !!getIn(errors, `${ProductField.Criteria}.${index}.valueEN`)
                            }
                            helperText={
                              Array.isArray(touched.criteria) &&
                              touched.criteria[index] &&
                              getIn(errors, `${ProductField.Criteria}.${index}.valueEN`)
                            }
                            width="100%"
                          />
                          <Input
                            name={`${ProductField.Criteria}.${index}.dimensionEN`}
                            labelColor={theme.palette.primary.light}
                            label={translateLang('dimension')}
                            value={values[ProductField.Criteria]?.[index].dimensionEN}
                            onChange={handleChange}
                            error={
                              Array.isArray(touched.criteria) &&
                              touched.criteria[index] &&
                              !!getIn(errors, `${ProductField.Criteria}.${index}.dimensionEN`)
                            }
                            helperText={
                              Array.isArray(touched.criteria) &&
                              touched.criteria[index] &&
                              getIn(errors, `${ProductField.Criteria}.${index}.dimensionEN`)
                            }
                            width="100%"
                          />
                        </Stack>
                      </Box>
                      <Box>
                        <Typography variant="body2" color={theme.palette.primary.main} mb={1.25}>
                          {`${translateLang('criterion')} ${index + 1} (${translateLang(
                            'german'
                          )})`}
                        </Typography>
                        <Stack
                          key={`${criterion}-${index}`}
                          width="100%"
                          flexDirection={{ xs: 'column', md: 'row' }}
                          alignItems="end"
                          justifyContent="space-between"
                          gap={1.25}
                        >
                          <Input
                            name={`${ProductField.Criteria}.${index}.titleDE`}
                            labelColor={theme.palette.primary.light}
                            label={translateLang('title')}
                            value={values[ProductField.Criteria]?.[index].titleDE}
                            onChange={handleChange}
                            error={
                              Array.isArray(touched.criteria) &&
                              touched.criteria[index] &&
                              !!getIn(errors, `${ProductField.Criteria}.${index}.titleDE`)
                            }
                            helperText={
                              Array.isArray(touched.criteria) &&
                              touched.criteria[index] &&
                              getIn(errors, `${ProductField.Criteria}.${index}.titleDE`)
                            }
                            width="100%"
                          />
                          <Input
                            name={`${ProductField.Criteria}.${index}.valueDE`}
                            labelColor={theme.palette.primary.light}
                            label={translateLang('value')}
                            value={values[ProductField.Criteria]?.[index].valueDE}
                            onChange={handleChange}
                            error={
                              Array.isArray(touched.criteria) &&
                              touched.criteria[index] &&
                              !!getIn(errors, `${ProductField.Criteria}.${index}.valueDE`)
                            }
                            helperText={
                              Array.isArray(touched.criteria) &&
                              touched.criteria[index] &&
                              getIn(errors, `${ProductField.Criteria}.${index}.valueDE`)
                            }
                            width="100%"
                          />
                          <Input
                            name={`${ProductField.Criteria}.${index}.dimensionDE`}
                            labelColor={theme.palette.primary.light}
                            label={translateLang('dimension')}
                            value={values[ProductField.Criteria]?.[index].dimensionDE}
                            onChange={handleChange}
                            error={
                              Array.isArray(touched.criteria) &&
                              touched.criteria[index] &&
                              !!getIn(errors, `${ProductField.Criteria}.${index}.dimensionDE`)
                            }
                            helperText={
                              Array.isArray(touched.criteria) &&
                              touched.criteria[index] &&
                              getIn(errors, `${ProductField.Criteria}.${index}.dimensionDE`)
                            }
                            width="100%"
                          />
                        </Stack>
                      </Box>
                    </Box>
                    <IconButton
                      sx={{ width: 30, height: 30, ml: 0.5, mt: 5, p: 0 }}
                      onClick={() => remove(index)}
                    >
                      <Delete />
                    </IconButton>
                  </Stack>
                ))}
              <Button
                variant="outlined"
                disabled={
                  Boolean(errors.criteria) ||
                  !!(
                    values?.criteria?.length &&
                    Boolean(!isCriteriaFilled(values.criteria[values.criteria.length - 1]))
                  )
                }
                startIcon={
                  <Add
                    style={{
                      color:
                        Boolean(errors.criteria) ||
                        !!(
                          values?.criteria?.length &&
                          Boolean(!isCriteriaFilled(values.criteria[values.criteria.length - 1]))
                        )
                          ? '#7A828A'
                          : '#848C22',
                    }}
                  />
                }
                sx={{
                  width: 'fit-content',
                  cursor: 'pointer',
                  p: 0,
                  border: 'none',
                  textTransform: 'initial',
                  '&:hover': {
                    border: 'none',
                    bgcolor: 'transparent',
                  },
                  '&:disabled': {
                    border: 'none',
                  },
                }}
                onClick={() => push({})}
              >
                <TranslatedField
                  isTranslate
                  color={
                    Boolean(errors.criteria) ||
                    !!(
                      values?.criteria?.length &&
                      Boolean(!isCriteriaFilled(values.criteria[values.criteria.length - 1]))
                    )
                      ? '#7A828A'
                      : '#848C22'
                  }
                  originText={translateLang('addCriterion')}
                />
              </Button>
            </>
          )
        }
      />
    </Stack>
  );
};
