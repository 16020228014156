import React, { FC } from 'react';
import { Box } from '@mui/system';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { Button } from '@mui/material';
import { CustomModal } from '../../../components';
import { useLocalization } from '../../../localization';

interface Props {
  closeModal(): void;
  isOpen: boolean;
}
export const ModalInfo: FC<Props> = ({ closeModal, isOpen }) => {
  const { translateLang } = useLocalization();

  return (
    <CustomModal title={translateLang('inCart')} handleClose={closeModal} open={isOpen}>
      <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center">
        <TranslatedField originText="partIsAlready" isTranslate fontSize={20} color="black" />
        <Button
          onClick={closeModal}
          variant="contained"
          color="secondary"
          fullWidth
          style={{
            marginTop: '20px',
          }}
        >
          <TranslatedField
            originText={translateLang('cancel')}
            fontSize={16}
            isTranslate
            noWrap
            color="white"
          />
        </Button>
      </Box>
    </CustomModal>
  );
};
