import React, { ReactNode } from 'react';
import { TableCellProps } from '@mui/material';

import { ReturnType } from './useGetSupplierReturns';
import { useLocalization } from '../../../../../localization';
import { SupplierReturnsRowItem } from '../SupplierReturnsRowItem';
import { HeaderCell } from '../../../../../components/TableProduct/TableProduct';

interface HookProps {
  supplierReturns?: ReturnType[];
  toggleReturnInvoiceTable: (id: string) => void;
}
interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTableActive(): Array<ReactNode>;
}

export const useGetSupplierReturnsTable = ({
  supplierReturns,
  toggleReturnInvoiceTable,
}: HookProps): ReturnHook => {
  const { translateLang } = useLocalization();
  const getHeadersTable = (): Array<HeaderCell> => {
    const style: TableCellProps = { color: '#F1F3F8', align: 'left' };
    return [
      {
        title: '№',
        containerProps: { ...style, align: 'center', width: '20px' },
      },
      {
        title: translateLang('dateOfReceiving'),
        containerProps: { ...style, align: 'center', width: '20px' },
      },
      {
        title: translateLang('returnDate'),
        containerProps: { ...style, align: 'center', width: '20px' },
      },
      {
        title: translateLang('returnTo'),
        containerProps: { ...style, align: 'center', width: '20px' },
      },
      {
        title: translateLang('status'),
        containerProps: { ...style, align: 'center', width: '20px' },
      },
      {
        title: translateLang('correctiveInvoice'),
        containerProps: { ...style, align: 'center', width: '40px' },
      },
      {
        title: '',
        containerProps: { width: '30px', color: '#f1f3f8', align: 'center' },
      },
    ];
  };

  const getRowsTableActive = (): ReactNode[] => {
    if (supplierReturns && supplierReturns?.length) {
      return supplierReturns?.map(item => {
        return (
          <SupplierReturnsRowItem
            key={`client-${item.id}`}
            supplierReturnItem={item}
            toggleReturnInvoiceTable={toggleReturnInvoiceTable}
          />
        );
      });
    }
    return [];
  };

  return {
    getHeadersTable,
    getRowsTableActive,
  };
};
