import { setPreDeliveryStateData } from '../functionsWitingCache';
import {
  Enum_Mode_Delivery_State,
  Enum_Order_Status,
  PreDeliveryState,
} from '../../../__generated__/types';

export const clearDataPreDelivery = () => {
  setPreDeliveryStateData({
    dataWrite: {
      preDeliveryState: {
        callParent: Enum_Mode_Delivery_State.Warehouse,
        idOrder: '',
        idUser: '',
        isInfoAndPrint: false,
        isOpenModal: false,
        orderNumber: '',
        status: Enum_Order_Status.Created,
      },
    },
  });
};

export const handlerSetDataDelivery = (props: PreDeliveryState) => {
  setPreDeliveryStateData({
    dataWrite: {
      preDeliveryState: {
        ...props,
      },
    },
  });
};
