import { Box, ButtonProps, IconButton } from '@mui/material';
import React, { FC, useRef } from 'react';
import { Icon } from '../../../legos';
import { Enum_Order_Status } from '../../../__generated__/types';

interface Props extends ButtonProps {
  handleClick: () => void;
  statusOrder: Enum_Order_Status;
  width?: number | string;
}
export const CarButton: FC<Props> = ({ handleClick, width, statusOrder }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const handleButtonClick = () => {
    if (buttonRef.current) {
      buttonRef.current.blur();
    }
    handleClick();
  };

  return (
    <Box
      bgcolor={statusOrder === Enum_Order_Status.InStock ? 'rgba(151,164,8,0.22)' : '#e4e4e4'}
      borderRadius={2}
    >
      <IconButton
        ref={buttonRef}
        sx={{
          width: width,
          color: '#97A408',
        }}
        onClick={handleButtonClick}
      >
        <Icon fontSize="small" icon="shippingCar" color="inherit" />
      </IconButton>
    </Box>
  );
};
