import React, { useEffect, useState } from 'react';
import { Stack, useMediaQuery } from '@mui/material';

import { CarouselPreview } from './CarouselPreview';
import { CarouselSlider } from './CarouselSlider';
import logo from '../../assets/logotype.svg';

export const CarouselWithPreview = ({ images }: { images: any }) => {
  const xsScreen = useMediaQuery(`(max-width:${600}px)`);
  const [chosenPhoto, setChosenPhoto] = useState(logo);

  useEffect(() => {
    if (images?.[0]?.imageURL1600) {
      setChosenPhoto(images?.[0]?.imageURL1600);
    } else {
      setChosenPhoto(logo);
    }
  }, [images, images?.length]);

  const handleChoosePhoto = (img: any) => {
    setChosenPhoto(img);
  };

  return (
    <Stack flexDirection={xsScreen ? 'column' : 'row'} gap={xsScreen ? 1.5 : 2.5} width="100%">
      {images?.length > 1 && (
        <CarouselSlider
          handleChoosePhoto={handleChoosePhoto}
          chosenPhoto={chosenPhoto}
          photos={images}
        />
      )}
      <CarouselPreview chosenPhoto={chosenPhoto} />
    </Stack>
  );
};
