import React, { FC, ReactNode } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { CustomModal } from './CustomModal';
import { TranslatedField } from './Layout/components/TranslatedField/TranslatedField';
import { useLocalization } from '../localization';

interface Props {
  title?: string;
  information?: string | ReactNode | ReactNode[];
  confirmTitleButton?: string;
  closeModal(): void;
  openModal: boolean;
  handlerConfirm?: () => void;
  isCancelButton?: boolean;
}

export const ModalConfirm: FC<Props> = ({
  openModal,
  closeModal,
  title = '',
  handlerConfirm = () => {/**/},
  confirmTitleButton = 'OK',
  information = '',
  isCancelButton = true,
}) => {
  const { translateLang } = useLocalization();
  const onPressConfirmButton = () => {
    handlerConfirm();
    closeModal();
  };
  return (
    <CustomModal title={title} handleClose={closeModal} open={openModal}>
      <Box>
        {information}
      </Box>
      <Stack flexDirection="row" mt={5}>
        {isCancelButton && (
          <Button
            onClick={closeModal}
            variant="contained"
            color="secondary"
            style={{
              width: '80%',
              height: '95%',
              marginRight: '2px',
            }}
          >
            <TranslatedField
              originText={translateLang('cancel')}
              fontSize={16}
              isTranslate
              noWrap
              color="white"
            />
          </Button>
        )}
        <Button
          onClick={onPressConfirmButton}
          variant="contained"
          color="error"
          style={{
            width: '80%',
            height: '95%',
            marginLeft: '2px',
          }}
        >
          <TranslatedField
            originText={confirmTitleButton}
            fontSize={16}
            isTranslate
            noWrap
            color="white"
          />
        </Button>
      </Stack>
    </CustomModal>
  );
};
