import { SelectChangeEvent } from '@mui/material';
import React, { FC, useState } from 'react';
import { useHandlerNotificationApp } from '../../../../hooks/useHandlerNotificationApp';
import { Enum_Newslettersubscription_Status } from '../../../../__generated__/types';
import { useUpdateNewsletterSubscriptionMutation } from '../../../../graphql/mutations/__generated__/updateNewsletterSubscription';
import { Selectus } from '../StyledSelect';
import { useLocalization } from '../../../../localization';
import { GetNewsletterSubscriptionsDocument } from '../../../../graphql/queries/__generated__/getNewsletterSubscriptions';

const NEWSLETTER_STATUSES = [
  Enum_Newslettersubscription_Status.Archived,
  Enum_Newslettersubscription_Status.Processed,
  Enum_Newslettersubscription_Status.Unprocessed,
];

interface Props {
  currentStatus: Enum_Newslettersubscription_Status;
  subscriptionId: string;
}
export const StatusSelect: FC<Props> = ({ currentStatus, subscriptionId }) => {
  const { addNotification } = useHandlerNotificationApp();
  const [runUpdateNewsletterSubscriptionMutation] = useUpdateNewsletterSubscriptionMutation();
  const { translateLang } = useLocalization();

  const handleChange = async (event: SelectChangeEvent) => {
    const _status = event.target.value as Enum_Newslettersubscription_Status;
    try {
      await runUpdateNewsletterSubscriptionMutation({
        variables: {
          id: subscriptionId,
          data: {
            status: _status,
          },
        },
        refetchQueries: [
          {
            query: GetNewsletterSubscriptionsDocument,
            variables: {
              sort: ['id:desc'],
              filters: { status: { eqi: 'unprocessed' } },
              pagination: { limit: -1 },
            },
          },
        ],
      });
      addNotification({
        messageError: translateLang('updatedSuccessfully'),
        typeMessage: 'success',
      });
    } catch (err: any) {
      addNotification({ messageError: err?.message, typeMessage: 'error' });
    }
  };
  return <Selectus data={NEWSLETTER_STATUSES} status={currentStatus} handleChange={handleChange} />;
};
