import { Typography, Stack, Divider } from '@mui/material';
import React, { FC } from 'react';
import { useGetIsMobileQuery } from '../../../../../graphql/cacheQuery/__generated__/getIsMobile';
interface Props {
  value?: string | null | undefined;
  label: string;
  width?: string;
  divider?: boolean;
  labelColor?: string;
}
export const CustomTypography: FC<Props> = ({
  value,
  label,
  width,
  divider = true,
  labelColor = '#7A8095',
}) => {
  const { data } = useGetIsMobileQuery();
  const isMobile = data?.isMobile ?? false;
  return (
    <>
      <Stack
        direction={{ xs: 'row', md: 'row' }}
        spacing={1}
        sx={{ my: !isMobile ? '3px' : '2px' }}
      >
        <Typography
          sx={{
            lineHeight: { lg: '17px', xs: '15px' },
            fontSize: { lg: '15px', xs: '12px' },
            fontWeight: { lg: '600', md: '500', sm: '400' },
            color: labelColor,
            width: isMobile ? '30%' : '120px',
          }}
        >
          {label}
        </Typography>
        <Typography
          sx={{
            lineHeight: '17px',
            fontSize: '14px',
            fontWeight: '400',
            color: '#000000',
            width: width ? width : '60%',
          }}
        >
          {value}
        </Typography>
      </Stack>
      {divider && <Divider />}
    </>
  );
};
