import React, { FC, useEffect, useState } from 'react';
import { Box, Checkbox, Stack, UseAutocompleteProps } from '@mui/material';
import { useGetBrandsQuery } from '../../../graphql/queries/__generated__/getBrands';
import { useLocalization } from '../../../localization';
import {
  AutocompleteCustom,
  AutocompleteItemType,
} from '../../AdminSettings/components/ChangeCatalog/components/AutocompleteCustom';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { ENUM_SEARCH_BY } from './SearchParts';

interface Props {
  updateFilters(brandIds?: number[]): void;
  numberSearch: string[];
  handleNumbersSearchChange: (type: string) => void;
  brandIds?: number[] | null;
}

export const FilterParts: FC<Props> = ({
  updateFilters,
  numberSearch,
  handleNumbersSearchChange,
  brandIds,
}) => {
  const { updateSelectLanguage, translateLang } = useLocalization();
  const { data } = useGetBrandsQuery({
    variables: { locale: updateSelectLanguage, pagination: { limit: -1 }, sort: 'brandName' },
  });

  const [brands, setBrands] = useState<AutocompleteItemType[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<AutocompleteItemType[]>([]);

  const handlerCloseAutocomplete = () => {
    const ids = selectedBrand.map(item => +item.id);
    if (ids.length > 0) {
      updateFilters(ids);
    } else {
      updateFilters(undefined);
    }
  };
  const handlerChangeAutocomplete: UseAutocompleteProps<
    AutocompleteItemType,
    any,
    any,
    any
  >['onChange'] = (event, option, reason) => {
    if (reason === 'clear') {
      updateFilters();
    } else if (reason === 'removeOption') {
      const ids = (option as AutocompleteItemType[]).map(item => +item.id);
      if (ids.length === 0) {
        updateFilters();
      } else {
        updateFilters(ids);
      }
    }
    setSelectedBrand(option as AutocompleteItemType[]);
  };

  const formattingBrandsData = (): AutocompleteItemType[] => {
    if (data && data.carSpareBrands?.data.length) {
      return data.carSpareBrands.data.map(item => ({
        id: item.attributes?.brandId?.toString() || '',
        title: `${item.attributes?.brandName} (${item.attributes?.brandId?.toString()})` || '',
        targetType: item.attributes?.brandName || '',
      }));
    }
    return [];
  };

  useEffect(() => {
    const _brands = formattingBrandsData();
    setBrands(_brands);
    setSelectedBrand(_brands.filter(item => brandIds?.includes(+item?.id)));
  }, [data]);

  return (
    <Stack
      mt={1}
      width="100%"
      flexDirection="row"
      bgcolor="white"
      borderRadius="10px"
      alignItems="center"
      p={3}
      gap={3}
      boxSizing="border-box"
    >
      <Box width="400px">
        <AutocompleteCustom
          options={brands}
          selectedValue={selectedBrand}
          label={translateLang('brandCode')}
          handlerChange={handlerChangeAutocomplete}
          isUpdateCatalog={true}
          onClose={handlerCloseAutocomplete}
          placeholder={''}
        />
      </Box>
      <Stack flexDirection="row" alignItems="center" gap={2}>
        <TranslatedField
          noWrap
          variant="body1"
          fontWeight={600}
          color="#2A3453"
          originText={`${translateLang('searchBy')}:`}
        />

        <Stack flexDirection="row" alignItems="center">
          <Checkbox
            // disabled={!searchText}
            checked={numberSearch.some(item => item === ENUM_SEARCH_BY.ArticleNumbers)}
            onChange={() => handleNumbersSearchChange(ENUM_SEARCH_BY.ArticleNumbers)}
          />
          <TranslatedField
            noWrap
            variant="body1"
            fontWeight={400}
            color="#2A3453"
            originText={translateLang('articleNumbers')}
          />
        </Stack>
        <Stack flexDirection="row" alignItems="center">
          <Checkbox
            // disabled={!searchText}
            checked={numberSearch.some(item => item === ENUM_SEARCH_BY.ComparableNumbers)}
            onChange={() => handleNumbersSearchChange(ENUM_SEARCH_BY.ComparableNumbers)}
          />
          <TranslatedField
            noWrap
            variant="body1"
            fontWeight={400}
            color="#2A3453"
            originText={translateLang('comparableNumbers')}
          />
        </Stack>
        <Stack flexDirection="row" alignItems="center">
          <Checkbox
            // disabled={!searchText}
            checked={numberSearch.some(item => item === ENUM_SEARCH_BY.OEMNumbers)}
            onChange={() => handleNumbersSearchChange(ENUM_SEARCH_BY.OEMNumbers)}
          />
          <TranslatedField
            noWrap
            variant="body1"
            fontWeight={400}
            color="#2A3453"
            originText={translateLang('OEMNumbers')}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
