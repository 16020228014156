import { Stack, Button } from '@mui/material';
import React, { FC, useState } from 'react';
import { TranslatedField } from '../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useUpdateReviewMutation } from '../../../../../graphql/mutations/__generated__/updateReview';
import { GetReviewsDocument } from '../../../../../graphql/queries/__generated__/getReviews';
import { useGetMe } from '../../../../../graphql/queries/hook/useGetMe';
import { getRefetchReviews } from '../../../../../graphql/queries/hook/useGetReviews';
import { useLocalization } from '../../../../../localization';
import { Input } from './Input';
import { useQuery } from '@apollo/client';
interface Props {
  id: string;
}
export const ReplayField: FC<Props> = ({ id }) => {
  const [textFieldValue, setTextFieldValue] = useState('');

  const { id: idUser } = useGetMe();
  const [updateReviewMutation] = useUpdateReviewMutation();

  const { refetch } = useQuery(GetReviewsDocument, {
    variables: getRefetchReviews(),
  });
  const { translateLang } = useLocalization();
  const handleTextFieldChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setTextFieldValue(event.target.value);
  };

  const handlerPublicateReply = async () => {
    try {
      await updateReviewMutation({
        variables: {
          id,
          data: {
            publication: true,
            reply: { date: new Date().toISOString(), user: idUser || '', text: textFieldValue },
          },
        },
      });
      refetch();
    } catch (err: unknown) {
      if (err instanceof ErrorEvent) {
        console.log(err?.message);
      }
    }
  };
  return (
    <Stack direction="row" mt="30px" spacing={2} alignItems="center">
      <Input
        value={textFieldValue}
        onChange={handleTextFieldChange}
        placeholder={translateLang('message')}
        fullWidth
      />

      <Button
        variant="contained"
        onClick={handlerPublicateReply}
        sx={{
          textTransform: 'none',
          height: '50px',
          borderRadius: '10px',
          borderWidth: '1.5px',
          width: '350px',
          backgroundColor: '#5269A3',
          mt: '14px',
        }}
      >
        <TranslatedField noWrap isTranslate fontSize={16} originText={'PostReply'} />
      </Button>
    </Stack>
  );
};
