/* eslint-disable no-console */
import React, { FC, useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';

import { CustomModal } from '../../../../components';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useGetClientById } from '../../../../graphql/queries/hook/useGetClientById';

import { useLocalization } from '../../../../localization';
import { RolesEnum } from '../../../../types/types';
import { useGetUsersRoleQuery } from '../../../../graphql/queries/__generated__/getUsersRole';
import {
  UpdateCustomerMutationVariables,
  useUpdateCustomerMutation,
} from '../../../../graphql/mutations/__generated__/updateCustomer';
import { GetClientByIdDocument } from '../../../../graphql/queries/__generated__/getClintById';
import { handlerError } from '../../../../helpers/functions';
import { useGetMe } from '../../../../graphql/queries/hook/useGetMe';

interface Props {
  clientId: string;
  closeModal(): void;
}

export const ChangeCustomerTypeModal: FC<Props> = ({ clientId, closeModal }) => {
  const { translateLang, updateSelectLanguage } = useLocalization();
  const { typeClientID, isContactInfoActive } = useGetClientById(clientId);
  const { isManager, isSuperAdmin } = useGetMe();
  const { data: roles } = useGetUsersRoleQuery();
  const [runUpdateCustomer] = useUpdateCustomerMutation();
  const [customerType, setCustomerType] = useState<string>(typeClientID || '');

  const handleSubmit = async () => {
    if (typeClientID === customerType) {
      closeModal();
      return;
    }
    const variables: UpdateCustomerMutationVariables = {
      id: clientId,
      data: {
        role: customerType,
      },
    };
    try {
      await runUpdateCustomer({
        variables,
        refetchQueries: !isContactInfoActive
          ? [
              {
                query: GetClientByIdDocument,
                variables: {
                  id: clientId,
                  locale: updateSelectLanguage as string,
                },
              },
            ]
          : [],
      });
    } catch (error: unknown) {
      handlerError(error);
    } finally {
      closeModal();
    }
  };
  const getRolesList = () => {
    if (roles?.usersPermissionsRoles?.data.length) {
      if (isManager) {
        const managerFilterList = roles.usersPermissionsRoles.data.filter(
          item =>
            (item?.attributes?.name as RolesEnum) === RolesEnum.Public ||
            (item?.attributes?.name as RolesEnum) === RolesEnum.Customer ||
            (item?.attributes?.name as RolesEnum) === RolesEnum.Customer40 ||
            (item?.attributes?.name as RolesEnum) === RolesEnum.Customer30 ||
            (item?.attributes?.name as RolesEnum) === RolesEnum.WholesaleCustomer ||
            (item?.attributes?.name as RolesEnum) === RolesEnum.PermanentWholesaleCustomer
        );

        if (managerFilterList) {
          return managerFilterList.map((item, index) => (
            <MenuItem key={item?.id || index.toString()} value={item?.id || ''}>
              {translateLang(item.attributes?.name ?? '')}
            </MenuItem>
          ));
        }
      } else if (isSuperAdmin) {
        return roles.usersPermissionsRoles.data.map((item, index) => (
          <MenuItem key={item?.id || index.toString()} value={item?.id || ''}>
            {translateLang(item.attributes?.name ?? '')}
          </MenuItem>
        ));
      } else {
        const resolveType = [RolesEnum.SuperAdmin, RolesEnum.Admin];
        const filterList = roles.usersPermissionsRoles.data.filter(
          item => !resolveType.includes(item?.attributes?.name as RolesEnum)
        );

        if (filterList) {
          return filterList.map((item, index) => (
            <MenuItem key={item?.id || index.toString()} value={item?.id || ''}>
              {translateLang(item.attributes?.name ?? '')}
            </MenuItem>
          ));
        }
      }
    }
    return null;
  };

  return (
    <CustomModal title={translateLang('contactInformation')} handleClose={closeModal} open={true}>
      <Stack component="form" flexGrow={1} spacing={2}>
        <FormControl fullWidth>
          <InputLabel>{translateLang('status')}</InputLabel>
          <Select
            value={customerType}
            label={translateLang('status')}
            onChange={event => setCustomerType(event.target.value as RolesEnum)}
          >
            {getRolesList()}
          </Select>
        </FormControl>
        <Stack flexDirection="row" mt={5}>
          <Button
            onClick={closeModal}
            variant="contained"
            color="secondary"
            style={{
              width: '80%',
              height: '95%',
              marginRight: '2px',
            }}
          >
            <TranslatedField
              originText={translateLang('cancel')}
              fontSize={16}
              isTranslate
              noWrap
              color="white"
            />
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="info"
            style={{
              width: '80%',
              height: '95%',
              marginLeft: '2px',
            }}
          >
            <TranslatedField
              originText={translateLang('save')}
              fontSize={16}
              isTranslate
              noWrap
              color="white"
            />
          </Button>
        </Stack>
      </Stack>
    </CustomModal>
  );
};
