import React from 'react';

interface ReturnHook {
  tabs: Array<{
    id: number;
    value: 'invoices' | 'balance';
  }>;
}
export const useGetTabs = (): ReturnHook => {
  return {
    tabs: [
      { id: 1, value: 'invoices' },
      { id: 2, value: 'balance' },
    ],
  };
};
