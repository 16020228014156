import React, { FC, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { TranslatedField } from '../../../../../components/Layout/components/TranslatedField/TranslatedField';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { useUpdateReviewMutation } from '../../../../../graphql/mutations/__generated__/updateReview';
import { GetReviewsDocument } from '../../../../../graphql/queries/__generated__/getReviews';
import { getRefetchReviews } from '../../../../../graphql/queries/hook/useGetReviews';
import { ReplayField } from './ReplayField';
import { DeleteButton } from './DeleteButton';
interface Props {
  statusPublication: boolean;
  id: string;
  reply: boolean;
}
export const ButtonBlock: FC<Props> = ({ statusPublication, id, reply }) => {
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [show, setShow] = useState(true);

  const handleAddReply = () => {
    setIsButtonClicked(true);
  };
  const [updateReviewMutation] = useUpdateReviewMutation();

  const handlerPublicate = async () => {
    try {
      await updateReviewMutation({
        variables: {
          id,
          data: {
            publication: true,
          },
        },
        refetchQueries: [
          {
            query: GetReviewsDocument,
            variables: getRefetchReviews(),
          },
        ],
      });
      setShow(true);
    } catch (err: unknown) {
      if (err instanceof ErrorEvent) {
        console.log(err?.message);
      }
    }
  };
  return (
    <>
      {isButtonClicked && !reply && <ReplayField id={id} />}
      <Stack
        direction="row"
        spacing={4}
        justifyContent={!reply ? 'space-between' : 'flex-start'}
        alignItems="baseline"
        mt="25px"
      >
        <Button
          variant="outlined"
          onClick={handlerPublicate}
          startIcon={!statusPublication ? <VisibilityOutlinedIcon /> : <DoneTwoToneIcon />}
          sx={{
            textTransform: 'none',
            height: '50px',
            borderRadius: '10px',
            color: !statusPublication ? '#5269A3' : '#97A408',
            borderColor: !statusPublication ? '#5269A3' : '#97A408',
            borderWidth: '1.5px',
            marginY: '10px',
            width: '230px',
          }}
        >
          <TranslatedField
            noWrap
            isTranslate
            fontSize={16}
            originText={!statusPublication ? 'postReview' : 'FeedbackPublished'}
          />
        </Button>
        {!reply && (
          <Button
            variant="outlined"
            onClick={() => {
              handleAddReply();
            }}
            startIcon={<ArrowCircleLeftOutlinedIcon />}
            sx={{
              textTransform: 'none',
              height: '50px',
              borderRadius: '10px',
              color: '#5269A3',
              borderColor: '#5269A3',
              borderWidth: '1.5px',
              marginY: '10px',
              width: '230px',
            }}
          >
            <TranslatedField noWrap isTranslate fontSize={16} originText={'ReplyFeedback'} />
          </Button>
        )}
        <DeleteButton id={id} statusPublication={statusPublication} setShow={setShow} show={show} />
      </Stack>
    </>
  );
};
