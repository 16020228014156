import React, { FC } from 'react';
import { Box } from '@mui/material';
import { DiscountInput, GeneralSparesEntity } from '../../../../__generated__/types';
import { useGetTableSupplierData } from '../PrtoductDiscount/hooks/useGetTableSupplierData';
import { TableProductActive } from '../../../../components/TableProductActive/TableProductActive';

interface Props {
  spareItem?: GeneralSparesEntity | null;
  changeDiscount(props: DiscountInput): void;
}

export const TableSuppliers: FC<Props> = ({ spareItem, changeDiscount }) => {
  const { getHeadersTable, getRowsTableActive, loading } = useGetTableSupplierData({
    spareItem,
    changeDiscount,
  });
  return (
    <Box width="100%" pt={2} boxSizing="border-box">
      <TableProductActive
        itemsHeader={getHeadersTable()}
        itemsRows={getRowsTableActive()}
        loading={loading}
      />
    </Box>
  );
};
