import React, { FC } from 'react';
import { Add, Remove } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';

interface Props {
  disabled?: boolean;
  indexCounter: number;
  limit: number;
  count: number;
  minOrderQuantity?: number | null;
  handlerCountChange(index: number, newCount: number): void;
}

export const CounterQuantity: FC<Props> = ({
  disabled,
  indexCounter,
  count,
  limit,
  handlerCountChange,
  minOrderQuantity,
}) => {
  let limitQuantity = 1;
  if (minOrderQuantity && minOrderQuantity > 1) {
    limitQuantity = minOrderQuantity;
  }
  const handleIncrement = () => {
    if (count < limit) {
      handlerCountChange(indexCounter, count + limitQuantity);
    }
  };
  const handleDecrement = () => {
    if (count > limitQuantity) {
      handlerCountChange(indexCounter, count - limitQuantity);
    }
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Box alignContent="center" bgcolor="#F1F3F8" borderRadius={10}>
        <IconButton
          disabled={disabled}
          size="small"
          onClick={() => handleDecrement()}
          sx={{
            color: '#97A408',
          }}
        >
          <Remove />
        </IconButton>
      </Box>
      <Box
        width={27}
        height={27}
        border={0.5}
        borderRadius={1}
        borderColor="#000"
        alignContent="center"
        justifyContent="center"
      >
        <TranslatedField
          style={{ textAlign: 'center', paddingTop: '2px' }}
          originText={count ? count.toString() : ''}
        />
      </Box>
      <Box alignContent="center" bgcolor="#F1F3F8" borderRadius={10}>
        <IconButton
          disabled={disabled}
          size="small"
          onClick={() => handleIncrement()}
          sx={{
            color: '#97A408',
          }}
        >
          <Add />
        </IconButton>
      </Box>
    </Stack>
  );
};
