import { Button, Stack } from '@mui/material';
import React, { FC } from 'react';
import { Icon } from '../../../legos';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { formatDate } from '../../../helpers/functions';

interface Props {
  isMobile: boolean;
}
export const Today: FC<Props> = ({ isMobile }) => {
  return (
    <Stack direction="row" sx={{ marginTop: isMobile ? '50px' : null }}>
      <Button
        variant="outlined"
        startIcon={<Icon icon="calendar" color="inherit" />}
        style={{
          textTransform: 'none',
          height: '50px',
          borderRadius: '10px',
          color: '#2A3453',
          borderColor: 'black',
          borderWidth: '1.5px',
        }}
      >
        <TranslatedField noWrap isTranslate fontSize={16} originText={isMobile ? 'today' : ''} />
        {formatDate(new Date())}
      </Button>
    </Stack>
  );
};
