import React, { FC, useState } from 'react';
import { Button, Fade, IconButton, Modal, Stack } from '@mui/material';

import { Icon } from '../../../../legos';
import { theme } from '../../../../helpers';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { PaymentForm } from './PaymentForm';
import { useGetCustomerAccountsQuery } from '../../../../graphql/queries/__generated__/getCustomerAccounts';

interface PaymentModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  customerId: string;
  orderId: string;
}

export const PaymentModal: FC<PaymentModalProps> = ({
  isModalOpen,
  handleClose,
  customerId,
  orderId,
}) => {
  const [payType, setPayType] = useState<'cash' | 'bankTransfer' | 'PA' | ''>('');

  const { data: customerAccountData, refetch: refetchCustomerAmounts } =
    useGetCustomerAccountsQuery({
      variables: {
        filters: { customerId: { eq: customerId } },
      },
    });

  const customerAmount = customerAccountData?.customerAccounts?.data?.[0].attributes?.total;

  const handlePayType = (type: 'cash' | 'bankTransfer' | 'PA') => {
    setPayType(type);
  };

  const handleCloseTransactionForm = () => {
    setPayType('');
  };

  return (
    <Modal open={true} onClose={handleClose} closeAfterTransition>
      <Fade in={isModalOpen}>
        <Stack
          bgcolor={theme.palette.common.lightGrey}
          sx={{
            p: 4,
            top: '50%',
            width: payType ? 400 : 700,
            left: '50%',
            boxShadow: 24,
            borderRadius: 2,
            position: 'absolute' as const,
            transform: 'translate(-50%, -50%)',
          }}
        >
          {!payType ? (
            <Stack justifyContent="center" alignItems="center" height={315}>
              <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 20, top: 20 }}>
                <Icon icon="close" sx={{ color: theme.palette.primary.main }} />
              </IconButton>
              <TranslatedField
                isTranslate
                variant="h5"
                color={theme.palette.common.darkBlue}
                marginBottom={5}
                originText={'orderPayment'}
              />

              <Stack flexDirection="row" width="100%" gap={1.25}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    height: 40,
                    p: 2,
                    textTransform: 'none',
                    backgroundColor: theme.palette.common.white,
                    borderRadius: '5px',
                    color: theme.palette.common.black,
                    boxShadow: 'none',
                    '&:hover': { color: theme.palette.common.white },
                  }}
                  onClick={() => {
                    handlePayType('cash');
                  }}
                >
                  <TranslatedField originText={'cash'} fontSize={16} isTranslate noWrap />
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    height: 40,
                    p: 2,
                    textTransform: 'none',
                    backgroundColor: theme.palette.common.white,
                    borderRadius: '5px',
                    color: theme.palette.common.black,
                    boxShadow: 'none',
                    '&:hover': { color: theme.palette.common.white },
                  }}
                  onClick={() => {
                    handlePayType('bankTransfer');
                  }}
                >
                  <TranslatedField originText={'bankTransfer'} fontSize={16} isTranslate noWrap />
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    height: 40,
                    p: 2,
                    textTransform: 'none',
                    backgroundColor: theme.palette.common.white,
                    borderRadius: '5px',
                    color: theme.palette.common.black,
                    boxShadow: 'none',
                    '&:hover': { color: theme.palette.common.white },
                  }}
                  onClick={() => {
                    handlePayType('PA');
                  }}
                >
                  <TranslatedField originText={'withdrawFromPA'} fontSize={16} isTranslate noWrap />
                </Button>
              </Stack>
            </Stack>
          ) : (
            <PaymentForm
              handleClose={handleClose}
              handleClosePaymentForm={handleCloseTransactionForm}
              payType={payType}
              customerAmount={customerAmount}
              customerId={customerId}
              orderId={orderId}
              refetchCustomerAmounts={refetchCustomerAmounts}
            />
          )}
        </Stack>
      </Fade>
    </Modal>
  );
};
