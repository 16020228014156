import { Step, styled } from '@mui/material';
import { TimePointStatusColor } from './types';

export const CustomizedStep = styled(Step)`
  & .MuiStepConnector-root {
    top: 58px;
    left: calc(-50% + 32px);
    right: calc(50% + 32px);
  }

  & .MuiStepConnector-root .MuiStepConnector-line {
    border-color: ${TimePointStatusColor.Completed};
  }

  & .Mui-active.MuiStepConnector-root .MuiStepConnector-line {
    border-top-style: dashed;
  }

  & .MuiTypography-root,
  & .MuiStepLabel-label.Mui-active,
  & .MuiStepLabel-label.Mui-completed,
  & .MuiStepLabel-label {
    color: ${TimePointStatusColor.Completed};
  }

  & .TimePointStatus-active.MuiTypography-root {
    color: ${TimePointStatusColor.Active};
  }

  & .TimePointStatus-skipped.MuiTypography-root {
    color: ${TimePointStatusColor.Skipped};
  }

  & .TimePointStatus-edit-history {
    cursor: pointer;

    &:hover {
      color: ${TimePointStatusColor.Active};
      text-decoration: underline;
    }
  }
`;
