import React, { Box, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { theme } from '../../../helpers';

export const BtnBack = styled(ButtonBack)({
  padding: 0,
  margin: '4px',
  marginRight: '10px',
  border: 'none',
  borderRadius: '5px',
  minWidth: 17,
  minHeight: 33,
  background: theme.palette.common.appleGreen,
  boxShadow: '0px 0px 1px rgba(25, 21, 22, 0.25), 0px 3px 8px -1px rgba(25, 21, 22, 0.05)',
  '&:disabled': {
    background: theme.palette.common.white,
    visibility: 'hidden',
  },
  '& svg': {
    color: `white !important`,
  },
  '&:disabled svg': {
    color: `${theme.palette.common.appleGreen} !important`,
  },
});

export const BtnNext = styled(ButtonNext)({
  padding: 0,
  margin: '4px',
  border: 'none',
  borderRadius: '5px',
  minWidth: 17,
  minHeight: 33,
  background: 'red',
  boxShadow: '0px 0px 1px rgba(25, 21, 22, 0.25), 0px 3px 8px -1px rgba(25, 21, 22, 0.05)',
  '&:disabled': {
    background: theme.palette.common.white,
    visibility: 'hidden',
  },
  '& svg': {
    color: `white !important`,
  },
  '&:disabled svg': {
    color: `${theme.palette.common.appleGreen} !important`,
  },
});

export const Buttons = () => (
  <Stack
    flexDirection="row"
    justifyContent="space-between"
    visibility={{ xs: 'hidden', sm: 'visible' }}
  >
    <Box position="absolute" top={-16} right={28} zIndex={1}>
      <BtnBack>
        <ArrowDownward />
      </BtnBack>
    </Box>
    <Box position="absolute" bottom={-15} right={32} zIndex={1}>
      <BtnNext>
        <ArrowUpward />
      </BtnNext>
    </Box>
  </Stack>
);
