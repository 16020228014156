import { Enum_Specialproposal_System_Name } from '../__generated__/types';

export const GRAPHQL_API =
  process.env.REACT_APP_GRAPHQL_API ?? 'https://dev.strapi.autobro.at/graphql';
export const AUTO_BRO_API = process.env.REACT_APP_AUTO_BRO_API ?? 'https://dev.strapi.autobro.at';
export const AUTO_BRO_BUNNY_API =
  process.env.REACT_APP_AUTO_BRO_BUNNY_API ?? 'https://autobro.b-cdn.net';

export const TbsTopProducts = [
  { title: 'Größte Rabatte -50%', enum: Enum_Specialproposal_System_Name.BiggestDiscounts },
  { title: 'TOP Angebote — Autoreifen', enum: Enum_Specialproposal_System_Name.TopOffers },
  { title: 'TOP-Verkäufe', enum: Enum_Specialproposal_System_Name.TopSales },
];
export const PAGE_COUNT = 10;
export const PAGE_COUNT_FIFE = 5;
export const VERSION_APP = '0.1.3';
export const PERIOD_FOR_PAYMENT = 14;
export const IBAN = 'AT66 2011 1847 2675 3100';
