import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';

import { Receipt } from './Receipt';
import { CartDetailList } from './CartDetailList';
import { CartOrderComment } from './CartOrderComment';
import { VehiclesType } from '../../../helpers/types';
import { useLocalization } from '../../../localization';
import { NoRowsImage } from '../../../components/NoRowsImage/NoRowsImage';
import { useGetOrderItems } from '../../../graphql/queries/hook/useGetOrderItems';

interface Props {
  orderNumber?: string | null;
  orderId: string;
  vehicle: VehiclesType | null;
  comment?: string | null | undefined;
  orderTotal?: number | null | undefined;
  phoneNumber: string;
}

export const CartDetailBox: FC<Props> = ({
  orderNumber,
  orderId,
  vehicle,
  comment,
  orderTotal,
  phoneNumber,
}) => {
  const { translateLang } = useLocalization();
  const {
    orderItems,
    loading: orderItemsLoading,
    refetch: refetchOrderItems,
  } = useGetOrderItems(orderId);
  const [openPhoneModal, setOpenPhoneModal] = useState<{ isOpen: boolean }>({
    isOpen: false,
  });

  useEffect(() => {
    if (orderTotal !== undefined && refetchOrderItems) {
      refetchOrderItems();
    }
  }, [orderTotal, refetchOrderItems]);

  const openPhoneModals = () => {
    setOpenPhoneModal({ isOpen: true });
  };

  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        mt: '32px',
        borderRadius: '10px',
        padding: '40px',
        mb: '32px',
      }}
    >
      <Typography
        sx={{ lineHeight: '37.65px', fontSize: '30px', fontWeight: '400', color: '#2A3453' }}
      >
        {translateLang('cart')}
      </Typography>
      {!orderItems?.length && <NoRowsImage text={translateLang('emptyCart')} />}
      {!!orderItems?.length && (
        <>
          <CartDetailList
            orderItems={orderItems}
            orderItemsLoading={orderItemsLoading}
            refetchOrderItems={refetchOrderItems}
          />
          <CartOrderComment comment={comment} />
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={3}
            alignItems="center"
            marginTop="30px"
          >
            <Typography lineHeight="38px" fontSize="30px" textAlign="right" color="#2A3453">
              {translateLang('orderAmount')}: {`${orderTotal?.toFixed(2)}`} €
            </Typography>
            <Button
              style={{
                marginLeft: '40px',
                height: '40px',
                textTransform: 'none',
                color: '#848C22',
                width: 140,
                padding: 1,
                borderRadius: '10px',
              }}
              onClick={openPhoneModals}
              color="success"
              variant="outlined"
              sx={{ height: '30px' }}
            >
              {translateLang('send')}
            </Button>
          </Stack>
        </>
      )}

      {openPhoneModal.isOpen && (
        <Receipt
          openModal={true}
          vehicle={vehicle}
          orderNumber={orderNumber}
          total={orderTotal}
          orderItems={orderItems}
          generalComment={comment}
          phoneNumber={phoneNumber}
          title={translateLang('order_info')}
          closeModal={() => setOpenPhoneModal({ isOpen: false })}
        />
      )}
    </Box>
  );
};
