import { RolesEnum } from '../../../types/types';
import { Markup, PriceByRole } from '../../../__generated__/types';
import { useGetMarkupQuery } from '../../../graphql/queries/__generated__/getMarkup';
import { useHandlerNotificationApp } from '../../../hooks/useHandlerNotificationApp';

export const MAX_DISCOUNT_PRICE_PERCENT = 20;
const MIN_MARKUP = 25;

interface GetFinalPriceParamsType {
  userPrice: number;
  providerPrice: number;
}
interface ReturnHook {
  getFinalPriceItem(params: GetFinalPriceParamsType): boolean;
}
interface ReturnHookWithRole {
  priceCalculatorWithRole(supplierPrice: number, discount: number, role: RolesEnum): number;
}

export const useGetPriceMarkup = (): ReturnHook => {
  const { addNotification } = useHandlerNotificationApp();
  const getFinalPriceItem = ({ userPrice, providerPrice }: GetFinalPriceParamsType): boolean => {
    const providerPriceMarkupPercent = ((userPrice - providerPrice) * 100) / providerPrice;

    if (providerPriceMarkupPercent < MIN_MARKUP) {
      addNotification({ typeMessage: 'error', messageError: 'Change discount to low' });
      return false;
    }

    return true;
  };

  return {
    getFinalPriceItem,
  };
};
export const useGetPriceMarkupWhitRole = (): ReturnHookWithRole => {
  const { data } = useGetMarkupQuery();

  const priceCalculatorWithRole = (
    supplierPrice: number,
    discount: number,
    role: RolesEnum
  ): number => {
    if (data?.markup?.data?.attributes) {
      const markup = getMarkup(role, data.markup.data.attributes);
      return calculatePrice(supplierPrice, discount, markup);
    }
    return 0;
  };

  return {
    priceCalculatorWithRole,
  };
};

function getMarkup(role: string, priceRole: Markup): number {
  switch (role) {
    case RolesEnum.Customer:
    case RolesEnum.Public:
    case RolesEnum.Employee:
      return priceRole?.customer ?? 0;
    case RolesEnum.Customer40:
      return priceRole?.customer40 ?? 0;
    case RolesEnum.Customer30:
      return priceRole?.customer30 ?? 0;

    case RolesEnum.WholesaleCustomer:
      return priceRole?.wholesaleCustomer ?? 0;
    case RolesEnum.PermanentWholesaleCustomer:
      return priceRole?.permanentWholesaleCustomer ?? 0;
    case RolesEnum.SuperAdmin:
    case RolesEnum.Admin:
    case RolesEnum.WarehouseManager:
    case RolesEnum.Courier:
    case RolesEnum.Manager:
      return priceRole?.employee ?? 0;
    default:
      return priceRole?.customer ?? 0;
  }
}
export function getMarkupForRolePrice(role?: string, priceByRole?: PriceByRole | null) {
  switch (role) {
    case RolesEnum.Customer:
    case RolesEnum.Public:
    case RolesEnum.Employee:
      return priceByRole?.customer ?? 0;
    case RolesEnum.Customer30:
      return priceByRole?.customer30 ?? 0;
    case RolesEnum.Customer40:
      return priceByRole?.customer40 ?? 0;
    case RolesEnum.WholesaleCustomer:
      return priceByRole?.wholesaleCustomer ?? 0;
    case RolesEnum.PermanentWholesaleCustomer:
      return priceByRole?.permanentWholesaleCustomer ?? 0;
    case RolesEnum.SuperAdmin:
    case RolesEnum.Admin:
    case RolesEnum.WarehouseManager:
    case RolesEnum.Courier:
    case RolesEnum.Manager:
      return priceByRole?.employee ?? 0;
    default:
      return priceByRole?.customer ?? 0;
  }
}
function calculatePrice(supplierPrice: number, discount: number, markup: number) {
  const price = Math.round((supplierPrice + (supplierPrice * markup) / 100) * 100) / 100;
  return Math.round((price - (price * discount) / 100) * 100) / 100;
}
