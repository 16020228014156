import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, Collapse, Switch, Typography, Badge } from '@mui/material';

import {
  Enum_Search_By,
  GeneralSparesFiltersInput,
  InputMaybe,
} from '../../../__generated__/types';
import { PAGE_COUNT } from '../../../helpers/constants';
import { useLocalization } from '../../../localization';
import { ProductCatalogTable } from './ProductCatalogTable';
import { FilterParts } from '../../CheckoutPage/components/FilterParts';
import { CategoryList } from '../../CheckoutPage/components/CategoryList';
import { useGetCategoryTreeQuery } from '../../../graphql/queries/__generated__/getCategoryTree';
import { useGetGeneralSparesQuery } from '../../../graphql/queries/__generated__/getGeneralSpares';
import { WrapperSearchFieldCollapse } from '../../CheckoutPage/components/WrapperSearchFieldCollapse';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { BottomNavigate } from '../../CheckoutPage/components/BottomNavigate';

export enum ENUM_SEARCH_BY {
  Title = 'Title',
  OEMNumbers = 'OEMNumbers',
  ArticleNumbers = 'ArticleNumber',
  ComparableNumbers = 'ComparableNumbers',
}

interface Props {
  handleProductId: (id: string) => void;
}

const ProductCatalog: FC<Props> = ({ handleProductId }) => {
  const { updateSelectLanguage, translateLang } = useLocalization();
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [isCheckedDrafts, setIsCheckedDrafts] = useState(false);
  const [isCheckedTecDoc, setIsCheckedTecDoc] = useState(false);
  const [numberSearch, setNumberSearch] = useState<string[]>([]);
  const [category, setCategory] = useState<string>('');
  const [subCategory, setSubCategory] = useState<string>('');
  const [checked, setChecked] = useState(false);
  const [currentActiveMenu, setCurrentActiveMenu] = useState<{
    id: string;
    name: string | null | undefined;
  }>({
    id: '',
    name: '',
  });
  const [filters, setFilters] = useState<GeneralSparesFiltersInput | undefined>({});
  const searchBy = [...numberSearch].filter(sortType => sortType !== null);

  useEffect(() => {
    const brandIds = params.getAll('brandIds').map(brandId => Number(brandId));
    setPage(Number(params.get('page')));
    setIsCheckedDrafts(Boolean(params.get('Drafts')));
    setIsCheckedTecDoc(Boolean(params.get('TecDoc')));
    handleSetFilters({
      ...filters,
      ...(brandIds.length > 0 ? { brandIds } : {}),
      searchText: params.get('searchText'),
      tecdoc_categoryId: params.get('tecdocCategoryId'),
    });
    setNumberSearch(params.getAll('searchBy'));
  }, []);

  useEffect(() => {
    const paramsHandler = setTimeout(() => {
      const newParams = new URLSearchParams(params);

      if (filters?.searchText) {
        newParams.set('searchText', filters?.searchText);
      } else {
        newParams.delete('searchText');
      }
      if (filters?.tecdoc_categoryId) {
        newParams.set('tecdocCategoryId', filters?.tecdoc_categoryId);
      }
      if (page > 1) {
        newParams.set('page', JSON.stringify(page));
      } else {
        newParams.delete('page');
      }
      if (isCheckedTecDoc) {
        newParams.set('TecDoc', JSON.stringify(isCheckedTecDoc));
      } else {
        newParams.delete('TecDoc');
      }
      if (isCheckedDrafts) {
        newParams.set('Drafts', JSON.stringify(isCheckedDrafts));
      } else {
        newParams.delete('Drafts');
      }
      if (searchBy.length > 0) {
        newParams.delete('searchBy');
        searchBy.forEach(item => newParams.append('searchBy', item)); 
      } else {
        newParams.delete('searchBy');
      }
      if (filters?.brandIds?.length !== 0) {
        newParams.delete('brandIds');
        filters?.brandIds?.forEach(item => newParams.append('brandIds', JSON.stringify(item))); 
      } else {
        newParams.delete('brandIds');
      }
      navigate(`?${newParams.toString()}`);
    }, 100);
    return () => {
      clearTimeout(paramsHandler);
    };
  }, [
    filters?.searchText,
    filters?.brandIds,
    filters?.tecdoc_categoryId,
    page,
    updateSelectLanguage,
    searchBy,
    isCheckedTecDoc,
    isCheckedDrafts,
  ]);

  const { data: dataCarSpares, loading: loadingCarSpares } = useGetGeneralSparesQuery({
    variables: {
      locale: updateSelectLanguage,
      pagination: { page: page, pageSize: PAGE_COUNT },
      filters: {
        ...(searchBy ? { searchBy: searchBy as InputMaybe<Enum_Search_By>[] } : {}),
        ...(isCheckedTecDoc ? { source: false } : {}),
        ...(isCheckedDrafts ? { isDraft: isCheckedDrafts } : {}),
        ...filters,
      },
      sort: ['tecdoc_articleNumber'],
    },
  });

  const generalSparesData = dataCarSpares?.generalSpares?.data;

  const [isCollapseCatalog, setIsCollapseCatalog] = useState(false);
  const [isCollapseFilter, setIsCollapseFilter] = useState(false);

  const { data: dataCategories } = useGetCategoryTreeQuery({
    variables: {
      input: {
        locale: updateSelectLanguage,
        levelTo: 1,
      },
    },
    skip: !isCollapseCatalog,
  });

  const handlerSearchFieldByText = (text: string) => {
    handleSetFilters({
      ...filters,
      searchText: text.trim(),
    });
    setPage(1);
  };
  const handlerSearchFieldByBrand = (brandIds?: number[]) => {
    handleSetFilters({
      ...filters,
      brandIds,
    });
    setPage(1);
  };
  const dataCategory = useMemo(
    () => dataCategories?.getCategoryTree?.categoryTree?.filter(item => item?.level === 0),
    [dataCategories]
  );
  const onCollapseClick = (type: 'catalog' | 'filter') => {
    if (type === 'catalog') {
      if (isCollapseFilter) {
        setIsCollapseFilter(false);
        setTimeout(() => {
          setIsCollapseCatalog(true);
        }, 500);
      } else {
        setIsCollapseCatalog(!isCollapseCatalog);
      }
    } else {
      if (isCollapseCatalog) {
        setIsCollapseCatalog(false);
        setTimeout(() => {
          setIsCollapseFilter(true);
        }, 500);
      } else {
        setIsCollapseFilter(!isCollapseFilter);
      }
    }
  };

  const handleChangePage = (_page: number): void => {
    setPage(_page + 1);
  };

  const handleNumbersSearchChange = (type: string) =>
    setNumberSearch(prevState => {
      const newState = prevState;
      if (newState.some(item => item === type)) {
        return [...newState.filter(item => item !== type)];
      } else {
        return [...newState, type];
      }
    });

  const handleSetFilters = (filters: GeneralSparesFiltersInput | undefined) => {
    setParams(oldValue => {
      return {
        ...oldValue,
        searchText: filters?.searchText,
        page: page,
      };
    });
    navigate(`?${params.toString()}`);
    setFilters(filters);
  };

  const clearFilter = () => {
    const newParams = new URLSearchParams(params?.toString());
    setCategory('');
    setSubCategory('');
    setChecked(false);
    setCurrentActiveMenu({
      id: '',
      name: '',
    });
    newParams.delete('tecdocCategoryId');
    newParams.delete('currentActiveMenu');
    newParams.delete('currentActiveMenuId');
    newParams.delete('subCategory');
    newParams.delete('category');
    
    const _filters = { ...filters };
    delete _filters.tecdoc_categoryId;

    handleSetFilters(_filters);
    navigate(`?${newParams.toString()}`, { replace: true });
  };
  const toggleIsDrafts = () => {
    handleChangePage(0);
    setIsCheckedDrafts(!isCheckedDrafts);
  };

  const toggleIsTecDoc = () => {
    handleChangePage(0);
    setIsCheckedTecDoc(!isCheckedTecDoc);
  };

  const totalPages = dataCarSpares?.generalSpares?.meta?.pagination.total
    ? Math.ceil(dataCarSpares?.generalSpares?.meta?.pagination.total / PAGE_COUNT)
    : 0;

  const currentPage = dataCarSpares?.generalSpares?.meta?.pagination.page || 1;

  return (
    <>
      <WrapperSearchFieldCollapse
        isCollapseCatalog={isCollapseCatalog}
        isCollapseFilter={isCollapseFilter}
        onCollapseCatalogClick={() => onCollapseClick('catalog')}
        onCollapseFilterClick={() => onCollapseClick('filter')}
        onSearchParts={handlerSearchFieldByText}
        searchText={filters?.searchText}
        filters={filters}
        searchBy={searchBy}
      />
      <Collapse in={isCollapseFilter} timeout={{ enter: 400, exit: 300 }}>
        <FilterParts
          updateFilters={handlerSearchFieldByBrand}
          numberSearch={numberSearch}
          handleNumbersSearchChange={handleNumbersSearchChange}
          brandIds={filters?.brandIds}
        />
      </Collapse>
      <Collapse in={isCollapseCatalog} timeout={{ enter: 400, exit: 300 }}>
        <CategoryList
          filters={filters}
          dataCategories={dataCategory}
          params={params}
          handleSetFilters={handleSetFilters}
          category={category}
          subCategory={subCategory}
          currentActiveMenu={currentActiveMenu}
          setCategory={setCategory}
          setSubCategory={setSubCategory}
          setCurrentActiveMenu={setCurrentActiveMenu}
          checked={checked}
          setChecked={setChecked}
        />
      </Collapse>
      <BottomNavigate
        category={category}
        currentActiveMenu={{
          name: params?.get('currentActiveMenu') ?? '',
          id: params?.get('currentActiveMenuId') ?? '',
        }}
        subCategory={subCategory}
        clearFilter={clearFilter}
      />
      <Box display="flex" alignItems="center" mt={1} mb={3} gap={2}>
        <Switch
          checked={isCheckedTecDoc}
          onChange={toggleIsTecDoc}
          inputProps={{ 'aria-label': 'controlled' }}
          size="medium"
        />
        <Typography color={isCheckedDrafts ? '#000' : 'rgba(0,0,0,0.43)'}>
          {translateLang('showTecDoc')}
        </Typography>
        <Switch
          checked={isCheckedDrafts}
          onChange={toggleIsDrafts}
          inputProps={{ 'aria-label': 'controlled' }}
          size="medium"
        />
        <Typography color={isCheckedDrafts ? '#000' : 'rgba(0,0,0,0.43)'}>
          {translateLang('showDrafts')}
        </Typography>
      </Box>

      <Box pt={2} boxSizing="border-box">
        <ProductCatalogTable
          handleProductId={handleProductId}
          generalSparesData={generalSparesData}
          loading={loadingCarSpares}
          changePage={handleChangePage}
          page={page}
          totalPages={totalPages}
          currentPage={currentPage}
        />
      </Box>
    </>
  );
};
export default ProductCatalog;
