import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TranslatedField } from '../../components/Layout/components/TranslatedField/TranslatedField';
import { PatchesName } from '../../types/types';

export const AdminTranslate = () => {
  const navigate = useNavigate();
  return (
    <Box
      flex={1}
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Button
        onClick={() => navigate(`/${PatchesName.Clients}`, { replace: true })}
        variant="contained"
        color="secondary"
        style={{
          textTransform: 'none',
          marginBottom: '50px',
        }}
      >
        <TranslatedField
          capitalLetter
          originText={'comeBack'}
          fontSize={16}
          isTranslate
          noWrap
          color="white"
        />
      </Button>
      <Typography variant="h3">Admin translate</Typography>
    </Box>
  );
};
