import React, { FC } from 'react';
import Chip from '@mui/material/Chip';
import { useLocalization } from '../../../localization';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { VehiclesType } from '../../../helpers/types';

interface Props {
  vehicle?: VehiclesType;
  isCatalog?: boolean;
}

export const VehicleItemChip: FC<Props> = ({ vehicle, isCatalog }) => {
  const { translateLang } = useLocalization();

  const getVehicleLabel = (item: VehiclesType): string => {
    if (!isCatalog) {
      return `
      ${item?.carBrand},
      ${item?.carModel},
      ${item?.yearOfManufacture ? `${item?.yearOfManufacture},` : ''}
      ${item?.modificationCar}
    `;
    } else {
      return `
      ${item?.carBrand},
      ${item?.carModel},
      ${item?.yearOfManufacture || ''}
     `;
    }
  };

  const getVehicleItem = () => {
    if (vehicle && !isCatalog) {
      return (
        <Chip
          sx={{
            background: 'white',
            borderRadius: '10px',
            height: '54px',
            color: '#2A3453',
          }}
          variant="filled"
          label={getVehicleLabel(vehicle)}
        />
      );
    } //
    else if (vehicle && isCatalog) {
      return (
        <TranslatedField
          noWrap
          variant="h5"
          fontWeight={200}
          color="#2A3453"
          originText={getVehicleLabel(vehicle)}
        />
      );
    }

    return (
      <>
        {!isCatalog ? (
          <TranslatedField
            noWrap
            isTranslate
            variant="h5"
            color="black"
            originText={`${translateLang('noAutoSelect')}`}
          />
        ) : null}
      </>
    );
  };

  return <>{getVehicleItem()}</>;
};
