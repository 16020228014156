import React from 'react';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useLocalization } from '../../localization';
import { EditingHistoryTableProps, TimePointStatusColor } from './types';

export const EditingHistoryTable: React.FC<EditingHistoryTableProps> = ({ data }) => {
  const { translateLang } = useLocalization();

  const editingHistoryTableHead = [
    translateLang('manager'),
    translateLang('date'),
    translateLang('actionNote'),
  ];

  return (
    <Stack mt={4} mb={2} width="100%" height="460px">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: `${TimePointStatusColor.Light}`,
                '&:last-child th': { border: 0 },
              }}
            >
              {editingHistoryTableHead.map((item, i) => (
                <TableCell key={i}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ verticalAlign: 'top' }}>
            {data?.map(({ id, manager, date, status_note }) => (
              <TableRow
                key={id}
                sx={{
                  '& td, & th': { borderColor: `${TimePointStatusColor.Light}` },
                }}
              >
                <TableCell>
                  {`${manager?.data?.attributes?.customer_contact_info?.data?.attributes?.firstName} ${manager?.data?.attributes?.customer_contact_info?.data?.attributes?.lastName}`}
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{`${new Date(
                    date
                  ).toLocaleDateString()}`}</Typography>
                  <Typography variant="body2">{`${new Date(
                    date
                  ).toLocaleTimeString()}`}</Typography>
                </TableCell>
                <TableCell>{status_note}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
