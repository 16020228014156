import React from 'react';

interface ReturnHook {
  tabs: Array<{
    id: number;
    value: 'orders' | 'clients' | 'providers' | 'returns';
  }>;
}
export const useGetTabs = (): ReturnHook => {
  return {
    tabs: [
      { id: 1, value: 'orders' },
      { id: 2, value: 'clients' },
      { id: 3, value: 'providers' },
    ],
  };
};
