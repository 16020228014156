import React, { ReactNode } from 'react';
import { useLocalization } from '../../../../../localization';
import { HeaderCell } from '../../../../../components/TableProductActive/TableProductActive';
import { TableReturnedItemsRow } from '../components/tablesComponents/TableReturnedItemsRow';
import { ReturnedType } from '../../../../../graphql/queries/hook/useGetOrderItemsReturnedFromCustomers';

interface HookProps {
  returnedItems?: ReturnedType[];
}
interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTableActive(): Array<ReactNode>;
}

export const useGetReturnedItemsTable = ({ returnedItems }: HookProps): ReturnHook => {
  const { translateLang } = useLocalization();

  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      {
        title: translateLang('№'),
        containerProps: { width: '50px', color: '#F1F3F8', align: 'center' },
      },
      {
        title: translateLang('article'),
        containerProps: { width: '120px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('productName'),
        containerProps: { width: '220px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('provider'),
        containerProps: { width: '95px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('reasonReturn'),
        containerProps: { width: '240px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('dateOfReceiving'),
        containerProps: { width: '115px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('returnDate'),
        containerProps: { width: '115px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('returnTo'),
        containerProps: { width: '115px', color: '#f1f3f8', align: 'left' },
      },

      {
        title: '',
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
    ];
  };

  const getRowsTableActive = (): ReactNode[] => {
    if (returnedItems && returnedItems?.length) {
      return returnedItems?.map((item, index) => {
        return <TableReturnedItemsRow key={item?.id || index.toString()} itemRow={item} />;
      });
    }
    return [];
  };

  return {
    getHeadersTable,
    getRowsTableActive,
  };
};
