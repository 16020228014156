import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { addDays, endOfToday, startOfDay, subDays } from 'date-fns';

import { theme } from '../../../../helpers';
import { useLocalization } from '../../../../localization';
import { useDefaultRangeCalendar } from '../../../../hooks';
import { RangeCalendar } from '../../../../legos/rangeCalendar/RangeCalendar';
import { useFiltersSearchParamsForOrders } from '../../../../hooks/useFiltersSearchParamsForOrders';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';

export const ClientReturnsToolbar = () => {
  const { translateLang } = useLocalization();

  const { reportRangeDates } = useDefaultRangeCalendar();
  const { changeDate, selDateTo, selDateFrom } = useFiltersSearchParamsForOrders();

  const [selectDates, setSelectedDates] = useState(getDatesFilters);

  function getDatesFilters(): Date[] {
    if (selDateFrom && selDateTo) {
      return [new Date(selDateFrom), new Date(selDateTo)];
    }
    return [startOfDay(subDays(new Date(), 30)), endOfToday()];
  }

  const handlerChangesDates = (val: Date[]) => {
    setSelectedDates(val);
    changeDate(
      val[0]?.toISOString(),
      val[1] ? addDays(val[1], 1)?.toISOString() : addDays(val[0], 1).toISOString()
    );
  };

  return (
    <>
      <Stack width="100%" flexDirection="row" justifyContent="end" mb={2.5} gap={6.25}>
        <RangeCalendar
          selectedDates={selectDates}
          defaultRangeDates={reportRangeDates}
          setSelectedDates={handlerChangesDates}
        />
      </Stack>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        pt={2.5}
        pl={3.75}
        pr={2}
        pb={2}
        bgcolor={theme.palette.common.white}
        sx={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
      >
        <TranslatedField
          isTranslate
          variant="h5"
          fontSize={26}
          color="black"
          originText={translateLang('return')}
        />
      </Stack>
    </>
  );
};
