import { GetClientsQuery, useGetClientsQuery } from '../__generated__/getClients';
import { RolesEnum } from '../../../types/types';
import { useLocalization } from '../../../localization';

type UserPermission = NonNullable<GetClientsQuery['usersPermissionsUsers']>['data'][0];
type AttributesType = NonNullable<UserPermission['attributes']>;

export interface ClientType {
  id?: UserPermission['id'];
  userName?: NonNullable<AttributesType['username']>;
  firstName?: string | null;
  lastName?: string | null;
}

interface ReturnHook {
  clients: Array<ClientType>;
  isLoading?: boolean;
}

export const useGetManagers = (): ReturnHook => {
  const { updateSelectLanguage } = useLocalization();
  const { data, loading } = useGetClientsQuery({
    variables: {
      sort: ['id:desc'],
      filters: {
        role: {
          or: [
            { name: { eqi: RolesEnum.Manager } },
            { name: { eqi: RolesEnum.Admin } },
            { name: { eqi: RolesEnum.SuperAdmin } },
          ],
        },
      },
      locale: updateSelectLanguage as string,
    },
  });

  if (data?.usersPermissionsUsers?.data) {
    const { data: users } = data.usersPermissionsUsers;
    const clients = users.map(user => {
      return {
        id: user.id,
        userName: user.attributes?.username,
        firstName: user.attributes?.customer_contact_info?.data?.attributes?.firstName,
        lastName: user.attributes?.customer_contact_info?.data?.attributes?.lastName,
      };
    });
    return { clients, isLoading: loading };
  }
  return { clients: [], isLoading: loading };
};
