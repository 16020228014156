import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type PreDeliveryStateQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PreDeliveryStateQuery = { __typename?: 'Query', preDeliveryState: { __typename?: 'PreDeliveryState', idOrder: string, isOpenModal: boolean, orderNumber: string, idUser: string, status: Types.Enum_Order_Status, callParent: Types.Enum_Mode_Delivery_State, isInfoAndPrint: boolean } };


export const PreDeliveryStateDocument = gql`
    query preDeliveryState {
  preDeliveryState @client {
    idOrder
    isOpenModal
    orderNumber
    idUser
    status
    callParent
    isInfoAndPrint
  }
}
    `;

/**
 * __usePreDeliveryStateQuery__
 *
 * To run a query within a React component, call `usePreDeliveryStateQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreDeliveryStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreDeliveryStateQuery({
 *   variables: {
 *   },
 * });
 */
export function usePreDeliveryStateQuery(baseOptions?: Apollo.QueryHookOptions<PreDeliveryStateQuery, PreDeliveryStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreDeliveryStateQuery, PreDeliveryStateQueryVariables>(PreDeliveryStateDocument, options);
      }
export function usePreDeliveryStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreDeliveryStateQuery, PreDeliveryStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreDeliveryStateQuery, PreDeliveryStateQueryVariables>(PreDeliveryStateDocument, options);
        }
export type PreDeliveryStateQueryHookResult = ReturnType<typeof usePreDeliveryStateQuery>;
export type PreDeliveryStateLazyQueryHookResult = ReturnType<typeof usePreDeliveryStateLazyQuery>;
export type PreDeliveryStateQueryResult = Apollo.QueryResult<PreDeliveryStateQuery, PreDeliveryStateQueryVariables>;