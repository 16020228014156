import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { BannerEntityFragmentDoc } from '../../fragments/__generated__/bannerEntity';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type ReplaceBannerMutationVariables = Types.Exact<{
  data: Types.BannerInput;
  id: Types.Scalars['ID'];
}>;


export type ReplaceBannerMutation = { __typename?: 'Mutation', updateBanner?: { __typename?: 'BannerEntityResponse', data?: { __typename?: 'BannerEntity', id?: string | null, attributes?: { __typename?: 'Banner', image: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', id?: string | null, attributes?: { __typename?: 'UploadFile', height?: number | null, width?: number | null, url: string } | null } | null } } | null } | null } | null };


export const ReplaceBannerDocument = gql`
    mutation replaceBanner($data: BannerInput!, $id: ID!) {
  updateBanner(data: $data, id: $id) {
    data {
      ...bannerEntity
    }
  }
}
    ${BannerEntityFragmentDoc}`;
export type ReplaceBannerMutationFn = Apollo.MutationFunction<ReplaceBannerMutation, ReplaceBannerMutationVariables>;

/**
 * __useReplaceBannerMutation__
 *
 * To run a mutation, you first call `useReplaceBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceBannerMutation, { data, loading, error }] = useReplaceBannerMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReplaceBannerMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceBannerMutation, ReplaceBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplaceBannerMutation, ReplaceBannerMutationVariables>(ReplaceBannerDocument, options);
      }
export type ReplaceBannerMutationHookResult = ReturnType<typeof useReplaceBannerMutation>;
export type ReplaceBannerMutationResult = Apollo.MutationResult<ReplaceBannerMutation>;
export type ReplaceBannerMutationOptions = Apollo.BaseMutationOptions<ReplaceBannerMutation, ReplaceBannerMutationVariables>;