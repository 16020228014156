import React, { FC } from 'react';
import { SearchField } from '../../../components/SearchField';
import { useFiltersSearchParamsForOrders } from '../../../hooks/useFiltersSearchParamsForOrders';

interface Props {
  placeholder: string;
  endAdornment?: JSX.Element;
}

export const WrapperSearch: FC<Props> = ({ placeholder, endAdornment }) => {
  const { changeSearchText, searchText } = useFiltersSearchParamsForOrders();
  return (
    <SearchField
      initStateText={searchText}
      executeWithDelay={changeSearchText}
      placeholder={placeholder}
      endAdornment={endAdornment}
    />
  );
};
