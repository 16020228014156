import React, { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { theme } from '../../../../../../helpers';
import { useLocalization } from '../../../../../../localization';
import { useGetClientReturnItemTable } from './hooks/useGetClientReturnItemTable';
import { CustomerReturnItemEntity } from '../../../../../../__generated__/types';
import { TableProductActive } from '../../../../../../components/TableProductActive/TableProductActive';

interface Props {
  loading?: boolean;
  invoiceAmount?: null | number;
  items?: CustomerReturnItemEntity[] | any;
}

export const ClientReturnItemTable: FC<Props> = ({ items, loading, invoiceAmount }) => {
  const { translateLang } = useLocalization();

  const { getHeadersTable, getRowsTableActive } = useGetClientReturnItemTable({
    items,
  });

  return (
    <Box
      boxSizing="border-box"
      width="100%"
      bgcolor={theme.palette.common.white}
      sx={{
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
      }}
    >
      <TableProductActive
        itemsRows={getRowsTableActive()}
        itemsHeader={getHeadersTable()}
        loading={loading}
        boxShadow="none"
      />
      <Stack
        width="100%"
        mb={3}
        flexDirection="row"
        justifyContent="end"
        alignItems="center"
        gap={1.25}
      >
        <Typography fontSize={20} lineHeight="26px" textAlign="end">
          {translateLang('sum')}:{' '}
        </Typography>
        <span
          style={{ fontSize: '26px', color: theme.palette.common.appleGreen, paddingRight: '50px' }}
        >
          {`${invoiceAmount} €`}
        </span>
      </Stack>
    </Box>
  );
};
