import { endOfToday, format, startOfDay, subDays } from 'date-fns';

import {
  Pagination,
  PaginationArg,
  SupplierTransactionEntity,
  SupplierTransactionsFiltersInput,
} from '../../../../../__generated__/types';
import { useGetSupplierTransactionsQuery } from '../../../../../graphql/queries/__generated__/getSupplierTransactions';

export type PaginationTransactionsType = Omit<Pagination, '__typename'>;

interface ReturnHook {
  transactions: Array<SupplierTransactionEntity>;
  pagination?: PaginationTransactionsType;
  isLoading?: boolean;
  supplierTransactionRefetch: () => void;
}

export type FiltersType = {
  searchStrings?: string;
  searchNumbers?: string;
};
interface PropsHook {
  pagination?: PaginationArg;
  filters: SupplierTransactionsFiltersInput;
}

export const useGetSupplierBalance = ({ pagination, filters }: PropsHook): ReturnHook => {
  const {
    data: customerTransactionData,
    loading: supplierTransactionLoading,
    refetch: supplierTransactionRefetch,
  } = useGetSupplierTransactionsQuery({
    variables: {
      filters: {
        ...(filters.dateStart
          ? {
              dateStart: filters.dateStart,
            }
          : {
              dateStart: format(startOfDay(subDays(new Date(), 30)), 'yyyy-MM-dd'),
            }),
        ...(filters.dateEnd
          ? {
              dateEnd: filters.dateEnd,
            }
          : {
              dateEnd: format(endOfToday(), 'yyyy-MM-dd'),
            }),
        supplierId: filters.supplierId,
      },
      pagination,
      sort: ['docDate:desc', 'docNumber:desc'],
    },
  });

  if (customerTransactionData?.supplierTransactions?.data) {
    const pagination: PaginationTransactionsType = {
      page: customerTransactionData?.supplierTransactions.meta.pagination.page,
      pageCount: customerTransactionData?.supplierTransactions.meta.pagination.pageCount,
      pageSize: customerTransactionData?.supplierTransactions.meta.pagination.pageSize,
      total: customerTransactionData?.supplierTransactions.meta.pagination.total,
    };

    return {
      transactions: customerTransactionData?.supplierTransactions.data,
      pagination,
      isLoading: supplierTransactionLoading,
      supplierTransactionRefetch,
    };
  }

  return { transactions: [], isLoading: supplierTransactionLoading, supplierTransactionRefetch };
};
