import React, { FC, useState } from 'react';
import { Button } from '@mui/material';
import { CustomModal } from '../../../components';
import { Input } from '../../../legos';
import { Stack } from '@mui/system';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { useLocalization } from '../../../localization';
import { GetOrderItemsDocument } from '../../../graphql/queries/__generated__/getOrderItems';
import { handlerError } from '../../../helpers/functions';
import { useUpdateOrderItemMutation } from '../../../graphql/mutations/__generated__/updateOrderItem';
import { useHandlerNotificationApp } from '../../../hooks/useHandlerNotificationApp';

interface Props {
  productID: string;
  orderID: string;
  comment: string;
  handleClose(): void;
}

export const ModalComment: FC<Props> = ({ productID, orderID, handleClose, comment }) => {
  const { translateLang, updateSelectLanguage } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();
  const [runUpdateOrderItem] = useUpdateOrderItemMutation();
  const [commentText, setCommentText] = useState(comment);

  const addCommentProduct = async () => {
    try {
      const response = await runUpdateOrderItem({
        variables: {
          id: productID,
          data: {
            comment: commentText,
          },
        },
        refetchQueries: [
          {
            query: GetOrderItemsDocument,
            variables: {
              filters: { order: { id: { eq: orderID } } },
              filtersLocale: { locale: { eqi: updateSelectLanguage } },
            },
          },
        ],
      });
      if (response.data) {
        addNotification({
          messageError: 'Update commet success',
          typeMessage: 'success',
        });
      }
    } catch (error: unknown) {
      handlerError(error);
      addNotification({ messageError: 'Comment Error', typeMessage: 'error' });
    } finally {
      handleClose();
    }
  };

  return (
    <CustomModal
      width={350}
      title={translateLang('writeComment')}
      handleClose={handleClose}
      open={true}
    >
      <Input
        value={commentText}
        onChange={evt => setCommentText(evt.target.value)}
        placeholder={translateLang('writeComment')}
        sx={{
          color: '#2A3453',
        }}
        multiline
      />
      <Stack flexDirection="row" mt={5}>
        <Button
          onClick={handleClose}
          variant="outlined"
          color="error"
          style={{
            width: '80%',
            height: '95%',
            marginRight: '2px',
          }}
        >
          <TranslatedField originText={translateLang('cancel')} fontSize={16} isTranslate noWrap />
        </Button>
        <Button
          onClick={addCommentProduct}
          variant="outlined"
          color="success"
          style={{
            width: '80%',
            height: '95%',
            marginLeft: '2px',
          }}
        >
          OK
        </Button>
      </Stack>
    </CustomModal>
  );
};
