import { GetClientsQuery } from '../__generated__/getClients';
import {
  ContactNotificationFiltersInput,
  Enum_Contactnotification_Status,
  Pagination,
  PaginationArg,
} from '../../../__generated__/types';

import { useGetContactNotificationsQuery } from '../__generated__/getContactNotifications';

type UserPermission = NonNullable<GetClientsQuery['usersPermissionsUsers']>['data'][0];
type AttributesType = NonNullable<UserPermission['attributes']>;

export interface ContactNotificationsType {
  id?: UserPermission['id'];
  userName?: NonNullable<AttributesType['username']>;
  phoneNumbers?: string;
  status: Enum_Contactnotification_Status | null | undefined;
  date?: string;
  message?: string;
}

export type PaginationType = Omit<Pagination, '__typename'>;

interface ReturnHook {
  contactNotifications: Array<ContactNotificationsType>;
  pagination?: PaginationType;
  isLoading?: boolean;
}

// export type FiltersType = {
//   searchStrings?: string;
//   searchNumbers?: number;
// };
interface PropsHook {
  pagination?: PaginationArg;
  filters?: ContactNotificationFiltersInput;
}

export const useGetContactNotifications = ({ pagination, filters }: PropsHook): ReturnHook => {
  const { data, loading: isLoading } = useGetContactNotificationsQuery({
    variables: {
      pagination,
      filters,
      sort: ['id:desc'],
    },
  });

  if (data?.contactNotifications?.data) {
    const contactNotifications: Array<ContactNotificationsType> =
      data?.contactNotifications?.data?.map(subscription => {
        return {
          id: subscription.id || '',
          userName: subscription.attributes?.name || '',
          phoneNumbers: subscription.attributes?.phoneNumber || '',
          status: subscription.attributes?.status,
          date: subscription.attributes?.updatedAt || '',
          message: subscription.attributes?.message || '',
        };
      });
    const pagination: PaginationType = {
      page: data.contactNotifications.meta.pagination.page,
      pageCount: data.contactNotifications.meta.pagination.pageCount,
      pageSize: data.contactNotifications.meta.pagination.pageSize,
      total: data.contactNotifications.meta.pagination.total,
    };
    return { contactNotifications, pagination, isLoading };
  }
  return { contactNotifications: [], isLoading };
};
