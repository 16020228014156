import React, { FC, useEffect, useRef } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { CustomModal } from '../../../../components';
import { DiscountInput, GeneralSparesEntity } from '../../../../__generated__/types';
import { InformSpare } from './InformSpare';
import { TableSuppliers } from './TableSuppliers';
import { useLocalization } from '../../../../localization';
import {
  SetDiscountForSpareMutationVariables,
  useSetDiscountForSpareMutation,
} from '../../../../graphql/mutations/__generated__/setDiscountForSpare';
import { handlerError } from '../../../../helpers/functions';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useHandlerNotificationApp } from '../../../../hooks/useHandlerNotificationApp';
import { GetGeneralSparesDocument } from '../../../../graphql/queries/__generated__/getGeneralSpares';

interface Props {
  open: boolean;
  handleClose: () => void;
  spareItem?: GeneralSparesEntity | null;
}

export const DiscountModal: FC<Props> = ({ open, handleClose, spareItem }) => {
  const { translateLang, updateSelectLanguage } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();
  const [runSetDiscountSpare] = useSetDiscountForSpareMutation();
  const discountsRef = useRef<Array<DiscountInput>>([]);

  const handleChangeDiscountForSuppliers = ({
    id,
    discount,
    supplierId,
    deleteDiscount,
  }: DiscountInput) => {
    const discountItem = discountsRef.current.find(item => item.supplierId === supplierId);
    if (discountItem) {
      if (discount && discount > 0) {
        discountItem.discount = discount;
        discountItem.deleteDiscount = undefined;
        if (id) {
          discountItem.id = id;
        }
      } else if (discount === 0 && id === undefined) {
        discountsRef.current = discountsRef.current.filter(item => item.supplierId !== supplierId);
      } else if (discount === 0 && id !== undefined) {
        discountItem.deleteDiscount = true;
      }
    } else {
      discountsRef.current.push({
        id,
        discount,
        supplierId,
        deleteDiscount,
      });
    }
  };
  const handleSetDiscountForSpare = async () => {
    try {
      if (discountsRef.current.length > 0) {
        const variables: SetDiscountForSpareMutationVariables = {
          generalSpareId: spareItem?.id ?? '',
          locale: updateSelectLanguage,
          discounts: discountsRef.current,
        };
        const resp = await runSetDiscountSpare({
          variables,
          refetchQueries: [
            {
              query: GetGeneralSparesDocument,
              variables: {
                locale: updateSelectLanguage,
                pagination: { limit: 10 },
                filters: {
                  isAvailable: true,
                  hasDiscount: true,
                },
              },
            },
          ],
        });
        if (resp.data?.updateDiscounts?.data?.id) {
          addNotification({
            messageError: translateLang('discountFixed'),
            typeMessage: 'success',
          });
        }
      }
    } catch (err: unknown) {
      handlerError(err);
    } finally {
      handleClose();
    }
  };

  useEffect(() => {
    if (spareItem?.attributes?.discounts?.length) {
      spareItem.attributes.discounts.forEach(item => {
        handleChangeDiscountForSuppliers({
          id: item?.id ?? undefined,
          discount: item?.discount ?? 0,
          supplierId: item?.supplier?.id ?? '',
        });
      });
    }
  }, [spareItem]);

  return (
    <CustomModal
      title={translateLang('discount')}
      handleClose={handleClose}
      open={open}
      bgcolor="#F1F3F8"
      width="800px"
      display="flex"
      flexDirection="column"
    >
      <Stack width="100%" height="100%">
        <InformSpare spareItem={spareItem} />
        <TableSuppliers spareItem={spareItem} changeDiscount={handleChangeDiscountForSuppliers} />
        <Box pt={3} display="flex" justifyContent="center">
          <Button
            onClick={handleSetDiscountForSpare}
            variant="contained"
            // disabled={disableButton}
            style={{
              width: '300px',
              textTransform: 'none',
              backgroundColor: '#5269A3',
              borderRadius: '10px',
              height: '46px',
            }}
          >
            <TranslatedField
              originText="saveChanges"
              fontSize={16}
              isTranslate
              noWrap
              color="#fff"
            />
          </Button>
        </Box>
      </Stack>
    </CustomModal>
  );
};
