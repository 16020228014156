import { Typography } from '@mui/material';
import React, { FC } from 'react';
interface Props {
  value?: string | null | undefined;
  label: string;
  width?: string;
}
export const ModalTypography: FC<Props> = ({ value, label, width }) => {
  return (
    <>
      <Typography
        sx={{
          lineHeight: '25.1px',
          fontSize: '20px',
          fontWeight: 400,
          color: '#000000',
          width: width,
        }}
      >
        {label}: {value}
      </Typography>
    </>
  );
};
