import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type GetVehicleEntityFragment = { __typename?: 'VehicleEntity', id?: string | null, attributes?: { __typename?: 'Vehicle', yearOfManufacture?: number | null, vinCode?: string | null, car?: { __typename?: 'CarEntityResponse', data?: { __typename?: 'CarEntity', id?: string | null, attributes?: { __typename?: 'Car', engines?: any | null, tecdoc_modelId?: string | null, tecdoc_carId?: string | null, tecdoc_carType?: string | null, tecdoc_manuId?: string | null, cylinderCapacityLiter?: number | null, powerHpTo?: number | null, car_locales?: { __typename?: 'CarLocaleRelationResponseCollection', data: Array<{ __typename?: 'CarLocaleEntity', attributes?: { __typename?: 'CarLocale', name?: string | null, constructionType?: string | null, fuelType?: string | null } | null }> } | null, car_brand?: { __typename?: 'CarBrandEntityResponse', data?: { __typename?: 'CarBrandEntity', id?: string | null, attributes?: { __typename?: 'CarBrand', tecdoc_manuId?: string | null, car_brand_locales?: { __typename?: 'CarBrandLocaleRelationResponseCollection', data: Array<{ __typename?: 'CarBrandLocaleEntity', attributes?: { __typename?: 'CarBrandLocale', name?: string | null } | null }> } | null } | null } | null } | null, car_model?: { __typename?: 'CarModelEntityResponse', data?: { __typename?: 'CarModelEntity', id?: string | null, attributes?: { __typename?: 'CarModel', tecdoc_modelId?: string | null, car_model_locales?: { __typename?: 'CarModelLocaleRelationResponseCollection', data: Array<{ __typename?: 'CarModelLocaleEntity', attributes?: { __typename?: 'CarModelLocale', name?: string | null } | null }> } | null } | null } | null } | null } | null } | null } | null } | null };

export const GetVehicleEntityFragmentDoc = gql`
    fragment getVehicleEntity on VehicleEntity {
  id
  attributes {
    yearOfManufacture
    car {
      data {
        id
        attributes {
          car_locales(filters: {locale: {eqi: $locale}}) {
            data {
              attributes {
                name
                constructionType
                fuelType
              }
            }
          }
          car_brand {
            data {
              id
              attributes {
                car_brand_locales(filters: {locale: {eqi: $locale}}) {
                  data {
                    attributes {
                      name
                    }
                  }
                }
                tecdoc_manuId
              }
            }
          }
          car_model {
            data {
              id
              attributes {
                car_model_locales(filters: {locale: {eqi: $locale}}) {
                  data {
                    attributes {
                      name
                    }
                  }
                }
                tecdoc_modelId
              }
            }
          }
          engines
          tecdoc_modelId
          tecdoc_carId
          tecdoc_carType
          tecdoc_manuId
          cylinderCapacityLiter
          powerHpTo
        }
      }
    }
    vinCode
  }
}
    `;