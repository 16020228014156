import React, { ChangeEvent, FC, ReactNode, useState } from 'react';
import { Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { OrderItemType } from '../../../helpers/types';
import { CustomModal } from '../../../components';
import { useLocalization } from '../../../localization';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { useUpdateOrderItemMutation } from '../../../graphql/mutations/__generated__/updateOrderItem';
import { useHandlerNotificationApp } from '../../../hooks/useHandlerNotificationApp';
import { handlerError } from '../../../helpers/functions';

interface Props {
  dataProduct?: OrderItemType | null;
  handleClose: () => void;
  open: boolean;
}

export const ChangeCustomerPriceModal: FC<Props> = ({ dataProduct, handleClose, open }) => {
  const { translateLang } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();
  const [runUpdateOrderItem, { loading }] = useUpdateOrderItemMutation();
  const [customerPrice, setCustomerPrice] = useState(dataProduct?.customerPrice ?? 0);

  const handleTextInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const price = +event.target.value;
    setCustomerPrice(price);
  };
  const handleSetDiscountForSpare = async () => {
    try {
      const response = await runUpdateOrderItem({
        variables: {
          id: dataProduct?.id ?? '',
          data: {
            customer_price: customerPrice,
          },
        },
      });
      if (response.data) {
        handleClose();
        addNotification({
          messageError: 'Update item price success',
          typeMessage: 'success',
        });
      }
    } catch (error: unknown) {
      handlerError(error);
    }
  };

  return (
    <CustomModal
      title={translateLang('customerPrice')}
      handleClose={handleClose}
      open={open}
      bgcolor="#F1F3F8"
      width="460px"
      display="flex"
      flexDirection="column"
    >
      <Stack width="100%" height="100%" p="10px 20px" boxSizing="border-box">
        <InformItem label={translateLang('productName')} value={dataProduct?.productName ?? ''} />
        <InformItem label={translateLang('provider')} value={dataProduct?.provider ?? ''} />
        <InformItem
          label={translateLang('purchasePrice')}
          value={dataProduct?.providerPrice ?? ''}
        />
        <InformItem
          label={translateLang('customerPrice')}
          value={
            <TextField
              autoComplete="off"
              value={customerPrice}
              InputProps={{ inputProps: { min: 0 } }}
              onChange={handleTextInputChange}
              type="number"
            />
          }
        />
        <Button
          onClick={handleSetDiscountForSpare}
          variant="contained"
          style={{
            textTransform: 'none',
            borderRadius: '8px',
            height: '50px',
            marginTop: '20px',
          }}
        >
          <TranslatedField originText="save" fontSize={16} isTranslate noWrap />
        </Button>
        {loading && (
          <Box display="flex" justifyContent="center" py={4}>
            <CircularProgress size={30} />
          </Box>
        )}
      </Stack>
    </CustomModal>
  );
};

interface PropsInformItem {
  label: string;
  value: string | ReactNode;
}
function InformItem({ label, value }: PropsInformItem) {
  return (
    <Box display="flex" width="100%" alignItems="center" mb={2} justifyContent="space-between">
      <Typography mr={3} fontSize="20px">{`${label}: `}</Typography>
      {typeof value === 'string' ? <Typography fontSize="20px">{`${value}`}</Typography> : value}
    </Box>
  );
}
