import React, { FC } from 'react';
import { Box } from '@mui/material';

import { IncomingInvoiceEntity } from '../../../../__generated__/types';
import { useGetIncomingInvoicesTable } from './hooks/useGetIncomingInvoicesTable';
import { TableProductActive } from '../../../../components/TableProductActive/TableProductActive';

interface Props {
  totalPage: number;
  items?: IncomingInvoiceEntity[] | any;
  currentPage?: number;
  changePage(page: number): void;
  loading?: boolean;
  toggleInvoiceTable: (id: string) => void;
  refetchSupplierBalance: () => void;
}

export const IncomingInvoicesTabTable: FC<Props> = ({
  items,
  totalPage,
  currentPage,
  changePage,
  loading,
  toggleInvoiceTable,
  refetchSupplierBalance,
}) => {
  const { getHeadersTable, getRowsTableActive } = useGetIncomingInvoicesTable({
    items,
    toggleInvoiceTable,
    refetchSupplierBalance,
  });

  return (
    <>
      <Box boxSizing="border-box" width="100%">
        <TableProductActive
          startPage={currentPage}
          totalPages={totalPage}
          itemsRows={getRowsTableActive()}
          itemsHeader={getHeadersTable()}
          changeCurrentPage={changePage}
          loading={loading}
        />
      </Box>
    </>
  );
};
