import React, { FC } from 'react';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { Button, Stack } from '@mui/material';
import { useGetMe } from '../../../graphql/queries/hook/useGetMe';

interface Props {
  handlerButton(): void;
}

export const ButtonSaveOrder: FC<Props> = ({ handlerButton }) => {
  const { username } = useGetMe();
  return (
    <Stack direction="row" spacing={5} alignItems="center">
      <TranslatedField fontSize="20px" originText="manager" suffix={`: ${username}`} isTranslate />
      <Button
        onClick={handlerButton}
        variant="contained"
        color="secondary"
        style={{
          minWidth: '220px',
          textTransform: 'none',
          backgroundColor: '#5269A3',
          borderRadius: '10px',
          height: '46px',
        }}
      >
        <TranslatedField
          originText="saveOrder"
          fontSize={16}
          isTranslate
          noWrap
          // color={textColor}
        />
      </Button>
    </Stack>
  );
};
