import React, { FC } from 'react';
import { Typography, TypographyProps } from '@mui/material';

interface Props extends TypographyProps {
  text: string;
  textNavigate?: string;
  actionNavigate?: () => void;
}
export const StrokeForNavigate: FC<Props> = ({ text, textNavigate, actionNavigate, ...props }) => {
  const handlerSpan = () => {
    if (actionNavigate) {
      actionNavigate();
    }
  };
  return (
    <Typography textAlign="center" fontSize="18px" lineHeight="18px" {...props}>
      {`${text}  `}
      {textNavigate && (
        <span
          onClick={handlerSpan}
          style={{ cursor: 'pointer', color: '#F44336', fontWeight: 'bold' }}
        >
          {textNavigate}
        </span>
      )}
    </Typography>
  );
};
