import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Link, List, ListItem, Stack, Typography } from '@mui/material';

import { authNav } from '../helpers/helpers';
import { useLocalization } from '../../../localization';
import imgBanner from '../../../assets/loginBanner.png';
import { useGetIsMobileQuery } from '../../../graphql/cacheQuery/__generated__/getIsMobile';

export const AuthorizationLayout = () => {
  const { translateLang } = useLocalization();
  const { data } = useGetIsMobileQuery();

  if (data?.isMobile) {
    return (
      <Box width="100%" boxSizing="border-box">
        <Outlet />
      </Box>
    );
  }
  return (
    <Box
      minHeight="100vh"
      display="flex"
      alignItems="center"
      justifyContent="space-around"
      flexDirection="column"
      width="100%"
      boxSizing="border-box"
      pt="74px"
      pb={2}
    >
      <Stack
        height="100%"
        width="100%"
        component="main"
        flex="1 1 auto"
        alignItems="center"
        direction="column"
        mb={5}
      >
        <Box
          width="calc(100% - 30px)"
          height="45%"
          overflow="hidden"
          borderRadius="15px"
          position="absolute"
          top="15px"
          left="15px"
          zIndex={-1}
          sx={{
            backgroundImage: `url(${imgBanner})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Box
          color="#fff"
          width="500px"
          ml="auto"
          mr="auto"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography textAlign="center" fontSize="40px" lineHeight="50px" mb="6px">
            {translateLang('signInAutoBro')}
          </Typography>
          <Typography
            width="360px"
            textAlign="center"
            color="#fff"
            fontSize="14px"
            lineHeight="20px"
            mb={2}
            sx={{
              '@media (max-height: 860px)': {
                display: 'none',
              },
            }}
          >
            {translateLang('subTitleLogIn')}
          </Typography>
        </Box>
        <Box maxWidth="520px" width="calc(100% - 40px)">
          <Box
            width="100%"
            height="auto"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Outlet />
          </Box>
        </Box>
      </Stack>
      <Box
        component="footer"
        display="flex"
        width="100%"
        maxWidth={1050}
        justifyContent="center"
        alignItems="center"
      >
        <Typography textAlign="end" color="#A0AEC0" fontSize="12px" lineHeight="18px">
          {` @ ${new Date().getFullYear()},  ${translateLang('madeWithLove')}`}{' '}
          <span style={{ color: '#F44336', fontWeight: 'bold', whiteSpace: 'nowrap' }}>
            {translateLang('softBeeTeam')}
          </span>
        </Typography>
      </Box>
    </Box>
  );
};
