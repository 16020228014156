import React, { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { theme } from '../../../../../../helpers';
import { useLocalization } from '../../../../../../localization';
import { StockEntity } from '../../../../../../__generated__/types';
import { useGetIncomingInvoiceTable } from './hooks/useGetIncomingInvoiceTable';
import { TableProductActive } from '../../../../../../components/TableProductActive/TableProductActive';
import { GetIncomingInvoiceQuery } from '../../../../../../graphql/queries/__generated__/getIncomingInvoice';
import { formatDate } from '../../../../../../helpers/functions';
interface Props {
  loading?: boolean;
  items?: StockEntity[] | any;
  incomingInvoiceData?: GetIncomingInvoiceQuery;
}

export const IncomingInvoiceTable: FC<Props> = ({ items, loading, incomingInvoiceData }) => {
  const { translateLang } = useLocalization();

  const invoiceId = incomingInvoiceData?.incomingInvoice?.data?.id;
  const invoiceStatus = incomingInvoiceData?.incomingInvoice?.data?.attributes?.status;

  const { getHeadersTable, getRowsTableActive } = useGetIncomingInvoiceTable({
    items,
    invoiceId,
    invoiceStatus,
  });

  const invoiceDate = incomingInvoiceData?.incomingInvoice?.data?.attributes?.supplierInvoiceDate;
  const acceptanceDate = formatDate(incomingInvoiceData?.incomingInvoice?.data?.attributes?.date);

  const invoiceAmount =
    incomingInvoiceData?.incomingInvoice?.data?.attributes?.supplierInvoiceTotal;
  const invoiceActualAmount = incomingInvoiceData?.incomingInvoice?.data?.attributes?.total;

  return (
    <Box
      boxSizing="border-box"
      width="100%"
      bgcolor={theme.palette.common.white}
      sx={{
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
      }}
    >
      <TableProductActive
        itemsRows={getRowsTableActive()}
        itemsHeader={getHeadersTable()}
        loading={loading}
        boxShadow="none"
      />
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        mb={3}
        mx={3.75}
        pt={3}
        borderTop="1px solid #2a345380"
      >
        <Stack flexDirection="row" gap={5}>
          <Stack flexDirection="column">
            <Typography fontSize="14px" color="#19151699" whiteSpace="nowrap">
              {`${translateLang('dateOfInvoice')}:`}{' '}
              <span style={{ color: '#191516' }}>{invoiceDate}</span>
            </Typography>
            <Typography fontSize="14px" color="#19151699" whiteSpace="nowrap">
              {`${translateLang('acceptanceDate')}:`}{' '}
              <span style={{ color: '#191516' }}>{acceptanceDate}</span>
            </Typography>
          </Stack>
          <Stack flexDirection="column">
            <Typography fontSize="14px" color="#19151699" whiteSpace="nowrap">
              {`${translateLang('invoiceAmount')}:`}{' '}
              <span style={{ color: '#191516' }}>{`${invoiceAmount} €`}</span>
            </Typography>
            <Typography fontSize="14px" color="#19151699" whiteSpace="nowrap">
              {`${translateLang('actualAmount')}:`}{' '}
              <span style={{ color: '#191516' }}>{`${invoiceActualAmount} €`}</span>
            </Typography>
          </Stack>
        </Stack>
        <Stack width="100%" flexDirection="row" justifyContent="end" alignItems="center" gap={1.25}>
          <Typography fontSize={20} lineHeight="26px" textAlign="end">
            {translateLang('sum')}:{' '}
          </Typography>
          <span
            style={{
              fontSize: '26px',
              color: theme.palette.common.appleGreen,
            }}
          >
            {`${invoiceActualAmount} €`}
          </span>
        </Stack>
      </Stack>
    </Box>
  );
};
