import React, { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useLocalization } from '../../../../../localization';
interface Props {
  reviewsUserName: string;
  spareName: string;
  date: string;
}
export const ReviewDetail: FC<Props> = ({ date, reviewsUserName, spareName }) => {
  const { translateLang } = useLocalization();

  return (
    <Box width="85%" mt="30px">
      <Stack direction="row" justifyContent="flex-start" alignItems="baseline" spacing={2}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography sx={{ fontSize: '14px', lineHeight: '17.57px', marginRight: '10px' }}>
            {`${translateLang('goods')}:`}
          </Typography>
          <Typography sx={{ fontSize: '12px', lineHeight: '15px' }}>{spareName}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography sx={{ fontSize: '14px', lineHeight: '17.57px', marginRight: '10px' }}>
            {`${translateLang('date')}:`}
          </Typography>
          <Typography sx={{ fontSize: '12px', lineHeight: '15px' }}>{date}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography sx={{ fontSize: '14px', lineHeight: '17.57px', marginRight: '10px' }}>
            {`${translateLang('Customer')}:`}
          </Typography>
          <Typography sx={{ fontSize: '12px', lineHeight: '15px' }}>{reviewsUserName}</Typography>
        </Box>
      </Stack>
    </Box>
  );
};
