import React, { CSSProperties, FC } from 'react';
import { Button, Stack } from '@mui/material';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';

interface Props {
  disableButtonOrder: boolean;
  disableButtonAuto: boolean;
  handlerButtonEditOrder(): void;
  handlerButtonAutoOrder(): void;
}

export const ButtonsWrapper: FC<Props> = ({
  disableButtonOrder,
  disableButtonAuto,
  handlerButtonEditOrder,
  handlerButtonAutoOrder,
}) => {
  const styleButton: CSSProperties = {
    minWidth: '220px',
    textTransform: 'none',
    borderRadius: '10px',
    height: '46px',
  };
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Button
        disabled={disableButtonOrder}
        onClick={handlerButtonEditOrder}
        variant="contained"
        style={{ ...styleButton, backgroundColor: disableButtonOrder ? '#c0c0c0' : '#5269A3' }}
      >
        <TranslatedField
          originText="editOrder"
          fontSize={16}
          isTranslate
          noWrap
          color={disableButtonOrder ? '#8e8e8e' : '#fff'}
        />
      </Button>
      <Button
        disabled={disableButtonAuto}
        onClick={handlerButtonAutoOrder}
        variant="contained"
        style={{ ...styleButton, backgroundColor: disableButtonAuto ? '#c0c0c0' : '#5269A3' }}
      >
        <TranslatedField
          originText="automaticOrder"
          fontSize={16}
          isTranslate
          noWrap
          color={disableButtonAuto ? '#8e8e8e' : '#fff'}
        />
      </Button>
    </Stack>
  );
};
