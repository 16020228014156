import React, { FC } from 'react';
import { useLocalization } from '../../../../../localization';
import { Button, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { StepReturnComponent } from '../../MobileDeliveryOrderInfo';
interface Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<StepReturnComponent>>;
}
export const ReturnCheckList: FC<Props> = ({ setCurrentStep }) => {
  const { translateLang } = useLocalization();
  const returnCheckList = translateLang('returnCheckList');
  const delimiter = ', ';

  const returnCheckListArray = returnCheckList.split(delimiter);
  return (
    <Stack alignItems="center" height="100%" bgcolor="#F1F3F8">
      <Stack alignItems="center">
        <Typography
          sx={{
            lineHeight: '25.1px',
            fontSize: '20px',
            fontWeight: '600',
            color: '#2A3453',
            textAlign: 'center',
            mt: '30px',
            mb: '48px',
            width: '300px',
          }}
        >
          {translateLang('returnCheckListHeader')}
        </Typography>
        <List component="nav" sx={{ mx: '10px', my: '40px' }}>
          {returnCheckListArray.map((item, index) => (
            <ListItem key={index}>
              <ListItemText
                sx={{
                  lineHeight: '25.1px',
                  fontSize: '20px',
                  fontWeight: '600',
                  color: '#000000',
                }}
                primary={`${index + 1}.\xa0\xa0\xa0 ${item}`}
              />
            </ListItem>
          ))}
        </List>
      </Stack>
      <Button
        onClick={() => {
          setCurrentStep(StepReturnComponent.Step3);
        }}
        variant="contained"
        color="secondary"
        style={{
          textTransform: 'none',
          width: '300px',
          height: '38px',
          marginTop: '260px',
        }}
      >
        {translateLang('nextBtn')}
      </Button>
    </Stack>
  );
};
