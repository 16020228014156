import React, { ChangeEvent, FC, useState } from 'react';
import { TextField } from '@mui/material';
import { TableProductActive } from '../../../../../components/TableProductActive/TableProductActive';
import { RolesEnum } from '../../../../../types/types';
import { useGetPriceMarkupWhitRole } from '../../../../CheckoutPage/hooks/useGetPriceMarkup';
import { DiscountInput } from '../../../../../__generated__/types';

interface Props {
  providerID?: string | null;
  discountID?: string | null;
  providerName?: string | null;
  providerPrice?: number | null;
  providerDiscount?: number | null;
  changeDiscount(props: DiscountInput): void;
}

export const TableSupplierRowItem: FC<Props> = ({
  providerID,
  discountID,
  providerName,
  providerPrice,
  providerDiscount,
  changeDiscount,
}) => {
  const { priceCalculatorWithRole } = useGetPriceMarkupWhitRole();
  const [currentDiscount, setCurrentDiscount] = useState(providerDiscount ?? 0);

  const getPricesUsers = (role: RolesEnum) => {
    if (providerPrice) {
      return priceCalculatorWithRole(providerPrice, currentDiscount, role);
    }
    return 0;
  };
  const handleTextInputChange = (evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const discount = +evt.target.value;
    setCurrentDiscount(discount);
    const props: DiscountInput = {
      id: discountID,
      discount,
      supplierId: providerID,
    };
    changeDiscount(props);
  };

  return (
    <TableProductActive.RowTable>
      <TableProductActive.CellTable ceilStyle={{ borderLeft: 'none' }}>
        {providerName}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable>{providerPrice}</TableProductActive.CellTable>
      <TableProductActive.CellTable>
        <TextField
          autoComplete="off"
          fullWidth
          value={currentDiscount}
          InputProps={{ inputProps: { min: 0 }, sx: { height: '40px', fontSize: '12px' } }}
          onChange={handleTextInputChange}
          type="number"
        />
      </TableProductActive.CellTable>
      <TableProductActive.CellTable>
        {getPricesUsers(RolesEnum.Customer)}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable>
        {getPricesUsers(RolesEnum.WholesaleCustomer)}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable>
        {getPricesUsers(RolesEnum.PermanentWholesaleCustomer)}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable>
        {getPricesUsers(RolesEnum.Customer40)}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable>
        {getPricesUsers(RolesEnum.Customer30)}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable>
        {getPricesUsers(RolesEnum.Manager)}
      </TableProductActive.CellTable>
    </TableProductActive.RowTable>
  );
};
