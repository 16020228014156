import { Stack } from '@mui/material';
import React, { DragEvent } from 'react';
import { FormikErrors, FormikTouched } from 'formik';

import { theme } from '../../../helpers';
import { IncomingInvoiceDetails } from '../types';
import { CalendarMUI, Input } from '../../../legos';
import { TranslatedField } from '../../Layout/components/TranslatedField/TranslatedField';
import { formatCurrencyAmount } from '../../../helpers/functions';

interface IncomingInvoiceFormProps {
  index: number;
  values: IncomingInvoiceDetails;
  errors?: FormikErrors<IncomingInvoiceDetails>;
  touched?: FormikTouched<IncomingInvoiceDetails>;
  dragEndHandler: (event: DragEvent<HTMLDivElement>) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<(FormikErrors<IncomingInvoiceDetails> | undefined)[]>;
}

export const IncomingInvoiceForm = ({
  index,
  values,
  errors,
  touched,
  setFieldValue,
}: IncomingInvoiceFormProps) => {
  return (
    <Stack width="100%" flexDirection="row" mb={1} gap={2}>
      <Stack justifyContent="start" flexDirection="column" alignItems="baseline" gap={3}>
        <TranslatedField
          noWrap
          isTranslate
          fontSize={16}
          overflow="initial"
          originText="numberSecond"
        />
        <Input
          disabled={index === 0}
          value={values.invoice.supplierInvoiceNumber}
          onChange={e => {
            setFieldValue(`[${index}].invoice.supplierInvoiceNumber`, e.target.value);
          }}
          error={
            !!(touched?.invoice?.supplierInvoiceNumber && errors?.invoice?.supplierInvoiceNumber)
          }
          helperText={
            touched?.invoice?.supplierInvoiceNumber ? errors?.invoice?.supplierInvoiceNumber : ''
          }
          inputProps={{
            style: {
              height: 12,
              paddingLeft: 0,
              textAlign: 'center',
              color: theme.palette.common.black,
              boxShadow: ' 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset',
            },
          }}
          bgcolor={theme.palette.common.white}
          sx={{
            ml: 'auto',
            maxWidth: '160px',
          }}
        />
      </Stack>
      <Stack gap={3} alignItems="baseline" flexDirection="column" justifyContent="start">
        <TranslatedField originText="date" fontSize={16} isTranslate noWrap overflow="initial" />
        <CalendarMUI
          disableFuture
          isLabel={false}
          disabled={index === 0}
          value={values.invoice.supplierInvoiceDate}
          setValue={(newDate: Date | null) =>
            setFieldValue(`[${index}].invoice.supplierInvoiceDate`, newDate)
          }
          error={touched?.invoice?.supplierInvoiceDate ? errors?.invoice?.supplierInvoiceDate : ''}
          inputStyle={{
            p: 0,
            height: 40,
            width: '100%',
            bgcolor: theme.palette.common.white,
            boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset',
            '& .MuiInputBase-root': {
              p: '5px 15px',
            },
          }}
        />
      </Stack>

      <Stack justifyContent="start" flexDirection="column" alignItems="baseline" gap={3}>
        <TranslatedField originText="amount" fontSize={16} isTranslate noWrap overflow="initial" />
        <Input
          disabled={index === 0}
          value={`${values.invoice.supplierInvoiceTotal}€`}
          onChange={event => {
            const value = formatCurrencyAmount(event.target.value);
            setFieldValue(`[${index}].invoice.supplierInvoiceTotal`, value);
          }}
          error={
            !!(touched?.invoice?.supplierInvoiceTotal && errors?.invoice?.supplierInvoiceTotal)
          }
          helperText={
            touched?.invoice?.supplierInvoiceTotal ? errors?.invoice?.supplierInvoiceTotal : ''
          }
          inputProps={{
            style: {
              height: 12,
              paddingLeft: 0,
              textAlign: 'center',
              color: theme.palette.common.black,
              boxShadow: ' 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset',
            },
          }}
          bgcolor={theme.palette.common.white}
          sx={{
            ml: 'auto',
            maxWidth: '160px',
          }}
        />
      </Stack>
    </Stack>
  );
};
