import deAT from 'date-fns/locale/de-AT';
import ukUK from 'date-fns/locale/uk';
import enGb from 'date-fns/locale/en-GB';
import { Enum_Language_Type } from '../__generated__/types';
import { SetSelectLanguageUserQuery } from '../graphql/store/__generated__/setSelectLanguageUser';

export const getCalendarLocalization = (lang: SetSelectLanguageUserQuery | undefined) => {
  if (lang?.selectLanguage === Enum_Language_Type.Uk) {
    return ukUK;
  }
  if (lang?.selectLanguage === Enum_Language_Type.De) {
    return deAT;
  }
  if (lang?.selectLanguage === Enum_Language_Type.En) {
    return enGb;
  }
};
