import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalization } from '../../localization';
import { WrapperPages } from '../../components/Wrapper/WrapperPages';
import { AddClientModal } from '../ClientInfo/components/Modals';
import { PatchesName } from '../../types/types';
import { TableClients } from './components/TableClients';

export const ClientsList = () => {
  const navigate = useNavigate();
  const { translateLang } = useLocalization();

  const [openModal, setOpenModal] = useState<boolean>(false);

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <WrapperPages
      isButton
      titleButton={translateLang('addClients')}
      titleHeader={translateLang('clients')}
      handlerButton={() => setOpenModal(true)}
    >
      <TableClients handlerCellClick={idUser => navigate(`/${PatchesName.Clients}/${idUser}`)} />
      <AddClientModal closeModal={closeModal} openModal={openModal} />
    </WrapperPages>
  );
};
