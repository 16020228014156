import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type GetCustomerAccountsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.CustomerAccountFiltersInput>;
  pagination?: Types.InputMaybe<Types.PaginationArg>;
  sort?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
}>;


export type GetCustomerAccountsQuery = { __typename?: 'Query', customerAccounts?: { __typename?: 'CustomerAccountEntityResponseCollection', data: Array<{ __typename?: 'CustomerAccountEntity', id?: string | null, attributes?: { __typename?: 'CustomerAccount', total?: number | null, customerId: any } | null }> } | null };


export const GetCustomerAccountsDocument = gql`
    query getCustomerAccounts($filters: CustomerAccountFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  customerAccounts(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        total
        customerId
      }
    }
  }
}
    `;

/**
 * __useGetCustomerAccountsQuery__
 *
 * To run a query within a React component, call `useGetCustomerAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerAccountsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetCustomerAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerAccountsQuery, GetCustomerAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerAccountsQuery, GetCustomerAccountsQueryVariables>(GetCustomerAccountsDocument, options);
      }
export function useGetCustomerAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerAccountsQuery, GetCustomerAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerAccountsQuery, GetCustomerAccountsQueryVariables>(GetCustomerAccountsDocument, options);
        }
export type GetCustomerAccountsQueryHookResult = ReturnType<typeof useGetCustomerAccountsQuery>;
export type GetCustomerAccountsLazyQueryHookResult = ReturnType<typeof useGetCustomerAccountsLazyQuery>;
export type GetCustomerAccountsQueryResult = Apollo.QueryResult<GetCustomerAccountsQuery, GetCustomerAccountsQueryVariables>;