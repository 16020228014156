import React, { ChangeEvent, FC, useState } from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useLocalization } from '../../../../localization';
import { CustomModal } from '../../../../components';
import { MarkupInput } from '../../../../__generated__/types';
import { useWriteNewMarkupMutation } from '../../../../graphql/mutations/__generated__/writeNewMarkup';
import { handlerError } from '../../../../helpers/functions';
import { useHandlerNotificationApp } from '../../../../hooks/useHandlerNotificationApp';

interface Props {
  handleClose(): void;
  open: boolean;
  currentMarkup: number;
  customerMarkup: keyof MarkupInput;
}

export const MarkupModal: FC<Props> = ({ handleClose, open, currentMarkup, customerMarkup }) => {
  const { translateLang } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();
  const [tax, setTax] = useState(currentMarkup);
  const [runWriteNewMarkup] = useWriteNewMarkupMutation();

  const handleTextInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setTax(+evt.target.value);
  };
  const changeMarkupProduct = async () => {
    try {
      const response = await runWriteNewMarkup({
        variables: {
          data: {
            [customerMarkup]: tax,
          },
        },
      });
      if (response?.data) {
        addNotification({
          messageError: `[SUCCESS] ${translateLang('markupChanged')}`,
          typeMessage: 'success',
        });
      }
    } catch (err: unknown) {
      handlerError(err);
    } finally {
      handleClose();
    }
  };

  return (
    <CustomModal
      title={translateLang('markupValue')}
      handleClose={handleClose}
      open={open}
      bgcolor="#F1F3F8"
      width="500px"
      display="flex"
      flexDirection="column"
    >
      <Stack width="100%" height="100%" p="10px 0" boxSizing="border-box">
        <Typography fontSize="32px" lineHeight="40px" fontWeight={600}>
          {translateLang('wantValueMarkup')}
        </Typography>
        <Typography mt={3} fontSize="18px" fontWeight={400}>
          {translateLang('changeValueMarkup')}
        </Typography>
        <Box
          display="flex"
          width="100%"
          alignItems="center"
          mb={2}
          mt={3}
          justifyContent="space-between"
        >
          <Typography mr={1} fontSize="18px">{`${translateLang('productMarkup')}: %`}</Typography>
          <TextField
            sx={{
              width: '48%',
            }}
            autoComplete="off"
            value={tax}
            InputProps={{ inputProps: { min: 0, max: 1000 } }}
            onChange={handleTextInputChange}
            type="number"
          />
        </Box>
        <Box display="flex" width="100%" justifyContent="space-between">
          <Button
            onClick={handleClose}
            variant="outlined"
            color="secondary"
            style={{
              width: '48%',
              height: '46px',
              borderRadius: '6px',
            }}
          >
            {translateLang('cancel')}
          </Button>
          <Button
            onClick={changeMarkupProduct}
            variant="contained"
            disabled={currentMarkup === tax}
            style={{
              minWidth: '48%',
              textTransform: 'none',
              backgroundColor: currentMarkup === tax ? 'rgba(82,105,163,0.53)' : '#5269A3',
              borderRadius: '6px',
              height: '46px',
              color: currentMarkup === tax ? 'rgba(241,241,241,0.53)' : '#ffffff',
            }}
          >
            OK
          </Button>
        </Box>
      </Stack>
    </CustomModal>
  );
};
