import { LocalStorageKey } from '../helpers/types';

interface ReturnLocalStorage {
  jwt: string;
  setStorageValue(value: string): void;
  clearJwtKey(): void;
}

export const useLocalStorage = (): ReturnLocalStorage => {
  const storedValue = JSON.parse(window.localStorage.getItem(LocalStorageKey.JWT) as string);

  const setStorageValue = (value: string) => {
    window.localStorage.setItem(LocalStorageKey.JWT, JSON.stringify(value));
  };

  const clearJwtKey = () => {
    window.localStorage.removeItem(LocalStorageKey.JWT);
  };

  return {
    jwt: storedValue,
    setStorageValue,
    clearJwtKey,
  };
};
