import React, { CSSProperties, FC } from 'react';
import { Button, Stack } from '@mui/material';
import { useLocalization } from '../../../localization';
import { EnumTabsAuthState } from '../helpers/types';

interface Props {
  currentState: EnumTabsAuthState;
  changeState(state: EnumTabsAuthState): void;
}
const styleButton: CSSProperties = {
  textTransform: 'none',
  fontSize: '20px',
  width: '220px',
  height: '50px',
};
export const TabsAuth: FC<Props> = ({ currentState, changeState }) => {
  const { translateLang } = useLocalization();
  const getColor = (state: EnumTabsAuthState): CSSProperties => {
    return {
      backgroundColor: currentState === state ? '#FEEDEB' : 'transparent',
      color: currentState === state ? '#F44336' : '#979797',
    };
  };
  return (
    <Stack my={4} display="flex" flexDirection="row" justifyContent="center" width="100%">
      <Button
        variant="text"
        sx={{ ...styleButton, ...getColor(EnumTabsAuthState.Email) }}
        onClick={() => changeState(EnumTabsAuthState.Email)}
      >
        {translateLang('email')}
      </Button>
      <Button
        variant="text"
        sx={{ ...styleButton, ...getColor(EnumTabsAuthState.Phone) }}
        onClick={() => changeState(EnumTabsAuthState.Phone)}
      >
        {translateLang('phone')}
      </Button>
    </Stack>
  );
};
