import { RolesEnum } from '../../../types/types';
import { GetClientsQuery, useGetClientsQuery } from '../__generated__/getClients';
import { useLocalization } from '../../../localization';
import { getUserFullName } from '../../../utils/getUserFullName';

type UserPermission = NonNullable<GetClientsQuery['usersPermissionsUsers']>['data'][0];

export interface ClientType {
  id?: UserPermission['id'];
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string;
}

interface ReturnHook {
  couriers: Array<ClientType>;
  isLoading?: boolean;
}

export const useGetCourier = (): ReturnHook => {
  const { updateSelectLanguage, translateLang } = useLocalization();
  const { data, loading } = useGetClientsQuery({
    variables: {
      sort: ['id:desc'],
      filters: {
        role: {
          name: {
            containsi: RolesEnum.Courier,
          },
        },
      },
      locale: updateSelectLanguage as string,
    },
  });

  if (data?.usersPermissionsUsers?.data) {
    const { data: users } = data.usersPermissionsUsers;
    const couriers = users.map(user => {
      return {
        id: user.id,
        userName: user.attributes?.username,
        firstName: user.attributes?.customer_contact_info?.data?.attributes?.firstName,
        lastName: user.attributes?.customer_contact_info?.data?.attributes?.lastName,
        fullName: getUserFullName(user),
      };
    });
    return { couriers, isLoading: loading };
  }
  return { couriers: [], isLoading: loading };
};
