import React, { FC } from 'react';
import { Button } from '@mui/material';
import { TableProductActive } from '../../../../components/TableProductActive/TableProductActive';
import { useLocalization } from '../../../../localization';

interface Props {
  nameCustomer: string;
  markup: number;
  handlerButton(): void;
}

export const TableMarkupRowItem: FC<Props> = ({ nameCustomer, markup, handlerButton }) => {
  const { translateLang } = useLocalization();
  return  <TableProductActive.RowTable>
    <TableProductActive.CellTable ceilStyle={{ borderLeft: 'none', justifyContent: 'flex-start', padding: '0 0 0 30px' }}>
      {translateLang(nameCustomer)}
    </TableProductActive.CellTable>
    <TableProductActive.CellTable>{`${markup} %`}</TableProductActive.CellTable>
    <TableProductActive.CellTable  ceilStyle={{ justifyContent: 'flex-end', padding: '5px 30px 5px 0' }}>
      <Button
        onClick={handlerButton}
        variant="outlined"
        color="success"
        style={{
          width: '250px',
          height: '40px',
          marginLeft: '2px',
        }}
      >
        {translateLang('changeValue')}
      </Button>
    </TableProductActive.CellTable>
  </TableProductActive.RowTable>;
};
