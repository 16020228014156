import React, { useState } from 'react';
import { Box } from '@mui/system';
import { endOfToday, startOfDay, subDays } from 'date-fns';

import { PAGE_COUNT } from '../../../helpers/constants';
import { ContactNotificationFiltersInput } from '../../../__generated__/types';
import { ContactNotificationsTable } from './ContactNotificationsTab/ContactNotificationsTable';
import { ToolBarContactNotifications } from './ContactNotificationsTab/ToolBarContactNotifications';
import { useGetContactNotifications } from '../../../graphql/queries/hook/useGetContactNotifications';

export const ContactNotifications = () => {
  const [page, setPage] = useState(0);
  const [filterSubscription, setFilterSubscription] = useState<ContactNotificationFiltersInput>({
    createdAt: {
      between: [startOfDay(subDays(new Date(), 30)).toISOString(), endOfToday().toISOString()],
    },
  });
  const [resetFilterOrders, setResetFilterOrders] = useState(new Date().getTime().toString());
  const { contactNotifications, pagination, isLoading } = useGetContactNotifications({
    pagination: {
      page: page + 1,
      pageSize: PAGE_COUNT,
    },

    filters: filterSubscription,
  });
  const handlerReset = () => {
    setFilterSubscription({
      createdAt: {
        between: [startOfDay(subDays(new Date(), 30)).toISOString(), endOfToday().toISOString()],
      },
    });
    setResetFilterOrders(new Date().getTime().toString());
  };

  return (
    <Box width="100%">
      <ToolBarContactNotifications
        key={resetFilterOrders}
        result={pagination?.total || ''}
        setFilterSubscription={setFilterSubscription}
        setResetFilter={handlerReset}
      />
      <ContactNotificationsTable
        subscription={contactNotifications}
        pagination={pagination}
        setPage={setPage}
        pageCount={PAGE_COUNT}
        isLoading={isLoading}
      />
    </Box>
  );
};
