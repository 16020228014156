import React, { useRef, useState } from 'react';

import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { handlerError } from '../../../../../../helpers/functions';
import { PatchesName } from '../../../../../../types/types';
import { ScanerStep } from '../CompleteOrderScanerPage/ScanerStep';
import { ManualSearch } from '../CompleteOrderScanerPage/ManualSearch';
import { NonSearched } from '../CompleteOrderScanerPage/NonSearched';
import { MobileTopNavBar } from '../../../MobileTopNavBar';
import { MODE } from '../../types';
import { useGetOrderItemForReturnLazyQuery } from '../../../../../../graphql/queries/__generated__/getOrderItemForReturn';
import { useLocalization } from '../../../../../../localization';
import { OrderItemType } from '../../../../../../helpers/types';
export const ReturnOrderScanerPage = () => {
  const navigate = useNavigate();
  const { updateSelectLanguage } = useLocalization();
  const [runGetOrderItemReturn] = useGetOrderItemForReturnLazyQuery();
  const [scanResult, setScanResult] = useState('');
  const [codeSearch, setCodeSearch] = useState<MODE>('inProcess');
  const [scaningError, setScaningError] = useState(null);
  const itemRef = useRef<OrderItemType[]>([]);
  const changeMode = async (eanNumber: string) => {
    try {
      const response = await runGetOrderItemReturn({
        variables: {
          input: { stockBarcode: eanNumber },
          filtersLocale: { locale: { eqi: updateSelectLanguage } },
        },
      });
      if (response.data?.searchOrderItemForReturn?.id) {
        const item = response.data.searchOrderItemForReturn;
        setScanResult('');
        if (item?.attributes?.order) {
          navigate(`/${PatchesName.MobileReturnOrder}/${item.attributes.barcode}`, {
            replace: true,
          });
          itemRef.current.push(item);
          setCodeSearch('searched');
          setScanResult('');
        } else {
          setCodeSearch('searchedNotOrder');
          setScanResult('');
        }
      } else {
        setCodeSearch('nonSearched');
        setScanResult('');
      }
    } catch (err: unknown) {
      handlerError(err);
      setCodeSearch('nonSearched');
    }
  };
  const handleBack = () => {
    if (codeSearch === 'inProcess') {
      navigate(`/${PatchesName.Delivery}`, {
        replace: true,
      });
    } else {
      setCodeSearch('inProcess');
    }
  };
  return (
    <Stack width="100%" height="100%" bgcolor="#F1F3F8" overflow="hidden">
      <MobileTopNavBar activeStep={1} onPrev={handleBack} totalStep={2} />
      <Stack textAlign="center" justifyContent="center" height="80%">
        {codeSearch === 'inProcess' && (
          <ScanerStep
            setCodeSearch={setCodeSearch}
            scanResult={scanResult}
            setScanResult={setScanResult}
            scaningError={scaningError}
            setScaningError={setScaningError}
            changeMode={changeMode}
          />
        )}

        {codeSearch === 'manualSearch' && (
          <ManualSearch changeMode={changeMode} setResult={setScanResult} />
        )}
        {codeSearch === 'nonSearched' && <NonSearched setCodeSearch={setCodeSearch} />}
      </Stack>
    </Stack>
  );
};
