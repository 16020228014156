import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { StockDataFragmentDoc } from '../../fragments/__generated__/StockData';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type AddStockItemMutationVariables = Types.Exact<{
  input: Types.AddStockInput;
}>;


export type AddStockItemMutation = { __typename?: 'Mutation', addStock?: { __typename?: 'AddStockResult', stockData?: { __typename?: 'StockData', title?: string | null, stockId?: string | null, articleNumber?: string | null, quantityIncoming?: number | null, stockBarcode?: string | null, supplierName?: string | null, stockCellAddress?: { __typename?: 'StockCellAddressItem', id?: string | null, cell?: string | null, shelf?: string | null, isEmptyCell?: boolean | null, priority?: number | null } | null } | null } | null };


export const AddStockItemDocument = gql`
    mutation addStockItem($input: AddStockInput!) {
  addStock(input: $input) {
    stockData {
      ...StockData
    }
  }
}
    ${StockDataFragmentDoc}`;
export type AddStockItemMutationFn = Apollo.MutationFunction<AddStockItemMutation, AddStockItemMutationVariables>;

/**
 * __useAddStockItemMutation__
 *
 * To run a mutation, you first call `useAddStockItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStockItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStockItemMutation, { data, loading, error }] = useAddStockItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStockItemMutation(baseOptions?: Apollo.MutationHookOptions<AddStockItemMutation, AddStockItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStockItemMutation, AddStockItemMutationVariables>(AddStockItemDocument, options);
      }
export type AddStockItemMutationHookResult = ReturnType<typeof useAddStockItemMutation>;
export type AddStockItemMutationResult = Apollo.MutationResult<AddStockItemMutation>;
export type AddStockItemMutationOptions = Apollo.BaseMutationOptions<AddStockItemMutation, AddStockItemMutationVariables>;