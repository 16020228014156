import React, { ReactNode } from 'react';
import { useLocalization } from '../../../../../localization';
import { HeaderCell } from '../../../../../components/TableProductActive/TableProductActive';
import { ReviewsType } from '../../../../../graphql/queries/hook/useGetReviews';
import { ReviewsRowItem } from '../components/tablesComponents/ReviewsRowItem';

interface HookProps {
  reviews: Array<ReviewsType>;
}
interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTableActive(): Array<ReactNode>;
}

export const useReviewsTabTable = ({ reviews }: HookProps): ReturnHook => {
  const { translateLang } = useLocalization();

  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      {
        title: translateLang('№'),
        containerProps: { width: '20px', color: '#F1F3F8', align: 'center' },
      },
      {
        title: translateLang('date'),
        containerProps: { width: '150px', color: '#F1F3F8', align: 'center' },
      },
      {
        title: translateLang('goods'),
        containerProps: { width: '280px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('Customer'),
        containerProps: { width: '190px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('Rating'),
        containerProps: { width: '60px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('Response'),
        containerProps: { width: '220px', color: '#f1f3f8', align: 'center' },
      },

      {
        title: translateLang('status'),
        containerProps: { width: '80px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang(''),
        containerProps: { width: '40px', color: '#f1f3f8', align: 'left' },
      },
    ];
  };
  const getRowsTableActive = (): ReactNode[] => {
    if (reviews && reviews?.length) {
      return reviews?.map((item, index) => {
        return <ReviewsRowItem key={item.id || index.toString()} item={item || undefined} />;
      });
    }
    return [];
  };

  return {
    getHeadersTable,
    getRowsTableActive,
  };
};
