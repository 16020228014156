import { setIsMobile, setSelectLanguageUserQuery } from '../../graphql/store/functionsWitingCache';
import { LocalStorageKey } from '../../helpers/types';
import { Enum_Language_Type } from '../../__generated__/types';

export function initStore(): void {
  const currentLanguage: Enum_Language_Type | null | undefined = window.localStorage.getItem(
    LocalStorageKey.LANG
  ) as Enum_Language_Type;
  if (currentLanguage) {
    const data = JSON.parse(currentLanguage);
    setSelectLanguageUserQuery(
      {
        dataWrite: { selectLanguage: data },
      },
      currentLanguage,
      false
    );
  } else {
    setSelectLanguageUserQuery(
      {
        dataWrite: { selectLanguage: Enum_Language_Type.En },
      },
      Enum_Language_Type.En,
      true
    );
  }
  const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
  setIsMobile({
    dataWrite: { isMobile: isMobileDevice },
  });
}
