import React, { ReactNode } from 'react';
import { TableCellProps } from '@mui/material';

import { ReturnType } from './useGetClientReturns';
import { useLocalization } from '../../../../../localization';
import { ClientReturnsRowItem } from '../ClientReturnsRowItem';
import { HeaderCell } from '../../../../../components/TableProduct/TableProduct';

interface HookProps {
  clientReturns?: ReturnType[];
  toggleReturnInvoiceTable: (id: string) => void;
}
interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTableActive(): Array<ReactNode>;
}

export const useGetClientReturnsTable = ({
  clientReturns,
  toggleReturnInvoiceTable,
}: HookProps): ReturnHook => {
  const { translateLang } = useLocalization();
  const getHeadersTable = (): Array<HeaderCell> => {
    const style: TableCellProps = { color: '#F1F3F8', align: 'left' };
    return [
      {
        title: '№',
        containerProps: { ...style, align: 'center', width: '20px' },
      },
      {
        title: translateLang('reasonReturn'),
        containerProps: { ...style, align: 'center', width: '20px' },
      },
      {
        title: translateLang('dateOfReceiving'),
        containerProps: { ...style, align: 'center', width: '20px' },
      },
      {
        title: translateLang('returnDate'),
        containerProps: { ...style, align: 'center', width: '20px' },
      },
      {
        title: translateLang('returnTo'),
        containerProps: { ...style, align: 'center', width: '20px' },
      },
      {
        title: translateLang('status'),
        containerProps: { ...style, align: 'center', width: '20px' },
      },
      {
        title: translateLang('correctiveInvoice'),
        containerProps: { ...style, align: 'center', width: '40px' },
      },
      {
        title: '',
        containerProps: { ...style, width: '30px', align: 'center' },
      },
    ];
  };

  const getRowsTableActive = (): ReactNode[] => {
    if (clientReturns && clientReturns?.length) {
      return clientReturns?.map(item => {
        return (
          <ClientReturnsRowItem
            key={`client-${item.id}`}
            clientReturnItem={item}
            toggleReturnInvoiceTable={toggleReturnInvoiceTable}
          />
        );
      });
    }
    return [];
  };

  return {
    getHeadersTable,
    getRowsTableActive,
  };
};
