import React, { ReactNode } from 'react';

import { TableClientReturnItemRow } from '../TableClientReturnItemRow';
import { useLocalization } from '../../../../../../../localization';
import { CustomerReturnItemEntity } from '../../../../../../../__generated__/types';
import { HeaderCell } from '../../../../../../../components/TableProduct/TableProduct';

interface HookProps {
  items?: CustomerReturnItemEntity[];
}
interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTableActive(): Array<ReactNode>;
}

export const useGetClientReturnItemTable = ({ items }: HookProps): ReturnHook => {
  const { translateLang } = useLocalization();

  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      {
        title: translateLang('article'),
        containerProps: { width: '240px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('productName'),
        containerProps: { width: '240px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('dateOfReceiving'),
        containerProps: { width: '240px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('quantity'),
        containerProps: { width: '240px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('price'),
        containerProps: { width: '240px', color: '#f1f3f8', align: 'center' },
      },
    ];
  };

  const getRowsTableActive = (): ReactNode[] => {
    if (items && items?.length) {
      return items?.map((item, index) => {
        return <TableClientReturnItemRow key={index} invoiceData={item} />;
      });
    }
    return [];
  };

  return {
    getHeadersTable,
    getRowsTableActive,
  };
};
