/* eslint-disable no-console */
import React, { FC, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, Stack, Typography, Zoom } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { GeneralSparesEntity } from '../../__generated__/types';
import { useLocalization } from '../../localization';
import { useGetSuppliersQuery } from '../../graphql/queries/__generated__/getSuppliers';
import { ItemProductType } from '../Warehouse/types';
import { SelectComponent } from '../Warehouse/components/PrintModal/SelectComponent';
import { Input } from '../../legos';
import { TranslatedField } from '../../components/Layout/components/TranslatedField/TranslatedField';
import { useUpdateGeneralSuppliersSpareMutation } from '../../graphql/mutations/__generated__/updateGeneralSupplierSpare';
import { CustomModal } from '../../components';
import { useCreateGeneralSuppliersSpareMutation } from '../../graphql/mutations/__generated__/createGeneralSupplierSpare';

interface AddCapacityModalProps {
  isOpen: boolean;
  closeModal(isRefetch?: boolean): void;
  product: GeneralSparesEntity;
}

enum AddCapcityFields {
  supplierPrice = 'supplierPrice',
  capacity = 'capacity',
}

export const AddCapacityModal: FC<AddCapacityModalProps> = ({ isOpen, closeModal, product }) => {
  const { translateLang } = useLocalization();
  const [selectedSupplier, setSelectedSupplier] = useState('');

  const { eanNumber, title, brandId, brandName, tecdoc_articleNumber } = {
    eanNumber: product?.attributes?.eanNumber,
    title: product?.attributes?.title,
    brandId: product?.attributes?.brandId,
    brandName: product?.attributes?.brandName,
    tecdoc_articleNumber: product?.attributes?.tecdoc_articleNumber,
  };

  const supplierSpare = useMemo(
    () =>
      product?.attributes?.suppliersSpare?.find(item => item?.supplier?.id === selectedSupplier),
    [selectedSupplier]
  );

  const { data: dataSuppliers } = useGetSuppliersQuery({
    variables: {
      filters: {
        and: [
          { or: [{ blocked: { eq: false } }, { blocked: { eq: null } }] },
          { or: [{ ownCompany: { eq: false } }, { ownCompany: { eq: null } }] },
        ],
      },
      pagination: {
        limit: -1,
      },
    },
  });

  const [runCreateCapacityMutation, { loading: createCapacityLoading }] =
    useCreateGeneralSuppliersSpareMutation();
  const [runUpdateCapacityMutation, { loading: updateCapacityLoading }] =
    useUpdateGeneralSuppliersSpareMutation();

  const getSuppliers = (): Array<ItemProductType> => {
    if (dataSuppliers?.suppliers?.data.length) {
      return dataSuppliers.suppliers.data.map(item => {
        return {
          value: item?.id || '',
          label: item?.attributes?.name || '',
        };
      });
    }
    return [];
  };

  const initialValues = {
    [AddCapcityFields.supplierPrice]: supplierSpare?.supplierPrice ?? 0,
    [AddCapcityFields.capacity]: +(supplierSpare?.amount || 0),
  };

  const validationSchema = yup.object({
    [AddCapcityFields.supplierPrice]: yup
      .number()
      .positive(translateLang('positiv_number'))
      .required(translateLang('recuired_field')),
    [AddCapcityFields.capacity]: yup
      .number()
      .positive(translateLang('positiv_number'))
      .required(translateLang('recuired_field')),
  });
  const { values, errors, touched, handleChange, handleSubmit, isValid, dirty } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async values => {
      handleSaveCapacity({
        newSupplierPrice: values[AddCapcityFields.supplierPrice],
        newCapacity: values[AddCapcityFields.capacity],
      });
    },
  });

  const handleSaveCapacity = ({
    newSupplierPrice,
    newCapacity,
  }: {
    newSupplierPrice: number;
    newCapacity: number;
  }) => {
    if (supplierSpare?.id) {
      runUpdateCapacityMutation({
        variables: {
          id: supplierSpare.id,
          data: {
            supplierPrice: newSupplierPrice,
            amount: `${newCapacity}`,
          },
        },
      }).finally(() => closeModal(true));
    } else {
      runCreateCapacityMutation({
        variables: {
          data: {
            amount: newCapacity,
            tecdoc_articleNumber: tecdoc_articleNumber || '',
            brandId,
            supplierId: selectedSupplier,
            supplierPrice: newSupplierPrice,
          },
        },
      }).finally(() => closeModal(true));
    }
  };

  return (
    <CustomModal
      title=""
      handleClose={closeModal}
      open={isOpen}
      bgcolor="#F1F3F8"
      width="700px"
      height="500px"
      display="flex"
      flexDirection="column"
    >
      <Stack textAlign="center" width="100%" height="100%" overflow="hidden">
        <Zoom
          in={isOpen}
          mountOnEnter
          unmountOnExit
          style={{ transitionDelay: isOpen ? '500ms' : '0ms' }}
        >
          <Stack
            component="form"
            onSubmit={handleSubmit}
            flexDirection="row"
            width="100%"
            height="100%"
            p={1}
          >
            <Box
              width="250px"
              borderRight="1px solid #8D93A6"
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '18px',
                  lineHeight: '38px',
                  color: '#646363',
                }}
              >
                {translateLang('barCodeOrder')}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '24px',
                  lineHeight: '30px',
                }}
              >
                {eanNumber}
              </Typography>
              <Typography
                mt={2}
                sx={{
                  fontWeight: 400,
                  fontSize: '18px',
                  lineHeight: '38px',
                  color: '#646363',
                }}
              >
                {translateLang('article')}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '24px',
                  lineHeight: '30px',
                }}
              >
                {tecdoc_articleNumber || ''}
              </Typography>
            </Box>
            <Stack
              flex={1}
              height="100%"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between"
              pl={6}
              pr={3}
            >
              <Stack width="100%" display="flex" flexDirection="column" alignItems="flex-start">
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '18px',
                    lineHeight: '38px',
                    color: '#646363',
                  }}
                >
                  {translateLang('productName')}:{' '}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '24px',
                    lineHeight: '30px',
                  }}
                >
                  {title} ({brandName})
                </Typography>
              </Stack>
              <Box width={300} textAlign="left">
                <SelectComponent
                  label={translateLang('selectProvider')}
                  dataSelect={getSuppliers()}
                  selectValue={selectedSupplier}
                  changeSelect={setSelectedSupplier}
                />
                <Stack gap={2}>
                  <Input
                    type="number"
                    variant={'outlined'}
                    placeholder={translateLang('purchasePrice')}
                    label={`${translateLang('purchasePrice')}, €`}
                    name={AddCapcityFields.supplierPrice}
                    value={values[AddCapcityFields.supplierPrice]}
                    onChange={handleChange}
                    error={!!errors[AddCapcityFields.supplierPrice]}
                    helperText={errors[AddCapcityFields.supplierPrice]}
                  />
                  <Input
                    type="number"
                    variant={'outlined'}
                    placeholder={translateLang('quantity')}
                    label={`${translateLang('quantity')}`}
                    name={AddCapcityFields.capacity}
                    value={values[AddCapcityFields.capacity]}
                    onChange={handleChange}
                    error={!!errors[AddCapcityFields.capacity]}
                    helperText={errors[AddCapcityFields.capacity]}
                  />
                </Stack>
              </Box>
              <Stack display="flex" my={5} width="100%" alignItems="center">
                <Button
                  type="submit"
                  disabled={
                    !selectedSupplier ||
                    !isValid ||
                    !dirty ||
                    createCapacityLoading ||
                    updateCapacityLoading
                  }
                  variant="contained"
                  color="secondary"
                  startIcon={
                    createCapacityLoading || updateCapacityLoading ? (
                      <CircularProgress size={20} />
                    ) : undefined
                  }
                  style={{
                    width: '50%',
                    height: '50px',
                    borderRadius: '10px',
                  }}
                >
                  <TranslatedField
                    originText={translateLang('addСapacity')}
                    fontSize={16}
                    isTranslate
                    noWrap
                    color="white"
                    textTransform="none"
                  />
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Zoom>
      </Stack>
    </CustomModal>
  );
};
