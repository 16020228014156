import React, { FC, useEffect, useState } from 'react';
import { addDays, format } from 'date-fns';
import { Edit, RemoveRedEyeOutlined } from '@mui/icons-material';
import { Box, Button, IconButton, SelectChangeEvent, Stack, Typography } from '@mui/material';

import { Icon, SelectCustom } from '../../../../legos';
import { useLocalization } from '../../../../localization';
import { ReturnType } from './hooks/useGetSupplierReturns';
import { CorrectiveInvoiceForm } from './component/CorrectiveInvoiceForm';
import { Enum_Supplierreturn_Status } from '../../../../__generated__/types';
import { useSupplierReturnStatuses } from './hooks/useSupplierReturnStatuses';
import { useHandlerNotificationApp } from '../../../../hooks/useHandlerNotificationApp';
import { TableProductActive } from '../../../../components/TableProductActive/TableProductActive';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useUpdateSupplierReturnMutation } from '../../../../graphql/mutations/__generated__/updateSupplierReturn';
import { formatDate } from '../../../../helpers/functions';

interface Props {
  supplierReturnItem: ReturnType;
  toggleReturnInvoiceTable: (id: string) => void;
}

export const SupplierReturnsRowItem: FC<Props> = ({
  supplierReturnItem,
  toggleReturnInvoiceTable,
}) => {
  const { translateLang, selectLanguages } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();
  const { supplierReturnStatuses } = useSupplierReturnStatuses();
  const [updateSupplierReturnMutation] = useUpdateSupplierReturnMutation();

  const {
    correctiveInvoiceDate,
    dateReturned,
    correctiveInvoiceNumber,
    id,
    dateOfReceiving,
    status,
  } = supplierReturnItem;

  const initialReturnStatus =
    status === Enum_Supplierreturn_Status.Completed
      ? translateLang('formed')
      : translateLang('forming');
  const [returnStatus, setReturnStatus] = useState(initialReturnStatus);

  const [correctiveInvoiceModal, setCorrectiveInvoiceModal] = useState(false);

  useEffect(() => {
    setReturnStatus(initialReturnStatus);
  }, [selectLanguages, initialReturnStatus]);

  const handleChangeReturnStatus = (event: SelectChangeEvent) => {
    const status =
      event.target.value === translateLang('formed')
        ? Enum_Supplierreturn_Status.Completed
        : Enum_Supplierreturn_Status.Created;

    updateSupplierReturnMutation({
      variables: {
        data: { status },
        id: id as string,
      },
    })
      .then(() => {
        addNotification({
          messageError: translateLang('invoiceSuccessfullyUpdated'),
          typeMessage: 'success',
        });

        setReturnStatus(event.target.value);
      })
      .catch(err => {
        addNotification({ messageError: err.message, typeMessage: 'error' });
      });
  };
  const toggleCorrectiveInvoiceModal = () => {
    setCorrectiveInvoiceModal(!correctiveInvoiceModal);
  };

  return (
    <>
      <TableProductActive.RowTable>
        <TableProductActive.CellTable ceilStyle={{ width: '40px', borderLeft: 'none' }}>
          {id}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable ceilStyle={{ width: '100px' }}>
          {formatDate(new Date(dateOfReceiving ?? new Date()))}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable ceilStyle={{ width: '100px' }}>
          {formatDate(new Date(dateReturned))}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable ceilStyle={{ width: '100px' }}>
          {formatDate(addDays(new Date(dateOfReceiving ?? new Date()), 14))}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable ceilStyle={{ width: '100px', borderLeft: 'none' }}>
          <SelectCustom
            items={supplierReturnStatuses}
            value={returnStatus}
            currentValue={returnStatus}
            handleChange={handleChangeReturnStatus}
            height="40px"
            minWidth={160}
            boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset"
          />
        </TableProductActive.CellTable>
        <TableProductActive.CellTable ceilStyle={{ width: '300px', borderLeft: 'none' }}>
          <Stack flexDirection="row" alignItems="center" gap={2}>
            {correctiveInvoiceNumber || correctiveInvoiceDate ? (
              <>
                <Typography fontSize={13} >
                  {`${correctiveInvoiceNumber ?? ''} ${formatDate(correctiveInvoiceDate) ?? ''}`}{' '}
                </Typography>
                <Box bgcolor="#e7eef4" borderRadius={5}>
                  <IconButton onClick={toggleCorrectiveInvoiceModal}>
                    <Edit fontSize="small" sx={{ color: '#5269A3' }} />
                  </IconButton>
                </Box>
              </>
            ) : (
              <Button
                variant="outlined"
                startIcon={<Icon icon="add" sx={{ color: '#8D8D8D' }} />}
                sx={{
                  height: 50,
                  p: 2,
                  textTransform: 'none',
                  borderRadius: '10px',
                  border: 'none',
                  '&:hover': {
                    border: 'none',
                  },
                }}
                onClick={toggleCorrectiveInvoiceModal}
              >
                <TranslatedField
                  originText={'addInvoice'}
                  fontSize={13}
                  isTranslate
                  noWrap
                  color="#8D8D8D"
                />
              </Button>
            )}
          </Stack>
        </TableProductActive.CellTable>
        <TableProductActive.CellTable ceilStyle={{ borderLeft: 'none', alignItems: 'end' }}>
          <Box bgcolor="#e7eef4" borderRadius={5}>
            <IconButton onClick={() => toggleReturnInvoiceTable(id as string)}>
              <RemoveRedEyeOutlined fontSize="small" sx={{ color: '#5269A3' }} />
            </IconButton>
          </Box>
        </TableProductActive.CellTable>
      </TableProductActive.RowTable>
      {correctiveInvoiceModal ? (
        <CorrectiveInvoiceForm
          id={id as string}
          isModalOpen={correctiveInvoiceModal}
          handleClose={toggleCorrectiveInvoiceModal}
          invoiceDate={correctiveInvoiceDate}
          invoiceNumber={correctiveInvoiceNumber}
        />
      ) : null}
    </>
  );
};
