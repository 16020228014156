import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { PatchesName, RolesEnum } from '../../types/types';
import { WrapperPages } from '../../components/Wrapper/WrapperPages';
import { useLocalization } from '../../localization';
import { TableChangeButton } from '../FeedbackPage/components/TableChangeButton';
import ChangeCatalog from './components/ChangeCatalog';
import { ProductDiscount } from './components/PrtoductDiscount';
import { ProductMarkup } from './components/ProductMarkup/ProductMarkup';
import { useGetMe } from '../../graphql/queries/hook/useGetMe';

type TabsItemValue = 'catalogFilter' | 'discountProduct' | 'productMarkup';
type TabsItem = {
  id: number;
  value: TabsItemValue;
};
const TABS: Array<TabsItem> = [
  { id: 1, value: 'catalogFilter' },
  { id: 2, value: 'discountProduct' },
];
const TABS_SUPER: Array<TabsItem> = [
  { id: 1, value: 'catalogFilter' },
  { id: 2, value: 'discountProduct' },
  { id: 3, value: 'productMarkup' },
];
export const AdminSettings = () => {
  const { role } = useGetMe();
  const { translateLang } = useLocalization();
  const [activeTable, setActiveTable] = useState<TabsItemValue>(TABS_SUPER[0].value);

  const handleChange = (val: string | undefined) => {
    if (val) {
      setActiveTable(val as TabsItemValue);
    }
  };

  return (
    <WrapperPages titleHeader={translateLang(PatchesName.AdminSettings)}>
      <Stack spacing={2}>
        <TableChangeButton
          activeTable={activeTable}
          tabs={role === RolesEnum.SuperAdmin ? TABS_SUPER : TABS}
          handleChange={handleChange}
          notBadge
        />
        {activeTable === 'catalogFilter' && <ChangeCatalog />}
        {activeTable === 'discountProduct' && <ProductDiscount />}
        {activeTable === 'productMarkup' && <ProductMarkup />}
      </Stack>
    </WrapperPages>
  );
};
