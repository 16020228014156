import React, { FC, useState } from 'react';
import { Box } from '@mui/material';
import {
  BoxCell,
  HeaderCell,
  TableProduct,
  TableCellsValue,
} from '../../../../components/TableProduct/TableProduct';
import { useLocalization } from '../../../../localization';
import { DeleteModal } from '../../../ClientInfo/components/Modals/DeleteModal';
import { PaginationType } from '../../../../graphql/queries/hook/useGetOrders';
import { Enum_Contactnotification_Status } from '../../../../__generated__/types';
import { StatusSelect } from './StatusSelect';
import { ContactNotificationsType } from '../../../../graphql/queries/hook/useGetContactNotifications';

interface Props {
  isLoading?: boolean;
  pageCount: number;
  subscription: ContactNotificationsType[] | undefined;
  pagination: PaginationType | undefined;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

export const ContactNotificationsTable: FC<Props> = ({
  subscription,
  pagination,
  setPage,
  pageCount,
  isLoading,
}) => {
  const { translateLang } = useLocalization();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const closeModal = () => {
    setOpenModal(false);
  };

  const formatDate = (item: string | number | Date) => {
    const date = new Date(item);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  };

  const changePageNumber = (val: number) => {
    setPage(val);
  };
  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      {
        title: translateLang('№'),
        containerProps: { width: '20px', color: '#F1F3F8', align: 'center' },
      },
      {
        title: translateLang('date'),
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('client'),
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('phone'),
        containerProps: { width: '140px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('message'),
        containerProps: { width: '140px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('source'),
        containerProps: { width: '120px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('status'),
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
    ];
  };

  const getRowsTable = (): Array<TableCellsValue> => {
    if (subscription && subscription?.length) {
      return subscription?.map(item => {
        const values: Array<BoxCell> = [];
        values.push({
          val: item.id,
          containerProps: { width: '20px', height: '20px', borderLeft: 'none' },
        });
        values.push({ val: formatDate(item.date as string) });
        values.push({ val: item?.userName });
        values.push({ val: item?.phoneNumbers });
        values.push({ val: item?.message });

        values.push({ val: 'Home Page' });

        values.push({
          val: (
            <Box bgcolor="#e7eef4" borderRadius={2}>
              <StatusSelect
                subscriptionId={item?.id || ''}
                currentStatus={item?.status || Enum_Contactnotification_Status.Completed}
              />
            </Box>
          ),
          containerProps: { width: '160px' },
        });

        return { cellsValues: values };
      });
    }
    return [];
  };

  return (
    <>
      <Box boxSizing="border-box" width="100%">
        <TableProduct
          startPage={pagination?.page || 0}
          totalPages={pagination?.total ? Math.ceil(pagination.total / pageCount) : 0}
          itemsRows={getRowsTable()}
          itemsHeader={getHeadersTable()}
          changeCurrentPage={changePageNumber}
          loading={isLoading}
        />
      </Box>
      <DeleteModal openModal={openModal} closeModal={closeModal} title={translateLang('del')} />
    </>
  );
};
