import React, { FC } from 'react';
import { Stack } from '@mui/material';

import { useLocalization } from '../../../localization';
import { StyledTypography } from './StyledTypography';
import { RolesEnum } from '../../../types/types';

interface CredentialsInformationsProps {
  id?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  userName?: string | null;
  clientType?: string;
  VATId?: string | null;
  companyName?: string | null;
  isSuperAdminOrAccounter?: boolean;
  onClickInput(idPhone: string): void;
}
export const CredentialsInformations: FC<CredentialsInformationsProps> = ({
  id,
  lastName,
  firstName,
  onClickInput,
  clientType = '',
  userName,
  VATId,
  companyName,
  isSuperAdminOrAccounter,
}) => {
  const { translateLang } = useLocalization();
  const isClient =
    clientType !== RolesEnum.WholesaleCustomer || RolesEnum.PermanentWholesaleCustomer;
  return (
    <Stack spacing={2} onClick={() => onClickInput(id || '')}>
      <>
        <StyledTypography value={userName} label={translateLang('login')} maxWidth="425px" />
        <StyledTypography
          value={firstName}
          label={isClient ? translateLang('clientName') : translateLang('serviceName')}
          maxWidth="425px"
        />
        <StyledTypography
          value={lastName}
          label={isClient ? translateLang('clientLastName') : translateLang('anotherServiceName')}
          maxWidth="425px"
        />
        {isSuperAdminOrAccounter ? (
          <>
            <StyledTypography
              value={companyName}
              label={translateLang('companyName')}
              maxWidth="425px"
            />
            <StyledTypography value={VATId} label={translateLang('VATId')} maxWidth="425px" />
          </>
        ) : null}
      </>
    </Stack>
  );
};
