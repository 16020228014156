import React, { FC, useState } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import {
  BoxCell,
  HeaderCell,
  TableProduct,
  TableCellsValue,
} from '../../../../components/TableProduct/TableProduct';
import { useLocalization } from '../../../../localization';
import { PaginationType } from '../../../../graphql/queries/hook/useGetOrders';
import { Enum_Nosparepart_Status } from '../../../../__generated__/types';
import { NoSparePartsType } from '../../../../graphql/queries/hook/useNoSpareParts';
import { StatusSelectNoParts } from './StatusSelectNoParts';
import { CustomModal } from '../../../../components';
import { RemoveRedEyeOutlined } from '@mui/icons-material';

interface Props {
  pageCount: number;
  noParts: NoSparePartsType[];
  pagination: PaginationType | undefined;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

export const NoPartsTable: FC<Props> = ({ noParts, pagination, setPage, pageCount }) => {
  const { translateLang } = useLocalization();
  const [modalMessage, setModalMessage] = useState<{ isOpen: boolean; message: string }>({
    isOpen: false,
    message: '',
  });

  const formatDate = (item: string | number | Date) => {
    const date = new Date(item);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  };

  const changePageNumber = (val: number) => {
    setPage(val);
  };
  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      {
        title: translateLang('№'),
        containerProps: { width: '10px', color: '#F1F3F8', align: 'center' },
      },
      {
        title: translateLang('date'),
        containerProps: { width: '80px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('phone'),
        containerProps: { width: '120px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('vinCode'),
        containerProps: { width: '120px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('message'),
        containerProps: { width: '220px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('source'),
        containerProps: { width: '100px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('status'),
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
    ];
  };
  const getRowsTable = (): Array<TableCellsValue> => {
    if (noParts && noParts?.length) {
      return noParts?.map(item => {
        const values: Array<BoxCell> = [];
        values.push({
          val: item.id,
          containerProps: { width: '20px', height: '20px', borderLeft: 'none' },
        });
        values.push({
          val: formatDate(item.date as string),
        });
        values.push({ val: item?.phoneNumbers });

        values.push({ val: item?.vinCode });
        values.push({
          val:
            item.message && item.message?.length > 45 ? (
              <Stack direction="row" spacing={3}>
                <Typography>{item.message.slice(0, 45) + '...'}</Typography>
                <IconButton
                  onClick={() => setModalMessage({ isOpen: true, message: item?.message || '' })}
                >
                  <RemoveRedEyeOutlined />
                </IconButton>
              </Stack>
            ) : (
              item.message
            ),
          containerProps: { padding: '30px !important', margin: '10px' },
        });

        values.push({ val: translateLang(`${item.source}`) });
        values.push({
          val: (
            <Box bgcolor="#e7eef4" borderRadius={2}>
              <StatusSelectNoParts
                noPartsId={item?.id || ''}
                currentStatus={item?.status || Enum_Nosparepart_Status.Unprocessed}
              />
            </Box>
          ),
          containerProps: { width: '160px' },
        });
        return { cellsValues: values };
      });
    }
    return [];
  };
  return (
    <>
      <Box boxSizing="border-box" width="100%">
        <TableProduct
          startPage={pagination?.page || 0}
          totalPages={pagination?.total ? Math.ceil(pagination.total / pageCount) : 0}
          itemsRows={getRowsTable()}
          itemsHeader={getHeadersTable()}
          changeCurrentPage={changePageNumber}
        />
      </Box>
      {modalMessage.isOpen && (
        <CustomModal
          title={translateLang('message')}
          handleClose={() => setModalMessage({ isOpen: false, message: '' })}
          open={true}
        >
          <Typography>{modalMessage.message}</Typography>
        </CustomModal>
      )}
    </>
  );
};
