import React, {
  DragEvent,
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
} from 'react';
import { NumericFormat } from 'react-number-format';
import { DragIndicator } from '@mui/icons-material';
import { Divider, Typography, Box } from '@mui/material';

import {
  Enum_Incominginvoice_Status,
  IncomingInvoiceEntity,
} from '../../../../../../__generated__/types';
import { StartDragInvoice } from './LinkingPaymentModal';
import { formatCurrency } from '../../../../../../utils/currencyUtils';
import { Input } from '../../../../../../legos';
import { useLocalization } from '../../../../../../localization';
import { theme } from '../../../../../../helpers';
import { formatDate } from '../../../../../../helpers/functions';

type InvoiceRowProps = {
  invoice: IncomingInvoiceEntity;
  linkedAmount?: number;
  linkedAmountChange?: (newAmount: string, invoiceId?: string | null) => Promise<void>;
  draggable: boolean;
  acceptDrag?: boolean;
  dropHandler: (e: DragEvent<HTMLDivElement>) => void;
  dragStartHandler: StartDragInvoice;
  editable?: boolean;
  disabled: boolean;
  lastChild?: boolean;
};

export const InvoiceRow = forwardRef<{ focusInput: () => void }, InvoiceRowProps>(
  (
    {
      invoice,
      linkedAmount,
      linkedAmountChange,
      dropHandler,
      dragStartHandler,
      draggable,
      editable = false,
      disabled,
      lastChild,
    },
    ref
  ) => {
    const { translateLang } = useLocalization();

    const inputRef = useRef<HTMLInputElement | null>(null);

    useImperativeHandle(ref, () => ({
      focusInput: () => {
        if (inputRef.current) {
          inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          inputRef.current.focus();
        }
      },
    }));

    const [innerLinkedAmount, setInnerLinkedAmount] = useState(`${linkedAmount}`);
    const [isInputFocused, setIsInputFocused] = useState(false);

    useEffect(() => {
      setInnerLinkedAmount(`${linkedAmount}`);
    }, [linkedAmount]);

    return (
      <Box
        style={{
          borderLeft: '1px solid rgba(224, 224, 224, 1)',
          borderRight: '1px solid rgba(224, 224, 224, 1)',
          borderBottom: lastChild ? '1px solid rgba(224, 224, 224, 1)' : 'unset',
        }}
      >
        <Box
          p={0.5}
          py={1}
          display="flex"
          className="item"
          flexDirection="row"
          alignItems="center"
          draggable={!disabled && draggable}
          justifyContent="center"
          onDragEnd={e => {
            e.currentTarget.style.outline = '2px solid transparent';
          }}
          onDrop={e => {
            if (e.currentTarget.parentElement?.parentElement) {
              e.currentTarget.parentElement.parentElement.style.outline = '2px solid transparent';
            }
            dropHandler(e);
          }}
          onDragStart={() => dragStartHandler(invoice)}
          style={{
            cursor: !disabled && draggable ? 'grab' : 'default',
          }}
          color={disabled ? theme.palette.common.grey : 'unset'}
        >
          <DragIndicator sx={{ color: '#5F5F5F' }} />
          <Box
            flex={1}
            display="flex"
            bgcolor="white"
            minHeight="52px "
            borderRadius="5px"
            alignItems="center"
            flexDirection="row"
            justifyContent="stretch"
          >
            <Box
              flex={3}
              paddingX={1}
              height="32px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography fontWeight={600}>
                #{invoice.id}{' '}
                <Typography
                  component="span"
                  fontWeight={600}
                  color={
                    invoice.attributes?.status === Enum_Incominginvoice_Status.Completed
                      ? theme.palette.common.appleGreen
                      : 'inherit'
                  }
                >
                  {invoice.attributes?.status
                    ? `(${translateLang(
                        invoice.attributes?.status === Enum_Incominginvoice_Status.Completed
                          ? 'formed'
                          : 'forming'
                      )})`
                    : ''}
                </Typography>
              </Typography>
              <Typography fontSize={13}>{formatDate(invoice.attributes?.date)}</Typography>
            </Box>
            <Divider orientation="vertical" variant="middle" flexItem />

            <Box
              flex={3}
              paddingX={1}
              height="32px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography fontWeight={600}>#{invoice.attributes?.supplierInvoiceNumber}</Typography>
              <Typography fontSize={13}>
                {formatDate(invoice.attributes?.supplierInvoiceDate)}
              </Typography>
            </Box>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Box
              px={1}
              flex={2}
              display="flex"
              overflow="clip"
              alignItems="center"
              justifyContent="start"
            >
              <Typography>{formatCurrency(invoice.attributes?.total)}</Typography>
            </Box>

            <Divider orientation="vertical" variant="middle" flexItem />
            <Box
              flex={2}
              height="32px"
              display="flex"
              paddingX="10px"
              overflow="clip"
              alignItems="center"
              justifyContent="start"
            >
              <Typography noWrap>{formatCurrency(invoice.attributes?.debtTotal)}</Typography>
            </Box>

            {editable && (
              <>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Box
                  flex={3}
                  height="32px"
                  display="flex"
                  paddingX="10px"
                  overflow="clip"
                  alignItems="center"
                  justifyContent="start"
                >
                  <NumericFormat
                    inputRef={inputRef}
                    decimalScale={2}
                    value={isInputFocused ? innerLinkedAmount : linkedAmount}
                    allowNegative={false}
                    onChange={e => setInnerLinkedAmount(e.target.value)}
                    customInput={Input}
                    onKeyDown={e => {
                      if (e.code === 'Enter') {
                        (e.currentTarget.lastChild?.lastChild as HTMLInputElement).blur();
                      }
                    }}
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={() =>
                      linkedAmountChange &&
                      linkedAmountChange(innerLinkedAmount, invoice.id).finally(() => {
                        setIsInputFocused(false);
                        setInnerLinkedAmount(`${linkedAmount}`);
                      })
                    }
                    inputProps={{
                      style: {
                        backgroundColor: '#F1F3F8',
                        fontWeight: isInputFocused ? '700' : '400',
                        color: 'black',
                      },
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
        {!lastChild && <Divider orientation="horizontal" variant="middle" flexItem />}
      </Box>
    );
  }
);

InvoiceRow.displayName = 'InvoiceRow';
