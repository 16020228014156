import React, { FC } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Icon } from '../../../../../../legos';
import { theme } from '../../../../../../helpers';
import { TranslatedField } from '../../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useGetInvoicePaymentDetailsQuery } from './query/__generated__/getInvoicePaymentDetails';
import {
  Enum_Supplierpayment_Paymentdirection,
  Enum_Supplierpayment_Paymentmethod,
  Enum_Supplierpaymentdetail_Paymenttype,
} from '../../../../../../__generated__/types';
import { useLocalization } from '../../../../../../localization';
import { formatCurrency } from '../../../../../../utils/currencyUtils';
import { formatDate } from '../../../../../../helpers/functions';

type IncomingInvoicePaymentDetailsModalProps = {
  isModalOpen: boolean;
  handleClose: () => void;
  invoiceId: string;
  total: number;
  debt: number;
};

export const IncomingInvoicePaymentDetailsModal: FC<IncomingInvoicePaymentDetailsModalProps> = ({
  isModalOpen,
  handleClose,
  invoiceId,
  total,
  debt,
}) => {
  const { translateLang } = useLocalization();

  const { data } = useGetInvoicePaymentDetailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      filters: {
        incoming_invoice: {
          id: {
            eq: invoiceId,
          },
        },
      },
      pagination: {
        limit: 1000,
      },
    },
  });
  return (
    <Dialog
      open={isModalOpen}
      onClose={() => handleClose()}
      closeAfterTransition
      maxWidth="md"
      fullWidth
      disableScrollLock
    >
      <DialogTitle>
        <Stack alignItems="center">
          <Stack width="100%" flexDirection="row" justifyContent="end" mb={2}>
            <IconButton onClick={() => handleClose()} sx={{ p: 0 }}>
              <Icon icon="close" sx={{ color: theme.palette.primary.main }} />
            </IconButton>
          </Stack>
          <TranslatedField
            isTranslate
            variant="h4"
            color={theme.palette.common.darkBlue}
            marginBottom={2.5}
            fontSize={30}
            originText="paymentDetails"
          />
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="row" gap={2}>
          <TranslatedField
            isTranslate
            variant="h4"
            fontSize={18}
            originText="total"
            pb={1}
            suffix={`: ${formatCurrency(total)}`}
          />
          <TranslatedField
            isTranslate
            variant="h4"
            fontSize={18}
            originText="debtAmount"
            pb={1}
            suffix={`: ${formatCurrency(debt)}`}
          />
          <TranslatedField
            isTranslate
            variant="h4"
            fontSize={18}
            originText="paid"
            pb={1}
            suffix={`: ${formatCurrency(total - debt)}`}
          />
        </Box>
        <Box
          py={0.5}
          zIndex={2}
          border="1px solid rgba(224, 224, 224, 1)"
          bgcolor={theme.palette.common.lightGrey}
        >
          <Box display="flex" alignItems="center" minHeight={52}>
            <TranslatedField
              isTranslate
              variant="h4"
              fontSize={14}
              originText="type"
              pl={1}
              flex={3}
            />
            <Divider orientation="vertical" variant="middle" flexItem />

            <TranslatedField
              isTranslate
              variant="h4"
              fontSize={14}
              originText="docNumber"
              pl={1}
              flex={3}
            />
            <Divider orientation="vertical" variant="middle" flexItem />

            <TranslatedField
              isTranslate
              variant="h4"
              fontSize={14}
              originText="docDate"
              pl={1}
              flex={2}
            />
            <Divider orientation="vertical" variant="middle" flexItem />

            <TranslatedField
              isTranslate
              variant="h4"
              fontSize={14}
              originText="amount"
              pl={1}
              flex={3}
            />
          </Box>
        </Box>
        {data?.supplierPaymentDetails?.data.map(spd => {
          const isReturn =
            spd.attributes?.paymentType === Enum_Supplierpaymentdetail_Paymenttype.Return;
          let docDate = '';
          let docType = '';
          let docId = '';
          if (isReturn) {
            docId = spd.attributes?.supplier_return?.data?.id ?? '';
            docDate = formatDate(spd.attributes?.supplier_return?.data?.attributes?.dateReturned);

            docType = translateLang('turnoverDocument');
          } else {
            docId = spd.attributes?.supplier_payment?.data?.id ?? '';

            docDate = formatDate(spd.attributes?.supplier_payment?.data?.attributes?.paymentDate);
            docType =
              spd.attributes?.supplier_payment?.data?.attributes?.paymentDirection ===
              Enum_Supplierpayment_Paymentdirection.Incoming
                ? spd.attributes?.supplier_payment?.data?.attributes?.paymentMethod ===
                  Enum_Supplierpayment_Paymentmethod.Cash
                  ? `${translateLang('paymentReceived')} (${translateLang('cash')})`
                  : `${translateLang('paymentReceived')} (${translateLang('nonCash')})`
                : spd.attributes?.supplier_payment?.data?.attributes?.paymentMethod ===
                  Enum_Supplierpayment_Paymentmethod.Cash
                ? `${translateLang('payment')} (${translateLang('cash')})`
                : `${translateLang('payment')} (${translateLang('nonCash')})`;
          }

          return (
            <Box key={spd.id}>
              <Box
                p={0.5}
                py={1}
                display="flex"
                className="item"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  flex={1}
                  display="flex"
                  bgcolor="white"
                  minHeight="52px "
                  borderRadius="5px"
                  alignItems="center"
                  flexDirection="row"
                  justifyContent="stretch"
                >
                  <Box
                    flex={3}
                    paddingX={1}
                    height="32px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Typography fontWeight={600}>{docType}</Typography>
                  </Box>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Box
                    flex={3}
                    paddingX={1}
                    height="32px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Typography fontWeight={600}>{docId}</Typography>
                  </Box>
                  <Divider orientation="vertical" variant="middle" flexItem />

                  <Box
                    px={1}
                    flex={2}
                    display="flex"
                    overflow="clip"
                    alignItems="center"
                    justifyContent="start"
                  >
                    <Typography>{docDate}</Typography>
                  </Box>

                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Box
                    flex={3}
                    paddingX={1}
                    height="32px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Typography
                      fontWeight={600}
                      color={
                        spd.attributes?.supplier_payment?.data?.attributes?.paymentDirection ===
                        Enum_Supplierpayment_Paymentdirection.Incoming
                          ? 'unset'
                          : theme.palette.common.red
                      }
                    >
                      {`${
                        spd.attributes?.supplier_payment?.data?.attributes?.paymentDirection ===
                        Enum_Supplierpayment_Paymentdirection.Incoming
                          ? ''
                          : '-'
                      }${formatCurrency(spd.attributes?.amount)}`}
                    </Typography>
                  </Box>
                  <Divider orientation="vertical" variant="middle" flexItem />
                </Box>
              </Box>
              <Divider orientation="horizontal" variant="middle" flexItem />
            </Box>
          );
        })}
      </DialogContent>
    </Dialog>
  );
};
