export const formatOrderNumber = (
  orderNumber?: string | null,
  additionalOrderNumber?: string | null
) => {
  if (!orderNumber) {
    return '';
  }
  return `${orderNumber ? `${orderNumber}` : ''}${
    additionalOrderNumber ? `-${additionalOrderNumber}` : ''
  }`;
};
