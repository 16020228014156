import React, { useState } from 'react';
import { useGetWarehouse } from '../../../../graphql/queries/hook/useGetWarehouse';
import { Stack } from '@mui/material';
import { WarhouseOrderTabTable } from './components/WarhouseOrderTabTable';
import { useLocalization } from '../../../../localization';
import { SearchField } from '../../../../components/SearchField';

export const WarehouseTab = () => {
  const { translateLang } = useLocalization();
  const [searchText, setSearchText] = useState('');
  const { itemsWarehouse, changePage, loading, page, totalPage } = useGetWarehouse({
    filters: searchText
      ? {
          or: [
            {
              car_spare: {
                or: [
                  { tecdoc_articleNumber: { containsi: searchText } },
                  { car_spare_locales: { title: { containsi: searchText } } },
                ],
              },
            },
            { supplier: { name: { containsi: searchText } } },
          ],
        }
      : undefined,
  });

  return (
    <Stack width="100%">
      <SearchField
        initStateText={searchText}
        executeWithDelay={text => setSearchText(text)}
        placeholder={translateLang('searchByPartName')}
      />
      <WarhouseOrderTabTable
        itemsWarehouse={itemsWarehouse}
        totalPage={totalPage}
        changePage={changePage}
        currentPage={page}
        loading={loading}
      />
    </Stack>
  );
};
