import React, { useState } from 'react';
import { Box } from '@mui/material';
import { endOfToday, startOfDay, subDays } from 'date-fns';

import { ReviewsTable } from './components/ReviewsTable';
import { ToolBarReviews } from './components/ToolBarReviews';
import { ReviewFiltersInput } from '../../../../__generated__/types';
import { useGetReviews } from '../../../../graphql/queries/hook/useGetReviews';

export const ReviewTab = () => {
  const [filter, setFilter] = useState<ReviewFiltersInput>({
    createdAt: {
      between: [startOfDay(subDays(new Date(), 30)).toISOString(), endOfToday().toISOString()],
    },
  });

  const [resetFilter, setResetFilter] = useState(new Date().getTime().toString());

  const handlerReset = () => {
    setFilter({
      createdAt: {
        between: [startOfDay(subDays(new Date(), 30)).toISOString(), endOfToday().toISOString()],
      },
    });
    changePage(0);
    setResetFilter(new Date().getTime().toString());
  };

  const { reviews, isLoading, changePage, page, totalPage, total } = useGetReviews({
    filters: filter,
  });
  return (
    <Box width="100%">
      <ToolBarReviews
        result={total}
        setFilter={setFilter}
        setResetFilter={handlerReset}
        key={resetFilter}
      />
      <ReviewsTable
        changePage={changePage}
        reviews={reviews}
        totalPage={totalPage}
        loading={isLoading}
        currentPage={page}
      />
    </Box>
  );
};
