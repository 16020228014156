import React from 'react';

export const StarsSVG = () => {
  return (
    <svg width='196' height='63' viewBox='0 0 196 63' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M80.7251 60.8057C79.2035 61.5878 77.4806 60.221 77.7869 58.4749L81.054 39.8525L67.1848 26.6357C65.8886 25.4005 66.5596 23.1421 68.296 22.8955L87.5837 20.1555L96.1837 3.1197C96.9584 1.5851 99.057 1.5851 99.8317 3.1197L108.432 20.1555L127.719 22.8955C129.456 23.1421 130.127 25.4005 128.831 26.6357L114.961 39.8525L118.228 58.4749C118.535 60.221 116.812 61.5878 115.29 60.8057L98.0077 51.9229L80.7251 60.8057Z'
        fill='#FF7E00'></path>
      <path
        d='M138.532 61.6068C137.566 62.1033 136.472 61.2355 136.666 60.1269L138.741 48.3032L129.935 39.9116C129.112 39.1273 129.538 37.6934 130.64 37.5368L142.887 35.7971L148.347 24.9808C148.839 24.0064 150.171 24.0064 150.663 24.9808L156.123 35.7971L168.369 37.5368C169.472 37.6934 169.898 39.1273 169.075 39.9116L160.269 48.3032L162.343 60.1269C162.538 61.2355 161.444 62.1033 160.478 61.6068L149.505 55.9669L138.532 61.6068Z'
        fill='#D5190B'></path>
      <path
        d='M35.5318 61.6068C34.5657 62.1033 33.4718 61.2355 33.6663 60.1269L35.7406 48.3032L26.9348 39.9116C26.1118 39.1273 26.5378 37.6934 27.6403 37.5368L39.8865 35.7971L45.3468 24.9808C45.8387 24.0064 47.1711 24.0064 47.663 24.9808L53.1233 35.7971L65.3695 37.5368C66.4719 37.6934 66.8979 39.1273 66.075 39.9116L57.2691 48.3032L59.3435 60.1269C59.538 61.2355 58.444 62.1033 57.4779 61.6068L46.5049 55.9669L35.5318 61.6068Z'
        fill='#D5190B'></path>
    </svg>
  );
};
