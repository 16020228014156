import { Select, SelectChangeEvent, MenuItem } from '@mui/material';
import React, { FC, useState } from 'react';
import { useLocalization } from '../../../../../localization';
import { useUpdateReviewMutation } from '../../../../../graphql/mutations/__generated__/updateReview';
import { GetReviewsDocument } from '../../../../../graphql/queries/__generated__/getReviews';
import { getRefetchReviews } from '../../../../../graphql/queries/hook/useGetReviews';
import { useHandlerNotificationApp } from '../../../../../hooks/useHandlerNotificationApp';

export enum REVIEWSPUBSTATUS {
  Published = 'true',
  Unpublished = 'false',
}

interface Props {
  currentStatus: string;
  itemId: string;
}

export const StatusSelect: FC<Props> = ({ currentStatus, itemId }) => {
  const [currentValue, setCurrentValue] = useState(currentStatus);
  const color = currentValue === 'true' ? '#97A408' : '#F44336';
  const { translateLang } = useLocalization();
  const [updateReviewMutation] = useUpdateReviewMutation();
  const { addNotification } = useHandlerNotificationApp();

  const handleStatusChange = async (event: SelectChangeEvent) => {
    setCurrentValue(event.target.value as string);
    try {
      await updateReviewMutation({
        variables: {
          id: itemId,
          data: {
            publication: currentValue === REVIEWSPUBSTATUS.Unpublished ? true : false,
          },
        },

        refetchQueries: [
          {
            query: GetReviewsDocument,
            variables: getRefetchReviews(),
          },
        ],
      });
      addNotification({
        messageError: 'Changed success!!!',
        typeMessage: 'success',
      });
    } catch (err: unknown) {
      if (err instanceof ErrorEvent) {
        addNotification({
          messageError: err?.message,
          typeMessage: 'error',
        });
      }
    }
  };

  const getStatusLabel = (value: string) => {
    if (value === REVIEWSPUBSTATUS.Published) {
      return translateLang('Processed');
    } else if (value === REVIEWSPUBSTATUS.Unpublished) {
      return `${translateLang('Unprocessed')}`;
    }
    return '';
  };

  return (
    <Select
      fullWidth
      value={currentValue}
      sx={{
        backgroundColor: 'white',
        color: color,
        height: '30px',
        textAlign: 'center',
        '&:before': {
          borderColor: color,
          color: color,
        },
        '&:after': {
          borderColor: color,
        },
        '&:not(.Mui-disabled):hover::before': {
          borderColor: color,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: color,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: color,
          backgroundColor: 'transparent',
        },
        '& .MuiSvgIcon-root': {
          color: color,
        },
      }}
      onChange={handleStatusChange}
    >
      {Object.values(REVIEWSPUBSTATUS).map((item, index) => (
        <MenuItem key={index.toString()} value={item} sx={{ textTransform: 'capitalize' }}>
          {getStatusLabel(item)}
        </MenuItem>
      ))}
    </Select>
  );
};
