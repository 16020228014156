import React, { FC } from 'react';
import Avatar, { AvatarProps } from '@mui/material/Avatar';

interface CustomAvatarProps extends AvatarProps {
  logo?: string;
}

export const CustomAvatar: FC<CustomAvatarProps> = ({ logo, src, ...avatarProps }) => (
  <Avatar src={src || logo} {...avatarProps} />
);
