import { useSearchParams } from 'react-router-dom';
import { AllFiltersType } from '../types/types';
import { addDays } from 'date-fns';
import { useCallback, useEffect } from 'react';

type FiltersType =
  | 'page'
  | 'manager'
  | 'searchText'
  | 'status'
  | 'statusPayment'
  | 'dateTo'
  | 'dateFrom';
interface ReturnHook {
  page: number;
  selManager: string;
  selStatus: string;
  selStatusPayment: string;
  selDateTo: string;
  selDateFrom: string;
  searchText: string;
  changeSearchText(searchText: string): void;
  changeManager(manager?: string): void;
  changeStatus(status?: string): void;
  changeStatusPayment(status?: string): void;
  changePage(page: number): void;
  changeDate(dateFrom: string, dateTo: string): void;
  resetFilters(): void;
}

export const useFiltersSearchParamsForOrders = (
  defaults: {
    [K in FiltersType]?: string;
  } = {}
): ReturnHook => {
  defaults = defaults ?? {};
  defaults.dateFrom = defaults.dateFrom ?? addDays(new Date(), -30).toISOString();
  defaults.dateTo = defaults.dateTo ?? new Date().toISOString();
  const [searchParams, setSearchParams] = useSearchParams();

  const tab = searchParams.get('tab');

  const getFieldFilter = useCallback(
    (field: FiltersType): string | number => {
      const filterField = searchParams.get(field);
      if (filterField) {
        return field === 'page' ? +filterField : filterField;
      }
      return field === 'page' ? 0 : '';
    },
    [searchParams]
  );

  const changePage = (page: number) => {
    const filters = getAllFilters();
    setSearchParams({ ...filters, ...(tab ? { tab: tab } : ''), page: page.toString() });
  };

  const changeManager = (manager?: string) => {
    const filters = getAllFilters();
    if (manager) {
      setSearchParams({ ...filters, ...(tab ? { tab: tab } : ''), manager, page: '0' });
    } else {
      delete filters['manager'];
      setSearchParams({ ...filters, ...(tab ? { tab: tab } : ''), page: '0' });
    }
  };

  const changeStatus = (status?: string) => {
    const filters = getAllFilters();
    if (status) {
      setSearchParams({ ...filters, ...(tab ? { tab: tab } : ''), status, page: '0' });
    } else {
      delete filters['status'];
      setSearchParams({ ...filters, ...(tab ? { tab: tab } : ''), page: '0' });
    }
  };

  const changeStatusPayment = (status?: string) => {
    const filters = getAllFilters();
    if (status) {
      setSearchParams({
        ...filters,
        ...(tab ? { tab: tab } : ''),
        statusPayment: status,
        page: '0',
      });
    } else {
      delete filters['statusPayment'];
      setSearchParams({ ...filters, ...(tab ? { tab: tab } : ''), page: '0' });
    }
  };

  const changeSearchText = (searchText: string) => {
    const filters = getAllFilters();
    setSearchParams({ ...filters, ...(tab ? { tab: tab } : ''), searchText, page: '0' });
  };

  const changeDate = (dateFrom: string, dateTo: string) => {
    const filters = getAllFilters();
    setSearchParams({ ...filters, ...(tab ? { tab: tab } : ''), dateFrom, dateTo, page: '0' });
  };

  const resetFilters = () => {
    setSearchParams({
      ...(tab ? { tab: tab } : ''),
      dateFrom: defaults.dateFrom ?? addDays(new Date(), -30).toISOString(),
      dateTo: defaults.dateTo ?? new Date().toISOString(),
      page: '0',
    });
  };

  function getAllFilters(): AllFiltersType {
    const filters: AllFiltersType = {};
    searchParams.forEach((val, key) => {
      filters[key] = val;
    });
    return filters;
  }

  return {
    searchText: getFieldFilter('searchText') as string,
    page: getFieldFilter('page') as number,
    selDateTo: getFieldFilter('dateTo') as string,
    selDateFrom: getFieldFilter('dateFrom') as string,
    selManager: getFieldFilter('manager') as string,
    selStatus: getFieldFilter('status') as string,
    selStatusPayment: getFieldFilter('statusPayment') as string,
    changePage,
    changeSearchText,
    changeDate,
    resetFilters,
    changeManager,
    changeStatus,
    changeStatusPayment,
  };
};
