import React, { FC, Fragment, useMemo } from 'react';
import { List, ListItem, ListItemButton, ListItemText, Stack } from '@mui/material';
import { useGetCategoryTreeQuery } from '../../../graphql/queries/__generated__/getCategoryTree';
import { useLocalization } from '../../../localization';
import { CategoryTree } from '../../../__generated__/types';

interface Props {
  idCategory?: string | null;
  subCategoryName: string;
  secondCategory: (
    tecdoc_categoryId: string,
    nameCategory: string,
    nameSubCategory: string
  ) => void;
}

export const SecondaryCategories: FC<Props> = ({ idCategory, subCategoryName, secondCategory }) => {
  const { selectLanguages } = useLocalization();
  const { data: dataSecondCategories } = useGetCategoryTreeQuery({
    variables: {
      input: {
        locale: selectLanguages,
        levelFrom: 2,
        levelTo: 3,
      },
    },
  });
  const dataSecondaryCategories = useMemo(
    () =>
      dataSecondCategories?.getCategoryTree?.categoryTree?.filter(
        item => item?.parentId === idCategory
      ),
    [dataSecondCategories?.getCategoryTree?.categoryTree, idCategory]
  );

  const handlerSecondCategory = (category: CategoryTree) => {
    secondCategory(category.id || '', subCategoryName, category?.name || '');
  };

  return (
    <Stack sx={{ wordBreak: 'break-all' }}>
      {dataSecondaryCategories?.map((item, index) => {
        return (
          <Fragment key={index.toString()}>
            {item?.name && (
              <List disablePadding>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => handlerSecondCategory(item)} component="button">
                    <ListItemText
                      sx={{
                        color: 'gray',
                        textTransform: 'none',
                        fontSize: 15,
                        fontWeight: 'medium',
                        lineHeight: '20px',
                      }}
                      primary={item?.name}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            )}
          </Fragment>
        );
      })}
    </Stack>
  );
};
