import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { addDays, endOfToday, startOfDay, subDays } from 'date-fns';
import { Box, Stack, TextField, Autocomplete, UseAutocompleteProps, Button } from '@mui/material';

import {
  ContactNotificationFiltersInput,
  Enum_Contactnotification_Status,
  OrderFiltersInput,
} from '../../../../__generated__/types';
import { useLocalization } from '../../../../localization';
import { useDefaultRangeCalendar } from '../../../../hooks';
import { RangeCalendar } from '../../../../legos/rangeCalendar/RangeCalendar';
import { useFiltersSearchParamsForOrders } from '../../../../hooks/useFiltersSearchParamsForOrders';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';

interface Props {
  setResetFilter(): void;
  result?: string | number;
  setFilterSubscription: Dispatch<SetStateAction<ContactNotificationFiltersInput>>;
}

export type NotificationsStatusType = {
  label: string;
  value: Enum_Contactnotification_Status;
};

const NotificationsTypes: NotificationsStatusType[] = [
  ...[
    Enum_Contactnotification_Status.Completed,
    Enum_Contactnotification_Status.InProcess,
    Enum_Contactnotification_Status.New,
  ].map(value => ({
    label: value,
    value,
  })),
];

export const ToolBarContactNotifications: FC<Props> = ({
  result,
  setFilterSubscription,
  setResetFilter,
}) => {
  const { translateLang } = useLocalization();
  const { reportRangeDates } = useDefaultRangeCalendar();
  const { changeDate, selDateTo, selDateFrom, resetFilters } = useFiltersSearchParamsForOrders();

  const [currentValueStatus, setCurrentValueStatus] = useState<NotificationsStatusType | null>();
  const [selectDates, setSelectedDates] = useState(getDatesFilters);

  const handlerReset = () => {
    setResetFilter();
    resetFilters();
    setSelectedDates([startOfDay(subDays(new Date(), 30)), new Date()]);
  };
  const handlerChangesDates = (val: Date[]) => {
    setSelectedDates(val);
    changeDate(
      val[0]?.toISOString(),
      val[1] ? addDays(val[1], 1)?.toISOString() : addDays(val[0], 1).toISOString()
    );
  };

  const handlerClearFilter = (deleteField: 'status') => {
    setCurrentValueStatus(undefined);

    setFilterSubscription(oldFilter => {
      const reset = { ...oldFilter };
      delete reset[deleteField];
      return reset;
    });
  };
  const handleChangeStatus: UseAutocompleteProps<
    NotificationsStatusType,
    any,
    any,
    any
  >['onChange'] = (evt, option): void => {
    if (option) {
      let filter: OrderFiltersInput | undefined;
      if (typeof option !== 'string') {
        const _option = option as NotificationsStatusType;
        filter = {
          status: {
            eq: _option.value,
          },
        };
      }
      setCurrentValueStatus(option as NotificationsStatusType);
      setFilterSubscription(oldFilter => ({
        ...oldFilter,
        ...filter,
      }));
    }
  };

  useEffect(() => {
    setFilterSubscription(oldFilter => ({
      ...oldFilter,
      createdAt: {
        between: [
          selDateFrom !== '' ? selDateFrom : startOfDay(subDays(new Date(), 30)).toISOString(),
          selDateTo !== '' ? selDateTo : endOfToday().toISOString(),
        ],
      },
    }));
  }, [selectDates]);

  function getDatesFilters(): Date[] {
    if (selDateFrom && selDateTo) {
      return [new Date(selDateFrom), new Date(selDateTo)];
    }
    return [startOfDay(subDays(new Date(), 30)), endOfToday()];
  }

  return (
    <>
      <Stack bgcolor="white" pl={2} paddingY={3} flexDirection="row" alignItems="center">
        <Stack width="100%" flexDirection="row" justifyContent="left">
          <TranslatedField
            fontSize="30px"
            originText={`${translateLang('result')}: ${result}` || ''}
          />
        </Stack>
        <Stack
          pr={3}
          py="27px"
          spacing={2}
          bgcolor="white"
          flexDirection="row"
          justifyContent="right"
        >
          <Stack direction="row" gap={2} alignItems="center">
            <TranslatedField originText={translateLang('status')} />
            <Autocomplete
              size="small"
              disablePortal
              id="combo-box-demo"
              sx={{ minWidth: 200 }}
              value={currentValueStatus}
              options={NotificationsTypes || []}
              renderInput={params => <TextField {...params} label={translateLang('status')} />}
              getOptionLabel={option => {
                return translateLang(option?.value) || '';
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.label}>
                  {translateLang(option.value)}
                </Box>
              )}
              onChange={handleChangeStatus}
              onInputChange={(e, value, reason) => {
                if (reason === 'clear') {
                  handlerClearFilter('status');
                }
              }}
            />
            <RangeCalendar
              selectedDates={selectDates}
              defaultRangeDates={reportRangeDates}
              setSelectedDates={handlerChangesDates}
            />
            <Button
              color="primary"
              variant="outlined"
              onClick={handlerReset}
              style={{
                width: '100%',
                textTransform: 'none',
              }}
            >
              <TranslatedField
                noWrap
                isTranslate
                fontSize={16}
                color="black"
                originText="resetFilters"
              />
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
