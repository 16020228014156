import React, { useState } from 'react';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useLocalization } from '../../localization';
import { AddClientModal } from '../ClientInfo/components/Modals';
import { PatchesName } from '../../types/types';
import { OrderStepper } from '../../components/NewOrderStepper';
import { WrapperPages } from '../../components/Wrapper/WrapperPages';
import { TableClients } from '../ClientsList/components/TableClients';

export const CreateOrderPage = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const closeModal = () => {
    setOpenModal(false);
  };
  const navigate = useNavigate();
  const { translateLang } = useLocalization();

  return (
    <WrapperPages
      isButton
      titleButton={translateLang('addClients')}
      titleHeader={translateLang('newOrder')}
      handlerButton={() => setOpenModal(true)}
    >
      <Box sx={{ position: 'relative' }}>
        <OrderStepper totalStep={3} activeStep={0} withoutStep={true} />

        <TableClients
          isCreateOrder
          handlerCellClick={idUser =>
            navigate(`/${PatchesName.Orders}/${PatchesName.CreateOrder}/${idUser}`)
          }
        />
      </Box>
      <AddClientModal closeModal={closeModal} openModal={openModal} />
    </WrapperPages>
  );
};
