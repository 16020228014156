import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';

import { theme } from '../../../helpers';
import { useLocalization } from '../../../localization';
import { useGetSuppliersQuery } from '../../../graphql/queries/__generated__/getSuppliers';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';

interface SupplierInfoHeaderProps {
  idSupplier: string;
  supplierBalance: number;
  remainingPayment: number;
  remainingReturn: number;
}

export const SupplierInfoHeader: FC<SupplierInfoHeaderProps> = ({
  idSupplier,
  supplierBalance,
  remainingPayment,
  remainingReturn,
}) => {
  const navigate = useNavigate();
  const { translateLang } = useLocalization();

  const { data: suppliersData } = useGetSuppliersQuery({
    variables: {
      filters: { id: { eq: idSupplier } },
    },
  });

  const supplierName = suppliersData?.suppliers?.data[0].attributes?.name;

  const handleGoBack = () => {
    navigate('/accounting?tab=providers');
  };

  return (
    <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={5}>
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <IconButton onClick={handleGoBack}>
          <ArrowBack sx={{ color: theme.palette.common.black }} />
        </IconButton>
        <Typography fontSize={40} fontWeight={400}>
          {supplierName}
        </Typography>
      </Stack>
      <Stack flexDirection="row" alignItems="center" gap={2.5}>
        <Stack
          flexDirection="row"
          alignItems="center"
          bgcolor={theme.palette.common.white}
          py={1.5}
          px={2}
          gap={1.25}
          borderRadius="10px"
        >
          <TranslatedField
            isTranslate
            color="black"
            fontSize={18}
            fontWeight={300}
            originText={`${translateLang('unlinkedPayments')}:`}
          />
          <Typography
            fontSize={20}
            sx={{
              color:
                remainingPayment === 0 ? theme.palette.common.appleGreen : theme.palette.common.red,
            }}
          >
            {`${remainingPayment}€`}
          </Typography>
        </Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          bgcolor={theme.palette.common.white}
          py={1.5}
          px={2}
          gap={1.25}
          borderRadius="10px"
        >
          <TranslatedField
            isTranslate
            color="black"
            fontSize={18}
            fontWeight={300}
            originText={`${translateLang('unlinkedReturns')}:`}
          />
          <Typography
            fontSize={20}
            sx={{
              color:
                remainingReturn === 0 ? theme.palette.common.appleGreen : theme.palette.common.red,
            }}
          >{`${remainingReturn}€`}</Typography>
        </Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          bgcolor={theme.palette.common.white}
          py={1.5}
          px={2}
          gap={1.25}
          borderRadius="10px"
        >
          <TranslatedField
            isTranslate
            color="black"
            fontSize={20}
            fontWeight={300}
            originText={`${translateLang('balance')}:`}
          />
          <Typography
            fontSize={26}
            sx={{
              color:
                supplierBalance >= 0 ? theme.palette.common.appleGreen : theme.palette.common.red,
            }}
          >
            {`${supplierBalance > 0 ? '+' : ''}${supplierBalance}€`}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
