import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';

import { StocksTable } from './StocksTable';
import { useGetStocks } from './hooks/useGetStocks';
import { Icon } from '../../../../../../../../legos';
import { theme } from '../../../../../../../../helpers';
import { useLocalization } from '../../../../../../../../localization';
import { useHandlerNotificationApp } from '../../../../../../../../hooks/useHandlerNotificationApp';
import { TranslatedField } from '../../../../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useUpdateIncomingInvoiceMutation } from '../../../../../../../../graphql/mutations/__generated__/updateIncomingInvoice';

interface Props {
  invoiceId: string;
  idSupplier: string;
  isModalOpen: boolean;
  handleClose: () => void;
  incomingInvoiceRefetch: () => void;
  incomingInvoiceIds: string[];
}

export const AddProductsModal: FC<Props> = ({
  isModalOpen,
  idSupplier,
  invoiceId,
  incomingInvoiceIds,
  handleClose,
  incomingInvoiceRefetch,
}) => {
  const { translateLang } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();

  const [updateIncomingInvoiceMutation] = useUpdateIncomingInvoiceMutation();

  const { itemsStock, loading } = useGetStocks({
    filters: {
      supplier: {
        id: { eq: idSupplier },
      },
    },
  });

  const itemsStockFiltered = itemsStock.filter(
    item => !incomingInvoiceIds.includes(item.productStockId) && !item.incomingInvoice
  );

  const [allChecked, setAllChecked] = useState(false);

  const [items, setItems] = useState(itemsStockFiltered);
  const [stocksIds, setStocksIds] = useState<string[] | []>([]);

  useEffect(() => {
    setItems(itemsStockFiltered);
  }, [loading]);

  const toggleChecked = () => {
    const newItems = items?.map(item => ({
      ...item,
      checked: !allChecked,
    }));
    const newStockIds = newItems.filter(item => item.checked).map(item => item.productStockId);
    setAllChecked(!allChecked);
    setItems(newItems);
    setStocksIds(newStockIds);
  };

  const handleCheckedItem = (id: string) => {
    const newItems = items?.map(item => ({
      ...item,
      checked: item.productStockId === id ? !item.checked : item.checked,
    }));
    const newStockIds = newItems.filter(item => item.checked).map(item => item.productStockId);

    setAllChecked(false);
    setItems(newItems);
    setStocksIds(newStockIds);
  };

  const handleUpdateIncomingInvoices = () => {
    updateIncomingInvoiceMutation({
      variables: {
        data: { stocks: [...incomingInvoiceIds, ...stocksIds] },
        id: invoiceId,
      },
    })
      .then(() => {
        addNotification({
          messageError: translateLang('invoiceSuccessfullyUpdated'),
          typeMessage: 'success',
        });
        handleClose();
        incomingInvoiceRefetch();
      })
      .catch(err => {
        addNotification({ messageError: err.message, typeMessage: 'error' });
      });
  };

  return (
    <Dialog open={true} onClose={handleClose} closeAfterTransition maxWidth="xl">
      <DialogTitle>
        <Stack
          width="100%"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography fontSize={30} fontWeight={400} color={theme.palette.common.darkBlue}>
            {translateLang('addProducts')}
          </Typography>

          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <Icon icon="close" sx={{ color: theme.palette.primary.main }} />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent>
        {!loading ? (
          <StocksTable
            items={items}
            allChecked={allChecked}
            toggleChecked={toggleChecked}
            handleCheckedItem={handleCheckedItem}
          />
        ) : (
          <Stack width="100%" alignItems="center" mt={10}>
            <CircularProgress size={40} />
          </Stack>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', pb: 4 }}>
        <Button
          disabled={stocksIds.length <= 0}
          variant="contained"
          fullWidth
          sx={{
            height: 50,
            maxWidth: 320,
            textTransform: 'none',
            backgroundColor: '#5269A3',
            borderRadius: '19px',
            '&:disabled': {
              color: theme.palette.common.white,
            },
          }}
          onClick={handleUpdateIncomingInvoices}
        >
          <TranslatedField originText={'addProducts'} fontSize={16} isTranslate noWrap />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
