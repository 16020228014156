import React, {
  ChangeEvent,
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { AddCircleOutline, Edit } from '@mui/icons-material';

import { Icon } from '../../../../legos';
import { theme } from '../../../../helpers';
import { RolesEnum } from '../../../../types/types';
import { useGetMe } from '../../../../graphql/queries/hook/useGetMe';
import { DataModalType, OrderItemType } from '../../../../helpers/types';
import { useHandlerNotificationApp } from '../../../../hooks/useHandlerNotificationApp';
import { TableProductActive } from '../../../../components/TableProductActive/TableProductActive';
import { useUpdateOrderItemMutation } from '../../../../graphql/mutations/__generated__/updateOrderItem';
import { GetOrderByIdDocument } from '../../../../graphql/queries/__generated__/getOrderById';
import { roundAmountToString } from '../../../../utils/currencyUtils';
import { formatDate } from '../../../../helpers/functions';

interface Props {
  id: string;
  index: number;
  changeQuantity(): void;
  changeCustomerPrice(): void;
  setDataDeleteModal: Dispatch<SetStateAction<DataModalType<string>>>;
  itemRow: OrderItemType;
  openModalComment(): void;
  idOrder: string;
  locale?: string;
}

export const TableSelectedSpareRowItem: FC<Props> = ({
  id,
  index,
  itemRow,
  changeQuantity,
  openModalComment,
  setDataDeleteModal,
  changeCustomerPrice,
  idOrder,
  locale,
}) => {
  const { role } = useGetMe();
  const { addNotification } = useHandlerNotificationApp();
  const [runUpdateOrderItem] = useUpdateOrderItemMutation({
    refetchQueries: [
      {
        query: GetOrderByIdDocument,
        variables: {
          id: idOrder,
          locale,
        },
      },
    ],
  });
  const [currentDiscount, setCurrentDiscount] = useState(0);
  const timer = useRef<NodeJS.Timer>();
  const firstChangeInput = useRef(false);
  const discountInputRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  const [discountError, setDiscountError] = useState(false);

  const handleSubmitDiscount = async (discountPercent: number) => {
    if (discountPercent < 0) {
      discountPercent = 0;
    }

    setCurrentDiscount(discountPercent);
    try {
      const response = await runUpdateOrderItem({
        variables: {
          id,
          data: {
            discount: discountPercent,
          },
        },
      });
      if (response.data) {
        setCurrentDiscount(discountPercent);
        setDiscountError(false);
        addNotification({
          messageError: 'Update item discount success',
          typeMessage: 'success',
        });
      }
    } catch (error) {
      setDiscountError(true);
      discountInputRef?.current?.click();
      console.error('[ORDER_ITEM_ERROR]', error);
      addNotification({ messageError: 'Order Discount Error', typeMessage: 'error' });
    }
  };

  const handleInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
    firstChangeInput.current = true;
    setCurrentDiscount(+evt.target.value);
    setDiscountError(false);
  };

  useEffect(() => {
    if (firstChangeInput.current) {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        handleSubmitDiscount(currentDiscount);
      }, 1000);
    }
  }, [currentDiscount]);

  function handleDiscountBlur() {
    setDiscountError(false);
    firstChangeInput.current = false;
    handleSubmitDiscount(currentDiscount);
  }

  const getCommentPart = () => {
    if (itemRow?.comment === '') {
      return (
        <IconButton onClick={openModalComment}>
          <AddCircleOutline />
        </IconButton>
      );
    }
    return (
      <Typography onClick={openModalComment} noWrap>
        {itemRow.comment}
      </Typography>
    );
  };

  return (
    <TableProductActive.RowTable>
      <TableProductActive.CellTable ceilStyle={{ width: '20px', borderLeft: 'none' }}>
        {index + 1}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable>{itemRow.article}</TableProductActive.CellTable>
      <TableProductActive.CellTable>{itemRow.productName}</TableProductActive.CellTable>
      <TableProductActive.CellTable>{itemRow.brandName}</TableProductActive.CellTable>
      <TableProductActive.CellTable>{itemRow.provider}</TableProductActive.CellTable>
      <TableProductActive.CellTable>{formatDate(new Date())}</TableProductActive.CellTable>
      <TableProductActive.CellTable>
        <Box height="100%" display="flex" alignItems="center" position="relative">
          {itemRow.quantity}
          {itemRow?.quantity && (
            <IconButton
              disabled={itemRow.quantity === itemRow.confirmedQuantity}
              sx={{
                marginLeft: '8px',
                position: 'absolute',
              }}
              onClick={changeQuantity}
            >
              <Edit />
            </IconButton>
          )}
        </Box>
      </TableProductActive.CellTable>
      <TableProductActive.CellTable>
        <TextField
          fullWidth
          value={(firstChangeInput.current && currentDiscount) || itemRow?.discount || 0}
          InputProps={{
            ref: discountInputRef,
            inputProps: { min: itemRow?.discount && itemRow?.discount < 0 ? undefined : 0 },
          }}
          onChange={handleInputChange}
          onBlur={handleDiscountBlur}
          type="number"
          disabled={
            itemRow?.quantity === itemRow?.confirmedQuantity ||
            (role !== RolesEnum.SuperAdmin &&
              role !== RolesEnum.Admin &&
              role !== RolesEnum.Manager)
          }
          sx={{
            '& .Mui-focused fieldset': {
              border: discountError
                ? `2px solid ${theme.palette.common.red} !important`
                : undefined,
            },
          }}
        />
      </TableProductActive.CellTable>
      <TableProductActive.CellTable>
        <Box height="100%" display="flex" alignItems="center" position="relative">
          {roundAmountToString((itemRow?.customerPrice ?? 0) * (itemRow?.quantity ?? 0))}
          {itemRow?.providerPrice && itemRow?.providerPrice < 20 && (
            <IconButton onClick={changeCustomerPrice}>
              <Edit />
            </IconButton>
          )}
        </Box>
      </TableProductActive.CellTable>
      <TableProductActive.CellTable>
        <Box width="230px" display="flex" justifyContent="center" sx={{ cursor: 'pointer' }}>
          {getCommentPart()}
        </Box>
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ justifyContent: 'center' }}>
        <IconButton
          disabled={itemRow?.quantity === itemRow?.confirmedQuantity}
          onClick={() =>
            setDataDeleteModal({
              selProduct: `${id}`,
              isOpen: true,
            })
          }
        >
          <Icon fontSize="medium" icon="deleting" color="inherit" />
        </IconButton>
      </TableProductActive.CellTable>
    </TableProductActive.RowTable>
  );
};
