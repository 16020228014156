import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { PaginationFragmentDoc } from '../../../../graphql/fragments/__generated__/Pagination';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type GetIncomingInvoicesStocksQueryVariables = Types.Exact<{
  pagination?: Types.InputMaybe<Types.PaginationArg>;
  incomingInvoiceId?: Types.InputMaybe<Types.Scalars['ID']>;
  sort?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  filtersLocale?: Types.InputMaybe<Types.CarSpareLocaleFiltersInput>;
}>;


export type GetIncomingInvoicesStocksQuery = { __typename?: 'Query', stocks?: { __typename?: 'StockEntityResponseCollection', meta: { __typename?: 'ResponseCollectionMeta', pagination: { __typename?: 'Pagination', page: number, pageCount: number, pageSize: number, total: number } }, data: Array<{ __typename?: 'StockEntity', id?: string | null, attributes?: { __typename?: 'Stock', incomingQuantity?: number | null, balanceQuantity: number, supplierPrice?: number | null, car_spare?: { __typename?: 'CarSpareEntityResponse', data?: { __typename?: 'CarSpareEntity', id?: string | null, attributes?: { __typename?: 'CarSpare', tecdoc_articleNumber?: string | null, car_spare_locales?: { __typename?: 'CarSpareLocaleRelationResponseCollection', data: Array<{ __typename?: 'CarSpareLocaleEntity', id?: string | null, attributes?: { __typename?: 'CarSpareLocale', title?: string | null, brandName?: string | null } | null }> } | null } | null } | null } | null } | null }> } | null };


export const GetIncomingInvoicesStocksDocument = gql`
    query getIncomingInvoicesStocks($pagination: PaginationArg, $incomingInvoiceId: ID, $sort: [String], $filtersLocale: CarSpareLocaleFiltersInput) {
  stocks(
    pagination: $pagination
    filters: {incoming_invoice: {id: {eq: $incomingInvoiceId}}}
    sort: $sort
  ) {
    meta {
      ...Pagination
    }
    data {
      id
      attributes {
        incomingQuantity
        balanceQuantity
        supplierPrice
        car_spare {
          data {
            id
            attributes {
              car_spare_locales(filters: $filtersLocale) {
                data {
                  id
                  attributes {
                    title
                    brandName
                  }
                }
              }
              tecdoc_articleNumber
            }
          }
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useGetIncomingInvoicesStocksQuery__
 *
 * To run a query within a React component, call `useGetIncomingInvoicesStocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncomingInvoicesStocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncomingInvoicesStocksQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      incomingInvoiceId: // value for 'incomingInvoiceId'
 *      sort: // value for 'sort'
 *      filtersLocale: // value for 'filtersLocale'
 *   },
 * });
 */
export function useGetIncomingInvoicesStocksQuery(baseOptions?: Apollo.QueryHookOptions<GetIncomingInvoicesStocksQuery, GetIncomingInvoicesStocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIncomingInvoicesStocksQuery, GetIncomingInvoicesStocksQueryVariables>(GetIncomingInvoicesStocksDocument, options);
      }
export function useGetIncomingInvoicesStocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIncomingInvoicesStocksQuery, GetIncomingInvoicesStocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIncomingInvoicesStocksQuery, GetIncomingInvoicesStocksQueryVariables>(GetIncomingInvoicesStocksDocument, options);
        }
export type GetIncomingInvoicesStocksQueryHookResult = ReturnType<typeof useGetIncomingInvoicesStocksQuery>;
export type GetIncomingInvoicesStocksLazyQueryHookResult = ReturnType<typeof useGetIncomingInvoicesStocksLazyQuery>;
export type GetIncomingInvoicesStocksQueryResult = Apollo.QueryResult<GetIncomingInvoicesStocksQuery, GetIncomingInvoicesStocksQueryVariables>;