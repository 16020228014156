import React, { FC, ReactElement } from 'react';
import { useGetMeQuery } from '../../graphql/queries/__generated__/getMe';

interface Props {
  children: ReactElement | null;
}

export const EntranceGate: FC<Props> = ({ children }) => {
  const { loading } = useGetMeQuery();
  if (loading) {
    return null;
  }
  return children;
};
