import React, { FC } from 'react';
import { Box } from '@mui/material';

import { ReturnType } from './hooks/useGetSupplierReturns';
import { useGetSupplierReturnsTable } from './hooks/useGetSupplierReturnsTable';
import { TableProductActive } from '../../../../components/TableProductActive/TableProductActive';

interface Props {
  totalPage?: number;
  supplierReturns?: ReturnType[];
  currentPage?: number;
  loading?: boolean;
  changePage(page: number): void;
  toggleReturnInvoiceTable: (id: string) => void;
}

export const SupplierReturnsTable: FC<Props> = ({
  supplierReturns,
  totalPage,
  currentPage,
  loading,
  changePage,
  toggleReturnInvoiceTable,
}) => {
  const { getHeadersTable, getRowsTableActive } = useGetSupplierReturnsTable({
    supplierReturns,
    toggleReturnInvoiceTable,
  });

  return (
    <Box boxSizing="border-box" width="100%">
      <TableProductActive
        startPage={currentPage}
        totalPages={totalPage}
        itemsRows={getRowsTableActive()}
        itemsHeader={getHeadersTable()}
        changeCurrentPage={changePage}
        loading={loading}
      />
    </Box>
  );
};
