import React, { FC, ReactNode, useRef, useState } from 'react';
import { Box, CircularProgress, Typography, Zoom } from '@mui/material';
import { useLocalization } from '../../../../localization';
import { GetSearchProductQuery } from '../../../../graphql/queries/__generated__/getSearchProducts';
import { ItemProvider } from './ItemProvider';

interface Props {
  isOpen: boolean;
  searchProducts?: GetSearchProductQuery['searchProducts'] | null;
  handlerSelectedProvider(index: number): void;
}

export const SelectProviderComponent: FC<Props> = ({
  isOpen,
  searchProducts,
  handlerSelectedProvider,
}) => {
  const { translateLang } = useLocalization();
  const [, setSelectProvider] = useState<number | null>(null);
  const [load, setLoad] = useState(false);
  const timerRef = useRef<NodeJS.Timer>();

  const handlerClickItem = (index: number) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setLoad(true);
    setSelectProvider(index);
    timerRef.current = setTimeout(() => {
      setLoad(false);
      handlerSelectedProvider(index);
    }, 2000);
  };
  const getItemsProviders = (): ReactNode[] | null => {
    if (searchProducts?.length) {
      const widPer = 100 / searchProducts.length - 4;
      return searchProducts.map((item, index) => (
        <ItemProvider
          key={index.toString()}
          onClickItem={() => handlerClickItem(index)}
          widthPercent={widPer}
          active={true}
          provider={item?.supplierName || ''}
          orderID={item?.orderId || ''}
        />
      ));
    }
    return null;
  };

  return (
    <Zoom
      in={isOpen}
      mountOnEnter
      unmountOnExit
      style={{ transitionDelay: isOpen ? '500ms' : '0ms' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        p={1}
        alignItems="center"
        paddingX="100px"
        boxSizing="border-box"
      >
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '36px',
            lineHeight: '45px',
            marginBottom: '16px',
          }}
        >
          {translateLang('selectProvider')}
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '20px',
            lineHeight: '25px',
          }}
        >
          {translateLang('selectProviderExplanation')}
        </Typography>
        <Box
          display="flex"
          width="100%"
          mt="70px"
          mb="20px"
          p={3}
          justifyContent="space-between"
          flexWrap="wrap"
          maxHeight="250px"
          overflow="auto"
          boxSizing="border-box"
        >
          {getItemsProviders()}
        </Box>
        {load && <CircularProgress size={30} color="secondary" />}
      </Box>
    </Zoom>
  );
};
