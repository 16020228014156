import { Badge, Button } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC } from 'react';
import { useLocalization } from '../../../localization';
interface Props {
  notification?: number;
  activeTab: string | null;
  label?: string;
  value?: string;
  id?: string;
  handleSet: (val: string | undefined) => void;
  notBadge?: boolean;
}
export const BadgetButtons: FC<Props> = ({
  label,
  value,
  notification = 0,
  activeTab,
  handleSet,
  notBadge,
}) => {
  const { translateLang } = useLocalization();
  return (
    <Box>
      <Button
        fullWidth={true}
        variant={activeTab === value ? 'contained' : 'text'}
        color={activeTab === value ? 'primary' : 'secondary'}
        sx={{
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '18px',
          marginX: '10px',
          height: '48px',
          paddingX: '10px',
          textTransform: 'none',
        }}
        onClick={() => handleSet(value)}
      >
        {translateLang(`${label}`)}
        {!notBadge && notification !== undefined && (
          <Badge
            color={notification >= 1 ? 'warning' : 'primary'}
            badgeContent={notification}
            sx={{
              marginLeft: '20px',
              '& .MuiBadge-badge': {
                borderRadius: '5px',
              },
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            showZero
          ></Badge>
        )}
      </Button>
    </Box>
  );
};
