import React, { FC } from 'react';
import { Box } from '@mui/material';

import { ItemStockType } from './hooks/useGetStocks';
import { useGetStocksTable } from './hooks/useGetStocksTable';
import { TableProductActive } from '../../../../../../../../components/TableProductActive/TableProductActive';

interface Props {
  items?: ItemStockType[];
  loading?: boolean;
  allChecked: boolean;
  toggleChecked: () => void;
  handleCheckedItem: (id: string) => void;
}

export const StocksTable: FC<Props> = ({
  items,
  allChecked,
  loading,
  handleCheckedItem,
  toggleChecked,
}) => {
  const { getHeadersTable, getRowsTableActive } = useGetStocksTable({
    items,
    handleCheckedItem,
    toggleChecked,
    allChecked,
  });

  return (
    <>
      <Box boxSizing="border-box" width="100%" maxHeight={500} overflow="scroll">
        <TableProductActive
          itemsRows={getRowsTableActive()}
          itemsHeader={getHeadersTable()}
          loading={loading}
        />
      </Box>
    </>
  );
};
