/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { OrderStepper } from '../../components/NewOrderStepper';
import { ClientInfoStep } from '../ClientInfo/components/ClientInfoStep';
import { WrapperPages } from '../../components/Wrapper/WrapperPages';
import { useLocalization } from '../../localization';
import { RegistrationOrderStep } from '../ClientInfo/RegistrationOrderStep';
import { VehicleList } from './components/VehicleList';
import { useNavigate, useParams } from 'react-router-dom';
import { PatchesName, UrlParamsObjectType } from '../../types/types';
import { ButtonSaveOrder } from './components/ButtonSaveOrder';
import { useAddOrderMutation } from '../../graphql/mutations/__generated__/addOrder';
import { useGetMeQuery } from '../../graphql/queries/__generated__/getMe';
import { useHandlerNotificationApp } from '../../hooks/useHandlerNotificationApp';
import {
  ComponentOrderStatusEditingHistoryInput,
  Enum_Componentorderstatuseditinghistory_Status,
  Enum_Order_Status,
} from '../../__generated__/types';
import {
  UpdateOrderMutationVariables,
  useUpdateOrderMutation,
} from '../../graphql/mutations/__generated__/updateOrder';
import { useGetManagers } from './hooks/useGetManagers';
import { getVariablesOrderQuery } from '../../graphql/queries/hook/useGetOrders';
import { GetOrdersDocument } from '../../graphql/queries/__generated__/getOrders';
import { format } from 'date-fns';
import { Address } from '../../helpers/functions';

enum StepperComponent {
  Step1,
  Step2,
  Step3,
}
export const CheckoutPage = () => {
  const { idOrder = '', idUser = '' } = useParams<UrlParamsObjectType>();
  const navigate = useNavigate();
  const { translateLang, updateSelectLanguage } = useLocalization();
  const [runAddOrder] = useAddOrderMutation();
  const { data } = useGetMeQuery();
  const { addNotification } = useHandlerNotificationApp();
  const [runUpdateOrder] = useUpdateOrderMutation();
  const { managers, orderNumber, orderDeliveryAddress, customerDeliveryAddresses } =
    useGetManagers(idOrder);
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState<Address | null>(
    orderDeliveryAddress
  );
  const [currentStep, setCurrentStep] = useState(StepperComponent.Step1);

  useEffect(() => {
    if (idOrder && !selectedDeliveryAddress) {
      setSelectedDeliveryAddress(
        orderDeliveryAddress ||
          customerDeliveryAddresses?.find(address => address?.main) ||
          customerDeliveryAddresses?.[0]
      );
    }
  }, [customerDeliveryAddresses, idOrder, orderDeliveryAddress, selectedDeliveryAddress]);

  const handleNext = () => {
    if (!idOrder && currentStep === StepperComponent.Step1) {
      createOrder(true).then(_idOrder => {
        if (_idOrder) {
          navigate(`/${PatchesName.Orders}/${PatchesName.CreateOrder}/${idUser}/${_idOrder}`, {
            replace: true,
          });
          setCurrentStep(StepperComponent.Step2);
        }
      });
    } else {
      setCurrentStep(currentStep => currentStep + 1);
    }
  };
  const handleBack = () => {
    setCurrentStep(currentStep => currentStep - 1);
  };

  const createOrder = async (stopNavigation?: boolean): Promise<string> => {
    const statusHistory: ComponentOrderStatusEditingHistoryInput = {
      date: new Date().toISOString(),
      manager: data?.me?.id || '',
      status: Enum_Componentorderstatuseditinghistory_Status.Created,
      status_note: '',
    };
    try {
      const response = await runAddOrder({
        variables: {
          data: {
            managers: [data?.me?.id || ''],
            customer: idUser,
            status: Enum_Order_Status.Created,
            status_editing_history: [statusHistory],
            deliveryAddress: selectedDeliveryAddress
              ? {
                  city: selectedDeliveryAddress.city,
                  country: selectedDeliveryAddress.country,
                  district: selectedDeliveryAddress.district,
                  number: selectedDeliveryAddress.number,
                  street: selectedDeliveryAddress.street,
                  zipCode: selectedDeliveryAddress.zipCode,
                }
              : null,
            date: format(new Date(), 'yyyy-MM-dd'),
          },
        },
        refetchQueries: [
          {
            query: GetOrdersDocument,
            variables: getVariablesOrderQuery(updateSelectLanguage as string),
          },
        ],
      });
      if (response && response?.data) {
        if (!stopNavigation) {
          addNotification({
            messageError: 'Create order success!!!',
            typeMessage: 'success',
          });
          navigate(`/${PatchesName.Orders}`, { replace: true });
        } else {
          addNotification({
            messageError: 'Create empty order success!!!',
            typeMessage: 'success',
          });
        }
      }
      return response?.data?.createOrder?.data?.id || '';
    } catch (error) {
      console.error('[ORDER_ERROR]', error);
      addNotification({ messageError: 'Order Error', typeMessage: 'error' });
      return '';
    }
  };

  const updateOrder = async () => {
    const variables: UpdateOrderMutationVariables = {
      id: idOrder,
      data: {
        managers,
        deliveryAddress: selectedDeliveryAddress
          ? {
              city: selectedDeliveryAddress.city,
              country: selectedDeliveryAddress.country,
              district: selectedDeliveryAddress.district,
              number: selectedDeliveryAddress.number,
              street: selectedDeliveryAddress.street,
              zipCode: selectedDeliveryAddress.zipCode,
            }
          : null,
      },
      locale: updateSelectLanguage as string,
    };
    try {
      const response = await runUpdateOrder({ variables });
      if (response && response?.data) {
        addNotification({ messageError: 'Update order success!!!', typeMessage: 'success' });
        navigate(`/${PatchesName.Orders}`, { replace: true });
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handlerButtonWrapper = async () => {
    if (!idOrder) {
      await createOrder();
    } else {
      await updateOrder();
    }
  };

  const titleHeader = idOrder
    ? `${translateLang('order')}: #${orderNumber} `
    : translateLang('newOrder');

  return (
    <WrapperPages
      titleHeader={titleHeader}
      colorButton="primary"
      customChildrenButton={<ButtonSaveOrder handlerButton={handlerButtonWrapper} />}
    >
      <OrderStepper
        activeStep={currentStep}
        onNext={handleNext}
        onPrev={handleBack}
        totalStep={2}
      />
      <>
        {currentStep === StepperComponent.Step1 && (
          <ClientInfoStep
            isNewOrder={!idOrder}
            selectedDeliveryAddress={selectedDeliveryAddress}
            setSelectedDeliveryAddress={setSelectedDeliveryAddress}
          />
        )}
        {currentStep === StepperComponent.Step2 && <VehicleList isStepper />}
        {currentStep === StepperComponent.Step3 && <RegistrationOrderStep />}
      </>
    </WrapperPages>
  );
};
