import { Autocomplete, Box, Stack, TextField, UseAutocompleteProps } from '@mui/material';
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { useLocalization } from '../../../localization';
import { Enum_Order_Status, OrderFiltersInput } from '../../../__generated__/types';
import { WrapperToolBar } from '../../../components/WrapperToolBar';

interface Props {
  result: string | number | undefined;
  setFilterOrderStatus: Dispatch<SetStateAction<OrderFiltersInput | undefined>>;
}

type OrderCurrentStatusType = {
  label: string;
  value: Enum_Order_Status;
};

const OrderStatusTypes: OrderCurrentStatusType[] = [
  ...[
    Enum_Order_Status.Created,
    Enum_Order_Status.Editing,
    Enum_Order_Status.InStock,
    Enum_Order_Status.Awaiting,
    Enum_Order_Status.Delivery,
    Enum_Order_Status.Completed,
    Enum_Order_Status.Coordination,
  ].map(value => ({
    label: value,
    value,
  })),
];

export const ToolBarOrderHistory: FC<Props> = ({ setFilterOrderStatus }) => {
  const { translateLang } = useLocalization();
  const [currentValueStatus, setCurrentValueStatus] = useState<OrderCurrentStatusType | null>();
  const startRef = useRef(false);

  const handlerClearFilter = (deleteField: 'status') => {
    setCurrentValueStatus(undefined);

    setFilterOrderStatus(oldFilter => {
      const reset = { ...oldFilter };
      delete reset[deleteField];
      return reset;
    });
  };
  const handleChangeStatus: UseAutocompleteProps<
    OrderCurrentStatusType,
    any,
    any,
    any
  >['onChange'] = (evt, option): void => {
    if (option) {
      let filter: OrderFiltersInput | undefined;
      if (typeof option !== 'string') {
        const _option = option as OrderCurrentStatusType;
        filter = {
          status: {
            eqi: _option.value,
          },
        };
      }
      setCurrentValueStatus(option as OrderCurrentStatusType);
      setFilterOrderStatus(oldFilter => ({
        ...oldFilter,
        ...filter,
      }));
    }
  };

  useEffect(() => {
    if (startRef.current) {
      setFilterOrderStatus(oldFilter => ({
        ...oldFilter,
      }));
    }
  }, [currentValueStatus]);

  return (
    <WrapperToolBar>
      <Stack flexDirection="row" justifyContent="left">
        <TranslatedField fontSize="30px" originText={translateLang('history')} />
      </Stack>
      <Stack direction="row" gap={2} alignItems="center">
        <TranslatedField originText={translateLang('show')} />
        <Autocomplete
          size="small"
          disablePortal
          id="combo-box-demo"
          sx={{
            minWidth: 200,
          }}
          value={currentValueStatus}
          options={OrderStatusTypes || []}
          renderInput={params => <TextField {...params} label={translateLang('status')} />}
          getOptionLabel={option => {
            return translateLang(option?.value) || '';
          }}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.label}>
              {translateLang(option.value)}
            </Box>
          )}
          onChange={handleChangeStatus}
          onInputChange={(e, value, reason) => {
            if (reason === 'clear') {
              handlerClearFilter('status');
            }
          }}
        />
      </Stack>
    </WrapperToolBar>
  );
};
