import React, { FC, useEffect, useState } from 'react';
import { Stack, Box, Button, Typography } from '@mui/material';
import { useLocalization } from '../../../../../localization';
import { TranslatedField } from '../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { DetailBlockInfo } from './DetailBlockInfo';
import {
  AllOrdersDeliveryType,
  useLazyGetAllOrdersDelivery,
} from '../../../../../graphql/queries/hook/useGetAllOrdersDelivery';
import { MobileScrollComponent } from '../MobileScrollComponent';
import { DeliveryDateChangeModal } from '../../AllOrders/components/DeliveryDateChangeModal';
import { NoRowsImage } from '../../../../../components/NoRowsImage/NoRowsImage';
import { formatDate } from '../../../../../helpers/functions';
interface Props {
  isMobile: boolean;
}
export const InProgressOrderDetailBlockInfoPhone: FC<Props> = ({ isMobile }) => {
  const { translateLang } = useLocalization();
  const [testOrders, setTestOrders] = useState<AllOrdersDeliveryType[]>([]);
  const [page, setPage] = useState(1);
  const [modal, setModal] = useState(false);
  const { allOrdersDelivery, loading, totalPage, getOrdersData } =
    useLazyGetAllOrdersDelivery(page);

  const all = testOrders.length === totalPage;
  const preLoaderItem = () => {
    if (testOrders.length < totalPage) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    getOrdersData();
  }, [page]);

  useEffect(() => {
    const arr = [...testOrders, ...allOrdersDelivery];
    setTestOrders(arr);
  }, [allOrdersDelivery]);
  return (
    <Stack>
      {allOrdersDelivery.length ? (
        <>
          <Stack width="100%" flexDirection="row" mb="20px">
            <TranslatedField
              fontSize={{ sm: '20px', md: '30px' }}
              originText={translateLang('current')}
            />
            <Typography
              sx={{ color: allOrdersDelivery.length > 0 ? '#97A408' : 'black', ml: '10px' }}
            >
              {totalPage || ''}
            </Typography>
          </Stack>
          <Box height="500px" width="100%" boxSizing="border-box">
            <MobileScrollComponent
              handlerScrollToBottom={preLoaderItem}
              loading={loading}
              all={all}
            >
              {testOrders.map((item, index) => (
                <Stack
                  p="20px"
                  key={index}
                  sx={{
                    placeContent: 'space-between',
                    height: '60%',
                    borderRadius: '10px',
                    paddingX: '25px',
                    backgroundColor: '#ffff',
                    marginBottom: '20px',
                    animation: `${
                      !loading
                        ? 'fade-in 0.3s ease-in-out forwards'
                        : 'fade-in 0.3s ease-in-out forwards'
                    }`,
                  }}
                >
                  <DetailBlockInfo
                    customerInfo={item.customerInfo}
                    customerPhone={item.customerPhone}
                    deliveryAddress={item.deliveryAddress}
                    orderNumber={item.orderNumber}
                    deliveryTime={item.deliveryTime}
                    status={item.status}
                    orderId={item.orderId}
                    orderDate={item.orderDate}
                    courierId={item.courierId}
                    courier={item.courier}
                    customerId={item.customerId}
                  />

                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setModal(true)}
                    style={{
                      textTransform: 'none',
                      height: '40px',
                      borderRadius: '10px',
                    }}
                  >
                    <TranslatedField
                      noWrap
                      isTranslate
                      fontSize={16}
                      color="black"
                      originText="editDeliveryTime"
                    />
                  </Button>
                  {modal && (
                    <DeliveryDateChangeModal
                      isMobile={isMobile}
                      orderId={item.orderId}
                      isOpen={modal}
                      date={formatDate(new Date(item.deliveryTime as string), true)}
                      closeModal={() => setModal(false)}
                    />
                  )}
                </Stack>
              ))}
            </MobileScrollComponent>
          </Box>
        </>
      ) : (
        <NoRowsImage />
      )}
    </Stack>
  );
};
