import { useGetMeLazyQuery } from '../__generated__/getMe';
import { useLocalStorage } from '../../../hooks';
import { useEffect } from 'react';
import { RolesEnum } from '../../../types/types';

interface ReturnHook {
  id?: string;
  username?: string;
  email?: string;
  role: string;
  isAdmin: boolean;
  isManager?: boolean;
  isSuperAdmin?: boolean;
  isAccounter?: boolean;
  lastName?: string;
  firstName?: string;
}
export const useGetMe = (): ReturnHook => {
  const { jwt } = useLocalStorage();
  const [runGetMe, { data: dataMe }] = useGetMeLazyQuery();

  useEffect(() => {
    if (jwt) {
      runGetMe().then();
    }
  }, [jwt]);

  if (!jwt) {
    return {
      isAdmin: false,
      role: '',
    };
  }

  return {
    id: dataMe?.me?.id,
    email: dataMe?.me?.email ?? '',
    username: dataMe?.me?.username ?? '',
    role: dataMe?.me?.role?.name ?? '',
    isAdmin: dataMe?.me?.role?.name === RolesEnum.Admin,
    isSuperAdmin: dataMe?.me?.role?.name === RolesEnum.SuperAdmin,
    isManager: dataMe?.me?.role?.name === RolesEnum.Manager,
    isAccounter: dataMe?.me?.role?.name === RolesEnum.Accounter,
    firstName: dataMe?.me?.customer_contact_info?.data?.attributes?.firstName ?? '',
    lastName: dataMe?.me?.customer_contact_info?.data?.attributes?.lastName ?? '',
  };
};
