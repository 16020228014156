import { FC, useState } from 'react';
import { endOfToday, startOfDay, subDays } from 'date-fns';

import { PAGE_COUNT } from '../../../helpers/constants';
import { IncomingInvoiceFiltersInput } from '../../../__generated__/types';
import { useGetIncomingInvoicesQuery } from '../__generated__/getIncomingInvoices';

interface PropsHook {
  filters: IncomingInvoiceFiltersInput;
  idSupplier?: string;
}

export const useGetIncomingInvoices = ({ filters }: PropsHook) => {
  const [page, setPage] = useState(1);

  const { data, loading, refetch, ...rest } = useGetIncomingInvoicesQuery({
    variables: {
      pagination: {
        page: page,
        pageSize: PAGE_COUNT,
      },
      filters,
      sort: ['date:desc'],
    },
  });

  const changePage = (_page: number): void => {
    setPage(_page + 1);
  };

  return {
    data,
    refetch,
    loading,
    page,
    pageSize: data?.incomingInvoices?.meta.pagination.total || 0,
    totalPage: data?.incomingInvoices?.meta.pagination.total
      ? Math.ceil(data?.incomingInvoices?.meta.pagination.total / PAGE_COUNT)
      : 0,
    changePage,
    ...rest,
  };
};
