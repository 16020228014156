import { endOfToday, startOfMonth } from 'date-fns';

import {
  CustomerReturnItemFiltersInput,
  Enum_Customerreturn_Status,
  Pagination,
  PaginationArg,
} from '../../../../../__generated__/types';
import { useGetCustomerReturnsQuery } from '../../../../../graphql/queries/__generated__/getCustomerReturns';

export interface ReturnType {
  id?: string | null;
  correctiveInvoiceDate: any;
  correctiveInvoiceNumber?: string | null;
  dateOfReceiving: any;
  dateReturned: any;
  status?: Enum_Customerreturn_Status | null;
  returnReason?: string | null;
  returnComment?: string | null;
}

export type PaginationTransactionsType = Omit<Pagination, '__typename'>;

interface ReturnHook {
  customerReturns: Array<ReturnType>;
  pagination?: PaginationTransactionsType;
  isLoading?: boolean;
  refetchCustomerReturns: () => void;
}

export type FiltersType = {
  searchStrings?: string;
  searchNumbers?: string;
};
interface PropsHook {
  pagination?: PaginationArg;
  filters: CustomerReturnItemFiltersInput;
}

export const useGetClientReturns = ({ pagination, filters }: PropsHook): ReturnHook => {
  const {
    data: clientReturnsData,
    loading: clientReturnsLoading,
    refetch: refetchCustomerReturns,
  } = useGetCustomerReturnsQuery({
    variables: {
      filters: {
        ...(filters.createdAt
          ? {
              createdAt: filters.createdAt,
            }
          : {
              createdAt: {
                between: [startOfMonth(new Date()), endOfToday()],
              },
            }),
        customer: { id: filters.id },
      },
      pagination,
      sort: ['createdAt:desc'],
    },
  });

  if (clientReturnsData?.customerReturns?.data) {
    const returnsData = clientReturnsData?.customerReturns.data;
    const customerReturns = returnsData.map(item => {
      return {
        id: item.id,
        dateOfReceiving:
          item?.attributes?.customer_return_items?.data[0]?.attributes?.stock?.data?.attributes
            ?.incoming_invoice?.data?.attributes?.supplierInvoiceDate,
        correctiveInvoiceDate: item?.attributes?.correctiveInvoiceDate,
        correctiveInvoiceNumber: item.attributes?.correctiveInvoiceNumber,
        dateReturned: item.attributes?.dateReturned,
        status: item.attributes?.status,
        returnReason: item?.attributes?.customer_return_items?.data[0]?.attributes?.returnReason,
        returnComment: item?.attributes?.customer_return_items?.data[0]?.attributes?.returnComment,
      };
    });
    const pagination: PaginationTransactionsType = {
      page: clientReturnsData?.customerReturns.meta.pagination.page,
      pageCount: clientReturnsData?.customerReturns.meta.pagination.pageCount,
      pageSize: clientReturnsData?.customerReturns.meta.pagination.pageSize,
      total: clientReturnsData?.customerReturns.meta.pagination.total,
    };
    return {
      customerReturns,
      pagination,
      isLoading: clientReturnsLoading,
      refetchCustomerReturns,
    };
  }

  return { customerReturns: [], isLoading: clientReturnsLoading, refetchCustomerReturns };
};
