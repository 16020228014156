import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type GetUsersRoleQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.UsersPermissionsRoleFiltersInput>;
}>;


export type GetUsersRoleQuery = { __typename?: 'Query', usersPermissionsRoles?: { __typename?: 'UsersPermissionsRoleEntityResponseCollection', data: Array<{ __typename?: 'UsersPermissionsRoleEntity', id?: string | null, attributes?: { __typename?: 'UsersPermissionsRole', name: string } | null }> } | null };


export const GetUsersRoleDocument = gql`
    query getUsersRole($filters: UsersPermissionsRoleFiltersInput) {
  usersPermissionsRoles(filters: $filters, pagination: {limit: 100000}) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
    `;

/**
 * __useGetUsersRoleQuery__
 *
 * To run a query within a React component, call `useGetUsersRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersRoleQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetUsersRoleQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersRoleQuery, GetUsersRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersRoleQuery, GetUsersRoleQueryVariables>(GetUsersRoleDocument, options);
      }
export function useGetUsersRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersRoleQuery, GetUsersRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersRoleQuery, GetUsersRoleQueryVariables>(GetUsersRoleDocument, options);
        }
export type GetUsersRoleQueryHookResult = ReturnType<typeof useGetUsersRoleQuery>;
export type GetUsersRoleLazyQueryHookResult = ReturnType<typeof useGetUsersRoleLazyQuery>;
export type GetUsersRoleQueryResult = Apollo.QueryResult<GetUsersRoleQuery, GetUsersRoleQueryVariables>;