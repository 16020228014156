import Resizer from 'react-image-file-resizer';

export const resizeFile = (file: File, width?: number, height?: number, quality?: number) =>
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      width || 300,
      height || 300,
      'JPEG',
      quality || 100,
      0,
      uri => {
        resolve(uri);
      },
      'file'
    );
  });
