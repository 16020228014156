import React, { FC, useEffect, useState } from 'react';
import { Button, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';

import { useLocalization } from '../../../../../../localization';
import { ModalConfirm } from '../../../../../../components/ModalConfirm';
import { WrapperToolBar } from '../../../../../../components/WrapperToolBar';
import { AddProductsModal } from './components/AddProductsModal/AddProductsModal';
import { Enum_Incominginvoice_Status } from '../../../../../../__generated__/types';
import { useHandlerNotificationApp } from '../../../../../../hooks/useHandlerNotificationApp';
import { GetIncomingInvoiceQuery } from '../../../../../../graphql/queries/__generated__/getIncomingInvoice';
import { TranslatedField } from '../../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useUpdateIncomingInvoiceMutation } from '../../../../../../graphql/mutations/__generated__/updateIncomingInvoice';
import { IncomingInvoiceSplittingModal } from '../../../../../../components/IncomingInvoiceSplitting';

interface Props {
  status?: Enum_Incominginvoice_Status | null;
  incomingInvoiceData?: GetIncomingInvoiceQuery;
  invoiceId: string;
  idSupplier: string;
  incomingInvoiceIds: string[];
  incomingInvoiceRefetch: () => void;
  refetchIncomingInvoices: () => void;
}

export const ToolbarInvoice: FC<Props> = ({
  status,
  invoiceId,
  idSupplier,
  incomingInvoiceIds,
  incomingInvoiceData,
  incomingInvoiceRefetch,
  refetchIncomingInvoices,
}) => {
  const { translateLang } = useLocalization();

  const [openModal, setOpenModal] = useState(false);
  const [invoiceSplittingModal, setInvoiceSplittingModal] = useState(false);
  const [addProductsModal, setAddProductsModal] = useState(false);

  const [updateIncomingInvoiceMutation] = useUpdateIncomingInvoiceMutation();
  const { addNotification } = useHandlerNotificationApp();

  const [invoiceStatus, setInvoiceStatus] = useState<
    Enum_Incominginvoice_Status | null | undefined
  >(null);

  useEffect(() => {
    setInvoiceStatus(status);
  }, [status]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    if (value === Enum_Incominginvoice_Status.Completed) {
      toggleModal();
      return;
    }

    updateIncomingInvoiceMutation({
      variables: {
        data: { status: Enum_Incominginvoice_Status.Created },
        id: invoiceId,
      },
    })
      .then(() => {
        addNotification({
          messageError: translateLang('invoiceSuccessfullyUpdated'),
          typeMessage: 'success',
        });

        setInvoiceStatus(Enum_Incominginvoice_Status.Created);
        incomingInvoiceRefetch();
      })
      .catch(err => {
        addNotification({ messageError: err.message, typeMessage: 'error' });
      });
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const toggleInvoiceSplittingModal = () => {
    setInvoiceSplittingModal(!invoiceSplittingModal);
  };
  const toggleAddProductsModal = () => {
    setAddProductsModal(!addProductsModal);
  };

  const handleConfirm = () => {
    const status = Enum_Incominginvoice_Status.Completed;

    updateIncomingInvoiceMutation({
      variables: {
        data: { status },
        id: invoiceId,
      },
    })
      .then(() => {
        addNotification({
          messageError: translateLang('invoiceSuccessfullyUpdated'),
          typeMessage: 'success',
        });

        setInvoiceStatus(Enum_Incominginvoice_Status.Completed);
        incomingInvoiceRefetch();
      })
      .catch(err => {
        addNotification({ messageError: err.message, typeMessage: 'error' });
      });
  };

  const invoiceDate = incomingInvoiceData?.incomingInvoice?.data?.attributes?.supplierInvoiceDate;

  const invoiceNumber = `${new Date(invoiceDate).getFullYear()}/${
    incomingInvoiceData?.incomingInvoice?.data?.attributes?.supplierInvoiceNumber
  }`;

  return (
    <WrapperToolBar pb={5}>
      <Stack width="100%" flexDirection="row" justifyContent="space-between" alignItems="start">
        <Stack flexDirection="row" alignItems="start" gap={4.5}>
          <Stack flexDirection="column" justifyContent="left">
            <Typography fontSize="22px" color="#19151699" mb={2}>
              {`${translateLang('incomingInvoice')} №`}{' '}
              <span style={{ fontSize: '26px', color: '#191516' }}>{invoiceNumber}</span>
            </Typography>
          </Stack>
          <Stack flexDirection="row" alignItems="center" gap={2}>
            <Typography fontSize="10px" color="#19151699">
              {`${translateLang('status')}:`}
            </Typography>
            <RadioGroup row value={invoiceStatus} onChange={handleChange}>
              <FormControlLabel
                value={Enum_Incominginvoice_Status.Created}
                control={<Radio />}
                label={
                  <Typography fontSize="14px" color="#191516">
                    {translateLang('forming')}
                  </Typography>
                }
              />

              <FormControlLabel
                value={Enum_Incominginvoice_Status.Completed}
                control={<Radio />}
                label={
                  <Typography fontSize="14px" color="#191516">
                    {translateLang('formed')}
                  </Typography>
                }
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack
          bgcolor="white"
          flexDirection="row"
          justifyContent="right"
          alignItems="center"
          gap={2}
        >
          <Button
            disabled={invoiceStatus === Enum_Incominginvoice_Status.Completed}
            variant="contained"
            sx={{
              height: 50,
              p: 2,
              textTransform: 'none',
              backgroundColor: '#5269A3',
              borderRadius: '10px',
            }}
            onClick={toggleAddProductsModal}
          >
            <TranslatedField originText={'addProducts'} fontSize={16} isTranslate noWrap />
          </Button>
          <Button
            onClick={toggleInvoiceSplittingModal}
            variant="contained"
            sx={{
              height: 50,
              p: 2,
              textTransform: 'none',
              backgroundColor: '#5269A3',
              borderRadius: '10px',
            }}
          >
            <TranslatedField originText={'splitInvoice'} fontSize={16} isTranslate noWrap />
          </Button>
        </Stack>
      </Stack>
      <ModalConfirm
        openModal={openModal}
        closeModal={toggleModal}
        title={translateLang('doYouReallyConfirmThisAction')}
        handlerConfirm={handleConfirm}
        confirmTitleButton={translateLang('yes')}
      />
      {invoiceSplittingModal && incomingInvoiceData?.incomingInvoice?.data ? (
        <IncomingInvoiceSplittingModal
          invoiceData={{
            ...incomingInvoiceData.incomingInvoice.data,
            attributes: {
              date: incomingInvoiceData.incomingInvoice.data.attributes?.date,
              ...incomingInvoiceData.incomingInvoice.data?.attributes,
              stocks: undefined,
            },
          }}
          isOpen={invoiceSplittingModal}
          handleClose={toggleInvoiceSplittingModal}
          refetchIncomingInvoices={refetchIncomingInvoices}
        />
      ) : null}

      {addProductsModal ? (
        <AddProductsModal
          invoiceId={invoiceId}
          idSupplier={idSupplier}
          isModalOpen={addProductsModal}
          handleClose={toggleAddProductsModal}
          incomingInvoiceRefetch={incomingInvoiceRefetch}
          incomingInvoiceIds={incomingInvoiceIds}
        />
      ) : null}
    </WrapperToolBar>
  );
};
