import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { endOfToday, format, startOfDay, subDays } from 'date-fns';

import { useDefaultRangeCalendar } from '../../../../hooks';
import { RangeCalendar } from '../../../../legos/rangeCalendar/RangeCalendar';
import { useFiltersSearchParamsForOrders } from '../../../../hooks/useFiltersSearchParamsForOrders';

export const SupplierBalanceToolbar = () => {
  const { reportRangeDates } = useDefaultRangeCalendar();
  const { changeDate, selDateTo, selDateFrom } = useFiltersSearchParamsForOrders();

  const [selectDates, setSelectedDates] = useState(getDatesFilters);

  function getDatesFilters(): Date[] {
    if (selDateFrom && selDateTo) {
      return [new Date(selDateFrom), new Date(selDateTo)];
    }
    return [startOfDay(subDays(new Date(), 30)), endOfToday()];
  }

  const handlerChangesDates = (val: Date[]) => {
    setSelectedDates(val);
    changeDate(format(val[0], 'yyyy-MM-dd'), format(val[1] ? val[1] : val[0], 'yyyy-MM-dd'));
  };

  return (
    <Stack width="100%" flexDirection="row" justifyContent="end" mb={2.5} gap={6.25}>
      <RangeCalendar
        selectedDates={selectDates}
        defaultRangeDates={reportRangeDates}
        setSelectedDates={handlerChangesDates}
      />
    </Stack>
  );
};
