import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { GeneralSpareFragmentFragmentDoc } from '../../fragments/__generated__/GeneralSpareFragment';
import { GeneralSpareDeFragmentFragmentDoc } from '../../fragments/__generated__/GeneralSpareDEFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type GetGeneralSpareByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetGeneralSpareByIdQuery = { __typename?: 'Query', en?: { __typename?: 'GeneralSparesEntityResponse', data?: { __typename?: 'GeneralSparesEntity', id: string, attributes?: { __typename?: 'GeneralSpare', descriptionsInfo?: any | null, articleCriteria?: any | null, isDraft?: boolean | null, source?: number | null, eanNumber?: any | null, oemNumbers?: any | null, images?: any | null, price?: number | null, brandId?: number | null, brandName?: string | null, title?: string | null, tecdoc_articleNumber?: string | null, discounts?: Array<{ __typename?: 'GeneralDiscounts', id?: string | null, discount?: number | null, supplier?: { __typename?: 'GeneralSupplier', id?: string | null } | null } | null> | null, suppliersSpare?: Array<{ __typename?: 'GeneralSuppliersSpare', id?: string | null, minOrderQuantity?: number | null, amount?: string | null, discount?: number | null, prevPrice?: number | null, price?: number | null, supplierPrice?: number | null, warehouse?: any | null, tecdoc_articleNumber?: string | null, external_id?: string | null, priceByRole?: { __typename?: 'PriceByRole', customer?: number | null, customer30?: number | null, customer40?: number | null, employee?: number | null, permanentWholesaleCustomer?: number | null, wholesaleCustomer?: number | null } | null, supplier?: { __typename?: 'GeneralSupplier', id?: string | null, name?: string | null, deliveryDay?: number | null } | null } | null> | null } | null } | null } | null, de?: { __typename?: 'GeneralSparesEntityResponse', data?: { __typename?: 'GeneralSparesEntity', attributes?: { __typename?: 'GeneralSpare', descriptionsInfo?: any | null, articleCriteria?: any | null, isDraft?: boolean | null, source?: number | null, eanNumber?: any | null, images?: any | null, price?: number | null, brandId?: number | null, brandName?: string | null, title?: string | null, tecdoc_articleNumber?: string | null } | null } | null } | null };


export const GetGeneralSpareByIdDocument = gql`
    query getGeneralSpareById($id: ID!) {
  en: generalSpare(id: $id, locale: "en") {
    data {
      ...GeneralSpareFragment
    }
  }
  de: generalSpare(id: $id, locale: "de") {
    data {
      ...GeneralSpareDEFragment
    }
  }
}
    ${GeneralSpareFragmentFragmentDoc}
${GeneralSpareDeFragmentFragmentDoc}`;

/**
 * __useGetGeneralSpareByIdQuery__
 *
 * To run a query within a React component, call `useGetGeneralSpareByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralSpareByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralSpareByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGeneralSpareByIdQuery(baseOptions: Apollo.QueryHookOptions<GetGeneralSpareByIdQuery, GetGeneralSpareByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGeneralSpareByIdQuery, GetGeneralSpareByIdQueryVariables>(GetGeneralSpareByIdDocument, options);
      }
export function useGetGeneralSpareByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGeneralSpareByIdQuery, GetGeneralSpareByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGeneralSpareByIdQuery, GetGeneralSpareByIdQueryVariables>(GetGeneralSpareByIdDocument, options);
        }
export type GetGeneralSpareByIdQueryHookResult = ReturnType<typeof useGetGeneralSpareByIdQuery>;
export type GetGeneralSpareByIdLazyQueryHookResult = ReturnType<typeof useGetGeneralSpareByIdLazyQuery>;
export type GetGeneralSpareByIdQueryResult = Apollo.QueryResult<GetGeneralSpareByIdQuery, GetGeneralSpareByIdQueryVariables>;