/* eslint-disable no-console */
import React, { FC, useState } from 'react';
import { LaunchRounded } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

import { ChangeLocationModal } from '../../../OrderTab/ChangeLocationModal';
import { CellAddressType } from '../../../../../../graphql/queries/hook/useGetWarehouse';
import { TableProductActive } from '../../../../../../components/TableProductActive/TableProductActive';

interface Props {
  index: number;
  article: string;
  supplier: string;
  productName: string;
  productStockId: string;
  balanceQuantity: number;
  incomingQuantity: number;
  reservedQuantity: number;
  stockAddressOrder: CellAddressType[];
}

export const TableWarehouseStocksRowItem: FC<Props> = ({
  article,
  supplier,
  productName,
  productStockId,
  balanceQuantity,
  incomingQuantity,
  reservedQuantity,
  stockAddressOrder,
}) => {
  const [modalLocal, setModalLocal] = useState(false);

  const stocks = () => {
    if (stockAddressOrder.length == 0) {
      return <Typography>? / ?</Typography>;
    } else if (stockAddressOrder.length > 2) {
      return (
        <>
          {stockAddressOrder.map(item => (
            <Typography key={item.id}>{item.address}</Typography>
          ))}
        </>
      );
    } else {
      return <Typography> {stockAddressOrder[0].address}</Typography>;
    }
  };

  return (
    <TableProductActive.RowTable>
      <TableProductActive.CellTable
        ceilStyle={{ borderLeft: 'none' }}
      >{`${productStockId}`}</TableProductActive.CellTable>
      <TableProductActive.CellTable>{article}</TableProductActive.CellTable>
      <TableProductActive.CellTable>{supplier}</TableProductActive.CellTable>
      <TableProductActive.CellTable>{productName}</TableProductActive.CellTable>
      <TableProductActive.CellTable>{incomingQuantity}</TableProductActive.CellTable>
      <TableProductActive.CellTable>{reservedQuantity}</TableProductActive.CellTable>
      <TableProductActive.CellTable>{balanceQuantity}</TableProductActive.CellTable>

      <TableProductActive.CellTable ceilStyle={{ justifyContent: 'left' }}>
        {stocks()}
        <Box display="flex" alignItems="center">
          <IconButton onClick={() => setModalLocal(true)}>
            <LaunchRounded sx={{ fontSize: '0.8rem', color: '#5269A3' }} />
          </IconButton>
        </Box>
      </TableProductActive.CellTable>
      {modalLocal && (
        <ChangeLocationModal
          productId={productStockId}
          isOpen={modalLocal}
          location={stockAddressOrder[0].id || ''}
          closeModal={() => setModalLocal(false)}
        />
      )}
    </TableProductActive.RowTable>
  );
};
