import React, { Dispatch, FC, useState } from 'react';
import { CustomerType, useGetClientById } from '../../../graphql/queries/hook/useGetClientById';
import { IconButton, Stack, FormLabel, Button, Box, Checkbox, Typography } from '@mui/material';
import { Icon } from '../../../legos';
import { useLocalization } from '../../../localization';
import { StyledTypography } from './StyledTypography';
import DeleteIcon from '@mui/icons-material/Delete';
import { GetClientByIdDocument } from '../../../graphql/queries/__generated__/getClintById';
import { useUpdateCustomerContactInfoMutation } from '../../../graphql/mutations/__generated__/updateCustomerContactInfo';
import { ComponentClientDeliveryAddressInput } from '../../../__generated__/types';
import { CustomModal } from '../../../components';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { useHandlerNotificationApp } from '../../../hooks/useHandlerNotificationApp';
import { Address, formatAddress, handlerError } from '../../../helpers/functions';
import { RadioButtonIcon } from '../../../components/TimeLine/RadioButtonIcon';
import { theme } from '../../../helpers';

type DeliveryAddress = Pick<CustomerType, 'deliveryAddress'>;
interface DeliveryAddressProps extends DeliveryAddress {
  userId: string;
  onClickInput(idAddress: string): void;
  selectedDeliveryAddress?: Address | null;
  setSelectedDeliveryAddress?: Dispatch<React.SetStateAction<Address | null>>;
}
export const AddressField: FC<DeliveryAddressProps> = ({
  deliveryAddress,
  onClickInput,
  userId,
  selectedDeliveryAddress,
  setSelectedDeliveryAddress,
}) => {
  const { translateLang, updateSelectLanguage } = useLocalization();
  const [runUpdateCustomer] = useUpdateCustomerContactInfoMutation();
  const { contactInfoId } = useGetClientById(userId);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [deletedId, setDeletedId] = useState<string>('');
  const { addNotification } = useHandlerNotificationApp();

  const closeSuccessModal = () => {
    setOpenSuccessModal(false);
  };
  const openModals = (value?: string) => {
    if (value) {
      setDeletedId(value);
    }
    setOpenSuccessModal(true);
  };
  const handlerDelete = async () => {
    const address: ComponentClientDeliveryAddressInput[] | undefined = deliveryAddress
      ?.map(item => {
        return {
          id: item?.id,
          city: item?.city,
          street: item?.street,
          zipCode: item?.zipCode,
          number: item?.number,
        };
      })
      .filter(item => item.id !== deletedId);

    const variables = {
      deliveryAddress: address,
    };
    try {
      const response = await runUpdateCustomer({
        variables: {
          data: { ...variables },
          id: contactInfoId || '',
        },
        refetchQueries: [
          {
            query: GetClientByIdDocument,
            variables: {
              id: userId || '',
              locale: updateSelectLanguage as string,
            },
          },
        ],
      });
      if (response) {
        setOpenSuccessModal(false);
        addNotification({ messageError: 'Address  updated success', typeMessage: 'success' });
      }
    } catch (err: unknown) {
      handlerError(err);
    }
  };
  return (
    <>
      <FormLabel sx={{ color: '#7A828A', my: '10px', fontSize: '14px' }}>
        {translateLang('address')}
      </FormLabel>

      {deliveryAddress &&
        deliveryAddress?.map(address => {
          const isSelectedDeliveryAddress =
            !!selectedDeliveryAddress &&
            formatAddress(selectedDeliveryAddress) === formatAddress(address);
          return (
            <Stack key={address?.id} direction="row" alignItems="flex-end">
              {setSelectedDeliveryAddress ? (
                <Box sx={{ marginBottom: 1, marginRight: 2 }}>
                  <RadioButtonIcon
                    statuses={{
                      isActive: isSelectedDeliveryAddress,
                      isCompleted: isSelectedDeliveryAddress,
                      isSkipped: false,
                    }}
                    onClick={() => {
                      setSelectedDeliveryAddress(address);
                    }}
                  />
                </Box>
              ) : null}
              <Stack
                flexWrap={'wrap'}
                onClick={() => onClickInput(address?.id || '')}
                direction={{ xs: 'column', sm: 'row' }}
                gap="8px"
                alignItems="flex-end"
              >
                <StyledTypography value={address?.city} label={translateLang('city')} />
                <StyledTypography value={address?.zipCode} label={translateLang('zip')} />
                <StyledTypography value={address?.street} label={translateLang('street')} />
                <StyledTypography value={address?.number} label={translateLang('house')} />
                <StyledTypography
                  value={address?.district}
                  label={translateLang('deliveryArrea')}
                />
                <Stack sx={{ flexGrow: 1 }} justifyContent="space-between" spacing={1} height={71}>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '500',
                      height: '17px',
                      paddingLeft: 1,
                      lineHeight: '17px',
                      color: '#2A3453',
                    }}
                  >
                    {translateLang('main')}
                  </Typography>
                  <Checkbox
                    checked={!!address?.main}
                    sx={{
                      width: 'auto',
                      color: theme.palette.grey[600],
                    }}
                    disabled
                  />
                </Stack>
              </Stack>

              {deliveryAddress.length > 1 && (
                <IconButton
                  sx={{ marginLeft: 2, width: 40, height: 40 }}
                  onClick={() => openModals(address?.id)}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Stack>
          );
        })}

      <IconButton
        sx={{
          width: '200px',
          fontSize: 'small',
          height: '40px',
          borderRadius: '0px',
          justifyContent: 'left',
          paddingLeft: '0px',
          color: '#848C22',
        }}
        onClick={() => onClickInput('')}
      >
        <Icon icon="add" color="inherit" />
        {translateLang('addAddress')}
      </IconButton>
      <CustomModal
        title={translateLang('deleteConfirm')}
        handleClose={closeSuccessModal}
        open={openSuccessModal}
      >
        <Stack flexDirection="row" mt={5}>
          <Button
            onClick={closeSuccessModal}
            variant="contained"
            color="secondary"
            style={{
              width: '80%',
              height: '95%',
              marginRight: '2px',
            }}
          >
            <TranslatedField
              originText={translateLang('cancel')}
              fontSize={16}
              isTranslate
              noWrap
              color="white"
            />
          </Button>
          <Button
            onClick={handlerDelete}
            variant="contained"
            color="error"
            style={{
              width: '80%',
              height: '95%',
              marginLeft: '2px',
            }}
          >
            <TranslatedField
              originText={translateLang('del')}
              fontSize={16}
              isTranslate
              noWrap
              color="white"
            />
          </Button>
        </Stack>
      </CustomModal>
    </>
  );
};
