import React, { FC } from 'react';
import { ArrowBack } from '@mui/icons-material';
import { CircularProgress, IconButton, Stack } from '@mui/material';

import { ToolbarInvoice } from './ToolbarInvoice';
import { theme } from '../../../../../../helpers';
import { ReturnInvoiceTable } from './ReturnInvoiceTable';
import { useLocalization } from '../../../../../../localization';
import { useGetSupplierReturnQuery } from '../../../../../../graphql/queries/__generated__/getSupplierReturn';
import { TranslatedField } from '../../../../../../components/Layout/components/TranslatedField/TranslatedField';

interface Props {
  returnInvoiceId: string;
  toggleReturnInvoiceTable: (id: string) => void;
}

export const ReturnInvoice: FC<Props> = ({ returnInvoiceId, toggleReturnInvoiceTable }) => {
  const { translateLang } = useLocalization();

  const { data: returnInvoiceData, loading } = useGetSupplierReturnQuery({
    variables: {
      id: returnInvoiceId,
    },
  });

  const returnInvoice =
    returnInvoiceData?.supplierReturn?.data?.attributes?.supplier_return_items?.data;

  const invoiceStatus = returnInvoiceData?.supplierReturn?.data?.attributes?.status;
  const invoiceAmount = returnInvoiceData?.supplierReturn?.data?.attributes?.total;

  return (
    <Stack width="100%" mb={5}>
      <>
        <Stack flexDirection="row" alignItems="center" gap={1} mb={4.25}>
          <IconButton onClick={() => toggleReturnInvoiceTable('')}>
            <ArrowBack sx={{ color: theme.palette.common.black }} />
          </IconButton>
          <TranslatedField
            originText={translateLang('returnToListOfInvoicesForReturn')}
            fontSize={20}
            isTranslate
            noWrap
          />
        </Stack>
        {!loading ? (
          <>
            <ToolbarInvoice
              returnInvoiceId={returnInvoiceId}
              status={invoiceStatus}
              returnInvoiceData={returnInvoiceData}
            />
            <ReturnInvoiceTable
              loading={loading}
              items={returnInvoice}
              invoiceAmount={invoiceAmount}
            />
          </>
        ) : (
          <Stack width="100%" alignItems="center" mt={10}>
            <CircularProgress size={40} />
          </Stack>
        )}
      </>
    </Stack>
  );
};
