/* eslint-disable no-console */
import React, { FC } from 'react';
import { IconButton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { RemoveRedEyeOutlined } from '@mui/icons-material';
import { PatchesName, UrlParamsObjectType } from '../../../../types/types';
import { TableProductActive } from '../../../../components/TableProductActive/TableProductActive';
import { formatDate } from '../../../../helpers/functions';

interface Props {
  orderID: string;
  orderNumber: string;
  status: string;
  manager?: string[];
  numberOfLine: number;
  price: number | null;
  date?: Date | undefined;
}

export const TableRowHistoryItem: FC<Props> = ({
  orderID,
  orderNumber,
  price,
  status,
  manager,
  date,
}) => {
  const { idUser = '' } = useParams<UrlParamsObjectType>();
  const navigate = useNavigate();

  return (
    <TableProductActive.RowTable>
      <TableProductActive.CellTable
        ceilStyle={{ width: '20px', height: '20px', borderLeft: 'none' }}
      >{`#${orderNumber}`}</TableProductActive.CellTable>
      <TableProductActive.CellTable>{formatDate(date ?? '')}</TableProductActive.CellTable>
      <TableProductActive.CellTable>{manager}</TableProductActive.CellTable>
      <TableProductActive.CellTable>{status}</TableProductActive.CellTable>
      <TableProductActive.CellTable>{price}</TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ justifyContent: 'center' }}>
        <IconButton onClick={() => navigate(`/${PatchesName.Orders}/${idUser}/${orderID}`)}>
          <RemoveRedEyeOutlined />
        </IconButton>
      </TableProductActive.CellTable>
    </TableProductActive.RowTable>
  );
};
