import React, { FC } from 'react';

import { StandardTextFieldProps } from '@mui/material';
import { StyledInputWithShadow } from '../../../../../legos/Input/styled';
interface CustomTextField extends Omit<StandardTextFieldProps, 'variant'> {
  placeholder?: string | undefined;
  label?: string;
  labelColor?: string;
  bgcolor?: string;
  variant?: 'outlined' | 'standard';
}

export const Input: FC<CustomTextField> = ({
  placeholder,
  label,
  bgcolor = '#F1F3F8',
  variant = 'standard',
  ...props
}) => {
  return (
    <StyledInputWithShadow
      bgcolor={bgcolor}
      variant={variant}
      InputProps={{
        disableUnderline: true,
      }}
      InputLabelProps={{
        shrink: true,
        style: {
          color: '#2A3453',
          fontSize: '14px',
          fontWeight: '500',
          height: '17px',
          lineHeight: '17px',
        },
      }}
      fullWidth
      multiline
      maxRows={2}
      label={label}
      placeholder={placeholder}
      {...props}
    />
  );
};
