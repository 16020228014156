import { useState } from 'react';
import { InputMaybe, SupplierFiltersInput } from '../../../__generated__/types';
import { PAGE_COUNT } from '../../../helpers/constants';
import { useGetSuppliersQuery } from '../__generated__/getSuppliers';

export const useGetSuppliers = (initialFilters?: InputMaybe<SupplierFiltersInput>) => {
  const [innerFilters, setInnerFilters] = useState({
    filters: initialFilters,
    page: 1,
  });

  const queryResult = useGetSuppliersQuery({
    variables: {
      pagination: {
        page: innerFilters.page,
        pageSize: PAGE_COUNT,
      },
      filters: innerFilters.filters,
      sort: ['name'],
    },
  });

  const changePage = (page: number): void => {
    setInnerFilters(oldValue => ({
      ...oldValue,
      page,
    }));
  };

  const changeFilters = (filters?: InputMaybe<SupplierFiltersInput>) => {
    setInnerFilters({
      filters,
      page: 1,
    });
  };

  return {
    page: innerFilters.page,
    changePage,
    changeFilters,
    ...queryResult,
  };
};
