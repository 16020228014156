import React, { DragEvent, FC } from 'react';
import { Box, Typography } from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';

import { StockRow } from './StockRow';
import { useLocalization } from '../../../localization';
import { IncomingInvoiceForm } from './IncomingInvoiceForm';
import { IncomingInvoiceDetails, IncomingInvoiceForSplit, StockForSplit } from '../types';
import { roundAmountToString } from '../../../utils/currencyUtils';

type InvoiceContainerProps = {
  index: number;
  acceptDrag?: boolean;
  values: IncomingInvoiceDetails;
  invoiceDetails: IncomingInvoiceDetails;
  errors?: FormikErrors<IncomingInvoiceDetails>;
  touched?: FormikTouched<IncomingInvoiceDetails>;
  dragEndHandler: (event: DragEvent<HTMLDivElement>) => void;
  dragStartHandler: (stock: StockForSplit, invoice: IncomingInvoiceForSplit) => void;
  dragDropHandler: (event: DragEvent<HTMLDivElement>, invoice: IncomingInvoiceForSplit) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<(FormikErrors<IncomingInvoiceDetails> | undefined)[]>;
};

export const InvoiceContainer: FC<InvoiceContainerProps> = ({
  index,
  errors,
  values,
  touched,
  acceptDrag,
  setFieldValue,
  invoiceDetails,
  dragEndHandler,
  dragDropHandler,
  dragStartHandler,
}) => {
  const { translateLang } = useLocalization();

  return (
    <Box
      pr={1}
      mr={1}
      display="flex"
      minWidth="550px"
      flexDirection="column"
      borderRight={'1px solid #8D8D8D'}
    >
      <IncomingInvoiceForm
        index={index}
        values={values}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        dragEndHandler={dragEndHandler}
      />
      <Box
        pr={4}
        pl={3.5}
        pb={0.5}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Typography fontWeight="800">
          {translateLang('rowsCount')}: {invoiceDetails?.stocks?.length ?? 0}
        </Typography>
        <Typography fontWeight="800">
          {translateLang('actualAmount')}:{' '}
          {roundAmountToString(
            invoiceDetails?.stocks?.reduce(
              (sum, item) => sum + (item.incomingQuantity ?? 0) * (item.supplierPrice ?? 0),
              0
            )
          )}
        </Typography>
      </Box>

      <Box
        height="100%"
        overflow="scroll"
        sx={{
          '::-webkit-scrollbar-corner': { background: 'transparent' },
          outline: '2px solid transparent',
          transition: 'all 200ms ',
        }}
        onDrop={e => {
          dragDropHandler(e, invoiceDetails.invoice);
          e.currentTarget.style.outline = '2px solid transparent';
        }}
        onDragLeave={e => {
          e.currentTarget.style.outline = '2px solid transparent';
        }}
        onDragOver={e => {
          if (acceptDrag) {
            e.preventDefault();
            e.currentTarget.style.outline = '2px solid #97A408';
          }
        }}
      >
        {invoiceDetails.stocks.map(stock => (
          <StockRow
            draggable
            stock={stock}
            key={stock.id}
            acceptDrag={acceptDrag}
            dragEndHandler={dragEndHandler}
            invoice={invoiceDetails.invoice}
            dragDropHandler={e => dragDropHandler(e, invoiceDetails.invoice)}
            dragStartHandler={() => dragStartHandler(stock, invoiceDetails.invoice)}
          />
        ))}
      </Box>
    </Box>
  );
};
